import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4 pb-8 position-relative"},[_c('div',{staticClass:"map-opr-srh"},[_c(VRow,[_c(VCol,{staticClass:"pr-0 pt-4",attrs:{"cols":"8"}},[_c('div',{staticClass:"pb-2"},[_c(VAutocomplete,{staticClass:"auto-pd",attrs:{"width":"300","height":"42","items":_vm.teamJobOperators,"item-text":"operatorDetails.firstName","item-value":"operatorDetails.id","placeholder":_vm.$stringConstants('selectOperatorPlaceholder'),"outlined":"","dense":"","menu-props":{
                                    contentClass: 'customMenu'
                                    },"flat":"","solo":""},on:{"change":_vm.filterLocationByOperator},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticStyle:{"padding":"5px 0"}},[_c('div',{staticClass:"ib"},[_c('OwnerAvatar',{staticClass:"mr-2",staticStyle:{"font-size":"13px"},attrs:{"width":"28px","height":"28px","isCircular":true,"isThemeColor":false,"isDark":false,"backgroundColor":data.item.operatorDetails.colorCode,"initials":data.item.operatorDetails.firstName}})],1),_c('div',{staticClass:"ib"},[_vm._v(" "+_vm._s(_vm.getShortOperatorName(data.item.operatorDetails.firstName)))])])]}},{key:"item",fn:function(ref){
                                    var item = ref.item;
return [_c('div',{staticStyle:{"padding":"5px 0"}},[_c('div',{staticClass:"ib"},[_c('OwnerAvatar',{staticClass:"mr-2",staticStyle:{"font-size":"13px"},attrs:{"width":"28px","height":"28px","isCircular":true,"isThemeColor":false,"isDark":false,"backgroundColor":item.operatorDetails.colorCode,"initials":item.operatorDetails.firstName}})],1),_c('div',{staticClass:"ib"},[_vm._v(" "+_vm._s(_vm.getShortOperatorName(item.operatorDetails.firstName)))])])]}}]),model:{value:(_vm.teamJobLocationSelectedOperatorId),callback:function ($$v) {_vm.teamJobLocationSelectedOperatorId=$$v},expression:"teamJobLocationSelectedOperatorId"}})],1)])],1)],1),_c('div',{staticStyle:{"height":"520px"},attrs:{"id":"teamJobsLocationMap"}})])}
var staticRenderFns = []

export { render, staticRenderFns }