






























































































import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";

export default {
    name: 'VehicleSettingsForm',
    mixins: [accountComputedMixin],
    components: {
        SectionLeftHeadings,
        VueCtkDateTimePicker
    }
}
