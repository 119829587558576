import { Role } from "./Role";

export class MinimalUser {
    id:	string;
    imageUrl: string;
    name?: string;
    businessName?: string;
    isInternal:	boolean;
    roles: Role[];
    ownerId: string;
    colorCode: string;

    public getMinimalUser(user) {
        this.id = user.id;
        this.imageUrl = user.imageUrl ? user.imageUrl : null;

        if(user.name) {
            this.name = user.name;
        } else if (user.firstName) {
            this.name = user.firstName;
        }
        
        if(user.businessProfile && user.businessProfile.title) {
            this.businessName = user.businessProfile.title;
        } else if (user.businessName) {
            this.businessName = user.businessName;
        }
        this.roles = user.roles ? user.roles.map(role => new Role(role)) : []
        this.colorCode = user.colorCode ? user.colorCode : null;

        return this;
    }
}