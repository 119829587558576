import $ from 'jquery';
import * as signalR from '@microsoft/signalr';
import { devLogger, getCookie, toCamelCase, sleep } from '@/utils/helpers';
import requests from '@/utils/requests';
import store from "../store";
import { apiPermissions } from './endpoints';
import buildUrl from 'build-url';
import router from '@/router/index';
import { homeComponentTitle, teamJobDetailsComponentTitle } from './strings';
import ConstantValues from '@/plugins/constantValues';
const routerState: any = router;

const channelList = [];
let client = null;

export default {

    fnOpenConnection: async function () {
        const url = process.env.VUE_APP_BASE_URL + "/" + "realtime";
        client = new signalR.HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: () => getCookie("tkn"),
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
            .build();

        devLogger().log('Connecting to SignalR.....');

        client.onreconnecting(async (error) => {
            console.assert(client.state === signalR.HubConnectionState.Reconnecting);
            devLogger().logErrorAllEnv(`Connection lost due to error: "${error}". Reconnecting.`);

            const hasAccessToken = getCookie("tkn");
            if(!hasAccessToken) {
                this.fnStopConnection();
                return;
            }
            
            const accessTokenExpirationDateTimeLocal = new Date(getCookie("ExpiringAt"));
            const currentDateTimeLocal = new Date();
            const isTokenValid = hasAccessToken && accessTokenExpirationDateTimeLocal && (currentDateTimeLocal < accessTokenExpirationDateTimeLocal);
            
            if(isTokenValid) {
                const permissionsResponse = await requests.getData(buildUrl(store.state.baseUrl, {
                    path: apiPermissions
                }));
    
                if(permissionsResponse.status != undefined && !(permissionsResponse && permissionsResponse.status == 200)) {
                        const tokenUpdated = await requests.updateToken(store);
                        if(!tokenUpdated) {
                            await store.dispatch('logout');
                            this.fnStopConnection();
                        }
                }
            }
        });

        client.onreconnected(async () => {
            if(routerState && routerState.history && routerState.history && routerState.history.pending) {
                switch(routerState.history.pending.name) {
                    case teamJobDetailsComponentTitle: 
                    case homeComponentTitle:  this.fnSubscribeGroup(localStorage.getItem('businessId')); break;
                }
            }
        });

        client.onclose(error => {
            console.assert(client.state === signalR.HubConnectionState.Disconnected);
            devLogger().logErrorAllEnv(`Connection closed due to error "${error}". Try refreshing this page to restart the connection.`);
        });
    },

    fnStopConnection: function () {
        devLogger().log('Disconnecting to SignalR...');
        if (client != null) {
            client.stop().then(function () {
                return devLogger().log('SignalR disconnected.');
            })
                .catch(function (err) {
                    return devLogger().logError(err.toString());
                });
        }
    },

    fnSubscribeGroup: async function (jobId) {
        if (client && client.state === signalR.HubConnectionState.Connected) {
            client.invoke("JoinGroup", jobId).then(function (result) {
                devLogger().log('Subscribed to job notifications.');
                devLogger().log(jobId);
                return true;
            }).catch(function (err) {
                devLogger().logError(err.toString());
            });
        }
    },

    fnUnSubscripbe: async function (id) {
        if (client != null && client.state === signalR.HubConnectionState.Connected) {
            client.invoke("LeaveGroup", id).then(function () {
                devLogger().log('UnSubscribed to notifications.');
                devLogger().log(id);
                return true;
            }).catch(function (err) {
                devLogger().logError(err.toString());
            });
        }
    },

    fnStart: async function (): Promise<boolean> {
        if (client.state == signalR.HubConnectionState.Disconnected) {
            const result = await client.start().then(async function () {
                if (client.state === signalR.HubConnectionState.Connected) {
                    devLogger().log('SignalR connected....');
                    return true;
                }
            }).catch(function (err) {
                devLogger().logError(err.toString());
                return false;
            });
            return result;
        }
        else {
            return false;
        }
    },

    fnStartConnection: async function (channelSubscribeName, state, methodName, jobId) {
        let signalRClass = null;
        signalRClass = this;
        if (client.state === signalR.HubConnectionState.Connected) {
            await signalRClass.fnSubscribeGroup(jobId);
            await signalRClass.GetJobAlerts(channelSubscribeName, state, methodName);
        }
    },

    GetJobAlerts: async function (channelSubscribeName, state, methodName) {
        if (client != null) {
            if (client.state === signalR.HubConnectionState.Connected) {
                client.on(channelSubscribeName, async function (message) {
                    const data = message;
                    //const body = message.body;
                    const loginUserId = localStorage.getItem('ownerId');
                    devLogger().log('Job update data received via signalR...');
                    if (methodName == 'notifyUserUpdate' && channelSubscribeName == 'Job') {
                        await state.dispatch(methodName, data);
                    }
                    else if (methodName == 'getPolyLineMarkersOnMap') {
                        const locationData = toCamelCase(JSON.parse(message.data.data));
                        locationData.forEach(async (location) => state.dispatch(methodName, location));
                    }
                });
            }
        }
    },

    fnGetChannelListSubscriptionData: function (channelSubscribeName, jobId) {
        channelList[jobId] = client.channels.get(jobId);
        channelList[jobId].subscribe(channelSubscribeName,
            function (msg) {
                channelList[msg.data.Data] = client.channels.get(msg.data.Data);
            }
        );
    }


}