import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'invoicesModule': [
        'invoiceDraftPreviewDetails',
        'invoiceJobsPreviewLoader'
    ],
    'invoicesExternalModule': [
        'invoiceLoaderExternal',
        'jobsListForInvoiceExternal',
        'noExternalListData',
        'selectedJobsForInvoiceExternal',
        'jobsForInvoiceSearchTextExternal',
        'selectedCustomerIdForInvoiceExternal',
        'customersListForInvoiceExternal',
        'customersSearchTextExternal',
        'invoiceDraftPreviewDetailsExternal',
        'anyLineItemInvalid',
        'dueDateForInvoiceExternal',
        'invoiceSelectedTabExternal',
        'draftedInvoiceIdToOpenExternal',
        'invoiceDateExternal',
        'invoiceGenerationInProgressExternal',
        'invoiceSendExternal',
        'invoiceExternalLineItems',
        'invoiceLineItemJobNumbersDistinctExternal',
        'invoiceLineItemsJobIdsExternal',
        'invoiceContactCompanyName',
        'invoiceExternalLineItemSearchText',
        'invoicesListExternal',
        'enableExternalInvoicingExternal',
        'totalExternalInvoicesCount',
        'invoicesExternalListCount',
        'invoicesExternalShowAddLineItemView',
        'invoicesExternalShowAddLineItemButton',
        'invoicesExternalInitialLineItem',
        'invoiceDraftPreviewDetailsExternalLineItems',
        'fetchingLineItems',
        'externalContactRadioGroup',
        'invoiceSearchTextExternal',
        'invoiceDateFilterApplied',
        'tempJobId',
        'customerForInvoicesList',
        'customersFilterListForInvoicesList',
        'customersFilterSearchText',
        'customersListForInvoiceExternalSize',
        'customersListForExternalInvoiceLoader',
        'invoiceStatusEnum',
        'supplierDetailsForExternalInvoice',
        'customerDetailsForExternalInvoice',
    ],
    'jobsInvoicesModule': [
        'invoiceJobReportUrls',
        'invoiceReportGenerationInProgress',
        'jobOperatorIds',
        'jobOperatorLocations',
        'invoiceReportUrlGenerationInProgress',
        'generatedFromJobDetails'
    ],
    'integrationsModule': [
        'xeroAccessState',
        'xeroAccess',
        'quickBookAccess',
        'quickbookAccessState',
        'sageAccessState',
        'sageAccess',
        'integrationType',
        'linkedContactToExternalService',
        'contacts',
        'contactSearchText',
        'loadedContacts',
        'intergrationHasData',
        'fetchingintergrationDataProgress',
        'customerLoader',
        'externalContactIdToLink',
        'allCustomersLoaded',
        'loadingIntegration',
        'tempIntegratedContacts'
    ],
    'root': [
        'noData',
        'filtersApplied',
        'maxCompanyNameLength',
        'listCount'
    ]
}

export default {
    name: 'InvoicesExternalComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        getSenderId() {
            return localStorage.getItem(ConstantValues.ownerId)
        },
        currencySymbol(){
            return localStorage.getItem("currencySymbol");
        },
    },
    methods: {
        isPropertyValid(property) {
            if (property) {
              if (
                property.id == null ||
                property.id == "" ||
                property.id == undefined ||
                property.id.length == 0
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
        },
        checkLineItemsValidity() {
            let isAnyLineItemInvalid = false;
      
            if(this.invoiceDraftPreviewDetailsExternalLineItems.length) {
              this.invoiceDraftPreviewDetailsExternalLineItems.map((item) => {
                if (item.item.id == null) {
                  isAnyLineItemInvalid = true;
                }
                if (!this.isPropertyValid(item.item.chartOfAccount)) {
                  isAnyLineItemInvalid = true;
                }
                if (!this.isPropertyValid(item.item.taxRate)) {
                  isAnyLineItemInvalid = true;
                }
              });
            }

            if (this.invoiceDraftPreviewDetailsExternalLineItems.length === 0)
                isAnyLineItemInvalid = true;
      
            this.anyLineItemInvalid = isAnyLineItemInvalid;
        },
    }
}