import buildUrl from "build-url";
import requests from '@/utils/requests';
import { apiAdminUsers } from "@/utils/endpoints";
import moment from "moment";
import ConstantValues from '@/plugins/constantValues';
import { preferenceQuery } from "@/utils/constants";
import { ViewPreferenceType } from "@/enum/viewPreferenceType";
import { notify } from '@/utils/helpers';
import StringConstants from '@/plugins/stringConstants';

export const actions = {

    async getSuUsersList({ commit, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions)
        rootState.listLoading = true

        const url = buildUrl(rootState.baseUrl, {
            path: apiAdminUsers + rootState.tempUrl + preferenceQuery + ViewPreferenceType.All
        })

        const result = await requests.getData(url)
        requests.handleSuperAdminForbiddenRequest(result)

        rootState.listLoading = false
        
        if(result) {
            rootState.listCount = result.data.value.length
            rootState.totalRowCount = result.data.size
            rootState.offset = result.data.offset
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.data.value)
            }
            commit('setSuUsersList', result?.data)
        }
        return result
    },

    async deleteSuUser({ rootState }, userId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiAdminUsers + '/' + userId
        });

        const result = await requests.deleteData(url);

        if (result?.status === 200) {
            notify(StringConstants.userDeleteSuccess, 'success');
            return true;
        }

        return false;
    },

    async setSuUsersListFilter({ commit, state }, data) {
        state.usersSearchText && commit('setFilterOptions', ['SearchText', state.usersSearchText])
        state.usersListRoles !== null && commit('setFilterOptions', ['Roles', state.usersListRoles.toLowerCase().replace(/ /g,'')])
        if(state.usersListStartDate && state.usersListEndDate) {
            commit('setFilterOptions', ['Start', moment(state.usersListStartDate).utc().format()])
            commit('setFilterOptions', ['End', moment(state.usersListEndDate).utc().format()])
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]])
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]])
    },

}