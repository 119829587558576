import $ from 'jquery';
import {  apiJobs } from '@/utils/endpoints';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import loadGoogleMapsApi from 'load-google-maps-api';
import Vue from 'vue';
import FieldLabelComponent from '@/components/shared/field-label.vue';
import { devLogger } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';

const fieldLabelConstructor = Vue.extend(FieldLabelComponent);

export const actions = {
    async getInProgressJobsForFields({  rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '?Statuses=2,3,4'
        });
        const result = await requests.getData(url)
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length > 0) {
                    commit('setInProgressJobsFields', result.data.value)
                    return true
                }
            }
        } else {
            devLogger().log(result)
            return false
        }
    },

    async getGoogleMapForInprogressJobs({ state, dispatch }, data) {
        state.jobsFieldsMap = null;
        $('#' + data).html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(async () => {
            const mapZoom = ConstantValues.defaultMapZoom;
            const { google } = window;

            const mapLat = localStorage.getItem('defaultStartingLat') ? parseFloat(localStorage.getItem('defaultStartingLat')) : ConstantValues.defaultBusinessLatitude;
            const mapLng = localStorage.getItem('defaultStartingLng') ? parseFloat(localStorage.getItem('defaultStartingLng')) : ConstantValues.defaultBusinessLongitude;

            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: { lat: mapLat, lng: mapLng },
                mapTypeControl: true,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.jobsFieldsMap = new google.maps.Map(document.getElementById(data), mapOptions);

            state.fieldInfowindow = new google.maps.InfoWindow();

            let tempFields = [];

            function localClearTempFields() {
                tempFields = []
            }

            if (state.jobsInProgressFields.length > 0) {
                state.jobsInProgressFields.map(field => {
                    if(field.fields.length > 0){
                        field.fields.map(innerField => {
                            const fieldInfo = {
                                field: innerField,
                                jobNumber: field.jobNumber ? field.jobNumber : null,
                                customer: field.customer ? field.customer : null,
                                operation: field.operation ? field.operation : null,
                                ownerId: field.ownerId ? field.ownerId : null,
                                jobId: field.jobId ? field.jobId : null,
                            }
                            tempFields.push(fieldInfo)
                        })
                    }
                })

                if (tempFields && tempFields.length > 0 && state.jobsInProgressFields && state.jobsInProgressFields.length > 0){
                    await dispatch('getJobsFieldsOnMap', tempFields);
                    await dispatch('setMapBoundsForJobsFields', tempFields);
                    await dispatch('setJobsFieldsLabels', tempFields);
                } else {
                    console.error('No fields found for in-progress jobs')
                }
                localClearTempFields()
            }
        });
    },

    async getJobsFieldsOnMap({ state }, data) {

        try {
            const fields = data.map(fieldInfo => fieldInfo.field);
            const fieldInfos = data.map(fieldInfo => {
                return {
                    customer: fieldInfo.customer,
                    jobNumber: fieldInfo.jobNumber,
                    operation: fieldInfo.operation,
                    ownerId: fieldInfo.ownerId,
                    jobId: fieldInfo.jobId,
                    fieldName: fieldInfo.field.title,
                }
            });
            for (let i = 0; i < fields.length; i++) {
                if (fields[i].polygon.length > 0) {
                    const polygonLatLng = [];
                    let cords = null;
                    let cordColor = null;
                    cords = fields[i].polygon;

                    for (let i = 0; i < cords.length; i++) {
                        const x = {
                            lat: cords[i].latitude,
                            lng: cords[i].longitude
                        }
                        polygonLatLng.push(x);
                    }

                    cordColor = fields[i].colour;

                    const PolygonShape = new google.maps.Polygon({
                        paths: polygonLatLng,
                        strokeColor: cordColor,
                        map: state.jobsFieldsMap,
                        strokeWeight: 3,
                        fillColor: cordColor,
                        fillOpacity: 0.2,
                    });

                    const jobUrl = `/jobdetails/${fieldInfos[i].jobId}/${fieldInfos[i].ownerId}`;

                    const contentString = 
                    '<div style="max-width:400px">' +
                        '<div>' +
                          '<div>' +
                            `<div class='info-ln-ht-jb'><strong><a target="_blank" class='info-ln-jb' href='${jobUrl}'>${fieldInfos[i].jobNumber}</a></strong></div>` +
                            `<div class='info-ln-ht'><strong>Operation:</strong> ${fieldInfos[i].operation}</div>` +
                            `<div class='info-ln-ht'><strong>Customer:</strong> ${fieldInfos[i].customer}</div>` +
                            `<div class='info-ln-ht'><strong>Field:</strong> ${fieldInfos[i].fieldName}</div>` +
                          '</div>' +
                        '</div>' +
                    '</div>';

                    google.maps.event.addListener(PolygonShape, 'click', function (event) {
                        state.fieldInfowindow.setContent(contentString);
                        state.fieldInfowindow.setPosition(event.latLng);
                        state.fieldInfowindow.open(state.jobsFieldsMap, this);
                    });

                    state.fieldShapes.push(PolygonShape);
                    state.fieldShapes[state.fieldShapes.length - 1].setMap(state.jobsFieldsMap);
                }
            }
        } catch (e){
            console.error(e.toString())
        }
    },

    async setMapBoundsForJobsFields({ state }, data) {
        const fields = data.map(fieldInfo => fieldInfo.field);

        const boundCords = [];
        const lats = [];
        const lngs = [];
        let sumLat = 0;
        let sumLng = 0;

        for (let i = 0; i < fields.length; i++) {
            if (fields[i].polygon.length > 0) {
                for (let j = 0; j < fields[i].polygon.length; j++) {
                    const x = {
                        lat: fields[i].polygon[j].latitude,
                        lng: fields[i].polygon[j].longitude
                    }
                    boundCords.push(x);
                }
            }
        }
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < boundCords.length; i++) {
            lats.push(boundCords[i].lat);
            sumLat += lats[i];
            lngs.push(boundCords[i].lng);
            sumLng += lngs[i];

            const LatLng = new google.maps.LatLng({ "lat": parseFloat(boundCords[i].lat), "lng": parseFloat(boundCords[i].lng) });
            bounds.extend(LatLng);
        }
        state.jobsFieldsMap.fitBounds(bounds);


        const listener = google.maps.event.addListener(state.jobsFieldsMap, "idle", function () {
            state.jobsFieldsMap.fitBounds(bounds, ConstantValues.defaultMapPadding);
            google.maps.event.removeListener(listener);
        });
    },

    async setJobsFieldsLabels({state}, data) {
        try {
            if(data){
                if (state.jobsFieldsMapsLabels.length > 0 && state.jobsFieldsMap) {
                    for (let i = 0; i < state.jobsFieldsMapsLabels.length; i++) {
                        state.jobsFieldsMapsLabels[i].setMap(null);
                    }
                }
                for (let i = 0; i < data.length; i++) {
                    const tempBounds = new google.maps.LatLngBounds();

                    if (data[i].field.polygon.length > 0) {
                        const fieldName = data[i].jobNumber + ' - ' + data[i].field.title;

                        const fieldLabelComponent = new fieldLabelConstructor({ propsData: { fieldName } });

                        fieldLabelComponent.$mount();

                        const fieldLabelString = new XMLSerializer().serializeToString(fieldLabelComponent.$el);

                        const fieldLabelUrl = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(fieldLabelString);

                        for (let j = 0; j < data[i].field.polygon.length; j++) {
                            const x = {
                                lat: data[i].field.polygon[j].latitude,
                                lng: data[i].field.polygon[j].longitude
                            }
                            const BoundLatLng = new google.maps.LatLng({ "lat": parseFloat(x.lat), "lng": parseFloat(x.lng) });
                            tempBounds.extend(BoundLatLng);
                        }
                        const centroid = tempBounds.getCenter();

                        const markerLabel = new google.maps.Marker({
                            position: centroid,
                            icon: fieldLabelUrl
                        });

                        const contentString =
                            '<div style="max-width:400px">' +
                            '<div>' +
                            '<div>' +
                                'Test window' +
                            '</div>' +
                            '</div>' +
                            '</div>';

                        google.maps.event.addListener(markerLabel, 'click', function (event) {
                            state.fieldInfowindow.setContent(contentString);
                            state.fieldInfowindow.setPosition(event.latLng);
                            state.fieldInfowindow.open(state.jobsFieldsMap, this);
                        });

                        state.jobsFieldsMapsLabels.push(markerLabel);
                    }
                }

                if (state.jobsFieldsMapsLabels.length > 0) {
                    for (let i = 0; i < state.jobsFieldsMapsLabels.length; i++) {
                        state.jobsFieldsMapsLabels[i].setMap(state.jobsFieldsMap);
                    }
                }
            }
        }
        catch(e) {
            devLogger().logError(e)
        }
    },
    
    async clearJobsFieldsLabelsAndFields({state}) {
        if (state.fieldShapes.length > 0) {
            for (let i = 0; i < state.fieldShapes.length; i++) {
                state.fieldShapes[i].setMap(null);
            }
        }
        if (state.jobsFieldsMapsLabels.length > 0) {
            for (let i = 0; i < state.jobsFieldsMapsLabels.length; i++) {
                state.jobsFieldsMapsLabels[i].setMap(null);
            }
        } 
        state.fieldShapes = [];
        state.jobsFieldsMapsLabels = [];
        state.jobsFieldsMap = null;
        state.jobsInProgressFields = [];
    }
}