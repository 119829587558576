<template>
    <div class="border-radius-3 bg-white">
        <div class="pL-6 pR-24 mT-none mB-none md-size-100 field-search-container"
                style="top: 10px !important; width: 375px !important">
            <v-icon color="black" class="pt-1 pr-2"> mdi-magnify </v-icon>
            <input type="text"
                    v-model="onboardMapSearchValue"
                    :placeholder="$stringConstants('accountMapSearchPlaceholder')"
                    class="input-search fm-light fc-grey fs-16 on-map-input"
                    id="searchFields"
                    autocomplete="off"
                    v-on:keyup.enter="searchPlaces" />
        </div>
    </div>
</template>
<script>
    import onboardComputedMixin from "@/components/onboard/_onboard-computed";
    import { mapActions } from "vuex";
    import { notify } from "@/utils/helpers";
    import {
        isValidLatitude,
        isValidLongitude,
    } from "@/utils/helpers/gmaps-helpers";

    export default {
        mixins: [onboardComputedMixin],
        methods: {
            ...mapActions({
                locateFieldAsync: "locateFieldReadOnly",
            }),
            isStringNumeric(stringValue) {
                if (typeof stringValue != "string") return false;
                return !isNaN(stringValue) && !isNaN(parseFloat(stringValue));
            },
            intiatePlacesSearch(latitude, longitude) {
                this.$store.dispatch("navigateToCoordinates", [latitude, longitude]);
            },
            requestPlacesSearchAPI() {
                this.$store.dispatch("requestGooglePlacesSearch");
            },
            searchPlaces() {
                if (
                    this.onboardMapSearchValue != "" &&
                    this.onboardMapSearchValue != null
                ) {
                    if (
                        this.onboardMapSearchValue &&
                        this.onboardMapSearchValue.includes(",")
                    ) {
                        const searchInputList = this.onboardMapSearchValue.split(",");
                        const searchInputHasCoordinates =
                            searchInputList.length == 2 &&
                            this.isStringNumeric(searchInputList[0]) &&
                            this.isStringNumeric(searchInputList[1]);

                        if (searchInputHasCoordinates) {
                            if (
                                isValidLatitude(searchInputList[0]) &&
                                isValidLongitude(searchInputList[1])
                            ) {
                                this.intiatePlacesSearch(searchInputList[0], searchInputList[1]);
                            } else {
                                notify(this.$stringConstants('onboardInvalidCoordinate'), "fail");
                            }
                        } else {
                            this.requestPlacesSearchAPI();
                        }
                    } else {
                        this.requestPlacesSearchAPI();
                    }
                }
            },
        },
    };
</script>
<style scoped>
.on-map-box{
    top: 55px !important;
    width: 375px !important
}
.on-map-input{
    width: 100%; transform: translate(31px, -36px)
}
</style>