


















import { closeOverlay } from "@/utils/helpers";
import ImportData from "@/components/shared/import/import-csv-list.vue";
import { mapMutations } from "vuex";
import machinesComputedMixin from "@/components/machines/machines-computed";
import { ImportResourceType } from "@/enum/importResourceType";
import { MachineType } from "@/enum/machineType";

export default {
    name: "MachinesImportForm",
    mixins: [machinesComputedMixin],
    components: {
        ImportData,
    },
    computed: {
        getMachineTypeName() {
            return this.machineType === MachineType.Vehicle ? ' vehicles' : 'implements'
        },

        getImportActionName() {
            return this.machineType === MachineType.Vehicle ? 'importVehiclesExcel' : 'importImplementsExcel'
        },

        getUploadNotes() {
            const uploadNotes = [
                this.$stringConstants('uploadNotesDeleteColumnWarning'),
                this.$stringConstants('uploadNotesCommaWarning')
            ]

            let requiredColumnsText = 'Required columns: '

            if(this.machineType === MachineType.Vehicle) {
                requiredColumnsText += 'name, make, model and registration.'
            } else if (this.machineType === MachineType.Implement) {
                requiredColumnsText += 'name, make and model.'
            }

            uploadNotes.push(requiredColumnsText)

            return uploadNotes
        },

        getImportResourceType() {
            return this.machineType === MachineType.Vehicle ? MachineType.Vehicle : MachineType.Implement
        },

        getRequiredImportFields() {
            let requiredImportFields = ['name', 'make', 'model']

            return this.machineType === MachineType.Vehicle 
            ? requiredImportFields.concat('registration') 
            : requiredImportFields
        },

        getMachinesSampleData() {
            const machineInfo = {
                titleField: JSON.parse('"name*"'),
                makeField: JSON.parse('"make*"'),
                modelField: JSON.parse('"model*"'),
                registrationField: JSON.parse('"registration*"'),
                widthField: JSON.parse('"width_in_metres"'),
                lengthField: JSON.parse('"length_in_metres"'),
                weightField: JSON.parse('"weight_in_kg"')
            }

            let machineObj = {}

            machineObj[machineInfo.titleField] = '';
            machineObj[machineInfo.makeField] = '';
            machineObj[machineInfo.modelField] = '';
            machineObj[machineInfo.registrationField] = '';
            machineObj[machineInfo.widthField] = '';
            machineObj[machineInfo.lengthField] = '';
            machineObj[machineInfo.weightField] = '';

            if(this.machineType === MachineType.Implement) {
                delete machineObj['registration*']
            }

            return [machineObj]
        }
    },
    methods: {
        ...mapMutations({
            clearMachinesFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
            resetImportForm: "resetImportDataDetails"
        }),
        closeImportMachinesForm() {
            closeOverlay("importMachinesFormOverlay");
        },
        refreshMachinesList() {
            this.resetPaging()
            this.clearMachinesFilterOption()
            this.$store.commit('setClearMachinesList')
            this.$store.dispatch('getMachineListFiltered')
        }

    },
};
