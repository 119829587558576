


















import { mapState } from "vuex";
import EllipsisMiniLoader from "./loaders/ellipsis-loader-mini.vue";
import PaginationModule from "@/store/modules/pagination";
import ConstantValues from '@/plugins/constantValues';

export default {
  name: "LoadMoreItem",
  props: ["searchText", "loadedListLength", "moduleNamespace"],
  components: {
    EllipsisMiniLoader,
  },

  computed: {
    ...mapState({
      moduleState(state) {
        return state[this.moduleNamespace];
      },
      hasMoreData(state, getters) {
        return getters[`${this.moduleNamespace}/showLoadMoreButtonGetter`]
      }
    }),
    showMiniLoader() {
      return (
        this.moduleState.loading &&
        (this.loadedListLength < this.moduleState.totalRowCount ||
          this.moduleState.totalRowCount == ConstantValues.defaultPageTotalRowCount)
      );
    },
  },
  created() {
			this.$emit("onMounted");
  },
  beforeDestroy() {
    this.unregisterFilterPaginationModules();
  },
  methods: {
    handleClick() {
      this.$store.commit(`${this.moduleNamespace}/fetchingData`);
      this.$emit("loadMoreItems");
    },
    registerFilterPaginationModules() {
      if(!this.$store.hasModule(this.moduleNamespace)) {
        this.$store.registerModule(this.moduleNamespace, PaginationModule);
      }
    },

    unregisterFilterPaginationModules() {
      if(this.$store.hasModule(this.moduleNamespace)) {
        this.$store.unregisterModule(this.moduleNamespace);
      }
    },
  },
};
