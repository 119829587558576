import { FeatureType } from "./featureType";

export enum UserRoleType {
    Contractor = 0,
    Farmer = 1,
    Manager = 2,
    TeamLead = 3,
    Operator = 4,
    SuperAdmin = 5,
}

export const UserRoleTypeLabel = new Map<number, string>([
    [UserRoleType.Contractor, 'Contractor'],
    [UserRoleType.Farmer, 'Farmer'],
    [UserRoleType.Manager, 'Manager'],
    [UserRoleType.TeamLead, 'Team Lead'],
    [UserRoleType.Operator, 'Operator'],
    [UserRoleType.SuperAdmin, 'Super Admin'],
]);

export const ManagerFeatureLabels = {
    [FeatureType.Operations]: 'Full',
    [FeatureType.Fields]: 'Full',
    [FeatureType.Vehicles]: 'Full',
    [FeatureType.Implements]: 'Full',
    [FeatureType.Products]: 'Full',
    [FeatureType.CustomersContractors]: 'Full',
    [FeatureType.TeamMembers]: 'Full',
    [FeatureType.AllJobs]: 'Full',
    [FeatureType.TeamLeadOnJobs]: 'Full',
    [FeatureType.OperatorOnJobs]: 'Full',
    [FeatureType.ApproveJobsForInvoicing]: 'Full'
}

export const TeamLeadFeatureLabels = {
    [FeatureType.Operations]: 'View only*',
    [FeatureType.Fields]: 'Full',
    [FeatureType.Vehicles]: 'Full',
    [FeatureType.Implements]: 'Full',
    [FeatureType.Products]: 'Full',
    [FeatureType.CustomersContractors]: 'View only',
    [FeatureType.TeamMembers]: 'View only',
    [FeatureType.AllJobs]: 'View only',
    [FeatureType.TeamLeadOnJobs]: 'Full',
    [FeatureType.OperatorOnJobs]: 'Restrictive**',
    [FeatureType.ApproveJobsForInvoicing]: 'No'
}

export const OperatorFeatureLabels = {
    [FeatureType.Operations]: 'View only*',
    [FeatureType.Fields]: 'Full',
    [FeatureType.Vehicles]: 'Full',
    [FeatureType.Implements]: 'Full',
    [FeatureType.Products]: 'Full',
    [FeatureType.CustomersContractors]: 'View only',
    [FeatureType.TeamMembers]: 'View only',
    [FeatureType.AllJobs]: 'No',
    [FeatureType.TeamLeadOnJobs]: 'Full',
    [FeatureType.OperatorOnJobs]: 'Restrictive**',
    [FeatureType.ApproveJobsForInvoicing]: 'No'
}