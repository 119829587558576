


































import businessesComputedMixin from "./su-businesses-computed";

export default {
    name: "BusinessesDetailsTable",
    mixins: [businessesComputedMixin],
    props: [
        'tableName',
        'noOfRows',
        'headerData',
        'rowData',
    ],
};
