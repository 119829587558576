





























import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import MapDialogBox from '@/components/shared/dialogs/map-dialog.vue';
import { getConvertedAreaUnit } from '@/utils/helpers';

export default {
    name: 'FieldDetailsView',
    mixins: [contactComputedMixin],
    components: {
        SectionLeftHeadings,
        MapDialogBox
    },
    data() {
        return {
            mapDialog: false
        }
    },
    methods: {
        getAreaFormatted(fieldArea: any) {
            return getConvertedAreaUnit(fieldArea);
        },
        openContactFieldsOnMap() {
            this.mapDialog = true;
            this.$store.dispatch('getAllContactFieldOnMap', this.contactFieldList);
        },
        closeMapDialogBox() {
            this.mapDialog = false;
        }
    }
}
