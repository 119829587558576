import { ITeamJobOperationBillingQuantity } from '../interfaces/ITeamJobOperationBillingQuantity';

export class TeamJobOperationBillingQuantity implements ITeamJobOperationBillingQuantity {

    fieldId?: string;
    billingQuantity: number;

    constructor() {
        this.fieldId = null;
        this.billingQuantity = 0;
        return this;
    }

    public getTeamJobOperationBillingQuantity(teamJobOperationBillingQuantity: TeamJobOperationBillingQuantity) {
        this.fieldId = teamJobOperationBillingQuantity.fieldId ? teamJobOperationBillingQuantity.fieldId : null;
        this.billingQuantity = teamJobOperationBillingQuantity.billingQuantity ? teamJobOperationBillingQuantity.billingQuantity : 0;
        return this;
    }
}