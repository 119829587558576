import moment from 'moment';
import { Timesheet } from '@/data/models/Timesheet';
import { User } from '@/data/models/User';
import { TimesheetStatistics } from '@/data/models/TimesheetStatistics';
import { TimeSheetType } from '@/enum/timeSheetType';
import { UserRoleType } from '@/enum/userRoleType';
import { getZeroPadding, listContainsItem } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
export const state = {
    timesheetsList: new Array<Timesheet>(),
    timesheetsMembersList: new Array<User>(),
    timesheetsLoader: false,
    timesheetsSearchText: '',
    timesheetMembersSearchText: '',
    timesheetsTypeList: [
        { title: "Holiday", value: TimeSheetType.Holiday },
        { title: "Sick", value: TimeSheetType.Sick },
        { title: "Working", value: TimeSheetType.Working }
    ],
    timesheetsFilterTypeList: [
        { title: "All", value: null },
        { title: "Holiday", value: TimeSheetType.Holiday },
        { title: "Sick", value: TimeSheetType.Sick },
        { title: "Working", value: TimeSheetType.Working }
    ],
    memberDefaultWorkStartTime: null,
    memberDefaultWorkEndTime: null,
    timesheetDetails: new Timesheet(),
    addTimesheetLoader: false,
    editTimesheetLoader: false,
    timesheetsStatistics: new TimesheetStatistics(),
    timesheetListFilterType: TimeSheetType.Working,
    timesheetsFilterStartDate: null,
    timesheetsFilterEndDate: null,
    timesheetDateFilterApplied: false,
    timesheetsSelectedMemberId: null,
    timesheetsFilterLoader: false,
    timesheetDataListOffset: 0,
    timesheetDataListTotalCount: 0,
    timesheetDataListLoadedCount: 0,
    timesheetDataListForExport: new Array<Timesheet>()
}

export const getters = {
    timesheetsList: state => state.timesheetsList,
    timesheetsLoader: state => state.timesheetsLoader,
    timesheetsSearchText: state => state.timesheetsSearchText,
    timesheetMembersSearchText: state => state.timesheetMembersSearchText,
    timesheetsMembersList: state => state.timesheetsMembersList,
    timesheetsTypeList: state => state.timesheetsTypeList,
    timesheetDetails: state => state.timesheetDetails,
    memberDefaultWorkStartTime: state => state.memberDefaultWorkStartTime,
    memberDefaultWorkEndTime: state => state.memberDefaultWorkEndTime,
    addTimesheetLoader: state => state.addTimesheetLoader,
    editTimesheetLoader: state => state.editTimesheetLoader,
    timesheetsStatistics: state => state.timesheetsStatistics,
    timesheetListFilterType: state => state.timesheetListFilterType,
    timesheetsFilterTypeList: state => state.timesheetsFilterTypeList,
    timesheetsFilterStartDate: state => state.timesheetsFilterStartDate,
    timesheetsFilterEndDate: state => state.timesheetsFilterEndDate,
    timesheetDateFilterApplied: state => state.timesheetDateFilterApplied,
    timesheetsSelectedMemberId: state => state.timesheetsSelectedMemberId,
    timesheetsFilterLoader: state => state.timesheetsFilterLoader,
    timesheetDataListOffset: state => state.timesheetDataListOffset,
    timesheetDataListTotalCount: state => state.timesheetDataListTotalCount,
    timesheetDataListLoadedCount: state => state.timesheetDataListLoadedCount,
    timesheetDataListForExport: state => state.timesheetDataListForExport
}

export const mutations = {
    setTimesheetsList(state, data) {
        data.map((data) => state.timesheetsList.push(data));
    },
    setDefaultMember(state, data) {
        state.timesheetsMembersList = [];

        let owner = null;
        if (data[1] == UserRoleType.Manager) {
            owner = {
                name: (localStorage.getItem('fullOwnerBusinessName')) ? (localStorage.getItem('fullOwnerBusinessName') + ' (owner)') : 'Owner',
                id: localStorage.getItem(ConstantValues.ownerId)
            }
        } else {
            owner = {
                name: data[0] + '(You)',
                id: localStorage.getItem(ConstantValues.ownerId)
            }
        }

        state.timesheetsMembersList.push(owner);
    },
    setTimesheetsMembersList(state, data) {
        data.map(timeSheetMember => {
            if (timeSheetMember) {
                const member = {
                    name: (localStorage.getItem('userId') == timeSheetMember.id) ?
                        (timeSheetMember.firstName + ' (You)') :
                        (timeSheetMember.firstName +
                            (timeSheetMember.lastName ? (' ' + timeSheetMember.lastName) : '')),
                    id: timeSheetMember.id
                }
                if (!listContainsItem(state.timesheetsMembersList, ['id'], member.id)) {
                     state.timesheetsMembersList.push(member);
                }
            }
        });
    },
    setTimesheetsStatistics(state, data) {
        state.timesheetsStatistics = new TimesheetStatistics().getTimesheetStatistics(data);
    },
    setUserWorkTimeForTimeSheet(state, data) {
        const crDate = moment().format('YYYY-MM-DD');
        const stTime = moment.utc(data.settings.workingStartTime).format("HH:mm:ss");
        const etTime = moment.utc(data.settings.workingEndTime).format("HH:mm:ss");
        state.memberDefaultWorkStartTime = moment.utc(crDate + " " + stTime).local().format("HH:mm");
        state.memberDefaultWorkEndTime = moment.utc(crDate + " " + etTime).local().format("HH:mm");
    },
    setTimesheetDetails(state, data) {
        state.timesheetDetails = new Timesheet().getTimesheet(data);
    },
    setTimesheetDataListForExport(state, data) {
        function getHoursAndMinsRounded(durationInSeconds) {
            const durationInMinutes = durationInSeconds / 60;
            let hours = (durationInMinutes / 60);
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            
            const h = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;
            const m = (Math.round(minutes/15) * 15) % 60;

            return `${parseInt(h.toString())}:${m.toString()}`;
        }

        function getHoursAndMinsRoundedPayroll(durationInSeconds) {

            function getMinutesPayroll(minutes) {
                const minutesInt = parseInt(minutes);
                let payrollMinsInt = 0;

                if (minutesInt > 0 && minutesInt <= 15) {
                    payrollMinsInt = 25;
                } else if (minutesInt >= 16 && minutesInt <= 30) {
                    payrollMinsInt = 50;
                } else if (minutesInt >= 31 && minutesInt <= 45) {
                    payrollMinsInt = 75;
                }

                return payrollMinsInt;
            }

            const durationInMinutes = durationInSeconds / 60;
            let hours = (durationInMinutes / 60);
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            
            const h = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;
            const m = (Math.round(minutes/15) * 15) % 60;
            const mPayroll = getMinutesPayroll(m);

            return `${parseInt(h.toString())}.${mPayroll.toString()}`;
        }

        function zeroPad(num, places) {
            return getZeroPadding(num, places);
        }

        function getHoursAndMinsActual(durationInSeconds) {
            const h = durationInSeconds/60/60;
            const hours = parseInt(h.toString());
            const mins = (h - hours)*60;
            return `${parseInt(hours.toString())}:${zeroPad(Math.round(parseFloat(mins.toString())), 2)}`;
        }

        function getDateTimeLocal(dateTime) {
            return dateTime ? moment.utc(dateTime).local().format('DD-MMM-YYYY H:mm') : ''
        }

        data.map((timesheet) => {
            const reportObj = {
                name: timesheet.name,
                start_time: getDateTimeLocal(timesheet.startTime),
                end_time: getDateTimeLocal(timesheet.endTime),
                type: state.timesheetsTypeList[state.timesheetsTypeList.findIndex(ts => ts.value == timesheet.type)].title,

                work_time_in_mins: (timesheet.type == TimeSheetType.Working) ? parseFloat((timesheet.workTimeInMinutes).toString()).toFixed(2) : '',
                work_time_in_hours: (timesheet.type == TimeSheetType.Working) ? getHoursAndMinsActual(timesheet.workTimeInMinutes*60) : '',
                work_time_in_hours_rounded: (timesheet.type == TimeSheetType.Working) ? getHoursAndMinsRounded(timesheet.workTimeInMinutes*60) : '',
                work_time_in_hours_payroll: (timesheet.type == TimeSheetType.Working) ? getHoursAndMinsRoundedPayroll(timesheet.workTimeInMinutes*60) : '',

                break_time_in_mins: parseFloat((timesheet.breakTimeInMinutes).toString()).toFixed(2),
                break_time_in_hours: getHoursAndMinsActual(timesheet.breakTimeInMinutes*60),
                break_time_in_hours_rounded: getHoursAndMinsRounded(timesheet.breakTimeInMinutes*60),

                travel_time_in_mins: parseFloat((timesheet.travelTimeInMinutes).toString()).toFixed(2),
                travel_time_in_hours: getHoursAndMinsActual(timesheet.travelTimeInMinutes*60),
                travel_time_in_hours_rounded: getHoursAndMinsRounded(timesheet.travelTimeInMinutes*60),

                note: timesheet.note ? timesheet.note : ''
            }
            
            state.timesheetDataListForExport.push(reportObj)
        });
        
        state.timesheetDataListLoadedCount = state.timesheetDataListForExport.length
        state.timesheetDataListOffset = state.timesheetDataListLoadedCount
    },
    resetTimesheetExportData(state) {
        state.timesheetDataListOffset = 0
        state.timesheetDataListTotalCount = 0
        state.timesheetDataListLoadedCount = 0
        state.timesheetDataListForExport = []
    }
}