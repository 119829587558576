






export default {
    name: 'SubHeaderPrimary',
    props: [
        'textValue',
    ]
}
