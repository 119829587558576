






































































import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
export default {
    name: 'ConfirmationDialogBox',
    props: {
      reasonRequired: Boolean,
      value: Boolean,
      loading: Boolean
    },
    data() {
      return {
        cancelTeamJobReason: ""
      }
    },
    components: {
        SecondaryButton
    },
    watch: {
      value(newValue) {
        if (!newValue) {
          this.cancelTeamJobReason = "";
        }
      }
    }
}
