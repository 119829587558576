




































































































import ListLoader from "@/components/shared/loaders/list-loader.vue";
import { mapMutations } from "vuex";
import moment from "moment";
import invoicesExternalComputed from "@/components/invoices-external/invoices-external-computed";
import { getAssignedUserNameFromJob } from "@/utils/helpers/jobs-helpers";
import { getListLengthAbsolute } from '@/utils/helpers';
import LoadMoreItem from "@/components/shared/load-more-item.vue";
import List from "@/components/shared/list.vue";
import PaginationModule from "@/store/modules/pagination";
import ConstantValues from '@/plugins/constantValues';

export default {
  name: "JobsListForInvoiceExternal",
  props: ['stepValue'],
  data() {
    return {
      tempCustomerId: "",
    };
  },
  mixins: [invoicesExternalComputed],
  components: {
    LoadMoreItem,
    List
  },
  watch: {
    selectedCustomerIdForInvoiceExternal(newVal) {
      if (newVal == null) {
        this.tempCustomerId = "";
        this.selectedJobsForInvoiceExternal = [];
      } else {
        this.getJobsFromCustomer(newVal);
      }
    },
  },
  created() {
    this.initializeAutocompletePaginationModule();
  },
  mounted() {
    this.setFilterOptionsAndGetJobsListForInvoiceExternal();
    this.$store.dispatch("getCustomersListForInvoiceExternal");
  },
  beforeDestroy() {
    this.$store.unregisterModule('customersDropdown')
  },
  methods: {
    ...mapMutations({
      clearCustomersFilterOption: "setClearFilterOptions",
      resetPaging: "resetPaging",
    }),
    initializeAutocompletePaginationModule() {
      this.$store.registerModule('customersDropdown', PaginationModule)
    },
    updateSelectedJobs(selectedJobs) {
      const newVal = selectedJobs[0];
      if (
        newVal &&
        newVal.length != 0 &&
        newVal.customer.id != this.getSenderId &&
        this.tempCustomerId != newVal.customer.id &&
        this.selectedCustomerIdForInvoiceExternal == null
      ) {
        const jobOwnerId = (this.tempCustomerId = newVal.customer.id);

        if (jobOwnerId) {
          this.tempJobId = selectedJobs[0].id;
          this.jobsListForInvoiceExternal = [];
          const customer = newVal.customer;
          if (customer.businessName) {
            customer["displayName"] = customer.businessName;
          } else {
            customer["displayName"] = customer.name;
          }
          this.customersListForInvoiceExternal.push(customer);
          this.selectedCustomerIdForInvoiceExternal = jobOwnerId;
        }
      }
    },
    clearSelectedJobs() {
      this.invoiceLoaderExternal = true;
      this.selectedJobsForInvoiceExternal = [];
      this.invoiceLoaderExternal = false;
    },
    getJobsListFromCustomerId() {
      if (!this.selectedCustomerIdForInvoiceExternal) {
          this.clearCustomersFilterOption();
          this.resetPaging();
          this.jobsListForInvoiceExternal = [];
          this.setFilterOptionsAndGetJobsListForInvoiceExternal();
      }
    },
    applySearchCustomersFilter() {
        if (this.searchTimerCustomers) {
            clearTimeout(this.searchTimerCustomers);
            this.searchTimerCustomers = null;
        }
        this.searchTimerCustomers = setTimeout(() => {
            this.applySearchFilters();
        }, ConstantValues.defaultDropdownSearchTimeout); 
    },
    applySearchFilters() {
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.$store
        .dispatch("setCustomersFilterExternal", [
          ConstantValues.defaultPageOffsetSize,
          ConstantValues.defaultPageLimitSize,
        ])
        .then(() => {
          this.$store.dispatch("getCustomersListForInvoiceExternal");
          if (this.customersSearchTextExternal == "") {
            this.clearCustomersFilterOption();
          }
        });
    },
    getJobsFromCustomer(customerIdForJobs: string) {
      if (customerIdForJobs) {
        this.jobsListForInvoiceExternal = [];
        this.clearCustomersFilterOption();
        this.resetPaging();
        this.setFilterOptionsAndGetJobsListForInvoiceExternal()
        .then(res => {
          this.clearSelectedJobs();
          if(this.jobsListForInvoiceExternal.length) {
              this.selectedJobsForInvoiceExternal = [];
            const previousSelectedJob = this.jobsListForInvoiceExternal.find(job => job.id == this.tempJobId)
            if(previousSelectedJob) {
              this.selectedJobsForInvoiceExternal.push(previousSelectedJob);           
            }
          }
        });
      }
    },
    getDateTimeFormat(date) {
      return date ? moment.utc(date).local().format("DD MMM YYYY") : "-";
    },
    applySearchJobsListFilter() {
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.jobsListForInvoiceExternal = [];
      this.$store.dispatch("setJobsForInvoiceFilterExternal").then((res) => {
        this.$store
          .dispatch(
            "getJobsListForInvoiceExternal",
            this.selectedCustomerIdForInvoiceExternal
          )
          .then(() => {
            this.invoiceLoaderExternal = false;
          });
        if (this.jobsForInvoiceSearchTextExternal == "") {
          this.clearCustomersFilterOption();
        }
      });
      this.setFilterOptionsAndGetJobsListForInvoiceExternal();
    },
    getAssignedUserName(job: any) {
      return getAssignedUserNameFromJob(job);
    },
    getJobNumberPrefix(jobNumber: string) {
      if (jobNumber.split("#").length < 2) { 
        return "#" + jobNumber;
      }
      return jobNumber.split("#")[0];
    },
    getJobNumberSuffix(jobNumber: string) {
      if (jobNumber.split("#").length == 2) {
        return "#" + jobNumber.split("#")[1];
      }
    },
    appendMoreCustomers() {
      this.customersSearchTextExternal = '';
      this.clearCustomersFilterOption();
      this.resetPaging();
      this.$store.commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
      this.$store.commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(this.customersListForInvoiceExternal)]);
      this.$store.dispatch("getCustomersListForInvoiceExternal");
      if (this.customersSearchTextExternal == "") {
        this.clearCustomersFilterOption();
      }
    },
    async setFilterOptionsAndGetJobsListForInvoiceExternal(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
      return await this.$store.dispatch('setJobsForInvoiceFilterExternal', filters)
      .then(() => {
        const response = this.$store.dispatch('getJobsListForInvoiceExternal');
        if (this.jobsForInvoiceSearchTextExternal == "") {
          this.filtersApplied = false
        }

        return response
      });

    }
  },
};
