export class TeamJobMetaData {
    id?: string;
    title: string;
    value?: string;
    unit: string;

    //constructor(metaData?: TeamJobMetaData) {
    //    this.id = metaData ? metaData.id : null;
    //    this.title = metaData ? metaData.title : null;
    //    this.value = metaData ? metaData.value : null;
    //    this.unit = metaData ? metaData.unit : null;
    //}

    public getTeamJobMetaData?(metaData: TeamJobMetaData) {
        this.id = metaData.id ? metaData.id : null;
        this.title = metaData.title ? metaData.title : null;
        this.unit = metaData.unit ? metaData.unit : null;
        this.value = metaData.value ? metaData.value : null;
        return this;

    }
}