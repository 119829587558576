







export default {
    name: 'SectionLeftHeadings',
    props: [
        'textValue1',
        'textValue2',
    ]
}
