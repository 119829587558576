















import $ from 'jquery';
import TeamMembersListView from '../team-members/team-members-list.vue';
import TeamMembersAddForm from '../team-members/team-members-add-form.vue';
import TeamMembersEditForm from '../team-members/team-members-edit-form.vue';
import { closeOverlay } from '@/utils/helpers';
import permissionsMixin from '@/mixins/permissions-mixin';

export default {
    mixins: [permissionsMixin],
    components: {
        TeamMembersListView,
        TeamMembersAddForm,
        TeamMembersEditForm
    },
    mounted() {
        this.$store.state.dashboardTitle = this.$stringConstants('teamMembersTitle');
        $('body').on('click', '#overlay', function () {
            closeOverlay("addTeamMemberFormOverlay");
            closeOverlay("editTeamMemberFormOverlay");
        });
    }
}
