









export default {
  name: "TeamJobsAttachmentsPdf",
  props: ["fileUrl"],
  methods: {
    openFileInNewTab() {
      if (this.fileUrl) window.open(this.fileUrl, "_blank");
    },
  },
};
