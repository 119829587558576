
































import { generateMonthlyDataByYear } from '@/utils/helpers';
import { GChart } from 'vue-google-charts';
import businessesComputedMixin from "./su-businesses-computed";
import { initialMonthlySubscriptionsChartData } from '@/utils/constants'

export default {
    name: "BusinessesDetailsTable",
    mixins: [businessesComputedMixin],
    data() {
        return {
            invoiceAmountList: [],
            selectedYear: new Date().getFullYear(),
            selectedYearListData: [],
            chartData: initialMonthlySubscriptionsChartData,
        }
    },
    props: [
        'rowData'
    ],
    components: {
        GChart
    },
    computed: {
        subscriptions() {
                return this.businessesDetails.subscriptions
        },
        yearsList() {
            if(this.subscriptions?.length > 0 && this.subscriptions?.[0]?.invoices?.length > 0) {
                let invoicesList = this.subscriptions?.[0]?.invoices 

                const filteredList = new Map()

                invoicesList.map(item => {
                    if(filteredList.has(item.year)) {
                        filteredList.get(item.year).push(item)
                    } else {
                        filteredList.set(item.year, [item])
                    }
                })

                let yearsListMap = filteredList.keys()
                let yearsList = Array.from(filteredList.keys())

                return yearsList
            } else {
                return []
            }
        },
    },
    mounted() {
        const businessId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
        this.$store.dispatch('getBusinessesDetails', businessId).then(() => {
            this.getChartData()      
        })
    },
    methods: {
        getChartData() {
            if(this.subscriptions?.length > 0 && this.subscriptions?.[0]?.invoices?.length > 0) {
                this.mapInvoicesListFromSubscriptions(this.subscriptions?.[0]?.invoices)

                this.chartData = generateMonthlyDataByYear(this.selectedYearListData)
            } else {
                return this.selectedYearListData
            }
        },
        mapInvoicesListFromSubscriptions(invoicesList, year = this.selectedYear) {
            const filteredList = new Map()

            invoicesList.map(item => {
                if(filteredList.has(item.year)) {
                    filteredList.get(item.year).push(item)
                } else {
                    filteredList.set(item.year, [item])
                }
            })

            this.selectedYearListData = filteredList.get(this.selectedYear)
        },
        updateChartByYear() {
            this.getChartData()
        }
    },
};
