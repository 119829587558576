





















































import { mapMutations } from 'vuex';
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import { checkFieldsValidity } from '@/utils/helpers';
import { routeCustomers } from '@/utils/endpoints';
import router from '@/router/index';
import permissionsMixin from '@/mixins/permissions-mixin';
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import ContactPersonalDetailsForm from './internal-contact-personal-details-form.vue';
import SecondaryContactDetailsForm from './internal-contact-secondary-contact-details.vue';
import ContactCompanyDetailsForm from './internal-contact-company-details.vue';
import ContactCompanyAddressForm from './internal-contact-company-address.vue';
import { ButtonActionPrimary, HoverTextButton}  from '@/components/shared/buttons/index';
import ConstantValues from '@/plugins/constantValues';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';

export default {
    mixins: [permissionsMixin, contactComputedMixin, breadcrumbsMixin],
    components: {
        ContactPersonalDetailsForm,
        SecondaryContactDetailsForm,
        ContactCompanyDetailsForm,
        ContactCompanyAddressForm,
        ListLoader,
        ButtonActionPrimary,
        HoverTextButton
    },
    data() {
        return {
            isEdiablePrefix: false
        }
    },
    computed: {
        resId() {
            return window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        },
        isFieldsValid() {
            if (checkFieldsValidity([
                this.contactProfile.firstName,
                this.contactProfile.businessProfile.title,
                this.contactProfile.businessProfile.address.postalCode,
                this.contactProfile.businessProfile.address.addressLine1,
            ])) {
                return true;
            } else {
                return false;
            }
        }
    },
    
    mounted() {
        this.resetContactProfile();
        this.$store.dispatch('getContactDetailsByAdmin', this.resId).then(res => {
            this.businessProfilePrefix = this.contactProfile.businessProfile.shortTitle;
        });
        this.$store.dispatch("getGlobalCountriesList");
        this.setBreadcrumbs('CustomerDetailsUpdate', this.resId);
    },
    
    methods: {
        ...mapMutations({
            resetContactProfile: 'resetContactProfile',
            clearFilterOptions: "setClearFilterOptions",
        }),
        updateContactProfile: function () {
            let bussinessAddress = null;
            if (this.contactProfile.businessProfile.address.addressLine1 != null && (this.contactProfile.businessProfile.address.addressLine1).trim() != "") {
                bussinessAddress = {
                    addressLine1: this.contactProfile.businessProfile.address.addressLine1,
                    addressLine2: this.contactProfile.businessProfile.address.addressLine2,
                    country: this.contactProfile.businessProfile.address.country,
                    state: this.contactProfile.businessProfile.address.state,
                    city: this.contactProfile.businessProfile.address.city,
                    postalCode: this.contactProfile.businessProfile.address.postalCode,
                    coordinate: {
                        latitude: 0,
                        longitude: 0
                    },
                }
            }
            let profileContactUpdateDto = {
                businessProfile: {
                    vatNumber: this.contactProfile.businessProfile.vatNumber,
                    address: bussinessAddress,
                    registrationNumber: this.contactProfile.businessProfile.registrationNumber,
                    title: this.contactProfile.businessProfile.title,
                    shortTitle: this.businessProfilePrefix
                },
                email: this.contactProfile.email,
                firstName: this.contactProfile.firstName,
                lastName: this.contactProfile.lastName,
                colorCode: this.contactProfile.colorCode,
                primaryContactNumber: this.contactProfile.primaryContactNumber,
                secondaryContactNumber: this.contactProfile.secondaryContactNumber,
                secondaryFirstName: this.contactProfile.secondaryFirstName,
            }
            const ids = {
                owner: localStorage.getItem(ConstantValues.ownerId),
                user: this.contactProfile.id
            }
            this.$store.dispatch('updateContactProfile', [profileContactUpdateDto, ids]).then((res) => {
                if (res == true) {
                    router.push(routeCustomers);
                }
            });
        },

        navigateToFarmerList() {
            router.push(routeCustomers);``
        }
    }
}
