import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.showAddCardSubscriptionDialog),callback:function ($$v) {_vm.showAddCardSubscriptionDialog=$$v},expression:"showAddCardSubscriptionDialog"}},[_c(VCard,[_c('h3',{staticClass:"pl-6 pt-6 pb-2"},[_vm._v(" "+_vm._s(_vm.$stringConstants('subscriptionCardAddTitle'))+" ")]),_c('div',{staticClass:"pl-6 pr-6"},[_c('div',{staticClass:"pb-4"},[_c('small',[_vm._v(" "+_vm._s(_vm.$stringConstants('subscriptionCardEnter'))+" ")])]),_c('div',{attrs:{"id":"card-element"}}),_c('div',{attrs:{"id":"card-result"}})]),_c(VCardText),_c(VCardActions,{staticClass:"pb-6 pr-6"},[_c(VSpacer),_c(VBtn,{staticClass:"btn-gray-light-bl-text f-nn",attrs:{"elevation":"0"},on:{"click":function($event){_vm.showAddCardSubscriptionDialog = false}}},[_vm._v(" "+_vm._s(_vm.$stringConstants('subscriptionCardCancel'))+" ")]),_c(VBtn,{staticClass:"btn-grn-wh-text f-nn",attrs:{"elevation":"0","id":"card-button","loading":_vm.cardsLoader},on:{"click":_vm.addCardPayment}},[_vm._v(" "+_vm._s(_vm.$stringConstants('subscriptionCardSave'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }