import Vue from "vue";
import Vuex from "vuex";
import { state, getters, mutations } from './mutations';
import { actions } from './actions';
import machinesModule from '@/store/modules/machines/index';
import productsModule from '@/store/modules/products/index';
import fieldsModule from '@/store/modules/fields/index';
import usersModule from '@/store/modules/users/index';
import teamMembersModule from '@/store/modules/team-members/index';
import farmersModule from '@/store/modules/farmers/index';
import contractorsModule from '@/store/modules/contractors/index';
import operationsModule from '@/store/modules/operations/index';
import timeSheetsModule from '@/store/modules/timesheets/index';
import teamJobsListModule from '@/store/modules/team-jobs-list/index';
import subscriptionsModule from '@/store/modules/subscriptions/index';
import invoicesModule from '@/store/modules/invoices/index';
import invoicesExternalModule from '@/store/modules/invoices-external/index';
import integrationsModule from '@/store/modules/integrations/index';
import contactsModule from '@/store/modules/internal-contact/index';
import jobsFieldsMapModule from '@/store/modules/jobs-fields-map/index';
import onboardModule from '@/store/modules/onboard/index';
import teamJobsModule from '@/store/modules/team-jobs/index';
import jobsInvoicesModule from '@/store/modules/jobs/jobs-invoices/index';
import excelExportModule from '@/store/modules/excel-export/index';
import adminOverviewModule from '@/store/modules/overview/index';
import suUsersModule from '@/store/modules/su-users/index';
import suBusinessesModule from '@/store/modules/su-businesses/index';
import jobsCalendarModule from "@/store/modules/jobs-calendar";

Vue.use(Vuex);

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules: {
        productsModule,
        fieldsModule,
        usersModule,
        teamMembersModule,
        farmersModule,
        contractorsModule,
        operationsModule,
        timeSheetsModule,
        teamJobsListModule,
        subscriptionsModule,
        invoicesModule,
        integrationsModule,
        contactsModule,
        invoicesExternalModule,
        jobsFieldsMapModule,
        onboardModule,
        machinesModule,
        teamJobsModule,
        jobsInvoicesModule,
        excelExportModule,
        adminOverviewModule,
        suUsersModule,
        suBusinessesModule,
        jobsCalendarModule
    }
});