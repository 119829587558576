import { Address } from "./Address";
import { Config } from "./Config";

export class BusinessProfile {
    
    id?: string;
    title: string;
    shortTitle: string;
    registrationNumber: string;
    vatNumber: string;
    paymentInstructions: string;
    config: Config;
    address: Address;
    trialEnd: null;

    constructor() {
        this.id = null;
        this.title = null;
        this.shortTitle = null;
        this.registrationNumber = null;
        this.vatNumber = null;
        this.paymentInstructions = null;
        this.address = new Address();
        this.config = new Config();
        this.trialEnd = null;
    }

    public getBusinessProfileDetails(business: BusinessProfile){
        this.id = business?.id || null;
        this.title = business?.title || null;
        this.shortTitle = business?.shortTitle || null;
        this.registrationNumber = business?.registrationNumber || null;
        this.vatNumber = business?.vatNumber || null;
        this.paymentInstructions = business?.paymentInstructions || null;
        this.address = business?.address ? new Address().setResponseAddress(business.address) : new Address(); 
        this.config = business?.config ? new Config().getConfigDetails(business.config) : new Config();
        this.trialEnd = business?.trialEnd || null;
        return this;
     }
}