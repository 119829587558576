
export class ResponseList<T>  {

    offset: number;
    limit: number;
    size: number;
    value: Array<T>;

    public constructor(offset: number, limit: number, size: number, data: Array<T>) {
        this.offset = offset;
        this.limit = limit;
        this.size = size;
        this.value = data;
        return this;
    }
}