





















export default {
    name: "SkeletonLoader",
    props: {
        'columnWidths': Array,
        'containsAction': Boolean,
    },
    data() {
        return {
            columnWidthsArray: [],
            actionColumnWidth: {}
        }
    },
    mounted() {
        this.columnWidthsArray = Object.create(this.columnWidths);
        this.reduceWidthsArrayIfListHasActionColumn();
    },
    methods: {
        reduceWidthsArrayIfListHasActionColumn() {
            if (this.listHasActionColumn()) {
                this.reduceColumnWidthsArrayAndAssignActionColumnWidth();
            }
        },
        listHasActionColumn() {
            if (this.containsAction) {
                return true
            }
            return false
        },
        reduceColumnWidthsArrayAndAssignActionColumnWidth() {
            this.actionColumnWidth = this.columnWidthsArray.pop();
        }
    },
}
