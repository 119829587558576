import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { TeamJobOperation } from '@/data/models/TeamJobOperation';
import { TeamJobViewPreference } from '@/data/models/TeamJobViewPreference';
import { UserRoleType } from '@/enum/userRoleType';
import { JobOwnerTypeText } from '@/enum/jobOwnerTypeText';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import { listContainsItem } from '@/utils/helpers';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { TeamJobField } from '@/data/models/TeamJobField';
import { MinimalUser } from '@/data/models/MinimalUser';
import { TeamJobAddress } from '@/data/models/TeamJobAddress';
import { TeamJobAddressSelectionType } from '@/enum/jobAddressSelectionType';
import { Machine } from '@/data/models/Machine';
import { TeamJobOperatorLocation } from '@/data/models/TeamJobOperatorLocation';
import { MachineType } from '@/enum/machineType';
import ConstantValues from '@/plugins/constantValues';

export const state = {
    teamJobsLoader: false,
    teamJobsAddLoader: false,
    teamJobViewPreference: new TeamJobViewPreference(),
    teamJobOwnerType: UserRoleType.Farmer,
    teamJobOwnerTypeText: JobOwnerTypeText,
    teamJobDetailsRealtime: null,
    teamJobDetailsUpdatedSnackbarVisible: false, 
    teamJobDisabledAllResources: false,

    //operation
    teamJobOperation: new TeamJobOperation(),
    teamJobOperationsList: [],
    teamJobOperationsSearchText: null,
    teamJobOperationOwnerId: null,
    teamJobOperationsListSize: 0,
    teamJobOperationsListLoader: false,

    //customers
    teamJobUsersList: [],
    teamJobUserSearchText: null,
    teamJobUser: null,
    teamJobUsersListSize: 0,
    teamJobUsersListLoader: false,

    //contractor
    teamJobContractorId: null,

    //generic
    teamJobScheduledDate: null,
    teamJobStatus: TeamJobStatusType.Pending,
    teamJobInstructions: null,
    teamJobAttachments: [],

    //operators
    teamJobShowJobAddOperatorExpandedView: true,
    teamJobShowOperatorSelectionDropdown: false,
    teamJobOperators: [],
    teamJobVehiclesListForOperators: [],
    teamJobImplementsListForOperators: [],
    teamJobVehiclesSearchInputListForOperators: [],
    teamJobImplementsSearchInputListForOperators: [],
    teamJobOperatorToAdd: null,
    teamJobOperatorToAddLoader: false,
    teamJobTeamMembersForOperatorsSeachText: null,
    teamJobsTeamMembersListForOperators: [],
    teamJobVehiclesSearchTextForOperators: null,
    teamJobImplementsSearchTextForOperators: null,
    teamJobVehiclesListForOperatorsSize: 0,
    teamJobImplementsListForOperatorsSize: 0,
    teamJobVehiclesListForOperatorsLoader: false,
    teamJobImplementsListForOperatorsLoader: false,

    //products
    teamJobProducts: new Array<TeamJobProduct>(),
    teamJobProductToAdd: new TeamJobProduct(),
    teamJobProductsList: [],
    teamJobProductsSearchText: null,
    teamJobProductsLoader: false,
    teamJobShowJobAddProductsExpandedView: true,
    teamJobShowJobAddProductsDropdown: false,

    //fields
    teamJobFieldsTempList: new Array<TeamJobField>(),
    teamJobFieldsList: new Array<TeamJobField>(),
    teamJobFieldsCustomerSearchText: null,
    teamJobFieldsLoaded: 0,
    teamJobFieldsTotal: 0,
    teamJobFieldsOffset: 0,
    teamJobSelectedFields: [],
    teamJobSelectedFieldsForList: [],
    teamJobPolygons: [],
    teamJobSelectedPolygon: [],
    teamJobFieldLabels: [],
    teamJobFieldCustomerId: null,
    fieldRequesterId: null,

    //meta data
    teamJobMetaDataList: [],

    //addresses
    teamJobAddresses: [],
    teamJobFarmAddressesSelected: [],
    teamJobAddressInstructions: null,
    teamJobAllAddresses: [],
    teamJobAddressSelectionType: TeamJobAddressSelectionType.FarmAddress,
    teamJobAddressesDropPinLatitude: 0,
    teamJobAddressesDropPinLongitude: 0,
    teamJobDetails: null,
    teamJobCustomAddress: new TeamJobAddress(),

    //time details
    teamJobTimeDetailsList: [],
    teamJobTempTimeDetailsList: [],

    //location map
    teamJobLocationSelectedOperatorId: null,
    selectedOperatorLocations: [],
    operatorsMarkers: [],
    operatorRealTimeLocation: [],

    //job completion notes
    teamJobNotes: null,
    attachmentDeleteInProgress: false,

    //changes State
    hasTeamJobChangesOnDetails: false,

    //job invoicing
    teamJobInvoiced: false,
    teamJobsFieldMap: null,
    polygonShapes: [],
    jobsReportApiKey: process.env.VUE_APP_JOBS_REPORT_API_KEY
}

export const getters = {
    teamJobsLoader: state => state.teamJobsLoader,
    teamJobsAddLoader: state => state.teamJobsAddLoader,
    teamJobViewPreference: state => state.teamJobViewPreference,
    teamJobOwnerType: state => state.teamJobOwnerType,
    teamJobOwnerTypeText: state => state.teamJobOwnerTypeText,
    teamJobDetailsRealtime: state => state.teamJobDetailsRealtime,
    teamJobDetailsUpdatedSnackbarVisible: state => state.teamJobDetailsUpdatedSnackbarVisible,
    teamJobDisabledAllResources: state => state.teamJobDisabledAllResources,

    //customers
    teamJobUsersList: state => state.teamJobUsersList,
    teamJobUserSearchText: state => state.teamJobUserSearchText,
    teamJobUser: state => state.teamJobUser,
    teamJobUsersListSize: state => state.teamJobUsersListSize,
    teamJobUsersListLoader: state => state.teamJobUsersListLoader,

    //contractor
    teamJobContractorId: state => state.teamJobContractorId,

    //operations
    teamJobOperation: state => state.teamJobOperation,
    teamJobOperationsList: state => state.teamJobOperationsList,
    teamJobOperationsSearchText: state => state.teamJobOperationsSearchText,
    teamJobOperationOwnerId: state => state.teamJobOperationOwnerId,
    teamJobOperationsListSize: state => state.teamJobOperationsListSize,
    teamJobOperationsListLoader: state => state.teamJobOperationsListLoader,

    //generic
    teamJobScheduledDate: state => state.teamJobScheduledDate,
    teamJobStatus: state => state.teamJobStatus,
    teamJobInstructions: state => state.teamJobInstructions,
    teamJobAttachments: state => state.teamJobAttachments,

    //operators
    teamJobOperators: state => state.teamJobOperators,
    teamJobVehiclesListForOperators: state => state.teamJobVehiclesListForOperators,
    teamJobVehiclesSearchTextForOperators: state => state.teamJobVehiclesSearchTextForOperators,
    teamJobImplementsListForOperators: state => state.teamJobImplementsListForOperators,
    teamJobImplementsSearchTextForOperators: state => state.teamJobImplementsSearchTextForOperators,
    teamJobVehiclesSearchInputListForOperators: state => state.teamJobVehiclesSearchInputListForOperators,
    teamJobImplementsSearchInputListForOperators: state => state.teamJobImplementsSearchInputListForOperators,
    teamJobShowJobAddOperatorExpandedView: state => state.teamJobShowJobAddOperatorExpandedView,
    teamJobShowOperatorSelectionDropdown: state => state.teamJobShowOperatorSelectionDropdown,
    teamJobOperatorToAdd: state => state.teamJobOperatorToAdd,
    teamJobOperatorToAddLoader: state => state.teamJobOperatorToAddLoader,
    teamJobTeamMembersForOperatorsSeachText: state => state.teamJobTeamMembersForOperatorsSeachText,
    teamJobsTeamMembersListForOperators: state => state.teamJobsTeamMembersListForOperators,
    teamJobVehiclesListForOperatorsSize: state => state.teamJobVehiclesListForOperatorsSize,
    teamJobImplementsListForOperatorsSize: state => state.teamJobImplementsListForOperatorsSize,
    teamJobVehiclesListForOperatorsLoader: state => state.teamJobVehiclesListForOperatorsLoader,
    teamJobImplementsListForOperatorsLoader: state => state.teamJobImplementsListForOperatorsLoader,

    //products
    teamJobProducts: state => state.teamJobProducts,
    teamJobProductToAdd: state => state.teamJobProductToAdd,
    teamJobProductsList: state => state.teamJobProductsList,
    teamJobProductsSearchText: state => state.teamJobProductsSearchText,
    teamJobProductsLoader: state => state.teamJobProductsLoader,
    teamJobShowJobAddProductsExpandedView: state => state.teamJobShowJobAddProductsExpandedView,
    teamJobShowJobAddProductsDropdown: state => state.teamJobShowJobAddProductsDropdown,

    //fields
    teamJobFieldsTempList: state => state.teamJobFieldsTempList,
    teamJobFieldsList: state => state.teamJobFieldsList,
    teamJobFieldsCustomerSearchText: state => state.teamJobFieldsCustomerSearchText,
    teamJobFieldsLoaded: state => state.teamJobFieldsLoaded,
    teamJobFieldsTotal: state => state.teamJobFieldsTotal,
    teamJobFieldsOffset: state => state.teamJobFieldsOffset,
    teamJobSelectedFields: state => state.teamJobSelectedFields,
    teamJobSelectedFieldsForList: state => state.teamJobSelectedFieldsForList,
    teamJobPolygons: state => state.teamJobPolygons,
    teamJobSelectedPolygon: state => state.teamJobSelectedPolygon,
    teamJobFieldLabels: state => state.teamJobFieldLabels,
    teamJobFieldCustomerId: state => state.teamJobFieldCustomerId,
    fieldRequesterId: state => state.fieldRequesterId,

    //metaData
    teamJobMetaDataList: state => state.teamJobMetaDataList,

    //addresses
    teamJobAddresses: state => state.teamJobAddresses,
    teamJobFarmAddressesSelected: state => state.teamJobFarmAddressesSelected,
    teamJobAddressInstructions: state => state.teamJobAddressInstructions,
    teamJobAllAddresses: state => state.teamJobAllAddresses,
    teamJobAddressSelectionType: state => state.teamJobAddressSelectionType,
    teamJobAddressesDropPinLatitude: state => state.teamJobAddressesDropPinLatitude,
    teamJobAddressesDropPinLongitude: state => state.teamJobAddressesDropPinLongitude,
    teamJobCustomAddress: state => state.teamJobCustomAddress,

    //time Details
    teamJobTimeDetailsList: state => state.teamJobTimeDetailsList,
    teamJobTempTimeDetailsList: state => state.teamJobTempTimeDetailsList,

    teamJobDetails: state => state.teamJobDetails,

    //location map
    teamJobLocationSelectedOperatorId: state => state.teamJobLocationSelectedOperatorId,
    selectedOperatorLocations: state => state.selectedOperatorLocations,
    operatorsMarkers: state => state.operatorsMarkers,
    operatorRealTimeLocation: state => state.operatorRealTimeLocation,

    //job completion
    teamJobNotes: state => state.teamJobNotes,
    attachmentDeleteInProgress: state => state.attachmentDeleteInProgress,

    //changes states
    hasTeamJobChangesOnDetails: state => state.hasTeamJobChangesOnDetails,

    //invoice
    teamJobInvoiced: state => state.teamJobInvoiced,
}

export const mutations = {
    setTeamJobOperation(state, data) {
        state.teamJobOperation = new TeamJobOperation().getTeamJobOperation(data);
    },

    setTeamJobViewPreference(state, data) {
        state.teamJobViewPreference = new TeamJobViewPreference().getTeamJobViewPreference(data);
    },

    setTeamJobsAddressList(state, data) {
        const addresses = data;
        if (addresses && addresses.length) {
            addresses.forEach(address => {
                state.teamJobAllAddresses.push(new TeamJobAddress().getTeamJobAddress(address))
            });
        }
    },

    setTeamJobsOwners(state, teamJobOwners) {
        const businessName = localStorage.getItem('fullOwnerBusinessName') + " (You)";

        if (!listContainsItem(state.teamJobUsersList, [ConstantValues.defaultObjectIdentifier], localStorage.getItem(ConstantValues.ownerId))) {
            state.teamJobUsersList.push({
                businessName: businessName,
                id: localStorage.getItem(ConstantValues.ownerId)
            });
        }

        if (teamJobOwners && teamJobOwners.length) {
            teamJobOwners.forEach(owner => {
                if (!listContainsItem(state.teamJobUsersList, [ConstantValues.defaultObjectIdentifier], owner.id)) {
                    if(owner.businessProfile) {
                        state.teamJobUsersList.push(new MinimalUser().getMinimalUser(owner));
                    }
                }
            });
        }
    },

    setTeamJobsOperations(state, operations) {
        if (operations && operations.length) {
            operations.forEach(operation => {
                if (!listContainsItem(state.teamJobOperationsList, [ConstantValues.defaultObjectIdentifier], operation.id))
                state.teamJobOperationsList.push(new TeamJobOperation().getTeamJobOperation(operation));
            });
        }
    },

    setTeamJobVehiclesList(state, vehicles) {
        if (vehicles && vehicles.length) {
            vehicles.forEach(vehicle => {
                if (!listContainsItem(state.teamJobVehiclesListForOperators, [ConstantValues.defaultObjectIdentifier], vehicle.id)){
                if (state.teamJobVehiclesListForOperators && state.teamJobVehiclesListForOperators.length && state.teamJobVehiclesListForOperators.length > vehicles.length) {
                    state.teamJobVehiclesListForOperators.unshift(new Machine().getVehicle(vehicle));
                } else {
                    state.teamJobVehiclesListForOperators.push(new Machine().getVehicle(vehicle));
                }
            }
            });
        }
    },

    setTeamJobOperatorMachinesDropdown(state, data) {
        const { machineType, machinesList } = data;
        switch(machineType) {
            case MachineType.Vehicle:
                if(machinesList && machinesList.length) {
                    machinesList.forEach((m) => {
                        if (!listContainsItem(state.teamJobVehiclesListForOperators, [ConstantValues.defaultObjectIdentifier], m.id))
                            state.teamJobVehiclesListForOperators.push(m);
                    })
                }
                break;
            case MachineType.Implement:
                if(machinesList && machinesList.length) {
                    machinesList.forEach((m) => {
                        if (!listContainsItem(state.teamJobImplementsListForOperators, [ConstantValues.defaultObjectIdentifier], m.id))
                            state.teamJobImplementsListForOperators.push(m);
                    })
                }
                break;
            default: return;
        }
    },

    setTeamJobImplementsList(state, implementsList) {
        if (implementsList && implementsList.length) {
            implementsList.forEach(implement => {
                if (!listContainsItem(state.teamJobImplementsListForOperators, [ConstantValues.defaultObjectIdentifier], implement.id)){
                if (state.teamJobImplementsListForOperators && state.teamJobImplementsListForOperators.length && state.teamJobImplementsListForOperators.length > implementsList.length) {
                    state.teamJobImplementsListForOperators.unshift(new Machine().getVehicle(implement));
                } else {
                    state.teamJobImplementsListForOperators.push(new Machine().getVehicle(implement));
                }
            }
            });
        }
    },

    setProductsListForTeamsJob(state, data) {
        const products = data;
        if (products.length) {
            if (state.teamJobProductsList.length) {
                products.forEach((product) => {
                    if (!listContainsItem(state.teamJobProductsList, [ConstantValues.defaultObjectIdentifier], product.id))
                        state.teamJobProductsList.push(new TeamJobProduct().getTeamJobProduct(product));
                })
            } else {
                state.teamJobProductsList = products.map(product => new TeamJobProduct().getTeamJobProduct(product));
            }
        }
    },

    setTeamJobAttachment(state, attachmentsList) {
        const { result, type, purpose } = attachmentsList;

        if (typeof result === 'string' || result instanceof String) {
            const obj = {
                "id": null,
                "purpose": purpose,
                "type": type,
                "url": result
            }
            state.teamJobAttachments.push(obj);
        } else if (result && Array.isArray(result) && result.length > 0) {
            result.map(attachment => {
                const obj = {
                    "id": null,
                    "purpose": purpose,
                    "type": type,
                    "url": attachment.path
                }
                state.teamJobAttachments.push(obj);
            })
        }
    },

    deleteAttachmentFromList(state, url) {
        state.teamJobAttachments = state.teamJobAttachments.filter(x => x.url != url);
    },

    setTeamMembersListForTeamsJob(state, data) {
        // farmer's or contractor's manager logged in
        if (getLoggedInUserRole().isFarmersManagerLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
            if (!listContainsItem(state.teamJobsTeamMembersListForOperators, [ConstantValues.defaultObjectIdentifier], localStorage.getItem(ConstantValues.ownerId))) {
                state.teamJobsTeamMembersListForOperators.push({
                    firstName: (localStorage.getItem('fullOwnerName') + (' (owner)')),
                    id: localStorage.getItem(ConstantValues.ownerId)
                });
            }
        }

        // farmer or contractor logged in
        if ((getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isContractorLoggedIn) && data[1]) {
            if (!listContainsItem(state.teamJobsTeamMembersListForOperators, [ConstantValues.defaultObjectIdentifier], localStorage.getItem(ConstantValues.ownerId))) {
                state.teamJobsTeamMembersListForOperators.push({
                    firstName: data[1] + ' (You)',
                    id: localStorage.getItem(ConstantValues.ownerId)
                });
            }
        }

        data[0].map((data) => state.teamJobsTeamMembersListForOperators.push(data));

        state.teamJobsTeamMembersListForOperators.map((t, index) => {
            if (getLoggedInUserRole().isContractorLoggedIn) {
                t.firstName = t.firstName + (t.lastName ? (' ' + t.lastName) : '');
            } else {
                if (index != 0) {
                    t.firstName = t.firstName + (t.lastName ? (' ' + t.lastName) : '');
                }
            }
        });
    },
    setFieldsForTeamJobs(state, data) {
        if (data && data.length) {
            data.forEach(field => {
                state.teamJobFieldsList.push(new TeamJobField().getTeamJobField(field));
                state.teamJobFieldsTempList.push(new TeamJobField().getTeamJobField(field));
            });
        }
        state.teamJobFieldsLoaded = state.teamJobFieldsList.length;
        state.teamJobFieldsOffset = state.teamJobFieldsLoaded;
    },
    setTeamJobDetails(state, data) {
        state.teamJobDetails = data;

    },
    setTeamJobsOperatorLocationHistory(state, data) {
        if (data.operatorLocations.length > 0) {
            data.operatorLocations.forEach(location => {
                state.selectedOperatorLocations.push(new TeamJobOperatorLocation().getTeamJobOperatorLocation(location));
            });
        }
    },
    resetTeamJobs(state) {
        state.teamJobViewPreference = new TeamJobViewPreference();
        state.teamJobsLoader = false;
        state.teamJobsAddLoader = false;
        state.teamJobOwnerType = UserRoleType.Farmer;
        state.teamJobOwnerTypeText = JobOwnerTypeText;
        state.teamJobDetailsRealtime = null;
        state.teamJobDetailsUpdatedSnackbarVisible = false;
        state.teamJobDisabledAllResources = false;

        //operation
        state.teamJobOperation = new TeamJobOperation();
        state.teamJobOperationsList = [];
        state.teamJobOperationsSearchText = null;
        state.teamJobOperationOwnerId = null;
        state.teamJobOperationsListSize = 0;
        state.teamJobOperationsListLoader = false;

        //customers
        state.teamJobUsersList = [];
        state.teamJobUser = null;
        state.teamJobUserSearchText = null;
        state.teamJobUsersListSize = 0;
        state.teamJobUsersListLoader = false;

        //contractor
        state.teamJobContractorId = null;

        //generic
        state.teamJobScheduledDate = null;
        state.teamJobStatus = TeamJobStatusType.Pending;
        state.teamJobInstructions = null;
        state.teamJobAttachments = [];

        //operators
        state.teamJobShowJobAddOperatorExpandedView = true;
        state.teamJobShowOperatorSelectionDropdown = false;
        state.teamJobOperators = [];
        state.teamJobVehiclesListForOperators = [];
        state.teamJobImplementsListForOperators = [];
        state.teamJobVehiclesSearchInputListForOperators = [];
        state.teamJobImplementsSearchInputListForOperators = [];
        state.teamJobOperatorToAdd = null;
        state.teamJobOperatorToAddLoader = false;
        state.teamJobTeamMembersForOperatorsSeachText = null;
        state.teamJobsTeamMembersListForOperators = [];
        state.teamJobVehiclesSearchTextForOperators = null;
        state.teamJobImplementsSearchTextForOperators = null;
        state.teamJobVehiclesListForOperatorsSize = 0;
        state.teamJobImplementsListForOperatorsSize = 0;
        state.teamJobVehiclesListForOperatorsLoader = false;
        state.teamJobImplementsListForOperatorsLoader = false;

        //products
        state.teamJobProducts = [];
        state.teamJobProductToAdd = null;
        state.teamJobProductsList = [];
        state.teamJobProductsSearchText = null;
        state.teamJobProductsLoader = false;
        state.teamJobShowJobAddProductsExpandedView = true;
        state.teamJobShowJobAddProductsDropdown = false;

        //fields
        state.teamJobFieldsTempList = new Array<TeamJobField>();
        state.teamJobFieldsList = new Array<TeamJobField>();
        state.teamJobFieldsCustomerSearchText = null;
        state.teamJobFieldsLoaded = 0;
        state.teamJobFieldsTotal = 0;
        state.teamJobFieldsOffset = 0;
        state.teamJobSelectedFields = [];
        state.teamJobSelectedFieldsForList = [];
        state.teamJobPolygons = [];
        state.teamJobSelectedPolygon = [];
        state.teamJobFieldLabels = [];
        state.teamJobFieldCustomerId = null;
        state.fieldRequesterId = null;

        //meta data
        state.teamJobMetaDataList = [];

        //addresses
        state.teamJobAddresses = [];
        state.teamJobFarmAddressesSelected = [];
        state.teamJobAllAddresses = [];
        state.teamJobAddressInstructions = null;
        state.teamJobAddressSelectionType = TeamJobAddressSelectionType.FarmAddress;
        state.teamJobAddressesDropPinLatitude = 0;
        state.teamJobAddressesDropPinLongitude = 0;
        state.teamJobCustomAddress = new TeamJobAddress();

        //time details 
        state.teamJobTimeDetailsList = [];
        state.teamJobTempTimeDetailsList = [];

        state.teamJobDetails = null;

        //location map
        state.teamJobLocationSelectedOperatorId = null;
        state.selectedOperatorLocations = [];
        state.operatorsMarkers = [];
        state.operatorRealTimeLocation = [];

        //job completion
        state.teamJobNotes = null;
        state.attachmentDeleteInProgress = false;

        //changes state
        state.hasTeamJobChangesOnDetails = false;

        //invoice
        state.teamJobInvoiced = false;

        //Map
        state.teamJobsFieldMap = false;
        state.polygonShapes = [];
    }
}