



















































































































































import onboardComputedMixin from "@/components/onboard/_onboard-computed";
import { formFieldPb } from "@/utils/uiconstants";
import { validateRegexAlphaNumeric } from "@/utils/helpers";

export default {
  mixins: [onboardComputedMixin],
  data() {
    return {
      formFieldPb,
    };
  },
  watch: {
    businessProfilePrefixTemp() {
      this.businessProfilePrefixTemp = this.getValidatedString(
        this.businessProfilePrefixTemp
      );
    },
  },
  methods: {
    getValidatedString(rawString: string) {
      return rawString
        .toString()
        .replace(/\s+/g, "")
        .replace(/[0-9]/g, "")
        .replace(/\./g, "")
        .toUpperCase();
    },
    updatePrefix(event: any) {
      const shortenedBusinessName: string = this.getValidatedString(
        event.currentTarget.value
      ).substring(0, 3);

      if (
        shortenedBusinessName.length == 3 &&
        (this.userProfile.businessProfile == null ||
          (this.userProfile.businessProfile &&
            (this.userProfile.businessProfile.shortTitle == null ||
              this.userProfile.businessProfile.shortTitle.length == 0)))
      ) {
        this.businessProfilePrefixTemp = shortenedBusinessName;
      }
    },
    checkKeyDownAlphaNumeric(event) {
      validateRegexAlphaNumeric(event);
    },
  },
};
