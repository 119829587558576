











































































































































import JobsListForInvoiceExternal from "@/components/invoices-external/invoices-external-add/invoices-external-add-jobs-list.vue";
import InvoiceDraftPreviewExternal from "@/components/invoices-external/invoices-external-draft/invoices-external-draft.vue";
import router from "@/router/index";
import { routeInvoices } from "@/utils/endpoints";
import { mapMutations } from "vuex";
import InvoiceMapExternalMixin from "@/mixins/invoice-external-map-mixin";
import ScrollerMixin from "@/mixins/scroller-mixin";
import InvoicesExternalComputedMixin from "@/components/invoices-external/invoices-external-computed";
import { ButtonActionPrimary, HoverTextButton } from "@/components/shared/buttons/index";
import { DeleteItemDialog as ReportDialog } from '@/components/shared/dialogs/index';
import moment from "moment";
import ConstantValues from '@/plugins/constantValues';
import Observer from '@/mixins/observer';
import { notify } from '@/utils/helpers';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';

export default {
  name: "InvoicesAddExternal",
  components: {
    JobsListForInvoiceExternal,
    InvoiceDraftPreviewExternal,
    ButtonActionPrimary,
    ReportDialog,
    HoverTextButton,
  },
  mixins: [
    InvoiceMapExternalMixin,
    ScrollerMixin,
    InvoicesExternalComputedMixin,
    Observer,
    breadcrumbsMixin
  ],
  data() {
    return {
      steps: 2,
      regenerateJobReport: false,
      stepperContentResized: false,
      externalInvoiceLink: null,
      integrationName: null
    }
  },
  watch: {
    steps(val) {
      if (this.invoiceSelectedTabExternal > val) {
        this.invoiceSelectedTabExternal = val;
      }
    },
  },
  methods: {
    ...mapMutations({
      setClearInvoiceData: "setClearInvoiceDataExternal",
      setClearIntegrations: "setClearIntegrations",
      setClearFilterOptions: "setClearFilterOptions",
      resetPaging: "resetPaging",
    }),
    previousStep(n) {
      if (n < 6) {
        this.invoiceSelectedTabExternal = n - 1;
      }
    },
    async generateJobSummaryForInvoice(invoiceIdToOpen) {
      this.invoiceReportUrlGenerationInProgress = true
      await this.$store.dispatch('getSupplierDetailsForExternalInvoice')
      await this.$store.dispatch('getCustomerDetailsForExternalInvoice', this.invoiceDraftPreviewDetailsExternal.customerId)
       
      const invoiceObj = {
          supplier: {
            name: this.supplierDetailsForExternalInvoice.name,
            emailAddress: this.supplierDetailsForExternalInvoice.emailAddress,
            phoneNumber: this.supplierDetailsForExternalInvoice.phoneNumber,
            address: this.supplierDetailsForExternalInvoice.address
          },
          customer: {
            name: this.customerDetailsForExternalInvoice.name,
            emailAddress: this.customerDetailsForExternalInvoice.emailAddress,
            phoneNumber: this.customerDetailsForExternalInvoice.phoneNumber,
            address: this.customerDetailsForExternalInvoice.address
          },
          jobIds: this.invoiceDraftPreviewDetailsExternal.jobIds
      }

      this.$store.dispatch('generateJobSummaryForInvoice', {
          integrationType: this.integrationType,
          invoiceObj
      })
    },
    attachJobReportUrlsToInvoice() {
      this.invoiceDraftPreviewDetailsExternal.attachments = [];
      if (this.invoiceJobReportUrls.length != 0) {
        this.invoiceJobReportUrls.map((report) => {
        
            const obj = {
              reference: report.reference,
              url: report.url,
            };
            this.invoiceDraftPreviewDetailsExternal.attachments.push(obj);
        });
      }
    },
    promtGenerateInvoiceReport() {
      if (this.invoiceJobReportUrls && this.invoiceJobReportUrls.length > 0) {
        this.regenerateJobReport = true;
      } else {
        this.generateInvoiceReport();
      }
    },
    cancelReportRegeneration() {
      this.regenerateJobReport = false;
    },
    proceedReportRegeneration() {
      this.regenerateJobReport = false;
      this.generateInvoiceReport();
    },
    generateInvoiceReport() {
      this.$store.dispatch("promtInvoiceGenerationMessage");
      this.invoiceJobReportUrls = [];
      this.invoiceDraftPreviewDetailsExternal.attachments = [];
      const invoiceIdToOpen = this.draftedInvoiceIdToOpenExternal ? this.draftedInvoiceIdToOpenExternal : this.invoiceDraftPreviewDetailsExternal.id;

      this.generatedFromJobDetails = false;
      this.generateJobSummaryForInvoice(invoiceIdToOpen);
    },
    copyLineItemsIfExists() {
      const productsList = new Array(...this.invoiceExternalLineItems);

      if (productsList.length == 0 || this.invoiceDraftPreviewDetailsExternalLineItems.length == 0)
        return;

      this.invoiceDraftPreviewDetailsExternalLineItems.forEach((lineItem) => {
        productsList.forEach((product) => {
          const productTitle = product.title?.toLowerCase()?.trim();

          if (productTitle === lineItem.description?.toLowerCase()?.trim() 
             || productTitle === lineItem.item?.title?.toLowerCase()?.trim()
             || productTitle === lineItem.description?.split('-')?.[1]?.toLowerCase()?.trim()
            ) {
            lineItem.item = product;
          }
        });
      });
    },
    createExternalInvoiceDraftPreview() {
      const jobIds = [];
      this.selectedJobsForInvoiceExternal.forEach((jb) => {
        if (jb) {
          jobIds.push(jb.id);
        }
      });
      const createInvoiceObj = {
        jobIds: jobIds,
        customerId: this.selectedCustomerIdForInvoiceExternal,
        dueDate: null,
      };
      this.$store.state.jobsInvoicesModule.map = null;
      this.$store
        .dispatch("createInvoiceDraftPreviewExternal", [
          this.integrationType,
          createInvoiceObj,
        ])
        .then((res) => {
          if (res) {
            this.$store.commit("setInvoicePreviewDetailsExternal", res);
            this.invoiceSelectedTabExternal = 2;
            if (
              this.invoiceDraftPreviewDetailsExternal &&
              this.invoiceDraftPreviewDetailsExternal.customerId
            ) {
              this.invoiceLoaderExternal = true;
              this.$store
                .dispatch("checkContactLinkStatus", [
                  this.integrationType,
                  this.invoiceDraftPreviewDetailsExternal.customerId,
                ])
                .then((rs) => {
                  this.invoiceLoaderExternal = false;
                  this.copyLineItemsIfExists();
                  setTimeout(() => { this.checkLineItemsValidity(); }, 500);
              });
            }
          }
        });
    },
    cancelDraftInvoice() {
      this.setClearInvoiceData();
      router.push(routeInvoices);
    },
    createExternalInvoiceFinalize(submitType: string) {
      switch (submitType) {
        case "submit":
          this.invoiceSendExternal = false;
          break;
        case "submitSend":
          this.invoiceSendExternal = true;
          break;
      }
      this.attachJobReportUrlsToInvoice();
      const convertedDueDate = moment.utc(this.invoiceDateExternal).format();
      const invObj = {
        number: this.invoiceDraftPreviewDetailsExternal.number,
        customerId: this.invoiceDraftPreviewDetailsExternal.customerId,
        customerName: this.invoiceDraftPreviewDetailsExternal.customerName,
        contactId: this.invoiceDraftPreviewDetailsExternal.contactId,
        contactName: this.invoiceDraftPreviewDetailsExternal.contactName,
        currency: this.invoiceDraftPreviewDetailsExternal.currency,
        dueDate: convertedDueDate
          ? convertedDueDate
          : this.invoiceDraftPreviewDetailsExternal.dueDate,
        instructions: this.invoiceDraftPreviewDetailsExternal.instructions,
        jobIds: this.invoiceDraftPreviewDetailsExternal.jobIds,
        lineItems: this.invoiceDraftPreviewDetailsExternalLineItems,
        attachments: this.invoiceDraftPreviewDetailsExternal.attachments,
      };
      if (this.integrationType && invObj.contactId) {
        this.invoiceGenerationInProgressExternal = true;
        this.$store
          .dispatch("createInvoiceFinalizeExternal", [
            this.integrationType,
            invObj,
          ])
          .then((res) => {
            this.invoiceGenerationInProgressExternal = false;
            if (res) {
              notify(`Your ${this.integrationName} invoice has been generated succesfully!`, 'success', { callback: () =>  window.open(res, "_blank"), text: 'View' })
              router.push(routeInvoices);
            }
          });
      }
    },
    isNextDisabled(stepNumber) {
      switch (stepNumber) {
        case 1:
          if (
            Array.isArray(this.selectedJobsForInvoiceExternal) &&
            this.selectedJobsForInvoiceExternal.length == 0
          ) {
            return true;
          } else {
            return false;
          }
        case 2:
          if (
            this.dueDateForInvoiceExternal == null ||
            this.dueDateForInvoiceExternal.length == 0
          ) {
            return true;
          } else {
            return false;
          }
      }
    },
    getExternalInvoiceTitle() {
      this.setBreadcrumbs('InvoicesExternalAdd');
    },
    getIntegrationType() {
      if (this.xeroAccessState == 2) {
        this.integrationType = "xero";
        this.integrationName = 'Xero';
      }
      if (this.quickbookAccessState == 2) {
        this.integrationType = "quickbooks";
        this.integrationName = 'QuickBooks';
      }
      if (this.sageAccessState == 2) {
        this.integrationType = "sage";
        this.integrationName = "Sage";
      }
    },
    getExternalLineItems() {
      this.invoiceExternalLineItems = [];
      this.$store.dispatch("getInvoiceLineItemsExternal", this.integrationType);
    },
    initializeStepperResizeObserver(callback) {
      const target = this.$refs.stepperRef;
      this.initializeResizeObserver(target, callback);
    },
    disconnectStepperResizeObserver() {
      this.observer?.disconnect();
    },
  },
  mounted() {
    this.initializeStepperResizeObserver(() => this.stepperContentResized = true);
    this.resetPaging();
    this.setClearFilterOptions();
    this.anyLineItemInvalid = true;
    this.invoiceReportGenerationInProgress = false;
    this.getExternalInvoiceTitle();
    this.setClearInvoiceData();
    this.setClearIntegrations();
    this.$store.dispatch("getExternalInvoicingServiceAccess").then(() => {
      this.loadingIntegration = false;
      this.getIntegrationType();
      this.getExternalInvoiceTitle();
      this.getExternalLineItems();
    });
  },
  beforeDestroy() {
    this.disconnectStepperResizeObserver();
  }
};
