

























































































































































































































































































































































































































































































































































































































































import { getCapitalised, openOverlay, reduceText, getDateFormatted } from "@/utils/helpers";
import { brandColor } from "@/utils/uiconstants";
import { routeTeamJobsDetails } from "@/utils/endpoints";
import InvoicesExternalContactsFlyout from "@/components/invoices-external/invoices-external-contacts/invoices-external-contacts.vue";
import moment from "moment";
import invoicesExternalComputed from "@/components/invoices-external/invoices-external-computed";
import { mapMutations } from "vuex";
import {getJobNumberPrefix, getJobNumberSuffix} from '@/utils/helpers/jobs-helpers';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import CloseButton from '@/components/shared/buttons/button-close.vue';
import ConstantValues from '@/plugins/constantValues';

export default {
  data() {
    return {
      menuDate: false,
      brandColor,
      showMissingInfoWarningTaxRateActiveIndex: null,
      showMissingInfoWarningChartOfAccount: false,
      linkingContact: false
    };
  },
  mixins: [invoicesExternalComputed],
  components: {
    InvoicesExternalContactsFlyout,
    Button,
    CloseButton,
  },
  watch: {
    invoiceDraftPreviewDetailsExternalLineItems(val) {
      this.checkLineItemsValidity();
    }
  },
  computed: {
    computedDateFormatted() {
      return this.invoiceDateExternal
        ? moment(this.invoiceDateExternal).format("DD MMM YYYY")
        : "";
    },
    canAddNewLineItem() {
        return (
          this.invoicesExternalInitialLineItem &&
          this.invoicesExternalInitialLineItem.item.title &&
          this.invoicesExternalInitialLineItem.unit &&
          this.invoicesExternalInitialLineItem.item.unitPrice
        )
    },
    isAllAddLineItemViewClosed() {
      if(!this.invoicesExternalShowAddLineItemView.length) return
      return this.invoicesExternalShowAddLineItemView.filter(isItemOpen => isItemOpen == false).length == 
        this.invoicesExternalShowAddLineItemView.length
    },
    invoiceExternalCurrencySymbol() {
      return this.invoiceDraftPreviewDetailsExternal?.currency?.symbol || this.currencySymbol
    }
  },
  mounted() {
    this.$store.state.tempUrl = '';
    this.getExternalInvoiceTitle();
    this.invoiceJobReportUrls = [];
    this.invoiceExternalLineItemSearchText = [];
    this.localCheckContactLinkStatus();
    this.$store.dispatch('getOperationUnits');
  },
  methods: {
    ...mapMutations({
      clearFilterOptions: "setClearFilterOptions",
      resetPaging: "resetPaging",
    }),

    localCheckContactLinkStatus() {
      if (
        this.invoiceDraftPreviewDetailsExternal &&
        this.invoiceDraftPreviewDetailsExternal.customerId
      ) {
        this.invoiceLoaderExternal = true;
        this.$store
          .dispatch("checkContactLinkStatus", [
            this.integrationType,
            this.invoiceDraftPreviewDetailsExternal.customerId,
          ])
          .then((res) => {
            this.invoiceLoaderExternal = false;
          });
      }
    },

    getExternalInvoiceTitle() {
      const invoiceTypeText = this.integrationType
        ? " " + getCapitalised(this.integrationType) + " "
        : " ";
      return invoiceTypeText;
    },

    updateLineItem(lineItem: any) {
      lineItem.description = lineItem.item.description;
      this.checkLineItemsValidity();
    },

    updateNewLineItem(lineItem: any) {
      lineItem.description = lineItem.item.description;
    },

    routeToJobDetails(jobId: string) {
      if (jobId) {
        window
          .open(`${routeTeamJobsDetails}${jobId}`, "_blank")
          .focus();
      }
    },

    unlinkContact() {
      this.invoiceLoaderExternal = true;
      this.$store
        .dispatch("unlinkContact", [
          this.integrationType,
          this.invoiceDraftPreviewDetailsExternal.customerId,
          this.invoiceDraftPreviewDetailsExternal.contactId,
        ])
        .then((unlinkResult) => {
          this.invoiceLoaderExternal = false;
          if (unlinkResult && unlinkResult.status == 200) {
            this.localCheckContactLinkStatus();
          }
        });
    },

    openContactsFlyout() {
      if (this.integrationType != null) {
        this.externalContactRadioGroup = 1;
        this.customerLoader = true;
        this.contacts = [];
        this.tempIntegratedContacts = [];
        this.clearFilterOptions();
        this.resetPaging();
      }
      this.linkingContact = true;
      openOverlay("contactsLinkFormOverlay");

    },

    viewReport(reportUrl: string) {
      window.open(reportUrl, "_blank");
    },

    removeAttachment(rIndx) {
      this.invoiceJobReportUrls.splice(rIndx, 1);
    },

    searchExternalLineItems(lineItemIndex) {
      this.clearFilterOptions();
      this.$store
        .dispatch("setLineItemsExternalSearchText", lineItemIndex)
        .then((res) => {
          if (res != false) {
            this.$store.dispatch(
              "getInvoiceLineItemsExternal",
              this.integrationType
            );
            if (this.invoiceExternalLineItemSearchText == "") {
              this.clearFilterOptions();
            }
          }
        });
    },

    getMissingInfoWarning() {
      return `Please update the missing information on ${
        this.integrationType.charAt(0).toUpperCase() +
        this.integrationType.slice(1)
      } and refresh the products again.`;
    },

    fetchLineItems() {
      this.clearFilterOptions();
      this.invoiceExternalLineItems = [];
      this.$store
        .dispatch("getInvoiceLineItemsExternal", this.integrationType);
    },

    reduceTextLength(textToReduce: string, maxTextLength: number) {
      return (textToReduce) ? reduceText(textToReduce, maxTextLength) : '';
    },


    
    showMissingInfoWarningTaxRate(index) {
      this.showMissingInfoWarningTaxRateActiveIndex = index
    },

    hideMissingInfoWarningTaxRate() {
      this.showMissingInfoWarningTaxRateActiveIndex = null
    },

    getJobNumberPrefix(jobNumber) {
      return getJobNumberPrefix(jobNumber)
    },

    getJobNumberSuffix(jobNumber) {
      return getJobNumberSuffix(jobNumber)
    },

    showAddLineItemView(distinctJobIndex) {
     this.invoicesExternalShowAddLineItemView = this.invoicesExternalShowAddLineItemView.map((lineItemView) => lineItemView = false)
     this.invoicesExternalShowAddLineItemView[distinctJobIndex] = true
    },

    resetToInitialLineItem() {
      this.invoicesExternalInitialLineItem = {
          "item": {
              "id": null,
              "title": null,
              "description": null,
              "unitPrice": 0,
              "taxRate": {
                  "id": null,
                  "title": null,
                  "rate": 0
              },
              "chartOfAccount": {
                  "id": null,
                  "title": null
              }
          },
          "quantity": 0,
          "unit": null,
          "description": "",
          "reference": "",
          "date": ""
      };
    },

    addNewLineItem(distinctJobIndex) {
      const jobReference = this.invoiceLineItemJobNumbersDistinctExternal[distinctJobIndex];
      let lastIndexToAppendTo = 0;
      this.invoiceDraftPreviewDetailsExternalLineItems.forEach((item, index) => {
        if(item.reference == jobReference) {
          lastIndexToAppendTo = index;
        }
      })
     this.invoicesExternalInitialLineItem.reference = jobReference;
     this.invoiceDraftPreviewDetailsExternalLineItems.splice(lastIndexToAppendTo + 1, 0, this.invoicesExternalInitialLineItem);
     const anyLineItemWithSameJob = this.invoiceDraftPreviewDetailsExternalLineItems.find(lineItem => lineItem.reference == jobReference);
     this.invoicesExternalInitialLineItem.date = (anyLineItemWithSameJob && anyLineItemWithSameJob.date) ? anyLineItemWithSameJob.date : '';
     this.resetToInitialLineItem();
     this.invoicesExternalShowAddLineItemView = this.invoicesExternalShowAddLineItemView.map((lineItemView) => lineItemView = false)
     this.invoicesExternalShowAddLineItemButton = true;
     this.checkLineItemsValidity();
    },

    cancelNewLineItemAdd() {
      this.resetToInitialLineItem();
      this.invoicesExternalShowAddLineItemView = this.invoicesExternalShowAddLineItemView.map((lineItemView) => lineItemView = false)
      this.invoicesExternalShowAddLineItemButton = true;
    },

    removeExternalLineItem(lineitemIndex) {
      this.invoiceDraftPreviewDetailsExternalLineItems = this.invoiceDraftPreviewDetailsExternalLineItems.filter((item, index) => index != lineitemIndex);
      this.checkLineItemsValidity();
    },

    shouldShowLineItemRemoveButton(distinctJobNumber) {
      const lineItemsWithSameJobNumber = this.invoiceDraftPreviewDetailsExternalLineItems.filter(item => item.reference == distinctJobNumber);
      return lineItemsWithSameJobNumber.length > 1;
    },

    getDateFormatted(date) {
      return getDateFormatted(date)
    },
  },
};
