
















































































import { closeOverlay, checkFieldsValidity, validateRegexNumeric, validateRegexAlphaNumeric } from '@/utils/helpers';
import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import productsComputedMixin from '@/components/products/products-computed';
import {HeaderPrimary, SubHeaderPrimary, DescriptionPrimary} from '@/components/shared/headers/index';
import {BaseButton as Button} from '@/components/shared/buttons/index';
import { Product } from '@/data/models/Product';

export default {
    components: {
        ListLoader,
        SubHeaderPrimary,
        DescriptionPrimary,
        Button,
        HeaderPrimary
    },
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
        }
    },
    mixins: [productsComputedMixin],
    computed: {
        isDisabledBtn() {
            if (this.productDetails != null) {
                if (checkFieldsValidity([this.productDetails.title, this.productDetails.unit, this.productDetails.cost])) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        }
    },
    methods: {
        closeProductEditForm: function () {
            closeOverlay("editProductFormOverlay");
        },
        checkKeyDownNumeric(event) {
            validateRegexNumeric(event);
        },
        checkKeyDownAlphaNumeric(event) {
            validateRegexAlphaNumeric(event);
        },
        editProductDetails: function () {
            let productDto = new Product().SetData(this.productDetails);
            this.$store.dispatch('addOrUpdateProduct', productDto);
        }
    }
}
