


































































































































































































import { closeOverlay, checkFieldsValidity } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import moment from 'moment';
import timesheetComputedMixin from '@/components/timesheets/timesheets-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { TimeSheetType } from '@/enum/timeSheetType';
import ConstantValues from '@/plugins/constantValues';
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";

export default {
    name: "TimeSheetEditListView",
    data() {
        return {
            menuDate: false,
            TimeSheetType
        }
    },
    mixins: [timesheetComputedMixin],
    components: {
        HeaderPrimary,
        Button,
        VueCtkDateTimePicker
    },
    computed: {
        isDisabledBtn() {
            if (checkFieldsValidity(
                [this.timesheetDetails.date, this.timesheetDetails.startTime, this.timesheetDetails.endTime])) {
                return false;
            } else {
                return true;
            }
        },
        computedDateFormatted() {
            return this.timesheetDetails.date ? moment(this.timesheetDetails.date).format('DD MMM YYYY') : ''
        },
    },
    methods: {
        ...mapMutations({
            clearTimeSheetFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),
        closeTimeSheetEditForm: function () {
            closeOverlay("editTimeSheetFormOverlay");
        },
        editTimeSheet: function () {
            let timeSheetEndDate = this.getTimesheetEndDate(this.timesheetDetails.date, this.timesheetDetails.startTimeConverted, this.timesheetDetails.endTimeConverted);

            const startTimeConverted = moment.utc(moment(this.timesheetDetails.date + ' ' + this.timesheetDetails.startTimeConverted).utc()).format();
            const endTimeConverted = moment.utc(moment(timeSheetEndDate + ' ' + this.timesheetDetails.endTimeConverted).utc()).format();

            const timeSheetDto = {
                userId: this.timesheetDetails.userId,
                type: this.timesheetDetails.type,
                startTime: startTimeConverted,
                endTime: endTimeConverted,
                breakTimeInMinutes: this.timesheetDetails.breakTimeInMinutesConverted.hours * 60 + this.timesheetDetails.breakTimeInMinutesConverted.minute,
                travelTimeInMinutes: this.timesheetDetails.travelTimeInMinutesConverted.hours * 60 + this.timesheetDetails.travelTimeInMinutesConverted.minute,
                note: this.timesheetDetails.note
            }
            this.$store.dispatch('updateTimesheet', [timeSheetDto, this.timesheetDetails.id]).then((res) => {
                this.clearTimeSheetFilterOption();
                this.resetPaging();
                if (res != undefined) {
                    this.$store.dispatch('setTimesheetsFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(res => {
                        this.$store.dispatch('getTimesheetsList')
                    });
                }
            });
        }
    }
}
