import Vue from "vue";
import Vuetify from "vuetify/lib";
import DatetimePicker from '@goldenm/vuetify-datetime-picker';
import { devLogger } from "@/utils/helpers";
import VueGtag from 'vue-gtag'
import { gAnalyticsKey } from '@/utils/constants'

if(process.env.NODE_ENV === 'production') {
    Vue.use(VueGtag, {
      config: { id: gAnalyticsKey }
    })
}

Vue.use(Vuetify);
Vue.use(DatetimePicker);

devLogger().logAllEnv(`ENV: ${process.env.NODE_ENV}`);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1d34ad',
                secondary: '#182879',
                tertiary: '#ebf1fb',
                quaternary: '#0e96fd',
                pale_gray: '#f6f7f9',
                danger: '#FA4E4E',
                white: '#FFFFFF'
            },
        },
        options: {
            customProperties: true
        }
    },
})

export default vuetify;