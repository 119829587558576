

























































































import { TeamJobStatusTypeLabel, TeamJobStatusType } from '@/enum/teamJobStatusType';
import { jobColors } from '@/store/modules/jobs-calendar/config';
import { getDateTimeFormattedLocal } from '@/utils/helpers';
import { getCalculatedFieldArea } from '@/utils/helpers/jobs-helpers';

export default {
    name: 'CalendarInfoWindow',
    props: {
        selectedEvent: Object,
        jobInfoWindowPosition: Object
    },
    data() {
        return {
          TeamJobStatusType
        }
    },
    methods: {
      getStatusText(status: number) {
        return TeamJobStatusTypeLabel.get(status);
      },
      getBackgroundColor(eventStatus: number) {
        const filteredColor = jobColors.filter(c => c.type === eventStatus)?.[0]?.dark;
        return filteredColor || '#E9ECF0';
      },
      getOperatorNames(operators: Array<{ name: string }>) {
        return operators.map(operator => operator.name).join(', ')
      },
      getFieldNames(fields: Array<{ title: string }>) {
        return fields.map(field => field.title).join(', ')
      },
      getTotalsFieldsArea(fields: Array<{ areaInSquareMeter: number }>) {
        return getCalculatedFieldArea(fields);
      },
      getDateTimeFormat (dateTime) {
        return getDateTimeFormattedLocal(dateTime);
      },
    },
}
