










































































































































































































































































































import $ from "jquery";
import { getListLengthAbsolute, openOverlay, getConvertedAreaUnit, preventKeyControlsForDropdown } from "@/utils/helpers";
import { mapMutations, mapActions } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import router from "@/router/index";
import { routeFields, routeFieldsAdd, routeFieldsEdit } from "@/utils/endpoints";
import FieldsListMap from "@/components/fields/field-list-map.vue";
import fieldsComputedMixin from "@/components/fields/fields-computed";
import {
    BaseButton as Button,
    BaseButton as SecondaryButton,
} from "@/components/shared/buttons/index";
import Snackbar from "@/components/shared/snackbar/snackbar-listing.vue";
import {
    DeleteItemDialog, 
    DeleteItemDialog as ArchiveItemDialog,
    DeleteItemDialog as RestoreItemDialog,
    ActionListingDialog
} from '@/components/shared/dialogs/index';
import { UserRoleType } from "@/enum/userRoleType";
import LoadMoreItem from "../shared/load-more-item.vue";
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import PaginationModule from "@/store/modules/pagination";
import Timers from "@/mixins/timers";
import { getLoggedInUserRole } from "@/utils/helpers/user-role-helpers";
import { TabFilterType } from "@/enum/tabFilterType"; 
import {boundMapOnCordinates} from "@/utils/helpers/gmaps-helpers";

export default {
    mixins: [
        permissionsMixin,
        scrollerMixin,
        filterParamsMixin,
        fieldsComputedMixin,
        Timers
    ],
    components: {
        FieldsListMap,
        Button,
        DeleteItemDialog,
        Snackbar,
        SecondaryButton,
        ArchiveItemDialog,
        RestoreItemDialog,
        ActionListingDialog,
        LoadMoreItem,
        List,
    },
    computed: {
        someFieldsSelected() {
            return this.selectedItems.length > 0 && !this.allFieldsSelected;
        },
        allFieldsSelected() {
            return this.selectedItems.length == this.fieldFilterList.length;
        },
        loginUser() {
            return localStorage.getItem(ConstantValues.ownerId);
        },
        drawerIsOpen() {
            return this.$store.state.drawerOpen;
        }
    },
    data: function () {
        return {
            files: null,
            isChecked: false,
            isInternalCustomer: false,
            dialogForSelectCustomer: false,
            archiveDialog: null,
            archiveFieldId: null,
            archiveFieldOwner: null,
            restoreDialog: null,
            restoreFieldId: null,
            restoreFieldOwner: null, 
            isInternalSelectedOwner: true,
            UserRoleType,
            searchTimerCustomers: null,
            listHeaderOffset: 0
        };
    },
    created() {
        this.TabFilterType = TabFilterType

        if(this.$route.path === routeFields) {
            this.customerDropdownModule = this.$stringConstants('fieldsFilterCustomersPaginationModule');
            if(!this.$store.hasModule(this.customerDropdownModule)){
                this.$store.registerModule(this.customerDropdownModule, PaginationModule);
            }
        }
    },
    watch: {
        fieldFilterList(val) {
            if (this.fieldListOwnerId != "All") {
                if (this.fieldFilterList.length > 0) {
                    const selectedOwner = this.fieldFilterList[0].owner;
                    if (selectedOwner.isInternal || this.loginUser == selectedOwner.id) {
                        this.isInternalCustomer = true;
                    } else {
                        this.isInternalCustomer = false;
                    }
                }
            } else {
                this.isInternalCustomer = false;
            }
        },
        selectedItems(val) {
            if (this.selectedItems.length > 0) {
                this.snackbarShow = true;
            } else {
                this.snackbarShow = false;
                this.isChecked = false;
            }
        },
        fieldListOwnerId() {
            if(this.fieldListOwnerId) {
                this.checkIfInternalSelectedOwner();
            }
        },
        drawerIsOpen() {
            const showFieldsList = !this.drawerIsOpen;
            this.$store.commit('setShowFieldsList', showFieldsList);
            if (!this.drawerIsOpen) {
                this.$store.state.dashboardTitle = this.$stringConstants("fieldsTitle");
            }
        },
        mapTriggeredEditFieldData() {
            if (this.mapTriggeredEditFieldData && !this.fieldDetails?.id) {
                this.handleListItemClick(this.mapTriggeredEditFieldData);
            }
        }
    },
    beforeMount() {
        this.clearAndResetFieldPagingStates();
        this.fieldOwnerList = [];
    },
    beforeDestroy() {
        this.clearAndResetFieldPagingStates();
        this.fieldOwnerList = [];
        this.$store.hasModule(this.customerDropdownModule) && this.$store.unregisterModule(this.customerDropdownModule);
    },
    mounted() {
        if(!this.$store.state.isPageRelated) {
            localStorage.removeItem('fieldSearchQuery');
            localStorage.removeItem('fieldFilterType');
            localStorage.removeItem('fieldOwnerId');
        }
        const fieldListOwnerId = localStorage.getItem('fieldListOwnerId')
        if (fieldListOwnerId) {
            this.fieldListOwnerId = fieldListOwnerId;
        } else {
            this.fieldListOwnerId = "All";
        }
        this.$store.state.dashboardTitle = "Fields";
        this.files = null;

        this.searchFarmerFilter();
        
        this.getUrlParamsFieldsLocal();
        this.setFilterOptionsAndGetFields()
        this.getYearListAsync();
        this.userIsFarmerOrFarmerManager = this.checkIfUserIsFarmerOrFarmerManager();
        this.checkIfURLIsForOpenDrawer();
        this.calculateListHeaderOffsetTop();

    },
    methods: {
        ...mapMutations({
            clearFieldDetailsState: "resetFieldDetails",
            clearFieldFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
            setDefaultOwnerListBulkUpload: "setDefaultOwnerListBulkUpload",
            resetSnackbarDetails: "clearSnackbarDetails",
            setDefaultOwnerList: "setDefaultOwnerList"
        }),
        ...mapActions({
            setFieldFilterAsync: "setFieldFilter",
            getYearListAsync: "getYearList",
            getFieldsListFilteredAsync: "getFieldsListFiltered",
            getGoogleMapToAddFieldAsync: "getGoogleMapToAddField",
            setSearchFarmersFilterAsync: "setSearchFarmersFilter",
            getOwnerListAction: "getOwnerList",
            clearFieldListsLabelsAndFields: "clearFieldListsLabelsAndFields",
            removeFieldFromFieldsListOnMap: "removeFieldFromFieldsListOnMap",
            updateMapToAddField: "updateMapToAddField",
            getFieldDetailsByIdAsync: "getFieldDetailsById"
        }),
        onFieldSearchClearClicked() {
            this.fieldFilterList = [];
            this.filtersApplied = false;
            this.clearFieldFilterOption();
            this.resetPaging();
            this.removeLocalFieldsParams();
            this.setFilterOptionsAndGetFields()
            .then(() => this.$store.dispatch("highlightFieldsOnMap"))
        },
        removeLocalFieldsParams() {
            this.fieldSearchText = this.removeLocalParamsMx("fieldSearchQuery", "");
        },
        setUrlParamsFieldsLocal() {
            this.setLocalParamsMx(this.fieldSearchText, "fieldSearchQuery");
            this.setLocalParamsMx(this.fieldListOwnerId, "fieldOwnerId");
            this.setLocalParamsMx(this.fieldStatus, "fieldFilterType");
        },
        getUrlParamsFieldsLocal() {
            this.fieldSearchText = this.getLocalParamsMx("fieldSearchQuery", "");
            this.fieldListOwnerId = this.getLocalParamsMx("fieldOwnerId", "All");
            this.fieldStatus = parseInt(this.getLocalParamsMx("fieldFilterType", 0));
        },
        applySearchFieldFilter() {
            this.clearFieldFilterOption();
            this.resetSnackbarDetails();
            this.resetPaging();
            this.fieldFilterList = [];
            this.filtersApplied = true;
            this.fieldsListMapLoader = true;
            this.setFilterOptionsAndGetFields()
            .then(async () => {
                if (this.fieldListOwnerId != 'All') {
                    await this.$store.dispatch("highlightFieldsOnMap")
                } else {
                    await this.$store.dispatch("setMapBoundsForFieldsInFieldsList")
                }
                this.fieldsListMapLoader = false;
            });
        },
        focusOnField() {
            if (
                Array.isArray(this.fieldFilterList) &&
                this.fieldFilterList.length > 0 &&
                this.fieldSearchText != "" &&
                this.fieldSearchText != null
            ) {
                this.fieldFilterList.map((field) => {
                    if (
                        field.title &&
                        field.title
                            .toLowerCase()
                            .includes(this.fieldSearchText.toLowerCase())
                    ) {
                        this.$store.dispatch("focusOnFieldOnFieldsMap", field);
                    }
                });
            }
        },
        clearAndResetFieldPagingStates() {
            $(window).unbind("scroll");
            this.$store.state.fieldsModule.fieldFilterList = [];
            this.clearFieldFilterOption();
            this.resetPaging();
            this.filtersApplied = false;
        },
        openAddFieldForm: function () {
            this.fieldsMountedOnMap.then(() => this.updateMapToAddField())
            this.showEditableFields = true;
            this.fieldReadonly = false;
            this.fieldDetails.areaInSquareMeter = 0;
            this.convertedArea = 0;
            this.clearFieldDetailsState();
            this.fieldOwnerList = [];
            const loginOwnerId = localStorage.getItem(ConstantValues.ownerId);
            if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
                this.fieldDetails.owner = loginOwnerId;
            }
            history.pushState({}, null,routeFieldsAdd);
        },
        openEditFieldForm: function (ids, isFieldReadonly: boolean, field: any) {
            this.fieldsMountedOnMap.then(() => {
                const fieldIndex = this.fieldsListMapData.findIndex(shape => shape.id == field.id);
                const fieldPolygon = this.fieldsListFieldShapes[fieldIndex];
                this.$store.commit('setTempPolygons', fieldPolygon);
                this.$store.commit('setFieldDetails', {...field, shapeIndex: fieldIndex, polygon: Array.isArray(field.polygon) ? field.polygon : JSON.parse(field.polygon)});
                this.$store.commit('setCurrentOwnerToOwnerList', field.owner);
                this.$store.dispatch('highlightFieldOnMap', [Array.isArray(field.polygon) ? field.polygon : JSON.parse(field.polygon), this.fieldsListMap])
                .then(() => boundMapOnCordinates(this.fieldsListMap, [field]));
            });
            this.showEditableFields = false;
            this.fieldReadonly = isFieldReadonly;
            localStorage.setItem('fieldListOwnerId', this.fieldListOwnerId);
            this.resetSnackbarDetails();
            history.pushState({}, null, `${routeFieldsEdit}/${ids[1]}/${ids[0]}?isEditable=${!isFieldReadonly}`);
        },
        getFieldsOwnerList() {
            this.fieldOwnerList = [];
            this.fieldOwnerFilterList = [];
            this.$store.commit("setDefaultOwnerList", this.loginUserBusinessName);
            this.$store.commit(
                "setDefaultOwnerFilterList",
                this.loginUserBusinessName
            );
            this.getOwnerListAction({
                moduleNamespace: this.customerDropdownModule
            }).then(() => this.checkIfInternalSelectedOwner());
        },
        filterFieldsList: function () {
            if(this.fieldListOwnerId) {
                this.setLoaderHeight();
                this.clearFieldFilterOption();
                this.resetSnackbarDetails();
                this.resetPaging();
                this.fieldFilterList = [];

                this.$nextTick(() => {
                    !this.isInternalSelectedOwner ? this.fieldStatus = 0 : null;
                    this.fieldsListMapLoader = true;
                    this.setFilterOptionsAndGetFields()
                    .then(() => this.getAllFieldsForMap());
                });
            }
        },
        searchFarmerFilter: function () {
            this.fieldOwnerFilterList = [];
            this.$store.commit("setDefaultOwnerList", this.loginUserBusinessName);
            this.$store.commit(
                "setDefaultOwnerFilterList",
                this.loginUserBusinessName
            );
            this.setSearchFarmersFilterAsync({
                offset: ConstantValues.defaultPageOffsetSize,
                limit: ConstantValues.defaultPageLimitSize,
                moduleNamespace: this.customerDropdownModule
            }).then(() => {
                this.getOwnerListAsync();
            });
        },
        applyCustomersSearchTimer(e) {
            if(preventKeyControlsForDropdown(e.keyCode)) {
                if (this.searchTimerCustomers) {
                    clearTimeout(this.searchTimerCustomers);
                    this.searchTimerCustomers = null;
                }
                this.searchTimerCustomers = setTimeout(() => {
                    this.applySearchFilters();
                }, ConstantValues.defaultDropdownSearchTimeout); 
            }
        },
        applySearchFilters: function () {
            this.searchNotify = this.$stringConstants('enterToSearch');
            this.searchFarmerFilter();
        },
        isFieldInternal(field: any) {
            return (
                field.owner.isInternal ||
                field.owner.id == localStorage.getItem(ConstantValues.ownerId)
            );
        },
        isFieldExternal(field) {
            return !field.owner.isInternal && field.owner.id !== localStorage.getItem(ConstantValues.ownerId);
        },
        getAreaFormatted(fieldArea: any) {
            if (fieldArea != undefined && fieldArea != null) {
                return getConvertedAreaUnit(fieldArea);
            }
            return "";
        },
        openBulkFieldsUploadForm() {
            this.fieldOwnerListForBulkUpload = [];
            this.bulkUploadOwner = null;
            this.compressedFile = null;
            openOverlay("fieldsBulkUploadFormOverlay");

            this.setDefaultOwnerListBulkUpload(this.loginUserBusinessName);
        },
        getOwnerBusinessTitle(owner: any) {
            if (owner && owner.businessProfile && owner.businessProfile.title)
                return owner.businessProfile.title;
            else
                return (
                    (owner.firstName ? owner.firstName : "") +
                    " " +
                    (owner.lastName ? owner.lastName : "")
                );
        },
        loadMoreFields() {
            const $states = this.$store.state;
            this.clearFieldFilterOption();
            $states.offset = $states.offset + $states.listCount;
            this.setFieldFilterAsync([$states.offset, $states.limit]).then(() => {
                this.getFieldsListFilteredAsync();
            });
        },
        getOwnership(ownership) {
            return this.enumOwnership.filter((o) => o.value == ownership)[0].title;
        },
        selectAllFields() {
            if (this.isInternalCustomer || this.userIsFarmerOrFarmerManager) {
                this.selectedItems = [];
                this.allItemsDeleted = false;
                if (this.isChecked) {
                    this.fieldFilterList.map((pro) => {
                        this.selectedItems.push(pro.id);
                    });
                }
            } else {
                this.dialogForSelectCustomer = true;
            }
        },
        closeDialogForSelectCustomer() {
            this.dialogForSelectCustomer = false;
            this.isChecked = false;
            this.resetSnackbarDetails();
        },
        handleListItemClick(field) {
            if (this.isFieldExternal(field)) {
                this.openDrawerForEditingField([field.id, field.owner.id], true, field);
            } else if (this.isFieldInternal(field)) {
                this.openDrawerForEditingField([field.id, field.owner.id], false, field);
            }
        },
        getFieldListByStatus() {
            this.clearFieldFilterOption();
            this.resetSnackbarDetails();
            this.resetPaging();
            this.fieldFilterList = [];
            this.fieldsListMapOffset = 0;
            this.fieldsListMapTotal = 0;
            this.clearFieldListsLabelsAndFields()
            .then(() => {
                this.fieldsListMapLoader = true;
                this.setFilterOptionsAndGetFields()
                .then(() => this.getAllFieldsForMap());
            });
        },
        confirmArchiveField(fieldId, fieldOwnerId) {
            this.archiveFieldId = fieldId;
            this.archiveFieldOwner = fieldOwnerId;
            this.archiveDialog = true;
        },
        cancelFieldArchiving() {
            this.archiveFieldId = "";
            this.archiveDialog = false;
        },
        proceedFieldArchiving() {
            this.archiveDialog = false;
            this.resetSnackbarDetails();
            this.$store.dispatch("archiveField", {
                fieldId: this.archiveFieldId, 
                fieldOwnerId: this.archiveFieldOwner
            })
        },
        confirmRestoreField(fieldId, fieldOwnerId) {
            this.restoreFieldId = fieldId;
            this.restoreFieldOwner = fieldOwnerId;
            this.restoreDialog = true;
        },
        cancelFieldRestoring() {
            this.restoreFieldId = "";
            this.restoreDialog = false;
        },
        proceedFieldRestoring() {
            this.restoreDialog = false;
            this.resetSnackbarDetails();
            this.$store.dispatch("restoreField", {
                fieldId: this.restoreFieldId, 
                fieldOwnerId: this.restoreFieldOwner
            })
        },
        checkIfInternalSelectedOwner() {
            const selectedOwner = this.fieldOwnerFilterList.find((owner) => 
                owner.id == this.fieldListOwnerId
            );
            if (this.fieldListOwnerId == "All" || selectedOwner && (selectedOwner.id == localStorage.getItem(ConstantValues.ownerId) || selectedOwner.isInternal)) {
                this.isInternalSelectedOwner = true;
            } else{
                this.isInternalSelectedOwner = false;
            }
        },
        appendMoreCustomers() {
            this.clearFieldFilterOption();

            if(this.fieldListOwnerId && this.fieldFarmerSearchText) {
                this.fieldFarmerSearchText = '';
                this.fieldListOwnerId = null;
            }
            
            this.setSearchFarmersFilterAsync({
                offset: getListLengthAbsolute().getListLengthWithOwner(this.fieldOwnerFilterList),
                limit: ConstantValues.defaultPageLimitSize,
                moduleNamespace: this.customerDropdownModule
            }).then(() => {
                this.getOwnerListAsync();
            });
        },
        async setFilterOptionsAndGetFields(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            if (!this.fieldSearchText) {
                this.filtersApplied = false;
            }
            const response = await this.setFieldFilterAsync(filters)
            .then(() => {
                this.setUrlParamsFieldsLocal();
                return this.getFieldsListFilteredAsync();
            });

            return response;
        },
        async getOwnerListAsync() {
            this.$store.dispatch(`${this.customerDropdownModule}/generateQueryString`).then(queryString => {
                this.getOwnerListAction({ queryString }).then(response => {
                    this.$store.dispatch(`${this.customerDropdownModule}/updateStates`, response)
                });
            });
        },
        setLoaderHeight() {
            this.$nextTick(() => {
                setTimeout(() => {
                    const mapHeight: any = document.getElementById('field-list-map')?.clientHeight
                    const fieldLoaderDiv: any = document.getElementsByClassName('jobs-fields-loader')?.[0]
                    const skeletonLoaderDiv: any = document.getElementsByClassName('v-skeleton-loader')?.[0]
                    const skeletonLoaderImageDiv: any = document.getElementsByClassName('v-skeleton-loader__image')?.[0]
                    
                    const newHeight = mapHeight || 500;

                    if(fieldLoaderDiv) {
                        fieldLoaderDiv.style.height 
                        = skeletonLoaderDiv.style.height 
                        = skeletonLoaderImageDiv.style.height 
                        = `${newHeight}px`
                    }
                }, 10)
            })
        },
        openDrawerForAddingField() {
            this.$store.commit('setDrawerOpen', true);
            this.openAddFieldForm();
        },
        openDrawerForEditingField(...args) {
            this.fieldsInfoWindowContent?.close();
            this.$store.commit('setDrawerOpen', true);
            this.openEditFieldForm(...args);
        },
        checkIfURLIsForOpenDrawer() {
            if (routeFields !== router.currentRoute.path) {
                const drawerForAddingField = router.currentRoute.path.includes("add");
                if (drawerForAddingField) {
                    this.openDrawerForAddingField();
                } else {
                    const pathArray = router.currentRoute.path.split("/");
                    const [ownerId, fieldId] = pathArray.slice(-2);
                    this.getFieldDetailsByIdAsync([fieldId, ownerId])
                    .then((field) => this.openDrawerForEditingField([fieldId, ownerId], this.isFieldExternal(field), {...field, polygon: JSON.stringify(field.polygon)}));
                    this.$store.dispatch("getJobsByField", {
                        ownerId: ownerId,
                        resourceId: fieldId,
                    });
                    this.setDefaultOwnerList(this.loginUserBusinessName);
                    
                }
            }
        },
        calculateListHeaderOffsetTop() {
            const filters = this.$refs.fieldFilters;
            this.listHeaderOffset = filters.clientHeight
        }
    },
};
