<template>
    <div>
        <div v-for="(loader, index) in loaderRows" :key="index">
            <div class="my-10 px-7">
                <div class="ib" style="width:30%">
                    <v-skeleton-loader width="100%"
                                        height="20"
                                        type="card"></v-skeleton-loader>
                </div>
                <div class="ib" style="width:60%"></div>
                <div class="ib" style="width:10%">
                    <v-skeleton-loader width="100%"
                                        height="20"
                                        type="card"></v-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['loaderRows']
}
</script>