








import accountComputedMixin from '@/components/account/account-computed';
import FarmsListView from './account-farms-list.vue';

export default {
    name: 'AccountFarmsAddress',
    mixins: [accountComputedMixin],
    components: {
        FarmsListView
    },
    mounted() {
        this.$store.dispatch('getUserFarmAddress');
    }
}
