export const state = {
    usersList: [],
    usersListStartDate: null,
    usersListEndDate: null,
    usersListRoles: null,
    usersFiltersApplied: false,
    usersSearchText: null,
}

export const getters = {
    usersList: state => state.usersList,
    usersListStartDate: state => state.usersListStartDate,
    usersListEndDate: state => state.usersListEndDate,
    usersFiltersApplied: state => state.usersFiltersApplied,
    usersListRoles: state => state.usersListRoles,
    usersSearchText: state => state.usersSearchText,
}

export const mutations = {
    setSuUsersList(state, data) {
        data?.value.forEach(user => state.usersList.push(user))
    },
    resetUsersListFilter(state) {
        state.usersListStartDate = null;
        state.usersListEndDate = null;
        state.usersFiltersApplied = false;
        state.usersSearchText = null;
    }
}