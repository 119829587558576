export class TimeZone {
    id: string;
    name: string;
    description: string;
    offset: number;

    public setTimeZone(timeZone: TimeZone){
        this.id = timeZone.id;
        this.name = timeZone.name;
        this.description = timeZone.description;
        this.offset = timeZone.offset;
        return this;
    }
}