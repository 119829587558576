import ConstantValues from '@/plugins/constantValues';
import { BusinessProfile } from '@/data/models/BusinessProfile';
import { User } from '@/data/models';

export const state = {
    usersLoader: false,
    userProfile: new User(),
    userSignUpRoles: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4
    },
    vehiclesForUserProfileSearchText: null,
    vehiclesListForUserProfile: [],
    implementsForUserProfileSearchText: null,
    implementsListForUserProfile: [],
    inviteeProfile: {
        code: null,
        inviterName: null,
        inviteeName: null,
        inviteeEmail: null,
        inviteeRole: null,
        timestamp: null,
        expiry: null,
        inviter: null
    },
    userInviteeEmail: null,
    userInviteeRole: 4,
    userInviteeAcceptanceCode: null,
    invitationType: {
        invitation: "Invitation",
        verification: "Verification"
    },
    startingUserLatLngOnMap: null,
    map: null,
    startingLat: ConstantValues.defaultBusinessLatitude,
    startingLng: ConstantValues.defaultBusinessLongitude,
    businessProfilePrefixTemp: null,
    accountBusinessProfile: new BusinessProfile(),
    accountTabTexts: [
        'Profile', 'Business', 'Farms'
    ],
    accountFarmAddresses: [],
    accountMapSearchValue: null,
    dialogInvitation: false,
    invitationId: null,
    connectionRequestType: null,
    dialogType: null,
    shouldCallOnboardUpdate: false
}

export const getters = {
    usersLoader: state => state.usersLoader,
    userProfile: state => state.userProfile,
    userSignUpRoles: state => state.userSignUpRoles,
    vehiclesForUserProfileSearchText: state => state.vehiclesForUserProfileSearchText,
    vehiclesListForUserProfile: state => state.vehiclesListForUserProfile,
    implementsForUserProfileSearchText: state => state.implementsForUserProfileSearchText,
    implementsListForUserProfile: state => state.implementsListForUserProfile,
    inviteeProfile: state => state.inviteeProfile,
    userInviteeEmail: state => state.userInviteeEmail,
    userInviteeRole: state => state.userInviteeRole,
    userInviteeAcceptanceCode: state => state.userInviteeAcceptanceCode,
    invitationType: state => state.invitationType,
    startingUserLatLngOnMap: state => state.startingUserLatLngOnMap,
    map: state => state.map,
    startingLat: state => state.startingLat,
    startingLng: state => state.startingLng,
    businessProfilePrefixTemp: state => state.businessProfilePrefixTemp,
    accountBusinessProfile: state => state.accountBusinessProfile,
    accountTabTexts: state => state.accountTabTexts,
    accountFarmAddresses: state => state.accountFarmAddresses,
    accountMapSearchValue: state => state.accountMapSearchValue,
    dialogInvitation: state => state.dialogInvitation,
    invitationId: state => state.invitationId,
    connectionRequestType: state => state.connectionRequestType,
    dialogType: state => state.dialogType,
    shouldCallOnboardUpdate: state => state.shouldCallOnboardUpdate
}
export const mutations = {
    setUserProfile(state, data) {
        state.userProfile = new User().getUserProfileDetails(data);
        
        if (data.businessProfile != null) {
            if (data.businessProfile.config) {
                localStorage.setItem('defaultAreaMeasurementUnit', data.businessProfile.config.defaultAreaMeasurementUnit);
                state.startingLat = data.businessProfile.config.defaultLatitude == 0 ? ConstantValues.defaultBusinessLatitude : data.businessProfile.config.defaultLatitude;
                state.startingLng = data.businessProfile.config.defaultLongitude == 0 ? ConstantValues.defaultBusinessLongitude : data.businessProfile.config.defaultLongitude;
                if(data.businessProfile.config?.currency?.symbol) {
                    localStorage.setItem('currencySymbol', data.businessProfile.config.currency.symbol);
                }
                if(data.businessProfile.address?.country) {
                    localStorage.setItem('country', data.businessProfile.address.country);
                }
                localStorage.setItem('defaultStartingLat', state.startingLat);
                localStorage.setItem('defaultStartingLng', state.startingLng);
            } else {
                state.startingLat = ConstantValues.defaultBusinessLatitude;
                state.startingLng = ConstantValues.defaultBusinessLongitude;
                localStorage.setItem('defaultStartingLat', state.startingLat);
                localStorage.setItem('defaultStartingLng', state.startingLng);
            }
        } 
    },
    setClearUserProfile(state) {
        state.userProfile = new User();
    },
    setVehiclesListForUserProfile(state, data) {
        data.map(x => {
            let hasVehicle = false;
            state.vehiclesListForUserProfile.map(y => {
                if (y.id == x.id) {
                    hasVehicle = true;
                }
            });
            if (!hasVehicle) {
                state.vehiclesListForUserProfile.push(x);
            }
        });
    },
    setImplementsListForUserProfile(state, data) {
        data.map(x => {
            let hasImplement = false;
            state.implementsListForUserProfile.map(y => {
                if (y.id == x.id) {
                    hasImplement = true;
                }
            });
            if (!hasImplement) {
                state.implementsListForUserProfile.push(x);
            }
        });
    },
    setInviteeProfile(state, data) {
        state.inviteeProfile.code = data.code;
        state.inviteeProfile.inviterName = data.inviterName;
        state.inviteeProfile.inviteeName = data.inviteeFirstName + " " + data.inviteeLastName;
        state.inviteeProfile.inviteeEmail = data.inviteeEmail;
        state.inviteeProfile.inviteeRole = data.inviteeRole;
        state.inviteeProfile.timestamp = data.timestamp;
        state.inviteeProfile.expiry = data.expiry;
        state.inviteeProfile.inviter = data.inviter;
    },
    setClearInviteeProfile(state, data) {
        state.userInviteeEmail = null;
        state.userInviteeRole = 4;
    },

    setAccountBusinessProfile(state, data) {
        state.accountBusinessProfile = new BusinessProfile().getBusinessProfileDetails(data);
        if (data.config) {
                state.startingLat = data.config.defaultLatitude == 0 ? ConstantValues.defaultBusinessLatitude : data.config.defaultLatitude;
                state.startingLng = data.config.defaultLongitude == 0 ? ConstantValues.defaultBusinessLongitude : data.config.defaultLongitude;
                localStorage.setItem('currencySymbol', data.config.currency.symbol);
                localStorage.setItem('country', data.address.country);
                localStorage.setItem("defaultStartingLat", state.startingLat);
                localStorage.setItem("defaultStartingLng", state.startingLng);
            } else {
                state.startingLat = ConstantValues.defaultBusinessLatitude;
                state.startingLng = ConstantValues.defaultBusinessLongitude;
            }
    },
    
    setAccountFarmAddresses(state, data) {
        state.accountFarmAddresses = [];
        state.accountFarmAddresses = data;
    },

    clearUserProfile(state) {
        state.usersLoader = false;
        state.userProfile = new User();
    },

    setUserTimeZone(state, data) {
        state.userProfile.businessProfile.config.timezone = data || null
    }
}