



































































































































import onboardComputedMixin from "@/components/onboard/_onboard-computed";
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { checkFieldsValidity, validateRegexPhoneNumber } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';

export default {
    mixins: [onboardComputedMixin],
    components: {
        Button
    },
    data() {
        return {
            tempRatePercentage: null,
            tempRateName: null,
            searchTaxType: null
         };
    },
    mounted() {
        if(this.userProfile.businessProfile.address.country){
            const country = this.userProfile.businessProfile.address.country;
            this.getCountryTaxesName(country);
            this.getCountryTaxRates(country);
        }
        if(this.userProfile.businessProfile?.config?.invoiceTermsInDays == 0) {
            this.userProfile.businessProfile.config.invoiceTermsInDays = ConstantValues.defaultInvoicingTermsInDays;
        }
    },
    computed: {
        isValidTaxRate(){
            if (checkFieldsValidity([this.tempRatePercentage, this.tempRateName])) {
                return false;
            } else {
                return true;
            }
        }
    },
    methods: {
        appendTaxRate(){
            const taxRate = {
                rateName: this.tempRateName,
                rateInPercentage: this.tempRatePercentage
            }
            this.userProfile.businessProfile.config.salesTaxRates.push(taxRate);
            this.resetTempTaxRate();

        },
        resetTempTaxRate(){
           this.tempRateName = null;
           this.tempRatePercentage = null;
        },
        removeSelectedTaxRate(idx){
            if (idx > -1) {
                this.userProfile.businessProfile.config.salesTaxRates.splice(idx, 1);
            }
        },
        getCountryTaxesName(countryName){
            this.$store.dispatch('getCountryTaxesDefaultName', countryName).then((res) => {
                   if(res){
                      this.userProfile.businessProfile.config.salesTaxName = res;
                   }
            });
        },
        getCountryTaxRates(countryName){
            this.$store.dispatch('getCountryTaxRatesList', countryName).then((res) => {
                   if(res){
                      this.userProfile.businessProfile.config.salesTaxRates = res;
                   }
            });
        },
        checkTermsNumber(event) {
            validateRegexPhoneNumber(event);
        }
    },
};
