






















export default {
  name: "LoginRegistrationWrapper",
};
