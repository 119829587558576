

































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { ButtonActionPrimary, HoverTextButton } from '@/components/shared/buttons/index';
import { routeJobs } from '@/utils/endpoints';
import router from '@/router/index';
import { getOperatorsWithChecklists } from '@/utils/helpers/jobs-helpers';

export default {
    name: "TeamJobsAdd",
    mixins: [TeamJobsComputed],
    components: {
        ButtonActionPrimary,
        HoverTextButton
    },
    methods: {
        getOperators() {
            return getOperatorsWithChecklists(
                this.teamJobOperation,
                this.teamJobOperators,
            )
        },
        cancelAddTeamJob() {
            router.push(routeJobs);
        }
    }
}
