import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"460"},model:{value:(_vm.itemToDelete),callback:function ($$v) {_vm.itemToDelete=$$v},expression:"itemToDelete"}},[_c(VCard,{staticClass:"dlt-diag px-3 pt-2 pb-3 d-flex flex-column"},[_c(VCardTitle,{staticClass:"headline"},[_vm._t("header")],2),_c(VCardText,{staticStyle:{"line-height":"20px"}},[_vm._t("body")],2),_c(VCardActions,{staticClass:"px-6 mt-auto"},[_c(VSpacer),_c('SecondaryButton',{attrs:{"variant":"secondary","text":"","color":"primary","outlined":"","outlineColor":"primary","textValue":_vm.firstActionText ? _vm.firstActionText : _vm.$stringConstants('deletePromtDisagree')},nativeOn:{"click":function($event){return _vm.$emit('cancel')}}}),_c('SecondaryButton',{staticClass:"ml-3",attrs:{"variant":"secondary","color":"primary","textValue":_vm.secondActionText ? _vm.secondActionText : _vm.$stringConstants('deletePromtAgree')},nativeOn:{"click":function($event){return _vm.$emit('proceed')}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }