

























import TeamJobsComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-computed";
import { brandColor } from "@/utils/uiconstants";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
export default {
    name: "TeamJobsScheduledDate",
    mixins: [TeamJobsComputed, TeamJobsCommon],
    data() {
        return {
            dateProps: {
                headerColor: brandColor,
            },
            timeProps: {
                headerColor: brandColor,
                useSeconds: false,
                ampmInTitle: true,
            },
        };
    },
    components: {
        VueCtkDateTimePicker,
    },
    methods: {
        onChangeScheduleDate() {
            this.enableTeamJobDiscard();
        },
    },
};
