



























































































































































import { mapMutations } from "vuex";
import {
  containerLeftPad,
  containerRightPad,
} from "@/utils/uiconstants";
import { closeOverlay } from "@/utils/helpers";
import operationsComputedMixin from "@/components/operations/operations-computed";
import { HeaderPrimary, SubHeaderPrimary, DescriptionPrimary } from "@/components/shared/headers/index";
import { BaseButton as Button, ButtonCloseIcon } from "@/components/shared/buttons/index";
import ListLoader from "@/components/shared/loaders/list-loader.vue";

export default {
  components: {
    SubHeaderPrimary,
    DescriptionPrimary,
    ButtonCloseIcon,
    HeaderPrimary,
    Button,
    ListLoader,
  },
  data() {
    return {
      containerLeftPad,
      containerRightPad,
      fieldsInvalid: false,
      operationTitle: null,
      operationDescription: null,
      operationSelected: false,
    };
  },
  mixins: [operationsComputedMixin],
  mounted() {
    this.setClearAddOperationTypesList();
  },
  methods: {
    ...mapMutations({
      setClearOperationTypesList: "setClearOperationTypesList",
      setClearAddOperationTypesList: "setClearAddOperationTypesList",
    }),
    showAddOperation() {
      this.operationTitle = null;
      this.operationDescription = null;
      this.operationSelected = false;
      this.showAddOperationFields = true;
      this.showAddOperationBtn = false;
    },
    hideAddOperation() {
      this.showAddOperationFields = false;
      this.showAddOperationBtn = true;
    },
    addNewOperationType() {
      this.addOperationTypesList.push({
        title: this.operationTitle,
        isActive: true,
        isSelected: true,
      });

      this.operationTitle = null;
      this.operationDescription = null;
      this.hideAddOperation();
    },
    saveOperations() {
      if (
        this.addOperationTypesList.length != 0 ||
        this.requestOperationTypesList.length != 0
      ) {
        this.addOperationTypesList.map((oprType) => {
          this.requestOperationTypesList.push({
            title: oprType.title,
            isActive: oprType.isActive,
            isSelected: oprType.isSelected,
          });
        });

        let requestTypes = [];

        this.requestOperationTypesList.map((oprType) => {
          if (oprType.isActive) {
            requestTypes.push({
              title: oprType.title,
              isSelected: oprType.isSelected,
            });
          }
        });

        this.$store.dispatch("saveOperationCategoriesList", requestTypes).then(() => {
          this.setClearAddOperationTypesList();
          closeOverlay("manageOperationsFormOverlay");
        });
      }
    },
    removeOperationType(operationIndex: number) {
      if (operationIndex > -1) {
        this.addOperationTypesList.splice(operationIndex, 1);
      }
    },
    closeOperationsManageForm: function () {
      this.setClearAddOperationTypesList();
      closeOverlay("manageOperationsFormOverlay");
      this.$emit('noActiveFlyout')
    },
    getOperationNamesList(operations) {
      if(operations && operations.length) {
        return operations.map(o => o.title).join(',');
      }
      return '';
    },
    handleSubOperationActiveToggle(event, operationType) { 
      if(!operationType.isSelectable) {
        event.preventDefault();
      } else {
        operationType.isActive = !operationType.isActive
      }
    }
  },
};
