import { getComputedProperties } from "@/utils/helpers/computed-generator"

const computedProperties: Record<string, Array<string>> = {
    'integrationsModule': [
        'integrationType',
        'contacts',
        'quickbookAccessState',
        'quickBookAccess',
        'xeroAccessState',
        'xeroAccess',
        'loadingIntegration',
        'account',
        'sageAccessState',
        'sageAccess',
        'loadingIntegrationBuffer',
    ]
}

export default {
    name: 'IntegrationsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}