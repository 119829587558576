import { getTimeDetailsFormatted, getTimeFormated, getDateFormated, getTimeDetailsRequestFormated, getTimeRequestFormated } from '../../utils/helpers/time-details-helpers';

export class TeamJobTimeDetails {
    id?: string;
    createdOn?: string;
    startedAt: string;
    endedAt: string;
    startTime?: string;
    endTime?: string;
    workTimeInSeconds: number;
    breakTimeInSeconds: number;
    downTimeInSeconds: number;
    travelTimeInSeconds: number;
    workTimeFormatted?: any;
    totalTimeFormatted?: any;
    breakTimeFormatted?: any;
    downTimeFormatted?: any;
    travelTimeFormatted?: any;

    constructor() {
        this.id = null;
        this.createdOn = null;
        this.startedAt = null;
        this.endedAt = null;
        this.startTime = null;
        this.endTime = null;
        this.workTimeInSeconds = 0;
        this.breakTimeInSeconds = 0;
        this.downTimeInSeconds = 0;
        this.travelTimeInSeconds = 0;
        this.workTimeFormatted = null;
        this.totalTimeFormatted = null;
        this.breakTimeFormatted = null;
        this.travelTimeFormatted = null;
        this.downTimeFormatted = null;
    }

    public getTeamJobTimeDetails?(time: TeamJobTimeDetails) {
        this.id = time.id;
        this.createdOn = time.createdOn ? time.createdOn : null;
        this.startedAt = time.startedAt;
        this.startTime = getDateFormated(time.startedAt);
        this.endTime = getDateFormated(time.endedAt);
        this.endedAt = time.endedAt;
        this.workTimeInSeconds = time.workTimeInSeconds;
        this.breakTimeInSeconds = time.breakTimeInSeconds;
        this.downTimeInSeconds = time.downTimeInSeconds;
        this.travelTimeInSeconds = time.travelTimeInSeconds;
        this.workTimeFormatted = time.workTimeInSeconds;
        this.totalTimeFormatted = time.workTimeInSeconds + time.breakTimeInSeconds + time.downTimeInSeconds;
        this.travelTimeFormatted = getTimeDetailsFormatted(time.travelTimeInSeconds);
        this.breakTimeFormatted = getTimeDetailsFormatted(time.breakTimeInSeconds);
        this.downTimeFormatted = getTimeDetailsFormatted(time.downTimeInSeconds);
        return this;
    }

    public getTeamJobTimeDetailsRequest?(timeRecord: TeamJobTimeDetails) {
        const timeRecordObject = {
            id: timeRecord.id,
            startedAt: getTimeDetailsRequestFormated(timeRecord.startTime),
            endedAt: getTimeDetailsRequestFormated(timeRecord.endTime),
            breakTimeInSeconds: getTimeRequestFormated(timeRecord.breakTimeFormatted),
            downTimeInSeconds: getTimeRequestFormated(timeRecord.downTimeFormatted),
            travelTimeInSeconds: getTimeRequestFormated(timeRecord.travelTimeFormatted)
        }
        return timeRecordObject;
    }
}