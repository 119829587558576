import {
    apiCards,
    apiSetDefaultCard,
    apiSubscriptions,
    apiSubscriptionsPricing,
    apiSubscriptionsCancel,
    apiPromoCode
} from '@/utils/endpoints';
import { devLogger, notify } from '@/utils/helpers';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import { enableDashboardNavigation } from '@/utils/helpers/layout-helpers';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';

export const actions = {
    async getCardsList({ rootState, state, commit, dispatch }) {
        state.cardsLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiCards
        });
        const result = await requests.getData(url);
        if (result != null && result.data != null) {
            result.data.value.length > 0 ? commit('setCardsList', result.data.value) : commit('setNoCardsStatus', result.data.value.length)
            state.cardsLoader = false;
        } else {
            devLogger().logError(result);
            state.cardsLoader = false;
        }
    },

    async getInvoicesHistory({ rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiSubscriptions + '/invoices'
        });
        const result = await requests.getData(url);

        if (result && result.data && result.data.value) {
            commit('setSubscriptionInvoicesList', result.data.value)
            return
        }
        commit('setSubscriptionInvoicesList', [])
    },

    async addCard({ rootState, state, dispatch }) {
        state.cardsLoader = true;
        state.showAddCardSubscriptionDialog = false;
        const formData = new FormData();
        formData.append('CardSource', state.cardSourceToken);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiCards
        });
        const result = await requests.postData(url, formData);
        if (result != null && result.length != 0) {
            state.cardsLoader = false;
            dispatch('getCardsList');
            return result;
        } else {
            state.cardsLoader = false;
            devLogger().logError(result);
        }
    },

    async setDefaultCard({ rootState, state, dispatch }, cardId) {
        state.cardsLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiCards + '/' + cardId + apiSetDefaultCard
        });
        const result = await requests.postData(url, null);
        if (result != null) {
            state.cardsLoader = false;
            await dispatch('getCardsList');
        } else {
            state.cardsLoader = false;
            devLogger().logError(result);
        }
    },

    async cancelSubscription({ rootState, state }) {
        state.subscriptionPlanLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiSubscriptionsCancel
        });
        const result = await requests.postData(url, {
            reason: state.subscriptionCancelReason ? state.subscriptionCancelReason : ''
        });
        state.subscriptionPlanLoader = false;
        if (result != null) {
            notify(StringConstants.subscriptionCancelSuccessMessage, 'success');
            localStorage.removeItem('subscriptionStatus');
            localStorage.removeItem('trialDaysRemaining');
            setTimeout(() => {
                location.reload();
            }, 2000);
        } else {
            devLogger().logError(result);
        }
    },

    async withdrawCancellation({ rootState, state }) {
        state.subscriptionPlanLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiSubscriptions + '/withdraw-cancellation'
        });
        const result = await requests.postData(url, null);
        state.subscriptionPlanLoader = false;
        if (result != null) {
            notify(StringConstants.subsciptionWithdrawlMessage, 'success');
            setTimeout(() => {
                location.reload();
            }, 2000);
        } else {
            devLogger().logError(result);
        }
    },

    async deleteCard({ rootState, state }, cardId) {
        state.cardsLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiCards + '/' + cardId
        });
        const result = await requests.deleteData(url);
        if (result.status == 200) {
            state.cardsLoader = false;
            return result.status;
        } else {
            state.cardsLoader = false;
            devLogger().logError(result);
        }
    },

    async getStripePublishKey({ commit, rootState }) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/payments/publish_key'
        });
        const result = await requests.getData(url);
        if (result != null && result.data != null) {
            commit('setStripePublishKey', result.data)
        } else {
            devLogger().logError(result);
        }
    },

    async getSubscriptionDetails({ commit, rootState, state }) {
        state.subscriptionPlanLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiSubscriptions
        });
        const result = await requests.getData(url);
        state.subscriptionPlanLoader = false;
        if (result != null && result.data != null) {
            commit('setSubscriptionDetails', result.data);
            return result.data;
        } else {
            devLogger().logError(result);
        }
    },

    async getSubscriptionPricing({ commit, rootState, state }) {
        state.pricingLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiSubscriptionsPricing
        });
        const result = await requests.getData(url);
        state.pricingLoader = false;
        if (result != null && result.data != null) {
            commit('setSubscriptionPricing', result.data);
            return result.data;
        } else {
            devLogger().logError(result);
        }
    },

    async createStripeSubscription({ rootState, state, dispatch }) {
        state.subscriptionPlanLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiSubscriptions
        });
        const promoCodePayload = state.promotionalCodeId ? {
            'promotionalCodeId': state.promotionalCodeId
        } : {}

        const result = await requests.postData(url, promoCodePayload);
        state.subscriptionPlanLoader = false;
        if (result[0] != null) {
            notify(StringConstants.subscriptionPlanFetchInProgress, 'success');
            enableDashboardNavigation();
            await dispatch('getSubscriptionDetails');
            return result;
        } else {
            console.error(result);
        }
    },

    async getPromoCodeDetails({ commit, rootState, state }) {
        state.promoCodeLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiPromoCode + state.subscriptionPromocode
        });
        const result = await requests.getDataRes(url);
        state.promoCodeLoader = false;
        if (result && result.data) {
            state.subscriptionSuccessDescription = result.data.description;
            commit('setPromoCodeDetails', result.data);
            return result.data;
        } else {
            devLogger().logError(result);
        }
    },
}