import store from '@/store/index';
import buildUrl from 'build-url';
import { getDateDifferenceInDays, getDateDifferenceInMinutes, sleep } from '../helpers';
import requests from '../requests';

export const showFullDashboardView = () => {
    store.state.showAllDashboardViews = true;
    localStorage.setItem('showAllDashboardViews', '1');
}

export const hideFullDashboardView = () => {
    store.state.showAllDashboardViews = false;
    localStorage.setItem('showAllDashboardViews', '0');
}

export const disableDashboardNavigation = () => {
    store.state.disabledNavigation = true;
    localStorage.setItem('disabledNavigation', '1');
}

export const enableDashboardNavigation = () => {
    store.state.disabledNavigation = false;
    localStorage.setItem('disabledNavigation', '0');
}

export const showTrialInfo = (trialEndDate: string) => {
    if (parseInt(getDateDifferenceInDays(trialEndDate)) > 0) {
        localStorage.setItem('showTrialInfo', '1');
        localStorage.setItem('trialDaysRemaining', parseInt(getDateDifferenceInDays(trialEndDate)).toString());
    } else {
        localStorage.setItem('showTrialInfo', '0');
    }
}

export const hideTrialInfo = () => {
    localStorage.setItem('showTrialInfo', '0');
}

export const isSystemTrialPeriodOver = (trialPeriodEndDate) => {
    return parseInt(getDateDifferenceInMinutes(trialPeriodEndDate)) <= 0
        || trialPeriodEndDate == null;
}

export const enableScrollToConfig = () => {
    localStorage.setItem('scrollToConfig', "1");
}

export const disableScrollToConfig = () => {
    localStorage.setItem('scrollToConfig', "0");
}

export const checkAppVersion = async (state) => {

    async function setDashboardVersion() {
        await sleep(2000);
        localStorage.setItem('version', deployedVersion);
        location.reload();
    }

    const url = buildUrl(state.baseUrl, {
        path: '/version/dashboard'
    });
    const result = await requests.getDataWithoutAuth(url);
    let deployedVersion = null;

    if (result) {
        deployedVersion = result.data;

        const loadedVersion = localStorage.getItem('version');

        if (loadedVersion && deployedVersion) {
            if (loadedVersion.toString() === deployedVersion.toString()) {
                return;
            } else {
                await setDashboardVersion();
            }
        }

        if (!loadedVersion) {
            await setDashboardVersion();
        }
    } else {
        return;
    }
}