











































import {BaseButton as Button} from "@/components/shared/buttons";
export default {
    name: "Notification",
    components: {Button},
    computed: {
        notificationCallback() {
            return this.$store.state.notification?.callback;
        },
        hasActionListener() {
            return Boolean(this.notificationCallback);
        },
        notificationTitle() {
            return this.$store.state.notification?.title;
        },
        notificationMessage() {
            return this.$store.state.notification?.message;
        },
        showNotification: {
            get() {
                return Boolean(this.notificationMessage);
            },
            set(visible) {
                if (!visible) this.$store.commit('cleanUpNotification');
                return;
            }
        },
        actionText() {
            return this.$store.state.notification?.actionText;
        },
        type() {
            return this.$store.state.notification?.type;
        },
    },
    methods: {
        dismissNotification() {
            this.$store.commit('cleanUpNotification');
        },
        executeNotificationCallback() {
            this.notificationCallback();
            this.$store.commit('cleanUpNotification');
        }
    }
}
