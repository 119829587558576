<template>
    <div class="mt-1">
        <div v-for="(loader, index) in loaderRows" :key="index">
            <div class="mt-3">
                <v-skeleton-loader width="70"
                                    height="20"
                                    type="card"></v-skeleton-loader>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['loaderRows']
}
</script>