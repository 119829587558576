import * as allStrings from '@/utils/strings';

const StringConstants: any = {
    ...allStrings
}

StringConstants.install = function(Vue) {
    Vue.prototype.$stringConstants = (key) => {
        return StringConstants[key]
    }
}

export default StringConstants;