import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule': [
        'teamJobUsersList',
        'teamJobUserSearchText',
        'teamJobUser',
        'teamJobOwnerType',
        'teamJobContractorId',
        'teamJobUsersListSize',
        'teamJobUsersListLoader'
    ]
}

export default {
    name: 'TeamJobsCustomersComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        isFarmerLoggedIn() {
            return getLoggedInUserRole().isUserOwnerFarmerLoggedIn;
        },
    }
}