export default {
    data() {
        return {
            observer: null,
        }
    },
    methods: {
        initializeResizeObserver(target: Element, callback) {
            this.observer = new ResizeObserver(entries => {
                entries.forEach(({contentBoxSize}) => contentBoxSize[0].inlineSize > 0 && callback())
            });
            this.observer?.observe(target)
        },
        initializeIntersectionObserver(target: Element, callback: () => void) {
            const options = {
                root: null,
                threshold: 0.5
            }

            this.observer = new IntersectionObserver(entries => {
                entries.forEach(({isIntersecting}) => {
                    isIntersecting && callback()
                })
            }, options);

            this.observer?.observe(target);
        }
    },
    beforeDestroy() {
        this.observer?.disconnect();
    }
}