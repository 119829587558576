































































import $ from 'jquery';
import { closeOverlay, checkFieldsValidity, clearDateTimePicker, initializeDateTimePlaceholders } from '@/utils/helpers';
import { formFieldPb, containerLeftPad, containerRightPad, vdprMaxWidth, vdprMinWidth } from '@/utils/uiconstants';
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import usersComputedMixin from './su-users-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import ConstantValues from '@/plugins/constantValues';
import { mapMutations } from "vuex";
import moment from 'moment';
import StringConstants from '@/plugins/stringConstants';
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
import { defaultDateFormat } from '@/utils/constants';

export default {
    components: {
        Button,
        HeaderPrimary,
        DatePicker,
    },
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
            filterDateInput: {
                placeholder: StringConstants.selectDatePlaceholder
            },
            filterFormat: defaultDateFormat,
            filterSameDateFormat: {
                from: defaultDateFormat,
                to: defaultDateFormat
            },
        }
    },
    mixins: [usersComputedMixin],
    computed: {
        isDisabledBtn() {
            return false
        }
    },
    mounted() {
        initializeDateTimePlaceholders()
    },
    methods: {
        ...mapMutations({
            clearUsersFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging",
        }),
        closeUsersFilterForm: function () {
            closeOverlay("usersFilterFormOverlay")
        },
        appplyFilter(filter = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            this.usersList = []
            this.clearUsersFilterOptions()
            this.resetPaging()
            this.$store.dispatch('setSuUsersListFilter', filter).then(() => {
                this.$store.dispatch('getSuUsersList')
            })
            this.closeUsersFilterForm()
        },
        setDateRangeFilterForUsers(start, end) {
            $('.vdpr-datepicker input').first().css({ width: vdprMaxWidth })
            this.usersListStartDate = moment(start).utc().format()
            this.usersListEndDate = moment(end).utc().format()
        },
        clearDateRange() {
            $('.vdpr-datepicker input[type=text]').first().val('');
            $('.vdpr-datepicker input').first().css({ width: vdprMinWidth });
            clearDateTimePicker(this.$refs.datePicker.$el);
            this.usersListStartDate = this.usersListEndDate = null;
        }
    }
}
