































































































































import { checkFieldsValidity } from '@/utils/helpers';
import { formFieldPb } from '@/utils/uiconstants';
import { mapMutations } from 'vuex';
import farmersComputedMixin from '@/components/farmers/farmers-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';

export default {
    name: 'FarmerEditForm',
    mixins: [farmersComputedMixin],
    props: [
        'intgrationType'
    ],
    data() {
        return {
            formFieldPb,
            updateCustomerLoader: false
        }
    },
    components: {
        Button
    },
    computed: {
        isFieldsValid() {
            if (checkFieldsValidity([
                this.farmerDetails.name,
                this.farmerDetails.companyName,
                this.farmerDetails.address.addressLine1
            ])) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapMutations({
            clearFarmerFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),

        editfarmerDetails: function (intgrationType) {
            this.updateCustomerLoader = true;
            const farmerDto = {
                "name": this.farmerDetails.name,
                "companyName": this.farmerDetails.companyName,
                "emailAddress": this.farmerDetails.emailAddress,
                "phoneNumber": this.farmerDetails.phoneNumber,
                "address": this.farmerDetails.address,
                "id": this.farmerDetails.id,
                "customer": this.farmerDetails.customer,
            }
            this.$store.dispatch('updateIntegratedCustomerDetails', [farmerDto, intgrationType]).then((res) => {
                if (res != undefined) {
                    this.contactsIntegration[this.contactsIntegration.findIndex(el => el.id === res.id)] = res;
                    this.updateFormEnabled = false;
                }
                this.updateCustomerLoader = false;
            });
        }
    }
}
