import { state, getters, mutations } from './mutations';
import { actions } from './actions';


export default ({
    state,
    mutations,
    getters,
    actions,
});
