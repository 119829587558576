export const state = {
    jobsCalendarData: [],
    jobsCalendarStartDate: [],
    jobsCalendarEndDate: [],
    jobsCalendarLoader: false,
    jobsUnscheduledJobsData: [],
    jobsUnscheduledJobsSearchText: ''
}

export const getters = {
    jobsCalendarData: state => state.jobsCalendarData,
    jobsCalendarStartDate: state => state.jobsCalendarStartDate,
    jobsCalendarEndDate: state => state.jobsCalendarEndDate,
    jobsCalendarLoader: state => state.jobsCalendarLoader,
    jobsUnscheduledJobsData: state => state.jobsUnscheduledJobsData,
    jobsUnscheduledJobsSearchText: state => state.jobsUnscheduledJobsSearchText
}

export const mutations = {
    setJobsCalendarData(state, data) {
        data.forEach((job: any) => state.jobsCalendarData.push(job));
    },
    resetJobsCalendarData(state) {
        state.jobsCalendarData = [];
        state.jobsCalendarStartDate = null;
        state.jobsCalendarEndDate = null;
        state.jobsCalendarLoader = false;
    }
}
