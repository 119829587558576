

















export default {
 props: [
     'teamTypeText',
     'membersText',
     'perMemberCostText',
     'perMemberCostSubtitleText',
     'teamTypeColor'
 ],
};
