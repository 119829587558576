


















import { closeOverlay } from "@/utils/helpers";
import ImportData from "@/components/shared/import/import-csv-list.vue";
import { mapMutations } from "vuex";
import farmersComputedMixin from "@/components/farmers/farmers-computed";
import { ImportResourceType } from "@/enum/importResourceType";

const customerInfo = {
    nameField: JSON.parse('"name*"'),
    emailField: JSON.parse('"email_address"'),
    primaryContactNumberField: JSON.parse('"primary_contact_number"'),
    secondaryNameField: JSON.parse('"secondary_name"'),
    secondaryContactNumberField: JSON.parse('"secondary_contact_number"'),
    businessNameField: JSON.parse('"business_name*"'),
    businessJobCodeField: JSON.parse('"business_job_code"'),
    businessAddressLine1: JSON.parse('"business_address_line1*"'),
    businessAddressLine2: JSON.parse('"business_address_line2"'),
    businessAddressCity: JSON.parse('"business_address_city*"'),
    businessAddressStateOrCounty: JSON.parse('"business_address_state_or_county*"'),
    businessAddressCountry: JSON.parse('"business_address_country"'),
    businessAddressPostalCode: JSON.parse('"business_address_postal_code*"'),
    businessRegistrationNumber: JSON.parse('"business_registration_number"'),
    businessTaxNumber: JSON.parse('"business_tax_number"'),
}

let customerObj = {}

customerObj[customerInfo.nameField] = '';
customerObj[customerInfo.emailField] = '';
customerObj[customerInfo.primaryContactNumberField] = '';
customerObj[customerInfo.secondaryNameField] = '';
customerObj[customerInfo.secondaryContactNumberField] = '';
customerObj[customerInfo.businessNameField] = '';
customerObj[customerInfo.businessJobCodeField] = '';
customerObj[customerInfo.businessAddressLine1] = '';
customerObj[customerInfo.businessAddressLine2] = '';
customerObj[customerInfo.businessAddressCity] = '';
customerObj[customerInfo.businessAddressStateOrCounty] = '';
customerObj[customerInfo.businessAddressCountry] = '';
customerObj[customerInfo.businessAddressPostalCode] = '';
customerObj[customerInfo.businessRegistrationNumber] = '';
customerObj[customerInfo.businessTaxNumber] = '';

export default {
    name: "FarmerImportForm",
    mixins: [farmersComputedMixin],
    components: {
        ImportData,
    },
    data() {
        return {
            customerSampleData: [
                customerObj
            ],
            requiredImportFields: [
                "name",
                "business_name",
                "business_address_line1",
                "business_address_city",
                "business_address_state_or_county",
                "business_address_postal_code"
            ],
            uploadNotes: [
                'Do not delete any column from the sample file. If you do not wish to provide information in an optional column, leave the data blank.',
                'Avoid using comma in any of the row data. This might corrupt the file.',
                'Required columns: name, business_name, business_address_line1, business_address_city, business_address_state_or_county, and business_address_postal_code.'
            ],
            importResourceType: ImportResourceType.Customers
        };
    },
    methods: {
        ...mapMutations({
            clearFarmerFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
        }),
        closeImportFarmerForm: function () {
            closeOverlay("importCustomerFormOverlay");
        },
        refreshFarmersList() {
            this.getFarmersListView()
        }
    },
};
