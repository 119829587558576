import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule': [
        'teamJobOperation',
        'teamJobOperationsList',
        'teamJobOperationsSearchText',
        'teamJobOperationOwnerId',
        'teamJobOperationsListLoader',
        'teamJobOperationsListSize'
    ]
}

export default {
    name: 'TeamJobsOperationsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}