






















export default {
    name: "BaseButton",
    props: [
        'textValue',
        'outlined',
        'outlineColor',
        'color',
        'width',
        'disabled',
        'loading',
        'variant',
        'text',
        'leftIcon',
        'rightIcon',
        'plain',
        'attrs',
        'on'
    ],
}
