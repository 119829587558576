
import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'usersModule': [
        'inviteeProfile',
        'userProfile'
    ]
}

export default {
    name: 'UsersComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}