







































import $ from 'jquery';
import InvoicesListComponent from '@/components/invoices/invoices-list/invoices-list.vue';
import InvoicesListExternalComponent from '@/components/invoices-external/invoices-external-list/invoices-external-list.vue';
import { brandColor } from '@/utils/uiconstants';
import { closeOverlay } from '@/utils/helpers';
import InvoiceJobsPreviewFlyout from '@/components/invoices/invoices-jobs-flyout/invoices-jobs-flyout.vue';
import InvoiceComputedMixin from '@/components/invoices/invoices-computed';
import {mapMutations} from 'vuex';

export default {
    data() {
        return {
            activeInvoiceTab: 0,
            brandColor,
        }
    },
    components: {
        InvoicesListComponent,
        InvoicesListExternalComponent,
        InvoiceJobsPreviewFlyout,
    },
    mixins: [InvoiceComputedMixin],
    watch: {
        activeInvoiceTab() {
            const $internalInvoiceState = this.$store.state.invoicesModule;
            const $externalInvoiceState = this.$store.state.invoicesExternalModule;

            if (this.activeInvoiceTab == 0) {
                this.$store.dispatch("fnSetInvoicesFilter", [$internalInvoiceState.invoicesListOffset, $internalInvoiceState.invoicesListLimit])
            } else if (this.activeInvoiceTab == 1) {
                this.$store.dispatch("fnSetInvoicesFilterExternal", [$externalInvoiceState.invoicesListExternalOffset, $externalInvoiceState.invoicesListExternalLimit])
            }
        }
    },
    computed: {
        integrationName() {
            return this.integrationType?.charAt(0).toUpperCase() + this.integrationType?.slice(1).toLowerCase();
        }
    },
    methods: {
        ...mapMutations({
            resetPaging: "resetPaging"
        }),
    },
    beforeMount() {
        this.resetPaging();
    },
    mounted() {
        $('body').on('click', '#overlay', function () {
            closeOverlay("invoiceJobsPreviewFlyout");
        });
        this.$store.state.dashboardTitle = 'Invoices';
        this.loadingIntegration = true;
        $('.v-tabs').hide();

        this.$store.dispatch("getExternalInvoicingServiceAccess").then(() => {
            this.loadingIntegration = false;
            if (this.xeroAccessState == 2) {
                this.integrationType = 'xero'
            } else if (this.quickbookAccessState == 2) {
                this.integrationType = 'quickbooks'
            } else if (this.sageAccessState == 2) {
                this.integrationType = 'sage'
            }

            if (this.integrationType) {
                if (this.invoicesList.length !== 0) {
                    $('.v-tabs').show();
                }

                this.resetPaging();
                this.enableExternalInvoicingExternal = true;
                this.selectedInvoiceTabTextExternal = this.integrationType.charAt(0).toUpperCase() + this.integrationType.slice(1);
                this.$nextTick(() => this.activeInvoiceTab = 1)
            }
        });
    },
}
