import { User } from '@/data/models';

export const state = {
    contactLoader: false,
    contactDetailsLoader: false,
    contactProfile: new User(),
    contactFieldList: [],
    contactOnGoingJobList: [],
    contactFarmAddresses: []
}

export const getters = {
    contactLoader: state => state.contactLoader,
    contactProfile: state => state.contactProfile,
    contactDetailsLoader: state => state.contactDetailsLoader,
    contactFieldList: state => state.contactFieldList,
    contactOnGoingJobList: state => state.contactOnGoingJobList,
    contactFarmAddresses: state => state.contactFarmAddresses,
}
export const mutations = {
    setContactProfile(state, data) {
        state.contactProfile = new User().getUserProfileDetails(data);
     },

    resetContactProfile(state) {
        state.contactProfile = new User();
    },

    setContactFieldList(state, data) {
        state.contactFieldList = data;
    },

    setContactOnGoingJobsList(state, data) {
        state.contactOnGoingJobList = data;
    },

    setContactFarmsList(state, data) {
        state.contactFarmAddresses = [];
        state.contactFarmAddresses = data;
    }
}