

































































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { getShortName } from '@/utils/helpers';
import OwnerAvatar from '@/components/shared/avatars/avatar.vue';

export default {
    name: "TeamJobsLocationMap",
    mixins: [TeamJobsComputed],
    components: {
        OwnerAvatar
    },
    mounted() {
        this.$store.dispatch('initializeTeamJobsLocationMap');
    },
    methods: {
        filterLocationByOperator() {
            this.operatorRealTimeLocation = [];
            this.selectedOperatorLocations = [];
            this.$store.dispatch('getOperatorLocationHistory', [this.jobResourceId, this.teamJobLocationSelectedOperatorId]).then((res) => {
                this.$store.dispatch('clearOperatorPolylines');
                this.$store.dispatch('drawSelectedOperatorPolylines');
            });
        },
        getShortOperatorName(operatorName) {
            return getShortName(operatorName, 20)
        }
    }
}
