<template>
  <svg
    width="200"
    height="30"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <g class="layer">
      <title>Field</title>
      <path
        d="m1.88501,27.955l0,-26l195.00001,0l0,26l-195.00001,0z"
        :fill="jobsMapLabelColorBackground"
        id="svg_3"
        stroke="#ffffff"
      />
      <text
        fill="#ffffff"
        font-family="Helvetica"
        font-size="14"
        id="svg_2"
        stroke="#000000"
        stroke-width="0"
        text-anchor="middle"
        transform="matrix(0.9827 0 0 1 3.23004 0)"
        x="98.9824"
        xml:space="preserve"
        y="21"
      >
        {{ fieldName }}
      </text>
    </g>
  </svg>
</template>

<script>
import { jobsMapLabelColorBackground } from "@/utils/uiconstants";

export default {
  props: ["fieldName"],
  data() {
    return {
      jobsMapLabelColorBackground,
    };
  },
};
</script>