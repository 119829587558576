



































































import { closeOverlay } from "@/utils/helpers";
import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import Avatar from "@/components/shared/avatars/avatar.vue";
import SubHeaderPrimary from "@/components/shared/headers/subheader-primary.vue";
import DescriptionPrimary from "@/components/shared/headers/description-primary.vue";
import contractorsComputedMixin from "@/components/contractors/contractors-computed";
import Tabs from "@/components/shared/tabs.vue";
import { BaseButton as Button } from '@/components/shared/buttons/index';

export default {
    name: "ContractorManageForm",
    mixins: [contractorsComputedMixin],
    components: {
        Avatar,
        SubHeaderPrimary,
        DescriptionPrimary,
        Tabs,
        Button
    },
    data() {
        return {
            activeTab: null,
            tabsRef: null,
            containerLeftPad,
            containerRightPad,
        };
    },
    methods: {
        closeContractorManageForm() {
            closeOverlay("manageContractorFormOverlay");
        },
        handleTabChange(currentTabIndex) {
            if (currentTabIndex == 0) {
                this.$store.dispatch("getConnectionRequestsReceived");
            } else {
                this.$store.dispatch("getConnectionRequestsSent");
            }
        },
    },
};
