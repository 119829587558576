




import {mapActions} from 'vuex';
export default {
    name: "JobsReport",
    computed: {
        jobId() {
            return this.$route.params.id
        }
    },
    data() {
        return {
            jobsReportURI: null
        }
    },
    created() {
        setTimeout(() => {
                    const navDrawer: any = document.querySelector('.v-navigation-drawer')
                    navDrawer.style.display = 'none'

                    const systemBar: any = document.querySelector('.v-system-bar')
                    systemBar.style.display = 'none'

                    document.querySelector('.v-main').classList.remove('pL-main')
        }, 100)
    },
    mounted() {
        const reportUrl = new URLSearchParams(window.location.search)
        this.jobsReportURI = reportUrl.get('pdf_url')
    }
}
