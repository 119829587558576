


















































import farmersComputedMixin from '@/components/farmers/farmers-computed';
import Avatar from '@/components/shared/avatars/avatar.vue';
import AvatarDisabled from '@/components/shared/avatars/avatar-disabled.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';

export default {
    name: 'FarmerListConnectionRequest',
    mixins: [farmersComputedMixin],
    components: {
        Avatar,
        AvatarDisabled,
        Button
    }
}
