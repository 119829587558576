import filterParamsMixin from "@/mixins/filter-params-mixin"
import { mapMutations } from "vuex"
import { getComputedProperties } from "@/utils/helpers/computed-generator"
import ConstantValues from '@/plugins/constantValues';

const computedProperties: Record<string, Array<string>> = {
    'machinesModule': [
        'addmachineLoader',
        'machineAddCheckListItems',
        'showAddmachineCheckListItemFields',
        'showAddmachineCheckListItemBtn',
        'machineDetails',
        'editmachineLoader',
        'machineListFiltered',
        'machineSearchText',
        'machinesListColumnTitles',
        'machinesLoader',
        'machinesFilterType',
    ],
    'root': [
        'enumOwnership',
        'listCount',
        'noData',
        'filtersApplied',
        'enumActiveStatus',
        'totalRowCount',
        'snackbarShow',
        'allItemsDeleted',
        'selectedItems',
        'machineType'
    ]
}

export default {
    name: 'MachinesComputedMixin',
    mixins: [filterParamsMixin],
    computed: {
        ...getComputedProperties(computedProperties)
    },
    methods: {
        ...mapMutations({
            clearMachineDetailsState: "resetMachineDetails",
            clearMachineFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging",
            resetSnackbarDetails: "clearSnackbarDetails",
        }),
        removeLocalMachineParams() {
            this.machineSearchText = this.removeLocalParamsMx("machineSearchQuery", '');
            this.machinesFilterType = this.removeLocalParamsMx("machineFilterType", 0);
        },
        clearAndResetMachinesPagingStates() {
            this.machineListFiltered = [];
            this.clearMachineDetailsState();
            this.clearMachineFilterOptions();
            this.removeLocalMachineParams();
            this.resetSnackbarDetails();
            this.resetPaging();
            this.filtersApplied = false;
        },
    }
}