

























































































































































































import onboardComputedMixin from "@/components/onboard/_onboard-computed";
import OnboardBusinessProfile from "@/components/onboard/onboard-business-profile.vue";
import OnBoardLocationInfo from "@/components/onboard/onboard-preferences.vue";
import OnBoardInvoicingInfo from "@/components/onboard/onboard-invoicing.vue";
import ButtonActionPrimary from "@/components/shared/buttons/button-action-primary.vue";
import {
    checkFieldsValidity,
    isShortCodeLengthValid,
    notify,
} from "@/utils/helpers";
import { mapMutations } from "vuex";
import moment from "moment";
import { UserRoleType } from "@/enum/userRoleType";

export default {
    mixins: [onboardComputedMixin],
    data() {
        return {
            configStepsText: {
                userVerified: "Verify user",
                businessInfo: "Set business",
                config: "Preferences",
                invoicing: "Invoicing & Taxes",
            }
        };
    },
    components: {
        OnboardBusinessProfile,
        ButtonActionPrimary,
        OnBoardLocationInfo,
        OnBoardInvoicingInfo
    },
    computed: {
        invalidShortCodeLength() {
            return !isShortCodeLengthValid(this.businessProfilePrefixTemp);
        },
        invalidConfig() {
            if (
                checkFieldsValidity([
                    this.userProfile.businessProfile.config.currency,
                    this.onboardConfigTempColor,
                ])
            ) {
                return false;
            } else {
                return true;
            }
        },
        isBusinesProfileFieldsInvalid() {
            if (
                checkFieldsValidity([
                    this.userProfile.businessProfile.title,
                    this.businessProfilePrefixTemp,
                    this.userProfile.businessProfile.address.addressLine1,
                    this.userProfile.businessProfile.address.postalCode,
                ])
            ) {
                return false;
            } else {
                return true;
            }
        },
        loginUserName() {
            return this.$store.getters.loginUserName;
        },
    },
    mounted() {
        this.setClearUserProfile();
        this.$store.commit("clearUserProfile");
        this.$store.dispatch("getGlobalCurrenciesList");
        this.$store.dispatch("getGlobalTimeZonesList");
        this.$store.dispatch("getGlobalCountriesList");
        this.$store.dispatch("getDefaultTaxesNameList");
        this.$store.dispatch("getUserProfileForOnboard").then(() => {
            this.businessProfilePrefixTemp =
                this.userProfile.businessProfile &&
                    this.userProfile.businessProfile.shortTitle
                    ? this.userProfile.businessProfile.shortTitle.toUpperCase()
                    : "";
        })
        setTimeout(() => {
            this.checkMissingConfig();
        }, 1000);
    },
    methods: {
        ...mapMutations({
            setClearUserProfile: "setClearUserProfile",
        }),
        updateOnboarding() {
            alert("Update onboarding");
        },
        backToBusinessProfile() {
            this.selectBusinessInfoTab();
        },
        selectVerifyUserTab() {
            this.onboardingStateSelected = this.onboardSelectUserVerified;
        },
        selectBusinessInfoTab() {
            this.onboardingStateSelected = this.onboardSelectUserVerified;
        },
        selectLocationInfoTab() {
            this.onboardingStateSelected = this.onboardSelectUserBusinessInfoFilled;
        },
        selectInvoicingInfoTab() {
            this.onboardingStateSelected = this.onboardSelectUserInvoicingInfoFilled;
        },
        saveUserBusinessProfile() {
            this.onboardLoader = true;

            const businessProfileUpdateObj = {
                vatNumber: this.userProfile.businessProfile.vatNumber,
                address: {
                    addressLine1: this.userProfile.businessProfile.address.addressLine1,
                    addressLine2: this.userProfile.businessProfile.address.addressLine2,
                    city: this.userProfile.businessProfile.address.city,
                    state: this.userProfile.businessProfile.address.state,
                    country: this.userProfile.businessProfile.address.country,
                    coordinate: this.userProfile.businessProfile.address.coordinate,
                    postalCode: this.userProfile.businessProfile.address.postalCode,
                },
                registrationNumber: this.userProfile.businessProfile.registrationNumber,
                title: this.userProfile.businessProfile.title,
                paymentInstructions:
                    this.userProfile.businessProfile.paymentInstructions,
                shortTitle: this.businessProfilePrefixTemp.toUpperCase(),
            };

            if (this.shouldCallOnboardUpdate) {
                this.$store
                    .dispatch(
                        "updateUserBusinessProfileForOnboarding",
                        businessProfileUpdateObj
                    )
                    .then((response) => {
                        if (response) {
                            setTimeout(() => {
                                if (response.address.city || response.address.country) {
                                    this.onboardMapSearchValue =
                                        (response.address.city ? response.address.city : "") +
                                        ", " +
                                        (response.address.country ? response.address.country : "");
                                    this.$store.dispatch("requestGooglePlacesSearch");
                                    if (this.userProfile.businessProfile.config) {
                                        this.userProfile.businessProfile["config"]["city"] =
                                            this.userProfile.businessProfile.address.city;
                                    }
                                }
                            }, 1000);
                            this.onboardLoader = false;
                            this.selectLocationInfoTab();
                        }
                    });
            } else {
                this.$store
                    .dispatch(
                        "addUserBusinessProfileForOnboarding",
                        businessProfileUpdateObj
                    )
                    .then((response) => {
                        if (response) {
                            setTimeout(() => {
                                if (response.address.city || response.address.country) {
                                    this.onboardMapSearchValue =
                                        (response.address.city ? response.address.city : "") +
                                        ", " +
                                        (response.address.country ? response.address.country : "");
                                    this.$store
                                        .dispatch("requestGooglePlacesSearch")
                                        .then((placesResponseLocation) => {
                                            if (placesResponseLocation) {
                                                this.$store.dispatch(
                                                    "setMarkerOnPlacesResponse",
                                                    placesResponseLocation
                                                );
                                            }
                                        });
                                }
                                if (this.userProfile.businessProfile.config) {
                                    this.userProfile.businessProfile["config"]["city"] =
                                        this.userProfile.businessProfile.address.city;
                                }
                            }, 1000);
                            this.onboardLoader = false;
                            this.selectLocationInfoTab();
                        }
                    });
            }
        },
        saveOnboardPreferences() {
            this.onboardLoader = true;
            setTimeout(() => {
                this.onboardLoader = false;
            }, 5000);
            const crDate = moment().format("YYYY-MM-DD");

            const settingsObj = {
                workingStartTime: moment.utc(moment(crDate + " " + this.userProfile.businessProfile.config.workingStartTimeConverted + ":00").utc()).format(),
                workingEndTime: moment.utc(moment(crDate + " " + this.userProfile.businessProfile.config.workingEndTimeConverted + ":00").utc()).format(),
                allowedBreakTimeADayInMinutes: this.userProfile.businessProfile.config.allowedBreakTimeADayInMinutes,
                defaultLatitude: this.startingLat,
                defaultLongitude: this.startingLng,
                defaultColor: this.onboardConfigTempColor,
                defaultAreaMeasurementUnit: this.userProfile.businessProfile.config.defaultAreaMeasurementUnit,
                timezoneId: this.userProfile.businessProfile.config.timezone?.id || this.userProfile.businessProfile.config.timezone,
                currencyId: this.userProfile.businessProfile.config.currency.id,
            };

            localStorage.setItem("defaultStartingLat", this.startingLat);
            localStorage.setItem("defaultStartingLng", this.startingLng);

            if (this.hasBusinessConfig) {
                this.$store
                    .dispatch("updateUserProfileBusinessSettings", settingsObj)
                    .then((updateResponse) => {
                        if (updateResponse) {
                            this.$store.dispatch("getUserProfile").then((rs) => {
                                if (this.loginOwnerIsContractor){
                                setTimeout(() => {
                                    this.onboardLoader = false;
                                    this.selectInvoicingInfoTab();
                                 }, 1000);
                                }
                                else {
                                    this.$store.dispatch("navigateFlows", rs);
                                }
                            });
                        }
                });
            } else {
                this.$store
                    .dispatch("addUserProfileBusinessSettings", settingsObj)
                    .then((updateResponse) => {
                        if (updateResponse) {
                            this.$store.dispatch("getUserProfile").then((rs) => {
                                if (this.loginOwnerIsContractor){
                                    setTimeout(() => {
                                        this.onboardLoader = false;
                                        this.selectInvoicingInfoTab();
                                        this.hasBusinessConfig = true;
                                    }, 1000);
                                }
                                else {
                                    this.$store.dispatch("navigateFlows", rs);
                                }
                            });
                        }
                });
            }
        },
        enableDashboardAccess() {
            this.onboardLoader = true;
            setTimeout(() => {
                this.onboardLoader = false;
            }, 5000);

            const crDate = moment().format("YYYY-MM-DD");

            const settingsObj = {
                workingStartTime: moment.utc(moment(crDate + " " + this.userProfile.businessProfile.config.workingStartTimeConverted + ":00").utc()).format(),
                workingEndTime: moment.utc(moment(crDate + " " + this.userProfile.businessProfile.config.workingEndTimeConverted + ":00").utc()).format(),
                allowedBreakTimeADayInMinutes: this.userProfile.businessProfile.config.allowedBreakTimeADayInMinutes,
                defaultLatitude: this.startingLat,
                defaultLongitude: this.startingLng,
                defaultColor: this.onboardConfigTempColor,
                defaultAreaMeasurementUnit: this.userProfile.businessProfile.config.defaultAreaMeasurementUnit,
                timezoneId: this.userProfile.businessProfile.config.timezone?.id || this.userProfile.businessProfile.config.timezone,
                currencyId: this.userProfile.businessProfile.config.currency.id,
                invoiceInstructions: this.userProfile.businessProfile.config.invoiceInstructions,
                invoiceTermsInDays: this.userProfile.businessProfile.config.invoiceTermsInDays,
                salesTaxName: this.userProfile.businessProfile.config.salesTaxName ? this.userProfile.businessProfile.config.salesTaxName : null,
                salesTaxRegistrationNumber: this.userProfile.businessProfile.config.salesTaxName ? this.userProfile.businessProfile.config.salesTaxRegistrationNumber : null,
                salesTaxRates: this.userProfile.businessProfile.config.salesTaxName ? this.userProfile.businessProfile.config.salesTaxRates : [],
            };

            localStorage.setItem("defaultStartingLat", this.startingLat);
            localStorage.setItem("defaultStartingLng", this.startingLng);

            const getUserAndNavigateFlows = () => {
                this.$store.dispatch("getUserProfile").then(async (rs) => {
                    this.$store.dispatch("navigateFlows", { ...rs, checkIntegration: true });
                });
            }

            if (this.hasBusinessConfig) {
                this.$store
                    .dispatch("updateUserProfileBusinessSettings", settingsObj)
                    .then((updateResponse) => {
                        if (updateResponse) {
                            getUserAndNavigateFlows();
                        }
                });
            } 
            else {
                this.$store
                    .dispatch("addUserProfileBusinessSettings", settingsObj)
                    .then((updateResponse) => {
                        if (updateResponse) {
                            getUserAndNavigateFlows();
                        }
                });
            }
        },
        logoutClick() {
            this.$store.dispatch("logout");
        },
        checkMissingConfig() {
            const hasIncompleteConfig = localStorage.getItem("hasIncompleteConfig");
            if (hasIncompleteConfig && hasIncompleteConfig == "1") {
                notify(this.$stringConstants('onboardUserMissingInfoWarning'), "successs");
            }
        },
    },
};
