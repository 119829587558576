import ConstantValues from "@/plugins/constantValues";

export default {
    data() {
        return {
            timerId: null
        }
    },
    methods: {
        applySearchTimer(callback: () => void) {
            if (this.timerId) {
                clearTimeout(this.timerId)
                this.timerId = null
            }
            this.timerId = setTimeout(() => {
                callback()
            }, ConstantValues.defaultSearchTimeout)
        }
    }
}