import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"b-btm py-5 px-8"},[_c(VRow,{staticClass:"fn-20",attrs:{"no-gutters":"","cols":"12"}},[_c(VCol,{staticClass:"oy-tl",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.$stringConstants('contractorManageText')))]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"4"}},[_c(VIcon,{staticClass:"oy-cl",attrs:{"small":"","id":"closeContractorManageForm"},on:{"click":_vm.closeContractorManageForm}},[_vm._v(" mdi-close-thick ")])],1)],1)],1),_c('div',[_c('div',{staticClass:"pt-8"},[_c('div',{class:[_vm.containerLeftPad, _vm.containerRightPad]},[_c('div',{staticClass:"py-2"},[_c('SubHeaderPrimary',{attrs:{"textValue":_vm.$stringConstants('connectionRequestTitle')}})],1),_c('div',{staticClass:"pt-1 pb-6"},[_c('DescriptionPrimary',{attrs:{"textValue":_vm.$stringConstants('userInvitationDescription')}})],1)]),_c('Tabs',{attrs:{"tabs":['Received', 'Sent'],"tabContents":[_vm.connectionRequestsReceivedList, _vm.connectionRequestsSentList],"listLoaders":[_vm.connectionRequestsReceivedLoader, _vm.connectionRequestsSentLoader],"emptyListTexts":[_vm.$stringConstants('noConnectionReceived'), _vm.$stringConstants('noConnectionSent')],"handleTabChange":_vm.handleTabChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var listLength = ref.listLength;
var currentTab = ref.currentTab;
return [_c(VRow,{staticClass:"py-0",class:[_vm.containerLeftPad, _vm.containerRightPad]},[_c(VCol,{staticClass:"d-flex align-center px-0 py-0",attrs:{"cols":"8"}},[_c('Avatar',{staticClass:"mr-2",attrs:{"initials":currentTab == 0 ? item.inviter.businessProfile.title : ("" + (item.inviteeFirstName))}}),_c(VCol,[_c('span',{staticClass:"d-block f-14"},[_vm._v(" "+_vm._s(currentTab == 0 ? item.inviter.businessProfile.title : ((item.inviteeFirstName) + " " + (item.inviteeLastName)))+" ")]),_c('span',{staticClass:"grey--text lighten-1"},[_vm._v(" "+_vm._s(currentTab == 0 ? item.inviter.email : item.inviteeEmail)+" ")])])],1),_c(VCol,{staticClass:"px-0 mt-2",attrs:{"cols":"4"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"end","align":"center"}},[(currentTab == 0)?_c('Button',{staticClass:"ib float-right ml-2 f-nn",attrs:{"color":"primary","textValue":"Accept"},nativeOn:{"click":function($event){return _vm.confirmAcceptInvitation(item.code)}}}):_vm._e(),_c(VIcon,{staticClass:"icon-size cursor-pointer ml-4",attrs:{"large":""},on:{"click":function($event){return _vm.confirmDeleteInvitation(item.code, currentTab == 0 ? 'received' : 'sent')}}},[_vm._v("mdi-delete")])],1)],1)],1),(listLength > 1)?_c(VCol,{attrs:{"cols":"12 pt-2"}},[_c(VDivider,{attrs:{"light":""}})],1):_vm._e()]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }