export class Role {
    id: string;
    name: string;
    type: number;

    constructor(role) {
        if(role) {
            this.id = role.id;
            this.name = role.name;
            this.type = role.type;
        }
    }
}