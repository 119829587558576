import { getDateDifferenceInMinutes, getDateTimeFormattedLocal } from '@/utils/helpers';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'suBusinessesModule': [
        'businesses',
        'businessCountry',
        'businessType',
        'businessSubscriptionStatus',
        'businessesStartDate',
        'businessesEndDate',
        'businessesListFiltersApplied',
        'businessesSearchText',
        'businessTypesList',
        'businessSubscriptionStatusTypes',
        'businessesDetails',
    ],
    'excelExportModule': [
        'exportData',
        'exportDataLength',
        'exportDataLoaded',
        'exportDataTotal',
    ],
    'root': [
        'isRoutedFromReferencePage',
        'listLoading',
        'enumMemberRoles',
        'totalRowCount',
        'showSuperAdminSubMenu',
        'globalCountriesList'
    ]
}

export default {
    name: 'AdminBusinessesComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        currencySymbol() {
            return localStorage.getItem("currencySymbol");
        }
    },
    methods: {
        getCancellationScheduledText(subsciption) {
            if(parseInt(getDateDifferenceInMinutes(subsciption.cancellationScheduledOn)) > 0) {
                return this.$stringConstants('cancellationScheduledOnText') + getDateTimeFormattedLocal(subsciption.cancellationScheduledOn)
            }
            return this.$stringConstants('cancelledOnText') + getDateTimeFormattedLocal(subsciption.cancellationScheduledOn)
        }
    }
}