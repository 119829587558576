
















































import { BaseButton as Button } from "@/components/shared/buttons/index";
import buildUrl from 'build-url';
import { apiOperationTypes } from '@/utils/endpoints';
import requests from '@/utils/requests';
import ConstantValues from '@/plugins/constantValues';

export default {
  data() {
    return {
      seedSize: 400,
      vehiclesCounter: 0,
      implementsCounter: 0,
      productsCounter: 0,
      operationsCounter: 0,
    };
  },
  components: {
    Button,
  },
  computed: {
    addProductLoader() {
      return this.$store.getters.addProductLoader;
    },
    addVehicleLoader() {
      return this.$store.getters.addVehicleLoader;
    },
    addImplementLoader() {
      return this.$store.getters.addImplementLoader;
    },
    seedOperationsLoader: {
      get() {
          return this.$store.getters.seedOperationsLoader
      },
      set(newVal) {
          this.$store.state.operationsModule.seedOperationsLoader = newVal
      }    
    }
 },
  mounted() {
    this.$store.state.dashboardTitle = "Seed data";
  },
  methods: {
    async seedVehicles() {
      this.vehiclesCounter = 0;
      for (let i = 0; i < this.seedSize; i++) {
        let vehicleObj = {
          title: "VEHICLE_" + i,
          healthSafetyList: [],
          isHealthSafetyListMandatory: false,
          length: i + 100,
          width: i,
          make: "MK" + i,
          model: "MD" + i,
          ownership: i % 2 == 0 ? 0 : 1,
          registration: "REG_" + i + "_" + this.generateRandomString(8),
          weight: i * 10,
        };
        await this.$store.dispatch("seedVehicles", vehicleObj);
        this.vehiclesCounter++;
      }
    },
    async seedProducts() {
      this.productsCounter = 0;
      for (let i = 0; i < this.seedSize; i++) {
        let productObj = {
          title: "PRODUCT_" + i,
          cost: i,
          unit: i % 2 == 0 ? "Metre" : "Kg",
        };
        await this.$store.dispatch("seedProducts", productObj);
        this.productsCounter++;
      }
    },
    async seedImplements() {
      this.implementsCounter = 0;
      for (let i = 0; i < this.seedSize; i++) {
        let implementObj = {
          title: "IMPLEMENT_" + i,
          healthSafetyList: [],
          isHealthSafetyListMandatory: false,
          length: i + 100,
          width: i,
          make: "MK" + i,
          model: "MD" + i,
          ownership: i % 2 == 0 ? 0 : 1,
          registration: "REG_" + i + "_" + this.generateRandomString(8),
          weight: i * 10,
        };
        await this.$store.dispatch("seedImplements", implementObj);
        this.implementsCounter++;
      }
    },
    async seedOperations() {
      this.seedOperationsLoader = true;
      this.operationsCounter = 0;
        const createOperations = async (title: string, id: string) => {
          for (let i = 0; i < this.seedSize; i++) {
              let operationObject = {
                  title: `${title} ${i}`,
                  description: "",
                  billingCost: i,
                  billingUnit: "Metre",
                  isTaxable: false,
                  vat: 0,
                  typeId: id,
                  isHealthSafetyListMandatory: false,
                  healthSafetyList: [],
                  loadInformationList: [],
                  vehicles: [],
                  implements: [],
                  products: []
              };

              await this.$store.dispatch("seedOperations", operationObject);
              if(i === this.seedSize - 1) {
                this.seedOperationsLoader = false;
              }
              this.operationsCounter++;
          }
        }

        const url = buildUrl(this.$store.state.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiOperationTypes
        });

        const result = await requests.getData(url);

        if(result?.data?.value?.length) {
          let { title, id } = result.data.value[0];
          createOperations(title, id);
        }
    },
    generateRandomString(stringLength) {
      let result = "";
      const alphaNumericCharacters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const alphabetsLength = alphaNumericCharacters.length;
      for (let i = 0; i < stringLength; i++) {
        result += alphaNumericCharacters.charAt(
          Math.floor(Math.random() * alphabetsLength)
        );
      }
      return result;
    },
  },
};
