


















export default {
    name: 'ButtonMiniPrimary',
    props: [
        'textValue',
        'width',
        'height',
        'disabled', 
        'loading', 
    ]
}
