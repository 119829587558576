import {getComputedProperties} from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'productsModule' : [
        'productDetails',
        'productsList',
        'productSearchText',
        'productStatus',
    ],
    'root': [
        'enumListProductUnits',
        'productLoader',
        'editProductLoader',
        'noData',
        'filtersApplied',
        'listCount',
        'enumActiveStatus',
        'totalRowCount',
        'snackbarShow',
        'allItemsDeleted',
        'selectedItems',
        'isPageRelated',
    ],
};


export default {
    name: 'ProductsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties), 
        currencySymbol() {
            return localStorage.getItem("currencySymbol");
        }
    }
}