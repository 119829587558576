import ConstantValues from "@/plugins/constantValues";
import { getListLengthAbsolute } from "@/utils/helpers";

export const actionsFilters = {
    async setParameterFilters({state, commit}) {
        (state.teamJobsListSearchText?.length > 0) && commit('setFilterOptions', ["SearchText", state.teamJobsListSearchText?.trim()]);
        state.teamJobsListFilterStartDate && commit('setFilterOptions', ["Start", state.teamJobsListFilterStartDate]);
        state.teamJobsListFilterEndDate && commit('setFilterOptions', ["End", state.teamJobsListFilterEndDate]);
        state.teamJobsFilterCustomerValue && commit('setFilterOptions', ["CustomerIds", state.teamJobsFilterCustomerValue]);
        state.teamJobsFilterOperationValue && commit('setFilterOptions', ["OperationIds", state.teamJobsFilterOperationValue]);
        state.teamJobsFilterTeamMemberValue && commit('setFilterOptions', ["OperatorIds", state.teamJobsFilterTeamMemberValue]);
        state.teamJobsFilterFieldValue && commit('setFilterOptions', ["FieldIds", state.teamJobsFilterFieldValue]);
        state.teamJobsFilterVehicleValue && commit('setFilterOptions', ["VehicleIds", state.teamJobsFilterVehicleValue]);
        state.teamJobsFilterImplementValue && commit('setFilterOptions', ["ImplementIds", state.teamJobsFilterImplementValue]);
        state.teamJobsFilterProductValue && commit('setFilterOptions', ["ProductIds", state.teamJobsFilterProductValue]);
    },

    async setJobsFilter({ commit, dispatch }, data) {
        commit('setFilterOptions', ["Offset", data[0]]);
        commit('setFilterOptions', ["Limit", data[1]]);
        dispatch('setParameterFilters');
    },

    setCustomersForJobsSearchFilters({ commit, state }, _) {
        commit('setClearFilterOptions');
        if (state.teamJobsFilterCustomerSearchText != null && state.teamJobsFilterCustomerSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.teamJobsFilterCustomerSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthWithoutOwner(state.teamJobsFilterCustomersList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },

    setTeamMembersForJobsSearchFilters({ commit, state }, _) {
        commit('setClearFilterOptions');
        if (state.teamJobsFilterTeamMemberSearchText != null && state.teamJobsFilterTeamMemberSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.teamJobsFilterTeamMemberSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthWithoutOwner(state.teamJobsFilterTeamMembersList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },

    setFieldsForJobsSearchFilters({ commit, state }, _) {
        commit('setClearFilterOptions');
        if (state.teamJobsFilterFieldsSearchText != null && state.teamJobsFilterFieldsSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.teamJobsFilterFieldsSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.teamJobsFilterFieldsList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },

    setVehiclesForJobsSearchFilters({ commit, state }, _) {
        commit('setClearFilterOptions');
        if (state.teamJobsFilterVehiclesSearchText != null && state.teamJobsFilterVehiclesSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.teamJobsFilterVehiclesSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.teamJobsFilterVehiclesList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },

    setImplementsForJobsSearchFilters({ commit, state }, _) {
        commit('setClearFilterOptions');
        if (state.teamJobsFilterImplementsSearchText != null && state.teamJobsFilterImplementsSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.teamJobsFilterImplementsSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.teamJobsFilterImplementsList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },

    setProductsForJobsSearchFilters({ commit, state }, _) {
        commit('setClearFilterOptions');
        if (state.teamJobsFilterProductsSearchText != null && state.teamJobsFilterProductsSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.teamJobsFilterProductsSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.teamJobsFilterProductsList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },
}
