import moment from 'moment';

export const getTimeDetailsFormatted = (time) => {
    const timeInMinutes = Math.floor(time / 60);
    let hour = (Math.floor(timeInMinutes / 60)).toString();
    let min = (timeInMinutes % 60).toString();
    if (hour.length != 2) {
        hour = '0' + hour;
    }
    if (min.length != 2) {
        min = '0' + min;
    }
    return {
        HH: hour,
        mm: min
    }
}
export const getDateFormated = (date) => {
    return moment.utc(date).local().format("YYYY-MM-DD HH:mm");
}

export const getTimeFormated = (date) => {
    return moment.utc(date).local().format("HH:mm");
}

export const getTimeInSeconds = (time) => {
    const dur = time.split(':');
    const hour = parseInt(dur[0]);
    const min = parseInt(dur[1]);
    const ttlSec = (hour * 60 * 60) + (min * 60);
    return ttlSec;
}

export const getTimeDetailsRequestFormated = (date) => {
    const dT = moment.utc(moment(date)).format();
    return dT;
}

export const getTimeRequestFormated = (hoursMins) => {
    const sec = (((parseInt(hoursMins.HH)) * 60 * 60) + ((parseInt(hoursMins.mm)) * 60));
    return sec;
}