import moment from 'moment';
import ConstantValues from '@/plugins/constantValues';
import { listContainsItem } from '@/utils/helpers';
import { Address } from '@/data/models/Address';

export const state = {
    supplierNameForInvoice: null,
    supplierEmailForInvoice: null,
    supplierPhoneNumberForInvoice: null,
    supplierPaymentInstructionsForInvoice: null,
    supplierBusinessProfileForInvoice: {
        title: null,
        address: new Address(),
        registrationNumber: null,
        vatNumber: null
    },
    customerNameForInvoice: null,
    customerEmailForInvoice: null,
    customerPhoneNumberForInvoice: null,
    customerBusinessProfileForInvoice: {
        title: null,
        address: new Address(),
        registrationNumber: null,
        vatNumber: null
    },
    customersListForInvoice: [],
    customersSearchText: '',
    invoiceLoader: false,
    selectedCustomerIdForInvoice: null,
    jobsListForInvoice: [],
    selectedJobsForInvoice: [],
    taxValuesForInvoice: [],
    dueDateForInvoice: null,
    invoiceDraftPreviewDetails: null,
    invoicesList: [],
    invoiceSearchText: '',
    canOpenInvoice: false,
    draftedInvoiceIdToOpen: null,
    isInvoiceOpened: false,
    invoiceSelectedTab: 1,
    invoiceDate: null,
    invoiceRefs: [],
    invoiceLineItemJobNumbersDistinct: [],
    invoiceFilterStatus: [
        {
            title: 'All',
            value: ''
        },
        {
            title: 'Draft',
            value: '0'
        },
        {
            title: 'Open',
            value: '1'
        },
        {
            title: 'Paid',
            value: '2'
        },
    ],
    invoiceFilterStatusSelected: null,
    invoiceFilterEmailStatusSelected: null,
    invoiceTabTexts: [
        'Internal', 'Quickbooks/Xero',
    ],
    selectedInvoiceTabTextExternal: '',
    invoiceJobsForPreview: [],
    invoiceJobsPreviewLoader: false,
    invoiceLineItemsJobIds: [],
    invoiceSend: false,
    invoiceShouldSendLater: false,
    invoicesListOffset: ConstantValues.defaultPageOffsetSize,
    invoicesListLimit: ConstantValues.defaultPageLimitSize,
    noListData: false,
    invoicesListCount: ConstantValues.defaultPageListCount,
    customersListForInvoiceLoader: false,
    customersListForInvoiceSize: 0,
    noData: false,
    listCount: ConstantValues.defaultPageListCount,
    totalRowCount: ConstantValues.defaultPageTotalRowCount,
    offset: ConstantValues.defaultPageOffsetSize,
    limit: ConstantValues.defaultPageLimitSize,
    invoicesListFilterCustomerValue: '',
    invoicesListFilterCustomersList: [],
    invoicesListFilterCustomerSearchText: '',
    invoicesListFilterCustomersDropdownLoader: false,
    invoicesListFilterStartDate: null,
    invoicesListFilterStartDateLocal: null,
    invoicesListFilterEndDate: null,
    invoicesListFilterEndDateLocal: null,
    invoicesListFilterDateApplied: false,
    showAddLineitemsForInternalInvoice: [],
    newProductForInternalInvoice: null,
    newLineItemsInitialsForInternal: {
        date:null,
        title:null,
        quantity:0,
        rate:0,
        unit:null,
        type:1,
        amount:0,
        description:null,
        reference:null,
        currency:null,
        taxAmounts:[
            {amount: 0,
            salesTaxName: '',
            salesTaxRateInPercentage: 0}],
        },
    invoiceInternalLineItemSearchText: null,
    productListForInternalInvoice: [],
    productFeatchingLoader: false
}

export const getters = {
    supplierBusinessProfileForInvoice: state => state.supplierBusinessProfileForInvoice,
    supplierNameForInvoice: state => state.supplierNameForInvoice,
    supplierEmailForInvoice: state => state.supplierEmailForInvoice,
    supplierPhoneNumberForInvoice: state => state.supplierPhoneNumberForInvoice,
    supplierPaymentInstructionsForInvoice: state => state.supplierPaymentInstructionsForInvoice,
    customerBusinessProfileForInvoice: state => state.customerBusinessProfileForInvoice,
    customerNameForInvoice: state => state.customerNameForInvoice,
    customerEmailForInvoice: state => state.customerEmailForInvoice,
    customerPhoneNumberForInvoice: state => state.customerPhoneNumberForInvoice,
    customersListForInvoice: state => state.customersListForInvoice,
    customersSearchText: state => state.customersSearchText,
    invoiceLoader: state => state.invoiceLoader,
    selectedCustomerIdForInvoice: state => state.selectedCustomerIdForInvoice,
    jobsListForInvoice: state => state.jobsListForInvoice,
    selectedJobsForInvoice: state => state.selectedJobsForInvoice,
    taxValuesForInvoice: state => state.taxValuesForInvoice,
    dueDateForInvoice: state => state.dueDateForInvoice,
    invoiceDraftPreviewDetails: state => state.invoiceDraftPreviewDetails,
    invoicesList: state => state.invoicesList,
    invoiceSearchText: state => state.invoiceSearchText,
    canOpenInvoice: state => state.canOpenInvoice,
    draftedInvoiceIdToOpen: state => state.draftedInvoiceIdToOpen,
    isInvoiceOpened: state => state.isInvoiceOpened,
    invoiceSelectedTab: state => state.invoiceSelectedTab,
    invoiceDate: state => state.invoiceDate,
    invoiceRefs: state => state.invoiceRefs,
    invoiceLineItemJobNumbersDistinct: state => state.invoiceLineItemJobNumbersDistinct,
    invoiceFilterStatus: state => state.invoiceFilterStatus,
    invoiceFilterStatusSelected: state => state.invoiceFilterStatusSelected,
    invoiceFilterEmailStatusSelected: state => state.invoiceFilterEmailStatusSelected,
    invoiceTabTexts: state => state.invoiceTabTexts,
    selectedInvoiceTabTextExternal: state => state.selectedInvoiceTabTextExternal,
    invoiceJobsForPreview: state => state.invoiceJobsForPreview,
    invoiceJobsPreviewLoader: state => state.invoiceJobsPreviewLoader,
    invoiceLineItemsJobIds: state => state.invoiceLineItemsJobIds,
    invoiceSend: state => state.invoiceSend,
    invoiceShouldSendLater: state => state.invoiceShouldSendLater,
    invoicesListOffset: state => state.invoicesListOffset,
    invoicesListLimit: state => state.invoicesListLimit,
    noListData: state => state.noListData,
    invoicesListCount: state => state.invoicesListCount,
    customersListForInvoiceLoader: state => state.customersListForInvoiceLoader,
    customersListForInvoiceSize: state => state.customersListForInvoiceSize,
    invoicesListFilterCustomerValue: state => state.invoicesListFilterCustomerValue,
    invoicesListFilterCustomersList: state => state.invoicesListFilterCustomersList,
    invoicesListFilterCustomerSearchText: state => state.invoicesListFilterCustomerSearchText,
    invoicesListFilterCustomersDropdownLoader: state => state.invoicesListFilterCustomersDropdownLoader,
    invoicesListFilterStartDate: state => state.invoicesListFilterStartDate,
    invoicesListFilterStartDateLocal: state => state.invoicesListFilterStartDateLocal,
    invoicesListFilterEndDate: state => state.invoicesListFilterEndDate,
    invoicesListFilterEndDateLocal: state => state.invoicesListFilterEndDateLocal,
    invoicesListFilterDateApplied: state => state.invoicesListFilterDateApplied,
    showAddLineitemsForInternalInvoice: state => state.showAddLineitemsForInternalInvoice,
    newProductForInternalInvoice: state => state.newProductForInternalInvoice,
    newLineItemsInitialsForInternal: state => state.newLineItemsInitialsForInternal,
    invoiceInternalLineItemSearchText: state => state.invoiceInternalLineItemSearchText,
    productListForInternalInvoice: state => state.productListForInternalInvoice,
    productFeatchingLoader: state => state.productFeatchingLoader,
}

export const mutations = {
    setBusinessProfileForInvoice(state, data) {
        state.supplierNameForInvoice = data.businessProfile.title ? data.businessProfile.title : '';
        state.supplierEmailForInvoice = data.email;
        state.supplierPhoneNumberForInvoice = data.primaryContactNumber;
        state.supplierPaymentInstructionsForInvoice = data.businessProfile.paymentInstructions;
        if (data.businessProfile != null) {
            const { businessProfile } = data;
            const { address } = businessProfile;
            state.supplierBusinessProfileForInvoice['title'] = businessProfile.title;
            state.supplierBusinessProfileForInvoice['address'] = new Address().setResponseAddress(address);
            state.supplierBusinessProfileForInvoice['registrationNumber'] = businessProfile.registrationNumber;
            state.supplierBusinessProfileForInvoice['vatNumber'] = businessProfile.vatNumber;
        } else {
            state.supplierBusinessProfileForInvoice['title'] = null;
            state.supplierBusinessProfileForInvoice['address'] = new Address();
            state.supplierBusinessProfileForInvoice['registrationNumber'] = null;
            state.supplierBusinessProfileForInvoice['vatNumber'] = null;
        }
    },
    setCustomerProfileForInvoice(state, data) {
        state.customerNameForInvoice =  data.businessProfile.title ? data.businessProfile.title : '';
        state.customerEmailForInvoice = data.email;
        state.customerPhoneNumberForInvoice = data.primaryContactNumber;
        if (data.businessProfile != null) {
            const { businessProfile } = data;
            const { address } = businessProfile;
            state.customerBusinessProfileForInvoice['title'] = businessProfile.title;
            state.customerBusinessProfileForInvoice['address'] = new Address().setResponseAddress(address);
            state.customerBusinessProfileForInvoice['registrationNumber'] = businessProfile.registrationNumber;
            state.customerBusinessProfileForInvoice['vatNumber'] = businessProfile.vatNumber;
        }
    },
    setCustomersListForInvoice(state, data) {
        data.map(customer => state.customersListForInvoice.push(customer));
        state.customersListForInvoice.map(customer => {
            if(customer.businessProfile && customer.businessProfile.title){
                customer['displayName'] = customer.businessProfile.title;
            } else {
                customer['displayName'] = customer.firstName;
            }
        });
    },
    setJobsListForInvoice(state, data) {
        data.map(job => state.jobsListForInvoice.push(job));
    },
    setTaxValues(state, data) {
        state.taxValuesForInvoice = [];
        data.map(taxValue => state.taxValuesForInvoice.push(taxValue));
    },
    setInvoicesList(state, data) {
        data.map(invoice => {
            if(!listContainsItem(state.invoicesList, [ConstantValues.defaultObjectIdentifier], invoice.id)) {
                state.invoicesList.push(invoice);
                invoice.jobIds.map(id => {
                    state.invoiceRefs.push({
                        jobId: id,
                        refText: null,
                        jobOwnerId: invoice.customerId
                    });
                });
    
                invoice.references.map((ref, refIndex) => {
                    state.invoiceRefs[refIndex].refText = ref;
                });
            }
        });
    },
    setJobsListForPreview(state, data) {
        data.map(job => {
            state.invoiceJobsForPreview.push(job);
        });
    },
    setInvoicePreviewDetails(state, data) {
        state.invoiceDraftPreviewDetails = data;
        state.invoiceLineItemsJobIds = data.jobIds;
        if (state.invoiceDraftPreviewDetails.lineItems.length > 0) {
            state.invoiceDraftPreviewDetails.lineItems.map(item => {
                if (item.taxAmounts.length == 0) {
                    item.taxAmounts.push({
                        amount: 0,
                        salesTaxName: '',
                        salesTaxRateInPercentage: 0,
                    });
                }
            });

            state.invoiceLineItemJobNumbersDistinct = [...new Set(state.invoiceDraftPreviewDetails.lineItems.map(item => item.reference))];
            state.invoiceDraftPreviewDetails.lineItems.sort((a, b) => a.reference < b.reference ? -1 : (a.reference > b.reference ? 1 : 0));
            state.showAddLineitemsForInternalInvoice = []
            state.invoiceLineItemJobNumbersDistinct.forEach(() => { 
                state.showAddLineitemsForInternalInvoice.push(false)
            })
         }
        if (data.dueDate != null) {
            state.invoiceDate = moment.utc(data.dueDate).local().format('YYYY-MM-DD');
        }
        state.draftedInvoiceIdToOpen = state.invoiceDraftPreviewDetails.id;
        state.newLineItemsInitialsForInternal.currency = state.invoiceDraftPreviewDetails.currency;
    },
    setClearInvoiceData(state) {
        state.supplierBusinessProfileForInvoice['title'] = null;
        state.supplierBusinessProfileForInvoice['address'] = new Address();
        state.supplierBusinessProfileForInvoice['registrationNumber'] = null;
        state.supplierBusinessProfileForInvoice['vatNumber'] = null;
        state.supplierNameForInvoice = null;
        state.supplierEmailForInvoice = null;
        state.supplierPhoneNumberForInvoice = null;
        state.customerBusinessProfileForInvoice['title'] = null;
        state.customerBusinessProfileForInvoice['address'] = new Address();
        state.customerBusinessProfileForInvoice['registrationNumber'] = null;
        state.customerBusinessProfileForInvoice['vatNumber'] = null;
        state.customerNameForInvoice = null;
        state.customerEmailForInvoice = null;
        state.customerPhoneNumberForInvoice = null;
        state.customersListForInvoice = [];
        state.customersSearchText = '';
        state.invoiceLoader = false;
        state.selectedCustomerIdForInvoice = null;
        state.jobsListForInvoice = [];
        state.selectedJobsForInvoice = [];
        state.taxValuesForInvoice = [];
        state.dueDateForInvoice = null;
        state.invoiceDraftPreviewDetails = null;
        state.canOpenInvoice = false;
        state.draftedInvoiceIdToOpen = null;
        state.isInvoiceOpened = false;
        state.invoiceSelectedTab = 1;
        state.invoiceLineItemJobNumbersDistinct = [];
        state.invoiceShouldSendLater = false;
    },
    resetNewTimeLineInitials(state) {
    state.newProductForInternalInvoice = null;
    state.newLineItemsInitialsForInternal = {
                date:null,
                title:null,
                quantity:0,
                rate:0,
                unit:null,
                type:1,
                amount:0,
                description:null,
                reference:null,
                currency: state.invoiceDraftPreviewDetails.currency,
                taxAmounts:[
                    {amount: 0,
                    salesTaxName: '',
                    salesTaxRateInPercentage: 0}],
            }
    },
    
    setNoListDataStatus(state, data) {
        state.noListData = data != null && data.length == 0
        state.noData = data?.length == 0
    },

    setCustomersListForInvoicesSearch(state, data) {
       data.map(customer => {
           if (customer) {
               const member = {
                   name: ((customer.businessProfile?.title) ? customer.businessProfile.title : (customer.firstName +
                       (customer.lastName ? (' ' + customer.lastName) : ''))),
                   id: customer.id
               }
               state.invoicesListFilterCustomersList.push(member);
           }
       });
   },
    setCustomersListSizeForInvoicesSearch(state, data) {
        state.invoicesListFilterCustomersListSize = data;
    },
    setProductListForInternalInvoice(state, data){
        data.map(product => {state.productListForInternalInvoice.push(product)});
    },
    updateInvoiceDetailsInInvoiceList(state, updatedInvoice) {
        const invoiceIndex = state.invoicesList.findIndex((invoice: any) => invoice.id === updatedInvoice.id);
        if (invoiceIndex > -1) {
            state.invoicesList.splice(invoiceIndex, 1, updatedInvoice);
        }
    },
    removeInvoiceFromList(state, invoiceId) {
        state.invoicesList.splice(invoiceId, 1);
    },
    updateInvoicePagingAfterListItemRemoval(state) {
        state.listCount--;
        state.totalRowCount--;
        state.noData = state.invoicesList.length === 0;
    },
    resetInvoicesListPagingStates(state) {
        state.invoicesList = [];
        state.listCount = ConstantValues.defaultPageListCount;
        state.totalRowCount = ConstantValues.defaultPageTotalRowCount;
        state.offset = ConstantValues.defaultPageOffsetSize;
        state.limit = ConstantValues.defaultPageLimitSize;
    }
}