





















import {mapMutations, mapActions} from "vuex";
import FieldList from './field-list.vue';
import FieldsBulkUploadForm from './fields-bulk-upload-form.vue';
import Drawer from '@/components/shared/drawer.vue';
import FieldForm from './field-form.vue';
import FieldDetails from './field-details.vue';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import {routeFields, routeFieldsAdd, routeFieldsEdit} from "@/utils/endpoints";
import BulkActionResponseDialog from '@/components/shared/dialogs/bulk-action-response-dialog.vue';

export default {
    mixins: [requestsCancellationMixin],
    components: {
        FieldList,
        FieldsBulkUploadForm,
        Drawer,
        FieldForm,
        FieldDetails,
        BulkActionResponseDialog,
    },
    computed: {
        drawerIsOpen() {
            return this.$store.state.drawerOpen
        },
        showEditableFields() {
            return this.$store.state.fieldsModule.showEditableFields
        },
        fieldDetails() {
            return this.$store.state.fieldsModule.fieldDetails
        }
    },
    mounted() {
        this.$store.state.dashboardTitle = this.$stringConstants('fieldsTitle');
        this.addNativeBrowserNavigationListener();
    },
    methods: {
        ...mapMutations({
            resetFieldDetails: "resetFieldDetails",
            setJobsByField: "setJobsByField",
        }),
        ...mapActions({
            resetAreaToInitialState: "resetAreaToInitialState",
            cleanUpMapAndFieldForm: "cleanUpMapAndFieldForm"
        }),
        addNativeBrowserNavigationListener() {
            window.addEventListener('popstate', (event) => {
                if (location.pathname.includes(routeFieldsAdd) || location.pathname.includes(routeFieldsEdit)) {
                    history.replaceState({}, null, routeFields)
                }
                this.$store.commit('setDrawerOpen', false);
                this.cleanUpMapAndFieldForm();
            })
        },
        resetFieldsPage() {
            this.$store.state.dialogBulkActionResponse = false
            location.reload()
        }
    }
}
