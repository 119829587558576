export class TeamJobComponentConfiguration {
    isVisible?: boolean;
    isEditable?: boolean;
    isDetailed?: boolean;

    constructor(isVisible?: boolean, isEditable?: boolean, isDetailed?: boolean) {
        this.isVisible = (isVisible != undefined && isVisible != null) ? isVisible : true;
        this.isEditable = (isEditable != undefined && isEditable != null) ? isEditable : true;
        this.isDetailed = (isDetailed != undefined && isDetailed != null) ? isDetailed : true; 
    }

    public getTeamJobComponentConfiguration(configuration: TeamJobComponentConfiguration) {
        this.isVisible = configuration.isVisible != undefined ? configuration.isVisible : true;
        this.isEditable = configuration.isEditable != undefined ? configuration.isEditable : true;
        this.isDetailed = configuration.isDetailed != undefined ? configuration.isDetailed : true;
        return this;
    }
}