





















export default {
  name: "CalendarDay",
  props: {
    date: Date,
    isDropZoneActive: Boolean
  },
  methods: {
    addDropZoneHighlight() {
      this.$refs.calendarDay.style.boxShadow = '0 0 50px 25px rgba(0, 0, 0, 0.1) inset';
      this.$refs.calendarDay.style.zIndex = 1;
    },
    removeDropZoneHighlight() {
      this.$refs.calendarDay.style.boxShadow = 'none';
      this.$refs.calendarDay.style.zIndex = 'auto';
    },
    handleDragEnter(e) {
      const jobId = e.dataTransfer.getData('application/job-id');
      const draggedJob = document.getElementById(`${jobId}`);

      if (draggedJob) {
        const draggedJobFromDayId = draggedJob.parentElement.closest('.v-calendar-weekly__day').querySelector('.calendar-day').getAttribute('id');
        if (this.$attrs.id === draggedJobFromDayId) return;
      }

      this.addDropZoneHighlight();
    },
    handleDragOver(e) {
      // console.log(e);
    },
    handleDragLeave(e) {
      this.removeDropZoneHighlight();
    },
    handleDrop(e) {
      const jobId = e.dataTransfer.getData('application/job-id');
      if (jobId) {
        const draggedJob = document.getElementById(`${jobId}`).parentElement;
        const draggedJobFromDay = draggedJob.closest('.v-calendar-weekly__day');
        const draggedJobFromDayId = draggedJobFromDay.querySelector('.calendar-day').getAttribute('id');
        const droppedJobOnDayId = this.$attrs.id;
        if (draggedJobFromDayId === droppedJobOnDayId) return;
        this.$emit('update-job', jobId);
      }

      this.removeDropZoneHighlight();
    }
  }
}
