



































































































import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
export default {
    name: 'CompanyAddressForm',
    mixins: [accountComputedMixin],
    components: {
        SectionLeftHeadings
    }
}
