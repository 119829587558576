import { routeFields } from '@/utils/endpoints';
import requests from '@/utils/requests';

export default {
    name: 'RequestsCancellation',
    beforeRouteLeave(to, from, next) {
        if (from.path.includes(routeFields)) {
            this.$store.commit('setDrawerOpen', false);
            this.$store.dispatch('cleanUpMapAndFieldForm');
        }
        next && requests.initializeCancelRequests(next)
    },
    beforeRouteEnter(to, from, next) {
        next && requests.initializeAbortController(next) 
    },
}