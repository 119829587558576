












export default {
    name: 'ButtonHoverText',
    props: [
        'textValue',
        'width',
        'height',
        'disabled', 
        'loading', 
    ]
}
