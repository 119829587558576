<template>
<div>
    <div class="su-db-box-wrapper su-db-box-minh">
        <div class="su-db-header position-relative">
            <div class="ib">
                <span>
                    Statistics
                </span>
                <span v-if="!adminOverviewCurrentStatisticsDateFilterApplied" class="su-recent-dt">
                    (Showing data from last 7 days)
                </span>
            </div>
            <div class="ib" >
                <div class="ib current-stats-date position-absolute">
                    <DatePicker ref="datePicker"
                                :dateInput="dateInput"
                                :format="format"
                                :sameDateFormat="sameDateFormat"
                                :switch-button-initial="true"
                                :show-helper-buttons="false"
                                language="en"
                                @date-applied="filterCurrentStatisticsByDateRange"
                                :class="adminOverviewCurrentStatisticsDateFilterApplied ? 'active' : ''"
                    />
                </div>
                <div class="ib" v-if="adminOverviewCurrentStatisticsDateFilterApplied">
                    <div @click="clearCurrentStatisticsDateFilter" class="cursor-pointer su-ov-dt-close">
                        <v-icon small>
                            mdi-close-thick
                        </v-icon>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="ib su-db-container-left" style="width:35%; vertical-align: top;">
                <div class="su-db-title pb-8 pt-3">
                    Registrations
                </div>
                <div v-if="!adminRecentOverviewLoader">
                    <SuDashboardCurrentStatisticsCounter 
                        counterTitle="New businesses"
                        :counterValue="adminOverviewRecentStatistics.businesses"
                        :counterSummary="null"
                    />
                </div>
                <div v-else>
                    <StatsLoader :loaderRows="2" />
                </div>
                <div class="pt-7">
                    <div v-if="!adminRecentOverviewLoader">
                        <SuDashboardCurrentStatisticsCounter 
                            counterTitle="New users"
                            :counterValue="adminOverviewRecentStatistics.users"
                            :counterSummary="null"
                        />
                    </div>
                    <div v-else>
                        <StatsLoader :loaderRows="2" />
                    </div>
                </div>
            </div>
            <div class="ib su-db-container-right pr-0" style="width:65%; vertical-align: top;">
                <div class="su-db-title pb-8 pt-3">
                    Recent subscription activity
                </div>
                <div>
                    <div class="ib" style="width: 35%;vertical-align: top;" >
                        <div v-if="!adminRecentOverviewLoader">
                            <SuDashboardCurrentStatisticsCounter 
                                counterTitle="Payments"
                                :counterValue="adminOverviewRecentStatistics.paidSubscriptions"
                                :counterSummary="null"
                            />
                        </div>
                        <div v-else>
                            <StatsLoader :loaderRows="2" />
                        </div>
                        <div v-if="!adminRecentOverviewLoader" class="pt-7">
                            <SuDashboardCurrentStatisticsCounter 
                                counterTitle="Trials"
                                :counterValue="adminOverviewRecentStatistics.businessesOnTrial"
                                :counterSummary="null"
                            />
                        </div>
                        <div v-else class="pt-7">
                            <StatsLoader :loaderRows="2" />
                        </div>
                    </div>
                    <div class="ib pl-3" style="width: 65%; vertical-align: top;">
                        <div v-if="!adminRecentOverviewLoader">
                            <SuDashboardCurrentStatisticsCounter 
                                counterTitle="Collection"
                                :counterValue="currencySymbol+adminOverviewRecentStatistics.subscriptionsCollection"
                                :counterSummary="null"
                            />
                        </div>
                        <div v-else>
                            <StatsLoader :loaderRows="2" />
                        </div>
                        <div v-if="!adminRecentOverviewLoader" class="pt-7">
                            <SuDashboardCurrentStatisticsCounter 
                                counterTitle="Expired or Cancelled"
                                :counterValue="adminOverviewRecentStatistics.expiredSubscriptions"
                                :counterSummary="null"
                            />
                        </div>
                        <div v-else class="pt-7">
                            <StatsLoader :loaderRows="2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery';
import { mapMutations } from 'vuex';
import SuDashboardCurrentStatisticsCounter from './su-overview-current-statistics-counter.vue';
import suOverviewComputedMixin from './su-overview-computed';
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker';
import StringConstants from '@/plugins/stringConstants';
import StatsLoader from './su-overview-stats-loader.vue';
import moment from 'moment';
import { clearDateTimePicker, initializeDateTimePlaceholders } from '@/utils/helpers';
import { defaultDateFormat } from '@/utils/constants';

export default {
    data() {
        return {
            dateInput: {
                placeholder: StringConstants.selectDatePlaceholder
            },
            format: defaultDateFormat,
            sameDateFormat: {
                from: defaultDateFormat,
                to: defaultDateFormat
            },
        }
    },
    components: {
        SuDashboardCurrentStatisticsCounter,
        DatePicker,
        StatsLoader
    },
    mixins: [suOverviewComputedMixin],
    mounted() {
       this.clearCurrentStatisticsDateFilter()
       this.getCurrentStatistics()
       initializeDateTimePlaceholders()
    },
    methods: {
        ...mapMutations({
            resetPaging: "resetPaging",
            clearCurrentStatisticsFilterOptions: "setClearFilterOptions",
        }),
        filterCurrentStatisticsByDateRange(start, end) {
            this.adminOverviewRecentStatisticsStartDate = moment(start).utc().format()
            this.adminOverviewRecentStatisticsEndDate = moment(end).utc().format()

            this.refreshCurrentStatistics()
            this.adminOverviewCurrentStatisticsDateFilterApplied = true
        },
        refreshCurrentStatistics() {
                this.clearCurrentStatisticsFilterOptions();
                this.resetPaging();

                this.getCurrentStatistics();
        },
        getCurrentStatistics() {
            this.$store.dispatch('setRecentStatisticsFilter').then(() => {
                this.$store.dispatch('getRecentStatistics')
            })
        },
    }
}
</script>

<style>
.su-db-box-wrapper {
    width: 560px;
    border: 1px solid #1d34ad2b;
}
     @media only screen and (max-width: 1480px) {
        .su-db-box-wrapper {
            max-width: 55vw;
        }
     }

.su-db-box-minh {
    min-height: 385px;
}
.su-db-header {
    padding: 14px 24px;
    border-bottom: 1px solid #1d34ad21;
    background: #ebf1fb;
    color: #000000;
    font-weight: 500;
}
.su-db-container-left {
    padding: 24px;
    border-right: 1px solid #1d34ad2b;
    min-height: 307px;
}
.su-db-container-right {
    padding: 24px;
}
.su-db-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
}

.su-ov-dt-close {
    position: absolute;
    right: 330px;
    top: 14px;
}

.su-ov-dt-close i {
    color:#7d7d7d !important;
}

.current-stats-date {
    right: 8px;
    top: 6px;
    color: #000;
}
.su-recent-dt {
    color: #000000;
    font-size: 12px;
    padding-left: 6px;
}
</style>