import { apiUsers, apiContractors, apiInvitation } from '@/utils/endpoints';
import request from '@/utils/requests';
import buildUrl from 'build-url';
import { UserRoleType } from '@/enum/userRoleType';
import { ResponseList, User } from '@/data/models';
import ConstantValues from '@/plugins/constantValues';
import { deleteObjectFromList, notify } from '@/utils/helpers';

export const actions = {
    async getContractorFiltered({ state, commit, rootState, dispatch }) {
        rootState.listLoading = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiContractors + rootState.tempUrl
        });
        const result: ResponseList<User> = await request.getList<User>(url, {rootState, cancellable: true});
        if (result != null) {
            state.acceptedContractorTotalCount = result.size;
            if (result.value.length > 0) {
                commit('setContractorFilteredList', result.value);
            }
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.value);
                commit('setNoAcceptedContractors', result.value);
            }
        }
        rootState.listLoading = false;
    },
    
    async setContractorFilter({ commit, state }, data) {
        if (state.contractorSearchText?.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.contractorSearchText]);
        }
        commit('setFilterOptions', ['Preference', state.contractorFilterType]);
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
        return true;
    },

    async getPendingContractorFiltered({ state, rootState, dispatch, commit }) {
        rootState.listLoading = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + rootState.tempUrl + '&showSent=true&roles=' + UserRoleType.Contractor
        });
        const result: ResponseList<User> = await request.getList<User>(url, {
            rootState, 
            cancellable: true
        });
        if (result != null) {
            state.pendingContractorTotalCount = result.size;
            if (result.value.length > 0) {
                commit('setPendingContractorFilteredList', result.value);
            }
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.value);
                commit('setNoPendingContractors', result.value);
            }
        }
        rootState.listLoading = false;
    },

    async getConnectionRequestsSent({state, rootState, commit}) {
        state.connectionRequestsSentLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + "?Roles=0" + "&ShowSent=true"
        });

        const result = await request.getData(url);
        if (result != null && 'data' in result) {
            if (result.data != null) {
                commit('setConnectionRequestsSentList', result.data.value);
            }
            state.connectionRequestsSentLoader = false;
        }
    },

    async getConnectionRequestsReceived({state, rootState, commit}) {
        state.connectionRequestsReceivedLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + "?Roles=0" + "&ShowSent=false"
        });

        const result = await request.getData(url);

        if (result != null && 'data' in result) {
            if (result.data != null) {
                commit('setConnectionRequestsReceivedList', result.data.value);
            }
            state.connectionRequestsReceivedLoader = false;
        }
    },

    async deleteContractor({ rootState, state, commit }, contractorId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiContractors + '/' + contractorId
        });
        const result = await request.deleteData(url, contractorId)

        if (result != null && result.length && result?.[0] !== null) {
            notify('Contractor deleted successfully!', 'success')
            deleteObjectFromList(state.contractorFilterList, contractorId, 'id')
            rootState.totalRowCount--
            commit('setNoDataStatus', state.contractorFilterList)
            return result.data
        }
    },
}