import { IHealthCheckListItem } from '../interfaces/IHealthCheckListInterface';

export class HealthCheckListItem implements IHealthCheckListItem {
    id?: string;
    title: string;

    constructor(id?: string, title?: string) {
        this.id = id;
        this.title = title;
    }

    public getHealthChecklistItem(checklist) {
        this.id = checklist.id ? checklist.id : null;
        this.title = checklist.title ? checklist.title : null;

        return this;
    }
}