

































































import { validationMixin } from "vuelidate";
import loginComputedMixin from "@/components/login/login-computed";
import { required, email } from "vuelidate/lib/validators";
import { routeLogin } from "@/utils/endpoints";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import LoginRegistrationWrapper from "@/components/layouts/login-registration-wrapper.vue";
import resendCountdownMixin from "@/mixins/resend-countdown-mixin";
export default {
  mixins: [validationMixin, loginComputedMixin, resendCountdownMixin],
  validations: {
    userName: { required, email },
  },
  components: {
    Button,
    LoginRegistrationWrapper,
  },
  data: function () {
    return {
      userName: "",
      routeLogin
    };
  },
  computed: {
    userNameErrors() {
      let errors: any = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.required && errors.push("Email address is required.");
      !this.$v.userName.email &&
        errors.push("Please enter the correct email address");
      return errors;
    },
    isFieldsInvalid: {
      get() {
        return this.$v.$invalid;
      },
    },
    sentCurrentRequest: {
      get() {
        return this.$store.getters.sentCurrentRequest;
      },
      set(value) {
        this.$store.state.sentCurrentRequest = value;
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetResendStates();
    next();
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.proceedToForgetPassword();
    },
    proceedToForgetPassword() {
      this.resendLoading = true;
      this.disableResendButton();
      let formData = new FormData();
      formData.append("username", this.userName);
      this.$store
        .dispatch("sendForgetPasswordRequest", formData)
        .then((success) => {
          if (success) {
            this.checkCurrentRequestAndProceed();
          } else {
            this.enableResendButton();
          }
          this.resendLoading = false;
        });
    },
  },
};
