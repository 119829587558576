import { UserRoleType, UserRoleTypeLabel } from "@/enum/userRoleType";
import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'usersModule': [
        'userInviteeEmail',
        'usersLoader',
        'userInviteeRole'
    ],
    'teamMembersModule': [
        'teamMemberDetails',
        'teamMembeOldRole',
        'teamMembersList',
        'pendingTeamMemberList',
        'teamMemberSearchText',
        'memberFilterType',
        'teamMembersLoader'
    ],
    'root': [
        'enumMemberRoles',
        'noData',
        'filtersApplied',
        'totalRowCount',
        'listCount',
        'isPageRelated',
        'userStatusFilterTypeList',
        'enumUserRoles',
        'allItemsDeleted',
        'allItemsArchived',
    ]
}

export default {
    name: 'TeamMembersComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    },
    methods: {
        showTeamMemberRoleFromType(roleType: UserRoleType) {
            if (roleType != undefined) {
                return UserRoleTypeLabel.get(UserRoleType[UserRoleType[roleType]]);
            }
        },
        showTeamMemberRoleFromRolesList(roles) {
            if (roles && roles.length) {
                return UserRoleTypeLabel.get(UserRoleType[UserRoleType[roles[0].type]]);
            }
        },
    }
}