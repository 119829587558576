<template>
<div>
    <div class="su-db-box-wrapper su-db-box-wrapper-left">
        <div class="su-db-header">
            <div class="ib">
                Service
            </div>
            <div class="ib" style="float: right">
                Businesses
            </div>
        </div>
        <div class="su-layout-dist-cont">
            <div v-if="!adminOverallOverviewLoader">
                <div v-for="(distribution, index) in invoiceDistributions" :key="index">
                    <div class="su-layout-dist">
                        <div class="ib">{{ distribution.type }}</div>
                        <div class="ib" style="float: right">{{ distribution.count }}</div>
                    </div>
                </div>
            </div>
            <div v-else>
                <DistributionLoaders :loaderRows="3"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import suOverviewComputedMixin from './su-overview-computed';
import DistributionLoaders from './su-overview-distribution-loader.vue';

export default {
    mixins: [suOverviewComputedMixin],
    components: {
        DistributionLoaders
    },
    computed: {
        invoiceDistributions: {
            get() {
                return this.adminOverviewOverallStatistics.businessesDistributionByInvoicingServices
            }
        }
    }
}
</script>

<style>
.su-layout-dist {
    padding: 24px;
    border-bottom: 1px solid #eee;
}
.su-layout-dist-cont {
    height: 285px;
    overflow-y: scroll;
}
</style>