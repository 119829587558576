




























































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import {TeamJobAddressSelectionType} from '@/enum/jobAddressSelectionType';
import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import { BaseButton as Button } from "@/components/shared/buttons/index";
export default {
    name: "TeamJobsAddressesToggle",
    mixins: [TeamJobsComputed],
    data() {
        return {
            TeamJobAddressSelectionType,
            containerLeftPad,
            containerRightPad
          }
    },
    components: {
            Button,  
    }
}
