export enum InvoiceStatusType {
    Draft = 0,
    Open = 1,
    Paid = 2,
    Void = 3,
}

export enum InvoiceEmailStatusType {
    Pending = 1,
    Sent = 2
}