
export class TimesheetStatistics {
    timesheetsCount: number;
    totalTimeInMinutes: number;
    workingTimeInMinutes: number;
    travelTimeInMinutes: number;
    breakTimeInMinutes: number;

    constructor() {
        this.timesheetsCount = 0;
        this.totalTimeInMinutes = 0;
        this.workingTimeInMinutes = 0;
        this.travelTimeInMinutes = 0;
        this.breakTimeInMinutes = 0;
    }

    getTimesheetStatistics(dto: TimesheetStatistics) {
        this.timesheetsCount = dto.timesheetsCount;
        this.totalTimeInMinutes = dto.totalTimeInMinutes;
        this.workingTimeInMinutes = dto.workingTimeInMinutes;
        this.travelTimeInMinutes = dto.travelTimeInMinutes;
        this.breakTimeInMinutes = dto.breakTimeInMinutes;
        return this;
    }
}