





























import { containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import farmersComputedMixin from '@/components/farmers/farmers-computed';
import Avatar from '@/components/shared/avatars/avatar.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';

export default {
    name: 'RecivedConnectionRequest',
    mixins: [farmersComputedMixin],
    props: ['item', 'currentTab'],
    data() {
        return {
            containerLeftPad,
            containerRightPad
        }
    },
    components: {
        Avatar,
        Button
    }
}
