










import { brandColor } from '@/utils/uiconstants'

export default {
    name: 'ComponentLoaderMini',
    created() {
        this.brandColor = brandColor
    },
}
