


































































































































































































import moment from 'moment';
import { mapMutations } from 'vuex';
import timers from '@/mixins/timers';
import filterParamsMixin from '@/mixins/filter-params-mixin';
import BaseFlyout from '@/components/shared/flyouts/base-flyout.vue';
import HeaderPrimary from '../shared/headers/header-primary.vue';
import List from '@/components/shared/list.vue';
import Avatar from '../shared/avatars/avatar.vue';
import FlyoutSnackbar from '@/components/shared/snackbar/flyout-snackbar.vue';
import CalendarJobsComputed from '@/components/calendar/calendar-jobs-computed';
import ConstantValues from '@/plugins/constantValues';
import { BaseButton as Button } from '@/components/shared/buttons';
import { unscheduledJobsListModule } from '@/utils/strings';
import PaginationModule from '@/store/modules/pagination';
import UnscheduledJobsDatepicker from './unscheduled-jobs-datepicker.vue';
import TeamJobsComputed from '../team-jobs/team-jobs-computed/_team-jobs-computed';
import { defaultPageSizeCalendarJobs } from '@/utils/constants';
import { routeTeamJobsDetails } from '@/utils/endpoints';

export default {
  name: "UnscheduledJobs",
  mixins: [
    timers,
    CalendarJobsComputed,
    filterParamsMixin,
    TeamJobsComputed
  ],
  components: {
    BaseFlyout,
    HeaderPrimary,
    List,
    Avatar,
    FlyoutSnackbar,
    UnscheduledJobsDatepicker,
    Button
  },
  computed: {
    someUnscheduledJobsSelected() {
      return this.selectedItems.length > 0 && !this.allUnscheduledJobsSelected
    },
    allUnscheduledJobsSelected() {
      return this.selectedItems.length === this.jobsUnscheduledJobsData.length
    },
    selectedItemsLabel() {
      if (this.selectedItems.length === 1) return '1 Job selected';
      return `${this.selectedItems.length} Jobs selected`;
    },
    hasScheduledJobs() {
      return this.jobsUnscheduledJobsData.some(job => !!job.scheduledAt);
    }
  },
  props: ['value'],
  data() {
    return {
      isChecked: false,
      datepickerPosition: {},
      datepickerValue: null,
      isSubmitInProgress: false,
      isListLoading: true,
      showDatepicker: false,
      routeTeamJobsDetails
    }
  },
  methods: {
    ...mapMutations({
      clearJobsListFilterOption: "setClearFilterOptions",
      resetSnackbarDetails: "resetSnackbarDetails",
      resetPaging: "resetPaging",

    }),
    setUrlParamsLocal() {
      this.setLocalParamsMx(this.teamJobsListSearchText, "unscheduledJobSearchQuery");
    },
    async handleSearchForJobs() {
      this.clearJobsListFilterOption();
      this.resetSnackbarDetails();
      this.resetPaging();
      this.jobsUnscheduledJobsData = [];
      this.filtersApplied = true;
      this.isListLoading = true;
      await this.setFilterOptionsAndGetJobs();
    },
    async setFilterOptionsAndGetJobs(filter = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
      this.$store.commit('setClearFilterOptions');
      this.$store.commit('resetPaging');
      await this.$store.dispatch('setUnscheduledJobsListFilter', filter);
      this.$store.commit(`${unscheduledJobsListModule}/fetchingData`);
      this.setUrlParamsLocal();

      const response = await this.$store.dispatch('getUnscheduledJobs');
      await this.$store.dispatch(`${unscheduledJobsListModule}/updateStates`, response);

      if (!this.jobsUnscheduledJobsSearchText) {
        this.clearJobsListFilterOption();
        this.filtersApplied = false;
      }

      this.isListLoading = false;
    },
    selectAllUnscheduledJobs() {
      this.selectedItems = [];
      this.allItemsDeleted = false;
      if (this.isChecked) {
        this.jobsUnscheduledJobsData.map((job) => this.selectedItems.push(job.id));
      }
    },
    handleScheduleDateChange(newDate, jobId) {
      this.$store.dispatch('updateUnscheduledJobStartDate', {jobId, date: newDate});
      this.closeDatepicker();
      this.selectedItems = [];
    },
    getFormattedScheduledDate(date) {
      return moment.utc(date).format('DD MMMM YYYY');
    },
    openDatepicker(event: MouseEvent, jobScheduledDate?: string) {
      this.showDatepicker = true;
      this.$nextTick(() => {
        this.datepickerValue = jobScheduledDate;

        const target = event.currentTarget as HTMLElement;
        const { top, right, bottom } = target.getBoundingClientRect();
        this.datepickerPosition = { top, right: window.innerWidth - right, bottom };

        const targetListItem = target.closest('.datepicker-trigger');
        const datepicker = this.$refs.datepickerRef.$el;
        targetListItem.appendChild(datepicker);
      });
    },
    closeDatepicker() {
      this.datepickerValue = null;
      this.datepickerPosition = null;
      this.showDatepicker = false;
    },
    async handleSubmit() {
      this.isSubmitInProgress = true;
      const updateRequests = [];
      this.jobsUnscheduledJobsData.map((updatedJob) => {
        if (updatedJob.scheduledAt) {
          updateRequests.push(this.updateTeamJob(updatedJob));
        }
      });
      await Promise.all(updateRequests);
      this.resetUnscheduledJobsList();
      this.$emit('submit');
      this.isSubmitInProgress = false;
    },
    async resetUnscheduledJobsList() {
      this.clearJobsListFilterOption();
      this.resetSnackbarDetails();
      this.resetPaging();
      this.closeDatepicker();
      this.jobsUnscheduledJobsData = [];
      this.filtersApplied = false;
      this.isListLoading = true;
      await this.$store.dispatch('updateOffsetAndLimitValues', [0, defaultPageSizeCalendarJobs]);
    }
  },
  watch: {
    value(value) {
      if (!value) {
        this.resetUnscheduledJobsList();
      } else {
        this.setFilterOptionsAndGetJobs();
      }
    },
    selectedItems() {
      if (this.selectedItems.length) {
        this.snackbarShow = true;
      } else {
        this.snackbarShow = false;
        this.isChecked = false;
      }
    }
  },
  created() {
    const modules = [unscheduledJobsListModule];
    modules.forEach(async(moduleName) => {
    if (!this.$store.hasModule(moduleName)) {
        await this.$store.registerModule(moduleName, PaginationModule);
      }
    });
  }
}
