







export default {
    name: 'labelAndDetailsReadonly',
    props: [
        'labelValue',
        'detailsValue',
    ]
}
