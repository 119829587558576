import { deleteObjectFromList } from "@/utils/helpers"
import { mapMutations } from "vuex"
import StringConstants from "@/plugins/stringConstants";
import ConstantValues from "@/plugins/constantValues";
import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'usersModule': [
        'userInviteeEmail',
        'usersLoader',
        'dialogInvitation',
        'invitationId',
        'connectionRequestType',
        'dialogType',
    ],
    'contractorsModule': [
        'contractorSearchText',
        'contractorFilterType',
        'contractorFilterList',
        'pendingContractorList',
        'noAcceptedContractors',
        'noPendingContractors',
        'acceptedContractorTotalCount',
        'pendingContractorTotalCount',
        'connectionRequestsSentList',
        'connectionRequestsReceivedList',
        'connectionRequestsSentLoader',
        'connectionRequestsReceivedLoader',
    ],
    'root': [
        'filtersApplied',
        'statusFilterTypeList',
        'noData',
        'listCount',
        'isPageRelated',
        'listLoading'
    ]
}

export default {
    name: 'ContractorsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        invitationDialogPromptTitle() {
            return this.dialogType == 'accept' ? StringConstants.userAcceptInvitationPromptTitle : StringConstants.userDeleteInvitationPromptTitle
        },

        invitationDialogPromptSubtitle() {
            return this.dialogType == 'accept' ? StringConstants.userAcceptInvitationPromptSubtitle : StringConstants.userDeleteInvitationPromptSubtitle
        },
    },
    
    methods: {
        ...mapMutations({
            clearContractorFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),
        confirmDeleteInvitation(id: any, connectionRequestType) {
            this.invitationId = id;
            this.dialogInvitation = true;
            this.connectionRequestType = connectionRequestType;
            this.dialogType = 'delete';
        },
        confirmAcceptInvitation(id) {
            this.invitationId = id;
            this.dialogInvitation = true;
            this.dialogType = 'accept';
        },
        acceptConnection() {
            this.$store.dispatch('acceptUserInvite', this.invitationId).then(res => {
                if (res) {
                    this.$store.commit('setConnectionRequestsReceivedList', deleteObjectFromList(this.connectionRequestsReceivedList, this.invitationId, 'code'));
                    if (this.contractorFilterType == 0) {
                        this.refreshContractorList();
                    }
                }
                this.dialogInvitation = !this.dialogInvitation;
            })
        },
        declineConnection() {
            this.$store.dispatch('declineUserInvite', this.invitationId).then(res => {
                if (res) {
                    this.$store.commit('setConnectionRequestsReceivedList', deleteObjectFromList(this.connectionRequestsReceivedList, this.invitationId, 'code'));
                }
                this.dialogInvitation = !this.dialogInvitation;
            })
        },
        deleteSentConnection() {
            this.$store.dispatch('deleteSentConReq', this.invitationId).then(res => {
                if (res) {
                    this.$store.commit('setConnectionRequestsSentList', deleteObjectFromList(this.connectionRequestsSentList, this.invitationId, 'code'));
                    if (this.contractorFilterType == 1) {
                        this.refreshPendingContractorList();
                    }
                }
                this.dialogInvitation = !this.dialogInvitation;
            })
        },
        clearContractorList() {
            this.contractorFilterList = [];
            this.clearContractorFilterOptions();
            this.resetPaging();
        },
        clearPendingContractorList() {
            this.pendingContractorList = [];
            this.clearContractorFilterOptions();
            this.resetPaging();
        },
        refreshContractorList() {
            this.clearContractorList();
            this.$store.dispatch('setContractorFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(res => {
                if (res) {
                    this.$store.dispatch('getContractorFiltered');
                }
            })
        },
        refreshPendingContractorList() {
            this.clearPendingContractorList();
            this.$store.dispatch('setContractorFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(res => {
                if (res) {
                    this.$store.dispatch('getPendingContractorFiltered');

                }
            })
        },
        resetContractorSearch() {
            this.$store.commit('setClearUserInvitationState');
         },
        
    }
}