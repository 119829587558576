import { TaxType } from "@/enum/taxType";

// api endpoints
export const apiAccount = 'account';
export const apiUsers = 'users';
export const apiLogin = 'account/login';
export const apiForgetPassword = '/account/reset_password';
export const apiRefreshToken = `${apiAccount}/access/renew`;
export const apiVehicles = '/vehicles';
export const apiProducts = '/products';
export const apiProductUnits = `${apiProducts}/units`;
export const apiFields = '/fields';
export const apiImplements = '/implements';
export const apiPermissions = `${apiAccount}/permissions`;
export const apiTeamMembers = 'members';
export const apiFarmers = '/farmers';
export const apiContractors = '/contractors';
export const apiOperationTypes = '/operation/types';
export const apiTaxes = '/taxes';
export const apiTaxesVat = `${apiTaxes}/${TaxType.vat}`;
export const apiInvoiceConfigutation = `${apiUsers}/owner`;
export const apiDefaultTaxes = `${apiTaxes}/default-names`;
export const apiDefaultTaxesName = '/default-name';
export const apiDefaultTaxesRate = '/default-rates';
export const apiTaxesVatDescription = `${apiTaxes}/${TaxType.vat}/description`;
export const apiOperations = 'operations';
export const apiTimeSheets = '/timesheets';
export const apiJobs = '/jobs';
export const apiJobForceFinish = '/force-finish';
export const apiJobPartComplete = '/part-complete';
export const apiJobComplete = '/complete';
export const apiWork = '/work';
export const apiJobAccept = '/accept';
export const apiJobDecline = '/decline';
export const apiJobCancel = '/cancel';
export const apiCards = '/cards';
export const apiSubscriptions = '/subscription';
export const apiSubscriptionsPricing = '/subscription/pricing';
export const apiSubscriptionsCancel = '/subscription/cancel';
export const apiSetDefaultCard = '/set_default';
export const apiInvoices = '/invoices';
export const apiInvoicesApprove = '/approve-for-invoicing';
export const apiInvoicesDraft = `${apiInvoices}/draft`;
export const apiInvoicesOpen = '/open';
export const apiInvoicesFinalize = '/finalize';
export const apiInvoicesItems = '/items';
export const apiJobLocations = '/locations';
export const apiQuickbooks = '/quickbooks';
export const apiSage = '/sage';
export const apiQuickbooksInvoice = '/quickbooks/invoices';
export const apiSageInvoice = '/sage/invoices';
export const apiXeroInvoice = '/xero/invoices';
export const apiContact = '/contact';
export const apiInvitation = '/invitations';
export const apiInvoicesPaid = '/paid';
export const apiImport = '/import';
export const apiOwner = '/users/owner';
export const apiPromoCode = `${apiSubscriptions}/promotion-codes?promoCode=`;
export const apiBusinessProfile = `${apiUsers}/business`;
export const apiAccountSettings = `${apiUsers}/settings`;
export const apiAccountAddress = `${apiBusinessProfile}/addresses`;
export const apiAddresses = '/business/addresses';
export const apiUnlinkUsers = `${apiUsers}/unlinks`;
export const apiGlobalCountries = '/globalization/countries';
export const apiCities = '/cities';
export const apiGlobalTimeZones = '/globalization/timezones';
export const apiGlobalCurrency = '/globalization/currencies';
export const apiColourCode = '/colourCode';
export const apiAdmin = '/admin';
export const apiAdminRecentOverview = `${apiAdmin}/recent-overview`;
export const apiAdminOverallOverview = `${apiAdmin}/overview`;
export const apiAdminUsers = `${apiAdmin}/users`;
export const apiAdminBusinesses = `${apiAdmin}/businesses`;
export const apiAdminBusinessesChangeToContractor = id => `${apiAdmin}/businesses/${id}/change-to-contractor`;
export const apiJobsSummary = '/jobs_summary';
export const apiInvoiceUrl = '/';
export const apiContactsImportUrl = '/import-from-integration';
export const apiCustomersUpdateUrl = '/update-from-integration';
export const apiImportFromFile = '/import-from-file';
export const apiCustomersImport = `${apiFarmers}${apiImportFromFile}`;
export const apiCustomersUpdateFromIntegration = `${apiFarmers}${apiCustomersUpdateUrl}`;

// router paths
export const routeRoot = '/';
export const routeAdd = '/add';
export const routeEdit = '/edit';
export const routeDetails = '/details';

export const routeOnboard = '/onboard';
export const routeLogin = '/login';
export const routeForgetPassword = '/forgetpassword';
export const routeResetPassword = '/resetpassword';
export const routeSignUp = '/signup';
export const routeResendEmailVerification = "/resend_email_verification";

export const routeJobs = '/jobs';
export const routeTeamJobs = '/jobs';
export const routeJobsAdd = `${routeTeamJobs}${routeAdd}`;
export const routeTeamJobsAdd = `${routeTeamJobs}${routeAdd}`;
export const routeTeamJobsDetails =  `${routeTeamJobs}${routeDetails}/`;

export const routeFields = '/fields';
export const routeFieldsAdd = `${routeFields}${routeAdd}`;
export const routeFieldsEdit = `${routeFields}${routeEdit}`;

export const routeOperations = '/operations';
export const routeTimesheets = '/timesheets';
export const routeTeamMembers = '/members';
export const routeContractors = '/contractors';
export const routeCustomers = '/customers';
export const routeVehicles = '/vehicles';
export const routeImplements = '/implements';
export const routeProducts = '/products';

export const routeJobDetails = '/jobdetails';
export const routeSubscriptions = '/subscriptions';

export const routeInvoices = '/invoices';
export const routeInvoicesDetails = `${routeInvoices}/`;
export const routeInvoicesAdd = `${routeInvoices}${routeAdd}`;
export const routeInvoicesAddExternal = `${routeInvoices}${routeAdd}/external`;
export const routeInvoicesDraft = `${routeInvoices}/draft`;
export const routeIntegrations = '/integrations';
export const routeQuickBooks = `${routeIntegrations}/quickbooks`;
export const routeXero = `${routeIntegrations}/xero`;
export const routeSage = `${routeIntegrations}/sage`;

export const routeContact = '/contact';
export const routeContactAdd = `${routeContact}${routeAdd}`;
export const routeCustomersUpdate = `${routeCustomers}${routeEdit}`;
export const routeCustomersDetails = `${routeCustomers}${routeDetails}`;
export const routeAccount = '/account';
export const routeSeed = '/seed';
export const routeJobsReport = `${routeJobs}/:id/report`;

export const routeOverview = '/overview';
export const routeUsers = '/users';
export const routeBusinesses = '/businesses';
export const routeBusinessesDetails = `${routeBusinesses}/:id`;

// routes for terms of service and privacy policy
export const termsOfServiceURL = 'https://cdn.ag-drive.com/03062021/terms_and_use.pdf';
export const privacyPolicyURL = 'https://cdn.ag-drive.com/03062021/privacy_policy.pdf';