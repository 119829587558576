

















import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { formFieldPb } from "@/utils/uiconstants";

export default {
    name: "TeamJobsAddressesInstructions",
    mixins: [TeamJobsComputed],
    data() {
        return {
            formFieldPb
        }
    }
}
