export enum JobOperatorWorkStatusType {
    Started = 0,
    Paused = 1,
    Completed = 2,
}

export const JobOperatorWorkStatusTypeLabel = new Map<number, string>([
    [JobOperatorWorkStatusType.Started, 'Working'],
    [JobOperatorWorkStatusType.Paused, 'On a break'],
    [JobOperatorWorkStatusType.Completed, 'Finished working']
]);