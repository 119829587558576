import { apiJobs, apiInvoicesApprove, apiFarmers, apiUsers, apiOperationTypes, apiTeamMembers, apiFields, apiVehicles, apiImplements, apiProducts } from '@/utils/endpoints';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import { actionsFilters } from './actions-filters';
import { ResponseList, TeamJob } from '@/data/models';
import ConstantValues from '@/plugins/constantValues';
import StringConstants from '@/plugins/stringConstants';
import { jobColors } from '../../jobs-calendar/config';
import { isScheduledQuery, searchTextQuery } from '@/utils/constants';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';

const mainActions = {

    async getJobsForListView({ state, commit, rootState, dispatch }) {
        rootState.listLoading = true;
        dispatch("jsonToQueryString", rootState.filterOptions);

        let url = buildUrl(rootState.baseUrl, {
            path: apiJobs + rootState.tempUrl
        });
        if (state.teamJobsFilterStatusSelected != null && state.teamJobsFilterStatusSelected.length > 0) {
            url += ('&Statuses=' + state.teamJobsFilterStatusSelected);
        }
        if (state.teamJobsFilterInvoiceStatusValue != null && state.teamJobsFilterInvoiceStatusValue.length > 0) {
            url += state.teamJobsFilterInvoiceStatusValue;
        }
        const result: ResponseList<TeamJob> = await requests.getList<TeamJob>(url, {
            rootState, 
            cancellable: true
        });
        if (result != null) {
            if (result.value.length > 0) {
                commit('setJobsFilteredList', result.value);
            }
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.value);
            }
            rootState.listLoading = false;
        }
    },

    async updateCalendarPosition({ state }) {
        const calendarWrapper = document.querySelector('.calendar-wrapper');
        calendarWrapper.scrollIntoView({ behavior: 'smooth' });
        calendarWrapper.scrollTop = 0;
    },

    async getTeamJobsList({ state, dispatch }) {
        if (state.jobViewType === 'List') {
            await dispatch('getJobsForListView');
        }

        if (state.jobViewType === 'Calendar') {
            await dispatch('updateCalendarPosition');
            await dispatch('getJobsForCalendarView');
        }
    },

    async getJobsForCalendarView({ state, rootState, dispatch }) {
        state.calendarViewJobs = [];
    
        await dispatch('updateOffsetAndLimitValues', [0, 500]);
    
        dispatch("jsonToQueryString", rootState.filterOptions);
        rootState.listLoading = true;
    
        let offset = 0;
    
        let url;
        let result: ResponseList<TeamJob>; 
    
        do {    
            rootState.listLoading = true;

            url = buildUrl(rootState.baseUrl, {
                path: apiJobs + rootState.tempUrl
            });

            const jobStatusesToInclude = [
                TeamJobStatusType.Ready, 
                TeamJobStatusType.InProgress, 
                TeamJobStatusType.AwaitingCompletion, 
                TeamJobStatusType.PartCompleted,
                TeamJobStatusType.Completed,
            ];

            jobStatusesToInclude.forEach((status) => {
                url += `&Statuses=${status}`;
            });

            url += `&${isScheduledQuery}=true`;
    
            const urlObj = new URL(url);
            const searchParams = new URLSearchParams(urlObj.search);
    
            searchParams.append("Start", state.calendarStartDate);
            searchParams.append("End", state.calendarEndDate);

            if (!url.includes(searchTextQuery) && state.teamJobsListSearchText) {
                searchParams.append(searchTextQuery, state.teamJobsListSearchText?.trim());
            }

            if (!url.includes("CustomerIds") && state.teamJobsFilterCustomerValue) {
                searchParams.append("CustomerIds", state.teamJobsFilterCustomerValue);
            }

            if (!url.includes("OperationIds") && state.teamJobsFilterOperationValue) {
                searchParams.append("OperationIds", state.teamJobsFilterOperationValue);
            }

            if (!url.includes("OperatorIds") && state.teamJobsFilterTeamMemberValue) {
                searchParams.append("OperatorIds", state.teamJobsFilterTeamMemberValue);
            }

            if (!url.includes("FieldIds") && state.teamJobsFilterFieldValue) {
                searchParams.append("FieldIds", state.teamJobsFilterFieldValue);
            }

            if (!url.includes("VehicleIds") && state.teamJobsFilterVehicleValue) {
                searchParams.append("VehicleIds", state.teamJobsFilterVehicleValue);
            }

            if (!url.includes("ImplementIds") && state.teamJobsFilterImplementValue) {
                searchParams.append("ImplementIds", state.teamJobsFilterImplementValue);
            }

            if (!url.includes("ProductIds") && state.teamJobsFilterProductValue) {
                searchParams.append("ProductIds", state.teamJobsFilterProductValue);
            }
    
            urlObj.search = searchParams.toString();
    
            url = urlObj.toString();
    
            result = await requests.getList<TeamJob>(url, {
                rootState,
                cancellable: true
            });
    
            rootState.listLoading = false;
    
            if (result != null) {
                result.value?.forEach((job) => {
                    if (!state.calendarViewJobs.some(j => j.id === job.id))
                        state.calendarViewJobs.push(job);
                });
    
                offset += result.value.length;
                await dispatch('updateOffsetAndLimitValues', [offset, 500]);
                dispatch("jsonToQueryString", rootState.filterOptions);
            }
        } while (result != null && result.value.length > 0);
    
        dispatch('updateCalendarViewJobEvents');
    },

    async createJobEvent({ state }, job) {
        const ignoreDragOnStatuses = [
            TeamJobStatusType.Pending,
            TeamJobStatusType.AwaitingCompletion,
            TeamJobStatusType.InProgress,
            TeamJobStatusType.PartCompleted,
            TeamJobStatusType.Completed,
            TeamJobStatusType.Cancelled
        ]

        function getColorFromStatus(status) {
            let color: any = '#E9ECF0';
            const filteredColor = jobColors.filter(c => c.type === status)?.[0];
      
            if (filteredColor)
              color = filteredColor;
      
            return color;
        }

        let start;
        let end;

        if (job.workStartedOn)
            start = new Date(job.workStartedOn);
        else if (job.scheduledAt)
            start = new Date(job.scheduledAt);
        
        if (job.workCompletedOn)
            end = new Date(job.workCompletedOn);        
        else if (job.lastPartCompletedOn)
            end = new Date(job.lastPartCompletedOn);
        else if (job?.timeRecords?.length > 0) {
            const lastTimeRecord = job.timeRecords[job.timeRecords.length - 1];
            end = new Date(lastTimeRecord.endedAt);
        }
        else if (job.workStartedOn)
            end = new Date(job.workStartedOn);
        else if (job.scheduledAt)
            end = new Date(job.scheduledAt);

        const jobEvent = {
            id: job?.id,
            name: job?.operation?.title || job?.number,
            start: start,
            end: end,
            color: getColorFromStatus(job.status),
            status: job?.status,
            timed: true,
            operators: job.operators,
            moreInformation: job,
            draggable: !ignoreDragOnStatuses.includes(job.status)
        }

        return jobEvent;
    },

    async updateCalendarViewJobEvents({ state, dispatch }) {
        state.calendarViewJobEvents = [];

        if (state.calendarViewJobs) {
            state.calendarViewJobs.forEach(async (job) => {
                if (job.scheduledAt && !state.calendarViewJobEvents.some(jobEvent => jobEvent.id === job.id)) {
                    const jobEvent = await dispatch('createJobEvent', job);
                    state.calendarViewJobEvents.push(jobEvent);
                } else {
                    console.error(`Job ${job.number} not scheduled!`);
                }
            });
        }
    },

    async approveJobForInvoicing({ rootState, commit }, jobId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + jobId + apiInvoicesApprove
        });
        const result = await requests.postData(url, null);
        commit('updateJobInList', result[0]);
        return result;
    },

    async getTeamMembersForJobsSearch({ state, rootState, commit, dispatch }) {
        state.teamJobsTeamMembersDropdownLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + '/' + apiTeamMembers + rootState.tempUrl
        });
        const result = await requests.getData(url, true);
        state.teamJobsTeamMembersDropdownLoader = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length > 0) {
                    commit('setTeammembersListForJobsSearch', result.data.value);
                    commit('setTeammembersListSizeForJobsSearch', result.data.size);
                }
            }
            return result
        }
    },

    async getFieldsForJobsSearch({ state, rootState, commit, dispatch }) {
        state.teamJobsFieldsDropdownLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiFields + rootState.tempUrl
        });
        const result = await requests.getData(url, true);
        state.teamJobsFieldsDropdownLoader = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length > 0) {
                    commit('setFieldsListForJobsSearch', result.data.value);
                    commit('setFieldsListSizeForJobsSearch', result.data.size);
                }
            }
            return result
        }
    },

    async getVehiclesForJobsSearch({ state, rootState, commit, dispatch }) {
        state.teamJobsVehiclesDropdownLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles + rootState.tempUrl
        });
        const result = await requests.getData(url, true);
        state.teamJobsVehiclesDropdownLoader = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length > 0) {
                    commit('setVehiclesListForJobsSearch', result.data.value);
                    commit('setVehiclesListSizeForJobsSearch', result.data.size);
                }
            }
            return result
        }
    },

    async getImplementsForJobsSearch({ state, rootState, commit, dispatch }) {
        state.teamJobsImplementsDropdownLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiImplements + rootState.tempUrl
        });
        const result = await requests.getData(url, true);
        state.teamJobsImplementsDropdownLoader = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length > 0) {
                    commit('setImplementsListForJobsSearch', result.data.value);
                    commit('setImplementsListSizeForJobsSearch', result.data.size);
                }
            }
            return result
        }
    },

    async getProductsForJobsSearch({ state, rootState, commit, dispatch }) {
        state.teamJobsProductsDropdownLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiProducts + rootState.tempUrl
        });
        const result = await requests.getData(url, true);
        state.teamJobsProductsDropdownLoader = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length > 0) {
                    commit('setProductsListForJobsSearch', result.data.value);
                    commit('setProductsListSizeForJobsSearch', result.data.size);
                }
            }
            return result
        }
    },

    async getOperationsListForJobsSearch({ state, rootState, commit }) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiOperationTypes + '?includeOperations=true&Preference=0' + (state.teamJobsFilterOperationSearchText ? ('&SearchText=' + state.teamJobsFilterOperationSearchText) : '')
        });
        const result = await requests.getData(url, true);
        if (result != null && 'data' in result) {
            if (result.data.value != null) {
                commit('setOperationsListForJobsSearch', result.data.value);
            }
        }
    },

    async getOperatorDetails({ rootState }, operatorId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + operatorId
        });
        const result = await requests.getData(url);
        if (result && result.data) {
            return result.data
        }
    },

    async getCustomersForJobsSearch({ state, rootState, commit, dispatch }) {
        state.teamJobsCustomersDropdownLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + rootState.tempUrl
        });
        const result = await requests.getData(url, true);
        state.teamJobsCustomersDropdownLoader = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length <= 0) {
                    rootState.searchNotify = StringConstants.noSearchResultFoundText;
                }
                else {
                commit('setCustomersListForJobsSearch', result.data.value);
                commit('setCustomersListSizeForJobsSearch', result.data.size);
                }
            }
            return result
        }
    },
}

const actions = { ...mainActions, ...actionsFilters}

export default actions;