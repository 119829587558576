import { mapActions, mapMutations } from "vuex"
import { UserRoleType } from "@/enum/userRoleType";
import ConstantValues from "@/plugins/constantValues";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
import { routeFields } from "@/utils/endpoints";

const computedProperties: Record<string, Array<string>> = {
    'fieldsModule': [
        'fieldDetails',
        'addFieldLoader',
        'colorUpdateOnMap',
        'fieldFarmerSearchTextInternal',
        'fieldCordList',
        'fieldFarmList',
        'fieldOwnerList',
        'yearList',
        'polyPath',
        'polygon',
        'polygonShape',
        'areaInvalid',
        'fieldListOwnerId',
        'fieldOwnerFilterList',
        'fieldFilterList',
        'editFieldLoader',
        'oldOwnerId',
        'fieldLoader',
        'fieldReadonly',
        'areaInSquareMeter',
        'convertedArea',
        'mountedGMaps',
        'fieldsListMap',
        'fieldsListMapData',
        'fieldsListMapLoader',
        'fieldsListMapLoaded',
        'fieldsListMapTotal',
        'fieldsListFieldShapes',
        'fieldFarmerSearchText',
        'fieldSearchText',
        'bulkUploadInProgress',
        'bulkUploadOwner',
        'compressedFile',
        'fieldOwnerListForBulkUpload',
        'fieldsListForSearchReadOnly',
        'fieldSearchTextReadOnly',
        'fieldSearchResultsReadOnly',
        'showEditableFields',
        'fieldsTotalCount',
        'fieldCropNameToAdd',
        'fieldCropYearToAdd',
        'fieldInProgressJobs',
        'fieldStatus',
        'fieldsListMapOffset',
        'userIsFarmerOrFarmerManager',
        'fieldsCustomersFilterDropdownLoader',
        'fieldOwnerListSize',
        'tempFieldPolygon',
        'drawingManager',
        'showFieldsList',
        'totalFieldsCount',
        'infoWindowData',
        'addingField',
        'editingField',
        'editingAreaOnMap',
        'fieldsMountedOnMap',
        'mapAlertMessage',
        'mapTriggeredEditFieldData',
        'fieldsInfoWindowContent',
        'placesSearchQuery',
        'placesSearchResults',

    ],
    'root': [
        'loginUserRole',
        'searchNotify',
        'selectedItems',
        'filtersApplied',
        'noData',
        'listCount',
        'totalRowCount',
        'snackbarShow',
        'allItemsDeleted',
        'isRoutedFromReferencePage',
        'isPageRelated',
        'enumOwnership',
        'enumActiveStatus',
        'loginUserBusinessName',
        'drawerOpen',
        'dialogBulkActionResponse',
        'dialogBulkActionResponseTitle',
    ]
}

export default {
    name: 'FieldsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        ownerRole: {
            get() {
                return localStorage.getItem('ownerRole')
            }
        }
    },
    data() {
        return {
            dialogField: false,
            deleteFieldId: null,
            deleteFieldOwner: null,
        }
    },
    methods: {
        ...mapMutations({
            setDrawerOpen: "setDrawerOpen",
            setMapTriggeredEditFieldData: "setMapTriggeredEditFieldData",
            resetFieldDetails: "resetFieldDetails"
        }),
        ...mapActions({
            setSearchInternalFarmersFilterAsync: "setSearchInternalFarmersFilter",
            getFieldsOwnerListInternalAsync: "getFieldsOwnerListInternal",
            getAllFieldsListForMap: 'getAllFieldsListForMap',
            getGoogleMapForFieldsListMap: 'getGoogleMapForFieldsListMap',
        }),
        getInternalFarmersList() {
            this.initializeFieldsOwnerListInternal();
            this.setSearchInternalFarmersFilterAsync({
                offset: ConstantValues.defaultPageOffsetSize,
                limit: ConstantValues.defaultPageLimitSize,
                searchText: '',
                moduleNamespace: this.$stringConstants('fieldsAddFilterCustomersPaginationModule')
            }).then(() => {
                this.getFieldsOwnerListInternalAsync();
            });
        },
        initializeFieldsOwnerListInternal() {
            this.$store.commit("setDefaultOwnerList", this.loginUserBusinessName);
        },
        checkIfUserIsFarmerOrFarmerManager() {
            const userIsFarmer = this.loginUserRole == UserRoleType.Farmer;
            const userIsFarmerManager = this.loginUserRole == UserRoleType.Manager && this.ownerRole == "Farmer";
            if (userIsFarmer || userIsFarmerManager) return true;
            return false;
        },
        async resetFieldMapDetails() {
            await this.$store.dispatch('clearFieldListsLabelsAndFields')
        },
        async getAllFieldsForMap() {
            await this.getAllFieldsListForMap();
            return;
        },
        confirmDeleteField: function (ids) {
            this.deleteFieldId = ids[0];
            this.deleteFieldOwner = ids[1];
            this.dialogField = true;
        },
        deleteField: function (callback = () => {return}) {
            this.dialogField = false;
            this.$store.dispatch("deleteField", {
                fieldId: this.deleteFieldId,
                fieldOwnerId: this.deleteFieldOwner,
            }).then(() => callback());
        },
        cleanUpMapAndFieldFormAfterAction() {
            this.setDrawerOpen(false);
            this.setMapTriggeredEditFieldData(null);
            this.resetFieldDetails();
            history.pushState({}, null, routeFields);
        }
    }
}