










































































































import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import moment from "moment";

export default {
  mixins: [subscriptionsComputedMixin],
  methods: {
    downloadInvoiceBill(downloadUrl: string) {
      window.open(downloadUrl, "_blank");
    },
    getLocalDate(invoiceDate) {
      return invoiceDate
        ? moment.utc(invoiceDate).local().format("DD MMM YYYY")
        : "";
    },
  },
  mounted() {
    this.$store.dispatch("getInvoicesHistory");
  },
};
