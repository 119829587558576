

































































































































































































































































































































import SubsscriptionAddCardDialog from "@/components/subscriptions/subscriptions-add-card-dialog.vue";
import SubscriptionDefaultCardConfirmDialog from "@/components/subscriptions/subscriptions-default-card-confirm-dialog.vue";
import { mapMutations } from "vuex";
import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import { openOverlay } from "@/utils/helpers";
import ButtonMiniTertiaryDark from "@/components/shared/buttons/button-mini-tertiary-dark.vue";
import ButtonMiniPrimary from "@/components/shared/buttons/button-mini-primary.vue";
import { enableDashboardNavigation } from "@/utils/helpers/layout-helpers";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { DeleteItemDialog } from "@/components/shared/dialogs/index";
import { SubscriptionStatusType } from "@/enum/subscriptionStatusType";
import { InvoiceStatusType } from "@/enum/invoiceStatusType";

export default {
  data() {
    return {
      dialogDeleteCard: false,
      cardIdToDelete: null,
      showPromoCodeApplyDialog: false,
      enteredPromoCodeValue: null,
      SubscriptionStatusType,
      InvoiceStatusType,
    };
  },
  mixins: [subscriptionsComputedMixin],
  components: {
    SubsscriptionAddCardDialog,
    SubscriptionDefaultCardConfirmDialog,
    ButtonMiniTertiaryDark,
    ButtonMiniPrimary,
    Button,
    DeleteItemDialog,
  },
  mounted() {
    this.setClearCardsList();
    this.$store.dispatch("getCardsList");
  },
  methods: {
    ...mapMutations({
      setClearCardsList: "setClearCardsList",
    }),
    proceedDeleteCard() {
      this.$store.dispatch("deleteCard", this.cardIdToDelete).then(() => {
        this.$store.dispatch("getCardsList");
      });
      this.dialogDeleteCard = false;
    },
    cancelCardDeletion() {
      this.dialogDeleteCard = false;
      this.cardIdToDelete = null;
    },
    toggleManageCardsFormView() {
      this.enableCardAddButton = false;
      openOverlay("manageCardsFormOverlay");
    },
    applyPromoCode() {
      if (this.subscriptionPromocodeApplied) {
        this.removePromoCode();
      } else {
        this.subscriptionPromocode = this.enteredPromoCodeValue;
        this.$store.dispatch("getPromoCodeDetails");
      }
    },
    checkPaymentStatus() {
      setInterval(() => {
        if (
          this.subscriptionDetails &&
          this.subscriptionDetails.latestInvoice != null &&
          this.subscriptionDetails.latestInvoice.status == 1
        ) {
          this.$store.dispatch("getSubscriptionDetails").then((subResponse) => {
            if (subResponse) {
              if (
                subResponse.latestInvoice &&
                subResponse.latestInvoice.status == 2
              ) {
                enableDashboardNavigation();
                location.reload();
              }
            }
          });
        }
      }, 10000);
    },
    payBill(invoicePaymentUrl: string) {
      window.open(invoicePaymentUrl, "_blank");
    },
    removePromoCode() {
      this.enteredPromoCodeValue = null;
      this.$store.commit("setClearPromoCodeInfo");
    },
    initiateSubscription() {
      this.showPromoCodeApplyDialog = false;
      this.createSubscription();
    },
    createSubscription() {
      this.$store.dispatch("createStripeSubscription").then(() => {
        setTimeout(() => {
          location.reload();
        }, 3000);
      });
    },
  },
};
