




















































































































































































































































































































































































































import { mapState, mapMutations } from "vuex";
import { closeOverlay, checkFieldsValidity } from "@/utils/helpers";
import {
    formFieldPb,
    containerLeftPad,
    containerRightPad,
} from "@/utils/uiconstants";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import operationsComputedMixin from "@/components/operations/operations-computed";
import ButtonCloseIcon from "@/components/shared/buttons/button-close.vue";
import {
    HeaderPrimary,
    SubHeaderPrimary,
    DescriptionPrimary,
} from "@/components/shared/headers/index";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import LoadMoreItem from "../shared/load-more-item.vue";
import PaginationModule from '@/store/modules/pagination';
import {
    subOperationVehiclesDropdownModule,
    subOperationImplementsDropdownModule,
    subOperationProductsDropdownModule
} from '@/utils/strings';
import scrollerMixin from '@/mixins/scroller-mixin';
import { ResourceType } from '@/enum/resourceType';
import Timers from '@/mixins/timers';
import { UserRoleType } from '@/enum/userRoleType';

export default {
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
            loadInfoName: "",
            loadInfoUnit: "",
            checkListItemName: "",
            isOpenHealthEditBtn: true,
            ResourceType,
            UserRoleType
        };
    },
    components: {
        ListLoader,
        SubHeaderPrimary,
        DescriptionPrimary,
        ButtonCloseIcon,
        HeaderPrimary,
        Button,
        LoadMoreItem,
    },
    mixins: [
        operationsComputedMixin, 
        scrollerMixin,
        Timers
    ],
    watch: {
        subOperationIsTaxable(changedVal) {
            if (!changedVal) {
                this.subOperationTaxPercentage = 0;
            }
        },
    },
    computed: {
        ...mapState({
            vehiclesLoading(state, getters) {
                return getters[`${subOperationVehiclesDropdownModule}/loading`]
            },
            implementsLoading(state, getters) {
                return getters[`${subOperationImplementsDropdownModule}/loading`]
            },
            productsLoading(state, getters) {
                return getters[`${subOperationProductsDropdownModule}/loading`]
            },
            noVehicles(state, getters) {
                return getters[`${subOperationVehiclesDropdownModule}/noData`]
            },
            noImplements(state, getters) {
                return getters[`${subOperationImplementsDropdownModule}/noData`]
            },
            noProducts(state, getters) {
                return getters[`${subOperationProductsDropdownModule}/noData`]
            }
        }),
        isFieldsValid() {
            if (
                checkFieldsValidity([
                    this.subOperationName,
                    this.subOperationBillingCost,
                    this.subOperationBillingUnit,
                ])
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
    created() {
        const modules = [
            subOperationVehiclesDropdownModule,
            subOperationImplementsDropdownModule,
            subOperationProductsDropdownModule
        ];
        modules.forEach(async (module) => await this.$store.registerModule(module, PaginationModule));
    },
    mounted() {
        this.getSubOperation();
        this.checkSelectionKeydown([
            this.$refs.vehicleFieldEditRef,
            this.$refs.implementFieldEditRef,
            this.$refs.productFieldEditRef,
        ]);
    },
    beforeDestroy() {
        const modules = [
            subOperationVehiclesDropdownModule,
            subOperationImplementsDropdownModule,
            subOperationProductsDropdownModule
        ];
        modules.forEach(async (module) => await this.$store.unregisterModule(module));
        this.clearFilterOptions();
    },
    methods: {
        ...mapMutations({
            setClearSubOperationDetails: "setClearSubOperationDetails",
            clearFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),
        ...mapMutations({
            resetVehiclesDropdownPaging(commit, payload) {
                commit(`${subOperationVehiclesDropdownModule}/resetPaging`)
            },
            resetImplementsDropdownPaging(commit, payload) {
                commit(`${subOperationImplementsDropdownModule}/resetPaging`)
            },
            resetProductsDropdownPaging(commit, payload) {
                commit(`${subOperationProductsDropdownModule}/resetPaging`)
            }
        }),
        checkSelectionKeydown(refFieldsList: Array<any>) {
            if(Array.isArray(refFieldsList) && refFieldsList.length) {
                refFieldsList.map((refField) => {
                    if(refField) {
                        let originalOnKeyDown = refField.onKeyDown;
                        refField.onKeyDown = (e) =>
                            e.keyCode === 13 ? void 0 : originalOnKeyDown(e);
                    }
                });
            }
        },
        saveSubOperation() {
            let subOprObj = {
                title: this.subOperationName,
                description: this.subOperationDescription,
                billingCost: this.subOperationBillingCost,
                billingUnit: this.subOperationBillingUnit,
                isTaxable: this.subOperationIsTaxable,
                taxRateInPercentage: this.subOperationTaxPercentage,
                typeId: this.subOperationTypeId,
                isHealthSafetyListMandatory:
                    this.subOperationIsHealthSafetyListMandatory,
                healthSafetyList: this.subOperationCheckListItems,
                loadInformationList: this.subOperationLoadInfoList,
                vehicles: this.subOperationVehicles.map((vehicle) => {
                    if (typeof vehicle === "object" && vehicle !== null) {
                        return {
                            id: vehicle.id,
                        };
                    } else {
                        return {
                            id: vehicle,
                        };
                    }
                }),
                implements: this.subOperationImplements.map((implement) => {
                    if (typeof implement === "object" && implement !== null) {
                        return {
                            id: implement.id,
                        };
                    } else {
                        return {
                            id: implement,
                        };
                    }
                }),
                products: this.subOperationProducts.map((product) => {
                    if (typeof product === "object" && product !== null) {
                        return {
                            id: product.id,
                        };
                    } else {
                        return {
                            id: product,
                        };
                    }
                }),
            };
            this.$store
                .dispatch("updateSubOperation", [
                    this.selectedSubOperationId,
                    subOprObj,
                ])
                .then(() => {
                    this.closeEditSubOperationsForm();
                    this.operationTypesList = [];
                    this.$store.dispatch("getOperationTypes");
                });
        },
        showAddLoadInfo() {
            this.loadInfoName = "";
            this.loadInfoUnit = "";
            this.showAddLoadInfoFields = true;
            this.showAddCheckListItemFields = false;
            this.showAddLoadInfoBtn = false;
            this.showAddCheckListItemBtn = true;
        },
        showAddCheckListItem() {
            this.checkListItemName = "";
            this.showAddCheckListItemFields = true;
            this.showAddLoadInfoFields = false;
            this.showAddCheckListItemBtn = false;
            this.showAddLoadInfoBtn = true;
            this.isOpenHealthEditBtn = false;
        },
        addNewLoadInfo() {
            if (this.loadInfoName.length != 0 && this.loadInfoUnit.length != 0) {
                this.subOperationLoadInfoList.push({
                    title: this.loadInfoName,
                    unit: this.loadInfoUnit,
                });
                this.loadInfoName = "";
                this.loadInfoUnit = "";
            }
            this.hideAddOperation();
        },
        addNewCheckListItem() {
            if (this.checkListItemName.length != 0) {
                this.subOperationCheckListItems.push({
                    title: this.checkListItemName,
                });
                this.checkListItemName = "";
            }
            this.hideAddCheckListItem();
            this.isOpenHealthEditBtn = true;
        },
        hideAddOperation() {
            this.showAddLoadInfoFields = false;
            this.showAddLoadInfoBtn = true;
        },
        hideAddCheckListItem() {
            this.showAddCheckListItemFields = false;
            this.showAddCheckListItemBtn = true;
            this.isOpenHealthEditBtn = true;
        },
        removeSubOperationLoadInfo(loadInfoIndex: number) {
            if (loadInfoIndex > -1) {
                this.subOperationLoadInfoList.splice(loadInfoIndex, 1);
            }
        },
        removeSubOperationCheckListItem(checkListIndex: number) {
            if (checkListIndex > -1) {
                this.subOperationCheckListItems.splice(checkListIndex, 1);
            }
        },
        searchSubOperationVehicles() {
            this.clearFilterOptions();
            this.clearDropdownPaginationAndListData(ResourceType.Vehicle);
            this.getSubOperationVehiclesListAsync();
        },
        searchSubOperationImplements() {
            this.clearFilterOptions();
            this.clearDropdownPaginationAndListData(ResourceType.Implement);
            this.getSubOperationImplementsListAsync();
        },
        searchSubOperationProducts() {
            this.clearFilterOptions();
            this.clearDropdownPaginationAndListData(ResourceType.Product);
            this.getSubOperationProductsListAsync();
        },
        closeEditSubOperationsForm: function () {
            this.setClearSubOperationDetails();
            closeOverlay("editSubOperationFormOverlay");
            this.$emit('noActiveFlyout');
        },
        negativeKeydown(e) {
            if (!/[^-+]/.test(e.key)) {
                e.preventDefault();
            }
        },
        async getSubOperationVehiclesListAsync() {
            this.$store.dispatch("setResourcesFilterOptions", {resourceType: ResourceType.Vehicle})
            this.$store.commit(`${subOperationVehiclesDropdownModule}/fetchingData`);
            this.$store.dispatch("getSubOperationVehiclesList")
            .then(response => {
                this.$store.dispatch(`${subOperationVehiclesDropdownModule}/updateStates`, response)
                if (this.subOperationVehiclesSearchText == "") {
                    this.clearFilterOptions();
                }
            });
        },
        async getSubOperationImplementsListAsync() {
            this.$store.dispatch("setResourcesFilterOptions", {resourceType: ResourceType.Implement})
            this.$store.commit(`${subOperationImplementsDropdownModule}/fetchingData`);
            this.$store.dispatch("getSubOperationImplementsList")
            .then(response => {
                this.$store.dispatch(`${subOperationImplementsDropdownModule}/updateStates`, response)
                if (this.subOperationImplementsSearchText == "") {
                    this.clearFilterOptions();
                }
            });
        },
        async getSubOperationProductsListAsync() {
            this.$store.dispatch("setResourcesFilterOptions", {resourceType: ResourceType.Product})
            this.$store.commit(`${subOperationProductsDropdownModule}/fetchingData`);
            this.$store.dispatch("getSubOperationProductsList")
            .then(response => { 
                this.$store.dispatch(`${subOperationProductsDropdownModule}/updateStates`, response)
                if (this.subOperationProductsSearchText == "") {
                    this.clearFilterOptions();
                }
            });
        },
        async getSubOperation() {
            await this.$store.dispatch("getSubOperation", this.selectedSubOperationId);
        },
        handleDropdownBlur(event, resourceType: ResourceType) {
            const blurTargetOutsideDropdown = !event.relatedTarget || event.relatedTarget?.getAttribute('role') !== 'listbox';
            if (blurTargetOutsideDropdown) {
                this.clearDropdownPaginationAndListData(resourceType);
                this.subOperationVehiclesSearchText = null;
                this.subOperationImplementsSearchText = null;
                this.subOperationProductsSearchText = null;
            }
            this.clearFilterOptions();
        },
        clearDropdownPaginationAndListData(resourceType: ResourceType) {  
            switch(resourceType) {
                case ResourceType.Vehicle:
                    this.resetVehiclesDropdownPaging();
                    this.subOperationVehiclesList = this.subOperationVehiclesList.filter(vehicle => this.subOperationVehicles.includes(vehicle.id));
                    break;
                case ResourceType.Implement:
                    this.resetImplementsDropdownPaging();
                    this.subOperationImplementsList = this.subOperationImplementsList.filter(implement => this.subOperationImplements.includes(implement.id));
                    break;
                case ResourceType.Product:
                    this.resetProductsDropdownPaging();
                    this.subOperationProductsList = this.subOperationProductsList.filter(product => this.subOperationProducts.includes(product.id));
                    break;
            } 
        },
        refocusAndAppendMoreResources(resourceType: ResourceType, ref: any) {
            ref.focus();
            this.appendMoreResources(resourceType);
        }
    },
};
