import { formFieldPb } from '@/utils/uiconstants';
import { validateRegexAlphaNumeric, validateRegexAlpha } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties = {
    'usersModule': [
        'accountTabTexts',
        'userProfile',
        'usersLoader',
        'accountBusinessProfile',
        'accountMapSearchValue',
        'vehiclesListForUserProfile',
        'vehiclesForUserProfileSearchText',
        'implementsListForUserProfile',
        'implementsForUserProfileSearchText',
        'startingLat',
        'startingLng',
        'accountFarmAddresses'
    ],
    'root': [
        'areaMeasurementUnitsList',
        'globalCurrenciesList',
        'globalTaxesNameList',
        'accountTab',
        'isRoutedToAccountForTaxUpdate',
        'globalCountriesList',
        'globalTimeZonesList'
    ]
}

export default {
    name: 'accountComputedMixin',
    data() {
        return {
            formFieldPb,
        }
    },
    computed: {
        ...getComputedProperties(computedProperties),
        businessProfilePrefix: {
            get() {
                return this.$store.getters.accountBusinessProfile.shortTitle;
            },
            set(newVal) {
                this.$store.state.usersModule.accountBusinessProfile.shortTitle = newVal;
            }
        },
        userRole: {
            get() {
                return localStorage.getItem('userRole');
            }
        },
    },
    methods: {
        updatePrefix(event: any) {
            const shortenedBusinessName: string = this.getValidatedString(event.currentTarget.value).substring(0, ConstantValues.defaultBusinessProfilePrefixMinLength);

            if (shortenedBusinessName.length == ConstantValues.defaultBusinessProfilePrefixMinLength &&
                (this.accountBusinessProfile == null ||
                    (this.accountBusinessProfile && (this.accountBusinessProfile.shortTitle == null || this.accountBusinessProfile.shortTitle.length == 0)))) {
                this.businessProfilePrefix = shortenedBusinessName;
            }
        },
        getValidatedString(rawString: string) {
            return rawString.toString().replace(/\s+/g, '').replace(/[0-9]/g, '').replace(/\./g, '').toUpperCase();
        },
        checkKeyDownAlpha(event) {
            validateRegexAlpha(event);
        },
        checkKeyDownAlphaNumeric(event) {
            validateRegexAlphaNumeric(event);
        },
    }
}