















import $ from 'jquery';
import ProductsListView from './products-list.vue';
import ProductEditForm from './products-edit-form.vue';
import ProductsImportForm from './products-import.vue';
import { closeOverlay } from '@/utils/helpers';
import requestsCancellationMixin from '@/mixins/requests-cancellation';

export default {
    components: {
        ProductsListView,
        ProductEditForm,
        ProductsImportForm
    },
    mixins: [requestsCancellationMixin],
    mounted() {
        this.$store.state.dashboardTitle = this.$stringConstants('productsTitle');
        $('body').on('click', '#overlay', function () {
            closeOverlay("editProductFormOverlay");
            closeOverlay("importProductsFormOverlay");
        });
    }
}
