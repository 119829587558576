import buildUrl from "build-url";
import ConstantValues from '@/plugins/constantValues';
import requests from '@/utils/requests';
import { apiAdminOverallOverview, apiAdminRecentOverview } from "@/utils/endpoints";
import moment from "moment";

export const actions = {
    async getRecentStatistics({ commit, rootState, dispatch, state }) {
        dispatch("jsonToQueryString", rootState.filterOptions);

        const url = buildUrl(rootState.baseUrl, {
            path: apiAdminRecentOverview + rootState.tempUrl
        });

        state.adminRecentOverviewLoader = true

        const result = await requests.getData(url)
        requests.handleSuperAdminForbiddenRequest(result)

        state.adminRecentOverviewLoader = false
        
        if(result) {
            commit('setRecentStatistics', result?.data)
        }
        return result;
    },
    async setRecentStatisticsFilter({ commit, state }, data) {
        if(state.adminOverviewRecentStatisticsStartDate && state.adminOverviewRecentStatisticsEndDate) {
            commit('setFilterOptions', ['Start', moment(state.adminOverviewRecentStatisticsStartDate).utc().format()]);
            commit('setFilterOptions', ['End', moment(state.adminOverviewRecentStatisticsEndDate).utc().format()]);
        } else {
            const startDate = moment().subtract(7,'d').utc().format();
            const endDate =  moment().utc().format();

            commit('setFilterOptions', ['Start', startDate]);
            commit('setFilterOptions', ['End', endDate]);
        }
    },
    async getOverallStatistics({commit, rootState, state}) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiAdminOverallOverview + rootState.tempUrl
        })

        state.adminOverallOverviewLoader = true

        const result = await requests.getData(url)
        requests.handleSuperAdminForbiddenRequest(result)

        state.adminOverallOverviewLoader = false

        if(result) {
            commit('setOverallStatistics', result?.data)
        }
        return result
    },
}