import { User } from './User';

export class TeamJobMapFieldDto {
    id?: string;
    createdOn?: Date;
    title: string;
    areaInSquareMeter: number;
    colour: string;
    polygon: any;
    metaData: [];
    owner?: User;
    ownerId: string;
    
    constructor(field?: TeamJobMapFieldDto) {
        this.id = field.id;
        this.createdOn = field.createdOn ? field.createdOn : null;
        this.title = field.title;
        this.areaInSquareMeter = field.areaInSquareMeter;
        this.colour = field.colour;
        this.polygon = field.polygon && typeof field.polygon === 'string' ? JSON.parse(field.polygon) : field.polygon;
        this.metaData = field.metaData ? field.metaData : [];
        this.owner = field.owner ? field.owner : null;
        this.ownerId = field.ownerId ? field.ownerId : null;
        return this;
    }
}