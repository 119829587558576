export class Currency {
    id: string;
    name: string;
    symbol: string;
    fractionUnitConversion: number;

    public setCurrency(currency: Currency){
        this.id = currency.id;
        this.name = currency.name;
        this.symbol = currency.symbol;
        this.fractionUnitConversion = currency.fractionUnitConversion;
        return this;
    }
}