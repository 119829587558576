export enum SubscriptionStatusType {
    Trialing = 0,
    Active = 1,
    Pending = 2,
    Cancelled = 3,
}

export const SubscriptionStatusTypeLabel = new Map<number, string>([
    [SubscriptionStatusType.Trialing, 'Trialing'],
    [SubscriptionStatusType.Active, 'Active'],
    [SubscriptionStatusType.Pending, 'Pending'],
    [SubscriptionStatusType.Cancelled, 'Cancelled']
]);