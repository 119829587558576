import { ITeamJobProduct } from '../interfaces/ITeamJobProduct';
import { TeamJobProductUsage } from '@/data/models/TeamJobProductUsage';

export class TeamJobProduct implements ITeamJobProduct {

    id?: string;
    title: string;
    cost: number;
    unit: string;
    usages?: TeamJobProductUsage[] = new Array<TeamJobProductUsage>();

    public getTeamJobProduct(teamJobProduct: TeamJobProduct) {
        this.id = teamJobProduct.id ? teamJobProduct.id : null;
        this.title = teamJobProduct.title ? teamJobProduct.title : null;
        this.cost = teamJobProduct.cost ? teamJobProduct.cost : null;
        this.unit = teamJobProduct.unit ? teamJobProduct.unit : null;
        if(teamJobProduct.usages &&  Array.isArray(teamJobProduct.usages)) {
            this.usages = teamJobProduct.usages.length == 0 ? 
                          [new TeamJobProductUsage()] : 
                          teamJobProduct.usages.map(usage => new TeamJobProductUsage().getTeamJobProductUsage(usage))
        } else {
            this.usages = [new TeamJobProductUsage()];
        }
        return this;
    }

    public getTeamJobProductRequest(teamJobProduct: TeamJobProduct) {
        this.id = teamJobProduct.id ? teamJobProduct.id : null;
        if(teamJobProduct.usages &&  Array.isArray(teamJobProduct.usages)) {
            this.usages = teamJobProduct.usages.length == 0 ? 
                          [new TeamJobProductUsage()] : 
                          teamJobProduct.usages.map(usage => new TeamJobProductUsage().getTeamJobProductUsage(usage))
        } else {
            this.usages = [new TeamJobProductUsage()];
        }
        return this;
    }
}