import { mapState } from "vuex";
import { ResourceType } from "@/enum/resourceType";
import { subOperationImplementsDropdownModule, subOperationProductsDropdownModule, subOperationVehiclesDropdownModule } from "@/utils/strings";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
import { routeAccount, routeInvoicesAdd } from "@/utils/endpoints";
import router from "@/router";
import { AccountTabType } from "@/enum/accountTabType";
import ConstantValues from '@/plugins/constantValues';
import { getLoggedInUserRole } from "@/utils/helpers/user-role-helpers";

const computedProperties: Record<string, Array<string>> = {
    'operationsModule': [
        'subOperationVehiclesList',
        'editSubOperationLoader',
        'subOperationImplementsList',
        'subOperationProductsList',
        'subOperationTypeId',
        'showAddLoadInfoBtn',
        'showAddCheckListItemBtn',
        'showAddCheckListItemFields',
        'showAddLoadInfoFields',
        'selectedSubOperationName',
        'selectedSubOperationId',
        'subOperationName',
        'subOperationBillingCost',
        'subOperationBillingUnit',
        'subOperationTaxPercentage',
        'subOperationIsTaxable',
        'subOperationVehicles',
        'subOperationImplements',
        'subOperationProducts',
        'subOperationLoadInfoList',
        'subOperationDescription',
        'subOperationCheckListItems',
        'subOperationVehiclesSearchText',
        'subOperationImplementsSearchText',
        'subOperationProductsSearchText',
        'subOperationIsHealthSafetyListMandatory',
        'subOperationLoader',
        'operationTypesList',
        'addOperationTypesList',
        'requestOperationTypesList',
        'showAddOperationFields',
        'showAddOperationBtn',
        'operationsLoader',
        'operationSearchText',
        'addSubOperationLoader',
        'subOperationVehiclesListSize',
        'subOperationImplementsListSize',
        'subOperationProductsListSize',
        'subOperationVehiclesDropdownLoader',
        'subOperationImplementsDropdownLoader',
        'subOperationProductsDropdownLoader',
        'subOperationTaxWarningVisible',
    ],
    'root': [
        'enumListLoadInfoUnits',
        'enumListOperationUnits',
        'noData',
        'filtersApplied',
        'isPageRelated',
        'accountTab',
        'isRoutedToAccountForTaxUpdate',
        'loginUserRole',
    ]
}

export default {
    name: 'OperationsComputedMixin',
    created() {
        this.$options.ResourceType = ResourceType;
    },
    methods: {
        appendMoreResources(resourceType: ResourceType) {
            switch(resourceType) {
                case ResourceType.Vehicle: {
                    this.$store.dispatch('setResourcesFilterOptions', {resourceType: ResourceType.Vehicle, paginationOffset: this.vehiclesDropdownOffset}).then(() => {
                        this.$store.dispatch('getSubOperationVehiclesList').then(response => {
                            this.$store.dispatch(`${subOperationVehiclesDropdownModule}/updateStates`, response);
                        });
                    });
                    break;
                }
                case ResourceType.Implement: {
                    this.$store.dispatch('setResourcesFilterOptions', {resourceType: ResourceType.Implement, paginationOffset: this.implementsDropdownOffset}).then(() => {
                        this.$store.dispatch('getSubOperationImplementsList').then(response => {
                            this.$store.dispatch(`${subOperationImplementsDropdownModule}/updateStates`, response);
                        });
                    });
                    break;
                }
                case ResourceType.Product: {
                    this.$store.dispatch('setResourcesFilterOptions', {resourceType: ResourceType.Product, paginationOffset: this.productsDropdownOffset}).then(() => {
                        this.$store.dispatch('getSubOperationProductsList').then(response => {
                            this.$store.dispatch(`${subOperationProductsDropdownModule}/updateStates`, response);
                        });
                    });
                    break;
                }
                default: return;
            }
        },
        getRateNameAndValue(rateName: string, rateInPercentage: number, currentTaxValue: number) {
            if(rateName) 
                return `${rateName} - ${rateInPercentage}`
            return `Default - ${currentTaxValue}`
        },
        checkTaxList() {
            this.$store.dispatch("getInvoiceConfiguration").then((res) => {
                if(res?.businessProfile?.config?.salesTaxRates?.length === 0) {
                    this.subOperationIsTaxable = false;
                    this.subOperationTaxWarningVisible = true;
                }
            });
        },
        routeToUpdateTax() {
            this.subOperationTaxWarningVisible = false;
            this.isRoutedToAccountForTaxUpdate = true;

            router.push(routeAccount).then(() => {
                this.accountTab = AccountTabType.Business;
                setTimeout(() => {
                    document.getElementById('account-business')?.scrollBy({top: 700, behavior: 'smooth'})
                }, ConstantValues.defaultScrollToPositionTimeout)
            })
        },
        cancelTaxSetup() {
            this.subOperationTaxWarningVisible = false;
        }
    },
    computed: {
        ...mapState({
            vehiclesDropdownOffset(state, getters) {
                return state[`${subOperationVehiclesDropdownModule}`].totalResponseRowCount
            },
            implementsDropdownOffset(state, getters) {
                return state[`${subOperationImplementsDropdownModule}`].totalResponseRowCount
            },
            productsDropdownOffset(state, getters) {
                return state[`${subOperationProductsDropdownModule}`].totalResponseRowCount
            }
        }),
        ...getComputedProperties(computedProperties),
        taxValuesListForInvoice() {
            return this.$store.getters.invoiceConfiguration.salesTaxRates
        },
        taxName() {
            return this.$store.getters.invoiceConfiguration.salesTaxName
        },
        currencySymbol(){
            return localStorage.getItem("currencySymbol");
        },
        ownerRole: {
            get() {
                return localStorage.getItem("ownerRole");
            }
        },
    }
}