














































































































































































































































import {
    formFieldPb,
    containerLeftPad,
    containerRightPad,
} from "@/utils/uiconstants";
import { IMachine } from "@/data/interfaces/IMachineInterface";
import { checkFieldsValidity, closeOverlay } from "@/utils/helpers";
import { mapMutations } from "vuex";
import MachinesComputedMixin from "@/components/machines/machines-computed";
import SubHeaderPrimary from "@/components/shared/headers/subheader-primary.vue";
import DescriptionPrimary from "@/components/shared/headers/description-primary.vue";
import {
    BaseButton as Button,
    ButtonCloseIcon,
} from "@/components/shared/buttons/index";
import { MachineType } from "@/enum/machineType";

export default {
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
            checkListItemName: "",
            MachineType
        };
    },
    components: {
        SubHeaderPrimary,
        DescriptionPrimary,
        ButtonCloseIcon,
        Button,
    },
    mixins: [MachinesComputedMixin],
    computed: {
        isFieldsValid() {
            const validationProperties = [this.machineDetails.title, this.machineDetails.make, this.machineDetails.model];

            if (this.machineType == MachineType.Vehicle && checkFieldsValidity([...validationProperties, this.machineDetails.registration])) {
                return false;
            }
            else if (this.machineType == MachineType.Implement && checkFieldsValidity([...validationProperties])) {
                return false;
            }
            else {
                return true;
            }
        },
    },
    methods: {
        ...mapMutations({
            resetPaging: "resetPaging",
            setClearFilterOptions: "setClearFilterOptions",
            setClearMachinesList: "setClearMachinesList",
            resetMachineDetails: "resetMachineDetails",
        }),
        negativeKeydown(e) {
            if (!/[^-+]/.test(e.key)) {
                e.preventDefault();
            }
        },
        validateRegexRegistration(event) {
            if (!/[a-zA-Z0-9\s]/.test(event.key)) {
                event.preventDefault();
            }
        },
        addNewCheckListItem() {
            if (this.checkListItemName.length != 0) {
                this.machineDetails.healthSafetyList.push({
                    title: this.checkListItemName,
                });
                this.checkListItemName = "";
            }
            this.hideAddCheckListItem();
        },
        removeVehicleCheckListItem(checkListIndex: number) {
            if (checkListIndex > -1) {
                this.machineDetails.healthSafetyList.splice(checkListIndex, 1);
            }
        },
        showAddCheckListItem() {
            this.checkListItemName = "";
            this.showAddmachineCheckListItemFields = true;
            this.showAddmachineCheckListItemBtn = false;
        },
        hideAddCheckListItem() {
            this.showAddmachineCheckListItemFields = false;
            this.showAddmachineCheckListItemBtn = true;
        },
        updateVehicleData() {
            const vehicleDto: IMachine = {
                id: this.machineDetails.id != null ? this.machineDetails.id.trim() : this.machineDetails.id,
                title: this.machineDetails.title.trim(),
                make: this.machineDetails.make.trim(),
                model: this.machineDetails.model.trim(),
                registration: this.machineDetails.registration != null ? this.machineDetails.registration.trim() : this.machineDetails.registration,
                width:
                    this.machineDetails.width != null && this.machineDetails.width != ""
                        ? this.machineDetails.width
                        : 0,
                length:
                    this.machineDetails.length != null && this.machineDetails.length != ""
                        ? this.machineDetails.length
                        : 0,
                weight:
                    this.machineDetails.weight != null && this.machineDetails.weight != ""
                        ? this.machineDetails.weight
                        : 0,
                ownership: this.machineDetails.ownership,
                healthSafetyList: this.machineDetails.healthSafetyList,
                isHealthSafetyListMandatory:
                    this.machineDetails.isHealthSafetyListMandatory,
            };
            
             if (this.machineDetails.id == null) {
                this.$store.dispatch("addMachine", vehicleDto);
            }
            else {
                this.$store.dispatch("updateMachine", [vehicleDto, this.machineDetails.id]);
            }
        },
        closeEditVehiclesForm() {
            this.resetMachineDetails();
            closeOverlay("editMachineFormOverlay");
        },
        clearCurrentChecklist() {
            this.checkListItemName = "";
            this.hideAddCheckListItem();
        },
    },
};
