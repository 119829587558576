























































import { mapMutations } from 'vuex';
import router from '@/router/index';
import moment from 'moment';
import { routeJobs, apiJobs } from '@/utils/endpoints';
import userComputedMixin from '@/components/users/users-computed';

export default {
    computed: {
        inviteeCode: {
            get() {
                return window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
            }
        },
    },
    mixins: [userComputedMixin],
    mounted() {
        this.$store.state.dashboardTitle = 'Inviter profile';
        this.setClearUserProfile();
        this.$store.dispatch('getUserProfile').then(() => {
            this.$store.dispatch('getInviteeProfile', this.inviteeCode);
        });
        this.inviteeProfile.code = this.inviteeCode;
    },
    methods: {
        ...mapMutations({
            setClearUserProfile: 'setClearUserProfile',
        }),
        acceptInvite() {
            this.$store.dispatch('acceptUserInvite', this.inviteeProfile.code).then(() => {
                router.push(apiJobs);
            });
        },
        declineInvitation() {
            this.$store.dispatch('declineUserInvite', this.inviteeProfile.code).then(res => {
                if(res){
                    router.push(routeJobs);
                }
            });
        },
        getLocalDataTime(dateTime: any) {
            let localDateTime = moment.utc(dateTime).local().format("MMM D, YYYY h:mm A");
            return localDateTime;
        },
        getBusinessInfo(businessProfile: any){
            return (businessProfile && businessProfile.title) ? businessProfile.title : '' 
        },
        routeToJobs() {
            router.push(routeJobs)
        }
    }
}
