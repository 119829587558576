import { Address } from "@/data/models/Address";

export const state = {
    farmersFilterList: [],
    pendingFarmersList: [],
    farmersLoader: false,
    farmerSearchText: '',
    farmerFilterType: 0,
    farmerType: null,
    addFarmerLoader: false,
    farmerDetails: {
        id: null,
        name: null,
        customer: null,
        emailAddress: null,
        companyName: null,
        address: new Address(),
        phoneNumber: null
    },
    farmerConReqList: [],
    farmerSendReqList: [],
    isShowConReq: true,
    updateFormEnabled: false,
    integrationContactSearchText: '',
    importIntegratedCustomerLoader: false,
    customerLoaderImport: true,
    fetchingintergrationDataProgressCustomer: false,
    contactsIntegration: [],
    loadedContactsCustomer: 0,
    intergrationHasDataCustomer: false,
    allIntegrationCustomersLoaded: false,
    tempIntegratedCustomers : [],
    integratedCustomerImportInProgress: false,
    farmerStatus: 0,
}

export const getters = {
    farmersLoader: state => state.farmersLoader,
    farmersFilterList: state => state.farmersFilterList,
    pendingFarmersList: state => state.pendingFarmersList,
    farmerSearchText: state => state.farmerSearchText,
    farmerDetails: state => state.farmerDetails,
    farmerFilterType: state => state.farmerFilterType,
    addFarmerLoader: state => state.addFarmerLoader,
    farmerType: state => state.farmerType,
    farmerConReqList: state => state.farmerConReqList,
    farmerSendReqList: state => state.farmerSendReqList,
    isShowConReq: state => state.isShowConReq,
    updateFormEnabled: state => state.updateFormEnabled,
    integrationContactSearchText: state => state.integrationContactSearchText,
    importIntegratedCustomerLoader: state => state.importIntegratedCustomerLoader,
    customerLoaderImport: state => state.customerLoaderImport,
    fetchingintergrationDataProgressCustomer: state => state.fetchingintergrationDataProgressCustomer,
    contactsIntegration: state => state.contactsIntegration,
    loadedContactsCustomer: state => state.loadedContactsCustomer,
    intergrationHasDataCustomer: state => state.intergrationHasDataCustomer,
    allIntegrationCustomersLoaded: state => state.allIntegrationCustomersLoaded,
    tempIntegratedCustomers: state => state.tempIntegratedCustomers,
    integratedCustomerImportInProgress: state => state.integratedCustomerImportInProgress,
    farmerStatus: state => state.farmerStatus,
}

export const mutations = {
    setFarmersFilteredList(state, data) {
        data.map((data) => state.farmersFilterList.push(data));
    },
    setPendingFarmersFilteredList(state, data) {
        data.map((x) => {
            const farmer = {
                "code": x.code,
                "inviterName": x.inviterName,
                "inviteeFirstName": x.inviteeFirstName,
                "inviteeLastName": x.inviteeLastName,
                "inviteeEmail": x.inviteeEmail,
                "inviteeRole": x.inviteeRole,
                "timestamp": x.timestamp,
                "expiry": x.expiry
            }
            state.pendingFarmersList.push(farmer);
        });
    },
    
    resetFarmerDetails(state) {
        state.farmerDetails.id = null;
        state.farmerDetails.name = null;
        state.farmerDetails.customer = null;
        state.farmerDetails.emailAddress = null;
        state.farmerDetails.companyName = null;
        state.farmerDetails.phoneNumber = null;
        state.farmerDetails.address = new Address();
    },
    setFarmerDetails(state, data) {
        state.farmerDetails['id'] = data.id;
        state.farmerDetails['name'] = data.name;
        state.farmerDetails['customer'] = data.customer;
        state.farmerDetails['emailAddress'] = data.emailAddress;
        state.farmerDetails['companyName'] = data.companyName;
        state.farmerDetails['phoneNumber'] = data.phoneNumber;
        if (data.address != null) {
            state.farmerDetails['address'] = data.address;
        }
    },
    setFarmerConReqList(state, data) {
        state.farmerConReqList = data;
    },
    setFarmerSendReqList(state, data) {
        state.farmerSendReqList = data;
    },
    setIntegrationContacts(state, contacts) {
        contacts.map((contactDto) => {
            const contact = {
                "customer": contactDto.customer,
                "name": contactDto.name,
                "emailAddress": contactDto.emailAddress,
                "companyName": contactDto.companyName,
                "phoneNumber": contactDto.phoneNumber,
                "address": contactDto.address != null ? {
                    "id": contactDto.address.id != null ? contactDto.address.id : "",
                    "type": contactDto.address.type != null ? contactDto.address.type : "",
                    "title": contactDto.address.title != null ? contactDto.address.title : "",
                    "addressLine1": contactDto.address.addressLine1 != null ? contactDto.address.addressLine1 : "",
                    "addressLine2": contactDto.address.addressLine2 != null ? contactDto.address.addressLine2 : "",
                    "city": contactDto.address.city != null ? contactDto.address.city : "",
                    "country": contactDto.address.country != null ? contactDto.address.country : "",
                    "postalCode": contactDto.address.postalCode != null ? contactDto.address.postalCode : "",
                    "coordinate": contactDto.address.coordinate
                } : contactDto.address,
                "id": contactDto.id != null ? contactDto.id : ""
            };
            state.contactsIntegration.push(contact);
        });
    },
    updateProfileInFarmersList(state, profile) {
        const profileIndex = state.farmersFilterList.findIndex(farmer => farmer.id === profile.id);
        if (profileIndex > -1) {
            state.farmersFilterList.splice(profileIndex, 1, profile);
        } else {
            state.farmersFilterList.push(profile);
        }
    },
    clearFarmersList(state) {
        state.farmersFilterList = [];
    }
}