import { listContainsItem } from "@/utils/helpers";

export const state = {
    contacts: [],
    account: {
        "type": null,
        "accountId": null,
        "accountName": null,
        "accessToken": null,
        "accessTokenExpiry": null,
        "refreshToken": null,
        "refreshTokenExpiry": null,
        "connectedOn": null,
    },
    contactSearchText: '',
    customerLoader: true,
    integrationType: null,
    integrationsAuthenticationInProgress: false,

    //integration services
    quickbookAccessState: 0,
    quickBookAccess: { 0: "NoAction", 1: "NoQuickbookAccess", 2: "QuickbookAccess" },
    xeroAccessState: 0,
    xeroAccess: { 0: "NoAction", 1: "NoXeroAccess", 2: "XeroAccess" },
    sageAccessState: 0,
    sageAccess: { 0: "NoAction", 1: "NoSageAccess", 2: "SageAccess" },

    //customer list
    intergrationHasData: false,
    fetchingintergrationDataProgress: false,
    loadingIntegration: false,
    externalContactIdToLink: null,
    enumImportStatus: {
        0: "Completed",
        1: "Pending",
        2: "Failed",
        3: "Declined"
    },
    loadedContacts: 0,
    
    allCustomersLoaded: false,
    loadingIntegrationBuffer: false,
    tempIntegratedContacts: [],
    showIntegrationPrompt: false,
}

export const getters = {
    contacts: state => state.contacts,
    account: state => state.account,
    contactSearchText: state => state.contactSearchText,
    customerLoader: state => state.customerLoader,
    integrationType: state => state.integrationType,
    integrationsAuthenticationInProgress: state => state.integrationsAuthenticationInProgress,
    quickBookAccess: state => state.quickBookAccess,
    quickbookAccessState: state => state.quickbookAccessState,
    xeroAccessState: state => state.xeroAccessState,
    xeroAccess: state => state.xeroAccess,
    sageAccessState: state => state.sageAccessState,
    sageAccess: state => state.sageAccess,
    fetchingintergrationDataProgress: state => state.fetchingintergrationDataProgress,
    intergrationHasData: state => state.intergrationHasData,
    loadingIntegration: state => state.loadingIntegration,
    externalContactIdToLink: state => state.externalContactIdToLink,
    enumImportStatus: state => state.enumImportStatus,
    loadedContacts: state => state.loadedContacts,
    allCustomersLoaded: state => state.allCustomersLoaded,
    loadingIntegrationBuffer: state => state.loadingIntegrationBuffer,
    tempIntegratedContacts: state => state.tempIntegratedContacts,
    showIntegrationPrompt: state => state.showIntegrationPrompt,
}

export const mutations = {
    setExternalInvoicingServiceContacts(state, contacts) {
        contacts.map((contactDto) => {
            const contact = {
                "customer": contactDto.customer,
                "name": contactDto.name,
                "emailAddress": contactDto.emailAddress,
                "companyName": contactDto.companyName,
                "phoneNumber": contactDto.phoneNumber,
                "address": contactDto.address != null ? {
                    "id": contactDto.address.id != null ? contactDto.address.id : "",
                    "type": contactDto.address.type != null ? contactDto.address.type : "",
                    "title": contactDto.address.title != null ? contactDto.address.title : "",
                    "addressLine1": contactDto.address.addressLine1 != null ? contactDto.address.addressLine1 : "",
                    "addressLine2": contactDto.address.addressLine2 != null ? contactDto.address.addressLine2 : "",
                    "city": contactDto.address.city != null ? contactDto.address.city : "",
                    "country": contactDto.address.country != null ? contactDto.address.country : "",
                    "postalCode": contactDto.address.postalCode != null ? contactDto.address.postalCode : "",
                    "coordinate": contactDto.address.coordinate
                } : contactDto.address,
                "id": contactDto.id != null ? contactDto.id : ""
            };
            if(state.contacts && state.contacts.length) {
                if(!listContainsItem(state.contacts, ['id'], contact.id)) {
                    state.contacts.push(contact);
                }
            } else {
                state.contacts.push(contact);
            }
        });
    },

    setIntegrationAccount(state, data) {
        state.account = {
            "type": data.type,
            "accountId": data.accountId,
            "accountName": data.accountName,
            "accessToken": data.accessToken,
            "accessTokenExpiry": data.accessTokenExpiry,
            "refreshToken": data.refreshToken,
            "refreshTokenExpiry": data.refreshTokenExpiry,
            "connectedOn": data.connectedOn,
        }
    },

    setClearIntegrations(state) {
        state.contacts = [];
        state.account = {
            "type": null,
            "accountId": null,
            "accountName": null,
            "accessToken": null,
            "accessTokenExpiry": null,
            "refreshToken": null,
            "refreshTokenExpiry": null,
            "connectedOn": null,
        };
        state.quickbookAccessState = 0;
        state.xeroAccessState = 0;
        state.sageAccessState = 0;
        state.loadingIntegration = false;
    },

}