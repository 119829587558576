export default {
    name: 'TeamJobsCompletionInformationComputedMixin',
    computed: {
        teamJobNotes: {
            get() {
                return this.$store.getters.teamJobNotes;
            },
            set(newVal) {
                this.$store.state.teamJobsModule.teamJobNotes = newVal;
            }
        },
    }
}