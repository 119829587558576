import { Location } from './Location';

export class TeamJobOperatorLocation {
    start: string;
    end: string;
    purpose: number;
    locations: Location[] = new Array<Location>();
    operatorId: string;

    public getTeamJobOperatorLocation?(operatorlocation: TeamJobOperatorLocation) {
        this.start = operatorlocation.start;
        this.end = operatorlocation.end;
        this.purpose = operatorlocation.purpose;
        this.locations = operatorlocation.locations ? operatorlocation.locations.map(loc => new Location().getLocation(loc)) : [];
        this.operatorId = operatorlocation.operatorId;
        return this;
    }
}
