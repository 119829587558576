import { SubscriptionStatusTypeLabel } from "@/enum/subscriptionStatusType"
import { getDateDifferenceInDays, getDateDifferenceInMinutes, getDateTimeFormattedLocal } from "../helpers"
import { cancellationScheduledOnText, cancelledOnText } from "../strings"

export const getSusbcriptionAmountIfExists = (business, showSymbol) => {
    if(business.subscriptions && business.subscriptions.invoices.length > 0 && business.subscriptions.invoices[0].amount) {
        return (showSymbol ? localStorage.getItem("currencySymbol") : '') 
                + business.subscriptions.invoices[0].amount
    }
    return ''
}

export const getTrialDaysLeft = trialEndDate => {
    const differenceInDays = parseInt(getDateDifferenceInDays(trialEndDate))
        
    const remainingTrialInMinutes =  parseInt(getDateDifferenceInMinutes(trialEndDate))
    const isTrialExpired = remainingTrialInMinutes <= 0
    const trialingText = 'Trialing ('

    if(isTrialExpired) {
        return 'Trial expired'
    }
    
    if(differenceInDays > 0) {
        return trialingText + differenceInDays + ' days left)'
    } 

    if(differenceInDays == 0) {
        return trialingText + 'Expiring today' + ')'
    }
}

export const getSubscriptionStatusEnum = status => {
    return status !== null ? SubscriptionStatusTypeLabel.get(status) : '-'
}

export const getCancellationDate = subscriptions => {
    if(subscriptions && subscriptions.cancellationScheduledOn) {
        return getCancellationScheduledText(subscriptions)
    }
    return ''
}

export const getCancellationScheduledText = subsciption => {
    if(parseInt(getDateDifferenceInMinutes(subsciption.cancellationScheduledOn)) > 0) {
        return cancellationScheduledOnText + getDateTimeFormattedLocal(subsciption.cancellationScheduledOn)
    }
    return cancelledOnText + getDateTimeFormattedLocal(subsciption.cancellationScheduledOn)
}