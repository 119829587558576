import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'suUsersModule': [
        'usersList',
        'usersListStartDate',
        'usersListEndDate',
        'usersFiltersApplied',
        'usersListRoles',
        'usersSearchText',
    ],
    'excelExportModule': [
        'exportData',
        'exportDataLength',
        'exportDataLoaded',
        'exportDataTotal',
    ],
    'root': [
        'isRoutedFromReferencePage',
        'listLoading',
        'enumMemberRoles',
        'totalRowCount'
    ],
}

export default {
    name: 'AdminUsersComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}