


























export default {
  name: "ImageViewer",
  props: ["imageUrl"],
  computed: {
    showImagePreview: {
      get() {
        return this.$store.getters.showImagePreview;
      },
      set(newVal) {
        this.$store.state.showImagePreview = newVal;
      },
    },
  },
  methods: {
    openImageInNewTab() {
      window.open(this.imageUrl);
      this.showImagePreview = false;
    }
  }
};
