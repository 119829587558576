













































































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { mapMutations } from 'vuex';
import { getLoggedInUserRole, getOwnerId } from '@/utils/helpers/user-role-helpers';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { TeamJobOperation } from '@/data/models/TeamJobOperation';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import OwnerAvatar from '@/components/shared/avatars/avatar.vue';
import { getListLengthAbsolute, getShortName } from '@/utils/helpers';
import ConstantValues from '@/plugins/constantValues';
import LoadMoreDropdown from "@/components/shared/load-more-jobs-dropdown.vue";

export default {
    name: "TeamJobsOwners",
    mixins: [TeamJobsComputed, TeamJobsCommon],
    components: {
        OwnerAvatar,
        LoadMoreDropdown
    },
    data(){
        return{
            searchTimer: null
        }
    },
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
        }),
        updateSearchText(e) {
            const searchedText = e.target.value;
            if (searchedText.length == 0) {
                this.teamJobUserSearchText = null;
                this.applySearchJobUserTimeout();
            } else {
                this.teamJobUserSearchText = searchedText;
                this.applySearchJobUserTimeout();
        }
        },
        refeatchUserList(){
            this.hasMoreData = true;
            if(this.teamJobUserSearchText != null && this.teamJobUser == null){
                this.teamJobUserSearchText = null;
                this.searchUserForTeamJob();
            }
        },
        applySearchJobUserTimeout() {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                this.searchUserForTeamJob();
            }, ConstantValues.defaultSearchTimeout);
        },
        searchUserForTeamJob() {
            this.teamJobUsersList = [];
            this.clearFilterOptions();
            this.$store.dispatch('setTeamJobCustomerFilters').then(res => {
                this.$store.dispatch('getTeamJobsOwners');
                    if (this.teamJobUserSearchText == '') {
                        this.clearFilterOptions();
                    }
            });
        },
        getShortBusinessName(data){
            return getShortName(data, 60);
        },

        filterResourcesByUser() {
            if (this.teamJobUser == null){
                this.applySearchJobUserTimeout();
            }
            this.teamJobUserSearchText = null;
            if (!(getLoggedInUserRole().isUserOwnerFarmerLoggedIn && getOwnerId() != this.teamJobUser.id)) {
                this.showResources();

            } else {
                this.hideResources();
            }

            if ((getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn)) {
                this.clearFilterOptions();
                this.teamJobOperation = new TeamJobOperation();
                this.teamJobOperators = [];
                this.teamJobProducts = new Array<TeamJobProduct>();
                this.teamJobOperationsList = [];
                this.teamJobOperationOwnerId = this.teamJobUser.id;
                this.$store.dispatch('getTeamJobOperations');
            }
            this.enableTeamJobDiscard();
        },

        appendMoreCustomers() {
            this.clearFilterOptions();
            this.$store.dispatch('setTeamJobCustomerFilters', [
                getListLengthAbsolute().getListLengthWithoutOwner(this.teamJobUsersList),
                ConstantValues.defaultPageLimitSize,
            ]).then(() => {
                this.$store.dispatch('getTeamJobsOwners');
            })
        }
    }
}
