




















































































import { closeOverlay } from '@/utils/helpers';
import moment from 'moment';
import ComponentLoader from '@/components/shared/loaders/component-loader-mini.vue';
import { routeTeamJobsDetails } from '@/utils/endpoints';
import invoicesComputedMixin from '@/components/invoices/invoices-computed';
import { getAssignedUserNameFromJob } from '@/utils/helpers/jobs-helpers';
import { BaseButton as Button } from '@/components/shared/buttons/index';

export default {
    name: 'JobsPreviewFlyout',
    mixins: [invoicesComputedMixin],
    components: {
        ComponentLoader,
        Button
    },
    methods: {
        closeJobsPreviewForm(){
            closeOverlay('invoiceJobsPreviewFlyout');
        },
        getAssignedOperator(job){
            return getAssignedUserNameFromJob(job);
        },
        getLocalDateFormat(date) {
            const formattedDate = moment.utc(date).local().format('DD MMM YYYY');
            return formattedDate;
        },
        routeToJobDetails(jobId: string){
            window.open(`${routeTeamJobsDetails}${jobId}`, '_blank');
        }
    }
}
