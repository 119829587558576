




















































































































import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import { closeOverlay, getListLengthAbsolute, notify } from "@/utils/helpers";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { UserRoleType } from '@/enum/userRoleType';
import ConstantValues from '@/plugins/constantValues';
import PaginationModule from "@/store/modules/pagination";
import LoadMoreItem from '../shared/load-more-item.vue';

export default {
  components: {
    Button,
    LoadMoreItem,
  },
  data() {
    return {
      containerLeftPad,
      containerRightPad,
      invalidFieldsData: [],
      validFieldsData: [],
      currentFieldCounter: 0,
      allFieldsParsed: false,
      fieldsValidated: 0,
      fieldValidationStarted: false,
      UserRoleType,
    };
  },
  computed: {
    loginUserRole() {
      return this.$store.getters.loginUserRole;
    },
    fieldOwnerListForBulkUpload: {
      get() {
        return this.$store.getters.fieldOwnerListForBulkUpload;
      },
      set(val) {
        this.$store.state.fieldsModule.fieldOwnerListForBulkUpload = val;
      },
    },
    fieldFarmerSearchTextInternal: {
      get() {
        return this.$store.getters.fieldFarmerSearchTextInternal;
      },
      set(val) {
        this.$store.state.fieldsModule.fieldFarmerSearchTextInternal = val;
      },
    },
    bulkUploadOwner: {
      get() {
        return this.$store.getters.bulkUploadOwner;
      },
      set(val) {
        this.$store.state.fieldsModule.bulkUploadOwner = val;
      },
    },
    searchNotify: {
      get() {
        return this.$store.getters.searchNotify;
      },
      set(newVal) {
        this.$store.state.searchNotify = newVal;
      },
    },
    bulkUploadInProgress: {
      get() {
        return this.$store.getters.bulkUploadInProgress;
      },
      set(val) {
        this.$store.state.fieldsModule.bulkUploadInProgress = val;
      },
    },
    compressedFile: {
      get() {
        return this.$store.getters.compressedFile;
      },
      set(val) {
        this.$store.state.fieldsModule.compressedFile = val;
      },
    },
    ownerRole: {
      get() {
        const ownerRole = localStorage.getItem("ownerRole");
        if (ownerRole) {
          return ownerRole;
        } else {
          return null;
        }
      },
    },
    computedAreas: {
      get() {
        return this.$store.getters.computedAreas;
      },
      set(val) {
        this.$store.state.fieldsModule.computedAreas = val;
      },
    },
    loadingDropdown: {
      get() {
        return this.$store.getters[`${this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule')}/loading`]
      }
    }
  },
  mounted() {
    this.$store.dispatch("mountGoogleMapForAreaCalculation", "bulk-upload-map");
  },
  created() {
      if(!this.$store.hasModule(this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule'))) {
        this.$store.registerModule(this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule'), PaginationModule);
      }
  },
  methods: {
    changeSearchEnter: function () {
      this.searchNotify = this.$stringConstants('enterToSearch');
    },
    closeBulkUploadFieldsForm() {
      closeOverlay("fieldsBulkUploadFormOverlay")
    },
    selectFiles(file) {
      if (file) {
        this.compressedFile = file;
      } else {
        this.compressedFile = null;
      }
    },
    uploadShapeFiles() {
      const formData = new FormData();
      formData.append("file", this.compressedFile);

      let bulkUploadRoleType = null;

      const farmersManager = this.loginUserRole == UserRoleType.Manager && this.ownerRole == "Farmer";
      const contractorsManager = this.loginUserRole == UserRoleType.Manager && this.ownerRole == "Contractor";

      if (this.loginUserRole == UserRoleType.Farmer || farmersManager) {
          bulkUploadRoleType = 'bulkUploadFields';
      } else if (this.loginUserRole == UserRoleType.Contractor || contractorsManager) {
          bulkUploadRoleType = 'bulkUploadFieldsContractor'
      }

      this.$store.dispatch(bulkUploadRoleType, formData).then(() => {
        this.compressedFile = null
        this.bulkUploadOwner = null
        this.closeBulkUploadFieldsForm()
      })
    },
    
    resetComputedValues() {
      this.computedAreas = [];
      this.invalidFieldsData = [];
      this.allFieldsParsed = false;
      this.fieldsValidated = 0;
      this.fieldValidationStarted = false;
    },
    async applyBulkUploadCustomersFilterSearchTimer() {
      if (this.searchTimerCustomers) {
          clearTimeout(this.searchTimerCustomers);
          this.searchTimerCustomers = null;
      }
      this.searchTimerCustomers = setTimeout(() => {
          this.applySearchFilters();
      }, ConstantValues.defaultDropdownSearchTimeout); 
    },
    applySearchFilters() {
        this.searchNotify = this.$stringConstants('enterToSearch');
        this.searchFarmerFilter();
    },
    searchFarmerFilter: function () {
        this.fieldOwnerListForBulkUpload = [];
        this.$store.commit("setDefaultOwnerListBulkUpload", this.loginUserBusinessName);

        this.$store.dispatch('setSearchInternalFarmersFilter', {
            offset: ConstantValues.defaultPageOffsetSize,
            limit: ConstantValues.defaultPageLimitSize,
            moduleNamespace: this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule')
        }).then(() => {
            this.getOwnerForBulkUploadListAsync();
        });
    },
    async getOwnerForBulkUploadListAsync() {
      this.$store.dispatch(`${this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule')}/generateQueryString`)
      .then(queryString => {
          this.$store.commit('setDefaultOwnerListBulkUpload', this.loginUserBusinessName);

          this.$store.commit(`${this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule')}/fetchingData`)
          
          this.$store.dispatch('getFieldsOwnerListInternalBulkUpload', { queryString }).then(response => {
              this.$store.dispatch(`${this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule')}/updateStates`, response)
          });
      });
    },
    async resetPagingAndGetBulkUploadOwnerList() {
      this.fieldOwnerListForBulkUpload = [];
      this.$store.commit(`${this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule')}/resetPaging`)

      this.$store.dispatch('setSearchInternalFarmersFilter', {
          offset: getListLengthAbsolute().getListLengthWithoutOwner(this.fieldOwnerListForBulkUpload),
          limit: ConstantValues.defaultPageLimitSize,
          moduleNamespace: this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule')
      }).then(() => {
          this.getOwnerForBulkUploadListAsync();
      });
    },
    appendMoreBulkUploadCustomers() { 
      this.$store.dispatch('setSearchInternalFarmersFilter', {
          offset: getListLengthAbsolute().getListLengthWithoutOwner(this.fieldOwnerListForBulkUpload),
          limit: ConstantValues.defaultPageLimitSize,
          moduleNamespace: this.$stringConstants('fieldsBulkUploadFilterCustomersPaginationModule')
      }).then(() => {
          this.getOwnerForBulkUploadListAsync();
      });
    },
  },
};
