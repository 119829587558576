import ConstantValues from "@/plugins/constantValues";

export default {
    data() {
        return {
            resendCountdown: 0,
            resendLoading: false,
            resendButtonDisabled: false
        }
    },
    computed: {
        isCountdownComplete: {
            get() {
                return this.resendCountdown == 0;
            }
        }
    },
    methods: {
        checkCurrentRequestAndProceed() {
            if (this.sentCurrentRequest) {
                this.resetCountdown();
                this.handleCountdown();
            }
        },
        resetCountdown() {
            this.resendCountdown = ConstantValues.defaultResendCountdown;
        },
        async handleCountdown() {
            const countdownId = await this.startCountdown();
            clearInterval(countdownId);
            this.enableResendButton();
        },
        startCountdown() {
            return new Promise(resolve => {
                const countdownId = setInterval(() => {
                    this.resendCountdown--;
                    if (this.isCountdownComplete) {
                        resolve(countdownId);
                    }
                }, 1000)
            });
        },
        enableResendButton() {
            this.resendButtonDisabled = false;
        },
        disableResendButton() {
            this.resendButtonDisabled = true;
        },
        resetResendStates() {
            this.sentCurrentRequest = false;
            this.resendCountdown = ConstantValues.defaultResendCountdown;
            this.resendButtonDisabled = true;
        }
    }
}