import requests from '@/utils/requests';
import buildUrl from 'build-url';
import ConstantValues from '@/plugins/constantValues';
import { apiJobs } from '@/utils/endpoints';
import { isScheduledQuery } from '@/utils/constants';

export const actions = {
	getCalendarEventTitle(job: any) {
		const title = job?.operation?.title || job?.number;
		return title;
	},

	async getUnscheduledJobs({ state, rootState, dispatch, commit }) {
		dispatch("jsonToQueryString", rootState.filterOptions);

		const url = buildUrl(rootState.baseUrl, {
			path: apiJobs + rootState.tempUrl + `&${isScheduledQuery}=false`
		});
		const result: any = await requests.get(url, {
			rootState,
			cancellable: true
		});

		if (result.value.length > 0) {
			state.jobsUnscheduledJobsData = [ ...state.jobsUnscheduledJobsData, ...result.value ];
		}
		return { data: result };
	},

	async setUnscheduledJobsListFilter({ commit, state }, data) {
		if (state.jobsUnscheduledJobsSearchText != null && state.jobsUnscheduledJobsSearchText.length > 0) {
				commit('setFilterOptions', [ConstantValues.searchTextQuery, state.jobsUnscheduledJobsSearchText]);
		}
		commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
		commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
	},
	async updateUnscheduledJobStartDate({ state, rootState, commit }, data) {
		const { jobId, date } = data;
		if (jobId !== 'snackbar') {
			state.jobsUnscheduledJobsData.find(job => job.id == jobId).scheduledAt = date;
		} else {
			state.jobsUnscheduledJobsData.forEach(job => {
				if (rootState.selectedItems.includes(job.id)) {
					job.scheduledAt = date;
				}
			})
		}
	}
}
