import buildUrl from "build-url";
import requests from '@/utils/requests';
import { apiAdminBusinesses, apiAdminBusinessesChangeToContractor } from "@/utils/endpoints";
import moment from "moment";
import ConstantValues from '@/plugins/constantValues';

export const actions = {

    async getBusinessesList({ commit, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions)
        rootState.listLoading = true

        const url = buildUrl(rootState.baseUrl, {
            path: apiAdminBusinesses + rootState.tempUrl
        })

        const result = await requests.getData(url)
        requests.handleSuperAdminForbiddenRequest(result)

        rootState.listLoading = false
        
        if(result?.data) {
            rootState.listCount = result.data.value.length
            rootState.totalRowCount = result.data.size
            rootState.offset = result.data.offset
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.data.value)
            }
            commit('setBusinessesList', result?.data)
        }
        return result
    },

    async getBusinessesDetails({ rootState, commit, state }, businessId) {
        rootState.listLoading = true

        const url = buildUrl(rootState.baseUrl, {
            path: apiAdminBusinesses + '/' + businessId
        })

        const result = await requests.getData(url)
        requests.handleSuperAdminForbiddenRequest(result)

        rootState.listLoading = false

        if (result?.data) {
            commit('setBusinessesDetails', result?.data)
        }
    },

    async deleteBusiness({ rootState, state }, businessId) {
        rootState.listLoading = true
        const url = buildUrl(rootState.baseUrl, {
            path: apiAdminBusinesses + '/' + businessId
        })

        const result = await requests.deleteData(url)
        requests.handleSuperAdminForbiddenRequest(result)

        rootState.listLoading = false
        return result
    },

    async businessChangeToContractor({ rootState, state }, businessId) {
        rootState.listLoading = true
        const url = buildUrl(rootState.baseUrl, {
            path: apiAdminBusinessesChangeToContractor(businessId)
        })
        
        const result = await requests.putData(url, {})
        requests.handleSuperAdminForbiddenRequest(result)

        rootState.listLoading = false
        return result
    },

    async setBusinessesListFilter({ commit, state }, data) {
        state.businessesSearchText && commit('setFilterOptions', ['SearchText', state.businessesSearchText])
        state.businessCountry && commit('setFilterOptions', ['Country', state.businessCountry])
        state.businessType && commit('setFilterOptions', ['BusinessType', state.businessType])
        if(state.businessSubscriptionStatus !== null) {
            commit('setFilterOptions', ['Status', state.businessSubscriptionStatus])
        } 

        if(state.businessesStartDate && state.businessesEndDate) {
            commit('setFilterOptions', ['Start', moment(state.businessesStartDate).utc().format()])
            commit('setFilterOptions', ['End', moment(state.businessesEndDate).utc().format()])
        }
        
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]])
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]])
    },

}