import { JobInvoicingStatusType } from '@/enum/jobInvoicingStatusType';
import { UserRoleType } from '@/enum/userRoleType';
import ConstantValues from '@/plugins/constantValues';
import { isScheduledQuery } from '@/utils/constants';

export const state = {
    teamJobsList: [],
    teamJobsListSearchText: '',
    teamJobsListFilterStartDate: null,
    teamJobsListFilterStartDateLocal: null,

    teamJobsListFilterAssignedUsers: null,

    teamJobsListFilterEndDate: null,
    teamJobsFilterStatusSelected: null,

    teamJobsFiltersStatusList: [
        {
            title: 'All',
            value: ''
        },
        {
            title: 'Pending',
            value: '0'
        },
        {
            title: 'Scheduled',
            value: `1&${isScheduledQuery}=true`
        },
        {
            title: 'Unscheduled',
            value: `1&${isScheduledQuery}=false`
        },
        {
            title: 'In-progress',
            value: '2'
        },
        {
            title: 'Awaiting completion',
            value: '3'
        },
        {
            title: 'Partly completed',
            value: '4'
        },
        {
            title: 'Completed',
            value: '5'
        },
        {
            title: 'Cancelled',
            value: '6'
        }
    ],

    teamJobsFiltersInvoiceStatusList: [
        {
            title: 'All',
            value: null
        },
        {
            title: 'Pending',
            value: '&InvoicingStatus=' + JobInvoicingStatusType.Pending

        },
        {
            title: 'Approved',
            value: '&InvoicingStatus=' + JobInvoicingStatusType.Approved
        },
        {
            title: 'Invoiced',
            value: '&InvoicingStatus=' + JobInvoicingStatusType.Invoiced
        }
    ],

    teamJobsFilterCustomersList: [],
    teamJobsFilterOperationsList: [],
    teamJobsFilterTeamMembersList: [],

    teamJobsFilterCustomerSearchText: '',
    teamJobsFilterOperationSearchText: '',
    teamJobsFilterTeamMemberSearchText: '',
    teamJobsFilterFieldsSearchText: '',
    teamJobsFilterVehiclesSearchText: '',
    teamJobsFilterImplementsSearchText: '',
    teamJobsFilterProductsSearchText: '',

    teamJobsFilterInvoiceStatusValue: null,
    teamJobsFilterCustomerValue: null,
    teamJobsFilterOperationValue: null,
    teamJobsFilterTeamMemberValue: null,
    teamJobsFilterFieldValue: null,
    teamJobsFilterVehicleValue: null,
    teamJobsFilterImplementValue: null,
    teamJobsFilterProductValue: null,

    teamJobsDateFilterApplied: false,

    teamJobsCustomersDropdownLoader: false,
    teamJobsCustomersDropdownTotalCount: 0,
    teamJobsTeamMembersDropdownLoader: false,
    teamJobsFieldsDropdownLoader: false,
    teamJobsVehiclesDropdownLoader: false,
    teamJobsImplementsDropdownLoader: false,
    teamJobsTeamMembersDropdownTotalCount: false,
    teamJobsFilterCustomersListSize: 0,
    teamJobsFilterTeammembersListSize: 0,
    teamJobsFilterFieldsList: [],
    teamJobsFilterFieldsListSize: 0,
    teamJobsFilterVehiclesList: [],
    teamJobsFilterVehiclesListSize: 0, 
    teamJobsFilterImplementsList: [],
    teamJobsFilterImplementsListSize: 0,
    teamJobsFilterProductsList: [],
    teamJobsFilterProductsListSize: 0,
    routedFromJobDetails: false,
    calendarViewJobs: [],
    calendarViewJobEvents: [],
    jobViewType: 'List',
    focusMonth: '',
    calendarStartDate: '',
    calendarEndDate: '',
}

export const getters = {
    teamJobsList: state => state.teamJobsList,
    teamJobsListSearchText: state => state.teamJobsListSearchText,
    teamJobsListFilterStartDate: state => state.teamJobsListFilterStartDate,
    teamJobsListFilterStartDateLocal: state => state.teamJobsListFilterStartDateLocal,
    teamJobsListFilterEndDate: state => state.teamJobsListFilterEndDate,
    teamJobsFiltersStatusList: state => state.teamJobsFiltersStatusList,
    teamJobsFilterStatusSelected: state => state.teamJobsFilterStatusSelected,
    teamJobsDateFilterApplied: state => state.teamJobsDateFilterApplied,
    teamJobsListFilterAssignedUsers: state => state.teamJobsListFilterAssignedUsers,

    jobInvoiceStatus: state => state.jobInvoiceStatus,
    teamJobsFiltersInvoiceStatusList: state => state.teamJobsFiltersInvoiceStatusList,
    teamJobsFilterCustomersList: state => state.teamJobsFilterCustomersList,
    teamJobsFilterOperationsList: state => state.teamJobsFilterOperationsList,
    teamJobsFilterTeamMembersList: state => state.teamJobsFilterTeamMembersList,
    teamJobsFilterFieldsList: state => state.teamJobsFilterFieldsList,
    teamJobsFilterVehiclesList: state => state.teamJobsFilterVehiclesList,
    teamJobsFilterImplementsList: state => state.teamJobsFilterImplementsList,
    teamJobsFilterProductsList: state => state.teamJobsFilterProductsList,

    teamJobsFilterInvoiceStatusValue: state => state.teamJobsFilterInvoiceStatusValue,
    teamJobsFilterCustomerValue: state => state.teamJobsFilterCustomerValue,
    teamJobsFilterOperationValue: state => state.teamJobsFilterOperationValue,
    teamJobsFilterTeamMemberValue: state => state.teamJobsFilterTeamMemberValue,
    teamJobsFilterFieldValue: state => state.teamJobsFilterFieldValue,
    teamJobsFilterVehicleValue: state => state.teamJobsFilterVehicleValue,
    teamJobsFilterImplementValue: state => state.teamJobsFilterImplementValue,
    teamJobsFilterProductValue: state => state.teamJobsFilterProductValue,

    teamJobsFilterCustomerSearchText: state => state.teamJobsFilterCustomerSearchText,
    teamJobsFilterOperationSearchText: state => state.teamJobsFilterOperationSearchText,
    teamJobsFilterTeamMemberSearchText: state => state.teamJobsFilterTeamMemberSearchText,
    teamJobsFilterFieldsSearchText: state => state.teamJobsFilterFieldsSearchText,
    teamJobsFilterVehiclesSearchText: state => state.teamJobsFilterVehiclesSearchText,
    teamJobsFilterImplementsSearchText: state => state.teamJobsFilterImplementsSearchText,
    teamJobsFilterProductsSearchText: state => state.teamJobsFilterProductsSearchText,
    
    teamJobsCustomersDropdownLoader: state => state.teamJobsCustomersDropdownLoader,
    teamJobsTeamMembersDropdownLoader: state => state.teamJobsTeamMembersDropdownLoader,
    teamJobsFieldsDropdownLoader: state => state.teamJobsFieldsDropdownLoader,
    teamJobsVehiclesDropdownLoader: state => state.teamJobsVehiclesDropdownLoader,
    teamJobsImplementsDropdownLoader: state => state.teamJobsImplementsDropdownLoader,
    teamJobsProductsDropdownLoader: state => state.teamJobsProductsDropdownLoader,

    teamJobsFilterProductsListSize: state => state.teamJobsFilterProductsListSize,
    teamJobsFilterImplementsListSize: state => state.teamJobsFilterImplementsListSize,
    teamJobsFilterCustomersListSize: state => state.teamJobsFilterCustomersListSize,
    teamJobsFilterTeammembersListSize: state => state.teamJobsFilterTeammembersListSize,
    teamJobsFilterFieldsListSize: state => state.teamJobsFilterFieldsListSize,
    teamJobsFilterVehiclesListSize: state => state.teamJobsFilterVehiclesListSize,
    routedFromJobDetails: state => state.routedFromJobDetails,
    calendarViewJobs: state => state.calendarViewJobs,
    calendarViewJobEvents: state => state.calendarViewJobEvents,
    jobViewType: state => state.jobViewType,
    focusMonth: state => state.focusMonth,
    calendarStartDate: state => state.calendarStartDate,
    calendarEndDate: state => state.calendarEndDate,
}

export const mutations = {
    setJobsFilteredList(state, data) {
        data.map((data) => state.teamJobsList.push(data));
    },
    setCustomersListForJobsSearch(state, data) {
         if (state.teamJobsFilterCustomersList.length == 0) {
            if (parseInt(localStorage.getItem('userRole')) === UserRoleType.Manager) {
                state.teamJobsFilterCustomersList.push({
                    name: localStorage.getItem('fullOwnerName') ? (localStorage.getItem('fullOwnerName') + ' (owner)') : 'Owner',
                    id: localStorage.getItem(ConstantValues.ownerId)
                });
            } else {
                state.teamJobsFilterCustomersList.push({
                    name: localStorage.getItem('fullOwnerBusinessName') ? (localStorage.getItem('fullOwnerBusinessName') + ' (You)') : 'You',
                    id: localStorage.getItem(ConstantValues.ownerId)
                });
            }
        }
        data.map(customer => {
            if (customer) {
                const member = {
                    name: ((customer.businessProfile.title) ? customer.businessProfile.title : (customer.firstName +
                        (customer.lastName ? (' ' + customer.lastName) : ''))),
                    id: customer.id
                }
                state.teamJobsFilterCustomersList.push(member);
            }
        });
    },
    setCustomersListSizeForJobsSearch(state, data) {
        state.teamJobsFilterCustomersListSize = data;
    },
    setTeammembersListForJobsSearch(state, data) {
        if (state.teamJobsFilterTeamMembersList.length == 0) {
            if (localStorage.getItem('userRole') == '2') {
                state.teamJobsFilterTeamMembersList.push({
                    name: localStorage.getItem('fullOwnerName') ? (localStorage.getItem('fullOwnerName') + ' (owner)') : 'Owner',
                    id: localStorage.getItem(ConstantValues.ownerId)
                });
            } else {
                state.teamJobsFilterTeamMembersList.push({
                    name: 'You',
                    id: localStorage.getItem(ConstantValues.ownerId)
                });
            }
        }

        data.map(teamMember => {
            if (teamMember) {
                const member = {
                    name: teamMember.firstName + ((teamMember.id == localStorage.getItem('userId')) ? ' (You)' : ''),
                    id: teamMember.id
                }
                state.teamJobsFilterTeamMembersList.push(member);
            }
        });
    },
    setFieldsListForJobsSearch(state, data) {
        data.map(field => {
            if (field) {
                const fieldObj = {
                    name: field.title,
                    id: field.id
                }
                state.teamJobsFilterFieldsList.push(fieldObj);
            }
        });
    },

    setFieldsListSizeForJobsSearch(state, data) {
        state.teamJobsFilterFieldsListSize = data;
    },

    setVehiclesListForJobsSearch(state, data) {
        data.map(vehicle => {
            if (vehicle) {
                const vehicleObj = {
                    name: vehicle.title,
                    id: vehicle.id
                }
                state.teamJobsFilterVehiclesList.push(vehicleObj);
            }
        });
    },

    setImplementsListForJobsSearch(state, data) {
        data.map(implement => {
            if (implement) {
                const implementObj = {
                    name: implement.title,
                    id: implement.id
                }
                state.teamJobsFilterImplementsList.push(implementObj);
            }
        });
    },

    setProductsListForJobsSearch(state, data) {
        data.map(product => {
            if (product) {
                const productObj = {
                    name: product.title,
                    id: product.id
                }
                state.teamJobsFilterProductsList.push(productObj);
            }
        });
    },

    setProductsListSizeForJobsSearch(state, data) {
        state.teamJobsFilterProductsListSize = data;
    },

    setVehiclesListSizeForJobsSearch(state, data) {
        state.teamJobsFilterVehiclesListSize = data;
    },

    setTeammembersListSizeForJobsSearch(state, data) {
        state.teamJobsFilterTeammembersListSize = data;
    },

    setImplementsListSizeForJobsSearch(state, data) {
        state.teamJobsFilterImplementsListSize = data;
    },

    setOperationsListForJobsSearch(state, data) {
        data.map((data) => {
            if (data.operations.length) {
                data.operations.map(opr => state.teamJobsFilterOperationsList.push(opr));
            }
        });
    },
    setRoutedFromJobDetails(state, data) {
        state.routedFromJobDetails = data;
    },
    updateJobInList(state, updatedJob) {
        const jobIndex = state.teamJobsList.findIndex(job => job.id === updatedJob.id);
        if (jobIndex > -1) {
            state.teamJobsList.splice(jobIndex, 1, updatedJob);
        }
    },
    removeJobFromList(state, jobIndex) {
        state.teamJobsList.splice(jobIndex, 1);
    },
    resetTeamJobsList(state) {
        state.teamJobsList = []
    }
}