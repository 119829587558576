





































import VueCtkDateTimePicker from "vue-ctk-date-time-picker";

export default {
  name: "UnscheduledJobsDatepicker",
  components: {
    VueCtkDateTimePicker
  },
  props: ['position', 'topOffset', 'value'],
  data() {
    return {
      jobsScheduledDate: null
    }
  },
  computed: {
    overshootsViewportBottom() {
      if (!this.$refs.datepickerRef || !this.position || !Object.values(this.position).length) return;
      const datepickerHeight = this.$refs.datepickerRef.clientHeight;
      if ((this.position.bottom + datepickerHeight) > window.innerHeight) return true;
      return false
    },
    calculatedStyle() {
      if (!this.position || !Object.values(this.position).length) return { opacity: 0, visibility: 'hidden' };
      if (this.overshootsViewportBottom) return { bottom: '100%' };
      return { top: '100%' }
    }
  },
  methods: {
    emitSelectedDatetime() {
      const jobId = this.$refs.datepickerRef.closest('.datepicker-trigger')?.getAttribute('data-id');
      this.$emit('update', this.jobsScheduledDate, jobId);
    },
  },
  watch: {
    value(updatedValue) {
      this.jobsScheduledDate = updatedValue;
    }
  }
}
