import { notify } from "@/utils/helpers";
import StringConstants from '@/plugins/stringConstants';

export default {
    methods: {
        async getFileObjectFormData(file: any) {
            return new Promise((resolve, reject) => {
                try {
                    const formData = new FormData();

                    const fileSizeInMB = file.size / (1024 * 1024);
                    if (fileSizeInMB > 10) {
                        notify(StringConstants.fileSizeWarning, 'fail');
                        reject(new Error(StringConstants.fileSizeWarning));
                        return;
                    }
        
                    formData.append("attachments", file);
                    resolve(formData);
                } catch (e) {
                    reject(e);
                }
            });
        }
    }
}
