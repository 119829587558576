















export default {
    name: 'ButtonActionPrimary',
    props: [
        'height',
        'width',
        'disabled', 
        'loading', 
        'textValue'
    ]
}
