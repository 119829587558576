import { mapMutations } from 'vuex';
import { openOverlay } from '@/utils/helpers';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'farmersModule': [
        'addFarmerLoader',
        'farmerDetails',
        'farmerSearchText',
        'farmerFilterType',
        'farmerType',
        'farmersFilterList',
        'farmerConReqList',
        'farmerSendReqList',
        'isShowConReq',
        'pendingFarmersList',
        'updateFormEnabled',
        'importIntegratedCustomerLoader',
        'integrationContactSearchText',
        'contactsIntegration',
        'allIntegrationCustomersLoaded',
        'fetchingintergrationDataProgressCustomer',
        'tempIntegratedCustomers',
        'intergrationHasDataCustomer',
        'customerLoaderImport',
        'integratedCustomerImportInProgress',
        'farmersLoader',
        'farmerStatus'
    ],
    'usersModule': [
        'userInviteeEmail',
        'dialogInvitation',
        'invitationId',
        'connectionRequestType',
        'dialogType'
    ],
    'integrationsModule': [
        'integrationType',
        'xeroAccessState',
        'quickbookAccessState',
        'sageAccessState',
        'enumImportStatus',
    ],
    'root': [
        'dialogBulkActionResponse',
        'dialogBulkActionResponseTitle',
        'bulkActionResponse',
        'loginUserRole',
        'filtersApplied',
        'statusFilterTypeList',
        'customerTypeFilterList',
        'inviteeRole',
        'noData',
        'listCount',
        'totalRowCount',
        'conReqLoader',
        'conSendLoader',
        'isPageRelated',
        'enumActiveStatus'
    ]
}

export default {
    name: 'FarmersComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        invitationDialogPromptTitle() {
            return this.dialogType == 'accept' ? StringConstants.userAcceptInvitationPromptTitle : StringConstants.userDeleteInvitationPromptTitle
        },
        invitationDialogPromptSubtitle() {
            return this.dialogType == 'accept' ? StringConstants.userAcceptInvitationPromptSubtitle : StringConstants.userDeleteInvitationPromptSubtitle
        },
    },
    methods: {
        ...mapMutations({
            clearFarmerFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),
        confirmDeleteInvitation(id: any, connectionRequestType) {
            this.invitationId = id;
            this.dialogInvitation = true;
            this.connectionRequestType = connectionRequestType;
            this.dialogType = 'delete';
        },
        confirmAcceptInvitation(id) {
            this.invitationId = id;
            this.dialogInvitation = true;
            this.dialogType = 'accept';
        },
        acceptConnection() {
            this.$store.dispatch('acceptUserInvite', this.invitationId).then((res) => {
                if (res) {
                    this.farmerConReqList = this.farmerConReqList.filter(x => x.code != this.invitationId);
                    this.refreshFarmerList();
                }
                this.dialogInvitation = !this.dialogInvitation;
            });
        },
        declineConnection() {
            this.$store.dispatch('declineUserInvite', this.invitationId).then((res) => {
                if (res) {
                    this.farmerConReqList = this.farmerConReqList.filter(x => x.code != this.invitationId);
                }
                this.dialogInvitation = !this.dialogInvitation;
            });
        },
        deleteSentConnection() {
            this.$store.dispatch('deleteSentConReq', this.invitationId).then((res) => {
                if (res) {
                    this.farmerSendReqList = this.farmerSendReqList.filter(x => x.code != this.invitationId);
                }
                this.dialogInvitation = !this.dialogInvitation;
            });
        },
        clearFarmerList() {
            this.$store.state.farmersModule.farmersFilterList = [];
            this.clearFarmerFilterOption();
            this.resetPaging();
        },
        refreshFarmerList() {
            this.clearFarmerList();
            this.$store.dispatch('setFarmersFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(res => {
                this.$store.dispatch('getFarmersFiltered');
            });
        },
        hideConReqList() {
            this.isShowConReq = false;
        },
        getFarmersListView(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            this.$store.dispatch('setFarmersFilter', filters).then(() => {
                this.$store.dispatch('getFarmersFiltered').then(() => {
                    if (this.farmerSearchText == '') {
                        this.clearFarmerFilterOption();
                        this.filtersApplied = false;
                    }
                });
            });
        },
        openManageFarmerForm() {
            openOverlay("manageFarmerFormOverlay");
            this.$store.dispatch('getFarmersConReq');
            this.$store.dispatch('getFarmersSendReq');
            this.hideConReqList();
        },
    }
}