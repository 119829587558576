export enum ImportResourceType {
    Vehicles = 0,
    Implements = 1,
    Products = 2,
    Fields = 3,
    Operations = 4,
    Timesheets = 5,
    TeamMembers = 6,
    Customers = 7,
    Jobs = 8
}