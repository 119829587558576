import $ from 'jquery';
import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
import { clearDateTimePicker } from '@/utils/helpers';

const computedProperties: Record<string, Array<string>> = {
    'adminOverviewModule': [
        'adminOverviewRecentStatistics',
        'adminOverviewOverallStatistics',
        'adminOverviewCurrentStatisticsDateFilterApplied',
        'adminOverviewRecentStatisticsStartDate',
        'adminOverviewRecentStatisticsEndDate',
        'adminRecentOverviewLoader',
        'adminOverallOverviewLoader',
    ],
    'root': [
        'isRoutedFromReferencePage'
    ]
}

export default {
    name: 'AdminOverviewComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        getSenderId() {
            return localStorage.getItem(ConstantValues.ownerId)
        },
        currencySymbol() {
            return localStorage.getItem("currencySymbol");
        }
    },
    methods: {
        clearCurrentStatisticsDateFilter() {
            $('.vdpr-datepicker input[type=text]').first().val('');
            clearDateTimePicker(this.$refs.datePicker.$el);
            this.adminOverviewRecentStatisticsStartDate = null
            this.adminOverviewRecentStatisticsEndDate = null
            this.adminOverviewCurrentStatisticsDateFilterApplied = false
            this.adminOverviewRecentStatisticsStartDate = null
            this.adminOverviewRecentStatisticsEndDate = null
            this.refreshCurrentStatistics()
        },
    }
}