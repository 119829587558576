import { formFieldPb } from '@/utils/uiconstants';
import {
    validateRegexAlphaNumeric,
    validateRegexAlpha,
    validateRegexPhoneNumber,
  } from '@/utils/helpers';
import StringConstants from '@/plugins/stringConstants';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'contactsModule': [
        'contactLoader',
        'contactFarmAddresses',
        'contactProfile',
        'contactDetailsLoader',
        'contactFieldList',
        'contactOnGoingJobList'
    ],
    'root': [
        'isRoutedFromReferencePage',
        'globalCountriesList',
    ]
}

export default {
    name: 'ContactComputedMixin',
    data() {
        return {
             formFieldPb,
        }
    },
    computed: {
        ...getComputedProperties(computedProperties),
        contactPhone() {
            return this.$store.getters.contactProfile.primaryContactNumber
        },
        contactEmail() {
            return this.$store.getters.contactProfile.email
        },
        businessProfilePrefix: {
            get() {
                return this.$store.getters.contactProfile.businessProfile.shortTitle
            },
            set(newVal) {
                this.$store.state.contactsModule.contactProfile.businessProfile.shortTitle = newVal
            }
        }
    },
    methods: {
        checkKeyDownAlpha(event) {
            validateRegexAlpha(event);
        },
        checkKeyDownAlphaNumeric(event) {
            validateRegexAlphaNumeric(event);
        },
        checkPhoneNumber(event) {
            validateRegexPhoneNumber(event);
        },
        getValidatedString(rawString: string) {
            if (rawString) {
                return rawString.toString().replace(/\s+/g, '').replace(/[0-9]/g, '').replace(/\./g, '').toUpperCase();
            }
        },
        generatePhoneValidationErrors() {
            const errors: any = [];
            if (!this.$v.contactPhone.$dirty) return errors
            if (!this.$v.contactPhone.format && this.contactPhone.length > 0) {
                errors.push(StringConstants.userFormatPhoneNumber);
                this.fieldsInvalid = true;
            }
            else if (!this.$v.contactPhone.minLength) {
                errors.push(StringConstants.userMinlengthPhoneNumber);
                this.fieldsInvalid = true;
            }
            else if (!this.$v.contactPhone.maxLength) {
                errors.push(StringConstants.userMaxLengthPhoneNumber);
                this.fieldsInvalid = true;
            }

            return errors;
        }
    }
}