export class Product implements IBase {
    id: string;
    title: string;
    cost: string;
    unit: string;
    isArchived: boolean;
    public SetData(productDto: Product) {
        this.id = productDto.id;
        this.title = productDto.title;
        this.cost = productDto.cost;
        this.unit = productDto.unit;
        this.isArchived = productDto.isArchived;
        return this;
    }

}