import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"field-input-label",class:{ 'pb-2': _vm.isDetailsView }},[_vm._v(" "+_vm._s(_vm.isFarmerLoggedIn ? _vm.$stringConstants('contractor') : _vm.$stringConstants('customer'))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',[_c(VAutocomplete,{directives:[{name:"show",rawName:"v-show",value:(!_vm.teamJobsLoader),expression:"!teamJobsLoader"}],class:{
                            customAuto: _vm.isDetailsView,
                            ownStyle: _vm.teamJobUser && _vm.isDetailsView,
                            opInput: !_vm.teamJobUser && _vm.isDetailsView,
                            disabled: !_vm.teamJobViewPreference.customer.isEditable
                        },attrs:{"items":_vm.teamJobUsersList,"item-text":"businessName","label":_vm.isFarmerLoggedIn ? 'Select contractor' : 'Select customer',"disabled":_vm.teamJobsLoader || !_vm.teamJobViewPreference.customer.isEditable,"loading":_vm.teamJobUsersListLoader,"menu-props":_vm.isDetailsView ? { contentClass: 'ownerMenu', minWidth: '450'} : '',"outlined":"","dense":"","flat":"","attach":"","hide-no-data":"","return-object":"","solo":""},on:{"change":_vm.filterResourcesByUser,"focus":function($event){return _vm.refeatchUserList($event)}},nativeOn:{"input":function($event){return _vm.updateSearchText($event)}},scopedSlots:_vm._u([(_vm.isDetailsView)?{key:"selection",fn:function(data){return [_c('div',[_c('div',{staticClass:"ib"},[_c('OwnerAvatar',{staticClass:"mr-2",attrs:{"width":"34px","height":"34px","isCircular":true,"isThemeColor":false,"isDark":false,"backgroundColor":data.item.colorCode,"initials":data.item.businessName}})],1),_c('div',{staticClass:"ib",class:{ownDetails: _vm.isDetailsView}},[_vm._v(" "+_vm._s(data.item.businessName))])])]}}:null,{key:"item",fn:function(ref){
                        var item = ref.item;
return [_c('div',[(_vm.isDetailsView)?_c('div',{staticClass:"ib"},[_c('OwnerAvatar',{staticClass:"mr-2",staticStyle:{"font-size":"13px"},attrs:{"width":"28px","height":"28px","isCircular":true,"isThemeColor":false,"isDark":false,"initials":item.businessName,"backgroundColor":item.colorCode}})],1):_vm._e(),_c('div',{staticClass:"ib",attrs:{"title":item.businessName}},[_vm._v(" "+_vm._s(_vm.getShortBusinessName(item.businessName)))])])]}},{key:"append-item",fn:function(){return [_c('LoadMoreDropdown',{attrs:{"moreData":_vm.hasMoreData,"loadInProgress":_vm.teamJobUsersListLoader,"loadingListLength":_vm.teamJobUsersList.length},on:{"appendMore":_vm.appendMoreCustomers}})]},proxy:true}],null,true),model:{value:(_vm.teamJobUser),callback:function ($$v) {_vm.teamJobUser=$$v},expression:"teamJobUser"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.teamJobsLoader),expression:"teamJobsLoader"}],staticClass:"mb-9 mt-1"},[_c(VSkeletonLoader,{attrs:{"height":"40","type":"card"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }