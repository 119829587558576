





































































































import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import LoginRegistrationWrapper from '@/components/layouts/login-registration-wrapper.vue';
import {BaseButton as Button} from '@/components/shared/buttons/index';
import loginComputedMixin from '@/components/login/login-computed';
import { routeLogin } from '@/utils/endpoints';
import ConstantValues from '@/plugins/constantValues';

export default {
    mixins: [validationMixin, loginComputedMixin],
    validations: {
        passWord: { 
            required,
            uppercaseConstraint: password => /[A-Z]/.test(password),
            lowercaseConstraint: password => /[a-z]/.test(password),
            numericConstraint: password => /[0-9]/.test(password),
            minLength: minLength(ConstantValues.minlengthPassword),
            maxLength: maxLength(ConstantValues.maxlengthPassword),
        },
        confirmPassWord: {
            required,
            sameAsPassword: sameAs('passWord')
        },
    },
    components: {
        LoginRegistrationWrapper,
        Button
    },
    data: function () {
        return {
            passWord: '',
            confirmPassWord: '',
            verificationSubmit: false,
            value: String,
            valueConfirm: String,
            routeLogin,
            passwordFieldIsFocused: false,
            validPasswordRequirements: [
                {
                    key: "minLength",
                    status: null,
                    text: 'At least 8 characters'
                },
                {
                    key: "uppercaseConstraint",
                    status: null,
                    text: 'At least 1 uppercase letter'
                },
                {
                    key: "lowercaseConstraint",
                    status: null,
                    text: 'At least 1 lowercase letter'
                },
                {
                    key: "numericConstraint",
                    status: null,
                    text: 'At least 1 number'
                }
            ],
        }
    },
    computed: {
        showPasswordRequirementsTooltip: {
            get() {
                return this.passwordFieldIsFocused
            },
            set(value) {
                this.passwordFieldIsFocused = value;
            }
        },
        isFormInvalid: {
            get() {
                return this.$v.$invalid
            }
        },
        emailAddress() {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('email'); 
        }
    },
    methods: {
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return
            }
            this.proceedToResetPassword();
        },
        proceedToResetPassword() {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const email = urlParams.get('email');
            if (token != null && email != null) {
                let formData = new FormData();
                formData.append('Token', token);
                formData.append('Username', email);
                formData.append('NewPassword', this.passWord);
                this.$store.dispatch('sendResetPasswordRequest', formData)
            }
        },
        generateValidationErrors(propertyName: string, validationName1: string, validationName2: string, userError1: string, userError2: string) {
            let errors: any = [];
            if (!this.$v[`${propertyName}`].$dirty) return errors
            if (!this.$v[`${propertyName}`][`${validationName1}`]) {
                errors.push(userError1)
            } else if (!this.$v[`${propertyName}`][`${validationName2}`]) {
                errors.push(userError2)
            }
            return errors
        },
        generateValidationPasswordErrors(
            propertyName: string, 
            validationName1: string, 
            validationName2: string, 
            validationName3: string, 
            validationName4: string, 
            validationName5: string, 
            validationName6: string, 
            userError1: string, 
            userError2: string, 
            userError3: string,
            userError4: string,
            userError5: string,
            userError6: string
        ) {
            let errors: any = [];
            if (!this.$v[`${propertyName}`].$dirty) return errors
            if (!this.$v[`${propertyName}`][`${validationName1}`]) {
                errors.push(userError1);
            } 
            if (!this.$v[`${propertyName}`][`${validationName2}`]) {
                errors.push(userError2);
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName2 ? requirement.status = false : null
                );
            } else {
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName2 ? requirement.status = true : null
                );
            }
            if (!this.$v[`${propertyName}`][`${validationName3}`]) {
                errors.push(userError3);
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName3 ? requirement.status = false : null
                );
            } else {
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName3 ? requirement.status = true : null
                );
            }
            if (!this.$v[`${propertyName}`][`${validationName4}`]) {
                errors.push(userError4);
                this.validPasswordRequirements.find(requirement =>
                    requirement.key == validationName4 ? requirement.status = false : null
            );
            } else {
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName4 ? requirement.status = true : null
                );
            }
            if (!this.$v[`${propertyName}`][`${validationName5}`]) {
                errors.push(userError5);
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName5 ? requirement.status = false : null
                );
            } else {
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName5 ? requirement.status = true : null
                );
            }
            if (!this.$v[`${propertyName}`][`${validationName6}`]) {
                errors.push(userError6);
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName6 ? requirement.status = false : null
                );
            } else {
                this.validPasswordRequirements.find(requirement => 
                    requirement.key == validationName6 ? requirement.status = true : null
                );
            }
            return errors
        },
        handlePasswordFieldInput() {
            if (this.$v.passWord.$model.length > 0) {
                this.$v.passWord.$touch();
            } else {
                this.validPasswordRequirements.map(requirement => requirement.status == null);
                this.$v.passWord.$reset();
            }
        },
        handlePasswordFieldBlur() {
            if (!this.$v.passWord.$invalid || this.$v.passWord.$model.length == 0) {
                this.showPasswordRequirementsTooltip = false;
            }
        },
        handlePasswordFieldFocus() {
            this.showPasswordRequirementsTooltip = true;
            if (this.$v.passWord.$model.length > 0) {
                this.$v.passWord.$touch();
            } else {
                this.$v.passWord.$reset();
            }
        }

    }
}
