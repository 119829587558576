




























































































import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { closeOverlay } from '@/utils/helpers';
import teamMembersComputedMixin from '@/components/team-members/team-members-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import ConstantValues from '@/plugins/constantValues';
import {FeatureTypeLabels} from '@/enum/featureType';
import {ManagerFeatureLabels, TeamLeadFeatureLabels, OperatorFeatureLabels, UserRoleType} from '@/enum/userRoleType';

export default {
    mixins: [validationMixin, teamMembersComputedMixin],
    validations: {
        userInviteeEmail: {
            required,
            email
        }
    },
    computed: {
        Features () {
            return FeatureTypeLabels;
        },
        ManagerFeatures() {
            return ManagerFeatureLabels;
        },
        TeamLeadFeatures() {
            return TeamLeadFeatureLabels;
        },
        OperatorFeatures() {
            return OperatorFeatureLabels;
        },
        UserRole() {
            return UserRoleType;
        }
    },
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
            fieldsInvalid: true
        }
    },
    components: {
        HeaderPrimary,
        Button
    },
    methods: {
        proceedTeamMemberInvitation() {
            const newTeamMemberInviteeObj = {
                inviteeEmail: this.userInviteeEmail,
                inviteeRole: this.userInviteeRole
            };
            this.$store.dispatch('sendUserInvite', newTeamMemberInviteeObj).then((res) => {
                if (res) {
                    if (this.memberFilterType == 2) {
                        this.pendingTeamMemberList = [];
                        this.$store.dispatch('fnSetTeamMembersFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(res => {
                            this.$store.dispatch('getPendingTeamMembers');
                        });
                    }
                    closeOverlay('addTeamMemberFormOverlay');
                }
            });
        },
        generateValidationErrors() {
            let errors: any = [];
            if (!this.$v.userInviteeEmail.$dirty) return errors
            if (!this.$v.userInviteeEmail.required) {
                errors.push(this.$stringConstants('userRequiredEmailAddress'))
                this.fieldsInvalid = true;
            }
            else if (!this.$v.userInviteeEmail.email) {
                errors.push(this.$stringConstants('userFormatEmailAddress'))
                this.fieldsInvalid = true;
            } else {
                this.fieldsInvalid = false;
            }
            return errors;
        },
        closeMemberAddForm() {
            closeOverlay("addTeamMemberFormOverlay");
        }
    }
}
