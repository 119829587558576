<template>
<div>
    <div class="su-counter-title pb-4">
        {{ counterTitle }}
    </div>
    <div class="su-counter-value">
        {{ counterValue }}
    </div>
    <div class="su-counter-summary pt-2" style="height: 25px">
        {{ counterSummary }}
    </div>
</div>
</template>

<script>
export default {
    name: "DashboardStaticsCounter",
    props: [
        'counterTitle',
        'counterValue',
        'counterSummary'
    ],

}
</script>

<style scoped>
.su-counter-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}
.su-counter-value {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
}
.su-counter-summary {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #484848;
}
</style>