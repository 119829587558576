export class Location {
    latitude: number;
    longitude: number;
    createdOn?: string;

    public getLocation?(location: Location) {
        this.latitude = location ? location.latitude : 0;
        this.longitude = location ? location.longitude : 0;
        this.createdOn = location ? location.createdOn : null;
        return this;
    }
}