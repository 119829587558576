import $ from 'jquery';
import { hideScrollBars, showScrollBars } from '@/utils/helpers/gmaps-helpers'

export default {
    methods: {
        reloadScrollBars() {
            $('.scroll-view').css({'overflow-y': 'scroll'});
        },
        unloadScrollBars() {
            $('.scroll-view').css({'overflow-y': 'hidden'});
        },
        scrollToLastTarget(targetContainer: string, innerContainer: string) {
            const $target = $(targetContainer);
            const scrollFromPos = $(innerContainer).innerHeight() / 2;
            if(scrollFromPos > 0) {
                $target.scrollTop(scrollFromPos);
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0)
        },
        scrollToToForm() {
            setTimeout(() => {
                this.scrollToTopElement('form-scroll-view');
            }, 100);
        },
        scrollToTopElement(classNameOrId) {
            const detectedElements: any = document.getElementById(classNameOrId) || document.getElementsByClassName(classNameOrId);
            const targetElements = [...detectedElements];
            
            if(targetElements.length > 0) {
                for(let i = 0; i < targetElements.length; i++) {
                    targetElements[i].scrollTop = 0;
                }
            }
        },
        scrollToBottom() {
            window.scrollTo(0,document.body.scrollHeight);
        },
        hideScrollBars(scrollerClass) {
            hideScrollBars(scrollerClass)
        },
        showScrollBars(scrollerClass) {
            showScrollBars(scrollerClass)
        },    
    }
}