export const state = {
    contractorFilterList: [],
    pendingContractorList: [],
    acceptedContractorTotalCount: 0,
    pendingContractorTotalCount: 0,
    noAcceptedContractors: false,
    noPendingContractors: false,
    contractorSearchText: '',
    contractorFilterType: 0,
    connectionRequestsSentList: [],
    connectionRequestsReceivedList: [],
    connectionRequestsReceivedLoader: false,
    connectionRequestsSentLoader: false,
}

export const getters = {
    acceptedContractorTotalCount: state => state.acceptedContractorTotalCount,
    pendingContractorTotalCount: state => state.pendingContractorTotalCount,
    noAcceptedContractors: state => state.noAcceptedContractors,
    noPendingContractors: state => state.noPendingContractors,
    contractorFilterList: state => state.contractorFilterList,
    pendingContractorList: state => state.pendingContractorList,
    contractorSearchText: state => state.contractorSearchText,
    contractorFilterType: state => state.contractorFilterType,
    connectionRequestsSentList: state => state.connectionRequestsSentList,
    connectionRequestsReceivedList: state => state.connectionRequestsReceivedList,
    connectionRequestsSentLoader: state => state.connectionRequestsSentLoader,
    connectionRequestsReceivedLoader: state => state.connectionRequestsReceivedLoader,
}

export const mutations = {
    setNoAcceptedContractors(state, data) {
        state.noAcceptedContractors = data != null && data.length == 0
    },
    setNoPendingContractors(state, data) {
        state.noPendingContractors = data != null && data.length == 0
    },
    setContractorFilteredList(state, data) {
        data.map(data => state.contractorFilterList.push(data));
    },
    setPendingContractorFilteredList(state, data) {
        data.map((x) => {
            const contractor = {
                "code": x.code,
                "inviterName": x.inviterName,
                "inviteeFirstName": x.inviteeFirstName,
                "inviteeLastName": x.inviteeLastName,
                "inviteeEmail": x.inviteeEmail,
                "inviteeRole": x.inviteeRole,
                "timestamp": x.timestamp,
                "expiry": x.expiry
            }
            state.pendingContractorList.push(contractor);
        });
    },
    setConnectionRequestsSentList(state, data) {
        state.connectionRequestsSentList = [];
        state.connectionRequestsSentList = data;
    },
    setConnectionRequestsReceivedList(state, data) {
        state.connectionRequestsReceivedList = [];
        state.connectionRequestsReceivedList = data;
    },
    
}