import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBtn,_vm._g(_vm._b({staticClass:"base-button",class:[
        {
            disabled: _vm.disabled, 
            outlined: _vm.outlined !== undefined,
        }, 
        _vm.variant + '-button',
        _vm.outlineColor !== undefined ? (_vm.outlineColor + "-outline") : ''
    ],attrs:{"elevation":"0","tile":""}},'v-btn',_vm.$props,false),_vm.on),[(_vm.leftIcon)?_c(VIcon,{staticClass:"mr-1",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.leftIcon))]):_vm._e(),_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(_vm.textValue))]),(_vm.rightIcon)?_c(VIcon,{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.rightIcon))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }