










import $ from 'jquery';
import { closeOverlay } from '@/utils/helpers';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import BusinessesFilterForm from '@/components/su-businesses/su-businesses-filter.vue';
import BusinessesList from './su-businesses-list.vue';
import businessesComputedMixin from "./su-businesses-computed";
import superAdminAccessMixin from '@/mixins/super-admin-access-mixin';

export default {
    name:"SuperAdminbusinesses",
    components: {
        BusinessesFilterForm,
        BusinessesList
    },
    mixins: [requestsCancellationMixin, businessesComputedMixin, superAdminAccessMixin],
    beforeCreate() {
        this.$store.state.dashboardTitle = this.$stringConstants('businessesTitle')
    },
    mounted() {
        $('body').on('click', '#overlay', () => {
            closeOverlay("businessesFilterFormOverlay")
        })
    }
}
