








































































































































import { mapMutations } from "vuex";
import businessesComputedMixin from "./su-businesses-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/timers';
import { getDateDifferenceInMinutes, getDateTimeFormat, getDateTimeFormattedLocal, getUTCDateFormat, notify } from '@/utils/helpers';
import BusinessesDetailsTable from './su-businesses-details-table.vue';
import BusinessesMonthlySubscriptionChart from './su-businesses-details-subscriptions-chart.vue';
import { SubscriptionStatusType, SubscriptionStatusTypeLabel } from '@/enum/subscriptionStatusType';
import superAdminAccessMixin from '@/mixins/super-admin-access-mixin';
import { DeleteItemDialog, DeleteItemDialog as ItemDialog} from '@/components/shared/dialogs/index';
import router from '@/router';
import { routeBusinesses } from '@/utils/endpoints';
import { buttonDeleteColor } from '@/utils/uiconstants';

export default {
    name: "BusinessesDetailsView",
    data() {
        return {
            generalInfoTableName: 'General info',
            generalInfoHeader: ['Business Name', 'Type', 'Address'],

            ownerInfoTableName: 'Owner info',
            ownerInfoHeader: ['Name', 'Email Address', 'Phone Number'],

            teamMemberTableName: 'Team members',
            teamMemberHeader: ['Total', 'Active', 'Inactive'],

            subscriptionTableName: 'Subscription',
            subscriptionHeader: ['Start', 'End', 'Status'],

            jobsTableName: 'Jobs',
            jobsHeader: ['Completed', 'Invoiced', 'Total jobs', 'Last job date'],

            resoucesTableName: 'Resources',
            resourcesHeader: ['Fields', 'Vehicles', 'Products', 'Implements', 'Timesheets', 'Operations'],

            invoiceTableName: 'Invoice',
            invoiceHeader: ['Service', 'Invoice count'],

            customerTableName: 'Customers',
            customerHeader: ['Internal', 'External', 'Total'],

            dialogDelete: false,
            dialogChangeRole: false,
            buttonDeleteColor
        }
    },

    computed: {
        businessId() {
            return window.location.pathname.split("/").pop()
        }
    },
    
    mixins: [
        businessesComputedMixin,
        Timers,
        superAdminAccessMixin
    ],

    components: {
        BusinessesDetailsTable,
        BusinessesMonthlySubscriptionChart,
        Button,
        DeleteItemDialog,
        ItemDialog,
    },

    beforeCreate() {
        this.$store.state.dashboardTitle = this.$stringConstants('businessesDetailsTitle')
    },
    mounted() {
        this.showSuperAdminSubMenu = true
    },
    methods: {
        ...mapMutations({
            resetPaging: "resetPaging",
        }),

        getDateTimeFormattedLocal(dateTime) {
            return getDateTimeFormattedLocal(dateTime) || 'N/A'
        },

        getSubscriptionStartDate(subscriptions) {
            if(subscriptions?.length > 0 && subscriptions?.[0].currentPeriodStart) {
                return this.getDateTimeFormattedLocal(subscriptions?.[0].currentPeriodStart) || ''
            }
            if(subscriptions?.length > 0 && subscriptions?.[0].trialStart) {
                return this.getDateTimeFormattedLocal(subscriptions?.[0].trialStart) || ''
            }
        },

        getSubscriptionEndDate(subscriptions) {
            if(subscriptions?.length > 0 && subscriptions?.[0].currentPeriodEnd) {
                return this.getDateTimeFormattedLocal(subscriptions?.[0].currentPeriodEnd) || ''
            }
            if(subscriptions?.length > 0 && subscriptions?.[0].trialEnd) {
                return this.getDateTimeFormattedLocal(subscriptions?.[0].trialEnd) || ''
            }
        },

        getSubscriptionStatus(subscriptions) {
            if(subscriptions?.length > 0 && subscriptions?.[0].status !== null) {
                const subscriptionsObj = subscriptions[0]

                if(subscriptionsObj.cancellationScheduledOn) {
                    return this.getCancellationScheduledText(subscriptionsObj)
                }
                return subscriptionsObj.status !== null 
                       ? SubscriptionStatusTypeLabel.get(subscriptionsObj.status) 
                       : '-'
            } else {
                if(subscriptions?.length > 0 && subscriptions?.[0].status == null) {
                    const subscriptionsObj = subscriptions?.[0]
                        if(parseInt(getDateDifferenceInMinutes(subscriptionsObj.trialEnd)) > 0) {
                            return 'Trialing'
                        }
                        return 'Trial expired'
                }
            }
        },

        getCancellationReasonIfAny(subscriptions) {
            if(subscriptions?.length > 0 && subscriptions?.[0].status !== null) {
                const subscriptionsObj = subscriptions[0]

                if(subscriptionsObj.cancellationScheduledOn) {
                    return subscriptionsObj.cancellationReason
                }
            }
            return ''
        },

        promtUserDeletion() {
            this.dialogDelete = true
        },

        promtUserRoleChange() {
            this.dialogChangeRole = true
        },

        proceedUserDeletion() {
            this.dialogDelete = false
            this.$store.dispatch('deleteBusiness', this.businessId).then(res => {
                if(res && res?.status == 200) {
                    notify(this.$stringConstants('businessDeleteSuccess'), 'success')
                    router.push(routeBusinesses)
                }
            })
        },

        proceedUserRoleChange() {
            this.dialogChangeRole = false
            this.$store.dispatch('businessChangeToContractor', this.businessId).then(res => {
                if(res) {
                    notify(this.$stringConstants('businessRoleChangeSuccess'), 'success')
                    this.$store.dispatch('getBusinessesDetails', this.businessId)
                }
            })
        }

    },
};
