






































import moment from 'moment';
import BaseFlyout from '@/components/shared/flyouts/base-flyout.vue';
import HeaderPrimary from '../shared/headers/header-primary.vue';
import { BaseButton as Button } from '@/components/shared/buttons';
import TeamJobsOwners from '../team-jobs/common/team-jobs-owners.vue';
import TeamJobsScheduledDate from '../team-jobs/common/team-jobs-generic-information/team-jobs-scheduled-date.vue';
import TeamJobsOperation from '@/components/team-jobs/common/team-jobs-operation.vue';
import TeamJobsOperators from '../team-jobs/common/team-jobs-operators.vue';
import { TeamJobComponentConfiguration } from '@/data/models/TeamJobComponentConfiguration';
import { TeamJobViewPreference } from '@/data/models/TeamJobViewPreference';
import ConstantValues from '@/plugins/constantValues';
import { UserRoleType } from '@/enum/userRoleType';
import { getLoggedInUserRole, getOwnerId } from '@/utils/helpers/user-role-helpers';
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';

export default {
  name: "AddJobFlyout",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    BaseFlyout,
    HeaderPrimary,
    Button,
    TeamJobsOwners,
    TeamJobsScheduledDate,
    TeamJobsOperation,
    TeamJobsOperators
  },
  props: {
    value: Boolean
  },
  data() {
    return {
      isSubmitInProgress: false
    }
  },
  computed: {
    computedDateFormatted() {
      return this.selectedDate ? moment(this.selectedDate).format('DD MMM YYYY') : '';
    },
    hasRequiredFields() {
      if (!this.teamJobUser?.id || !this.teamJobOperation?.id) return false;
      return true;
    },
    isExistingJob() {
      return !!this.teamJobDetails?.id
    }
  },
  methods: {
    setViewPreference() {
      const newViewEnabledConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
      this.teamJobViewPreference.operators = new TeamJobViewPreference().setOperatorsPreference(newViewEnabledConfiguration);
      this.teamJobViewPreference.customers = new TeamJobViewPreference().setCustomerPreference(newViewEnabledConfiguration);
    },
    resetAddJobForm() {
      this.teamJobUser = null;
      this.teamJobOperation = null;
      this.teamJobOperators = [];
      this.teamJobDetails = null;
      this.$nextTick(() => {
        this.teamJobViewPreference = new TeamJobViewPreference();
      });
    },
    async getAllListItemsOfFieldsInAddJobForm() {
      if (getLoggedInUserRole().isUserOwnerFarmerLoggedIn) {
          this.teamJobOwnerType = UserRoleType.Contractor;
      }

      this.$store.commit('setClearFilterOptions');
      await this.$store.dispatch('getTeamJobsOwners');

      if (getLoggedInUserRole().isUserOwnerContractorLoggedIn) {
        this.teamJobOperationOwnerId = getOwnerId();

        await this.$store.dispatch('getTeamJobOperations');
        this.$store.commit('setClearFilterOptions');
      }

      await this.$store.dispatch('getTeamJobTeamMembers');
      this.$store.commit('setClearFilterOptions');
      
      await this.$store.dispatch('getTeamJobVehiclesList');
      this.$store.commit('setClearFilterOptions');

      await this.$store.dispatch('getTeamJobImplementsList');
      this.$store.commit('setClearFilterOptions');
    },
    async handleSubmit() {
      this.isSubmitInProgress = true;
      if (this.isExistingJob) {
        await this.updateTeamJob(true);
      } else {
        await this.saveTeamJob(true);
      }
      this.$emit('submit');
      this.isSubmitInProgress = false;
    }
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.resetAddJobForm();
        this.$store.commit('toggleIsJobBeingAdded');
        this.$store.commit('resetTeamJobs');
      } else {
        this.getAllListItemsOfFieldsInAddJobForm();
        this.setViewPreference();
      }
    }
  }
}
