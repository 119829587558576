






export default {
    name: 'ButtonCloseIcon'
}
