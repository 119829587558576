import ConstantValues from '@/plugins/constantValues';
import exportFromJSON from 'export-from-json';
import { devLogger } from '../helpers';
import { generateRandomFilename } from '../helpers/generator-helpers';
import store from '@/store/index';

export const excelParser = () => {
    
    function exportDataFromJSON(data: Array<Record<string, unknown>>, newFileName?: string, fileExportType?: string) {
        if(!data) return;
        try {
            const fileName = newFileName || generateRandomFilename();
            const exportType = exportFromJSON.types[fileExportType || ConstantValues.defaultListExportFormat];
            exportFromJSON({ data, fileName, exportType });
        } catch(e) {
            devLogger().logError(e);
            throw new Error('Parsing failed!');
        }
    }

    function saveDataToExcel() {
        store.commit('removeIdsFromExportableData')
        const { exportData } = store.state.excelExportModule

        exportDataFromJSON(
            exportData,
            null,
            ConstantValues.defaultListExportFormat,
        )
    }

    return {
        exportDataFromJSON,
        saveDataToExcel
    }
}