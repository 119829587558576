import { getComputedProperties } from "@/utils/helpers/computed-generator"

const computedProperties: Record<string, Array<string>> = {
    'usersModule': [
        'userInviteeAcceptanceCode',
        'invitationType',
        'usersLoader'
    ],
    'subscriptionsModule': [
        'subscriptionPlanLoader'
    ],
    'root': [
        'loginLoader'
    ]
}

export default {
    name: 'LoginComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}