










































































































import { closeOverlay } from "@/utils/helpers";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import ExternalContactsSearch from "@/components/invoices-external/invoices-external-contacts/invoices-external-contacts-search.vue";
import InvoicesExternalComputedMixin from "@/components/invoices-external/invoices-external-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import EllipsisLoader from "@/components/shared/loaders/ellipsis-loader.vue";
import ConstantValues from '@/plugins/constantValues';
import ListLoader from "@/components/shared/loaders/list-loader.vue";

export default {
    name: "CustomerListVue",
    mixins: [permissionsMixin, InvoicesExternalComputedMixin],
    data() {
        return {
            externalContact: null,
            showLinkWarning: false,
            dialogMessage: null,
        }
    },
    components: {
        ExternalContactsSearch,
        Button,
        EllipsisLoader,
        ListLoader,
    },
    
    mounted() {
        this.externalContactIdToLink = null;
        this.clearContactList();
        if (this.integrationType != null) {
            this.setFilterOptionsAndGetContacts();
        }
        this.changeDefaultPageLimitSize();
    },
    beforeDestroy() {
        this.clearContactList();
        this.externalContact = null;
        this.showLinkWarning = false;  
        this.dialogMessage = null;
    },
    destroyed() {
        this.clearContactList();
    },
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging",
        }),
        clearContactList() {
            this.clearFilterOptions();
            this.resetPaging();
            this.fetchingintergrationDataProgress = false;
            this.contacts = [];
            this.contactSearchText = "";
            this.filtersApplied = false;
            this.loadedContacts = 0;
            
            this.allCustomersLoaded = false;
        },
        localCheckContactLinkStatus() {
            if (
                this.invoiceDraftPreviewDetailsExternal &&
                this.invoiceDraftPreviewDetailsExternal.customerId
            ) {
                this.invoiceLoaderExternal = true;
                this.$store
                    .dispatch("checkContactLinkStatus", [
                        this.integrationType,
                        this.invoiceDraftPreviewDetailsExternal.customerId,
                    ])
                    .then((res) => {
                        this.invoiceLoaderExternal = false;
                    });
            }
        },
        linkCustomerCheck() {
            if(this.externalContact && this.externalContact.customer) {
                this.dialogMessage = `${this.externalContact?.companyName || 'Contact '} 
                ${this.$stringConstants('invoiceExternalAlreadyLinkedWarningText1')} 
                ${this.externalContact?.customer?.firstName || ''}. 
                ${this.$stringConstants('invoiceExternalAlreadyLinkedWarningText2')} 
                ${this.invoiceDraftPreviewDetailsExternal.customerName} instead?`;

                this.showLinkWarning = true;
            } else {
                this.linkCustomer()
            }
        },
        unlinkAndLinkContact() {
            this.dialogMessage = null;
            this.showLinkWarning = false;
            this.invoiceLoaderExternal = true;

            this.$store.dispatch('unlinkContact', [
                this.integrationType,
                this.externalContact.customer.id,
                this.externalContact.id
            ]).then(() => {
                this.linkCustomer()
            })
        },
        linkCustomer() {
            const externalContactId = this.externalContactIdToLink;
            const internalCustomerId =
                this.invoiceDraftPreviewDetailsExternal.customerId;
            if (externalContactId && internalCustomerId) {
                this.invoiceLoaderExternal = true;
                this.closeContactLinkFlyout();
                this.$store
                    .dispatch("linkContact", [
                        this.integrationType,
                        internalCustomerId,
                        externalContactId,
                    ])
                    .then((unlinkResult) => {
                        this.invoiceLoaderExternal = false;
                        if (unlinkResult[0].length == 0) {
                            this.localCheckContactLinkStatus();
                        }
                    });
            }
        },
        getSelectedCustomer(contact: string) {
            this.externalContact = contact;
            this.externalContactIdToLink = this.externalContact.id;
        },
        closeContactLinkFlyout() {
            this.clearContactList();
            this.externalContactIdToLink = null;
            closeOverlay("contactsLinkFormOverlay");
            this.$emit('linkingContactDone')
        },
        setFilterOptionsAndGetContacts(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSizeCustomer]) {
            this.$store.dispatch("setContactsSearchFilter", filters)
            .then(() => {
                this.$store.dispatch("getExternalInvoicingServiceContacts", this.integrationType)
            })
        },
        changeDefaultPageLimitSize() {
            this.$store.commit('setDefaultPageLimitSize', ConstantValues.defaultPageLimitSizeCustomer)
        }
    },
};
