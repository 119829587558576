





















































































































import $ from 'jquery';
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobAddressesFlyoutForm from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout.vue';
import { openOverlay, closeOverlay } from '@/utils/helpers';
import { TeamJobAddressSelectionType } from '@/enum/jobAddressSelectionType';
import { TeamJobAddress } from '@/data/models/TeamJobAddress';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';

export default {
    name: "TeamJobsLocations",
    mixins: [TeamJobsComputed, TeamJobsCommon],
    components: {
      TeamJobAddressesFlyoutForm  
    },
    mounted() {
        $('body').on('click', '#overlay', function () {
            closeOverlay("jobAddressesFormOverlay");
        });
    },
    methods: {
        resetFarmAddressStates() {
            this.teamJobFarmAddressesSelected = [];
            this.teamJobAddressInstructions = null;
            this.teamJobAddressSelectionType = TeamJobAddressSelectionType.FarmAddress;
            this.teamJobCustomAddress = new TeamJobAddress();
        },
        openAddAddressesForm() {
            this.resetFarmAddressStates();
            openOverlay('jobAddressesFormOverlay')
        },
        removeAddress(addressIndex) {
            this.enableTeamJobDiscard();
            this.teamJobAddresses = this.teamJobAddresses.filter((_, jIndex) => jIndex !== addressIndex);

        },
        getAddressTitle(address) {
            const line1 = address.addressLine1 ? address.addressLine1 : "";
            const line2 = " " + (address.addressLine2 ? address.addressLine2 : "");
            const city = " " + (address.city ? address.city : "");
            const country = " " + (address.country ? address.country : "");
            const postalCode = " " + (address.postalCode ? address.postalCode : "");
            const addressArray = [line1, line2, city, country, postalCode];
            return addressArray.join();
        }
    }
}
