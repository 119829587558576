

















































































































































































































































import BusinessProfileForInvoiceAdd from "@/components/invoices/invoices-add/invoices-add-business-info.vue";
import JobsListForInvoice from "@/components/invoices/invoices-add/invoices-add-jobs-list.vue";
import InvoiceDraftPreview from "@/components/invoices/invoices-draft/invoices-draft.vue";
import router from "@/router/index";
import { apiInvoices, routeInvoices } from "@/utils/endpoints";
import { mapMutations } from "vuex";
import InvoiceMapMixin from "@/mixins/invoice-map-mixin";
import ScrollerMixin from "@/mixins/scroller-mixin";
import InvoicesComputedMixin from "@/components/invoices/invoices-computed";
import { ButtonActionPrimary, ButtonActionPrimaryBack, BaseButton as DownloadButton, HoverTextButton } from "@/components/shared/buttons/index";
import { DeleteItemDialog as ReportDialog } from '@/components/shared/dialogs/index';
import ConstantValues from '@/plugins/constantValues';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';

export default {
    name: "InvoicesAdd",
    components: {
        BusinessProfileForInvoiceAdd,
        JobsListForInvoice,
        InvoiceDraftPreview,
        ButtonActionPrimary,
        ButtonActionPrimaryBack,
        DownloadButton,
        ReportDialog,
        HoverTextButton
    },
    data() {
        return {
            steps: 3,
            regenerateJobReportPopup: false
         };
    },
    computed: {
        currentInvoiceId: {
            get() {
                const invoiceId = window.location.pathname.split("/").pop();
                return invoiceId !== "add" ? invoiceId : null;
            }
        },
        invoiceLineItemsHaveValidQuantity() {
            return this.invoiceDraftPreviewDetails && this.invoiceDraftPreviewDetails.lineItems.every(lineItem => lineItem.quantity != 0);
        }
    },
    mixins: [InvoiceMapMixin, ScrollerMixin, InvoicesComputedMixin, requestsCancellationMixin, breadcrumbsMixin],
    watch: {
        steps(val) {
            if (this.invoiceSelectedTab > val) {
                this.invoiceSelectedTab = val;
            }
        },
    },
    methods: {
        ...mapMutations({
            setClearInvoiceData: "setClearInvoiceData",
            setClearIntegrations: "setClearIntegrations",
            clearInternalInvoiceFilterOptions: "setClearFilterOptions",
            resetInternalInvoicePaging: "resetPaging",
        }),
        nextStep(n) {
            if (n === this.steps) {
                this.invoiceSelectedTab = 1;
            } else {
                this.invoiceSelectedTab = n + 1;
            }
        },
        previousStep(n) {
            if (n < 6) {
                this.invoiceSelectedTab = n - 1;
            }
        },
        cancelReportRegeneration() {
            this.regenerateJobReportPopup = false;
        },
        proceedReportRegeneration() {
            this.regenerateJobReportPopup = false;
            this.generateInvoiceReport();
        },
        promtGenerateInvoiceReport() {
            if (this.invoiceJobReportUrls && this.invoiceJobReportUrls.length > 0) {
                this.regenerateJobReportPopup = true;
            } else {
                this.generateInvoiceReport();
            }
        },
        createDraft() {
            const createInvoiceObj = {
                jobIds: this.selectedJobsForInvoice.map((jb) => jb.id),
                supplierDetails: {
                    name: this.supplierNameForInvoice,
                    emailAddress: this.supplierEmailForInvoice,
                    phoneNumber: this.supplierPhoneNumberForInvoice,
                    address: this.supplierBusinessProfileForInvoice.address,
                    paymentInstructions: this.supplierPaymentInstructionsForInvoice,
                },
                customerId: this.selectedCustomerIdForInvoice,
                customerDetails: {
                    name: this.customerNameForInvoice,
                    emailAddress: this.customerEmailForInvoice,
                    phoneNumber: this.customerPhoneNumberForInvoice,
                    address: this.customerBusinessProfileForInvoice.address,
                },
                dueDate: null,
            };

            this.$store.state.jobsInvoicesModule.map = null;

            this.$store
                .dispatch("createInvoiceDraftPreview", createInvoiceObj)
                .then((res) => {
                    if (res) {
                        this.$store.commit("setInvoicePreviewDetails", res);
                        this.invoiceSelectedTab = 3;

                        if(this.invoiceDraftPreviewDetails.number) {
                            this.setBreadcrumbs('InvoiceDetails', this.invoiceDraftPreviewDetails.number)
                        }

                    }
                });
        },
        cancelDraftInvoice() {
            if (!this.invoiceDraftPreviewDetails) return router.back();
            const invoicesList = this.$store.state.invoicesModule.invoicesList;
            const invoiceIndex = invoicesList.findIndex(invoice => invoice.id === this.invoiceDraftPreviewDetails?.id);
            if (invoiceIndex > -1) {
                router.back()
            } else {
                this.clearAndResetInvoicesPagingStates();
                router.push(routeInvoices);
            }
        },
        async getJobReportUrls() {
            this.$store.dispatch('getJobReportUrlsAndUpdateReferencesAsync', this.invoiceDraftPreviewDetails)
            this.invoiceReportGenerationInProgress = true
        },
        updateInvoiceDraft() {
            this.invoiceDraftPreviewDetails.customer.address.title =
                this.invoiceDraftPreviewDetails.customer.address.addressLine1;
            this.invoiceDraftPreviewDetails.supplier.address.title =
                this.invoiceDraftPreviewDetails.supplier.address.addressLine1;

            this.invoiceDraftPreviewDetails.dueDate = this.invoiceDate;
            let invoiceIdToOpen = this.draftedInvoiceIdToOpen
                ? this.draftedInvoiceIdToOpen
                : this.invoiceDraftPreviewDetails.id;

            this.invoiceDraftPreviewDetails.dueDate =
                this.invoiceDraftPreviewDetails.dueDate.length === 10
                    ? this.invoiceDraftPreviewDetails.dueDate + "T00:00:00Z"
                    : this.invoiceDraftPreviewDetails.dueDate;

            this.attachJobReportUrlsToInvoice();
            this.$store.dispatch("updateInvoiceDraft", [
                this.invoiceDraftPreviewDetails,
                invoiceIdToOpen,
            ]).then(updatedInvoice => { 
                this.$store.commit('updateInvoiceDetailsInInvoiceList', updatedInvoice)

                if(this.invoiceDraftPreviewDetails.number) {
                    this.setBreadcrumbs('InvoiceDetails', this.invoiceDraftPreviewDetails.number)
                }
            });
        },
        attachJobReportUrlsToInvoice() {
            this.invoiceDraftPreviewDetails.attachments = [];

            if (this.invoiceJobReportUrls.length > 0) {
                this.invoiceJobReportUrls.map((report) => {
                    const obj = {
                        reference: report.reference,
                        url: report.url,
                    };
                    this.invoiceDraftPreviewDetails.attachments.push(obj);
                });
            }
        },
        generateInvoiceReport() {
            this.$store.dispatch('promtInvoiceGenerationMessage');
            this.invoiceDraftPreviewDetails.attachments = [];
            this.invoiceJobReportUrls = [];
            const invoiceIdToOpen = this.draftedInvoiceIdToOpen
                ? this.draftedInvoiceIdToOpen
                : this.invoiceDraftPreviewDetails.id;

            this.scrollToTopElement('stepper-scroll');
            this.hideScrollBars('stepper-scroll');
            this.getJobReportUrls(invoiceIdToOpen);
        },
        createInvoiceDraft() {
            this.invoiceDraftPreviewDetails.dueDate =
                this.invoiceDate != null ? this.invoiceDate + "T00:00:00Z" : null;

            this.$store.dispatch(
                "createInvoiceDraft",
                this.invoiceDraftPreviewDetails
            ).then(() => this.clearAndResetInvoicesPagingStates());
        },
        createInvoiceFinalize() {
            this.invoiceSend = !this.invoiceShouldSendLater;

            const invoiceIdToOpen = this.draftedInvoiceIdToOpen
                ? this.draftedInvoiceIdToOpen
                : this.invoiceDraftPreviewDetails.id;

            if (invoiceIdToOpen != null) {
                this.attachJobReportUrlsToInvoice();
                this.$store
                    .dispatch("updateInvoiceDraft", [
                        this.invoiceDraftPreviewDetails,
                        invoiceIdToOpen,
                    ])
                    .then((result) => {
                        this.$store
                            .dispatch("createInvoiceFinalize", invoiceIdToOpen)
                            .then((res) => {
                                history.replaceState({}, null, routeInvoices + "/" + invoiceIdToOpen);
                                this.$store.dispatch("getInvoiceDetails", invoiceIdToOpen)
                                .then(updatedInvoice => {
                                        this.$store.commit('updateInvoiceDetailsInInvoiceList', updatedInvoice)

                                        if(this.invoiceDraftPreviewDetails.number) {
                                            this.setBreadcrumbs('InvoiceDetails', this.invoiceDraftPreviewDetails.number)
                                        }
                                    }
                                );
                            });
                    });
            }
        },
        sendInvoice() {
            const invoiceId = this.draftedInvoiceIdToOpen
                ? this.draftedInvoiceIdToOpen
                : this.invoiceDraftPreviewDetails.id;

            this.$store.dispatch("sendInvoiceMail", invoiceId);
        },
        isNextDisabled(stepNumber) {
            switch (stepNumber) {
                case 1:
                    if (
                        this.supplierNameForInvoice == null ||
                        this.supplierNameForInvoice.length == 0 ||
                        this.supplierEmailForInvoice == null ||
                        this.supplierEmailForInvoice.length == 0 ||
                        this.supplierPhoneNumberForInvoice == null ||
                        this.supplierPhoneNumberForInvoice == 0 ||
                        this.supplierBusinessProfileForInvoice.address == null ||
                        this.supplierBusinessProfileForInvoice.address.addressLine1 ==
                        null ||
                        this.supplierBusinessProfileForInvoice.address.addressLine1
                            .length == 0 ||
                        this.supplierBusinessProfileForInvoice.address.postalCode == null ||
                        this.supplierBusinessProfileForInvoice.address.postalCode.length ==
                        0
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                case 2:
                    if (
                        Array.isArray(this.selectedJobsForInvoice) &&
                        this.selectedJobsForInvoice.length == 0
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                case 3:
                    if (
                        this.dueDateForInvoice == null ||
                        this.dueDateForInvoice.length == 0
                    ) {
                        return true;
                    } else {
                        return false;
                    }
            }
        },
        markInvoiceAsPaid() {
            const invoiceId = this.invoiceDraftPreviewDetails.id;
            this.$store.dispatch("markInvoiceAsPaid", invoiceId).then(() => {
                this.$store.dispatch("getInvoiceDetails", invoiceId).then(updatedInvoice => this.$store.commit('updateInvoiceDetailsInInvoiceList', updatedInvoice));
            });
        },
        goToPreviousRoute() {
            const invoicesList = this.$store.state.invoicesModule.invoicesList;
            const invoiceIndex = invoicesList.findIndex(invoice => invoice.id === this.invoiceDraftPreviewDetails.id);
            if (invoiceIndex > -1 || this.isPageRelated) {
                router.back()
            } else {
                router.push(routeInvoices);
            }
        },
        downloadInvoice() {
            this.$store.dispatch("downloadInvoice", this.draftedInvoiceIdToOpen)
        },
        clearAndResetInvoicesPagingStates() {
            this.$store.state.isPageRelated = false;
            this.$store.commit('resetInvoicesListPagingStates');
        }
    },
    mounted() {
        if (!this.currentInvoiceId) {
            this.resetInternalInvoicePaging();
            this.clearInternalInvoiceFilterOptions();
            this.invoiceReportGenerationInProgress = false;
            this.setBreadcrumbs('InvoicesAdd');
            this.$store.dispatch("getCustomersListForInvoice");
            this.setClearIntegrations();
        }
    },
    beforeDestroy() {
        this.setClearInvoiceData();
    }
};
