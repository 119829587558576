<template>
<div>
    <div class="su-db-box-wrapper su-db-box-wrapper-right su-db-box-minh">
        <div class="su-db-header">
            <div class="ib">
                Overall statistics
            </div>
        </div>
        <div>
            <div class="ib su-db-container-left" style="width:70%; vertical-align: top;">
                <div class="su-db-title pb-8 pt-3">
                    Businesses
                </div>
                <div>
                    <div v-if="!adminOverallOverviewLoader">
                        <SuDashboardCurrentStatisticsCounter 
                            counterTitle="Total"
                            :counterValue="adminOverviewOverallStatistics.totalBusinesses"
                            :counterSummary="adminOverviewOverallStatistics.averageTeamMembersPerBusiness+' average members per business'"
                        />
                    </div>
                    <div v-else>
                        <StatsLoader :loaderRows="2" />
                    </div>
                </div>
                <div class="pt-7">
                    <div>
                        <div v-if="!adminOverallOverviewLoader">
                            <SuDashboardCurrentStatisticsCounter 
                                counterTitle="Active or Trialing"
                                :counterValue="adminOverviewOverallStatistics.totalActiveBusinesses"
                                :counterSummary="currencySymbol + adminOverviewOverallStatistics.averageSubscriptionInvoiceAmountPerBusiness + ' average subscriptions amount per business'"
                            />
                        </div>
                        <div v-else>
                            <StatsLoader :loaderRows="2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="ib su-db-container-right" style="width:30%; vertical-align: top;">
                <div class="su-db-title pb-8 pt-3">
                    Users
                </div>
                <div>
                    <div class="ib">
                        <div>
                            <div v-if="!adminOverallOverviewLoader">
                                <SuDashboardCurrentStatisticsCounter 
                                    counterTitle="Total"
                                    :counterValue="adminOverviewOverallStatistics.totalUsers"
                                    :counterSummary="null"
                                />
                            </div>
                            <div v-else>
                                <StatsLoader :loaderRows="2" />
                            </div>
                        </div>
                        <div class="pt-7">
                            <div v-if="!adminOverallOverviewLoader">
                                <SuDashboardCurrentStatisticsCounter 
                                    counterTitle="Active"
                                    :counterValue="adminOverviewOverallStatistics.totalActiveUsers"
                                    :counterSummary="null"
                                />
                            </div>
                            <div v-else>
                                <StatsLoader :loaderRows="2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import SuDashboardCurrentStatisticsCounter from './su-overview-current-statistics-counter.vue';
import suOverviewComputedMixin from './su-overview-computed';
import StatsLoader from './su-overview-stats-loader.vue';

export default {
    mixins: [suOverviewComputedMixin],
    components: {
        SuDashboardCurrentStatisticsCounter,
        StatsLoader
    },
    mounted() {
        this.$store.dispatch('getOverallStatistics')
    },
}
</script>

<style>
.su-db-container-left {
    padding: 24px;
}
.su-db-container-right {
    padding: 24px;
}
.su-db-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
}
     @media only screen and (max-width: 1480px) {
        .su-db-box-wrapper-right {
            margin-top: 24px;
        }
     }
</style>