




























































































































import { listAction } from '@/enum/listAction';

    export default {
        name: 'SnackBarList',
        props: [
            'deleteApiType',
            'allItemsSelected',
            'someItemsSelected',
            'status',
            'showOnlyDelete',
            'showArchiveOrDelete',
            'showRoleChange',
            'change-role-callback',
            'showUpdateButton',
            'update-items-callback',
            'integrationType',
            'loaderState'
        ],
        data() {
            return {
                timeout: -1,
                listAction
            }
        },
        watch: {
            snackbarShow(snackBarToggleVisibility) {
                if(snackBarToggleVisibility === false) {
                    this.clearAllSelected();
                }
            }
        },
        computed: {
            snackbarShow: {
                get() {
                    return this.$store.getters.snackbarShow;
                },
                set(newVal) {
                    this.$store.state.snackbarShow = newVal;
                }
            },
            allItemsDeleted: {
                get() {
                    return this.$store.getters.allItemsDeleted;
                },
                set(newVal) {
                    this.$store.state.allItemsDeleted = newVal;
                }
            },
            allItemsRestored: {
                get() {
                    return this.$store.getters.allItemsRestored;
                },
                set(newVal) {
                    this.$store.state.allItemsRestored = newVal;
                }
            },
            allItemsArchived: {
                get() {
                    return this.$store.getters.allItemsArchived;
                },
                set(newVal) {
                    this.$store.state.allItemsArchived = newVal;
                }
            },
            selectedItems: {
                get() {
                    return this.$store.getters.selectedItems;
                },
                set(newVal) {
                    this.$store.state.selectedItems = newVal;
                }
            },
            totalRowCount: {
                get() {
                    return this.$store.getters.totalRowCount;
                },
                set(newVal) {
                    this.$store.state.totalRowCount = newVal;
                }
            }, 
            listActionType: {
                get() {
                    return this.$store.getters.listActionType;
                },
                set(newVal) {
                    this.$store.state.listActionType = newVal;
                }
            },
            dialogSelectedRows: {
                get() {
                    return this.$store.getters.dialogSelectedRows;
                },
                set(val) {
                    this.$store.state.dialogSelectedRows = val;
                },
            },
            archiveDialogSelectedRows: {
                get() {
                    return this.$store.getters.archiveDialogSelectedRows;
                },
                set(val) {
                    this.$store.state.archiveDialogSelectedRows = val;
                },
            },
            restoreDialogSelectedRows: {
                get() {
                    return this.$store.getters.restoreDialogSelectedRows;
                },
                set(value) {
                    this.$store.state.restoreDialogSelectedRows = value;
                }
            },
            bulkActionLoader: {
                get() {
                    return this.$store.getters.bulkActionLoader;
                },
                set(value) {
                    this.$store.state.bulkActionLoader = value;
                }
            },
        },
        methods: {
            selectAllItemsData() {
                this.allItemsDeleted = true;
                this.allItemsArchived = true;
                this.allItemsRestored = true;
            },
            deleteSectedRows() {
                this.listActionType = listAction.Delete;
                this.dialogSelectedRows = true;
            },
            clearAllSelected() {
                this.selectedItems = [];
                this.allItemsDeleted = false;
                this.allItemsArchived = false;
                this.allItemsRestored = false;
                
            },
            archiveSelectedRows() {
                this.listActionType = listAction.Archive;
                this.dialogSelectedRows = true;
            },
            restoreSelectedRows() {
                this.listActionType = listAction.Restore;
                this.dialogSelectedRows = true;
            },
            changeRole() {
                this.$emit('change-role-callback')
            },
            updateItems() {
                this.$emit('update-items-callback')
            }
        }
    }
