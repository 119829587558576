import { Address } from '@/data/models/Address';
import ConstantValues from '@/plugins/constantValues';
import moment from 'moment';

export const state = {
    customersListForInvoiceExternal: [],
    customersSearchTextExternal: '',
    jobsForInvoiceSearchTextExternal: '',
    invoiceLoaderExternal: false,
    selectedCustomerIdForInvoiceExternal: null,
    jobsListForInvoiceExternal: [],
    selectedJobsForInvoiceExternal: [],
    dueDateForInvoiceExternal: null,
    invoiceDraftPreviewDetailsExternal: null,
    invoicesListExternal: [],
    invoiceSearchTextExternal: '',
    draftedInvoiceIdToOpenExternal: null,
    invoiceSelectedTabExternal: 1,
    invoiceDateExternal: null,
    invoiceLineItemJobNumbersDistinctExternal: [],
    enableExternalInvoicingExternal: false,
    invoiceExternalLineItems: [],
    invoiceLineItemsJobIdsExternal: [],
    linkedContactToExternalService: false,
    invoiceContactCompanyName: '',
    anyLineItemInvalid: true,
    invoiceGenerationInProgressExternal: false,
    invoiceSendExternal: false,
    invoiceExternalLineItemSearchText: [],
    invoiceStatusEnum: {
        draft: 0,
        open: 1,
        paid: 2,
        void: 3
    },
    invoiceStatusTextEnum: {
        0: "Draft",
        1: "Open",
        2: "Paid",
        3: "Void"
    },
    totalExternalInvoicesCount: ConstantValues.defaultPageTotalRowCount,
    invoicesListExternalOffset: ConstantValues.defaultPageOffsetSize,
    invoicesListExternalLimit: ConstantValues.defaultPageLimitSize,
    noExternalListData: false,
    invoicesExternalListCount: ConstantValues.defaultPageListCount,
    invoiceDraftPreviewDetailsExternalLineItems: [],
    invoicesExternalShowAddLineItemView: [],
    invoicesExternalShowAddLineItemButton: true,
    invoicesExternalInitialLineItem: {
        "item": {
            "id": null,
            "title": null,
            "description": null,
            "unitPrice": 0,
            "taxRate": {
                "id": null,
                "title": null,
                "rate": 0
            },
            "chartOfAccount": {
                "id": null,
                "title": null
            }
        },
        "quantity": 0,
        "unit": null,
        "description": "",
        "reference": "",
        "date": ""
    },
    fetchingLineItems: false,
    externalContactRadioGroup: 1,
    invoiceDateFilterApplied: false,
    tempJobId: null,
    customerForInvoicesList: null,
    customersFilterListForInvoicesList: [],
    customersFilterSearchText: null,
    customersListForInvoiceExternalSize: 0,
    customersListForExternalInvoiceLoader: false,
    noData: false,
    listCount: ConstantValues.defaultPageListCount,
    totalRowCount: ConstantValues.defaultPageTotalRowCount,
    offset: ConstantValues.defaultPageOffsetSize,
    limit: ConstantValues.defaultPageLimitSize,
    supplierDetailsForExternalInvoice:  {
        name: null,
        emailAddress: null,
        phoneNumber: null,
        address: new Address()
    },
    customerDetailsForExternalInvoice: {
        name: null,
        emailAddress: null,
        phoneNumber: null,
        address: new Address()
    }
}

export const getters = {
    customerBusinessProfileForInvoiceExternal: state => state.customerBusinessProfileForInvoiceExternal,
    customersListForInvoiceExternal: state => state.customersListForInvoiceExternal,
    customersSearchTextExternal: state => state.customersSearchTextExternal,
    invoiceLoaderExternal: state => state.invoiceLoaderExternal,
    selectedCustomerIdForInvoiceExternal: state => state.selectedCustomerIdForInvoiceExternal,
    jobsListForInvoiceExternal: state => state.jobsListForInvoiceExternal,
    selectedJobsForInvoiceExternal: state => state.selectedJobsForInvoiceExternal,
    dueDateForInvoiceExternal: state => state.dueDateForInvoiceExternal,
    invoiceDraftPreviewDetailsExternal: state => state.invoiceDraftPreviewDetailsExternal,
    invoicesListExternal: state => state.invoicesListExternal,
    invoiceSearchTextExternal: state => state.invoiceSearchTextExternal,
    jobsForInvoiceSearchTextExternal: state => state.jobsForInvoiceSearchTextExternal,
    draftedInvoiceIdToOpenExternal: state => state.draftedInvoiceIdToOpenExternal,
    invoiceSelectedTabExternal: state => state.invoiceSelectedTabExternal,
    invoiceDateExternal: state => state.invoiceDateExternal,
    invoiceLineItemJobNumbersDistinctExternal: state => state.invoiceLineItemJobNumbersDistinctExternal,
    enableExternalInvoicingExternal: state => state.enableExternalInvoicingExternal,
    invoiceExternalLineItems: state => state.invoiceExternalLineItems,
    invoiceLineItemsJobIdsExternal: state => state.invoiceLineItemsJobIdsExternal,
    linkedContactToExternalService: state => state.linkedContactToExternalService,
    invoiceContactCompanyName: state => state.invoiceContactCompanyName,
    anyLineItemInvalid: state => state.anyLineItemInvalid,
    invoiceGenerationInProgressExternal: state => state.invoiceGenerationInProgressExternal,
    invoiceSendExternal: state => state.invoiceSendExternal,
    invoiceExternalLineItemSearchText: state => state.invoiceExternalLineItemSearchText,
    invoiceStatusEnum: state => state.invoiceStatusEnum,
    invoiceStatusTextEnum: state => state.invoiceStatusTextEnum,
    totalExternalInvoicesCount: state => state.totalExternalInvoicesCount,
    invoicesListExternalOffset: state => state.invoicesListExternalOffset,
    invoicesListExternalLimit: state => state.invoicesListExternalLimit,
    noExternalListData: state => state.noExternalListData,
    invoicesExternalListCount: state => state.invoicesExternalListCount,
    invoicesExternalShowAddLineItemView: state => state.invoicesExternalShowAddLineItemView,
    invoicesExternalShowAddLineItemButton: state => state.invoicesExternalShowAddLineItemButton,
    invoicesExternalInitialLineItem: state => state.invoicesExternalInitialLineItem,
    invoiceDraftPreviewDetailsExternalLineItems: state => state.invoiceDraftPreviewDetailsExternalLineItems,
    fetchingLineItems: state => state.fetchingLineItems,
    externalContactRadioGroup: state => state.externalContactRadioGroup,
    invoiceDateFilterApplied: state => state.invoiceDateFilterApplied,
    tempJobId: state => state.tempJobId,
    customerForInvoicesList: state => state.customerForInvoicesList,
    customersFilterListForInvoicesList: state => state.customersFilterListForInvoicesList,
    customersFilterSearchText: state => state.customersFilterSearchText,
    customersListForInvoiceExternalSize: state => state.customersListForInvoiceExternalSize,
    customersListForExternalInvoiceLoader: state =>  state.customersListForExternalInvoiceLoader,
    supplierDetailsForExternalInvoice: state => state.supplierDetailsForExternalInvoice,
    customerDetailsForExternalInvoice: state => state.customerDetailsForExternalInvoice,
}

export const mutations = {
    setCustomersListForInvoiceExternal(state, data) {
        data.map(customer => state.customersListForInvoiceExternal.push(customer));
        state.customersListForInvoiceExternal.map(customer => {
            if(customer.businessProfile && customer.businessProfile.title){
                customer['displayName'] = customer.businessProfile.title;
            } else {
                customer['displayName'] = customer.firstName;
            }
        });
    },
    
    setJobsListForInvoiceExternal(state, data) {
        data.map(job => state.jobsListForInvoiceExternal.push(job));
    },

    setInvoicesListExternal(state, data) {
        data.map(invoice => {
            state.invoicesListExternal.push(invoice);
        });
    },

    setInvoicePreviewDetailsExternal(state, data) {
        state.invoiceDraftPreviewDetailsExternal = data;
        state.invoiceDraftPreviewDetailsExternalLineItems = data.lineItems;
        state.invoiceLineItemsJobIdsExternal = data.jobIds;
        if (state.invoiceDraftPreviewDetailsExternalLineItems.length > 0) {
            for(let i = 0; i < state.invoiceDraftPreviewDetailsExternalLineItems.length; i++) {
                if(state.invoiceDraftPreviewDetailsExternalLineItems[i].item == null){
                    state.invoiceDraftPreviewDetailsExternalLineItems[i].item = {
                        id: null,
                        title: null,
                        description: null,
                        unitPrice: 0,
                        taxRate: {
                            id: null,
                            title: null,
                            rate: 0
                        },
                        chartOfAccount: {
                            id: null,
                            title: null
                        }
                    }
                }
            }
            state.invoiceLineItemJobNumbersDistinctExternal = [...new Set(state.invoiceDraftPreviewDetailsExternalLineItems.map(item => item.reference))];
            state.invoicesExternalShowAddLineItemView = []
            state.invoiceLineItemJobNumbersDistinctExternal.forEach(() => { 
                state.invoicesExternalShowAddLineItemView.push(false)
            })
        }
        if (data.dueDate != null) {
            state.invoiceDateExternal = moment.utc(data.dueDate).local().format('YYYY-MM-DD');
        }
        state.draftedInvoiceIdToOpenExternal = state.invoiceDraftPreviewDetailsExternal.id;
    },

    setInvoiceExternalLineItems(state, data) {
        const externalLineItems = data.value
        if (Array.isArray(externalLineItems) && externalLineItems.length) {
            externalLineItems.forEach(lineItem => {
                state.invoiceExternalLineItems.push(lineItem)
            })
        }
    },

    setLinkedContactInfo(state, data) {
        if (data) {
            if (state.invoiceDraftPreviewDetailsExternal) {
                state.invoiceDraftPreviewDetailsExternal.contactId =  data.id;
                state.invoiceDraftPreviewDetailsExternal.contactName = data.name;
            }
            state.invoiceContactCompanyName = data.companyName ? data.companyName : (data.name ? data.name : '');
        }
    },

    setClearInvoiceDataExternal(state) {
        state.customersListForInvoiceExternal = [];
        state.customersSearchTextExternal = '';
        state.jobsForInvoiceSearchTextExternal = '';
        state.invoiceLoaderExternal = false;
        state.selectedCustomerIdForInvoiceExternal = null;
        state.jobsListForInvoiceExternal = [];
        state.selectedJobsForInvoiceExternal = [];
        state.dueDateForInvoiceExternal = null;
        state.invoiceDraftPreviewDetailsExternal = null;
        state.invoiceSearchTextExternal = '';
        state.draftedInvoiceIdToOpenExternal = null;
        state.invoiceSelectedTabExternal = 1;
        state.selectedCustomerIdForInvoiceSendXeroExternal = null;
        state.selectedCustomerIdForInvoiceSendQuickBooksExternal = null;
        state.invoiceLineItemJobNumbersDistinctExternal = [];
        state.invoiceLineItemsJobIdsExternal = [];
        state.invoiceSendExternal = false;
    },
    
    setNoExternalListDataStatus(state, data) {
        state.noExternalListData = data != null && data.length == 0
        state.noData = data?.length == 0
    },

    setCustomersListFilterForInvoices(state, data) {
        function pushAllOptionToCustomersList() {
            state.customersFilterListForInvoicesList.push({
                businessName: 'All',
                id: null
            })
        }

        function pushCustomerToCustomersList(customer) {
            state.customersFilterListForInvoicesList.push({
                id: customer.id,
                isInternal: customer.isInternal,
                firstName: customer.firstName,
                businessName: customer.businessProfile ? customer.businessProfile.title : '',
                email: customer.email,
            })
        }

        if((state.customersFilterListForInvoicesList.length == 0) || 
            (state.customersFilterListForInvoicesList && state.customersFilterListForInvoicesList.filter(cus => cus.id == null).length == 0)) {
            pushAllOptionToCustomersList()
        }

        const customers = data
        customers.forEach(customer => {
            pushCustomerToCustomersList(customer)
        })
    },

    setSupplierDetailsForExternalInvoice(state, data) {
        state.supplierDetailsForExternalInvoice.name = data.businessProfile.title ? data.businessProfile.title : '';
        state.supplierDetailsForExternalInvoice.emailAddress = data.email
        state.supplierDetailsForExternalInvoice.phoneNumber = data.primaryContactNumber
        if(data.businessProfile != null) {
            state.supplierDetailsForExternalInvoice.address = new Address().setResponseAddress(data.businessProfile.address)
        }
    },

    setCustomerDetailsForExternalInvoice(state, data) {
        state.customerDetailsForExternalInvoice.name = data.businessProfile.title ? data.businessProfile.title : '';
        state.customerDetailsForExternalInvoice.emailAddress = data.email
        state.customerDetailsForExternalInvoice.phoneNumber = data.primaryContactNumber
        if(data.businessProfile != null) {
            state.customerDetailsForExternalInvoice.address = new Address().setResponseAddress(data.businessProfile.address)
        }
    }
}