














export default {
    name: 'ButtonMiniPrimaryDark',
    props: [
        'textValue',
        'width',
        'height',
        'disabled',
        'loading',
    ]
}
