import ConstantValues from '@/plugins/constantValues';
import { apiDefaultTaxesName, apiDefaultTaxesRate, apiTaxes } from '@/utils/endpoints';
import { changeInfoWindowTheme } from '@/utils/helpers/gmaps-helpers';
import requests from '@/utils/requests';
import BuildUrl from 'build-url';
import loadGoogleMapsApi from 'load-google-maps-api';

export const actions = {
    async changeInfoWindowTheme() {
        changeInfoWindowTheme('#onboard-map', 'dark')
    },
    async getGoogleMapToSelectDefaultLocation({ rootState, state, commit, dispatch }) {
        const _usersModule = rootState.usersModule
        state.polygonShape = null
        state.polyPath = null
        state.onboardMap = null

        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(async () => {
            const mapZoom = ConstantValues.defaultMapZoom
            const { google } = window
            const mapOptions = {
                zoom: 16,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": ConstantValues.defaultBusinessLatitude, "lng": ConstantValues.defaultBusinessLongitude }),
                mapTypeControl: true,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                rotateControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.onboardMap = new google.maps.Map(document.getElementById('onboard-map'), mapOptions)

            state.mapLocationInfoWindow = new google.maps.InfoWindow()

            if (_usersModule.startingLat && _usersModule.startingLng) {
                state.startingUserLatLngOnMap = JSON.stringify({
                    lat: _usersModule.startingLat,
                    lng: _usersModule.startingLng
                }, null, 2)
                if (state.mapLocationInfoWindow) {
                    state.mapLocationInfoWindow.setPosition(new google.maps.LatLng(_usersModule.startingLat, _usersModule.startingLng))
                    state.mapLocationInfoWindow.setContent(_usersModule.startingLat.toFixed(4).toString() + ', ' + _usersModule.startingLng.toFixed(4).toString())

                    state.mapLocationInfoWindow.open(state.onboardMap)
                }
            } else {
                if (state.mapLocationInfoWindow) {
                    state.mapLocationInfoWindow.setPosition(new google.maps.LatLng(ConstantValues.defaultBusinessLatitude, ConstantValues.defaultBusinessLongitude))
                    state.mapLocationInfoWindow.setContent(ConstantValues.defaultBusinessLatitude.toFixed(4).toString() + ', ' + ConstantValues.defaultBusinessLongitude.toFixed(4).toString())

                    state.mapLocationInfoWindow.open(state.onboardMap)
                }
            }

            setTimeout(() => {
                dispatch('changeInfoWindowTheme')
            }, 200)

            state.onboardMap.addListener("click", (mapsMouseEvent) => {
                state.startingUserLatLngOnMap = null
                state.startingUserLatLngOnMap = JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)

                _usersModule.startingLat = mapsMouseEvent.latLng.toJSON().lat
                _usersModule.startingLng = mapsMouseEvent.latLng.toJSON().lng

                state.mapLocationInfoWindow.close()

                state.mapLocationInfoWindow.setPosition(mapsMouseEvent.latLng)
                const latLngString = _usersModule.startingLat.toFixed(4).toString().trim() + ', ' + _usersModule.startingLng.toFixed(4).toString().trim()
                state.mapLocationInfoWindow.setContent(latLngString)

                state.mapLocationInfoWindow.open(state.onboardMap)
                state.onboardMapSearchValue = latLngString

                dispatch('changeInfoWindowTheme')
            })

        })
    },

    async clearMapLocationInfoWindow({ state }) {
        state.mapLocationInfoWindow.setMap(null)
    },

    async requestGooglePlacesSearch({ state, dispatch }) {
        let searchedLocation = null;
        const request = {
            query: state.onboardMapSearchValue,
            fields: ['name', 'geometry'],
        }
        const service = new google.maps.places.PlacesService(state.onboardMap);
        await service.findPlaceFromQuery(request, async (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                state.onboardMap.setCenter(results[0].geometry.location)
                searchedLocation = results[0].geometry.location
                await dispatch('setMarkerOnPlacesResponse', searchedLocation)

                return searchedLocation
            }
        })
    },

    async setMarkerOnPlacesResponse({ state, rootState, dispatch }, location) {
        const _usersModule = rootState.usersModule
        _usersModule.startingLat = location.lat()
        _usersModule.startingLng = location.lng()

        state.mapLocationInfoWindow.close()

        state.mapLocationInfoWindow.setPosition(location)

        state.mapLocationInfoWindow.open(state.onboardMap)
        dispatch('changeInfoWindowTheme')
    },

    async navigateToCoordinates({ state, dispatch, rootState }, coordinates) {
        const _usersModule = rootState.usersModule

        if (coordinates && coordinates.length == 2) {
            const latitude = coordinates[0]
            const longitude = coordinates[1]
            state.startingUserLatLngOnMap = null

            const newCoordinates = new google.maps.LatLng(latitude, longitude)

            state.startingUserLatLngOnMap = JSON.stringify(newCoordinates.toJSON(), null, 2)

            _usersModule.startingLat = latitude
            _usersModule.startingLng = longitude

            const latLngString = ''.concat(latitude.trim(), ', ', longitude.trim())

            state.mapLocationInfoWindow.close()

            state.mapLocationInfoWindow.setPosition(newCoordinates)
            state.mapLocationInfoWindow.setContent(latLngString)
            state.onboardMapSearchValue = latLngString

            state.onboardMap.setCenter(newCoordinates)
            state.onboardMap.setZoom(ConstantValues.defaultMapZoom)
            state.mapLocationInfoWindow.open(state.onboardMap)
            dispatch('changeInfoWindowTheme')
        }
    },

    async getCountryTaxesDefaultName({rootState}, name){
        const url = BuildUrl(rootState.baseUrl, {
            path: apiTaxes + "/" + name + apiDefaultTaxesName
        });
        const result = await requests.getData(url);
        if (result?.data) {
           return result.data;
        }
    },
    async getCountryTaxRatesList({rootState}, name){
        const url = BuildUrl(rootState.baseUrl, {
            path: apiTaxes + "/" + name + apiDefaultTaxesRate
        });
        const result = await requests.getData(url);
        if (result?.data) {
           return result.data;
        }
    }
}