

















































































































































































































































import $ from "jquery";
import { clearDateTimePicker, getDateDifferenceInDays, getDateDifferenceInMinutes, openOverlay } from "@/utils/helpers";
import { mapMutations } from "vuex";
import scrollerMixin from "@/mixins/scroller-mixin";
import businessesComputedMixin from "./su-businesses-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { listAction } from '@/enum/listAction';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/search-timers';
import { getDateTimeFormat, getDateTimeFormattedLocal } from '@/utils/helpers';
import Avatar from '@/components/shared/avatars/avatar.vue';
import { SubscriptionStatusType, SubscriptionStatusTypeLabel } from '@/enum/subscriptionStatusType';
import { apiAdminBusinesses, routeBusinesses } from '@/utils/endpoints';
import router from '@/router';
import { ExcelExportModuleType } from '@/enum/excelExportModuleType';
import { excelParser } from "@/utils/helpers/excel-parser-helpers";
import { getCancellationDate, getSusbcriptionAmountIfExists, getTrialDaysLeft } from '@/utils/helpers/subscription-helper';

export default {
    name: "UsersListView",
    data() {
        return {
            SubscriptionStatusType,
            showExportDialog: false,
            totalRowCountTemp: null,
        }
    },
    mixins: [
        scrollerMixin,
        businessesComputedMixin,
        Timers
    ],
    components: {
        Button,
        List,
        Avatar,
    },
    beforeMount() {
        this.businesses = []
        this.resetPaging()
        this.clearAllFilterStates()
    },
    beforeDestroy() {
        this.resetPaging()
        this.clearBusinessesFilterOptions()
    },
    mounted() {
        this.getBusinessesList();
    },
    methods: {
        ...mapMutations({
            clearBusinessesFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging",
            resetUsersListFilter: "resetUsersListFilter",
        }),
        
        boldIfTrialTimeRemaining(trialEndDateTime) {
            if(trialEndDateTime && parseInt(getDateDifferenceInMinutes(trialEndDateTime)) > 0) {
                return 'fw-6'
            }
            return ''
        },

        getDateTimeFormat(dateTime) {
            return getDateTimeFormat(dateTime)
        },

        getDateTimeFormattedLocal(dateTime) {
            return getDateTimeFormattedLocal(dateTime)
        },
        
        openBusinessesFilterForm: function () {
            this.businessesListFiltersApplied = false
            openOverlay("businessesFilterFormOverlay")
        },
        clearAllFilterStates() {
            this.clearBusinessesFilterOptions()
            this.clearFilterStates('businessCountry')
            this.clearFilterStates('businessType')
            this.clearFilterStates('businessSubscriptionStatus')
            this.clearFilterStates('dateRange')
        },
        clearFilterStates(filterType) {
            switch(filterType) {
                case 'businessCountry': this.businessCountry = null; break;
                case 'businessType': this.businessType = null; break;
                case 'businessSubscriptionStatus': this.businessSubscriptionStatus = null; break;
                case 'dateRange': this.businessesStartDate = this.businessesEndDate = null; 
                                  $('.vdpr-datepicker input[type=text]').first().val('');
                                  $('.vdpr-datepicker input').first().css({ width: "110px" });
                                  clearDateTimePicker(document.querySelector('.vdpr-datepicker'));
                                  this.businessesStartDate = this.businessesEndDate = null
                                  break;
                default: return
            }
            this.applyBusinessesFilter()
        },
        applyBusinessesFilter() {
            this.clearBusinessesFilterOptions()
            this.resetPaging()
            this.businesses = []
            this.businessesListFiltersApplied = true
            this.getBusinessesList()
        },
        clearAndResetUsersPagingStates() {
            $(window).unbind("scroll")
            this.$store.state.suBusinessesModule.businesses = []
            this.clearBusinessesFilterOptions()
            this.resetPaging()
            this.businessesListFiltersApplied = false
        },
        getBusinessesList(filter = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            this.$store.dispatch('setBusinessesListFilter', filter).then(() => {
                this.$store.dispatch('getBusinessesList')
            })
        },
        setFilterOptionsAndGetBusinesses(emittedFilters) {
            if (!this.businessesSearchText) {
                this.clearBusinessesFilterOptions()
                this.businessesListFiltersApplied = false
            }
            this.getBusinessesList(emittedFilters)
        },
        getBusinessType(businessType) {
            if(businessType !== undefined && this.businessTypesList.length > 0) {
                return this.businessTypesList.find(b => b.value == businessType)?.title
            }
        },
        getSubscriptionStatusType(statusType) {
            if(statusType !== undefined && this.businessSubscriptionStatusTypes.length > 0) {
                return this.businessSubscriptionStatusTypes.find(b => b.value == statusType)?.title
            }
        },
        getSubscriptionStatusEnum(status) {
            return status !== null ? SubscriptionStatusTypeLabel.get(status) : '-'
        },
        getDifferenceInDays(date) {
            return parseInt(getDateDifferenceInDays(date))
        },

        getTrialDaysLeft(trialEndDate) { return getTrialDaysLeft(trialEndDate) },

        getSusbcriptionAmountIfExists(subscription) { return getSusbcriptionAmountIfExists(subscription, true) },

        getCancellationDate(subscriptions) { return getCancellationDate(subscriptions) },

        openBusinessDetailsView: function (user) {
            router.push(routeBusinesses + '/' + user.id);
        },

        getCancellationReason(subscriptions) {
            if(subscriptions && subscriptions.cancellationReason) {
                return subscriptions.cancellationReason
            }
            return ''
        },
        initiateBusinessesExport() {
            this.totalRowCountTemp = this.totalRowCount
            this.$store.commit('resetExportData')
            this.clearBusinessesFilterOptions()
            this.resetPaging()
            this.showExportDialog = true
            this.getBusinesseswDataForDownload()
        },
        getBusinesseswDataForDownload() {
            const exportFilters: any = []
            
            if(this.businessesSearchText) {
                exportFilters.push({ searchText: this.businessesSearchText })
            }
            if(this.businessCountry) {
                exportFilters.push({ country: this.businessCountry })
            }
            if(this.businessType !== null) {
                exportFilters.push({ businessType: this.businessType })
            }
            if(this.businessSubscriptionStatus !== null) {
                exportFilters.push({ status: this.businessSubscriptionStatus })
            }
            if(this.businessesStartDate !== null) {
                exportFilters.push({ start: this.businessesStartDate })
            }
            if(this.businessesEndDate !== null) {
                exportFilters.push({ end: this.businessesEndDate })
            }

            this.$store.dispatch("setExportDataParameterFilters", exportFilters).then(() => {
                this.$store.dispatch("getDataListForExport", {
                    apiUrl: apiAdminBusinesses,
                    moduleType: ExcelExportModuleType.Businesses
                }).then(() => {
                    this.getMoreBusinessesForExport();
                });
            });
        },
        resetPagingAndList() {
            this.resetPaging()
            this.clearBusinessesFilterOptions()
            this.resetUsersListFilter()
            this.totalRowCount = this.totalRowCountTemp
            this.businesses = []
            this.getBusinessesList()
        },
        getMoreBusinessesForExport() {
            this.$store.dispatch("getDataListForExport", {
                apiUrl: apiAdminBusinesses,
                moduleType: ExcelExportModuleType.Businesses
            }).then(() => {
                if (this.exportDataLoaded < this.exportDataTotal) {
                    this.getMoreBusinessesForExport();
                } else {
                    if (this.exportData.length > 0) {
                        this.showExportDialog = false
                        excelParser().saveDataToExcel()
                        this.resetPagingAndList()
                    }
                }
            });
        },
    },
};
