






export default {
    name: 'ButtonLoadMore',
    props: [
        'disabled', 
        'loading', 
    ]
}
