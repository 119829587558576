










































import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { closeOverlay, checkFieldsValidity } from '@/utils/helpers';
import { mapMutations, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email, alpha } from 'vuelidate/lib/validators';
import contractorsComputedMixin from '@/components/contractors/contractors-computed';
import {BaseButton as Button} from '@/components/shared/buttons/index';
import { UserRoleType } from '@/enum/userRoleType';

export default {
    name: 'ContractorAddForm',
    mixins: [validationMixin, contractorsComputedMixin],
    validations: {
        userInviteeEmail: {
            required,
            email
        },
    },
    components: {
       Button
    },
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
            fieldsInvalid: true
        }
    },
    computed: {
        isFieldsInvalid() {
            if (checkFieldsValidity([
                this.userInviteeEmail
            ]) && !this.$v.userInviteeEmail.$invalid) {
                return false
            } else {
                return true
            }
        }
    },
    mounted() {
        this.setClearInviteeProfile();
    },
    methods: {
        ...mapMutations({
            resetPaging: 'resetPaging',
            setClearFilterOptions: 'setClearFilterOptions',
            setClearInviteeProfile: 'setClearInviteeProfile'
        }),
        ...mapActions({
            sendInvite: 'sendUserInvite'
        }),
        proceedContractorInvitation() {
            const newContractorInviteeObj = {
                inviteeEmail: this.userInviteeEmail,
                inviteeRole: UserRoleType.Contractor
            };
            this.sendInvite(newContractorInviteeObj).then((res) => {
                if (res) {
                    if (this.contractorFilterType == 1) {
                        this.refreshPendingContractorList();
                    }
                    closeOverlay('addContractorFormOverlay');
                }
            });

        },
        generateValidationErrors() {
            let errors: any = [];
            if (!this.$v.userInviteeEmail.$dirty) return errors
            if (!this.$v.userInviteeEmail.required) {
                errors.push(this.$stringConstants('userRequiredEmailAddress'))
                this.fieldsInvalid = true;
            }
            else if (!this.$v.userInviteeEmail.email) {
                errors.push(this.$stringConstants('userFormatEmailAddress'))
                this.fieldsInvalid = true;
            } else {
                this.fieldsInvalid = false;
            }
            return errors;
        },
        closeContractorAddForm() {
            closeOverlay("addContractorFormOverlay");
            this.resetContractorSearch();
            this.$v.$reset();
        }
    }
}
