export const state = {
    operationsLoader: false,
    operationTypesList: [],
    addOperationTypesList: [],
    requestOperationTypesList: [],
    operationsList: [],
    operationSearchText: '',
    selectedSubOperationId: null,
    selectedSubOperationName: null,
    showAddOperationFields: false,
    showAddLoadInfoFields: false,
    showAddCheckListItemFields: false,
    showAddOperationBtn: true,
    showAddLoadInfoBtn: true,
    showAddCheckListItemBtn: true,
    subOperationTypeId: '',
    subOperationName: '',
    subOperationBillingCost: '',
    subOperationBillingUnit: null,
    subOperationTaxPercentage: 0.00,
    subOperationIsTaxable: false,
    subOperationVehicles: [],
    subOperationImplements: [],
    subOperationProducts: [],
    subOperationLoadInfoList: [],
    subOperationDescription: '',
    subOperationCheckListItems: [],
    subOperationVehiclesSearchText: '',
    subOperationImplementsSearchText: '',
    subOperationProductsSearchText: '',
    subOperationVehiclesList: [],
    subOperationImplementsList: [],
    subOperationProductsList: [],
    subOperationIsHealthSafetyListMandatory: false,
    addSubOperationLoader: false,
    editSubOperationLoader: false,
    subOperationLoader: false,
    seedOperationsLoader: false,
    subOperationVehiclesDropdownLoader: false,
    subOperationImplementsDropdownLoader: false,
    subOperationProductsDropdownLoader: false,
    subOperationVehiclesListSize: 0,
    subOperationImplementsListSize: 0,
    subOperationProductsListSize: 0,
    subOperationTaxWarningVisible: false,
}

export const getters = {
    operationsLoader: state => state.operationsLoader,
    operationTypesList: state => state.operationTypesList,
    operationsList: state => state.operationsList,
    operationSearchText: state => state.operationSearchText,
    operationsListColumnTitles: state => state.operationsListColumnTitles,
    selectedSubOperationId: state => state.selectedSubOperationId,
    subOperationTypeId: state => state.subOperationTypeId,
    selectedSubOperationName: state => state.selectedSubOperationName,
    showAddOperationFields: state => state.showAddOperationFields,
    showAddLoadInfoFields: state => state.showAddLoadInfoFields,
    showAddCheckListItemFields: state => state.showAddCheckListItemFields,
    showAddOperationBtn: state => state.showAddOperationBtn,
    showAddLoadInfoBtn: state => state.showAddLoadInfoBtn,
    showAddCheckListItemBtn: state => state.showAddCheckListItemBtn,
    addOperationTypesList: state => state.addOperationTypesList,
    requestOperationTypesList: state => state.requestOperationTypesList,
    subOperationName: state => state.subOperationName,
    subOperationBillingCost: state => state.subOperationBillingCost,
    subOperationBillingUnit: state => state.subOperationBillingUnit,
    subOperationTaxPercentage: state => state.subOperationTaxPercentage,
    subOperationIsTaxable: state => state.subOperationIsTaxable,
    subOperationVehicles: state => state.subOperationVehicles,
    subOperationImplements: state => state.subOperationImplements,
    subOperationProducts: state => state.subOperationProducts,
    subOperationLoadInfoList: state => state.subOperationLoadInfoList,
    subOperationDescription: state => state.subOperationDescription,
    subOperationCheckListItems: state => state.subOperationCheckListItems,
    subOperationVehiclesSearchText: state => state.subOperationVehiclesSearchText,
    subOperationImplementsSearchText: state => state.subOperationImplementsSearchText,
    subOperationProductsSearchText: state => state.subOperationProductsSearchText,
    subOperationVehiclesList: state => state.subOperationVehiclesList,
    subOperationImplementsList: state => state.subOperationImplementsList,
    subOperationProductsList: state => state.subOperationProductsList,
    subOperationIsHealthSafetyListMandatory: state => state.subOperationIsHealthSafetyListMandatory,
    addSubOperationLoader: state => state.addSubOperationLoader,
    editSubOperationLoader: state => state.editSubOperationLoader,
    subOperationLoader: state => state.subOperationLoader,
    seedOperationsLoader: state => state.seedOperationsLoader,
    subOperationVehiclesDropdownLoader: state => state.subOperationVehiclesDropdownLoader,
    subOperationImplementsDropdownLoader: state => state.subOperationImplementsDropdownLoader,
    subOperationProductsDropdownLoader: state => state.subOperationProductsDropdownLoader,
    subOperationVehiclesListSize: state => state.subOperationVehiclesListSize,
    subOperationImplementsListSize: state => state.subOperationImplementsListSize,
    subOperationProductsListSize: state => state.subOperationProductsListSize,
    subOperationTaxWarningVisible: state => state.subOperationTaxWarningVisible,
}

export const mutations = {
    setOperationTypesList(state, data) {
        state.operationTypesList = [];
        data.map((data) => state.operationTypesList.push(data));
    },
    setManageOperationsList(state, data) {
        state.requestOperationTypesList = [];
        data.map((data) => state.requestOperationTypesList.push({
            title: data.title,
            description: data.description,
            isSelected: data.isSelected,
            isActive: !data.isArchived,
            id: data.id,
            isSelectable: data.operations.length == 0,
            operations: data.operations
        }));
    },
    setOperationsList(state, data) {
        data.map((data) => state.operationsList.push(data));
    },
    setSubOperation(state, data) {
        state.selectedSubOperationId = data.id;
        state.subOperationTypeId = data.type.id;
        state.subOperationName = data.title;
        state.subOperationDescription = data.description;
        state.subOperationBillingCost = data.billingCost;
        state.subOperationBillingUnit = data.billingUnit;
        state.subOperationIsTaxable = data.isTaxable;
        state.subOperationTaxPercentage = data.taxRateInPercentage;
        state.subOperationIsHealthSafetyListMandatory = data.isHealthSafetyListMandatory;
        state.subOperationLoadInfoList = data.loadInformationList;
        state.subOperationCheckListItems = data.healthSafetyList;
        state.subOperationVehicles = data.vehicles.map((vehicle) => { return vehicle.id });
        state.subOperationVehiclesList = data.vehicles.map((vehicle) => { return { id: vehicle.id, title: vehicle.title + " ( " + vehicle.make + ", " + vehicle.model + " )" } });
        state.subOperationImplements = data.implements.map((implement) => { return implement.id });
        state.subOperationImplementsList = data.implements.map((implement) => { return { id: implement.id, title: implement.title + " ( " + implement.make + ", " + implement.model + " )" } });
        state.subOperationProducts = data.products.map((product) => { return product.id });
        state.subOperationProductsList = data.products.map((product) => { return { id: product.id, title: product.title } });
    },
    setSubOperationVehiclesList(state, data) {
        data.map((vehicle) => {
            state.subOperationVehiclesList.push({
                id: vehicle.id,
                title: vehicle.title + " ( " + vehicle.make + ", " + vehicle.model + " )"
            })
        });
    },
    setSubOperationImplementsList(state, data) {
        data.map((implement) => {
            state.subOperationImplementsList.push({
                id: implement.id,
                title: implement.title + " ( " + implement.make + ", " + implement.model + " )"
            })
        });
    },
    setSubOperationProductsList(state, data) {
        data.map((product) => {
            state.subOperationProductsList.push({
                id: product.id,
                title: product.title
            })
        });
    },
    setClearOperationTypesList(state) {
        state.operationTypesList = [];
    },
    setClearOperationsList(state) {
        state.operationsList = [];
    },
    setClearAddOperationTypesList(state) {
        state.addOperationTypesList = [];
        state.showAddOperationFields = false;
        state.showAddOperationBtn = true;
    },
    setClearSubOperationDetails(state) {
        state.selectedSubOperationId = null;
        state.selectedSubOperationName = null;
        state.showAddOperationFields = false;
        state.showAddLoadInfoFields = false;
        state.showAddCheckListItemFields = false;
        state.showAddOperationBtn = true;
        state.showAddCheckListItemBtn = true;
        state.showAddLoadInfoBtn = true;
        state.subOperationTypeId = '';
        state.subOperationName = '';
        state.subOperationBillingCost = '';
        state.subOperationBillingUnit = null;
        state.subOperationTaxPercentage = 0.00;
        state.subOperationIsTaxable = false;
        state.subOperationVehicles = [];
        state.subOperationImplements = [];
        state.subOperationProducts = [];
        state.subOperationLoadInfoList = [];
        state.subOperationDescription = '';
        state.subOperationCheckListItems = [];
        state.subOperationVehiclesSearchText = '';
        state.subOperationImplementsSearchText = '';
        state.subOperationProductsSearchText = '';
        state.subOperationVehiclesList = [];
        state.subOperationImplementsList = [];
        state.subOperationProductsList = [];
        state.subOperationIsHealthSafetyListMandatory = false;
        state.addSubOperationLoader = false;
        state.editSubOperationLoader = false;
        state.subOperationVehiclesDropdownLoader = false;
        state.subOperationImplementsDropdownLoader = false;
        state.subOperationProductsDropdownLoader = false;
        state.subOperationVehiclesListSize = 0;
        state.subOperationImplementsListSize = 0;
        state.subOperationProductsListSize = 0;
        state.subOperationTaxWarningVisible = false;
    }
}