









































import { loadStripe } from "@stripe/stripe-js";
import { notify } from "@/utils/helpers";
import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";

export default {
  data() {
    return {
      cardElement: null,
      stripeInstance: null
    };
  },
  mixins: [subscriptionsComputedMixin],
  watch: {
    showAddCardSubscriptionDialog(addCardDialogVisible) {
      if (addCardDialogVisible) {
        this.loadStripeConfig();
      }
    },
  },
  methods: {
    async loadStripeConfig() {
      this.stripeInstance = await loadStripe(this.stripePublishKey);
      const elements = this.stripeInstance.elements();
      this.cardElement = elements.create("card", {
        hidePostalCode: true,
      });
      this.cardElement.mount("#card-element");
    },
    async proceedCardSetup() {
      this.cardSourceToken = await this.stripeInstance
        .createToken(this.cardElement)
        .then((result) => {
          if (result.token) {
            const { token } = result;
            this.cardSourceToken = token.id;
            this.$store.dispatch("addCard");
          }
          if (result.error && result.error.message) {
            notify(result.error.message, "fail");
          }
        });
    },
    addCardPayment() {
      this.showAddCardSubscriptionDialog = false;
      this.proceedCardSetup();
    },
  },
};
