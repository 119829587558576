













import EllipsisMiniLoader from "./loaders/ellipsis-loader-mini.vue";

export default {
  name: "LoadMoreDropdown",
  props: ["moreData", "loadInProgress", "loadingListLength"],
  components: {
    EllipsisMiniLoader,
  }
};
