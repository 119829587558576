




























































































import TeamJobsComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-computed";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { DeleteItemDialog } from "@/components/shared/dialogs/index";
import { routeJobs } from "@/utils/endpoints";
import router from "@/router/index";
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import {BaseButton as SecondaryButton} from '@/components/shared/buttons/index';

export default {
    name: "TeamJobsCancelOrDelete",
    mixins: [TeamJobsComputed, TeamJobsCommon],
    components: {
        DeleteItemDialog,
        SecondaryButton
    },
    data() {
        return {
            dialogCancelWithReasonTeamJob: false,
            cancelTeamJobReason: "",
            TeamJobStatusType,
            dialogForceFinish: false,
            dialogDirectCancelJob: false,
            dialogDeleteJob: false
        }
    },

    methods: {
        proceedJobCancellation() {
            let cancelWithReason = false

            let isJobInternal = this.teamJobDetails.isInternal
            let isSelfJob = this.teamJobDetails.customer.id === this.teamJobDetails.contractor.id
            let jobCreatedByCustomerAssignedToContractor = (this.teamJobDetails.customer.id === this.teamJobDetails.creatorId) && !isSelfJob && !isJobInternal
            let jobCreatedByContractorAssignedToCustomer = (this.teamJobDetails.contractor.id === this.teamJobDetails.creatorId) && !isSelfJob && !isJobInternal

            if(getLoggedInUserRole().isUserOwnerFarmerLoggedIn && jobCreatedByContractorAssignedToCustomer) {
                cancelWithReason = true
            }

            if(getLoggedInUserRole().isUserOwnerContractorLoggedIn && jobCreatedByCustomerAssignedToContractor) {
                cancelWithReason = true
            }

            if (cancelWithReason) {
                this.dialogCancelWithReasonTeamJob = true
            }
            else {
                this.dialogDirectCancelJob = true
            }
        },

        closeJobCancelDialog() {
            this.cancelTeamJobReason = "";
            this.dialogCancelWithReasonTeamJob = false;
        },

        proceedToCancelTeamJob() {
            let obj = {
                resourceId: this.teamJobDetails.id,
                reason: {
                    reason: this.cancelTeamJobReason,
                },
            };
            this.$store.dispatch("cancelTeamJobByContractor", obj).then((response) => {
                this.closeJobCancelDialog();
                if (response) {
                    router.back();
                }
            });
        },

        cancelAddTeamJob() {
            router.push(routeJobs);
        },

        deleteJob() {
            this.dialogDeleteJob = false;
            this.$store.dispatch('deleteTeamJob', this.teamJobDetails.id).then((response) => {
                if (response) {
                    router.back();
                }
            })
        },
    },
};
