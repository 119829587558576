import { Product } from '@/data/models/Product';
import Vue from 'vue';

export const state = {
    productsList: new Array<Product>(),
    productDetails: new Product(),
    productLoader: false,
    productSearchText: '',
    addProductLoader: false,
    editProductLoader: false,
    productStatus: 0
}

export const getters = {
    productDetails: state => state.productDetails,
    productLoader: state => state.productLoader,
    productSearchText: state => state.productSearchText,
    productsList: state => state.productsList,
    addProductLoader: state => state.addProductLoader,
    editProductLoader: state => state.editProductLoader,
    productStatus: state => state.productStatus
}

export const mutations = {
    setProductstFilteredList(state, data) {
        data.forEach(x => state.productsList.push(new Product().SetData(x)));
    },
    setProductDetails(state, data: Product) {
        state.productDetails = new Product().SetData(data);
    },
    setAddProduct(state, data: Product) {
        state.productsList.unshift(data);
    },
    updateProduct(state, data: Product) {
        const index: number = state.productsList.findIndex((element, index) => {
            if (element.id == data.id) {
                return true;
            }
        });
        if (index >= 0) {
            Vue.set(state.productsList, index, new Product().SetData(data));
        }
    },
    resetProductDetails(state) {
        state.productDetails = new Product();
        state.editProductLoader = false;
    }
}