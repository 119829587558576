











































































































































































































































import { validationMixin } from "vuelidate";
import {
    required,
    minLength,
    maxLength,
    email,
    sameAs,
} from "vuelidate/lib/validators";
import {
    routeLogin,
    termsOfServiceURL,
    privacyPolicyURL,
} from "@/utils/endpoints";
import { notify, validateRegexPhoneNumber } from "../../utils/helpers";
import { ExternalIntegrationType } from "@/enum/externalIntegrationType";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import LoginRegistrationWrapper from "@/components/layouts/login-registration-wrapper.vue";
import { UserRoleType } from '@/enum/userRoleType';
import scriptsLoader from '@/mixins/scripts-loader';
import StringConstants from '@/plugins/stringConstants';
import router from '@/router';
import ConstantValues from '@/plugins/constantValues';

export default {
    mixins: [validationMixin, scriptsLoader],

    validations: {
      
        firstName: {
            required,
            minLength: minLength(ConstantValues.minlengthFirstName),
        },
       
        emailAddress: {
            required,
            email,
        },
        phoneNumber: {
            required,
            minLength: minLength(ConstantValues.minlengthPhoneNumber),
        },
        passWord: {
            required,
            uppercaseConstraint: (password) => /[A-Z]/.test(password),
            lowercaseConstraint: (password) => /[a-z]/.test(password),
            numericConstraint: (password) => /[0-9]/.test(password),
            minLength: minLength(ConstantValues.minlengthPassword),
            maxLength: maxLength(ConstantValues.maxlengthPassword),
        },
        confirmPassWord: {
            required,
            minLength: minLength(ConstantValues.minlengthPassword),
            sameAsPassword: sameAs(StringConstants.passWordField),
        },
        acceptTermsAndPrivacyPolicy: {
            required,
            sameAs: sameAs(() => true),
        },
    },
    components: {
        Button,
        LoginRegistrationWrapper,
    },
    data: function () {
        return {
            firstName: "",
            lastName: "",
            fullName: "",
            passWord: "",
            emailAddress: "",
            phoneNumber: "",
            companyName: "",
            confirmPassWord: "",
            roleSelection: 0,
            routeLogin,
            termsOfServiceURL,
            privacyPolicyURL,
            showRoleToggle: true,
            value: String,
            valueConfirm: String,
            nameRegexPattern: /\W/g,
            isUnclickable: false,
            validPasswordRequirements: [
                {
                    key: StringConstants.minLengthField,
                    status: null,
                    text: "At least 8 characters",
                },
                {
                    key: StringConstants.uppercaseConstraint,
                    status: null,
                    text: "At least 1 uppercase letter",
                },
                {
                    key: StringConstants.lowercaseConstraint,
                    status: null,
                    text: "At least 1 lowercase letter",
                },
                {
                    key: StringConstants.numericConstraint,
                    status: null,
                    text: "At least 1 number",
                },
            ],
            acceptTermsAndPrivacyPolicy: false,
            UserRoleType,
            enumRoleTitles: ['Contractor', 'Farmer', 'Manager', 'Team Lead', 'Operator'],
            connType: null,
            isEmailDisabled: false,
            ExternalIntegrationType,
        };
    },
   
    computed: {
        userSignUpRoles: {
            get() {
                return this.$store.getters.userSignUpRoles;
            },
        },
        invitationType: {
            get() {
                return this.$store.getters.invitationType;
            },
        },
        usersLoader: {
            get() {
                return this.$store.getters.usersLoader;
            },
            set(newVal) {
                this.$store.state.usersModule.usersLoader = newVal;
            },
        },
        showPasswordRequirementsTooltip: {
            get() {
                return this.passwordFieldIsFocused;
            },
            set(value) {
                this.passwordFieldIsFocused = value;
            },
        },
        isFormInvalid: {
            get() {
                return this.$v.$invalid;
            },
        },
    },
    mounted() {
        this.loadScripts();

        const urlParams = new URLSearchParams(window.location.search);
        const invitationCode = urlParams.get("code");
        const invitationType = urlParams.get("type");

        if (invitationCode != null && invitationType != null) {
            if (invitationType == this.invitationType["invitation"]) {
                this.$store
                    .dispatch("getInviteeProfile", invitationCode)
                    .then((result) => {
                        if (result) {
                            this.roleSelection = result.inviteeRole;
                            if (
                                !(
                                    this.roleSelection == UserRoleType.Contractor ||
                                    this.roleSelection == UserRoleType.Farmer
                                )
                            ) {
                                this.showRoleToggle = false;
                            } else {
                                this.isUnclickable = true;
                            }
                            this.emailAddress = result.inviteeEmail;
                        }
                    });
            }
        }

        this.checkAndInitializeExternalInvoicingSignup();
    },
    methods: {
        checkAndInitializeExternalInvoicingSignup() {
            const urlParams = new URLSearchParams(window.location.search);

            const email = urlParams.get("email");
            const name = urlParams.get("name");
            const connType = urlParams.get("connType") as any;

            if (email) {
                this.isEmailDisabled = true;
                this.emailAddress = email;
                this.firstName = name;
            }
            
            if (connType)
                this.connType =  Number(connType);

            if (connType == ExternalIntegrationType.Xero && !email && !name)
                this.$store.dispatch("initializeLoginWithExternalInvoicingService", ExternalIntegrationType.Xero);
        },
        firstNameKeydown(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },
        lastNameKeydown(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault();
            }
        },
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.proceedSignUp();
        },
        generateTermsAndPrivacyPolicyError() {
            let errors = [];
            if (!this.$v.acceptTermsAndPrivacyPolicy.$dirty) return errors;
            if (!this.$v.acceptTermsAndPrivacyPolicy.sameAs) {
                errors.push(this.$stringConstants('userRequiredTermsAndPrivacyCheckbox'));
            }
            return errors;
        },
        generateValidationErrors(
            propertyName: string,
            validationName1: string,
            validationName2: string,
            userError1: string,
            userError2: string
        ) {
            let errors: any = [];
            if (!this.$v[`${propertyName}`].$dirty) return errors;
            if (!this.$v[`${propertyName}`][`${validationName1}`]) {
                errors.push(userError1);
            } else if (!this.$v[`${propertyName}`][`${validationName2}`]) {
                errors.push(userError2);
            }
            return errors;
        },
        generateValidationPasswordErrors(
            propertyName: string,
            validationName1: string,
            validationName2: string,
            validationName3: string,
            validationName4: string,
            validationName5: string,
            validationName6: string,
            userError1: string,
            userError2: string,
            userError3: string,
            userError4: string,
            userError5: string,
            userError6: string
        ) {
            let errors: any = [];
            if (!this.$v[`${propertyName}`].$dirty) return errors;
            if (!this.$v[`${propertyName}`][`${validationName1}`]) {
                errors.push(userError1);
            }
            if (!this.$v[`${propertyName}`][`${validationName2}`]) {
                errors.push(userError2);
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName2
                        ? (requirement.status = false)
                        : null
                );
            } else {
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName2
                        ? (requirement.status = true)
                        : null
                );
            }
            if (!this.$v[`${propertyName}`][`${validationName3}`]) {
                errors.push(userError3);
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName3
                        ? (requirement.status = false)
                        : null
                );
            } else {
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName3
                        ? (requirement.status = true)
                        : null
                );
            }
            if (!this.$v[`${propertyName}`][`${validationName4}`]) {
                errors.push(userError4);
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName4
                        ? (requirement.status = false)
                        : null
                );
            } else {
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName4
                        ? (requirement.status = true)
                        : null
                );
            }
            if (!this.$v[`${propertyName}`][`${validationName5}`]) {
                errors.push(userError5);
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName5
                        ? (requirement.status = false)
                        : null
                );
            } else {
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName5
                        ? (requirement.status = true)
                        : null
                );
            }
            if (!this.$v[`${propertyName}`][`${validationName6}`]) {
                errors.push(userError6);
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName6
                        ? (requirement.status = false)
                        : null
                );
            } else {
                this.validPasswordRequirements.find((requirement) =>
                    requirement.key == validationName6
                        ? (requirement.status = true)
                        : null
                );
            }
            return errors;
        },
        handlePhoneNumberInput(event) {
            let phoneNumberIsValid = validateRegexPhoneNumber(event);
            if (phoneNumberIsValid) {
                this.$v.phoneNumber.$touch();
            }
        },
        handlePasswordFieldInput() {
            if (this.$v.passWord.$model.length > 0) {
                this.$v.passWord.$touch();
            } else {
                this.validPasswordRequirements.map(
                    (requirement) => (requirement.status = null)
                );
                this.$v.passWord.$reset();
            }
        },
        handlePasswordFieldBlur() {
            if (!this.$v.passWord.$invalid || this.$v.passWord.$model.length == 0) {
                this.showPasswordRequirementsTooltip = false;
            }
        },
        handlePasswordFieldFocus() {
            this.showPasswordRequirementsTooltip = true;
            if (this.$v.passWord.$model.length > 0) {
                this.$v.passWord.$touch();
            } else {
                this.$v.passWord.$reset();
            }
        },
        proceedSignUp() {
            const urlParams = new URLSearchParams(window.location.search);
            const invitationCode = urlParams.get("code");

            let createUserInfo = null;

            if (invitationCode != null) {
                createUserInfo = {
                    profile: {
                        firstName: this.firstName.trim(),
                        email: this.emailAddress,
                        primaryContactNumber: this.phoneNumber,
                    },
                    roles: [this.userSignUpRoles[this.roleSelection]],
                    password: this.passWord,
                    invitationCode: invitationCode,
                };
            } else {
                createUserInfo = {
                    profile: {
                        firstName: this.firstName.trim(),
                        email: this.emailAddress,
                        primaryContactNumber: this.phoneNumber,
                    },
                    roles: [this.userSignUpRoles[this.roleSelection]],
                    password: this.passWord,
                };
            }

            if (this.emailAddress) 
                createUserInfo['externalConnectionType'] = this.connType;

            this.$store.dispatch("signUpUser", createUserInfo).then((response) => {
                if (!response) return;

                
                const adminRoleTypes = [UserRoleType.Contractor, UserRoleType.Farmer, UserRoleType.Manager];
                
                if (adminRoleTypes.includes(response?.roles[0]?.type)) {
                    let formData = new FormData();
                    formData.append("username", this.emailAddress);
                    formData.append("password", this.passWord);
                    formData.append("clientType", ConstantValues.clientType);
                    this.$store.dispatch("login", formData);
                } else {
                    router.push(routeLogin);
                    notify(StringConstants.userOperatorSignUpSuccessMessage, 'success');
                }

                this.isEmailDisabled = false;
            });
        },
    },
};
