



























































































































































































































import SubsscriptionAddCardDialog from "@/components/subscriptions/subscriptions-add-card-dialog.vue";
import SubscriptionDefaultCardConfirmDialog from "@/components/subscriptions/subscriptions-default-card-confirm-dialog.vue";
import {
  formFieldPb,
  containerLeftPad,
  containerRightPad,
} from "@/utils/uiconstants";
import { mapMutations } from "vuex";
import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import { closeOverlay, notify } from "@/utils/helpers";
import { loadStripe } from "@stripe/stripe-js";
import { ButtonMiniTertiaryDark as SaveButton } from "@/components/shared/buttons";
import { DeleteItemDialog } from '@/components/shared/dialogs/index';

export default {
  data() {
    return {
      formFieldPb,
      containerLeftPad,
      containerRightPad,
      dialogDeleteCard: false,
      cardIdToDelete: null,
      cardElement: null,
      stripeInstance: null,
    };
  },
  components: {
    SubsscriptionAddCardDialog,
    SubscriptionDefaultCardConfirmDialog,
    SaveButton,
    DeleteItemDialog
  },
  mixins: [subscriptionsComputedMixin],
  mounted() {
    this.$store.dispatch("getStripePublishKey").then(() => {
      this.loadStripeConfig();
    });
  },
  methods: {
    ...mapMutations({
      setClearCardsList: "setClearCardsList",
    }),
    closeCardsForm() {
      closeOverlay("manageCardsFormOverlay");
    },
    setDefaultCard(card) {
      if (!card.isDefaultCard) {
        this.cardIdToSelect = card.id;
        this.cardBrandToSelect = card.brand;
        this.cardLastFourToSelect = card.last4;
        this.showDefaultCardConfirmDialog = true;
      }
    },

    proceedDeleteCard() {
      this.$store.dispatch("deleteCard", this.cardIdToDelete).then((res) => {
        this.$store.dispatch("getCardsList");
      });
      this.dialogDeleteCard = false;
    },
    removeSelectedCard(cardId) {
      if (cardId != null) {
        this.cardIdToDelete = cardId;
      }
      this.dialogDeleteCard = true;
    },
    cancelCardDeletion() {
      this.dialogDeleteCard = false;
      this.cardIdToDelete = null;
    },
    async loadStripeConfig() {
      this.stripeInstance = await loadStripe(this.stripePublishKey);
      const elements = this.stripeInstance.elements();
      this.cardElement = elements.create("card", {
        hidePostalCode: true,
      });
      this.cardElement.mount("#card-element");
      this.cardElement.on("change", (event) => {
        if (event.complete) {
          this.enableCardAddButton = true;
        } else if (event.error) {
          this.enableCardAddButton = false;
        }
      });
    },
    async proceedCardSetup() {
      this.cardSourceToken = await this.stripeInstance
        .createToken(this.cardElement)
        .then((result) => {
          if (result.token) {
            const { token } = result;
            this.cardSourceToken = token.id;
            this.$store.dispatch("addCard").then((res) => {
              this.cardElement.clear();
              this.enableCardAddButton = false;
            });
          }
          if (result.error && result.error.message) {
            notify(result.error.message, "fail");
          }
        });
    },
    addCardPayment() {
      this.cardsLoader = true;
      this.showAddCardSubscriptionDialog = false;
      this.proceedCardSetup();
    },
  },
};
