







































































































































































import $ from "jquery";
import { openOverlay, clearDateTimePicker } from "@/utils/helpers";
import { mapMutations } from "vuex";
import scrollerMixin from "@/mixins/scroller-mixin";
import usersComputedMixin from "./su-users-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { DeleteItemDialog } from "@/components/shared/dialogs/index";
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import Timers from '@/mixins/search-timers';
import { getDateTimeFormat, getDateTimeFormattedLocal } from '@/utils/helpers';
import Avatar from '@/components/shared/avatars/avatar.vue';
import { apiAdminUsers } from '@/utils/endpoints';
import { ExcelExportModuleType } from '@/enum/excelExportModuleType';
import { excelParser } from "@/utils/helpers/excel-parser-helpers";
import { ViewPreferenceType } from '@/enum/viewPreferenceType';

export default {
    name: "UsersListView",
    mixins: [
        scrollerMixin,
        usersComputedMixin,
        Timers
    ],
    data() {
        return {
            showExportDialog: false,
            totalRowCountTemp: null,
            showDeleteDialog: false,
            userDeleteId: null,
            userDeleteName: null,
        }
    },
    components: {
        Button,
        List,
        Avatar,
        DeleteItemDialog
    },
    beforeMount() {
        this.resetPagingAndFilters();
    },
    beforeDestroy() {
        this.resetPaging()
        this.clearUsersFilterOptions()
    },
    mounted() {
        this.getSuUsersList();
    },
    methods: {
        ...mapMutations({
            clearUsersFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging",
            resetUsersListFilter: "resetUsersListFilter",
        }),

        resetPagingAndFilters() {
            this.usersList = []
            this.resetPaging()
            this.clearUsersFilterOptions()
            this.clearFilterStates('userRole')
            this.clearFilterStates('dateRange')
        },
        getDateTimeFormat(dateTime) {
            return getDateTimeFormat(dateTime)
        },

        getDateTimeFormattedLocal(dateTime) {
            return getDateTimeFormattedLocal(dateTime)
        },
        
        openUsersFilterForm: function () {
            this.usersFiltersApplied = false
            openOverlay("usersFilterFormOverlay")
        },
        clearFilterStates(filterType) {
            switch(filterType) {
                case 'userRole': this.usersListRoles = null; break;
                case 'dateRange': 
                        this.usersListStartDate = this.usersListEndDate = null; 
                        $('.vdpr-datepicker input[type=text]').first().val('');
                        $('.vdpr-datepicker input').first().css({ width: "110px" });
                        clearDateTimePicker(document.querySelector('.vdpr-datepicker'));
                        this.usersListStartDate = this.usersListEndDate = null
                      break;
                default: return
            }
            this.applyUsersFilter()
        },
        applyUsersFilter() {
            this.clearUsersFilterOptions()
            this.resetPaging()
            this.usersList = []
            this.usersFiltersApplied = true
            this.getSuUsersList()
        },
        clearAndResetUsersPagingStates() {
            $(window).unbind("scroll")
            this.$store.state.suUsersModule.usersList = []
            this.clearUsersFilterOptions()
            this.resetPaging()
            this.usersFiltersApplied = false
        },
        getSuUsersList(filter = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            this.$store.dispatch('setSuUsersListFilter', filter).then(() => {
                this.$store.dispatch('getSuUsersList')
            })
        },
        setFilterOptionsAndGetUsers(emittedFilters) {
            if (!this.usersSearchText) {
                this.clearUsersFilterOptions()
                this.usersFiltersApplied = false
            }
            this.getSuUsersList(emittedFilters)
        },
        initiateUsersExport() {
            this.totalRowCountTemp = this.totalRowCount
            this.$store.commit('resetExportData')
            this.clearUsersFilterOptions()
            this.resetPaging()
            this.showExportDialog = true
            this.getUsersDataForDownload()
        },
        getUsersDataForDownload() {
            const exportFilters: any = []
            
            if(this.usersSearchText) {
                exportFilters.push({ searchText: this.usersSearchText })
            }
            if(this.usersListRoles) {
                exportFilters.push({ roles: this.usersListRoles.toLowerCase() })
            }
            if(this.usersListStartDate) {
                exportFilters.push({ start: this.usersListStartDate })
            }
            if(this.usersListEndDate) {
                exportFilters.push({ end: this.usersListEndDate })
            }

            exportFilters.push({ preference: ViewPreferenceType.All })

            this.$store.dispatch("setExportDataParameterFilters", exportFilters).then(() => {
                this.$store.dispatch("getDataListForExport", {
                    apiUrl: apiAdminUsers,
                    moduleType: ExcelExportModuleType.Users
                }).then(() => {
                    this.getMoreUsersForExport();
                });
            });
        },
        resetPagingAndList() {
            this.resetPaging()
            this.clearUsersFilterOptions()
            this.resetUsersListFilter()
            this.totalRowCount = this.totalRowCountTemp
            this.usersList = []
            this.getSuUsersList()
        },
        getMoreUsersForExport() {
            this.$store.dispatch("getDataListForExport", {
                apiUrl: apiAdminUsers,
                moduleType: ExcelExportModuleType.Users
            }).then(() => {
                if (this.exportDataLoaded < this.exportDataTotal) {
                    this.getMoreUsersForExport();
                } else {
                    if (this.exportData.length > 0) {
                        this.showExportDialog = false
                        excelParser().saveDataToExcel()
                        this.resetPagingAndList()
                    }
                }
            });
        },
        prmoptUserDelete(userId: string, userName: string) {
            this.userDeleteId = userId
            this.userDeleteName = userName
            this.showDeleteDialog = true
        },
        proceedUserDelete() {
          this.showDeleteDialog = false
          this.userDeleteName = null
            this.$store.dispatch('deleteSuUser', this.userDeleteId).then((res) => {
                if (res) {
                  this.usersList = this.usersList.filter((u) => u.id !== this.userDeleteId);
                }
            })
        },
        cancelUserDelete() {
            this.userDeleteId = null
            this.userDeleteName = null
            this.showDeleteDialog = false
        }
    },
};
