export enum FeatureType {
    Operations = 1,
    Fields = 2,
    Vehicles = 3,
    Implements = 4,
    Products = 5,
    CustomersContractors = 6,
    TeamMembers = 7,
    AllJobs = 8,
    OperatorOnJobs = 9,
    TeamLeadOnJobs = 10,
    ApproveJobsForInvoicing = 11
}

export const FeatureTypeLabels = new Map<number, string>([
    [FeatureType.Operations, 'Operations'],
    [FeatureType.Fields, 'Fields'],
    [FeatureType.Vehicles, 'Vehicles'],
    [FeatureType.Implements, 'Implements'],
    [FeatureType.Products, 'Products'],
    [FeatureType.CustomersContractors, 'Customers/Contractors'],
    [FeatureType.TeamMembers, 'Team members'],
    [FeatureType.AllJobs, 'All Jobs'],
    [FeatureType.TeamLeadOnJobs, 'Team lead on jobs'],
    [FeatureType.OperatorOnJobs, 'Operator on jobs'],
    [FeatureType.ApproveJobsForInvoicing, 'Approve jobs for invoicing']
]);
