












































































































import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import labelAndDetailsReadonly from '@/components/shared/readonly/labelAndDetails.vue';
import { routeCustomersUpdate, routeCustomers } from '@/utils/endpoints';
import router from '@/router/index';
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { buttonDeleteColor } from '@/utils/uiconstants';

export default {
    name: 'ContactPersonalDetailsForm',
    mixins: [contactComputedMixin],
    data() {
        return {
            farmerId: null,
            dialogFarmer: false,
            menuColor: false,
            buttonDeleteColor
        }
    },
    computed: {
        swatchStyle() {
            const { menuColor } = this;
            return {
                backgroundColor: this.contactProfile.colorCode,
                cursor: "pointer",
                height: "24px",
                width: "24px",
                borderRadius: menuColor ? "50%" : "50px",
                transition: "border-radius 200ms ease-in-out",
            };
        }
    },
    components: {
        SectionLeftHeadings,
        labelAndDetailsReadonly,
        DeleteItemDialog,
        Button
    },
    methods: {
        openContactEditForm: function (id) {
            router.push(routeCustomersUpdate + '/' + id);
        },
        confirmDeleteFarmer: function (id) {
            this.farmerId = id;
            this.dialogFarmer = true;
        },
        confirmRestoreFarmer(id) {
            this.farmerId = id;
            this.dialogFarmer = true;
        },
        proceedActionOnFarmer: function () {
            this.dialogFarmer = false;
            if(this.contactProfile.isArchived) {
                this.$store.dispatch('restoreFarmer', this.farmerId).then(res => {
                    router.push(routeCustomers);
                });
            } else {
                this.$store.dispatch('deleteFarmer', this.farmerId).then(res => {
                    router.push(routeCustomers);
                });
            }
        },
        updateCustomerColor(){
            const colorCode = {
                colourCode: this.contactProfile.colorCode
            }
            this.$store.dispatch('updateExternalCustomerColor', [this.contactProfile.id, colorCode]);
        }
    }
}
