export const formFieldPb = 'pb-1';
export const containerLeftPad = 'pl-9';
export const containerRightPad = 'pr-9';

export const successColor = '#1d34ad';
export const failureColor = '#e65a5a';
export const brandColor = '#1d34ad';
export const jobsMapLoaderColor = '#eeeeee';
export const jobsMapLabelColorBackground = '#000000';
export const disabledColor = '#afafaf';
export const teamJobOperatorIdleColor = '#B0B4C9';
export const buttonDeleteColor = '#ff0000';

export const defaultScrollViewHeight = 700;
export const defaultScrollViewHeightJobs = 685;
export const defaultScrollViewHeightTimeSheet = 500;
export const defaultJobReportMapWidth = 865;
export const defaultFadeInTimeout = 400;
export const defaultFieldsListVH = 0.75;
export const defaultImagePlaceholder = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADxSURBVHgB7dFBAQAgDAChaf+Qa6I17gEVOLv7how7pAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERLzAR4vBGekHICAAAAAAElFTkSuQmCC`;
export const defaultSidebarWidth = 230

export const paidInvoiceColor = '#b87333'
export const unpaidInvoiceColor = '#ff0000'
export const vdprMaxWidth = '300px';
export const vdprMinWidth = '110px';

export const avatarColors = [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#FF9800',
    '#FF5722'
];

export const avatarColorDisabled = '#d8d8d8';

export const defaultFieldStrokeColor = '#ffe600';

export const fieldColors = ['#ffffff', '#00ff37', '#00d0ff', '#ff9900', '#fff700', '#a200ff', '#4800ff'];
export const logCssColors = 'background: #008cf4; color: #ffffff; font-size: 12px; padding: 2px 4px;';
export const logCssErrorColors = 'background: #974855; color: #ffffff; font-size: 12px; padding: 2px 4px;';
export const avatarColorPresets = [
    {
        group: 'abcde',
        color: '#eb5656'
    },
    {
        group: 'fghi',
        color: '#56a0eb'
    },
    {
        group: 'jklm',
        color: '#eb56a6'
    },
    {
        group: 'nopq',
        color: '#f0a257'
    },
    {
        group: 'rstu',
        color: '#1d7836'
    },
    {
        group: 'vwxyz',
        color: '#657efe'
    }
];

//button styles
export const PRIMARY_BUTTON_HEIGHT = 48;
export const SECONDARY_BUTTON_HEIGHT = 40;
export const TERTIARY_BUTTON_HEIGHT = 36;
export const PRIMARY_BUTTON_FONT_SIZE = 15;
export const SECONDARY_BUTTON_FONT_SIZE = 13;
export const TERTIARY_BUTTON_FONT_SIZE = 1;

export const CALENDAR_INFO_WINDOW_WIDTH = 320;
export const CALENDAR_INFO_WINDOW_HEIGHT = 315;