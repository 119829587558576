

















import $ from 'jquery';
import ContractorListView from './contractors-list.vue';
import ContractorAddForm from './contractor-add-form.vue';
import { closeOverlay } from '@/utils/helpers';
import ContractorManageForm from './contractor-manage-form.vue';
import {DeleteItemDialog as InvitationDialog} from '@/components/shared/dialogs/index';
import contractorsComputedMixin from './contractors-computed';
import requestsCancellationMixin from '@/mixins/requests-cancellation';

export default {
    mixins: [contractorsComputedMixin, requestsCancellationMixin],
    components: {
        ContractorListView,
        ContractorAddForm,
        ContractorManageForm,
        InvitationDialog,
    },
    mounted() {
        this.$store.state.dashboardTitle = this.$stringConstants('contractorsTitle');
        $('body').on('click', '#overlay', function () {
            closeOverlay("addContractorFormOverlay");
            closeOverlay("manageContractorFormOverlay");
        });
    },
    methods: {
        handleDialogCancel() {
            this.dialogInvitation = false
        },
        handleDialogAction() {
            if (this.dialogType == 'accept') {
                this.acceptConnection();
            } else {
                if (this.connectionRequestType == 'received') {
                    this.declineConnection();
                } else {
                    this.deleteSentConnection();
                }
            }
        }
    }
}
