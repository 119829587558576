

















import {BaseButton} from '@/components/shared/buttons/index';
export default {
    name: 'PrimaryButton',
    props: [    
        'textValue',
        'outlined',
        'color',
        'disabled',
        'loading',
        'variant'
    ],
    components: {
        BaseButton
    }

}
