import { apiUsers, apiVehicles, apiImplements, apiInvitation, apiBusinessProfile, apiAccountAddress, apiAccount } from '@/utils/endpoints';
import { notify, closeOverlay, devLogger } from '@/utils/helpers';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import loadGoogleMapsApi from 'load-google-maps-api';
import $ from 'jquery';
import { changeInfoWindowTheme } from '@/utils/helpers/gmaps-helpers';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { UserRoleType } from '@/enum/userRoleType';

export const actions = {
    async signUpUser({ rootState, state, commit }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers
        });

        const result = await requests.postDataWithoutHeader(url, data);
        if (result[0] != null) {
            state.usersLoader = false;
            commit("setSentCurrentRequest", true);
            notify(StringConstants.userSignUpSuccessMessage, 'success');
            return result[0];
        } else {
            state.usersLoader = false;
        }
    },

    async resendEmailVerification({ rootState, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiAccount + "/email/verify"
        });
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            notify(StringConstants.userResendEmailVerificationMessage, 'success');
            commit("setSentCurrentRequest", true);

            return true;
        }
    },

    async getUserProfile({ rootState, state, commit, dispatch }) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem('userId')
        });
        const result = await requests.getData(url, true);
        if (result != null) {
            if (result.data != null) {
                commit('setUserProfile', result.data);
                await dispatch('shouldCallOnboardUpdateCheck', result.data);
                if (result.data.businessProfile != null &&
                    result.data.businessProfile.title != null &&
                    result.data.businessProfile.address &&
                    result.data.businessProfile.address.addressLine1 != null &&
                    result.data.businessProfile.address.postalCode != null
                ) {
                    rootState.hasBusinessProfile = true;
                    const _onboardModule = rootState.onboardModule;
                    if (result.data.businessProfile) {
                        _onboardModule.onboardingStateSelected = _onboardModule.onboardSelectUserVerified;
                        if (result.data.businessProfile.config.currency && localStorage.getItem('ownerRole') ==  UserRoleType[UserRoleType.Contractor]) {
                            _onboardModule.onboardingStateSelected = _onboardModule.onboardSelectUserInvoicingInfoFilled;
                        }
                        else{
                            _onboardModule.onboardingStateSelected = _onboardModule.onboardSelectUserBusinessInfoFilled;
                        }
                    } else {
                        _onboardModule.onboardingStateSelected = _onboardModule.onboardSelectUserVerified;
                    }
                }
                state.usersLoader = false;
                return result.data;
            }
        } else {
            state.usersLoader = false;
            devLogger().logError(result);
        }
    },

    async addUserProfileBusinessSettings({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/business/config'
        });

        const result = await requests.postData(url, data);
        state.usersLoader = false;
        if (result[0] != null) {
            rootState.loginUserName = result[0].firstName;
            dispatch('getUserProfile');
            return result[0];
        }
    },

    async updateUserProfileBusinessSettings({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/business/config'
        });

        const result = await requests.putData(url, data);
        state.usersLoader = false;
        if (result[0] != null) {
            rootState.loginUserName = result[0].firstName;
            dispatch('getUserProfile');
            return result[0];
        }
    },

    async updateUserProfile({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers
        });

        const result = await requests.putData(url, data);
        if (result[0] != null) {
            state.usersLoader = false;
            rootState.loginUserName = result[0].firstName;
            dispatch('getUserProfile');
            return result[0];
        } else {
            state.usersLoader = false;
        }
    },

    async updateUserBusinessProfile({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiBusinessProfile
        });
        const result = await requests.putData(url, data);
        if (result[0] != null) {
            state.usersLoader = false;
            rootState.loginUserName = result[0].firstName;
            dispatch('getUserProfile');
            return result[0];
        } else {
            state.usersLoader = false;
        }
    },

    async shouldCallOnboardUpdateCheck({ state }, data) {
        state.shouldCallOnboardUpdate = false;

        if (data) {
            if (data.businessProfile && 
                (data.businessProfile.title ||
                data.businessProfile.shortTitle ||
                data.businessProfile.prefix ||
                data.businessProfile.registrationNumber ||
                data.businessProfile.address ||
                data.businessProfile.address.addressLine1 ||
                data.businessProfile.address.city ||
                data.businessProfile.address.country ||
                data.businessProfile.address.postalCode)
                ) {
                state.shouldCallOnboardUpdate = true;
            } else {
                state.shouldCallOnboardUpdate = false;
            }
        }
    },

    async getUserProfileForOnboard({ rootState, state, commit, dispatch }) {

        function hasAllBusinessInformation(data) {
            if (data.businessProfile &&
                data.businessProfile.title &&
                data.businessProfile.shortTitle &&
                data.businessProfile.prefix &&
                data.businessProfile.registrationNumber &&
                data.businessProfile.address &&
                data.businessProfile.address.addressLine1 &&
                data.businessProfile.address.city &&
                data.businessProfile.address.country &&
                data.businessProfile.address.postalCode
            ) {
                return true;
            }
            return false;
        }

        const _onboardModule = rootState.onboardModule;

        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem('userId')
        });
        const result = await requests.getData(url);
        state.usersLoader = false;

        if (result != null) {
            if (result.data != null) {
                commit('setUserProfile', result.data);

                await dispatch('shouldCallOnboardUpdateCheck', result.data);
                if (result.data.businessProfile != null &&
                    result.data.businessProfile.title != null &&
                    result.data.businessProfile.address &&
                    result.data.businessProfile.address.addressLine1 != null &&
                    result.data.businessProfile.address.postalCode != null
                ) {
                    rootState.hasBusinessProfile = true;

                } else {
                    rootState.hasBusinessProfile = false;
                }

                if (result.data.businessProfile && result.data.businessProfile.config) {
                    const { currency } = result.data.businessProfile.config;
                    const { timezone } = result.data.businessProfile.config;
                    const { allowedBreakTimeADayInMinutes } = result.data.businessProfile.config;
                    const { defaultColor } = result.data.businessProfile.config;

                    if (currency || timezone || allowedBreakTimeADayInMinutes || defaultColor) {
                        rootState.hasBusinessConfig = true;

                    } else {
                        rootState.hasBusinessConfig = false;
                    }

                    if (!currency || !timezone) {
                        localStorage.setItem('hasIncompleteConfig', '1');
                    } else {
                        localStorage.setItem('hasIncompleteConfig', '0');
                    }

                } else {
                    rootState.hasBusinessConfig = false;
                }

                if (hasAllBusinessInformation(result.data)) {
                    _onboardModule.onboardingStateSelected = _onboardModule.onboardSelectUserBusinessInfoFilled;
                }

                state.usersLoader = false;
                return result.data;
            }
        } else {
            state.usersLoader = false;
            devLogger().logError(result);
        }
    },

    async updateUserBusinessProfileForOnboarding({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiBusinessProfile
        });
        const result = await requests.putData(url, data);
        state.usersLoader = false;
        if (result[0] != null) {
            rootState.loginUserName = result[0].firstName;
            dispatch('getUserProfileForOnboard');
            return result[0];
        }
    },

    async addUserBusinessProfileForOnboarding({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiBusinessProfile
        });
        const result = await requests.postData(url, data);
        state.usersLoader = false;
        if (result[0] != null) {
            rootState.loginUserName = result[0].firstName;
            dispatch('getUserProfileForOnboard');
            return result[0];
        }
    },

    async addUserAddresses({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiAccountAddress
        });
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            state.usersLoader = false;
            dispatch('getUserProfile');
            notify(StringConstants.userAddressAddSuccessMessage, 'success');

            return result[0];
        } else {
            state.usersLoader = false;
        }
    },

    async updateUserAddresses({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiAccountAddress + "/" + data.id
        });
        const result = await requests.putData(url, data);
        if (result[0] != null) {
            state.usersLoader = false;
            dispatch('getUserProfile');
            notify(StringConstants.userAddressAddSuccessMessage, 'success');

            return result[0];
        } else {
            state.usersLoader = false;
        }
    },

    async deleteUserAddresses({ state, rootState, dispatch }, id) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiAccountAddress + "/" + id
        });
        const result = await requests.deleteData(url);
        if (result.status == 200) {
            state.usersLoader = false;
            dispatch('getUserProfile');
            notify(StringConstants.userAddressDeletedSuccessMessage, 'success');

            return result[0];
        }
        else {
            state.usersLoader = false;
        }
    },

    async getVehiclesListForUserProfile({ commit, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles + rootState.tempUrl
        });
        const result = await requests.getData(url);
        if (result != null) {
            if (result.data != null) {
                rootState.listCount = result.data.value.length;
                if (result.data.value.length > 0) {
                    commit('setVehiclesListForUserProfile', result.data.value);
                }
            }
        }
    },

    async getImplementsListForUserProfile({ commit, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiImplements + rootState.tempUrl
        });
        const result = await requests.getData(url);
        if (result != null) {
            if (result.data != null) {
                rootState.listCount = result.data.value.length;
                if (result.data.value.length > 0) {
                    commit('setImplementsListForUserProfile', result.data.value);
                }
            }
        }
    },

    async sendUserInvite({ state, rootState }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation
        });
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            state.usersLoader = false;
            notify(StringConstants.userInviteSendSuccessMessage, 'success');
            closeOverlay('addTeamMemberFormOverlay');
            return true;
        } else {
            state.usersLoader = false;
            return false;
        }
    },

    async getInviteeProfile({ commit, rootState }, code) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiInvitation + '/' + code
        });
        const result = await requests.getDataWithoutAuth(url);
        if (result?.data) {
            commit('setInviteeProfile', result.data);
            return result.data;
        } else {
            notify(StringConstants.userInvitationExpiredMessage, 'fail')
        }
    },

    async verifyEmailInvite({ state, rootState, dispatch }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: '/account/email/verify?code=' + data[0] + '&email=' + encodeURIComponent(data[1])
        });
        const resultStatusCode = await requests.verifyWithoutAuth(url);
        if (resultStatusCode == 200) {
            state.usersLoader = false;
            notify(StringConstants.userEmailVerifySuccessMessage, 'success');

            localStorage.clear();
            dispatch('logout');
            return true;
        } else {
            notify(StringConstants.userEmailVerifyFailMessage, 'fail');
            state.usersLoader = false;
            return false;
        }
    },

    async changeInfoWindowThemeLarge() {
        changeInfoWindowTheme('#selectBusinessCordinateMap', 'dark')
    },

    async getBusinessLocationCordinateOnMap({ state, dispatch }) {
        state.map = null;
        $("#selectJobCordinateMap").html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const mapZoom = ConstantValues.defaultMapZoom;
            const { google } = window;
            const mapOptions = {
                zoom: mapZoom,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: { lat: parseFloat(state.startingLat), lng: parseFloat(state.startingLng) },
                mapTypeControl: true,
                streetViewControl: false,
                rotateControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.map = new google.maps.Map(document.getElementById('selectBusinessCordinateMap'), mapOptions);

            state.mapLocationInfoWindow = new google.maps.InfoWindow();

            if (state.startingLat != null && state.startingLng != null) {
                state.startingUserLatLngOnMap = JSON.stringify({
                    lat: state.startingLat,
                    lng: state.startingLng
                }, null, 2);

                state.mapLocationInfoWindow.setPosition(new google.maps.LatLng(state.startingLat, state.startingLng));
                state.mapLocationInfoWindow.setContent(state.startingLat.toFixed(4).toString() + ', ' + state.startingLng.toFixed(4).toString())
                state.mapLocationInfoWindow.open(state.map);
            } else {
                state.mapLocationInfoWindow.setPosition(new google.maps.LatLng(ConstantValues.defaultBusinessLatitude, ConstantValues.defaultBusinessLongitude));
                state.mapLocationInfoWindow.setContent((new google.maps.LatLng(ConstantValues.defaultBusinessLatitude, ConstantValues.defaultBusinessLongitude)).toString());
                state.mapLocationInfoWindow.open(state.map);
            }

            setTimeout(() => {
                dispatch('changeInfoWindowThemeLarge')
            }, 200)

            state.map.addListener("click", (mapsMouseEvent) => {
                state.startingUserLatLngOnMap = null;
                state.startingUserLatLngOnMap = JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2);

                state.startingLat = mapsMouseEvent.latLng.toJSON().lat;
                state.startingLng = mapsMouseEvent.latLng.toJSON().lng;

                state.mapLocationInfoWindow.close();

                state.mapLocationInfoWindow.setPosition(mapsMouseEvent.latLng);
                state.mapLocationInfoWindow.setContent('(' + state.startingLat.toFixed(4).toString() + ', ' + state.startingLng.toFixed(4).toString() + ')');
                const latLngString = state.startingLat.toFixed(4).toString().trim() + ', ' + state.startingLng.toFixed(4).toString().trim()
                state.mapLocationInfoWindow.open(state.map);
                state.accountMapSearchValue = latLngString;
                dispatch('changeInfoWindowThemeLarge')
            });

        });
    },

    async navigateToAccountCoordinates({ state, dispatch }, coordinates) {

        if (coordinates && coordinates.length == 2) {
            const latitude = coordinates[0]
            const longitude = coordinates[1]
            state.startingUserLatLngOnMap = null

            const newCoordinates = new google.maps.LatLng(latitude, longitude)

            state.startingUserLatLngOnMap = JSON.stringify(newCoordinates.toJSON(), null, 2)
            state.startingLat = parseFloat(latitude)
            state.startingLng = parseFloat(longitude)

            const latLngString = ''.concat(latitude.trim(), ', ', longitude.trim())

            state.mapLocationInfoWindow.close()

            state.mapLocationInfoWindow.setPosition(newCoordinates)
            state.mapLocationInfoWindow.setContent(latLngString)
            state.accountMapSearchValue = latLngString

            state.map.setCenter(newCoordinates)
            state.map.setZoom(ConstantValues.defaultMapZoom)
            state.mapLocationInfoWindow.open(state.map)
            dispatch('changeInfoWindowThemeLarge')
        }
    },

    async requestGooglePlacesSearchAccount({ state }) {
        const request = {
            query: state.accountMapSearchValue,
            fields: ['name', 'geometry'],
        }

        const service = new google.maps.places.PlacesService(state.map);
        service.findPlaceFromQuery(request, function (results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                for (let i = 0; i < results.length; i++) {
                    devLogger().log(results[i]);
                }
                state.map.setCenter(results[0].geometry.location)
                return results[0].geometry.location
            }
        })
    },
    
    async getAccountBussinessProfile({ state, rootState, commit }) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiBusinessProfile
        });
        const result = await requests.getData(url);
        if (result != null) {
            if (result.data != null) {
                commit('setAccountBusinessProfile', result.data);
                state.usersLoader = false;
                return result.data;
            }
        }
        state.usersLoader = false;

    },

    async updateAccountBusinessProfile({ state, commit, rootState }, data) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiBusinessProfile
        });
        const result = await requests.putData(url, data);
        if (result[0] != null) {
            if(localStorage.getItem('fullOwnerBusinessName') !=  result[0].title){
               localStorage.setItem('fullOwnerBusinessName', result[0].title);
               window.location.reload();
            }
            else{
            commit('setAccountBusinessProfile', result[0]);
            notify(StringConstants.userAccountBusinessDetailsUpdateSuccess, 'success');
        }
        }
        state.usersLoader = false;
    },

    async getUserFarmAddress({ state, commit, rootState }) {
        state.usersLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiAccountAddress
        });
        const result = await requests.getData(url);
        if (result != null) {
            if (result.data != null) {
                commit('setAccountFarmAddresses', result.data.value);
            }
        }
        state.usersLoader = false;
    },

    async changeInfoWindowTheme() {
        changeInfoWindowTheme('#accountMarkerMap', 'dark')
    },

    async getGoogleMapToShowDefaultMarker({ state, dispatch }) {
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(async () => {
            const mapZoom = ConstantValues.defaultMapZoom
            const { google } = window
            const mapOptions = {
                zoom: ConstantValues.defaultMarkerZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": state.startingLat, "lng": state.startingLng }),
                mapTypeControl: false,
                zoomControl: false,
                disableDefaultUI: true,
                streetViewControl: false,
            };
            state.accountMarkerMap = new google.maps.Map(document.getElementById('accountMarkerMap'), mapOptions)
            state.mapLocationInfoWindow = new google.maps.InfoWindow()
            state.mapLocationInfoWindow.setPosition(new google.maps.LatLng(state.startingLat, state.startingLng))
            state.mapLocationInfoWindow.setContent(state.startingLat.toFixed(4).toString() + ', ' + state.startingLng.toFixed(4).toString())

            state.mapLocationInfoWindow.open(state.accountMarkerMap);
            setTimeout(() => {
                dispatch('changeInfoWindowTheme')
            }, 200)
        })
    }
}