





































































import { BaseButton as Button } from "@/components/shared/buttons/index";
import moment from 'moment';

export default {
    name: "Integartioncard",
    props: [
        "imageUrl",
        "integrationServiceTitle",
        "disconnectText",
        "loadingIntegration",
        "discriptionText1",
        "discriptionText2",
        "showDisconnectBtn",
        "isAnyIntegration",
        "integrationAccount",
    ],
    components: {
        Button
    },
    computed: {
        loadingIntegrationBuffer: {
            get() {
                return this.$store.getters.loadingIntegrationBuffer;
            },
            set(newVal) {
                this.$store.state.integrationsModule.loadingIntegrationBuffer = newVal;
            }
        },
    },
    methods: {
        getDateTimeFormat(date) {
            return moment.utc(date).local().format("DD MMM YYYY");
        },
    },
};
