import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
import moment  from 'moment';

const computedProperties: Record<string, Array<string>> = {
    'timeSheetsModule': [
        'timesheetMembersSearchText',
        'timesheetsMembersList',
        'addTimesheetLoader',
        'timesheetsTypeList',
        'timesheetDetails',
        'editTimesheetLoader',
        'timesheetsList',
        'timesheetsStatistics',
        'timesheetsFilterTypeList',
        'timesheetListFilterType',
        'timesheetsFilterLoader',
        'timesheetsLoader',
        'timesheetsSearchText',
        'timesheetsFilterStartDate',
        'timesheetsFilterEndDate',
        'timesheetDateFilterApplied',
        'timesheetsSelectedMemberId',
        'timesheetDataListOffset',
        'timesheetDataListTotalCount',
        'timesheetDataListLoadedCount',
        'timesheetDataListForExport'
    ],
    'root': [
        'hoursList',
        'minutesList',
        'searchNotify',
        'loginUserRole',
        'noData',
        'filtersApplied',
        'loginUserName',
        'listCount',
        'routedFromTimesheets',
        'totalRowCount',
        'snackbarShow',
        'allItemsDeleted',
        'selectedItems',
        'isRoutedFromReferencePage'
    ]
}

export default {
    name: 'TimesheetsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        timeStart: {
            get() {
                return this.$store.getters.memberDefaultWorkStartTime
            },
            set(newVal) {
                this.$store.state.timeSheetsModule.memberDefaultWorkStartTime = newVal
            }
        },
        timeEnd: {
            get() {
                return this.$store.getters.memberDefaultWorkEndTime
            },
            set(newVal) {
                this.$store.state.timeSheetsModule.memberDefaultWorkEndTime = newVal
            }
        },
        getSenderId() {
            return localStorage.getItem(ConstantValues.ownerId)
        }
    },
    methods: {
        getTimesheetEndDate(timesheetStartDate, startTime, endTime) {
            let timeSheetEndDate = timesheetStartDate

            if (new Date(`0001-01-01T${startTime}:00`) > new Date(`0001-01-01T${endTime}:00`)) 
                timeSheetEndDate = moment(timesheetStartDate).add(1, 'day').format('YYYY-MM-DD')
            
            return timeSheetEndDate
        }
    }
}