


















export default {
    name: 'ButtonMiniPrimary',
    props: [
        'textValue',
        'width',
        'height',
        'disabled', 
        'loading', 
    ],
    data() {
        return {
            color: '#181818'
        }
    }
}
