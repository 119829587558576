import { defaultFieldStrokeColor } from "@/utils/uiconstants";

export const state = {
    onboardingStates: {
        userNotVerified: 0,
        userVerified: 1,
        userBusinessInfoFilled: 2,
        userInvoicingInfoFilled: 3
    },
    onboardingStateSelected: 1,
    onboardSelectUserVerified: 1,
    onboardSelectUserBusinessInfoFilled: 2,
    onboardSelectUserInvoicingInfoFilled: 3,
    onboardLoader: false,
    onboardMapSearchValue: null,
    onboardConfigTempColor: defaultFieldStrokeColor,
}

export const getters = {
    onboardingStates: state => state.onboardingStates,
    onboardingStateSelected: state => state.onboardingStateSelected,
    onboardSelectUserVerified: state => state.onboardSelectUserVerified,
    onboardSelectUserBusinessInfoFilled: state => state.onboardSelectUserBusinessInfoFilled,
    onboardSelectUserInvoicingInfoFilled: state => state.onboardSelectUserInvoicingInfoFilled,
    onboardLoader: state => state.onboardLoader,
    onboardMapSearchValue: state => state.onboardMapSearchValue,
    onboardConfigTempColor: state => state.onboardConfigTempColor,
}

export const mutations = {
    
}