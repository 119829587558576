


















































































































































import { notify } from "@/utils/helpers";
import SubHeaderPrimary from "@/components/shared/headers/subheader-primary.vue";
import DescriptionPrimary from "@/components/shared/headers/description-primary.vue";
import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import { mapMutations } from "vuex";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
import { excelParser } from '@/utils/helpers/excel-parser-helpers';
import { ImportResourceType } from "@/enum/importResourceType";
import { ImportStatusType } from "@/enum/importStatusType";

export default {
    name: "ImportCSVForm",
    props: [
        "importOverlayCloseFn",
        "importSampleData",
        "importDispatchFn",
        "importListName",
        "inprogressText1",
        "inprogressText2",
        "uploadFileText",
        "requiredFields",
        "uploadNoteTitle",
        "uploadNotes",
        "importResourceType"
    ],
    components: {
        SubHeaderPrimary,
        DescriptionPrimary,
        ListLoader
    },
    data() {
        return {
            containerLeftPad,
            containerRightPad,
            dragging: false,
            fileType: ["csv"],
            sampleFileName: "sample_file",
            errorFileName: "file_with_errors",
            exportTypeExtension: 'csv',
        };
    },
    computed: {
        selectedFile: {
            get() {
                return this.$store.getters.importSelectedFile;
            },
            set(newVal) {
                this.$store.state.importSelectedFile = newVal;
            },
        },
        importResponseData: {
            get() {
                return this.$store.getters.importResponseData;
            },
            set(newVal) {
                this.$store.state.importResponseData = newVal;
            },
        },
        importInProgress: {
            get() {
                return this.$store.getters.importInProgress;
            },
            set(newVal) {
                this.$store.state.importInProgress = newVal;
            },
        },
        fileName: {
            get() {
                return this.$store.getters.importfileName;
            },
            set(newVal) {
                this.$store.state.importfileName = newVal;
            },
        },
        importInvalidData: {
            get() {
                return this.$store.getters.importInvalidData;
            },
            set(newVal) {
                this.$store.state.importInvalidData = newVal;
            },
        },
    },
    methods: {
        ...mapMutations({
            clearFarmerFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
        }),
        
        downloadSampleCustomersFile() {
            excelParser().exportDataFromJSON(this.importSampleData, this.sampleFileName, this.exportTypeExtension)
        },
        onChange(e) {
            var files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                this.dragging = false;
                return;
            }

            this.createFile(files[0]);
        },
        createFile(file) {
            const type = file.name.split(".").pop().toLowerCase();
            if (!this.fileType.includes(type)) {
                notify("please select a valid file", "fail");
                this.dragging = false;
                return;
            }
            this.fileName = file.name;
            this.selectedFile = file;
            this.dragging = false;
        },
        removeFile() {
            this.selectedFile = null;
            this.fileName = null;
        },
        proceedFileUpload(onImportAction) {
            if (this.selectedFile != null) {
                this.importInProgress = true;
                this.importResponseData = null;
                let formData = new FormData();
                formData.append("file", this.selectedFile);

                this.$store
                    .dispatch(onImportAction, formData)
                    .then((importResponse) => {
                        this.importInProgress = false;
                        if (importResponse) {
                            this.importResponseData = importResponse;

                            this.importInvalidData = importResponse.results.filter(
                                (res) => res.status == ImportStatusType.Failed || res.status == ImportStatusType.Declined
                            );

                            if (importResponse.succeeded > 0) {
                                this.$emit('on-success-callback')
                            }
                        }
                        this.removeFile();
                    });
            }
        },
        downloadInvalidData() {
            let data = this.importInvalidData.map((cd, i) => {
                delete cd.data.id;
                return cd.data;
            });

            excelParser().exportDataFromJSON(data, this.errorFileName, this.exportTypeExtension)
        },
        getCondensedList(condensedListSize: number) {
            return this.importInvalidData
        },
    },
};
