






import InvoicesAdd from '@/components/invoices/invoices-add/invoices-add.vue';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';

export default {
    name: 'InvoicesDetails',
    mixins: [breadcrumbsMixin],
    components: {
        InvoicesAdd
    },
    computed: {
        currentInvoiceId: {
            get() {
                return window.location.pathname.split("/").pop();
            }
        },
        invoiceSelectedTab: {
            get() {
                return this.$store.getters.invoiceSelectedTab;
            },
            set(newVal) {
                this.$store.state.invoicesModule.invoiceSelectedTab = newVal;
            }
        },
        invoiceDraftPreviewDetails: {
            get() {
                return this.$store.getters.invoiceDraftPreviewDetails;
            },
            set(newVal) {
                this.$store.state.invoicesModule.invoiceDraftPreviewDetails = newVal;
            }
        }
    },
    mounted() {
        this.setBreadcrumbs('InvoiceDetails');
                    
        this.$store.dispatch('getInvoiceDetails', this.currentInvoiceId).then(() => {
            if(this.invoiceDraftPreviewDetails?.number) {
                this.setBreadcrumbs('InvoiceDetails', this.invoiceDraftPreviewDetails.number);
            }
        });
        this.invoiceSelectedTab = 3;
    },
}
