import { TeamJobStatusType } from "@/enum/teamJobStatusType";

export const jobColors = [
    {
        type: TeamJobStatusType.Pending,
        dark: '#000000',
        light: '#E9ECF0'
    },
    {
        type: TeamJobStatusType.Ready,
        dark: '#F09000',
        light: '#fff3e0'
    },
    {
        type: TeamJobStatusType.InProgress,
        dark: '#0E96FD',
        light: '#DEF3FF'
    },
    {
        type: TeamJobStatusType.AwaitingCompletion,
        dark: '#0E96FD',
        light: '#DEF3FF'
    },
    {
        type: TeamJobStatusType.PartCompleted,
        dark: '#009760',
        light: '#E1FBE2'
    },
    {
        type: TeamJobStatusType.Completed,
        dark: '#009760',
        light: '#E1FBE2'
    },
    {
        type: TeamJobStatusType.Cancelled,
        dark: '#EB5656',
        light: '#ffdfdf'
    },
];
