export const state = {
    businesses: [],
    businessCountry: null,
    businessType: null,
    businessSubscriptionStatus: null,
    businessesStartDate: null,
    businessesEndDate: null,
    businessesListFiltersApplied: false,
    businessesSearchText: null,
    businessTypesList: [
        {
            value: 0,
            title: 'All'
        },
        {
            value: 1,
            title: 'Farmer'
        },
        {
            value: 2,
            title: 'Contractor'
        },
    ],
    businessSubscriptionStatusTypes: [
        {
            value: 0,
            title: 'Trialing'
        },
        {
            value: 1,
            title: 'Active'
        },
        {
            value: 2,
            title: 'Unpaid'
        },
        {
            value: 3,
            title: 'Cancellation Requested'
        },
        {
            value: 4,
            title: 'Cancelled'
        },
        {
            value: 5,
            title: 'Trial Expired'
        },
    ],
    businessesDetails: {
        name: null,
        ownerName: null,
        type: null,
        address: null,
        teamMembers: {
          total: 0,
          active: 0,
          archived: 0
        },
        subscriptions: [],
        jobs: {
          active: 0,
          completed: 0,
          invoiced: 0,
          cancelled: 0,
          archived: 0,
          total: 0
        },
        connectedBusinesses: {
          total: 0,
          internal: 0,
          external: 0
        },
        activeInvoicingService: null,
        totalInvoicesCount: 0,
        totalFieldsCount: 0,
        totalVehiclesCount: 0,
        totalImplementsCount: 0,
        totalProductsCount: 0
      }

}

export const getters = {
    businesses: state => state.businesses,
    businessesStartDate: state => state.businessesStartDate,
    businessesEndDate: state => state.businessesEndDate,
    businessesListFiltersApplied: state => state.businessesListFiltersApplied,
    businessesSearchText: state => state.businessesSearchText,
    businessCountry: state => state.businessCountry,
    businessType: state => state.businessType,
    businessSubscriptionStatus: state => state.businessSubscriptionStatus,
    businessTypesList: state => state.businessTypesList,
    businessSubscriptionStatusTypes: state => state.businessSubscriptionStatusTypes,
    businessesDetails: state => state.businessesDetails,
}

export const mutations = {
    setBusinessesList(state, data) {
        data?.value.forEach(user => state.businesses.push(user))
    },
    resetBusinessesFilter(state) {
        state.businessesStartDate = null;
        state.businessesEndDate = null;
        state.businessesListFiltersApplied = false;
        state.businessesSearchText = null;
    },
    setBusinessesDetails(state, data) {
        if(data) {
            state.businessesDetails = data
        }
    }
}