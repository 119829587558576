






import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';

export default {
    name: "TeamJobsAddressesDropPinMap",
    mixins: [TeamJobsComputed],
    mounted() {
        this.$store.dispatch('getGoogleMapToSelectDefaultLocation');
    },
}
