






























































































































































































































































import {mapActions, mapMutations} from "vuex";
import fieldsComputedMixin from "./fields-computed";
import {
    getAreaUnit, 
    getConvertedAreaUnit,
    getShortName,
    getDateTimeFormattedLocal,
} from "@/utils/helpers";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import {routeTeamJobsDetails, routeJobs} from "@/utils/endpoints";
import CustomerAvatar from "@/components/shared/avatars/avatar.vue";
import {BaseButton as Button} from "@/components/shared/buttons";
import { removeAllLocalParamsJobs } from "@/utils/helpers/jobs-helpers";
import router from "@/router/index";
import filtersParamsMixin from "@/mixins/filter-params-mixin";
import {DeleteItemDialog} from "@/components/shared/dialogs";

export default {
    name: "FieldDetails",
    mixins: [fieldsComputedMixin, filtersParamsMixin],
    components: {
        CustomerAvatar,
        Button,
        DeleteItemDialog
    },
    data() {
        return {
            showAddCropHistoryDialog: false,
            files: null,
            areaUnit: getAreaUnit(),
            TeamJobStatusType,
            recalculateButtonVisible: false,
            routeTeamJobsDetails,
            showAllJobs: false
        }
    },
    computed: {
        fieldNote() {
            return this.fieldDetails.note
        },
        jobsList() {
            if (this.showAllJobs) return this.fieldInProgressJobs
            return this.fieldInProgressJobs.slice(0, 4)
        }
    },
    mounted() {
        this.getConvertedArea();
        this.calculateAndSetPolygonArea(this.polygon);
        this.getJobsByField({
            ownerId: this.fieldDetails.owner,
            resourceId: this.fieldDetails.id
        });
        this.$store.commit("setDashboardTitle", this.fieldDetails.title);
    },
    methods: {
        ...mapMutations({
            resetFieldDetails: "resetFieldDetails"
        }),
        ...mapActions({
            getJobsByField: "getJobsByField",
            calculateAndSetPolygonArea: "calculateAndSetPolygonArea"
        }),
        toggleFieldsEditable() {
            if (!this.fieldReadonly) {
                this.editingAreaOnMap = !this.showEditableFields;
            }
            this.showEditableFields = !this.showEditableFields;
        },
        getConvertedArea() {
            if (
                this.fieldDetails.areaInSquareMeter != null &&
                this.fieldDetails.areaInSquareMeter > 0
            ) {
                this.convertedArea = parseFloat(
                    getConvertedAreaUnit(this.fieldDetails.areaInSquareMeter)
                );
            } else {
                this.convertedArea = parseFloat(getConvertedAreaUnit(this.areaInSquareMeter));
            }
        },
        getOwnerInfo() {
        let ownerInfo = "";
        if (
            this.fieldDetails &&
            this.fieldDetails.ownerDetails &&
            this.fieldDetails.ownerDetails.businessProfile &&
            this.fieldDetails.ownerDetails.businessProfile.title
        ) {
            ownerInfo += this.fieldDetails.ownerDetails.businessProfile.title;
        }
        const ownership = this.getOwnership();

        return ownerInfo + " (" + ownership + ")";
        },
        getOwnership() {
            if (
                this.enumOwnership &&
                this.enumOwnership.length > 0 &&
                this.fieldDetails &&
                this.fieldDetails.ownerShip != null
            ) {
                return this.enumOwnership.filter(
                (ownership) => ownership.value == this.fieldDetails.ownerShip
                )[0].title;
            }
            return "";
        },
        getJobOwnerName(job) {
            if (job) {
                if (job.customer.businessName) return getShortName(job.customer.businessName, 20);
                if (job.customer.name) return getShortName(job.customer.name, 20);
            }
        },
        getLocalDate(dateTime) {
            return getDateTimeFormattedLocal(dateTime);
        },
        showJobsList() {
            this.showAllJobs = true;
        },
        addNewCrop() {
            this.showAddCropHistoryDialog = false;
            this.$store
                .dispatch("addFieldCrop", {
                ownerId: this.fieldDetails.owner,
                resourceId: this.fieldDetails.id,
                cropObj: {
                    name: this.fieldCropNameToAdd,
                    year: this.fieldCropYearToAdd,
                },
                })
                .then((res) => {
                    if (res) {
                        this.fieldDetails.cropHistory.push({
                        name: res.name,
                        year: res.year,
                        });
                        this.fieldCropNameToAdd = this.fieldCropYearToAdd = null;
                    }
                });
        },
        getFieldAddress() {
            if (this.fieldDetails.address) {
                let address = "";
                if (this.fieldDetails.address.addressLine1)
                    address += this.fieldDetails.address.addressLine1;
                if (this.fieldDetails.address.addressLine2)
                   address += ", " + this.fieldDetails.address.addressLine2;
                return address;
            }
            return "";
        },
        handleDelete() {
            this.confirmDeleteField([this.fieldDetails.id, this.fieldDetails.owner]);
        },
        proceedToDelete() {
            this.deleteField(this.cleanUpMapAndFieldFormAfterAction);
        },
    }
}
