









































import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { closeOverlay } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import farmersComputedMixin from '@/components/farmers/farmers-computed';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { UserRoleType } from '@/enum/userRoleType';

export default {
    name: 'inviteFarmer',
    mixins: [validationMixin, farmersComputedMixin],
    validations: {
        userInviteeEmail: {
            required,
            email
        }
    },
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
            fieldsInvalid: true,
            inxClick: null,
        }
    },
    components: {
        Button
    },
    
    mounted() {
        this.setClearInviteeProfile();
    },
    methods: {
        ...mapMutations({
            setClearInviteeProfile: 'setClearInviteeProfile'
        }),

        proceedFarmerInvitation() {
            if (this.$v.$invalid) return
            const newFarmerInviteeObj = {
                inviteeEmail: this.userInviteeEmail,
                inviteeRole: UserRoleType.Farmer
            };
            this.addFarmerLoader = true;
            this.$store.dispatch('sendUserInvite', newFarmerInviteeObj).then((res) => {
                if (res) {
                    closeOverlay('addFarmerFormOverlay');
                }
                this.addFarmerLoader = false;
            });
        },
        generateValidationErrors() {
            let errors: any = [];
            if (!this.$v.userInviteeEmail.$dirty) return errors
            if (!this.$v.userInviteeEmail.required) {
                errors.push(this.$stringConstants('userRequiredEmailAddress'))
                this.fieldsInvalid = true;
            }
            else if (!this.$v.userInviteeEmail.email) {
                errors.push(this.$stringConstants('userFormatEmailAddress'))
                this.fieldsInvalid = true;
            } else {
                this.fieldsInvalid = false;
            }
            return errors;
        },
        closeFarmerAddForm() {
            closeOverlay("addFarmerFormOverlay");
        }
    }
}
