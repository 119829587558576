<template>
    <svg width="44" height="44" viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>operator</title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="officer" transform="translate(1.000000, 1.000000)">
                <circle id="Oval" :stroke="getFillColor()" stroke-width="0.1" :fill="getFillColor()" fill-rule="nonzero" cx="32" cy="5" r="6"></circle>
                <circle cx="32" cy="27" r="11" :stroke="getFillColor()" stroke-width="1" :fill="getFillColor()" />
                <text id="1234567" font-family="Helvetica, Arial, sans-serif" font-size="11" font-weight="400" fill="#ffffff">
                    <tspan text-anchor="middle" x="32" y="31">{{initials}}</tspan>
                </text>
            </g>
        </g>
    </svg>
</template>

<script>
    import { getColorPreset } from '@/utils/helpers';

    export default {
        props: ["fillColor", "initials"],
        methods: {
            getFillColor() {
                return getColorPreset(this.initials);
            }
        }
    }
</script>