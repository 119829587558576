







































































import { routeCustomers, routeIntegrations } from "@/utils/endpoints";
import SubHeaderPrimary from '@/components/shared/headers/subheader-primary.vue';
import DescriptionPrimary from '@/components/shared/headers/description-primary.vue';
import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import integrationsComputedMixin from '@/components/Integrations/integrations-computed';
import integrationCard from '@/components/shared/integration/integration-card.vue'
import { mapMutations } from 'vuex';
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
import { notify } from '@/utils/helpers';
import router from '@/router';
import ConstantValues from '@/plugins/constantValues';
import requestsCancellationMixin from '@/mixins/requests-cancellation';

export default {
    data() {
        return {
            dialogDisconnectIntegration: false,
            disconnectTypeText: '',
            containerLeftPad,
            containerRightPad,
            routeCustomers
        }
    },
    components: {
        SubHeaderPrimary,
        DescriptionPrimary,
        integrationCard,
        DeleteItemDialog
    },
    mixins: [integrationsComputedMixin, requestsCancellationMixin],
    computed: {
        currentPageType: {
            get() {
                return this.integrationType ? this.integrationType.toLowerCase() : '';
            }
        },
        checkIntegration() {
            if (this.quickBookAccess[this.quickbookAccessState] == ConstantValues.quickbookAccessStruct['quickbookAccess']
                || this.xeroAccess[this.xeroAccessState] == ConstantValues.xeroAccessStruct['xeroAccess']
                || this.sageAccess[this.sageAccessState] == ConstantValues.sageAccessStruct['sageAccess']) {
                return true;
            }
            else {
                return false;
            }
        }
    },
    beforeDestroy() {
        this.loadingIntegrationBuffer = true;
        this.destroyAllState();
    },
    beforeMount() {
        this.loadingIntegrationBuffer = true;
        this.destroyAllState();
    },
    mounted() {
        this.$store.state.dashboardTitle = 'Integrations';

        const urlParams = new URLSearchParams(window.location.search);

        const error = urlParams.get('error');
        if (error) {
            notify(error, "fail");
            this.integrationsAuthenticationInProgress = false;
            this.loadingIntegrationBuffer = false
            return;
        }

        this.bufferServiceConnection();

        this.$store.dispatch("getExternalInvoicingServiceAccess").then(() => {
            if (this.quickbookAccessState == 2) {
                if (this.currentPageType != 'quickbooks') {
                    this.integrationType = 'quickbooks';
                }
            }
            if (this.xeroAccessState == 2) {
                if (this.currentPageType != 'xero') {
                    this.integrationType = 'xero';
                }
            }
            if (this.sageAccessState == 2) {
                if (this.currentPageType != 'sage') {
                    this.integrationType = 'sage';
                }
            }
        });
    },
    methods: {
        ...mapMutations({
            setClearIntegrations: 'setClearIntegrations',
        }),
        destroyAllState: function () {
            this.integrationType = null;
            this.setClearIntegrations();
            this.xeroAccessState = 0;
            this.quickbookAccessState = 0;
            this.sageAccessState = 0;
        },
        showDialogDisconnectIntegration(dialogType) {
            switch (dialogType) {
                case 'quickbooks': this.disconnectTypeText = 'QuickBooks';
                    this.dialogDisconnectIntegration = true;
                    break;
                case 'xero': this.disconnectTypeText = 'Xero';
                    this.dialogDisconnectIntegration = true;
                    break;
                case 'sage': this.disconnectTypeText = 'Sage';
                    this.dialogDisconnectIntegration = true;
                    break;
            }
        },
        disconnectIntegration(disconnectType) {
            if (!disconnectType)
                disconnectType = this.integrationType.toLowerCase();

            this.dialogDisconnectIntegration = false;
            switch (disconnectType) {
                case 'quickbooks': this.confirmDisconnectQuickBooks();
                    break;
                case 'xero': this.confirmDisconnectXero();
                    break;
                case 'sage': this.confirmDisconnectSage();
                    break;
            }
        },
        bufferServiceConnection() {
            this.loadingIntegrationBuffer = true;
            setTimeout(() => {
                this.loadingIntegrationBuffer = false;
            }, ConstantValues.defaultIntegrationBufferTimeout);
        },
        confirmDisconnectQuickBooks() {
            this.$store.dispatch('disconnectIntegration', 'quickbooks');
        },
        confirmDisconnectXero() {
            this.$store.dispatch('disconnectIntegration', 'xero');
        },
        confirmDisconnectSage() {
            this.$store.dispatch('disconnectIntegration', 'sage');
        },
        integrateXero() {
            this.bufferServiceConnection();
            this.$store.dispatch("startIntegrationAuthentication", 'xero');
        },
        integrateQuickbooks() {
            this.bufferServiceConnection();
            this.$store.dispatch("startIntegrationAuthentication", 'quickbooks');
        },
        integrateSage() {
            this.bufferServiceConnection();
            this.$store.dispatch("startIntegrationAuthentication", 'sage');
        },

    },
}
