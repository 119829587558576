import { isSystemTrialPeriodOver } from "@/utils/helpers/layout-helpers";
import { getComputedProperties } from "@/utils/helpers/computed-generator";
import moment from "moment";

const computedProperties: Record<string, Array<string>> = {
    'usersModule': [
        'userProfile'
    ],
    'subscriptionsModule': [
        'showAddCardSubscriptionDialog',
        'cardSourceToken',
        'stripePublishKey',
        'cardsLoader',
        'subscriptionPlanTypes',
        'subscriptionDetails',
        'cardsList',
        'noCards',
        'showDefaultCardConfirmDialog',
        'cardIdToSelect',
        'cardBrandToSelect',
        'cardLastFourToSelect',
        'subscriptionMembersSize',
        'subscriptionPlanLoader',
        'subscriptionStatus',
        'defaultTrialPeriodInDays',
        'dialogCancelSubscription',
        'cancellationScheduledOn',
        'subscriptionPromocode',
        'subscriptionPromocodeApplied',
        'promoCodeLoader',
        'promotionalCodeId',
        'subscriptionSuccessDescription',
        'pricingLoader',
        'subscriptionCancelReason',
        'enableCardAddButton',
        'subscriptionInvoicesList'
    ],
    'invoicesExternalModule': [
        'invoiceStatusEnum'
    ],
    'root': [
        'enumSubscriptionStatus',

    ]
}

export default {
    name: 'SubscriptionsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        isSystemTrialPeriodOver() {
            return isSystemTrialPeriodOver(this.userProfile.businessProfile.trialEnd);
        },
        flatPrice() {
            return this.subscriptionPlanTypes[0].flatPrice;
        }
    },
    methods: {
        getCancellationScheduledDateString(dateTime) {
            return this.$stringConstants('subscriptionPlanCancelDateText') 
                   + moment.utc(dateTime).local().format("DD MMM YYYY HH:mm");
        }
    }
}