import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"px-9 pt-10",attrs:{"no-gutters":"","cols":"12"}},[_c(VCol,{attrs:{"cols":"3"}},[_c('SectionLeftHeadings',{attrs:{"textValue1":_vm.$stringConstants('accountCompanyDetailsHeading1'),"textValue2":_vm.$stringConstants('accountCompanyDetailsHeading2')}})],1),_c(VCol,{staticClass:"px-3",attrs:{"cols":"7"}},[_c(VRow,{attrs:{"no-gutters":"","cols":"12"}},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"field-input-label"},[_vm._v(_vm._s(_vm.$stringConstants('companyName'))+" *")]),_c(VTextField,{class:_vm.formFieldPb,attrs:{"required":"","outlined":"","dense":""},on:{"keydown":function($event){return _vm.updatePrefix($event)}},model:{value:(_vm.accountBusinessProfile.title),callback:function ($$v) {_vm.$set(_vm.accountBusinessProfile, "title", $$v)},expression:"accountBusinessProfile.title"}})],1)],1),_c(VRow,{attrs:{"no-gutters":"","cols":"12"}},[_c(VCol,{staticClass:"py-0 pl-0 pr-2",attrs:{"cols":"6"}},[_c('div',{staticClass:"field-input-label position-relative"},[_c('span',[_vm._v(_vm._s(_vm.$stringConstants('companyJobCode'))+" *")]),_c('span',{staticClass:"info-icon"},[_c(VTooltip,{attrs:{"bottom":"","color":"#000","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"small":"","color":"#000000"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',{staticClass:"tol-cont"},[_vm._v(" "+_vm._s(_vm.$stringConstants('jobPrefixText'))+" ")])])],1)]),(_vm.userRole == _vm.UserRoleType.Contractor || _vm.userRole == _vm.UserRoleType.Farmer)?_c('div',{staticClass:"biz-short-name"},[_c(VTextField,{class:_vm.formFieldPb,attrs:{"outlined":"","disabled":_vm.accountBusinessProfile.shortTitle != null && _vm.accountBusinessProfile.shortTitle.length >= _vm.$constantValues('defaultBusinessProfilePrefixMinLength') && _vm.accountBusinessProfile.shortTitle.length <= _vm.$constantValues('defaultBusinessProfilePrefixLength'),"dense":"","maxlength":3,"required":""},model:{value:(_vm.businessProfilePrefix),callback:function ($$v) {_vm.businessProfilePrefix=$$v},expression:"businessProfilePrefix"}})],1):_vm._e()]),_c(VCol,{staticClass:"py-0 pl-2",attrs:{"cols":"6"}},[_c('div',{staticClass:"field-input-label"},[_vm._v(_vm._s(_vm.$stringConstants('companyRegistrationNumber')))]),_c(VTextField,{class:_vm.formFieldPb,attrs:{"required":"","outlined":"","dense":""},on:{"keydown":function($event){return _vm.checkKeyDownAlphaNumeric($event)}},model:{value:(_vm.accountBusinessProfile.registrationNumber),callback:function ($$v) {_vm.$set(_vm.accountBusinessProfile, "registrationNumber", $$v)},expression:"accountBusinessProfile.registrationNumber"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }