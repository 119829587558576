<template>
<div>
    <div class="su-db-box-wrapper su-db-box-wrapper-right">
        <div class="su-db-header">
            <div class="ib">
                Countries
            </div>
            <div class="ib" style="float: right">
                Businesses
            </div>
        </div>
        <div class="su-layout-dist-cont">
            <div v-if="!adminOverallOverviewLoader">
                <div v-for="(distribution, index) in countriesDistributions" :key="index">
                    <div class="su-layout-dist">
                        <div class="ib">{{ distribution.type }}</div>
                        <div class="ib" style="float: right">{{ distribution.count }}</div>
                    </div>
                </div>
            </div>
            <div v-else>
                <DistributionLoaders :loaderRows="3"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import suOverviewComputedMixin from './su-overview-computed';
import DistributionLoaders from './su-overview-distribution-loader.vue';


export default {
    mixins: [suOverviewComputedMixin],
    components: {
        DistributionLoaders
    },
    computed: {
        countriesDistributions: {
            get() {
                return this.adminOverviewOverallStatistics.businessesDistributionByCountry
            }
        }
    }
}
</script>