export class Coordinate implements ICoordinate {
    latitude: number;
    longitude: number;

    public getCoordinate?(coordinate: Coordinate) {
        this.latitude = coordinate ? coordinate.latitude : null;
        this.longitude = coordinate ? coordinate.longitude : null;
        if(this.latitude && this.longitude) {
            return this;
        }
        return null;
    }
} 