import StringConstants from '@/plugins/stringConstants';
import { getDateDifferenceInDays } from '@/utils/helpers';

export const state = {
    cardsList: [],
    noCards: true,
    showAddCardSubscriptionDialog: false,
    showDefaultCardConfirmDialog: false,
    subscriptionMembersSize: 0,
    cardsLoader: false,
    subscriptionPlanLoader: false,
    cardSourceToken: null,
    stripePublishKey: null,
    cardIdToSelect: null,
    cardBrandToSelect: null,
    cardLastFourToSelect: null,
    subscriptionDetails: null,
    subscriptionStatus: null,
    defaultTrialPeriodInDays: 0,
    subscriptionPlanTypes: {
        0: {
            flatPrice: null,
            maxUnits: null,
            pricePerUnit: null,
            title: 'Small team'
        },
        1: {
            flatPrice: null,
            maxUnits: null,
            pricePerUnit: null,
            title: 'Medium team'
        },
        2: {
            flatPrice: null,
            maxUnits: null,
            pricePerUnit: null,
            title: 'Large team'
        },
        3: {
            flatPrice: null,
            maxUnits: null,
            pricePerUnit: null,
            title: 'Very large team'
        }
    },
    dialogCancelSubscription: false,
    cancellationScheduledOn: null,
    subscriptionPromocode: null,
    subscriptionPromocodeApplied: false,
    subscriptionSuccessDescription: StringConstants.subscriptionPromoCodeActivateSuccessText,
    promoCodeLoader: false,
    promotionalCodeId: null,
    pricingLoader: false,
    subscriptionInvoicesList: [],
    subscriptionCancelReason: null,
    enableCardAddButton: false,
}

export const getters = {
    cardsList: state => state.cardsList,
    noCards: state => state.noCards,
    showAddCardSubscriptionDialog: state => state.showAddCardSubscriptionDialog,
    subscriptionMembersSize: state => state.subscriptionMembersSize,
    cardsLoader: state => state.cardsLoader,
    cardSourceToken: state => state.cardSourceToken,
    stripePublishKey: state => state.stripePublishKey,
    cardIdToSelect: state => state.cardIdToSelect,
    cardBrandToSelect: state => state.cardBrandToSelect,
    cardLastFourToSelect: state => state.cardLastFourToSelect,
    showDefaultCardConfirmDialog: state => state.showDefaultCardConfirmDialog,
    subscriptionDetails: state => state.subscriptionDetails,
    subscriptionPlanLoader: state => state.subscriptionPlanLoader,
    subscriptionStatus: state => state.subscriptionStatus,
    defaultTrialPeriodInDays: state => state.defaultTrialPeriodInDays,
    subscriptionPlanTypes: state => state.subscriptionPlanTypes,
    dialogCancelSubscription: state => state.dialogCancelSubscription,
    cancellationScheduledOn: state => state.cancellationScheduledOn,
    subscriptionPromocode: state => state.subscriptionPromocode,
    subscriptionPromocodeApplied: state => state.subscriptionPromocodeApplied,
    promoCodeLoader: state => state.promoCodeLoader,
    promotionalCodeId: state => state.promotionalCodeId,
    subscriptionSuccessDescription: state => state.subscriptionSuccessDescription,
    pricingLoader: state => state.pricingLoader,
    subscriptionInvoicesList: state => state.subscriptionInvoicesList,
    subscriptionCancelReason: state => state.subscriptionCancelReason,
    enableCardAddButton: state => state.enableCardAddButton,
}

export const mutations = {
    setCardsList(state, data) {
        state.cardsList = [];
        data.map(card => state.cardsList.push(card));
        state.noCards = false;
    },
    setSubscriptionPricing(state, data) {
        state.defaultTrialPeriodInDays = data.defaultTrialPeriodInDays;
        data.pricingTiers.map((tier, index) => {
            state.subscriptionPlanTypes[index].flatPrice = tier.flatPrice;
            state.subscriptionPlanTypes[index].pricePerUnit = tier.pricePerUnit;
            state.subscriptionPlanTypes[index].maxUnits = tier.maxUnits;
        });
    },
    setClearCardsList(state) {
        state.cardsList = [];
    },
    setSubscriptionInvoicesList(state, data) {
        state.subscriptionInvoicesList = data;
    },
    setNoCardsStatus(state, cardsListLength) {
        state.noCards = cardsListLength > 0 ? false : true;
    },
    setClearUpdateSubscriptionDialog(state) {
        state.subscriptionMembersSize = 0;
    },
    setStripePublishKey(state, data) {
        state.stripePublishKey = data;
    },
    setSubscriptionDetails(state, data) {
        state.subscriptionDetails = data;
        state.subscriptionStatus = data.status;
        state.subscriptionMembersSize = data.quantity;
        state.cancellationScheduledOn = data.cancellationScheduledOn;
        localStorage.setItem('subscriptionStatus', data.status);
        localStorage.setItem('trialDaysRemaining', getDateDifferenceInDays(data.trailEndOn));
    },
    setPromoCodeDetails(state, data) {
        if (data.id) {
            state.promotionalCodeId = data.id;
            state.subscriptionPromocodeApplied = true;
        }
    },
    setClearSubscriptionDetails(state) {
        state.subscriptionPlanLoader = false;
        state.subscriptionDetails = null;
        state.subscriptionStatus = null;
        state.cardsList = [];
        state.subscriptionStatus = null;
        state.dialogCancelSubscription = false;
        state.cancellationScheduledOn = null;
        state.subscriptionCancelReason = null;
    },
    setClearPromoCodeInfo(state) {
        state.subscriptionPromocode = null;
        state.subscriptionPromocodeApplied = false;
        state.promoCodeLoader = false;
        state.promotionalCodeId = null;
        state.subscriptionSuccessDescription = StringConstants.subscriptionPromoCodeActivateSuccessText;
    },
}