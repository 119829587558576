import { IHealthCheckListItem } from '../interfaces/IHealthCheckListInterface';
import { IMachine } from '../interfaces/IMachineInterface';
import { HealthCheckListItem } from './HealthCheckListItem';

export class Machine implements IMachine, IBase {

    id: string;
    title: string;
    make: string;
    model: string;
    registration: string;
    width: number;
    length: number;
    weight: number;
    ownership: number;
    healthSafetyList: Array<IHealthCheckListItem> = new Array<HealthCheckListItem>();
    isHealthSafetyListMandatory: boolean;
    isArchived: boolean;

    public getVehicle(machine: Machine) {
        this.id = machine.id;
        this.title = machine.title;
        this.make = machine.make;
        this.model = machine.model;
        this.registration = machine.registration;
        this.width = machine.width;
        this.length = machine.length;
        this.weight = machine.weight;
        this.ownership = machine.ownership;
        this.healthSafetyList = machine.healthSafetyList.map(x => new HealthCheckListItem(x?.id, x?.title));
        this.isHealthSafetyListMandatory = machine.isHealthSafetyListMandatory;
        this.isArchived = machine.isArchived;
        return this;
    }
}