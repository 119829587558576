


























































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsOwners from '@/components/team-jobs/common/team-jobs-owners.vue';
import TeamJobsScheduledDate from '@/components/team-jobs/common/team-jobs-generic-information/team-jobs-scheduled-date.vue';
import TeamJobsOperation from '@/components/team-jobs/common/team-jobs-operation.vue';
import TeamJobsInstructions from '@/components/team-jobs/common/team-jobs-instructions.vue';
import TeamJobsAttachments from '@/components/team-jobs/common/team-jobs-attachments.vue';
import TeamJobsStatus from '@/components/team-jobs/common/team-jobs-generic-information/team-jobs-status.vue';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import TeamJobsCancelOrDelete from "@/components/team-jobs/team-jobs-details/team-jobs-delete-cancel.vue";

export default {
    name: "TeamJobsGenericInformation",
    mixins: [TeamJobsComputed],
    components: {
        TeamJobsOwners,
        TeamJobsScheduledDate,
        TeamJobsOperation,
        TeamJobsInstructions,
        TeamJobsAttachments,
        TeamJobsStatus,
        TeamJobsCancelOrDelete
    },
    data() {
        return {
            TeamJobStatusType
        }
    }
}
