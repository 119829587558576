import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule': [
        'teamJobAddresses',
        'teamJobFarmAddressesSelected',
        'teamJobAddressInstructions',
        'teamJobAllAddresses',
        'teamJobAddressSelectionType',
        'teamJobAddressesDropPinLatitude',
        'teamJobAddressesDropPinLongitude',
        'teamJobCustomAddress'
    ]
}

export default {
    name: 'TeamJobsAddressesComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}