import { VCard } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{ref:"tabsRef",class:[_vm.containerLeftPad, _vm.containerRightPad],staticStyle:{"border-bottom":"1px solid #ededed"},attrs:{"id":"tabs","centered":"","grow":""},on:{"change":_vm.handleTabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab,inx){return _c(VTab,{key:inx},[_vm._v(" "+_vm._s(tab)+" ")])}),1),_c(VTabsItems,{style:('width: 100%'),model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabContents),function(tabContent,index){return _c(VTabItem,{key:index,attrs:{"transition":false}},[(!_vm.listLoaders[index] && tabContent.length > 0)?_c(VCard,{staticClass:"scroll-view",attrs:{"flat":""}},_vm._l((tabContent),function(item,index){return _c(VRow,{key:index,staticClass:"align-center pt-2",attrs:{"no-gutters":""}},[_vm._t("default",null,{"item":item,"currentTab":_vm.activeTab})],2)}),1):(!_vm.listLoaders[index] && tabContent.length == 0)?_c(VCard,{staticClass:"mt-vh2",attrs:{"flat":""}},[_c('DescriptionPrimary',{staticClass:"text-center",attrs:{"textValue":_vm.emptyListTexts[index]}})],1):(_vm.listLoaders[index])?_c(VCard,{attrs:{"flat":""}},[_c('ListLoader',{staticClass:"text-center"})],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }