
































































import SubscriptionPlanCard from "@/components/subscriptions/subscription-plan-card.vue";
import { mapMutations } from "vuex";
import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";

export default {
  data() {
    return {
      dialogDeleteCard: false,
      cardIdToDelete: null
    };
  },
  components: {
    SubscriptionPlanCard,
    Button,
  },
  mixins: [subscriptionsComputedMixin],
  mounted() {
    this.setClearCardsList();
    this.$store.dispatch("getCardsList");
  },
  methods: {
    ...mapMutations({
      setClearCardsList: "setClearCardsList",
    }),
    getMembersCount(maxUnits) {
      return maxUnits ? `${maxUnits - 9}-${maxUnits} members` : "30+ members";
    },
    getPlanTypeColor(planTypeColor) {
      if (planTypeColor != undefined && planTypeColor != null) {
        switch (planTypeColor) {
          case 0:
            return "#ef4545";
          case 1:
            return "#5645ef";
          case 2:
            return "#eba230";
          case 3:
            return "#4592ef";
        }
      }
    },
    routeToPricing() {
      window.open("https://ag-drive.com/pricing", "_blank");
    },
  },
};
