import { ITeamJobAddress } from '../interfaces/ITeamJobAddress';
import { Coordinate  } from './Coordinate';
export class TeamJobAddress implements ITeamJobAddress {
    id?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    coordinate?: Coordinate;
    instructions?: string;

    constructor() {
        this.id = null;
        this.addressLine1 =  null;
        this.addressLine2 = null;
        this.city = null;
        this.state = null;
        this.country = null;
        this.postalCode = null;
        this.coordinate = null;
        this.instructions = null;
    }

    public getTeamJobAddress(address: TeamJobAddress, instruction?: string) {
        this.id = address.id ? address.id : null;
        this.addressLine1 = address.addressLine1 ? address.addressLine1 : null;
        this.addressLine2 = address.addressLine2 ? address.addressLine2: null;
        this.city = address.city ? address.city : null;
        this.state = address.state ? address.state : null;
        this.country = address.country ? address.country : null;
        this.postalCode = address.postalCode ? address.postalCode : null;
        this.coordinate = new Coordinate().getCoordinate(address.coordinate);
        this.instructions = instruction ? instruction : address.instructions;

        return this;
    }
}