export class Breadcrumb {
    text: string;
    disabled: boolean;
    to: string;

    constructor() {
        this.text = null;
        this.disabled = false;
        this.to = null;
    }

    public getBreadcrumb(text, disabled, to) {
        this.text = text || '';
        this.disabled = disabled;
        this.to = to || '/';

        return this;
    }
}