import { Coordinate } from './Coordinate';
import moment from 'moment'
import { getHoursAndMinTimeSheet } from '../../utils/helpers';

export class Timesheet {
    id: string;
    userId: string;
    name: string;
    type: number;
    startTime: Date;
    endTime: Date;
    workTimeInMinutes: number;
    breakTimeInMinutes: number;
    travelTimeInMinutes: number;
    overTimeInMinutes: number;
    coordinate: Coordinate = new Coordinate();
    note: string;
    date?: string;
    startTimeConverted?: string;
    endTimeConverted?: string;
    breakTimeInMinutesConverted?: any;
    travelTimeInMinutesConverted?: any;

    constructor() {
        this.id = null;
        this.userId = null;
        this.name = null;
        this.type = 0;
        this.startTime = null;
        this.startTime = null;
        this.endTime = null;
        this.workTimeInMinutes = 0;
        this.breakTimeInMinutes = 0;
        this.travelTimeInMinutes = 0;
        this.overTimeInMinutes = 0;
        this.coordinate = null;
        this.note = null;
        this.date = null;
        this.startTimeConverted = null;
        this.endTimeConverted = null;
        this.breakTimeInMinutesConverted = { hours: 0, minutes: 0 };
        this.travelTimeInMinutesConverted = { hours: 0, minutes: 0 };
    }

    getTimesheet(dto: Timesheet) {
        this.id = dto.id;
        this.userId = dto.userId;
        this.name = dto.name;
        this.type = dto.type;
        this.startTime = dto.startTime;
        this.endTime = dto.endTime;
        this.workTimeInMinutes = dto.workTimeInMinutes;
        this.breakTimeInMinutes = dto.breakTimeInMinutes;
        this.travelTimeInMinutes = dto.travelTimeInMinutes;
        this.overTimeInMinutes = dto.overTimeInMinutes;
        this.coordinate = dto.coordinate;
        this.note = dto.note;
        this.date = moment.utc(dto.startTime).local().format('YYYY-MM-DD');
        this.startTimeConverted = moment.utc(dto.startTime).local().format("HH:mm");
        this.endTimeConverted = moment.utc(dto.endTime).local().format("HH:mm");
        this.breakTimeInMinutesConverted = getHoursAndMinTimeSheet(dto.breakTimeInMinutes);
        this.travelTimeInMinutesConverted = getHoursAndMinTimeSheet(dto.travelTimeInMinutes);
        return this;
    }
}