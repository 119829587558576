






























import accountComputedMixin from '@/components/account/account-computed';
import PersonalDetailsForm from './account-personal-details.vue';
import PersonalContactForm from './account-personal-contact-info.vue';
import PersonalPreferencesForm from './account-personal-preferences.vue';
import ProfileAddressForm from './account-profile-address.vue';
import ButtonActionPrimary from '@/components/shared/buttons/button-action-primary.vue';
import moment from 'moment';
import { checkFieldsValidity } from '@/utils/helpers';
import Loader from '@/components/shared/loaders/list-loader.vue';
import { UserRoleType } from '@/enum/userRoleType';

export default {
    name: 'AccountProfileForm',
    mixins: [accountComputedMixin],
    components: {
        PersonalDetailsForm,
        PersonalContactForm,
        PersonalPreferencesForm,
        ProfileAddressForm,
        ButtonActionPrimary,
        Loader
    },
    mounted() {
        this.usersLoader = true;
        this.$store.dispatch('getImplementsListForUserProfile').then(() => {
            this.$store.dispatch('getVehiclesListForUserProfile').then(() => {
                this.$store.dispatch('getUserProfile');
            });
        });
    },
    data(){
        return{
            UserRoleType
        }
    },
    computed: {
        isFieldsValid() {
            if (this.userRole == UserRoleType.Manager) {
               return true;
            } else {
                if (checkFieldsValidity([
                    this.userProfile.firstName,
                    this.userProfile.email,
                    this.userProfile.primaryContactNumber,
                    this.userProfile.address.addressLine1,
                    this.userProfile.address.postalCode,
                ])) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },
    methods: {
        updateAccountProfile() {
            const crDate = moment().format('YYYY-MM-DD');
            let profileUpdateObj = {
                businessProfile: null,
                email: this.userProfile.email,
                firstName: this.userProfile.firstName,
                lastName: this.userProfile.lastName,
                primaryContactNumber: this.userProfile.primaryContactNumber,
                secondaryContactNumber: this.userProfile.secondaryContactNumber,
                secondaryFirstName: this.userProfile.secondaryFirstName,
                defaultVehicleId: this.userProfile.defaultVehicleId,
                defaultImplementId: this.userProfile.defaultImplementId,
                settings: {
                    workingStartTime: moment.utc(moment(crDate + " " + this.userProfile.settings.workingStartTimeConverted + ':00').utc()).format(),
                    workingEndTime: moment.utc(moment(crDate + " " + this.userProfile.settings.workingEndTimeConverted + ':00').utc()).format(),
                    allowedBreakTimeADayInMinutes: this.userProfile.settings.allowedBreakTimeADayInMinutes,
                }
            }

            if (this.userProfile.address.addressLine1 != null && (this.userProfile.address.addressLine1).trim() != "") {
                profileUpdateObj['address'] = this.userProfile.address;
            } else {
                profileUpdateObj['address'] = null;
            }
            this.$store.dispatch('updateUserProfile', profileUpdateObj).then((res) => {
                this.$store.dispatch('getUserProfile');
            });
        },
    }
}
