import {state, getters, mutations} from "./mutations";
import {actions} from "./actions";

export default {
    state: () => {
        return {...state}
    },
    mutations,
    getters,
    actions,
    namespaced: true
}