






















































import { containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import DescriptionPrimary from "@/components/shared/headers/description-primary.vue";
import ListLoader from "@/components/shared/loaders/list-loader.vue";
export default {
  name: "Tabs",
  props: [
    "tabs",
    "tabContents",
    "listLoaders",
    "emptyListTexts",
    "handleTabChange",
  ],
  components: {
    DescriptionPrimary,
    ListLoader,
  },
  data() {
    return {
      activeTab: null,
      containerLeftPad,
      containerRightPad,
    };
  },
  mounted() {
    this.observeVisibility();
  },
  methods: {
    observeVisibility() {
      let element = document.getElementById("tabs");
      if (window.IntersectionObserver) {
        this.intersectionObserver = new IntersectionObserver((entries) => {
          if (entries[0].intersectionRatio) {
            this.$refs.tabsRef.onResize();
          }
        });
        this.intersectionObserver.observe(element);
      }
    },
    unObserveVisibility() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
      }
    },
  },
  beforeDestroy: function () {
    this.unObserveVisibility();
  },
};
