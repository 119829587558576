


































































































































































































































































































































import { mapMutations, mapActions } from "vuex";
import moment from "moment";
import subscriptionsComputedMixin from "@/components/subscriptions/subscriptions-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { InvoiceStatusType } from "@/enum/invoiceStatusType";

export default {
  data() {
    return {
      polling: null,
      showStartTrialWarning: false,
      InvoiceStatusType,
    };
  },
  mixins: [subscriptionsComputedMixin],
  computed: {
    getTrialEndDate() {
      if (
        this.userProfile.businessProfile &&
        this.userProfile.businessProfile.trialEnd
      ) {
        return this.getLocalDate(this.userProfile.businessProfile.trialEnd);
      } else {
        return "";
      }
    },
  },
  components: {
    Button,
  },
  mounted() {
    this.subscriptionPlanLoader = true;
    this.setClearPromoCodeInfo();
  },
  methods: {
    ...mapMutations({
      setClearPromoCodeInfo: "setClearPromoCodeInfo",
    }),

    ...mapActions({
      getPromoCodeDetailsAsync: "getPromoCodeDetails",
    }),

    checkPaymentStatus() {
      this.polling = setInterval(() => {
        if (
          this.subscriptionDetails &&
          this.subscriptionDetails.latestInvoice != null &&
          this.subscriptionDetails.latestInvoice.status ==
            InvoiceStatusType.Open
        ) {
          this.$store.dispatch("getSubscriptionDetails");
        }
      }, 10000);
    },
    cancelSubscription() {
      this.dialogCancelSubscription = false;
      this.$store.dispatch("cancelSubscription");
    },
    beforeDestroy() {
      clearInterval(this.polling);
    },
    getLocalDate(date) {
      return moment.utc(date).local().format("DD MMM YYYY HH:mm");
    },

    getSubscriptionStatus(planStatus) {
      switch (planStatus) {
        case 0:
          return "Trialing";
        case 1:
          return "Active";
        case 2:
          return "Pending";
        case 3:
          return "Cancelled";
        case 4:
          return "Expired";
        default:
          return "Unknown";
      }
    },
    getPlanStatusClass(planStatus) {
      switch (planStatus) {
        case 0:
          return "sb-plan-status-active";
        case 1:
          return "sb-plan-status-active";
        case 2:
        case 3:
        case 4:
        default:
          return "sb-plan-status-expires";
      }
    },
    startSubscription() {
      if (!this.isSystemTrialPeriodOver) {
        this.showStartTrialWarning = true;
      } else {
        this.createSubscription();
      }
    },
    createSubscription() {
      this.showStartTrialWarning = false;
      this.$store.dispatch("createStripeSubscription").then(() => {
        setTimeout(() => {
          location.reload();
        }, 3000);
      });
    },
    withdrawCancellation() {
      this.$store.dispatch("withdrawCancellation");
    },
  },
};
