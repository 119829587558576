






























































import { BaseButton as Button } from "@/components/shared/buttons/index";
import LoginRegistrationWrapper from "@/components/layouts/login-registration-wrapper.vue";
import { routeLogin } from "@/utils/endpoints";
import resendCountdownMixin from "@/mixins/resend-countdown-mixin";
import { getCookie } from "@/utils/helpers";
import router from "@/router";

export default {
  name: "ResendEmailVerification",
  components: {
    Button,
    LoginRegistrationWrapper,
  },
  mixins: [resendCountdownMixin],
  data() {
    return {
      routeLogin,
      showUnverifiedEmailMessage: false
    };
  },
  computed: {
    userProfile: {
      get() {
        return this.$store.getters.userProfile;
      },
      set(value) {
        this.$store.state.usersModule.userProfile = value;
      },
    },
    sentCurrentRequest: {
      get() {
        return this.$store.getters.sentCurrentRequest;
      },
      set(value) {
        this.$store.state.sentCurrentRequest = value;
      },
    },
    emailAddress: {
      get() {
        return this.$route.params.email || this.userProfile.email;
      },
    },
    userId: {
      get() {
        return localStorage.getItem("userId");
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetResendStates();
    next();
  },
  beforeMount() {
    this.sentCurrentRequest = false;
    if (this.userId) {
      this.getUserAccountDetails();
    }
  },
  methods: {
    submit() {
      this.resendLoading = true;
      this.disableResendButton();
      this.$store.dispatch("resendEmailVerification").then((response) => {
        if (response) {
          this.checkCurrentRequestAndProceed();
        } else {
          this.enableResendButton();
        }

        this.resendLoading = false;
      });
    },
    logoutUser() {
      this.$store.dispatch("logout");
    },
    getUserAccountDetails() {
      return new Promise((resolve) => {
        this.$store.dispatch("getUserProfileForOnboard").then((response) => {
          if (response) {
            resolve(response);
          }
        });
      });
    },
    checkIfEmailVerifiedAndRedirectToMainPage() {
      if (getCookie("tkn")) {
        this.getUserAccountDetails().then((response) => {
          if (response.emailConfirmed) {
            this.$store.dispatch("navigateFlows", response);
          } else {
            let timeout = this.showUnverifiedEmailMessageAndSetInterval();
            clearInterval(timeout);
          }
        });
      } else {
        this.$store.dispatch("logout");
        router.push(routeLogin);
      }
    },
    showUnverifiedEmailMessageAndSetInterval() {
      this.showUnverifiedEmailMessage = true;
      return new Promise((resolve) => {
        const timeoutId = setTimeout(() => {
          this.showUnverifiedEmailMessage = false;
          resolve(timeoutId)
        }, 5000);
      })
    }
  },
};
