export const state = {
    jobsInProgressFields: [],
    fieldShapes: [],
    jobsFieldsMapsLabels: [],
    jobsFieldsMap: null,
    fieldInfowindow: null,
    loadingJobsFields: false
}

export const getters = {
    jobsInProgressFields: state => state.jobsInProgressFields,
    fieldShapes: state => state.fieldShapes,
    jobsFieldsMapsLabels: state => state.jobsFieldsMapsLabels,
    jobsFieldsMap: state => state.jobsFieldsMap,
    fieldInfowindow: state => state.fieldInfowindow,
    loadingJobsFields: state => state.loadingJobsFields
}
export const mutations = {
    setInProgressJobsFields(state, data) {
        data.map(job => {
            if(job.fields.length != 0){
                const jobField = {
                    jobNumber: job.number ? job.number : '',
                    customer: job.owner.businessProfile.title ? job.owner.businessProfile.title : '',
                    operation: job.operation.title ? job.operation.title : '',
                    fields: job.fields,
                    ownerId: job.owner.id,
                    jobId: job.id,
                }
                state.jobsInProgressFields.push(jobField)
            }
        })
    }
}