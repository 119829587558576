export const state = {
    teamMemberDetails: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        ownerId: null,
        roles: [],
    },
    teamMembersList: [],
    pendingTeamMemberList: [],
    teamMemberSearchText: '',
    teamMembersLoader: false,
    memberFilterType: 0,
    teamMembeOldRole: null
}

export const getters = {
    teamMembersLoader: state => state.teamMembersLoader,
    teamMemberDetails: state => state.teamMemberDetails,
    teamMembersList: state => state.teamMembersList,
    teamMembersListColumnTitles: state => state.teamMembersListColumnTitles,
    teamMemberSearchText: state => state.teamMemberSearchText,
    memberFilterType: state => state.memberFilterType,
    pendingTeamMemberList: state => state.pendingTeamMemberList,
    teamMembeOldRole: state => state.teamMembeOldRole
}
export const mutations = {
    setTeamMemberDetails(state, data) {
        state.teamMemberDetails['id'] = data.id;
        state.teamMemberDetails['email'] = data.email;
        state.teamMemberDetails['firstName'] = data.firstName;
        state.teamMemberDetails['lastName'] = data.lastName;
        state.teamMemberDetails['ownerId'] = data.ownerId;
        state.teamMemberDetails['roles'] = data.roles[0];
        state.teamMembeOldRole = data.roles[0].type;
    },

    setTeamMembersList(state, data) {
        data.map((data) => state.teamMembersList.push(data));
    },

    setPendingTeamMembersList(state, data) {
        data.map((x) => {
            const member = {
                "code": x.code,
                "inviterName": x.inviterName,
                "inviteeFirstName": x.inviteeFirstName,
                "inviteeLastName": x.inviteeLastName,
                "inviteeEmail": x.inviteeEmail,
                "inviteeRole": x.inviteeRole,
                "timestamp": x.timestamp,
                "expiry": x.expiry
            }
            state.pendingTeamMemberList.push(member);
        });
    },
    
    resetTeamMemberDetails(state) {
        state.teamMemberDetails['id'] = null;
        state.teamMemberDetails['email'] = null;
        state.teamMemberDetails['firstName'] = null;
        state.teamMemberDetails['lastName'] = null;
        state.teamMemberDetails['ownerId'] = null;
        state.teamMemberDetails['roles'] = [];
    },

    setClearTeamMembersList(state) {
        state.teamMembersList = [];
    }
}