
function generateComputed(stateName: string, moduleName: string) {
    return {
        [stateName]: {
            get() {
                return this.$store.getters[stateName];
            },
            set(newVal) {
                if(moduleName == 'root') {
                    this.$store.state[stateName] = newVal;
                } 
                else {
                    this.$store.state[moduleName][stateName] = newVal;
                }
            }
        }
    }
}

let dynamicComputedProperties = {};

function createDynamicProperties(computedProperties) {
 for(const [key, value] of Object.entries(computedProperties)) {
    const newValue: any = value;
    newValue.map(v => {
         const computedResult = generateComputed(v, key)
         dynamicComputedProperties = {...dynamicComputedProperties , ...computedResult }
     })
 }

 return dynamicComputedProperties
}

export const getComputedProperties = (computedProperties) => {
 createDynamicProperties(computedProperties);

 return {
     ...dynamicComputedProperties,
 }
}
