import StringConstants from "@/plugins/stringConstants";
import {
    routeOverview,
    routeUsers,
    routeBusinesses,
} from "@/utils/endpoints";

export default [
    {
        route: routeOverview,
        icon: require("../assets/su-admin.svg"),
        title: 'Admin',
        renderCondition: {primaryRole: "SuperAdmin"}
    },
    {
        route: routeOverview,
        icon: require("../assets/su-overview.svg"),
        title: StringConstants.overviewTitle,
        renderCondition: {primaryRole: "SuperAdmin"}
    },
    {
        route: routeUsers,
        icon: require("../assets/su-users.svg"),
        title: StringConstants.usersTitle,
        renderCondition: {primaryRole: "SuperAdmin"}
    },
    {
        route: routeBusinesses,
        icon: require("../assets/su-businesses.svg"),
        title: StringConstants.businessesTitle,
        renderCondition: {primaryRole: "SuperAdmin"}
    },
]