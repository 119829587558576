import ConstantValues from '@/plugins/constantValues';
import { getComputedProperties } from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'integrationsModule': [
        'loadingIntegration',
        'xeroAccessState',
        'sageAccessState',
        'sageAccess',
        'xeroAccess',
        'quickbookAccessState',
        'quickBookAccess',
        'integrationType'
    ],
    'invoicesModule': [
        'selectedInvoiceTabTextExternal',
        'invoiceTabTexts',
        'invoicesList',
        'invoiceSearchText',
        'noListData',
        'invoiceLoader',
        'invoiceSelectedTab',
        'invoiceRefs',
        'invoiceFilterStatus',
        'invoiceFilterStatusSelected',
        'invoiceFilterEmailStatusSelected',
        'invoiceJobsPreviewLoader',
        'invoiceJobsForPreview',
        'invoiceDraftPreviewDetails',
        'canOpenInvoice',
        'draftedInvoiceIdToOpen',
        'isInvoiceOpened',
        'invoiceDate',
        'invoiceLineItemJobNumbersDistinct',
        'invoiceLineItemsJobIds',
        'supplierBusinessProfileForInvoice',
        'supplierNameForInvoice',
        'supplierEmailForInvoice',
        'customersListForInvoice',
        'customersSearchText',
        'selectedCustomerIdForInvoice',
        'selectedJobsForInvoice',
        'jobsListForInvoice',
        'jobsForInvoiceSearchText',
        'supplierPhoneNumberForInvoice',
        'customerNameForInvoice',
        'customerEmailForInvoice',
        'customerPhoneNumberForInvoice',
        'customerBusinessProfileForInvoice',
        'dueDateForInvoice',
        'supplierPaymentInstructionsForInvoice',
        'invoiceSend',
        'invoiceShouldSendLater',
        'customersListForInvoiceLoader',
        'customersListForInvoiceSize',
        'invoicesListCount',
        'invoicesListFilterCustomerValue',
        'invoicesListFilterCustomersList',
        'invoicesListFilterCustomerSearchText',
        'invoicesListFilterCustomersDropdownLoader',
        'invoicesListFilterStartDate',
        'invoicesListFilterStartDateLocal',
        'invoicesListFilterEndDate',
        'invoicesListFilterEndDateLocal',
        'invoicesListFilterDateApplied',
        'showAddLineitemsForInternalInvoice',
        'invoiceInternalLineItemSearchText',
        'productListForInternalInvoice',
        'newLineItemsInitialsForInternal',
        'productFeatchingLoader',
        'newProductForInternalInvoice',
    ],
    'invoicesExternalModule': [
        'invoiceStatusEnum',
        'invoiceStatusTextEnum',
        'enableExternalInvoicingExternal'
    ],
    'jobsInvoicesModule': [
        'invoiceJobReportUrls',
        'invoiceReportGenerationInProgress'
    ],
    'excelExportModule': [
        'exportDataOffset',
        'exportDataTotal',
        'exportDataLoaded',
        'exportData',
    ],
    'root': [
        'noData',
        'filtersApplied',
        'listCount',
        'accountTab',
        'isPageRelated'
    ]
}

export default {
    name: 'InvoicesComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties),
        taxValuesListForInvoice: {
            get() {
                return this.$store.getters.invoiceConfiguration.salesTaxRates
            }
        },
        taxName: {
            get() {
                if(this.invoiceDraftPreviewDetails?.taxAccounts[0]?.taxName) {
                    return this.invoiceDraftPreviewDetails?.taxAccounts[0]?.taxName
                }
                return this.$store.getters.invoiceConfiguration.salesTaxName
            }
        },
        businessInfoUserId: {
            get() {
                return localStorage.getItem(ConstantValues.ownerId)
            }
        },
        getSenderId() {
            return localStorage.getItem(ConstantValues.ownerId)
        },
        currencySymbol(){
            return localStorage.getItem("currencySymbol");
        },
    }
}