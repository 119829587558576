import { apiFields, apiUsers, apiFarmers, apiAddresses } from '@/utils/endpoints';
import requests from '@/utils/requests';
import { deleteObjectFromList, devLogger, getPolygonCoords, notify, getConvertedAreaUnit } from '@/utils/helpers';
import buildUrl from 'build-url';
import loadGoogleMapsApi from 'load-google-maps-api';
import { 
    setLabelsOnMap, 
    setLabelsVisible, 
    setLabelsInvisible, 
    boundMapOnCordinates, 
    formatFieldObjectForLabel,
    createPolygonShapes,
    polygonMouseHoverEvent,
    setupPolygonInfoWindow,
    setupPolygonClickListener
} from '@/utils/helpers/gmaps-helpers';
import $ from 'jquery';
import FieldLabelComponent from '@/components/shared/labels/fields-label-stroke.vue';
import FieldLabelComponentLight from '@/components/shared/labels/fields-label-stroke-light.vue';
import Vue from 'vue';
import { UserRoleType } from '@/enum/userRoleType';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import StringConstants from '@/plugins/stringConstants';
import { ResponseList, Field } from '@/data/models';
import ConstantValues from '@/plugins/constantValues';
import { TeamJobMapFieldDto } from '@/data/models/TeamJobMapFieldDto';
import { state } from './mutations';

const fieldLabelConstructor = Vue.extend(FieldLabelComponent);
const fieldLabelLightConstructor = Vue.extend(FieldLabelComponentLight);

export const actions = {
    async getFieldsListFiltered({ state, commit, rootState, dispatch }) {
        if(state.fieldListOwnerId == null) return;
        state.fieldsTotalCount = 0;
        rootState.listLoading = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        let path = null;

        if (state.fieldListOwnerId == "All") {
            path = apiFields
        }
        else {
            path = state.fieldListOwnerId + apiFields
        }
        const url = buildUrl(rootState.baseUrl, {
            path: path + (rootState.tempUrl ? rootState.tempUrl : '?') + ConstantValues.preferenceQuery + state.fieldStatus
        });
        const result: ResponseList<Field> = await requests.getList(url, {
            rootState,
            cancellable: true
        });

        if (result != null) {
            state.fieldsTotalCount = rootState.totalRowCount;
            if (result.value.length > 0) {
                commit('setFieldFilteredList', result.value);
            }
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.value);
            }
            rootState.listLoading = false;
        }
    },

    async setFieldFilter({ state, commit }, data) {
        if (state.fieldSearchText?.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.fieldSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
    },

    async focusOnFieldOnFieldsMap({ state, dispatch }, field) {
        if (field && Array.isArray(field.polygon) && field.polygon.length > 0) {
            const polygonPoints = field.polygon

            const bounds = new google.maps.LatLngBounds()

            polygonPoints.map(polygon => {
                const LatLng = new google.maps.LatLng(polygon.latitude, polygon.longitude)
                bounds.extend(LatLng)
            })

            if (state.fieldsListMap) {
                state.fieldsListMap.fitBounds(bounds)
                state.fieldsListMap.setZoom(16)
            }

            dispatch('highlightFieldOnMap', [polygonPoints, state.fieldsListMap, field])
        }
    },

    async addNewField({ state, rootState, commit, dispatch }, data) {

        const addNewFieldPolygonToMapAndList = async (newField) => {
            const highlightShouldNotTimeout = newField.owner.id === state.fieldListOwnerId;
            if (state.fieldStatus == 1) {
                state.fieldStatus = 0;
            } 
            if (state.fieldListOwnerId === newField.owner.id) {
                commit('appendFieldToStartOfFieldFilteredList', result[0]);
                state.fieldsTotalCount = state.fieldsTotalCount + 1;
                state.totalRowCount = state.totalRowCount + 1;
            }
            const PolygonShape = createPolygonShapes(state.fieldsListMap, newField);
            state.fieldsListMapData.push(newField);
            state.fieldsListFieldShapes.push(PolygonShape);
            polygonMouseHoverEvent(PolygonShape, rootState);
            setupPolygonInfoWindow(state, rootState, newField, PolygonShape);
            setupPolygonClickListener(state, rootState, commit, newField, PolygonShape);
            boundMapOnCordinates(state.fieldsListMap, [{...newField, polygon: JSON.stringify(newField.polygon)}]);
            dispatch('highlightFieldOnMap', [newField.polygon, state.fieldsListMap, newField, highlightShouldNotTimeout])
        }

        state.addFieldLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: data[1] + apiFields
        });
        const result = await requests.postData(url, data[0]);
        if (result[0] != null) {
            if(result[0]?.id) {
                state.polygon?.setEditable(false);
                state.polygon?.setMap(null);
                state.tempFieldPolygon?.setEditable(false);
                state.tempFieldPolygon?.setMap(null);
                state.fieldsListMapOffset = state.fieldsListMapOffset + 1;
                state.fieldsListMapLoaded = state.fieldsListMapLoaded + 1;
                state.fieldsListMapTotal = state.fieldsListMapTotal + 1;
                state.totalFieldsCount = state.totalFieldsCount + 1;

                commit('setClearFilterOptions');
                commit('clearSnackbarDetails');
                await addNewFieldPolygonToMapAndList(result[0]);
            }
        }
        else {
            if (result[1].response.data.title != null) {
                notify(result[1].response.data.title, 'fail');
            }
            else {
                notify(StringConstants.fieldNotCreated, 'fail');
            }
        }
        state.addFieldLoader = false;
    },

    async getFieldDetailsById({ state, rootState, commit, dispatch }, ids) {
        const ownerId = ids[1]
        const fieldId = ids[0]
        if (ownerId && fieldId) {
            state.fieldLoader = true;
            const url = buildUrl(rootState.baseUrl, {
                path: ownerId + apiFields + "/" + fieldId
            });
            const result = await requests.getData(url, true);
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    state.fieldLoader = false;
                    dispatch('getUserFarmList', result.data.owner.id);
                    commit('setCurrentOwnerToOwnerList', result.data.owner);
                    commit('setFieldDetails', result.data);
                    dispatch('calculateAndSetPolygonArea', result.data.polygon);

                    return result.data;
                }
            }
        }
    },

    async getYearList({ commit }) {
        const start = 1990;
        const end = new Date().getFullYear();
        for (let i = end; i >= start; i--) {
            const year = {
                year: i
            }
            commit('setYearList', year);
        }
    },

    async updateFieldDetails({ state, rootState, commit, dispatch }, data) {

        const addUpdatedFieldPolygonToMapAndList = async (updatedField) => {
            const highlightShouldNotTimeout = updatedField.owner.id === state.fieldListOwnerId;
            if (state.fieldStatus == 1) {
                state.fieldStatus = 0;
            }

            state.fieldsListFieldShapes[data[1].shapeIndex]?.setMap(null);
            const PolygonShape = createPolygonShapes(state.fieldsListMap, updatedField);
            state.polygon = PolygonShape;
            state.fieldsListFieldShapes.splice(data[1].shapeIndex, 1, PolygonShape);
            setupPolygonClickListener(state, rootState, commit, updatedField, PolygonShape);
            polygonMouseHoverEvent(PolygonShape, rootState);

            setupPolygonInfoWindow(state, rootState, updatedField, PolygonShape);
            boundMapOnCordinates(state.fieldsListMap, [{...updatedField, polygon: JSON.stringify(updatedField.polygon)}]);
            dispatch('highlightFieldOnMap', [updatedField.polygon, state.fieldsListMap, updatedField, highlightShouldNotTimeout])


        }

        state.addFieldLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: state.oldOwnerId + apiFields + "/" + data[1].id
        });
        const result = await requests.putData(url, data[0]);
        if (result[0] != null) {
            state.polygon?.setEditable(false);
            state.tempFieldPolygon?.setEditable(false);
            state.polygon?.setMap(null);
            state.tempFieldPolygon?.setMap(null);
            state.editingAreaOnMap = null;
            state.addFieldLoader = false;
            if (state.fieldListOwnerId !== "All") {
                const fieldPreviousHighlightIndex = state.highlightedFieldsListMapData.findIndex(field => field.id === result[0].id);
                state.highlightedField[fieldPreviousHighlightIndex]?.setMap(null);
                state.highlightedField.splice(fieldPreviousHighlightIndex, 1);
                deleteObjectFromList(state.highlightedFieldsListMapData, result[0].id, 'id');
            }
            commit('updateFieldInFieldFilteredList', result[0]);
            await addUpdatedFieldPolygonToMapAndList(result[0]);
            return result[0];
        }
        else {
            if (result[1].response.data.title != null) {
                notify(result[1].response.data.title, 'fail');
            }
            else {
                notify(StringConstants.fieldNotUpdated, 'fail');
            }
        }
        state.addFieldLoader = false;
    },

    async deleteField({ rootState, state, dispatch }, {fieldId, fieldOwnerId}) {
        const url = buildUrl(rootState.baseUrl, {
            path: fieldOwnerId + apiFields + "/" + fieldId + "?Preference=" + state.fieldStatus
        });
        const result = await requests.deleteData(url, true);
        if (result != null && 'data' in result) {
            dispatch('removeFieldsAndUpdateMapBounds', [fieldId]);
            notify(StringConstants.fieldDeleted, 'success');
        } else {
            if (result[1].response.data.title != null) {
                notify(result[1].response.data.title, 'fail');
            }
            else {
                notify(StringConstants.fieldNotDeleted, 'fail');
            }
        }
    },

    async setSearchFarmersFilter({ state, commit, dispatch }, {offset, limit, moduleNamespace}) {
        await dispatch(`${moduleNamespace}/setSearchFilters`, {
            offset,
            limit, 
            searchText: state.fieldFarmerSearchText, 
            keepSearchText: state.fieldFarmerSearchText != 'All' && state.fieldFarmerSearchText != null
        });
    },

    async setSearchInternalFarmersFilter({ state, commit, dispatch },  {offset, limit, moduleNamespace}) {
        await dispatch(`${moduleNamespace}/setSearchFilters`, {
            offset,
            limit, 
            searchText: state.fieldFarmerSearchTextInternal || '', 
            keepSearchText: (state.fieldDetails.owner === null || state.fieldDetails.owner === '')
        });
    },

    async getOwnerList({ state, commit, rootState, dispatch }, { queryString }) {
        if (rootState.loginUserRole != UserRoleType.Farmer) {

            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + (queryString || '')
            });

            state.fieldsCustomersFilterDropdownLoader = true;
            const result = await requests.getData(url, true);
            state.fieldsCustomersFilterDropdownLoader = false;
            
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    if (result.data.value.length <= 0) {
                        rootState.searchNotify = StringConstants.noSearchResultFoundText;
                    }
                    commit('setOwnerList', result.data.value);
                    commit('setOwnerListSize', result.data.size);
                }
                
                return result;
            }
        }
    },

    async getFieldsOwnerListInternal({ state, commit, rootState, dispatch }, {queryString}) {
        if (rootState.loginUserRole != UserRoleType.Farmer) {
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + (queryString || '') + (queryString ? '&' : '?') + 'IsInternal=true'
            });
            
            state.fieldsCustomersFilterDropdownLoader = true;
            const result = await requests.getData(url);
            state.fieldsCustomersFilterDropdownLoader = false;

            if (result != null && 'data' in result) {
                
                if (result.data != null) {
                    if (result.data.value.length <= 0) {
                        rootState.searchNotify = StringConstants.noSearchResultFoundText;
                    }
                    commit('setOwnerList', result.data.value);
                    commit('setOwnerListSize', result.data.size);
                }

                return result;
            }
        }
    },

    async getFieldsOwnerListInternalBulkUpload({ state, commit, rootState, dispatch }, {queryString}) {
        if (rootState.loginUserRole != UserRoleType.Farmer) {
           
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + (queryString || '') + (queryString ? '&' : '?') + 'IsInternal=true'
            });

            const result = await requests.getData(url);

            if (result != null && 'data' in result) {
                if (result.data != null) {
                    if (result.data.value.length <= 0) {
                        rootState.searchNotify = StringConstants.noSearchResultFoundText;
                    }
                    commit('setOwnerListBulkUpload', result.data.value);
                }

                return result;
            }
        }
    },

    async getUserFarmList({ state, commit, rootState }, id) {
        if(id) {
            state.fieldFarmList = [];
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + id + apiAddresses + "?type=0"
            });
            const result = await requests.getData(url, true);
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    if (result.data.value.length > 0) {
                        commit('setFieldFarmList', result.data.value);
                    }
                }
            }
        }
    },

    async calculateAndSetPolygonArea({state}, polygon) {
        const computedArea = getPolygonArea();
        if (computedArea > 0) {
            state.areaInvalid = false;
        } else {
            state.areaInvalid = true;
        }

        setPolygonArea(computedArea);

        function getPolygonArea() {
            const computedArea = google.maps.geometry.spherical.computeArea(polygon.getPath());
            return parseFloat(computedArea.toFixed(2));
        }
        function setPolygonArea(polygonArea) {
            state.fieldDetails.areaInSquareMeter = polygonArea;
            state.areaInSquareMeter = polygonArea;
            state.convertedArea = parseFloat(getConvertedAreaUnit(polygonArea));
        }
    },

    async setFieldCoordinates({commit}, polygon) {
        const path = polygon.getPath();
        for (let i = 0; i < path.length; i++) {

            const data = {
                latitude: path.getAt(i).lat(),
                longitude: path.getAt(i).lng()
            }

            if (data != null) {
                if (i == 0) {
                    commit("setAddFieldCord", null);
                }
                commit("setAddFieldCord", data);
            }
        }
    },

    async addEventListenerToPolygon({state, commit, dispatch}, polygon) {
        const pathChangeEvents = ['set_at', 'insert_at', 'remove_at'];
        pathChangeEvents.forEach(eventName => polygon.getPath().addListener(eventName, () => {
            updatePolygon(polygon);
        }))

        function updatePolygon(polygon) {
            dispatch('calculateAndSetPolygonArea', polygon);
            dispatch('setFieldCoordinates', polygon);
        }
    },

    async initializeDrawingManager({state, dispatch}, polygonOptions = null) {
        state.drawingManager?.setMap(null);
        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: polygonOptions || ConstantValues.defaultDrawingManagerPolygonOptions
        });

        if (!state.fieldsInfoWindowContent) {
            state.fieldsInfoWindowContent = new window.google.maps.InfoWindow();
        }

        drawingManager.addListener('polygoncomplete', (polygon: any) => {
            const lastVertex = polygon.getPath().getAt(polygon.getPath().length - 1);
            drawingManager.setOptions({
                drawingControl: false,
            });
            drawingManager.setDrawingMode(null);
            state.tempFieldPolygon = polygon;
            state.fieldsInfoWindowContent.setContent(`<div style="padding: 10px">${StringConstants.fieldInfoWindowMessage}</div>`);
            state.fieldsInfoWindowContent.setPosition({lat: lastVertex.lat(), lng: lastVertex.lng()});
            state.fieldsInfoWindowContent.open(state.map);
            setTimeout(function() {
                state.fieldsInfoWindowContent.close();
            }, ConstantValues.defaultInfoWindowTimeout)
            dispatch('setFieldCoordinates', polygon);
            dispatch('calculateAndSetPolygonArea', polygon)
            .then(() => dispatch('addEventListenerToPolygon', polygon));
        });

        drawingManager.setMap(state.fieldsListMap || state.map);
        state.drawingManager = drawingManager;
    },

    async getGoogleMapToAddField({ state, commit, dispatch }, divId) {
        state.polygonShape = null;
        state.polyPath = null;
        state.map = null;
        $('#' + divId).html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(async () => {
            const latitude = state.fieldsListMap?.getCenter().lat() || ConstantValues.defaultBusinessLatitude;
            const longitude = state.fieldsListMap?.getCenter().lng() || ConstantValues.defaultBusinessLongitude;
            const mapZoom = state.fieldsListMap?.getZoom() || ConstantValues.defaultMapZoom;
            const { google } = window;
            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": parseFloat(latitude), "lng": parseFloat(longitude) }),
                mapTypeControl: true,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
                }
            };
            state.map = new google.maps.Map(document.getElementById(divId), mapOptions);

            await dispatch('initializeDrawingManager')
                
            let tempFields = [];

            function localClearTempFields() {
                tempFields = []
            }
            if (state.fieldsListMapData.length > 0) {
                state.fieldsListMapData.map(field => {
                    const fieldInfo = {
                        field: field,
                        customer: (field.owner.businessProfile ? field.owner.businessProfile.title : ((field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''))),
                        ownerId: field.owner.id ? field.owner.id : null,
                        fieldId: field.id,
                        fieldColor: '#ffffff'
                    }
                    tempFields.push(fieldInfo)
                })

                if (tempFields && tempFields.length > 0 && state.fieldsListMapData && state.fieldsListMapData.length > 0) {
                    if(state.totalFieldsCount < ConstantValues.defaultPageLimitForJobsFields) {
                        await dispatch('getFieldsListOnMapReadOnly', tempFields);
                        await dispatch('setFieldsListLabelsReadOnly', tempFields);
                    }
                    commit('setFieldsListForSearchReadOnly', tempFields);
                } else {
                    console.error('No fields found')
                }
                localClearTempFields()
            }
        });
    },

    async editAreaOnMap({ state, dispatch }) {
        const [x, y, z, polygonCoordinates] = getPolygonCoords(state.fieldDetails.polygon);
        if (state.polygon) {
            state.polygonShape = {
                paths: polygonCoordinates
            };
            state.polygon.setEditable(true);
            dispatch('addEventListenerToPolygon', state.polygon);
        }
    },

    async resetAreaToInitialState({state, dispatch}) {
        state.polygonShape && state.polygon?.setPath(state.polygonShape?.paths);
        if(state.highlightedFieldsListMapData && state.highlightedFieldsListMapData.length) {
            const fieldIndex = state.highlightedFieldsListMapData.findIndex(field => field.id === state.fieldDetails.id);
            state.highlightedField[fieldIndex]?.setMap(state.fieldsListMap);
        }
        
        await dispatch('setFieldCoordinates', state.polygon)
        .then(async () => {
            await dispatch('calculateAndSetPolygonArea', state.polygon)
            .then(() => {
                state.polygon?.setEditable(false);
                state.polygon?.setMap(state.fieldsListMap || state.map);
                state.drawingManager?.setDrawingMode(null);
                state.drawingManager?.setOptions({
                    drawingControl: false
                });
                state.tempFieldPolygon?.setMap(null);
            })
        })
        
    },

    async resetDrawingManagerAndTempStates({state, dispatch, commit}) {
        commit('clearFieldsPolygons');
        if (!state.drawingManager) dispatch('initializeDrawingManager');
        else state.drawingManager.setOptions({
            drawingMode: google.maps.drawing.OverlayType.POLYGON, 
            drawingControl: true
        });
    },

    async getGoogleMapToEditField({ state }, data) {

        const polygonPath = data[0];
        if (state.colorUpdateOnMap != true) {
            $('#' + data[1]).html("");
        }
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const colorCode = state.fieldDetails.colour;
            const polygonData = getPolygonCoords(polygonPath);
            const x: any = polygonData[0];
            const y: any = polygonData[1];
            const z: any = polygonData[2];
            const fieldCoords: any = polygonData[3];
            const latitude = Math.atan2(z, Math.sqrt(x * x + y * y)) * 180 / Math.PI;
            const longitude = Math.atan2(y, x) * 180 / Math.PI;
            const { google } = window;
            const mapZoom = ConstantValues.defaultMapZoom;
            const mapOptions = {
                center: new google.maps.LatLng(latitude, longitude),
                zoom: mapZoom,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
                }
            };
            if (state.colorUpdateOnMap != true) {
                state.map = new google.maps.Map(document.getElementById(data[1]), mapOptions);
            }
            const lineSymbol = {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                strokeWeight: 3,
                scale: 1,
            };

            const polygonOptions = {
                paths: fieldCoords,
                strokeColor: colorCode,
                strokeOpacity: 0.7,
                fillColor: colorCode,
                fillOpacity: 0.4,
                strokeWeight: 2
            };

            state.polygon = new google.maps.Polygon(polygonOptions);
            state.polygonShape = polygonOptions;

            const computedArea = (google.maps.geometry.spherical.computeArea(state.polygon.getPath()));
            if (computedArea > 0) {
                state.areaInvalid = false;
            }
            else {
                state.areaInvalid = true;
            }

            if (state.mountedGMaps) {
                state.areaInSquareMeter = parseFloat(computedArea.toFixed(2));
                if(parseFloat(state.fieldDetails.areaInSquareMeter) > 0) {
                    state.fieldDetails.areaInSquareMeter = parseFloat(state.fieldDetails.areaInSquareMeter)
                } else {
                    state.fieldDetails.areaInSquareMeter = state.areaInSquareMeter;
                }
                state.convertedArea = parseFloat(getConvertedAreaUnit(state.fieldDetails.areaInSquareMeter));
            } else {
                if (computedArea != 0 && state.fieldDetails.areaInSquareMeter == 0) {
                    state.areaInSquareMeter = parseFloat(computedArea.toFixed(2));
                    state.fieldDetails.areaInSquareMeter = state.areaInSquareMeter > 0 ? state.areaInSquareMeter : 0;
                    state.convertedArea = parseFloat(getConvertedAreaUnit(state.fieldDetails.areaInSquareMeter));
                }
                state.areaInSquareMeter = parseFloat(state.fieldDetails.areaInSquareMeter);
                state.convertedArea = parseFloat(getConvertedAreaUnit(state.areaInSquareMeter));
            }

            state.fieldCordList = [];
            state.fieldCordList = polygonPath;

            state.polygon.setMap(state.map);

            const bounds = new google.maps.LatLngBounds();

            for (let i = 0; i < fieldCoords.length; i++) {
                const LatLng = new google.maps.LatLng(fieldCoords[i].lat, fieldCoords[i].lng);
                bounds.extend(LatLng);
            }

            if (fieldCoords.length != 0) {
                state.map.fitBounds(bounds);
            }

            const listener = google.maps.event.addListener(state.map, "idle", function () {
                state.map.fitBounds(bounds, ConstantValues.defaultMapPadding);
                google.maps.event.removeListener(listener);
            });
        });
    },
    clearPolygonOnMap({ state }) {
        if (state.polygonShape)
            state.polygonShape.setMap(null)
        if (state.polyPath)
            state.polyPath.setMap(null)
    },
    handleBulkUploadFieldsResponse({ rootState }, response) {
        if(response.data.failed) {
            rootState.bulkActionResponse = response.data
            rootState.dialogBulkActionResponseTitle = StringConstants.bulkActionResponseTitleFiledsImport
            rootState.dialogBulkActionResponse = true
        } else {
            notify(StringConstants.fieldsBulkUploadSuccess, 'success')
            setTimeout(() => location.reload(), 2000)
        }
        return response
    },
    async bulkUploadFields({ state, rootState, dispatch }, data) {
        state.bulkUploadInProgress = true
        rootState.bulkActionResponse = null

        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/fields/import'
        })
        const result = await requests.postBulkFields(url, data)
        
        state.bulkUploadInProgress = false
        if (result[0] != null && result[0].status == 200) {
            return await dispatch('handleBulkUploadFieldsResponse', result[0])
        }
    },
    async bulkUploadFieldsContractor({ state, rootState, dispatch }, data) {
        state.bulkUploadInProgress = true
        rootState.bulkActionResponse = null

        const url = buildUrl(rootState.baseUrl, {
            path: state.bulkUploadOwner + '/fields/import'
        });
        const result = await requests.postBulkFields(url, data)
        
        state.bulkUploadInProgress = false
        if (result[0] != null && result[0].status == 200) {
            return await dispatch('handleBulkUploadFieldsResponse', result[0])
        }
    },
    async getAllFieldsListForMap({ state, rootState, commit, dispatch }) {
        state.fieldsListMapLoader = true;

        const callStackSize = ConstantValues.defaultPageLimitForJobsFields;

        const url = buildUrl(rootState.baseUrl, {
            path: '/fields?offset=0&limit=1'
        });
        const fieldCountResponse = await requests.getData(url);
            
        state.totalFieldsCount = fieldCountResponse?.data?.size
       
        const fieldsCallCounter = parseInt((state.totalFieldsCount / callStackSize).toString()) || 1
        const urlPaths = []

        for (let i = 0; i <= fieldsCallCounter; i++) {
            let path = rootState.baseUrl;
            path += apiFields + '?' + 'Preference=' + state.fieldStatus + '&' + ConstantValues.limitQuery + '=' + callStackSize + '&offset=' + i * callStackSize

            urlPaths.push(path)
        }

        commit('clearFieldsListMapData');
        await Promise.all(urlPaths.map(async (urlPath) => {
            const result = await requests.getData(urlPath, true);
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    state.fieldsListMapOffset = result.data.value.length;
                    state.fieldsListMapTotal = result.data.size;
                    commit('setFieldsListMapData', result.data.value);
                    commit('setFieldsLoadedCounter', null);
                }
            }
        }));

        if (!state.fieldsListMap) {
            await dispatch('getGoogleMapForFieldsListMap','field-list-map');
        } else if (state.fieldsListFieldShapes.length === 0) {
            await dispatch('attachExistingGoogleMapsObjectToDom', 'field-list-map');
        }
        await dispatch('drawFieldsOnMap');
        await dispatch('highlightFieldsOnMap');
        await dispatch("setMapBoundsForFieldsInFieldsList");
        state.fieldsListMapLoader = false;
    },
    async getGoogleMapForFieldsListMap({ state }, mapDiv) {
        state.fieldsListMapLoader = true;
        await loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const mapZoom = ConstantValues.defaultMapZoom;
            const { google } = window;

            const mapLat = localStorage.getItem('defaultStartingLat') ? parseFloat(localStorage.getItem('defaultStartingLat')) : ConstantValues.defaultBusinessLatitude;
            const mapLng = localStorage.getItem('defaultStartingLng') ? parseFloat(localStorage.getItem('defaultStartingLng')) : ConstantValues.defaultBusinessLongitude;

            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: { lat: mapLat, lng: mapLng },
                mapTypeControl: true,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
                },
                options: {
                    gestureHandling: 'cooperative'
                }
            }

            state.fieldsListMap = new google.maps.Map(document.getElementById(mapDiv), mapOptions)

            state.fieldsInfoWindowContent = new google.maps.InfoWindow()
        });
    },

    async getFieldsListOnMap({ state, commit, rootState }, data) {
        try {
            const fields = data.map(fieldInfo => fieldInfo.field);
            const fieldInfos = data.map(fieldInfo => {
                return {
                    ownerId: fieldInfo.field.owner.id,
                    fieldName: fieldInfo.field.title,
                    fieldId: fieldInfo.fieldId,
                    fieldColor: fieldInfo.fieldColor ? fieldInfo.fieldColor : '#ffffff',
                    fieldArea: fieldInfo.field.areaInSquareMeter,
                    fieldCrop: fieldInfo.field.crop ? fieldInfo.field.crop : { name: '', year: '' },
                    fieldCustomer: fieldInfo.customer ? fieldInfo.customer : '',
                    fieldEditable: fieldInfo.field.owner.isInternal || fieldInfo.field.owner.id === localStorage.getItem(ConstantValues.ownerId)
                }
            });

            for (let i = 0; i < fields.length; i++) {
                if (fields[i].polygon.length > 0) {
                    const dataField = new TeamJobMapFieldDto(fields[i]);
                    const PolygonShape = createPolygonShapes(state.fieldsListMap, dataField)
                    polygonMouseHoverEvent(PolygonShape, rootState);
                    setupPolygonInfoWindow(state, rootState, fields[i], PolygonShape);
                    setupPolygonClickListener(state, rootState, commit, fields[i], PolygonShape);
                    state.fieldsListFieldShapes.push(PolygonShape);
                    PolygonShape.setMap(state.fieldsListMap);
                }
            }

        } catch (e) {
            console.error(e.toString())
        }
    },

    async setMapBoundsForFieldsList({ state }, data) {
        const fields = data.map(fieldInfo => fieldInfo.field);
        const parsedFields = []
        fields.map(f => {
            const field = {
                ...f,
                polygon: Array.isArray(f.polygon) ? f.polygon : JSON.stringify(f.polygon) 
            }
            parsedFields.push(field)
        })
        boundMapOnCordinates(state.fieldsListMap, parsedFields);
    },

    async setFieldsListLabels({ state }, data) {
        try {
            if (data) {
                if (state.fieldsListMapLabels.length > 0 && state.fieldsListMap) {
                    for (let i = 0; i < state.fieldsListMapLabels.length; i++) {
                        state.fieldsListMapLabels[i].setMap(null);
                    }
                }
                for (let i = 0; i < data.length; i++) {
                    const tempBounds = new google.maps.LatLngBounds();
                    const fieldPoly = JSON.parse(data[i].field.polygon);
                    if (fieldPoly.length > 0) {
                        const fieldName = data[i].field.title;

                        const fieldLabelComponent = new fieldLabelConstructor({ propsData: { fieldName } });

                        fieldLabelComponent.$mount();

                        const fieldLabelString = new XMLSerializer().serializeToString(fieldLabelComponent.$el);

                        const fieldLabelUrl = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(fieldLabelString);

                        for (let j = 0; j < fieldPoly.length; j++) {
                            const x = {
                                lat: fieldPoly[j].latitude,
                                lng: fieldPoly[j].longitude
                            }
                            const BoundLatLng = new google.maps.LatLng({ "lat": parseFloat(x.lat), "lng": parseFloat(x.lng) });
                            tempBounds.extend(BoundLatLng);
                        }
                        const centroid = tempBounds.getCenter();

                        const markerLabel = new google.maps.Marker({
                            position: centroid,
                            icon: fieldLabelUrl
                        });

                        state.fieldsListMapLabels.push(markerLabel);
                    }
                }

                if (state.fieldsListMapLabels.length > 0) {
                    for (let i = 0; i < state.fieldsListMapLabels.length; i++) {
                        state.fieldsListMapLabels[i].setMap(state.fieldsListMap);
                    }
                }

                setLabelsOnMap(state.fieldsListMapLabels, state.fieldsListMap)

                setLabelsInvisible(state.fieldsListMapLabels)

                if (google.maps && state.fieldsListMap) {
                    google.maps.event.addListener(state.fieldsListMap, ConstantValues.defaultZoomChangedEvent, function () {
                        const zoomLevel = state.fieldsListMap.getZoom();
                        if (zoomLevel >= ConstantValues.defaultLabelVisibilityZoomLevel) {
                            setLabelsVisible(state.fieldsListMapLabels)
                        } else {
                            setLabelsInvisible(state.fieldsListMapLabels)
                        }
                    });
                }

            }
        }
        catch (e) {
            devLogger().logError(e);
        }
    },
    async clearFieldListsLabelsAndFields({ state }) {
        if (state.fieldsListFieldShapes.length > 0) {
            for (let i = 0; i < state.fieldsListFieldShapes.length; i++) {
                state.fieldsListFieldShapes[i].setMap(null)
            }
        }
        if (state.fieldsListMapLabels.length > 0) {
            for (let i = 0; i < state.fieldsListMapLabels.length; i++) {
                state.fieldsListMapLabels[i].setMap(null)
            }
        }
        state.fieldsListFieldShapes = []
        state.fieldsListMapLabels = []
        state.fieldsListMapData = []
        state.fieldsListMapLoaded = null
        state.fieldsListMapTotal = null
        state.fieldsListMapOffset = null
    },

    async addFieldCrop({ rootState }, data) {
        const { ownerId, resourceId, cropObj } = data

        const url = buildUrl(rootState.baseUrl, {
            path: ownerId + apiFields + "/" + resourceId + '/crops'
        })
        const result = await requests.postData(url,
            cropObj
        )
        if (result && result[0]) {
            return result[0]
        }
    },

    async getJobsByField({ rootState, commit }, data) {
        const { ownerId, resourceId } = data
        if (ownerId && resourceId) {
            const url = buildUrl(rootState.baseUrl, {
                path: ownerId + apiFields + "/" + resourceId + '/jobs'
            })
            const result = await requests.getData(url);

            if (result && result.data) {
                commit('setJobsByField', result.data.value)
                return result.data.value
            }
        }
    },

    async mountGoogleMapForAreaCalculation({ state }, divId) {
        state.polygonShape = null
        state.polyPath = null
        state.map = null
        $('#' + divId).html('')
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const defaultStartingLat = localStorage.getItem('defaultStartingLat')
            const defaultStartingLng = localStorage.getItem('defaultStartingLng')
            const latitude = defaultStartingLat ? defaultStartingLat : ConstantValues.defaultLatitude
            const longitude = defaultStartingLng ? defaultStartingLng : ConstantValues.defaultLongitude
            const mapZoom = ConstantValues.defaultMapZoom
            const { google } = window
            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": parseFloat(latitude), "lng": parseFloat(longitude) }),
                mapTypeControl: true,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.map = new google.maps.Map(document.getElementById(divId), mapOptions)
        })
    },

    async getGoogleMapForAreaCalculation({ state }, data) {
        const polygonPath = data[0]
        $('#bulk-upload-map').html("")
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'geometry']
        }).then(() => {
            const polygonData = getPolygonCoords(polygonPath)
            const x: any = polygonData[0]
            const y: any = polygonData[1]
            const z: any = polygonData[2]
            const fieldCoords: any = polygonData[3]
            const latitude = Math.atan2(z, Math.sqrt(x * x + y * y)) * 180 / Math.PI
            const longitude = Math.atan2(y, x) * 180 / Math.PI
            const { google } = window
            const mapZoom = ConstantValues.defaultMapZoom
            const mapOptions = {
                center: new google.maps.LatLng(latitude, longitude),
                zoom: mapZoom,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID
            }
            state.map = new google.maps.Map(document.getElementById('bulk-upload-map'), mapOptions)
            const lineSymbol = {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                strokeWeight: 3,
                scale: 1,
            }

            state.polygonShape = new google.maps.Polygon({
                paths: fieldCoords,
                strokeColor: '#ffffff',
                strokeOpacity: 0,
                fillColor: '#ffffff',
                fillOpacity: 0.1
            })

            state.polyPath = new google.maps.Polyline({
                path: fieldCoords,
                geodesic: true,
                strokeColor: '#ffffff',
                strokeOpacity: 0,
                icons: [{
                    icon: lineSymbol,
                    offset: '0',
                    repeat: '5px'
                }],
            })
            const computedArea = (google.maps.geometry.spherical.computeArea(state.polygonShape.getPath()))
            state.computedAreas.push(computedArea)

            state.fieldCordList = []
            state.fieldCordList = polygonPath
            state.polygonShape.setMap(state.map)
            state.polyPath.setMap(state.map)

            const bounds = new google.maps.LatLngBounds()

            for (let i = 0; i < fieldCoords.length; i++) {
                const LatLng = new google.maps.LatLng(fieldCoords[i].lat, fieldCoords[i].lng)
                bounds.extend(LatLng)
            }

            if (fieldCoords.length != 0) {
                state.map.fitBounds(bounds)
            }

            const listener = google.maps.event.addListener(state.map, "idle", function () {
                state.map.fitBounds(bounds, ConstantValues.defaultMapPadding)
                google.maps.event.removeListener(listener)
            })
        })
    },

    async getFieldsListOnMapReadOnly({ state }, data) {
        try {
            const fields = data.map(fieldInfo => fieldInfo.field);

            for (let i = 0; i < fields.length; i++) {
                if (fields[i].polygon.length > 0) {
                    const polygonLatLng = [];
                    let cords = null;
                    let cordColor = null;
                    cords = JSON.parse(fields[i].polygon);

                    for (let i = 0; i < cords.length; i++) {
                        const x = {
                            lat: cords[i].latitude,
                            lng: cords[i].longitude
                        }
                        polygonLatLng.push(x);
                    }

                    cordColor = '#ffffff'

                    const PolygonShape = new google.maps.Polygon({
                        paths: polygonLatLng,
                        strokeColor: cordColor,
                        map: state.map,
                        strokeWeight: 3,
                        strokeOpacity: 0.6,
                        fillColor: cordColor,
                        fillOpacity: 0.2,
                    });

                    state.fieldsListFieldShapesReadOny.push(PolygonShape);
                    state.fieldsListFieldShapesReadOny[state.fieldsListFieldShapesReadOny.length - 1].setMap(state.map);
                }
            }
        } catch (e) {
            console.error(e.toString())
        }
    },

    async setMapBoundsForFieldsListReadOnly({ state, dispatch }, data) {
        const fields = data.map(fieldInfo => fieldInfo.field);
        const parsedFields = []
        fields.map(f => {
            const field = {
                ...f,
                polygon: Array.isArray(f.polygon) ? f.polygon : JSON.stringify(f.polygon) 
            }
            parsedFields.push(field)
        })
        boundMapOnCordinates(state.map, parsedFields);
    },

    async getFeildsByOwnerReadOnly({ state, dispatch, commit }, ownerId) {
        let tempFieldsReadonly = []

        if (ownerId) {
            if (state.fieldsListFieldShapesReadOny.length > 0) {
                for (let i = 0; i < state.fieldsListFieldShapesReadOny.length; i++) {
                    state.fieldsListFieldShapesReadOny[i].setMap(null)
                }
            }

            if (state.fieldsListMapData.length > 0) {
                state.fieldsListMapData.map(field => {
                    if (field.owner && field.owner.id && ownerId === field.owner.id) {
                        const fieldInfo = {
                            field: field,
                            customer: (field.owner.businessProfile ? field.owner.businessProfile.title : ((field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''))),
                            ownerId: field.owner.id ? field.owner.id : null,
                            fieldId: field.id,
                            fieldColor: '#ffffff'
                        }
                        tempFieldsReadonly.push(fieldInfo)
                    }
                })

                if (tempFieldsReadonly && tempFieldsReadonly.length > 0 && state.fieldsListMapData && state.fieldsListMapData.length > 0) {
                    await dispatch('getFieldsListOnMapReadOnly', tempFieldsReadonly)
                    if (!state.tempFieldPolygon) {
                        await dispatch('setMapBoundsForFieldsListReadOnly', tempFieldsReadonly)
                    }
                    await dispatch('setFieldsListLabelsReadOnly', tempFieldsReadonly)
                    commit('setFieldsListForSearchReadOnly', tempFieldsReadonly)
                } else {
                    if (state.fieldsListMapLabelsReadOnly.length > 0 && state.map) {
                        for (let i = 0; i < state.fieldsListMapLabelsReadOnly.length; i++) {
                            state.fieldsListMapLabelsReadOnly[i].setMap(null);
                        }
                        state.fieldsListMapLabelsReadOnly = []
                    }
                    console.error('No fields found for this customer!')
                }
                tempFieldsReadonly = []
            }
        }
    },

    async setFieldsListLabelsReadOnly({ state }, data) {
        try {
            if (data) {
                if (state.fieldsListMapLabelsReadOnly.length > 0 && state.map) {
                    for (let i = 0; i < state.fieldsListMapLabelsReadOnly.length; i++) {
                        state.fieldsListMapLabelsReadOnly[i].setMap(null);
                    }
                    state.fieldsListMapLabelsReadOnly = []
                }
                for (let i = 0; i < data.length; i++) {
                    const tempBounds = new google.maps.LatLngBounds();
                    const fieldPoly = JSON.parse(data[i].field.polygon);
                    if (fieldPoly.length > 0) {
                        const fieldName = data[i].field.title;

                        const fieldLabelComponent = new fieldLabelLightConstructor({ propsData: { fieldName } });

                        fieldLabelComponent.$mount();

                        const fieldLabelString = new XMLSerializer().serializeToString(fieldLabelComponent.$el);


                        const fieldLabelUrl = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(fieldLabelString);

                        for (let j = 0; j < fieldPoly.length; j++) {
                            const x = {
                                lat: fieldPoly[j].latitude,
                                lng: fieldPoly[j].longitude
                            }
                            const BoundLatLng = new google.maps.LatLng({ "lat": parseFloat(x.lat), "lng": parseFloat(x.lng) });
                            tempBounds.extend(BoundLatLng);
                        }
                        const centroid = tempBounds.getCenter();

                        const markerLabel = new google.maps.Marker({
                            position: centroid,
                            icon: fieldLabelUrl,
                            opacity: 0.7
                        });

                        state.fieldsListMapLabelsReadOnly.push(markerLabel);
                    }
                }

                if (state.fieldsListMapLabelsReadOnly.length > 0) {
                    for (let i = 0; i < state.fieldsListMapLabelsReadOnly.length; i++) {
                        state.fieldsListMapLabelsReadOnly[i].setMap(state.map);
                    }
                }

                setLabelsOnMap(state.fieldsListMapLabelsReadOnly, state.map)

                setLabelsInvisible(state.fieldsListMapLabelsReadOnly)

                if (google.maps && state.map) {
                    google.maps.event.addListener(state.map, ConstantValues.defaultZoomChangedEvent, function () {
                        const zoomLevel = state.map.getZoom();
                        if (zoomLevel >= ConstantValues.defaultLabelVisibilityZoomLevel) {
                            setLabelsVisible(state.fieldsListMapLabelsReadOnly)
                        } else {
                            setLabelsInvisible(state.fieldsListMapLabelsReadOnly)
                        }
                    });
                }

            }
        }
        catch (e) {
            devLogger().logError(e);
        }
    },

    async navigateToField({ state, dispatch }, fieldId) {
        if (state.fieldsListForSearchReadOnly && state.fieldsListForSearchReadOnly.length > 0) {
            const stringPolygonPoints = state.fieldsListForSearchReadOnly.filter(field => field.field.id == fieldId)[0].field.polygon
            const polygonPoints = JSON.parse(stringPolygonPoints);

            const bounds = new google.maps.LatLngBounds()

            polygonPoints.map(polygon => {
                const LatLng = new google.maps.LatLng(polygon.latitude, polygon.longitude)
                bounds.extend(LatLng)
            })

            if (state.fieldsListMap || state.map) {
                state.fieldsListMap?.fitBounds(bounds);
                state.fieldsListMap?.setZoom(16)
                state.map?.fitBounds(bounds)
                state.map?.setZoom(16)
            }

            state.fieldSearchResultsReadOnly = []
            state.fieldSearchTextReadOnly = null

            dispatch('highlightFieldOnMap', [polygonPoints, state.fieldsListMap || state.map])

        }
    },

    async highlightFieldOnMap({ state, commit }, data) {
        const polygonPoints = data[0]
        const mapReference = data[1]
        const field = data[2] || false
        const shouldNotTimeout = data[3];

        if (polygonPoints.length > 0) {
            const polygonLatLng = []
            let cords = null
            let cordColor = null
            cords = polygonPoints

            for (let i = 0; i < cords.length; i++) {
                const x = {
                    lat: cords[i].latitude,
                    lng: cords[i].longitude
                }
                polygonLatLng.push(x)
            }

            cordColor = '#ffffff'
            const fillColor = '#ffffff';
            const fieldShape = new google.maps.Polygon({
                paths: polygonLatLng,
                strokeColor: cordColor,
                map: mapReference,
                strokeWeight: 5,
                strokeOpacity: 1,
                fillColor: fillColor,
                fillOpacity: 0.8,
                zIndex: 99
            })

            fieldShape.setMap(mapReference)
            state.highlightedField.push(fieldShape)
            commit('setHighlightedFieldsListMapData', [field])
            if (!shouldNotTimeout) {
                setTimeout(() => {
                    fieldShape.setMap(null)
                    state.highlightedField.pop();
                    state.highlightedFieldsListMapData.pop();
                }, 1500)
            }
        }
    },

    async highlightFieldsOnMap({ state, dispatch, commit }) {
        if (state.highlightedField && state.highlightedField.length > 0) {
            state.highlightedField.map(fld => {
                fld.setMap(null)
            })
        }
        state.highlightedField = []
        state.highlightedFieldsListMapData = []

        let currentOwnerFilteredFields = [];
        if (state.fieldListOwnerId != 'All') {
            currentOwnerFilteredFields = state.fieldsListMapData.filter(field => field.owner.id == state.fieldListOwnerId)
        }
        if (state.fieldSearchText) {
            currentOwnerFilteredFields = currentOwnerFilteredFields.filter(field => field.title.toLowerCase().includes(state.fieldSearchText.toLowerCase()));
        }
        if (currentOwnerFilteredFields.length > 0) {
            const bounds = new google.maps.LatLngBounds()
            currentOwnerFilteredFields.map(field => {
                const fieldPoly = JSON.parse(field.polygon);
                if (Array.isArray(fieldPoly) && fieldPoly.length > 0) {
                    const polygonPoints = fieldPoly
                    if (polygonPoints.length > 0) {
                        const polygonLatLng = []
                        let cords = null
                        let cordColor = null
                        cords = polygonPoints

                        for (let i = 0; i < cords.length; i++) {
                            const x = {
                                lat: cords[i].latitude,
                                lng: cords[i].longitude
                            }
                            polygonLatLng.push(x)
                        }

                        cordColor = '#ffffff'

                        const fieldShape = new google.maps.Polygon({
                            paths: polygonLatLng,
                            strokeColor: cordColor,
                            map: state.fieldsListMap,
                            strokeWeight: 5,
                            strokeOpacity: 1,
                            fillColor: cordColor,
                            fillOpacity: 0.8,
                            zIndex: 99
                        })

                        state.highlightedField.push(fieldShape)

                        cords.map(polygon => {
                            const LatLng = new google.maps.LatLng(polygon.latitude, polygon.longitude)
                            bounds.extend(LatLng)
                        })

                        if (state.fieldsListMap) {
                            state.fieldsListMap.fitBounds(bounds)
                        }
                    }
                }
                
            })
            if (state.highlightedField && state.highlightedField.length > 0) {
                state.highlightedField.map(field => {
                    field.setMap(state.fieldsListMap)
                })
                commit("setHighlightedFieldsListMapData", currentOwnerFilteredFields);
            }
        }
        if (state.fieldFilterList && state.fieldListOwnerId == 'All') {
            dispatch('spanBoundsToFullMap')
        }
    },

    async spanBoundsToFullMap({ state }) {
        const bounds = new google.maps.LatLngBounds()
        state.fieldsListMapData.map(field => {
            const fieldPoly = JSON.parse(field.polygon)
             if (Array.isArray(fieldPoly) && fieldPoly.length > 0) {
                const polygonPoints = fieldPoly
                if (polygonPoints.length > 0) {
                    let cords = null
                    cords = polygonPoints

                    cords.map(polygon => {
                        const LatLng = new google.maps.LatLng(polygon.latitude, polygon.longitude)
                        bounds.extend(LatLng)
                    })

                    if (state.fieldsListMap) {
                        state.fieldsListMap.fitBounds(bounds)
                    }
                }
            }
        })
    },

    async locateFieldReadOnly({ dispatch, state }) {
        let fieldIndex = 0
        state.fieldsListForSearchReadOnly.map((fld, index) => {
            if (fld.title && fld.title.toLowerCase().includes(state.fieldSearchTextReadOnly.toLowerCase())) {
                fieldIndex = index
                state.hotspotSearchText = fld.title
            }
        })
        const polygonPoints = state.fieldsListForSearchReadOnly[fieldIndex].field.polygon

        const bounds = new google.maps.LatLngBounds()

        polygonPoints.map(polygon => {
            const LatLng = new google.maps.LatLng(polygon.latitude, polygon.longitude)
            bounds.extend(LatLng)
        })

        if (state.map) {
            state.map.fitBounds(bounds)
            state.map.setZoom(16)
        }

        dispatch('highlightFieldOnMap', [polygonPoints, state.map])

        state.fieldSearchResultsReadOnly = []
        state.fieldSearchTextReadOnly = null
    },

    async clearHighlightedPolygons({ state }) {
        if (state.highlightedField && state.highlightedField.length > 0) {
            state.highlightedField.map(fld => {
                fld.setMap(null)
            })
        }
        state.highlightedField = []
    },
    
    async archiveField({ rootState, dispatch}, {fieldId, fieldOwnerId}) {
        const url = buildUrl(rootState.baseUrl, {
            path: fieldOwnerId + apiFields + "/" + fieldId + "/archive" + "?Preference=" + state.fieldStatus
        });
        const result = await requests.postData(url, {});
        if (result[0] == '') {
            dispatch('removeFieldsAndUpdateMapBounds', [fieldId]);
            notify(StringConstants.fieldArchiveSuccessMessage, 'success');
        }
    },

    async restoreField({ rootState, dispatch}, {fieldId, fieldOwnerId}) {
        const url = buildUrl(rootState.baseUrl, {
            path: fieldOwnerId + apiFields + "/" + fieldId + "/restore" + "?Preference=" + state.fieldStatus
        });
        const result = await requests.postData(url, {});
        if (result[0] == '') {
            dispatch('removeFieldsAndUpdateMapBounds', [fieldId]);
            notify(StringConstants.fieldRestoreSuccessMessage, 'success');
        }
    },
    async removeFieldsAndUpdateMapBounds({state, rootState}, removedFieldIds) {
        removedFieldIds.forEach(fieldId => {
            const removedFieldMapIndex = state.fieldsListMapData.findIndex(field => field.id == fieldId);
            
            if(state.fieldListOwnerId != 'All') {
                const removedFieldHighlightedIndex = state.highlightedFieldsListMapData.findIndex(field => field.id == fieldId);
                state.highlightedField[removedFieldHighlightedIndex].setMap(null);
                state.highlightedField.splice(removedFieldHighlightedIndex, 1);
                deleteObjectFromList(state.highlightedFieldsListMapData, fieldId, 'id');
            } 
            if(state.fieldsListFieldShapes) {
                state.fieldsListFieldShapes[removedFieldMapIndex].setMap(null);
            }
            if(state.fieldsListMapLabels && state.fieldsListMapLabels.length) {
                state.fieldsListMapLabels[removedFieldMapIndex].setMap(null);
            }
            if(state.fieldsListFieldShapes) {
                state.fieldsListFieldShapes.splice(removedFieldMapIndex, 1);
            }
            if(state.fieldsListMapLabels && state.fieldsListMapLabels.length) {
                state.fieldsListMapLabels.splice(removedFieldMapIndex, 1);
            }
            if(state.fieldsListMapData) {
                deleteObjectFromList(state.fieldsListMapData, fieldId, 'id');
            }
            if(state.fieldFilterList) {
                deleteObjectFromList(state.fieldFilterList, fieldId, 'id');
            }
            state.fieldsTotalCount -= 1;
            rootState.totalRowCount -= 1;
            rootState.noData = state.fieldFilterList.length === 0;
        });
    },
    async removeFieldsFromMapByIds({ state, commit }, [removableFieldIds, removeAllFields]) {
        if(removeAllFields) {
            if(state.fieldsListFieldShapes && state.fieldsListFieldShapes.length) {
                removableFieldIds.forEach((fd) => {
                    const removedFieldMapIndex = state.fieldsListMapData.findIndex(field => field.id == fd);
                    if(state.fieldsListFieldShapes) {
                        state.fieldsListFieldShapes[removedFieldMapIndex]?.setMap(null);
                        state.fieldsListFieldShapes.splice(removedFieldMapIndex, 1);
                        deleteObjectFromList(state.fieldsListMapData, fd, 'id');
                    }
                    if(state.fieldsListMapLabels && state.fieldsListMapLabels.length) {
                        state.fieldsListMapLabels[removedFieldMapIndex]?.setMap(null);
                    }
                })
            }
            if(state.fieldsListMapLabels && state.fieldsListMapLabels.length) {
                state.fieldsListMapLabels.forEach(f => f.setMap(null))
            }
            if(state.highlightedField && state.highlightedField.length) {
                state.highlightedField.forEach(f => f.setMap(null));
                commit('clearHighlightedFieldsListMapData');
            }
        } else {
            if(state.fieldsListMapData && state.fieldsListMapData.length) {
                removableFieldIds.forEach((fd) => {
                    const removedFieldMapIndex = state.fieldsListMapData.findIndex(field => field.id == fd);
                    if(state.fieldsListFieldShapes) {
                        state.fieldsListFieldShapes[removedFieldMapIndex]?.setMap(null);
                        state.fieldsListFieldShapes.splice(removedFieldMapIndex, 1);
                        deleteObjectFromList(state.fieldsListMapData, fd, 'id');
                    }
                    if(state.fieldsListMapLabels && state.fieldsListMapLabels.length) {
                        state.fieldsListMapLabels[removedFieldMapIndex]?.setMap(null);
                    }
                })
            }
            if(state.fieldListOwnerId != 'All' && (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn)) {
                removableFieldIds.forEach((fid) => {
                    const removedFieldHighlightedIndex = state.highlightedFieldsListMapData.findIndex(field => field.id == fid);
                    if(state.highlightedField && state.highlightedField.length) {
                        state.highlightedField[removedFieldHighlightedIndex]?.setMap(null);
                        state.highlightedField.splice(removedFieldHighlightedIndex, 1);
                        deleteObjectFromList(state.highlightedFieldsListMapData, fid, 'id');
                    }

                })
            } 
        }
    },
    async setMapBoundsForFieldsInFieldsList({rootState, state, dispatch}) {
        const fields = _fieldsForBounds();
        if (state.fieldFilterList.length > 0) {
            


            boundMapOnCordinates(state.fieldsListMap, fields);
        } else {
            dispatch('spanBoundsToFullMap');
        }

        function _fieldsForBounds() {
            if (state.fieldListOwnerId != 'All') {
                return state.highlightedFieldsListMapData
            } 
            if (rootState.filtersApplied) {
                return state.fieldFilterList
            }
            return state.fieldsListMapData
        }
    },

    async drawFieldsOnMap({state, dispatch, commit}) {
        if (state.fieldsListMapData?.length > 0) {
            const tempFields = [];
            state.fieldsListMapData.map(field => {
                tempFields.push(formatFieldObjectForLabel(field))
            });
            if (tempFields?.length > 0) {
                commit('clearFieldsListFieldShapes');
                await dispatch('getFieldsListOnMap', tempFields);
               // await dispatch('setFieldsListLabels', tempFields);
            }
        }
    },
    async attachExistingGoogleMapsObjectToDom({state}, mapDiv) {
        document.getElementById(mapDiv).replaceWith(state.fieldsListMap.getDiv());
    },
    async updateMapToAddField({state, dispatch, commit}) {
        // await dispatch('initializeDrawingManager')
                
        if (!state.fieldDetails.owner) {
            const selectOwnerAlertListener = state.fieldsListMap.addListener('mouseover', function() {
                commit('setMapAlertMessage', StringConstants.fieldAddSelectCustomerAlertMessage);
            });
            commit('setMapAlertListener', selectOwnerAlertListener);
            commit('turnoffDrawingManager');
        } else {
            dispatch('initializeDrawingManager', ConstantValues.defaultDrawingManagerPolygonOptions);
        }
        let tempFields = [];

        function localClearTempFields() {
            tempFields = []
        }
        if (state.fieldsListMapData.length > 0) {
            state.fieldsListMapData.map(field => {
                const fieldInfo = {
                    field: field,
                    customer: (field.owner.businessProfile ? field.owner.businessProfile.title : ((field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''))),
                    ownerId: field.owner.id ? field.owner.id : null,
                    fieldId: field.id,
                    fieldColor: '#ffffff'
                }
                tempFields.push(fieldInfo)
            })

            if (tempFields && tempFields.length > 0 && state.fieldsListMapData && state.fieldsListMapData.length > 0) {
                if(state.totalFieldsCount < ConstantValues.defaultPageLimitForJobsFields) {
                    await dispatch('getFieldsListOnMapReadOnly', tempFields);
                    await dispatch('setFieldsListLabelsReadOnly', tempFields);
                }
                commit('setFieldsListForSearchReadOnly', tempFields);
            } else {
                console.error('No fields found')
            }
            localClearTempFields()
        }
    },
    async cleanUpMapAndFieldForm({state, commit, dispatch}) {
        commit('turnoffDrawingManager');
        if (state.fieldDetails?.id) {
            state.editingAreaOnMap = null;
            await dispatch('resetAreaToInitialState');
        } else {
            commit('clearFieldsPolygons');
        }
        commit('clearMapAlertMessage');
        commit('clearAlertMessageListener');
        commit('resetFieldDetails');
        commit('setJobsByField', []);
        commit('clearFieldFarmList', []);
        commit('setMapTriggeredEditFieldData', null);
    },

    async requestGooglePlacesSearchOnFields({ state, rootState, dispatch }, focusOnFirstResult) {
        if (!state.placesSearchQuery || state.placesSearchQuery?.length <= 2) return;

        const country = localStorage.getItem('country');

        const request = {
            query: country ? `${state.placesSearchQuery},${country}` : state.placesSearchQuery,
            fields: ['name', 'geometry'],
        }

        const service = new google.maps.places.PlacesService(state.map);

        service.findPlaceFromQuery(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                state.placesSearchResults = results;

                if (focusOnFirstResult && state.placesSearchResults.length > 0)
                    dispatch('focusOnSearchResultByIndex', 0);
            }
        });
    },

    async focusOnSearchResultByIndex({ state }, index) {
        if (state.placesSearchResults.length > 0) {
            state.fieldsListMap.setCenter(state.placesSearchResults[index].geometry.location);
            state.fieldsListMap.setZoom(18);
        }
    },
}
