import { TeamJobComponentConfiguration } from '@/data/models/TeamJobComponentConfiguration';
import ConstantValues from '@/plugins/constantValues';

export class TeamJobViewPreference {
    customer: TeamJobComponentConfiguration;
    scheduledDate: TeamJobComponentConfiguration;
    status: TeamJobComponentConfiguration;
    operation: TeamJobComponentConfiguration;
    instructions: TeamJobComponentConfiguration;
    instructionPhotos: TeamJobComponentConfiguration;
    operators: TeamJobComponentConfiguration;
    fields: TeamJobComponentConfiguration;
    metaData: TeamJobComponentConfiguration;
    products: TeamJobComponentConfiguration;
    locations: TeamJobComponentConfiguration;
    completionInformation: TeamJobComponentConfiguration;
    billingInformation: TeamJobComponentConfiguration;
    timeDetails: TeamJobComponentConfiguration;
    locationHistoryMap: TeamJobComponentConfiguration;

    constructor() {
        this.customer = new TeamJobComponentConfiguration();
        this.scheduledDate = new TeamJobComponentConfiguration();
        this.status = new TeamJobComponentConfiguration();
        this.operation = new TeamJobComponentConfiguration();
        this.instructions = new TeamJobComponentConfiguration();
        this.instructionPhotos = new TeamJobComponentConfiguration();
        this.operators = new TeamJobComponentConfiguration();
        this.fields = new TeamJobComponentConfiguration();
        this.metaData = new TeamJobComponentConfiguration();
        this.products = new TeamJobComponentConfiguration();
        this.locations = new TeamJobComponentConfiguration();
        this.completionInformation = new TeamJobComponentConfiguration();
        this.billingInformation = new TeamJobComponentConfiguration();
        this.timeDetails = new TeamJobComponentConfiguration();
        this.locationHistoryMap = new TeamJobComponentConfiguration();
    }

    public setCustomerPreference(viewPreference: TeamJobComponentConfiguration) {
        this.customer = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.customer;
    }

    public setScheduledDataPreference(viewPreference: TeamJobComponentConfiguration) {
        this.scheduledDate = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.scheduledDate;
    }

    public setStatusPreference(viewPreference: TeamJobComponentConfiguration) {
        this.status = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.status;
    }

    public setOperationPreference(viewPreference: TeamJobComponentConfiguration) {
        this.operation = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.operation;
    }

    public setInstructionsPreference(viewPreference: TeamJobComponentConfiguration) {
        this.instructions = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.instructions;
    }

    public setInstructionPhotosPreference(viewPreference: TeamJobComponentConfiguration) {
        this.instructionPhotos = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.instructionPhotos;
    }

    public setOperatorsPreference(viewPreference: TeamJobComponentConfiguration) {
        this.operators = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.operators;
    }

    public setFieldsPreference(viewPreference: TeamJobComponentConfiguration) {
        this.fields = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.fields;
    }

    public setMetaDataPreference(viewPreference: TeamJobComponentConfiguration) {
        this.metaData = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.metaData;
    }

    public setProductsPreference(viewPreference: TeamJobComponentConfiguration) {
        this.products = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.products;
    }

    public setLocationsPreference(viewPreference: TeamJobComponentConfiguration) {
        this.locations = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.locations;
    }

    public setCompletionInformationPreference(viewPreference: TeamJobComponentConfiguration) {
        this.completionInformation = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.completionInformation;
    }

    public setBillingInformationPreference(viewPreference: TeamJobComponentConfiguration) {
        this.billingInformation = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.billingInformation;
    }

    public setTimeDetailsPreference(viewPreference: TeamJobComponentConfiguration) {
        this.timeDetails = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.timeDetails;
    }

    public setLocationHistoryMapPreference(viewPreference: TeamJobComponentConfiguration) {
        this.locationHistoryMap = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference);
        return this.locationHistoryMap;
    }

    public getTeamJobViewPreference(viewPreference: TeamJobViewPreference) {
        this.customer = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.customer);
        this.scheduledDate = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.scheduledDate);
        this.status = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.status);
        this.operation = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.operation);
        this.instructions = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.instructions);
        this.instructionPhotos = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.instructionPhotos);
        this.operators = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.operators);
        this.fields = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.fields);
        this.metaData = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.metaData);
        this.products = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.products);
        this.locations = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.locations);
        this.completionInformation = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.completionInformation);
        this.billingInformation = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.billingInformation);
        this.timeDetails = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.timeDetails);
        this.locationHistoryMap = new TeamJobComponentConfiguration().getTeamJobComponentConfiguration(viewPreference.locationHistoryMap);

        return this;
    }

    public getTeamJobViewPreferenceDisabled() {
        const newJobDisabledViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setNotEditable, ConstantValues.setDetailed);

        this.customer = new TeamJobViewPreference().setCustomerPreference(newJobDisabledViewConfiguration);
        this.scheduledDate = new TeamJobViewPreference().setScheduledDataPreference(newJobDisabledViewConfiguration);
        this.operation = new TeamJobViewPreference().setOperationPreference(newJobDisabledViewConfiguration);
        this.instructions = new TeamJobViewPreference().setInstructionsPreference(newJobDisabledViewConfiguration);
        this.instructionPhotos = new TeamJobViewPreference().setInstructionPhotosPreference(newJobDisabledViewConfiguration);
        this.operators = new TeamJobViewPreference().setOperatorsPreference(newJobDisabledViewConfiguration);
        this.fields = new TeamJobViewPreference().setFieldsPreference(newJobDisabledViewConfiguration);
        this.metaData = new TeamJobViewPreference().setMetaDataPreference(newJobDisabledViewConfiguration);
        this.products = new TeamJobViewPreference().setProductsPreference(newJobDisabledViewConfiguration);
        this.locations = new TeamJobViewPreference().setLocationsPreference(newJobDisabledViewConfiguration);
        this.completionInformation = new TeamJobViewPreference().setCompletionInformationPreference(newJobDisabledViewConfiguration);
        this.billingInformation = new TeamJobViewPreference().setBillingInformationPreference(newJobDisabledViewConfiguration);
        this.timeDetails = new TeamJobViewPreference().setTimeDetailsPreference(newJobDisabledViewConfiguration);
        this.locationHistoryMap = new TeamJobViewPreference().setLocationHistoryMapPreference(newJobDisabledViewConfiguration);

        return this;
    }
}