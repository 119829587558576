



















import $ from 'jquery';
import OperationsListView from '@/components/operations/operations-list.vue';
import OperationsManageForm from '@/components/operations/operations-manage-form.vue';
import SubOperationEditForm from '@/components/operations/sub-operations-edit-form.vue';
import SubOperationAddForm from '@/components/operations/sub-operations-add-form.vue';
import { closeOverlay } from '@/utils/helpers';
import permissionsMixin from '@/mixins/permissions-mixin';
import requestsCancellationMixin from '@/mixins/requests-cancellation';

export default {
    mixins: [permissionsMixin, requestsCancellationMixin],
    components: {
        OperationsListView,
        OperationsManageForm,
        SubOperationAddForm,
        SubOperationEditForm
    },
    data() {
        return {
            activeFlyout: null
        }
    },
    mounted() {
        this.$store.state.dashboardTitle = this.$stringConstants('operationsTitle');
        $('body').on('click', '#overlay', () => {
            closeOverlay("manageOperationsFormOverlay");
            closeOverlay("editOperationFormOverlay");
            closeOverlay("addSubOperationFormOverlay");
            closeOverlay("editSubOperationFormOverlay");
            this.setActiveFlyout(null);
        });
    },
    methods: {
        setActiveFlyout(flyout) {
            this.activeFlyout = flyout
        }
    }
}
