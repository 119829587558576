import { ITeamJobProductUsage } from '../interfaces/ITeamJobProductUsage';

export class TeamJobProductUsage implements ITeamJobProductUsage {
    id?: string;
    fieldId?: string;
    usage?: number;

    constructor() {
        this.id = null;
        this.fieldId = null;
        this.usage = 0;
        return this;
    }

    public getTeamJobProductUsage(teamJobProductUsage: TeamJobProductUsage) {
        this.id = teamJobProductUsage && teamJobProductUsage.id ? teamJobProductUsage.id : null;
        this.fieldId = teamJobProductUsage && teamJobProductUsage.fieldId ? teamJobProductUsage.fieldId : null;
        this.usage = teamJobProductUsage && teamJobProductUsage.usage ? teamJobProductUsage.usage : 0;
        return this;
    }
}