






























































import contactComputedMixin from "@/components/internal-contact/internal-contact-computed";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import moment from "moment";
import Avatar from "@/components/shared/avatars/avatar.vue";
import router from "@/router/index";
import { routeJobs, routeTeamJobsDetails } from "@/utils/endpoints";
import { getAssignedUserNameFromJob, removeAllLocalParamsJobs } from "@/utils/helpers/jobs-helpers";
import { TeamJobStatusType, TeamJobStatusTypeLabel } from '@/enum/teamJobStatusType';

export default {
    name: "FieldDetailsView",
    mixins: [contactComputedMixin, filterParamsMixin],
    components: {
        Avatar,
    },
    created() {
        this.TeamJobStatusType = TeamJobStatusType;
        this.TeamJobStatusTypeLabel = TeamJobStatusTypeLabel;
    },
    beforeDestroy() {
        this.TeamJobStatusType = null;
        this.TeamJobStatusTypeLabel = null;
    },
    computed: {
        resId() {
            return window.location.pathname.split("/")[
                window.location.pathname.split("/").length - 1
            ];
        },
    },
    methods: {
        getDateTimeFormat: function (date) {
            return moment.utc(date).local().format("DD MMM YYYY");
        },
        getAssignedUserName(job: any) {
            return getAssignedUserNameFromJob(job);
        },
        navigateToJobView(cusId: any) {
            this.$store.state.isRoutedFromReferencePage = true;
            removeAllLocalParamsJobs();
            this.setLocalParamsMx(cusId, "jobCustomerIds");
            router.push(routeJobs);
        },
        openCustomerJobDetails: function (resourceId) {
            let routeData = this.$router.resolve({
                path: routeTeamJobsDetails + resourceId,
            });
            window.open(routeData.href, "_blank");
        },
    }
};
