

































































































































































    import onboardComputedMixin from "@/components/onboard/_onboard-computed";
    import OnboardMapSearch from "@/components/onboard/onbaord-map-search.vue";
    import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
    import { mapActions } from "vuex";
import { devLogger } from '@/utils/helpers';

    export default {
        mixins: [onboardComputedMixin],
        data() {
            return {
                menu: false
            };
        },
        updated() {
                if(this.globalTimeZonesList?.length > 0 && !this.userProfile.businessProfile.config.timezone) {
                    const currentTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone

                    devLogger().log(`currentTimeZone: ${currentTimeZone}`)
                    if(currentTimeZone) {
                        const matchedTimeZone = this.globalTimeZonesList.filter(tz => tz.id === currentTimeZone)?.[0]
                        devLogger().log(`matchedTimeZone: ${matchedTimeZone}`)

                        if(matchedTimeZone) {
                            this.$store.commit('setUserTimeZone', matchedTimeZone)
                        }
                    }
                }
        },
        mounted() {
            this.getGoogleMapToSelectDefaultLocation()
        },
        components: {
            OnboardMapSearch,
            VueCtkDateTimePicker
        },
        watch: {
            onboardConfigTempColor(value) {
                if (value.toString().match(/#[a-zA-Z0-9]{8}/)) {
                    this.onboardConfigTempColor = value.substr(0, 7);
                }
            },
        },
        computed: {
            swatchStyle() {
                const { onboardConfigTempColor, menu } = this;
                return {
                    backgroundColor: onboardConfigTempColor,
                    cursor: "pointer",
                    height: "24px",
                    width: "24px",
                    borderRadius: menu ? "50%" : "50px",
                    transition: "border-radius 200ms ease-in-out",
                };
            },
        },
        methods: {
            ...mapActions({
                getGoogleMapToSelectDefaultLocation: "getGoogleMapToSelectDefaultLocation",
                clearMapLocationInfoWindow: "clearMapLocationInfoWindow",
            }),
        },
    };
