import { apiJobs, apiJobsSummary } from '@/utils/endpoints';
import { closeNotify, notify } from '@/utils/helpers';
import request from '@/utils/requests';
import buildUrl from 'build-url';
import requests from '@/utils/requests';
import StringConstants from '@/plugins/stringConstants';
import { showScrollBars, toggleScrollBarsOnScrollView } from '@/utils/helpers/gmaps-helpers';
import ConstantValues from '@/plugins/constantValues';

export const actions = {
    async getJobDetailsByResourceInvoice({ state, commit, rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + data
        });
        const result = await request.getData(url);

        if (result != null && 'data' in result) {
            if (result.data != null) {
                commit('setJobDetails', result.data);
                state.jobDetailsLoader = false;
            }
        }
        return true;
    },

    async generateInvoiceReportUrls({ rootState, commit, state }, jobId) {
        const url = buildUrl(rootState.baseUrl, {
            path: 'jobs/' + jobId + '/report'
        });
        state.invoiceReportUrlGenerationInProgress = true;
        const result = await requests.postData(url, {});
        state.invoiceReportUrlGenerationInProgress = false;
        
        if(state.generatedFromJobDetails && result && result[0] != null) {
            const url = result[0];
            notify(StringConstants.jobReportGenerationMessage, 'success', {callback: () => window.open(url, '_blank'), text: 'View'});
            window.open(url, "_blank");
            closeNotify(5000);
        }

        if (result && result[0] != null) {
            commit('setInvoiceJobReportUrls', [result[0], jobId]);
            return result;
        }
    },

    async getJobReportUrlsAndUpdateReferencesAsync({ state, dispatch }, invoiceDraftPreviewDetails) {
        const jobIds = invoiceDraftPreviewDetails?.jobIds
        if(jobIds?.length > 0) {
            await Promise.all(jobIds.map(async (jobId) => {
                await dispatch('generateInvoiceReportUrls', jobId)
            }))
            showScrollBars('stepper-scroll')
            toggleScrollBarsOnScrollView(true)
            state.invoiceReportGenerationInProgress = false
        }
    },

    async generateJobSummaryForInvoice({ state, commit, rootState }, { integrationType, invoiceObj }) {
        const url = buildUrl(rootState.baseUrl, {
                    path: localStorage.getItem(ConstantValues.ownerId) 
                    + '/' + integrationType + apiJobsSummary
        })

        state.invoiceReportUrlGenerationInProgress = true
        const result = await requests.postData(url, invoiceObj)
        state.invoiceReportUrlGenerationInProgress = false

        if (result && result[0] != null) {
            const url = result[0]
            let reference = result[0]
            const referenceList = reference?.split('/')

            if(reference && referenceList.length > 0) {
                reference = referenceList[referenceList.length - 1].replace(/\.[^/.]+$/, "")
            }

            commit('setInvoiceJobReportSummaryUrls', { url, reference })
            return result
        }
    },

    async promtInvoiceGenerationMessage() {
        notify(StringConstants.invoiceGenerationWarning);
    },
}