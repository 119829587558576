export default {
    methods: {
        scriptExistsAlready(scriptContent) {
            let containsScript = false;
            const scriptElements = document.getElementsByTagName('script');

            if(scriptElements && scriptElements.length) {
                for(let i = 0; i < scriptElements.length; i++) {
                    if(scriptElements[i].src.includes(scriptContent)) {
                        containsScript = true;
                    }
                }
            } 
            return containsScript;
        },
        loadScripts() {
            if(process.env.NODE_ENV == 'production' && !this.scriptExistsAlready('boat3deer.com')) {
                const boat3Script = document.createElement('script');
                boat3Script.setAttribute('src', 'https://secure.boat3deer.com/js/259765.js');
                document.head.appendChild(boat3Script);
                
                const noScript = document.createElement('noscript');
                const imgElement = document.createElement('img');
                imgElement.setAttribute('alt', '');
                imgElement.setAttribute('src', 'https://secure.boat3deer.com/259765.png');
    
                noScript.appendChild(imgElement);
            }
        }
    }
}