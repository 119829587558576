import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule': [
        'teamJobMetaDataList'
    ],
    'root': [
        'enumListLoadInfoUnits'
    ]
}

export default {
    name: 'TeamJobsGenericComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}