










export default {
    name: 'ListLoader'
}
