
































































































































































































import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { checkFieldsValidity, validateRegexPhoneNumber } from '@/utils/helpers';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { UserRoleType } from "@/enum/userRoleType";
import ConstantValues from '@/plugins/constantValues';

    export default {
        name: 'InvoicePaymentsForm',
        mixins: [accountComputedMixin],
        components: {
            SectionLeftHeadings,
            Button
        },
        data(){
            return {
                tempRatePercentage: null,
                tempRateName: null,
                searchTaxType: null,
                UserRoleType
            }
        },
        computed: {
            isValidTaxRate(){
                if (checkFieldsValidity([this.tempRatePercentage, this.tempRateName])) {
                    return false;
                } else {
                    return true;
                }
            },
            ownerRole: {
                get() {
                    return localStorage.getItem("ownerRole");
                }
              },
        },
        mounted() {
            if(this.isRoutedToAccountForTaxUpdate) {
                setTimeout(() => {
                    document.getElementById('taxTypeNameId')?.click()
                }, ConstantValues.defaultScrollToPositionClickTimeout)
            }
        },
        beforeDestroy() {
            this.isRoutedToAccountForTaxUpdate = false;
        },
        methods: {
            appendTaxRate(){
                const taxRate = {
                    rateName: this.tempRateName,
                    rateInPercentage: this.tempRatePercentage
                }
                this.accountBusinessProfile.config.salesTaxRates.push(taxRate);
                this.resetTempTaxRate();

            },
            resetTempTaxRate(){
               this.tempRateName = null;
               this.tempRatePercentage = null;
            },
            removeSelectedTaxRate(idx){
                if (idx > -1) {
                    this.accountBusinessProfile.config.salesTaxRates.splice(idx, 1);
                }
            },
            resetTaxRegistration(){
                this.accountBusinessProfile.config.salesTaxRegistrationNumber = null;
            },
            checkTermsNumber(event) {
                validateRegexPhoneNumber(event);
            }
        },
    }
