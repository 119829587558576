









import { avatarColorDisabled } from '@/utils/uiconstants';

export default {
    name: 'AvatarDisabled',
    props: ['initials'],
    methods: {
        getInitials(data: string) {
            if (data != null) {
                let chr;
                const d = data.toUpperCase();
                chr = d.split(" ");
                if (chr.length >= 2) {
                    return chr[0][0] + chr[1][0];
                }
                else {
                    return d[0] + d[1];
                }
            }
            return '';
        },
        getDisabledColor() {
            return avatarColorDisabled;
        }
    }
}
