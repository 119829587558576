











import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsComponents from '@/components/team-jobs/common/team-jobs-all.vue';
import TeamJobsAddSaveButtons from '@/components/team-jobs/team-jobs-add/team-jobs-add-save.vue';
import { getLoggedInUserRole, getOwnerId } from '@/utils/helpers/user-role-helpers';
import { UserRoleType } from '@/enum/userRoleType';
import { TeamJobComponentConfiguration } from '@/data/models/TeamJobComponentConfiguration';
import { TeamJobViewPreference } from '@/data/models/TeamJobViewPreference';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import ConstantValues from '@/plugins/constantValues';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';
import { Breadcrumb } from '@/data/models/Breadcrumb';

export default {
    name: "TeamJobsAdd",
    mixins: [TeamJobsComputed, TeamJobsCommon, requestsCancellationMixin, breadcrumbsMixin],
    mounted() {
        this.mountTeamJobAdd();
        this.$store.dispatch("getGlobalCountriesList");
    },
    components: {
        TeamJobsComponents,
        TeamJobsAddSaveButtons
    },
    beforeMount() {
        this.$store.commit('resetTeamJobs');
    },
    beforeDestroy() {
        this.$store.commit('resetTeamJobs');
        this.$store.dispatch("destroyMapInstance");
    },
    methods: {
        async mountTeamJobAdd() {
            await this.$store.dispatch('initializeTeamJobsMap');
            await this.initTeamJobsAdd();
            await this.setFieldCustomerFilter();
        },
        async setFieldCustomerFilter() {
            if (this.teamJobUser) {
                this.teamJobFieldCustomerId = this.teamJobUser.id;
                if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                   await this.$store.dispatch('filterCustomerFieldsTeamJob');
                }
            }
        },
        setAddTeamsJobViewPreference() {
            //set status preference
            const newViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.status = new TeamJobViewPreference().setStatusPreference(newViewConfiguration);
            this.teamJobViewPreference.locationHistoryMap = new TeamJobViewPreference().setLocationHistoryMapPreference(newViewConfiguration);
            this.teamJobViewPreference.timeDetails = new TeamJobViewPreference().setTimeDetailsPreference(newViewConfiguration);

            //set operators preference
            const newViewEnabledConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.operators = new TeamJobViewPreference().setOperatorsPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.customers = new TeamJobViewPreference().setCustomerPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.scheduledDate = new TeamJobViewPreference().setScheduledDataPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.operation = new TeamJobViewPreference().setOperationPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.instructions = new TeamJobViewPreference().setInstructionsPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.instructionPhotos = new TeamJobViewPreference().setInstructionPhotosPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.fields = new TeamJobViewPreference().setFieldsPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.metaData = new TeamJobViewPreference().setMetaDataPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.products = new TeamJobViewPreference().setProductsPreference(newViewEnabledConfiguration);
            
            this.teamJobViewPreference.locations = new TeamJobViewPreference().setLocationsPreference(newViewEnabledConfiguration);
            this.teamJobViewPreference.billingInformation = new TeamJobViewPreference().setBillingInformationPreference(newViewEnabledConfiguration);

            //set job completion preference
            const newJobCompletionViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.completionInformation = new TeamJobViewPreference().setCompletionInformationPreference(newJobCompletionViewConfiguration);
            
            this.hideBillingQuantities();
            this.editableTeamUser();
        },
        async initTeamJobsAdd() {
            const pageId = Math.random().toString(36).substr(2, 9);
            if (this.fieldRequesterId == null) {
                this.fieldRequesterId = pageId;
            }
            this.setAddTeamsJobViewPreference();

            this.setBreadcrumbs('TeamJobsAdd');

            if (getLoggedInUserRole().isUserOwnerFarmerLoggedIn) {
                this.teamJobOwnerType = UserRoleType.Contractor;
            }
            await this.$store.dispatch('getTeamJobsOwners');
            
            this.teamJobOperationOwnerId = getOwnerId();

            await this.$store.dispatch('getTeamJobOperations');
            this.$store.commit('setClearFilterOptions');

            await this.$store.dispatch('getTeamJobTeamMembers');
            this.$store.commit('setClearFilterOptions');

            await this.$store.dispatch('getTeamJobProducts');
            this.$store.commit('setClearFilterOptions');

            await this.$store.dispatch('getTeamJobAddresses');
            this.$store.commit('setClearFilterOptions');

            await this.$store.dispatch('getTeamJobVehiclesList');
            this.$store.commit('setClearFilterOptions');

            await this.$store.dispatch('getTeamJobImplementsList');
            this.$store.commit('setClearFilterOptions');

            await this.$store.dispatch('getTeamJobAllFields', pageId);
        }
    }
}
