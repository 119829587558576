export enum JobOperatorTravelStatusType {
    Started = 0,
    Paused = 1,
    Completed = 2,
}

export const JobOperatorTravelStatusTypeLabel = new Map<number, string>([
    [JobOperatorTravelStatusType.Started, 'Travelling'],
    [JobOperatorTravelStatusType.Paused, 'Paused travel'],
    [JobOperatorTravelStatusType.Completed, 'Reached on location']
]);