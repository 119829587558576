
















































import { mapMutations } from 'vuex';
import { checkFieldsValidity } from '@/utils/helpers';
import { routeCustomers } from '@/utils/endpoints';
import router from '@/router/index';
import permissionsMixin from '@/mixins/permissions-mixin';
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import ContactPersonalDetailsForm from './internal-contact-personal-details-form.vue';
import SecondaryContactDetailsForm from './internal-contact-secondary-contact-details.vue';
import ContactCompanyDetailsForm from './internal-contact-company-details.vue';
import ContactCompanyAddressForm from './internal-contact-company-address.vue';
import { ButtonActionPrimary, HoverTextButton } from '@/components/shared/buttons/index';

export default {
    mixins: [permissionsMixin, contactComputedMixin],
    components: {
        ContactPersonalDetailsForm,
        SecondaryContactDetailsForm,
        ContactCompanyDetailsForm,
        ContactCompanyAddressForm,
        ButtonActionPrimary,
        HoverTextButton
    },
    data() {
        return {
            isEdiablePrefix: true
        }
    },
    computed: {
        isFieldsValid() {
            if (checkFieldsValidity([
                this.contactProfile.firstName,
                this.contactProfile.businessProfile.title,
                this.contactProfile.businessProfile.address.postalCode,
                this.contactProfile.businessProfile.address.addressLine1,
                this.businessProfilePrefix
            ])) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted() {
        this.resetContactProfile();
        this.$store.dispatch("getGlobalCountriesList");
        this.$store.state.dashboardTitle = this.$stringConstants('addContactTitle');
    },
    methods: {
        ...mapMutations({
            resetContactProfile: 'resetContactProfile',
        }),
        cancelSaveContactProfile: function () {
            this.resetContactProfile();
            router.push(routeCustomers);
        },
        saveContactProfile() {
            let bussinessAddress = null;
            if (this.contactProfile.businessProfile.address.addressLine1) {
                bussinessAddress = {
                    addressLine1: this.contactProfile.businessProfile.address.addressLine1,
                    addressLine2: this.contactProfile.businessProfile.address.addressLine2,
                    country: this.contactProfile.businessProfile.address.country,
                    city: this.contactProfile.businessProfile.address.city,
                    state: this.contactProfile.businessProfile.address.state,
                    postalCode: this.contactProfile.businessProfile.address.postalCode,
                    coordinate: {
                        latitude: 0,
                        longitude: 0
                    },
                }
            }

            let profileContactObj = {
                businessProfile: {
                    vatNumber: this.contactProfile.businessProfile.vatNumber,
                    address: bussinessAddress,
                    registrationNumber: this.contactProfile.businessProfile.registrationNumber,
                    title: this.contactProfile.businessProfile.title.trim(),
                    shortTitle: this.businessProfilePrefix,
                },
                email: this.contactProfile.email,
                firstName: this.contactProfile.firstName.trim(),
                lastName: this.contactProfile.lastName?.trim(),
                colorCode: this.contactProfile.colorCode,
                primaryContactNumber: this.contactProfile.primaryContactNumber,
                secondaryContactNumber: this.contactProfile.secondaryContactNumber,
                secondaryFirstName: this.contactProfile.secondaryFirstName,
            }
            this.$store.dispatch('addNewContact', profileContactObj).then((res) => {
                if (res == true) {
                    router.push(routeCustomers);
                }
            });
        }
    }
}
