




















import $ from "jquery";
import SubscriptionsPlanDetails from "@/components/subscriptions/subscriptions-plan-details.vue";
import SubscriptionsCardsManage from "@/components/subscriptions/subscriptions-cards-manage-form.vue";
import SubscriptionsPaymentDetails from "@/components/subscriptions/subscriptions-payment-details.vue";
import SubscriptionsPlans from "@/components/subscriptions/subscriptions-plans.vue";
import SubscriptionsInvoiceHistory from "@/components/subscriptions/subscriptions-invoice-history.vue";
import { closeOverlay } from "@/utils/helpers";
import requestsCancellationMixin from '@/mixins/requests-cancellation';

export default {
  components: {
    SubscriptionsPlanDetails,
    SubscriptionsCardsManage,
    SubscriptionsPaymentDetails,
    SubscriptionsPlans,
    SubscriptionsInvoiceHistory,
  },
  mixins: [requestsCancellationMixin],
  mounted() {
    this.$store.state.dashboardTitle = this.$stringConstants('subscriptionsTitle');
    this.$store.dispatch("getUserProfile").then(() => {
      this.$store.dispatch("getSubscriptionDetails");
      this.$store.dispatch("getSubscriptionPricing");
    });

    $("body").on("click", "#overlay", () => {
      closeOverlay("manageCardsFormOverlay");
    });
  },
};
