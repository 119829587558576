import moment from "moment";

export class UserSetting {
    workingStartTime: string;
    workingEndTime: string;
    workingStartTimeConverted: string;
    workingEndTimeConverted: string;
    allowedBreakTimeADayInMinutes: number;

    constructor() {
        const crDate = moment().format('YYYY-MM-DD');
        const stTime = moment.utc("0001-01-01T06:00:00.000Z").format("HH:mm:ss");
        const etTime = moment.utc("0001-01-01T14:00:00.000Z").format("HH:mm:ss");
        this.workingStartTime = null;
        this.workingEndTime = null;
        this.allowedBreakTimeADayInMinutes = 0;
        this.workingStartTimeConverted = moment.utc(crDate + " " + stTime).local().format("HH:mm");
        this.workingEndTimeConverted = moment.utc(crDate + " " + etTime).local().format("HH:mm");
    }

    public getUserSettings(setting: UserSetting){
        const crDate = moment().format('YYYY-MM-DD');
        const stTime = setting.workingStartTime ? moment.utc(setting.workingStartTime).format("HH:mm:ss") : moment.utc("0001-01-01T06:00:00.000Z").format("HH:mm:ss");
        const etTime = setting.workingEndTime ? moment.utc(setting.workingEndTime).format("HH:mm:ss") : moment.utc("0001-01-01T14:00:00.000Z").format("HH:mm:ss");
        this.workingStartTime = setting.workingStartTime ? setting.workingStartTime : null;
        this.workingEndTime = setting.workingEndTime ? setting.workingEndTime : null;
        this.allowedBreakTimeADayInMinutes = setting.allowedBreakTimeADayInMinutes ? setting.allowedBreakTimeADayInMinutes : 0;
        this.workingStartTimeConverted = moment.utc(crDate + " " + stTime).local().format("HH:mm");
        this.workingEndTimeConverted = moment.utc(crDate + " " + etTime).local().format("HH:mm");
        return this;
    }
}