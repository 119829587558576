export const state = {
    adminOverviewRecentStatistics: {
        users: 0,
        businesses: 0,
        paidSubscriptions: 0,
        expiredSubscriptions: 0,
        businessesOnTrial: 0,
        subscriptionsCollection: 0
    },
    adminOverviewRecentStatisticsStartDate: null,
    adminOverviewRecentStatisticsEndDate: null,
    adminOverviewOverallStatistics: {
        totalUsers: 0,
        totalActiveUsers: 0,
        totalBusinesses: 0,
        totalActiveBusinesses: 0,
        averageTeamMembersPerBusiness: 0,
        averageSubscriptionInvoiceAmountPerBusiness: 0,
        businessesDistributionByInvoicingServices: [],
        businessesDistributionByCountry: [],
    },
    adminOverviewCurrentStatisticsDateFilterApplied: false,
    adminRecentOverviewLoader: false,
    adminOverallOverviewLoader: false,
}

export const getters = {
    adminOverviewRecentStatistics: state => state.adminOverviewRecentStatistics,
    adminOverviewRecentStatisticsStartDate: state => state.adminOverviewRecentStatisticsStartDate,
    adminOverviewRecentStatisticsEndDate: state => state.adminOverviewRecentStatisticsEndDate,
    adminOverviewOverallStatistics: state => state.adminOverviewOverallStatistics,
    adminOverviewCurrentStatisticsDateFilterApplied: state => state.adminOverviewCurrentStatisticsDateFilterApplied,
    adminRecentOverviewLoader: state => state.adminRecentOverviewLoader,
    adminOverallOverviewLoader: state => state.adminOverallOverviewLoader,
}

export const mutations = {
    setRecentStatistics(state, data) {
        state.adminOverviewRecentStatistics = data
    },
    setOverallStatistics(state, data) {
        state.adminOverviewOverallStatistics = data
    }
}