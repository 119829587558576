import { Address } from "./Address";
import { BusinessProfile } from "./BusinessProfile";
import { Config } from "./Config";
import { UserSetting } from "./UserSetting";
import { generateRandomHexColorString } from "@/utils/helpers/generator-helpers";

export class User implements IUser {
    address: Address;
    archivedOn: string;
    businessProfile: BusinessProfile;
    colorCode: string;
    defaultImplement: any;
    defaultVehicle: any;
    email: string;
    emailConfirmed: boolean;
    firstName: string;
    id: string;
    imageUrl: string;
    isArchived: boolean;
    isInternal: boolean;
    lastName: string;
    ownerId: string;
    primaryContactNumber: string;
    roles: Array<any>;
    secondaryContactNumber: string;
    secondaryFirstName: string;
    secondaryLastName: string;
    settings: UserSetting;
    userRole: string;
    userId: string;
    defaultVehicleId: string;
    defaultImplementId: string; 

    constructor(){
            this.address = new Address();
            this.archivedOn = null;
            this.businessProfile = new BusinessProfile();
            this.colorCode = generateRandomHexColorString();
            this.defaultImplement = null;
            this.defaultVehicle = null;
            this.email = null;
            this.emailConfirmed = false;
            this.firstName = null;
            this.id = null;
            this.imageUrl = null;
            this.isArchived = false;
            this.isInternal = false;
            this.lastName = null;
            this.ownerId = null;
            this.primaryContactNumber = null;
            this.roles = [];
            this.secondaryContactNumber = null;
            this.secondaryFirstName = null;
            this.secondaryLastName = null;
            this.settings = new UserSetting();
            this.userRole = null;
            this.userId = null;
            this.defaultVehicleId = null;
            this.defaultImplementId = null;
    }

    public getUserProfileDetails(user : User){
            this.address = user?.address ? new Address().setResponseAddress(user.address) : new Address();
            this.archivedOn = user?.archivedOn || null;
            this.businessProfile = user?.businessProfile ? new BusinessProfile().getBusinessProfileDetails(user.businessProfile) : new BusinessProfile();
            this.colorCode = user?.colorCode || generateRandomHexColorString();
            this.defaultImplement = user?.defaultImplement || null;
            this.defaultVehicle = user?.defaultVehicle || null;
            this.email = user?.email || null;
            this.emailConfirmed = user?.emailConfirmed || false;
            this.firstName = user?.firstName || null;
            this.id = user?.id || null;
            this.imageUrl = user?.imageUrl || null;
            this.isArchived = user?.isArchived || false;
            this.isInternal = user?.isInternal || false;
            this.lastName = user?.lastName || null;
            this.ownerId = user?.ownerId || null;
            this.primaryContactNumber = user?.primaryContactNumber || null;
            this.roles = user?.roles || [];
            this.secondaryContactNumber = user?.secondaryContactNumber || null;
            this.secondaryFirstName = user?.secondaryFirstName || null;
            this.secondaryLastName = user?.secondaryLastName || null;
            this.settings = user?.settings ? new UserSetting().getUserSettings(user.settings) : new UserSetting();
            this.userRole = user?.roles ? user.roles[0].type : null;
            this.userId = user?.id || null;
            this.defaultVehicleId = user?.defaultVehicle ? user.defaultVehicle.id : null;
            this.defaultImplementId = user?.defaultImplement ? user.defaultImplement.id : null;
        return this;
    }
    
}
        