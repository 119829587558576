import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule': [
        'teamJobProducts',
        'teamJobProductToAdd',
        'teamJobProductsList',
        'teamJobProductsSearchText',
        'teamJobProductsLoader',
        'teamJobShowJobAddProductsExpandedView',
        'teamJobShowJobAddProductsDropdown'
    ]
}

export default {
    name: 'TeamJobsProductsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}