













export default {
    name: "SidebarLink",
    props: [
        "icon",
        "title",
        "route",
        "isChildMenu"
    ]
}
