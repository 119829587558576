import { Breadcrumb } from "@/data/models/Breadcrumb";
import StringConstants from "@/plugins/stringConstants";
import { routeCustomers, routeJobs, routeInvoices, routeCustomersDetails } from '@/utils/endpoints';
import store from '@/store/index';

export default {
    methods: {
        setBreadcrumbs(pageId: string, currentPageTitle?: string) {
            const rootStore = store;
            const rootState = rootStore?.state;
            if(rootState) {
                rootStore.commit('resetBreadcrumbsItems')
    
                switch(pageId) {
                    case 'TeamJobsAdd':
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.jobsTitle, false, routeJobs))
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.jobsAddTitle, true, null))
                        break

                    case 'TeamJobsDetails':
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.jobsTitle, false, routeJobs))
                        if(currentPageTitle) {
                            rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(currentPageTitle, true, null))
                        } else {
                            rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb('Job details', true, null))
                        }
                        break

                    case 'CustomerDetails':
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.customersTitle, false, routeCustomers))
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.customerDetailsTitle, true, null))
                        break

                    case 'CustomerDetailsUpdate':
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.customersTitle, false, routeCustomers))
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.customerDetailsTitle, false, routeCustomersDetails + '/' + currentPageTitle))
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.customerDetailsUpdateTitle, true, null))
                        break

                    case 'InvoicesAdd':
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.invoicesTitle, false, routeInvoices))
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.invoicesAddTitle, true, null))
                        break

                    case 'InvoicesExternalAdd':
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.invoicesTitle, false, routeInvoices))
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.invoicesCreateTitle, true, null))
                        break

                    case 'InvoiceDetails':
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.invoicesTitle, false, routeInvoices))
                        if(currentPageTitle) {
                            rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(currentPageTitle, true, null))
                        } else {
                            rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb(StringConstants.invoicesDetailsTitle, true, null))
                        }
                        break
                    default:
                        rootState.breadcrumbsItems.push(new Breadcrumb().getBreadcrumb('Dashboard', false, routeJobs))
                }
            }
        }
    }
}