export enum TeamJobStatusType {
    Pending = 0,
    Ready = 1,
    InProgress = 2,
    AwaitingCompletion = 3,
    PartCompleted = 4,
    Completed = 5,
    Cancelled = 6
}
export const TeamJobStatusTypeLabel = new Map<number, string>([
    [TeamJobStatusType.Pending, 'Pending'],
    [TeamJobStatusType.Ready, 'Ready'],
    [TeamJobStatusType.InProgress, 'In-progress'],
    [TeamJobStatusType.AwaitingCompletion, 'Awaiting completion'],
    [TeamJobStatusType.PartCompleted, 'Part completed'],
    [TeamJobStatusType.Completed, 'Completed'],
    [TeamJobStatusType.Cancelled, 'Cancelled'],
]);