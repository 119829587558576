import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"b-btm py-5 px-8"},[_c(VRow,{staticClass:"fn-20",attrs:{"no-gutters":"","cols":"12"}},[_c(VCol,{staticClass:"oy-tl",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.$stringConstants('farmerManageCustomer')))]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"4"}},[_c(VIcon,{staticClass:"oy-cl",attrs:{"small":"","id":"closeFarmerAddForm"},on:{"click":_vm.closeFarmerManageForm}},[_vm._v(" mdi-close-thick ")])],1)],1)],1),_c('div',[_c('div',{staticClass:"pt-8",class:[_vm.containerLeftPad, _vm.containerRightPad]},[_c('div',{staticClass:"py-2"},[_c('SubHeaderPrimary',{attrs:{"textValue":_vm.$stringConstants('connectionRequestTitle')}})],1),_c('div',{staticClass:"pt-1 pb-6"},[_c('DescriptionPrimary',{attrs:{"textValue":_vm.$stringConstants('userInvitationDescription')}})],1)]),_c('div',[_c('Tabs',{attrs:{"tabs":['Received', 'Sent'],"tabContents":[_vm.farmerConReqList, _vm.farmerSendReqList],"listLoaders":[_vm.conReqLoader, _vm.conSendLoader],"emptyListTexts":[_vm.$stringConstants('noConnectionReceived'), _vm.$stringConstants('noConnectionSent')],"handleTabChange":_vm.onTabChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var currentTab = ref.currentTab;
return [_c('RecivedConReq',{attrs:{"item":item,"currentTab":currentTab}})]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }