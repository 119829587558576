import { TeamJob } from '@/data/models/TeamJob';
import { JobAttachmentPurposeType } from '@/enum/jobAttachmentPurposeType';

export const state = {
    selectedProductsForCompleteJob: [],
    productsListForCompleteJob: [],
    productsForCompleteJobSearchText: '',
    isUpdatedCompleteJobStarted: false,
    jobDeclineReason: null,
    jobDetails: {
        id: null,
        createdOn: null,
        lastUpdatedOn: null,
        number: null,
        scheduledAt: null,
        instructions: null,
        notes: null,
        status: null,
        statusChangeHistory: [],
        customer: {
            id: null,
            imageUrl: null,
            name: null,
            businessName: null,
            isInternal: false,
            roles: [],
            ownerId: null,
        },
        customerCancellationReason: null,
        contractor: {
            id: null,
            imageUrl: null,
            name: null,
            businessName: null,
            isInternal: false,
            roles: [],
            ownerId: null,
        },
        contractorApprovalStatus: null,
        contractorDeclineReason: null,
        operation: null,
        operators: [],
        fields: [],
        products: [],
        timeRecords: [],
        addresses: [],
        attachments: [],
        metaData: [],
        invoiceNumber: null,
        invoiceUrl: null,
        isApprovedToInvoice: false
    },
    jobCurrentStatus: 0,
    isJobStarted: false,
    jobDetailsLoader: false,
    isMethodSubscribed: false,
    isAssigneeMethodSubscribed: false,
    isAssigneeUserSubscribed: false,
    polygonShapes: [],
    assigneeTravelHistory: [],
    updateJobLoader: false,
    updateJobLoaderStatus: 0,
    polylineCords: [],
    pathPolyLineDrawn: null,
    fullPathPolyLineDrawn: null,
    isManualWorkTimeValid: true,
    existingbreakTimeInSeconds: 0,
    existingDownTimeInSeconds: 0,
    jobOperatorLocations: [],
    jobOperatorsProofOfWork: [],
    invoiceReportUrlGenerationInProgress: false,
    invoiceReportGenerationInProgress: false,
    invoiceJobReportUrls: [],
    generatedFromJobDetails: false,
}

export const getters = {
    jobDetails: state => state.jobDetails,
    jobCurrentStatus: state => state.jobCurrentStatus,
    isJobStarted: state => state.isJobStarted,
    selectedProductsForCompleteJob: state => state.selectedProductsForCompleteJob,
    productsListForCompleteJob: state => state.productsListForCompleteJob,
    productsForCompleteJobSearchText: state => state.productsForCompleteJobSearchText,
    isUpdatedCompleteJobStarted: state => state.isUpdatedCompleteJobStarted,
    jobDetailsLoader: state => state.jobDetailsLoader,
    jobDeclineReason: state => state.jobDeclineReason,
    isMethodSubscribed: state => state.isMethodSubscribed,
    isAssigneeUserSubscribed: state => state.isAssigneeUserSubscribed,
    updateJobLoader: state => state.updateJobLoader,
    updateJobLoaderStatus: state => state.updateJobLoaderStatus,
    polylineCords: state => state.polylineCords,
    pathPolyLineDrawn: state => state.pathPolyLineDrawn,
    fullPathPolyLineDrawn: state => state.fullPathPolyLineDrawn,
    isManualWorkTimeValid: state => state.isManualWorkTimeValid,
    existingbreakTimeInSeconds: state => state.existingbreakTimeInSeconds,
    existingDownTimeInSeconds: state => state.existingDownTimeInSeconds,
    jobOperatorLocations: state => state.jobOperatorLocations,
    jobOperatorIds: state => state.jobOperatorIds,
    jobOperatorsProofOfWork: state => state.jobOperatorsProofOfWork,
    invoiceReportUrlGenerationInProgress: state => state.invoiceReportUrlGenerationInProgress,
    invoiceJobReportUrls: state => state.invoiceJobReportUrls,
    invoiceReportGenerationInProgress: state => state.invoiceReportGenerationInProgress,
    generatedFromJobDetails: state => state.generatedFromJobDetails,
}

export const mutations = {
    setJobDetails(state, data) {
        state.jobDetails = new TeamJob().getTeamJob(data);
        state.jobOperatorIds = [];

        if(state.jobDetails.operators && state.jobDetails.operators.length) {
            state.jobDetails.operators.forEach(operator => {
                state.jobOperatorIds.push(operator.id);
            })
        } 
        state.jobOperatorIds = [...new Set(state.jobOperatorIds)]
    },

    setJobOperatorLocations(state, operatorLocations) {
        state.jobOperatorLocations = operatorLocations;
    },

    setJobsDetailsInvoice(state, data) {
        state.jobDetails = new TeamJob().getTeamJob(data);
    },

    setProductsListForCompleteJob(state, data) {
        data.map((data) => state.productsListForCompleteJob.push(data));
    },

    setInvoiceJobReportUrls(state, data){
        const obj = {
            reference: data[1],
            url: data[0]
        }
        state.invoiceJobReportUrls.push(obj);
    },
    
    setInvoiceJobReportSummaryUrls(state, {reference, url}) {
        state.invoiceJobReportUrls = []
        state.invoiceJobReportUrls.push({ reference, url })
    },

    resetJobDetails(state) {
        state.jobDetails = new TeamJob();
        state.jobOperatorLocations = [];
        state.jobOperatorIds = [];
        state.jobOperatorsProofOfWork = [];
        state.invoiceReportUrlGenerationInProgress = false;
        state.invoiceReportGenerationInProgress = false;
        state.invoiceJobReportUrls = [];
        state.generatedFromJobDetails = false;
    }
}