import * as allConstants from '@/utils/constants';

const ConstantValues: any = {
    ...allConstants
}

ConstantValues.install = function(Vue) {
    Vue.prototype.$constantValues = (key) => {
        return ConstantValues[key]
    }
}

export default ConstantValues;