






































import { formFieldPb } from '@/utils/uiconstants';
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { validateRegexPhoneNumber } from '@/utils/helpers';
import {validationMixin} from 'vuelidate';
import {maxLength, minLength} from 'vuelidate/lib/validators';
import ConstantValues from '@/plugins/constantValues';

export default {
    name: 'SecondaryContactDetailsForm',
    mixins: [validationMixin, contactComputedMixin],
    validations: {
        contactPhone: {
            format: phone => /[0-9]/.test(phone),
            maxLength: maxLength(ConstantValues.maxlengthPhoneNumber),
            minLength: minLength(ConstantValues.minlengthPhoneNumber)
        }
    },
    components: {
        SectionLeftHeadings,
    },
    data() {
        return {
            formFieldPb
        }
    },
    methods: {
       checkPhoneNumber(event) {
           validateRegexPhoneNumber(event);
       }
    }
}
