












































import { getConvertedAreaUnit } from "@/utils/helpers";
import fieldsComputedMixin from "@/components/fields/fields-computed";
import { mapMutations } from "vuex";

export default {
  props: ["fieldInfo"],
  mixins: [fieldsComputedMixin],
  methods: {
    ...mapMutations({
      clearFieldDetailsState: "resetFieldDetails",
    }),
    getAreaFormatted(fieldAreaInSquareMeters) {
      if (
        fieldAreaInSquareMeters != undefined &&
        fieldAreaInSquareMeters != null
      ) {
        return getConvertedAreaUnit(fieldAreaInSquareMeters);
      }
      return "";
    },
    getStyledObject(fieldColor) {
      return {
        backgroundColor: fieldColor,
      };
    },
    getFieldsOwnerListInternal() {
      this.fieldOwnerList = [];
      this.fieldOwnerFilterList = [];
      this.$store.commit("setDefaultOwnerList", this.loginUserBusinessName);
      this.$store.commit(
        "setDefaultOwnerFilterList",
        this.loginUserBusinessName
      );
      this.$store.dispatch("getFieldsOwnerListInternal");
    },
  },
};
