import ConstantValues from "@/plugins/constantValues"

const initialState = () => {
    return {
        offset: ConstantValues.defaultPageOffsetSize,
        limit: ConstantValues.defaultPageLimitSize,
        responseRowCount: ConstantValues.defaultPageListCount,
        totalResponseRowCount: ConstantValues.defaultPageListCount,
        loading: false,
        totalRowCount: ConstantValues.defaultPageTotalRowCount,
        noData: false,
        filtersApplied: false,
        error: false,
        errorMessage: '',
        filterOptions: [],
        queryString: null,
        searchText: '',
    }
}

export const state = initialState();

export const getters = {
    offset: state => state.offset,
    limit: state => state.limit,
    responseRowCount: state => state.responseRowCount,
    loading: state => state.loading,
    totalRowCount: state => state.totalRowCount,
    noData: state => state.noData,
    filtersApplied: state => state.filtersApplied,
    error: state => state.error,
    searchText: state => state.searchText,
    showLoadMoreButtonGetter: state =>  {
       return state.totalResponseRowCount < state.totalRowCount && state.responseRowCount == state.limit;
    }
}

export const mutations = {
    setOffset(state, data) {
        state.offset = data;
    },
    setNoDataStatus(state, data) {
        state.noData = data?.length == 0
    },
    resetPaging(state) {
        Object.assign(state, initialState())
    },
    setResponseRowCount(state, data) {
        state.responseRowCount = data
    },
    setTotalResponseRowCount(state, data) {
        state.totalResponseRowCount = state.totalResponseRowCount + data
    },
    setTotalRowCount(state, data) {
        state.totalRowCount = data
    },
    fetchingData(state) {
        state.loading = true
    },
    doneFetchingData(state) {
        state.loading = false
    },
    resetError(state, data) {
        state.error = data;
    },
    apiCallError(state, {error, response}) {
        if(response?.status !== 200) {
            state.error = true;
            state.errorMessage = error
        }
    },
    setFilterOptions(state, data) {
        state.filterOptions.push(data);
    },
    setClearFilterOptions(state) {
        state.filterOptions = [];
    },
    setQueryString(state, data) {
        if(!data) return;
        state.queryString = data;
    },
}