import moment from 'moment';

export const generateRandomHexColorString = () => {
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    if (randomColor.length != 7) {
        return generateRandomHexColorString();
    } else {
        return randomColor;
    }
}

export const generateRandomFilename = () => {
    const userId = localStorage.getItem('userId');
    const currentTimestamp = moment(new Date()).format('MM_DD_YYYY_h_mm_ss');
    const randomNumber = Math.floor(Math.random() * 1000000);
    return userId + '_' + currentTimestamp + '_' + randomNumber;
}