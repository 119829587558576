import { ITeamJobOperation } from '../interfaces/ITeamJobOperation';
import { TeamJobOperationBillingQuantity } from './TeamJobOperationBillingQuantity';
import { MetaInfo } from './MetaInfo'
import { HealthCheckListItem } from './HealthCheckListItem';

export class TeamJobOperation implements ITeamJobOperation {
    id: string;
    title?: string;
    displayTitle?: string;
    description: string;
    billingCost: number;
    billingUnit: string;
    isTaxable: boolean;
    vat: number;
    typeId: string;
    typeName: string;
    billingQuantities: TeamJobOperationBillingQuantity[] = new Array<TeamJobOperationBillingQuantity>();
    loadInformationList: MetaInfo[] = new Array<MetaInfo>();
    healthSafetyList?: HealthCheckListItem[] = new Array<HealthCheckListItem>();
    isHealthSafetyListMandatory?: boolean;

    constructor() {
        this.id = null;
        this.title = null;
        this.displayTitle = null;
        this.description = null;
        this.billingCost = null;
        this.billingUnit = null;
        this.isTaxable = false;
        this.vat = 0;
        this.typeId = null;
        this.typeName = null;
        this.loadInformationList = new Array<MetaInfo>();
        this.billingQuantities = [];
        this.healthSafetyList = new Array<HealthCheckListItem>();
        this.isHealthSafetyListMandatory = false;
    }

    public getTeamJobOperation(teamJobOperation: TeamJobOperation) {
        this.id = teamJobOperation.id || null;
        this.title = teamJobOperation.title || null;
        this.displayTitle = teamJobOperation.displayTitle || teamJobOperation.title || null;
        this.description = teamJobOperation.description || null;
        this.billingCost = teamJobOperation.billingCost || 0;
        this.billingUnit = teamJobOperation.billingUnit || null;
        this.isTaxable = teamJobOperation.isTaxable || false;
        this.vat = teamJobOperation.vat || 0;
        this.typeId = (teamJobOperation && teamJobOperation.typeId) ? teamJobOperation.typeId : null;
        this.typeName = (teamJobOperation && teamJobOperation.typeName) ? teamJobOperation.typeName : null;
        this.loadInformationList = (teamJobOperation && teamJobOperation.loadInformationList) ? teamJobOperation.loadInformationList.map(info => new MetaInfo().getMetaInfo(info)) : new Array<MetaInfo>();
        if (teamJobOperation && teamJobOperation.billingQuantities && teamJobOperation.billingQuantities.length) {
            this.billingQuantities = teamJobOperation.billingQuantities.map(
                billingQuantity => new TeamJobOperationBillingQuantity().getTeamJobOperationBillingQuantity(billingQuantity)
            )
        } else {
            this.billingQuantities = new Array(new TeamJobOperationBillingQuantity());
        }

        if (teamJobOperation && teamJobOperation.healthSafetyList && teamJobOperation.healthSafetyList.length) {
            this.healthSafetyList = teamJobOperation.healthSafetyList.map(
                hsl => new HealthCheckListItem().getHealthChecklistItem(hsl)
            )
        } else {
            this.healthSafetyList = [];
        }

        if (teamJobOperation && teamJobOperation.isHealthSafetyListMandatory != undefined && teamJobOperation.isHealthSafetyListMandatory != null) {
            this.isHealthSafetyListMandatory = teamJobOperation.isHealthSafetyListMandatory;
        } else {
            this.isHealthSafetyListMandatory = false;
        }
        return this;
    }

}