





































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import FileReader from "@/mixins/file-reader";
import { JobAttachmentPurposeType } from '@/enum/jobAttachmentPurposeType';
import { JobAttachmentType } from '@/enum/jobAttachmentType';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import TeamJobsAttachmentsPdf from '@/components/team-jobs/common/team-jobs-attachments-pdf.vue';
import { defaultImagePlaceholder } from '@/utils/uiconstants';

    export default {
        name: "TeamJobsAttachments",
        mixins: [TeamJobsComputed, FileReader, TeamJobsCommon],
        data() {
            return {
                selectedImages: [],
                JobAttachmentPurposeType,
                JobAttachmentType
            };
        },
        created() {
            this.base64ImagePlaceholder = defaultImagePlaceholder;
        },
        components: {
            TeamJobsAttachmentsPdf
        },
        methods: {
            previewImage() {
                this.selectedImages.map((img) => {
                    this.imageToFormData(img);
                });
            },
            async imageToFormData(file) {
                const obj = await this.getFileObjectFormData(file);
                
                this.$store.dispatch("addTeamJobAttachment", {
                    obj: obj,
                    type: this.getAttachmentFileType(file),
                    purpose: JobAttachmentPurposeType.Creation
                }).then((res) => {
                    this.enableTeamJobDiscard();
                    this.selectedImages = [];
                });
            },
            removeSelectedImage(img) {
                if (img.id != null) {
                    this.teamJobAttachments = this.teamJobAttachments.filter(x => x.id != img.id);
                }
                else {
                    if (!this.attachmentDeleteInProgress) {
                        this.attachmentDeleteInProgress = true;
                        this.$store.dispatch("deleteTeamJobAttachment", img.url).then(() => {
                            this.attachmentDeleteInProgress = false;
                        });
                        this.selectedImages = [];
                    }
                }
                this.enableTeamJobDiscard();
            },
            showImage(imageUrl) {
                this.tempImageUrl = imageUrl;
                this.showImagePreview = true;
            }
        }
    }
