




















































































































































































































































































import $ from "jquery";
import { clearDateTimePicker, notify, openOverlay, parseFloatDecimal } from "@/utils/helpers";
import { mapMutations } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import { apiInvoices, routeAccount, routeInvoices, routeInvoicesAdd } from "@/utils/endpoints";
import router from "@/router/index";
import moment from "moment";
import invoicesComputedMixin from "@/components/invoices/invoices-computed";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { DeleteItemDialog } from '@/components/shared/dialogs/index';
import List from '@/components/shared/list.vue';
import ConstantValues from '@/plugins/constantValues';
import { AccountTabType } from '@/enum/accountTabType';
import StringConstants from '@/plugins/stringConstants';
import { excelParser } from "@/utils/helpers/excel-parser-helpers";
import { ExcelExportModuleType } from "@/enum/excelExportModuleType";
import PaginationModule from "@/store/modules/pagination";
import LoadMoreItem from "@/components/shared/load-more-item.vue";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import {InvoiceEmailStatusType} from '@/enum/invoiceStatusType';
import { defaultDateFormat } from "@/utils/constants";

export default {
    name: "InvoicesListView",
    mixins: [
        permissionsMixin, 
        scrollerMixin, 
        invoicesComputedMixin, 
        requestsCancellationMixin
    ],
    components: {
        Button,
        DeleteItemDialog,
        List,
        LoadMoreItem,
        DatePicker,
    },
    computed: {
        invoiceFilterEmailStatus() {
            return InvoiceEmailStatusType
        },
        invoiceFilterEmailStatusOptions() {
            return ['All', ...Object.keys(InvoiceEmailStatusType).filter(status => isNaN(Number(status)))]
        },
        datesAppliedOnFilter() {
            if (!this.invoicesListFilterStartDate || !this.invoicesListFilterEndDate) return [];
            return [new Date(this.invoicesListFilterStartDate), new Date(this.invoicesListFilterEndDate)]
        }
    },
    data() {
        return {
            deleteDialog: false,
            deleteInvoiceId: "",
            dateInput: {
                placeholder: StringConstants.selectDatePlaceholder,
            },
            format: defaultDateFormat,
            sameDateFormat: {
                from: defaultDateFormat,
                to: defaultDateFormat,
            },
            deleteInvoice: false,
            showExportDialog: false,
        };
    },
    beforeDestroy() {
        if (!this.isPageRelated) {
            this.clearAndResetInvoicesPagingStates();
        }
        if(this.$store.hasModule(this.$stringConstants('invoicesListFilterCustomersPaginationModule'))){
            this.$store.unregisterModule(this.$stringConstants('invoicesListFilterCustomersPaginationModule'));
        }
    },
    created() {
        if (!this.$store.hasModule(this.$stringConstants('invoicesListFilterCustomersPaginationModule'))) {
            this.$store.registerModule(this.$stringConstants('invoicesListFilterCustomersPaginationModule'), PaginationModule);
        }
    },
    mounted() {
        if (!this.isPageRelated || this.invoicesList.length === 0) {
            this.invoicesList = [];
            this.invoiceRefs = [];
            this.clearInvoiceFilterOptions();
            this.invoiceJobsPreviewLoader = false;
            this.invoiceFilterStatusSelected = this.invoiceFilterStatus[0].value;
            this.invoiceFilterEmailStatusSelected = this.invoiceFilterEmailStatusOptions[0];
            this.clearAndResetSearchFilters();
            this.invoicesListFilterCustomerValue = null;
            this.invoicesListFilterCustomersList = [];
            this.clearInvoicesDateFilter();
            this.setFilterOptionsAndGetInvoices();
        }

        let dInputStartDate = document.getElementsByClassName(
            "vdpr-datepicker__calendar-input-date-elem"
        )[0] as HTMLInputElement;
        if (dInputStartDate) {
            dInputStartDate.placeholder = "DD/MM/YYYY";
        }

        let dInputEndDate = document.getElementsByClassName(
            "vdpr-datepicker__calendar-input-date-elem"
        )[1] as HTMLInputElement;
        if (dInputEndDate) {
            dInputEndDate.placeholder = "DD/MM/YYYY";
        }

        let dInputStartTime = document.getElementsByClassName(
            "vdpr-datepicker__calendar-input-time-elem"
        )[0] as HTMLInputElement;
        if (dInputStartTime) {
            dInputStartTime.placeholder = "HH:MM";
        }

        let dInputEndTime = document.getElementsByClassName(
            "vdpr-datepicker__calendar-input-time-elem"
        )[1] as HTMLInputElement;
        if (dInputEndTime) {
            dInputEndTime.placeholder = "HH:MM";
        }
    },
    methods: {
        ...mapMutations({
            clearInvoiceDetailsState: "setClearInvoiceData",
            clearInvoiceFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging",
        }),
        openAddInvoice() {
            this.$store.state.scrollToItem = null;
            this.$store.dispatch("getInvoiceConfiguration").then((res) => {
                if(res?.businessProfile?.config?.salesTaxRates?.length === 0) {
                    notify(this.$stringConstants('invoiceNoTaxRatesWarning'), 'fail')
                    router.push(routeAccount)
                    setTimeout(() => {
                        this.accountTab = AccountTabType.Business;
                    }, ConstantValues.defaultTabSelectionTimeout)

                    setTimeout(() => {
                        document.getElementById('account-business').scrollBy(0, 700)
                    }, ConstantValues.defaultScrollToPositionTimeout)
                } else {
                    this.clearInvoiceDetailsState();
                    this.$store.state.jobsInvoicesModule.map = null;
                    router.push(routeInvoicesAdd);
                }
            });
        },
        applySearchInvoicesFilter() {
            this.clearInvoiceFilterOptions();
            this.resetPagingForModule();
            this.invoicesList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetInvoices();
        },
        promtDeleteInvoice(invoiceId: string) {
            this.deleteDialog = true;
            this.deleteInvoiceId = invoiceId;
        },
        proceedInvoiceDeletion() {
            this.deleteDialog = false;
            this.$store
                .dispatch("deleteInvoice", this.deleteInvoiceId);
        },
        cancelInvoiceDeletion() {
            this.deleteDialog = false;
            this.deleteInvoiceId = "";
        },
        clearAndResetInvoicesPagingStates() {
            $(window).unbind("scroll");
            this.$store.state.invoicesModule.invoicesList = [];

            this.clearInvoiceDetailsState();
            this.clearInvoiceFilterOptions();
            this.resetPagingForModule();
            this.filtersApplied = false;
        },
        getLocalFormat(date) {
            const formattedDate = moment.utc(date).local().format("DD MMM YYYY");
            return formattedDate;
        },
        openInvoiceDetails(invoice) {
            this.$store.state.jobsInvoicesModule.map = null;
            this.$store.dispatch("getInvoiceDetails", invoice.id);
            this.saveListItemID(invoice.id);
            router.push(routeInvoices + "/" + invoice.id);

            this.invoiceSelectedTab = 3;
        },
        filterInvoicesByStatus() {
            this.clearInvoiceFilterOptions();
            this.resetPagingForModule();
            this.invoicesList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetInvoices();
        },
        filterInvoicesByEmailStatus(value) {
            this.invoiceFilterEmailStatusSelected = value;
            this.clearInvoiceFilterOptions();
            this.resetPagingForModule();
            this.invoicesList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetInvoices();
        },
        openJobsPreview(jobIds: Array<any>) {
            openOverlay("invoiceJobsPreviewFlyout");
            this.$store.dispatch("getJobsListForPreview", jobIds.join());
        },
        getInvoiceCost(invoiceCost) {
            if (invoiceCost) {
                return parseFloatDecimal(invoiceCost);
            }
            return "N/A";
        },
        resetPagingForModule() {
            const $state = this.$store.state.invoicesModule;
            $state.offset = ConstantValues.defaultPageOffsetSize;
            $state.limit = ConstantValues.defaultPageLimitSize;
            $state.listCount = ConstantValues.defaultPageListCount;
            $state.noData = false;
            this.resetPaging();
        },
        setFilterOptionsAndGetInvoices(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            this.$store.dispatch('fnSetInvoicesFilter', filters)
            .then(() => {
                this.$store.dispatch('getInvoicesList')
                if (this.invoiceSearchText == "") {
                    this.filtersApplied = false;
                }
            });
        },
        saveInvoicesDataToExcel() {
            this.$store.commit('removeIdsFromExportableData')
            excelParser().exportDataFromJSON(
                this.exportData,
                null,
                ConstantValues.defaultListExportFormat,
            )
        },
        getMoreInvoicesForExport() {
            this.$store.dispatch("getDataListForExport", {
                apiUrl: apiInvoices,
                moduleType: ExcelExportModuleType.Invoices
            }).then(() => {
                if (this.exportDataLoaded < this.exportDataTotal) {
                    this.getMoreInvoicesForExport();
                } else {
                    if (this.exportData.length > 0) {
                        this.showExportDialog = false;
                        this.saveInvoicesDataToExcel();
                    }
                }
            });
        },
        getInvoicesDataForDownload() {
            this.$store.dispatch("setExportDataParameterFilters", [
                { searchText: this.invoiceSearchText },
                { statuses: this.invoiceFilterStatusSelected },
                { customerId: this.invoicesListFilterCustomerValue },
                { start: this.invoicesListFilterStartDate },
                { end: this.invoicesListFilterEndDate },
            ]).then(() => {
                this.$store.dispatch("getDataListForExport", {
                    apiUrl: apiInvoices,
                    moduleType: ExcelExportModuleType.Invoices
                }).then(() => {
                    this.getMoreInvoicesForExport();
                });
            });
        },
        initiateInvoicesExport() {
            this.$store.commit('resetExportData')
            this.clearInvoiceFilterOptions();
            this.resetPaging();
            this.showExportDialog = true;
            this.getInvoicesDataForDownload();
        },
        applyInvoicesListCustomersFilterSearchTimer() {
            if (this.searchTimerCustomersFilter) {
                clearTimeout(this.searchTimerCustomersFilter);
                this.searchTimerCustomersFilter = null;
            }
            this.searchTimerCustomersFilter = setTimeout(() => {
                this.searchCustomersByText();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        searchCustomersByText: function () {
            this.invoicesListFilterCustomersList = [];
            this.clearInvoiceFilterOptions();
            this.appendMoreCustomers();
        },
        appendMoreCustomers() {
            const paginationModule = this.$stringConstants('invoicesListFilterCustomersPaginationModule');
            this.$store.dispatch('setCustomersForInvoicesSearchFilters');
            this.$store.dispatch('getCustomersForInvoicesSearch').then(response => this.$store.dispatch(`${paginationModule}/updateStates`, response));
        },
        getInvoicesByCustomer() {
            this.clearInvoiceFilterOptions();
            this.resetPagingForModule();
            this.invoicesList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetInvoices();
        },
        filterInvoicesByDateRange(start, end) {
            this.invoicesListFilterStartDate = moment(start).utc().format();
            this.invoicesListFilterEndDate = moment(end).utc().format();
            this.clearInvoiceFilterOptions();
            this.resetPagingForModule();
            this.invoicesList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetInvoices();
        },
        clearInvoicesDateFilter() {
            $(".vdpr-datepicker input[type=text]").first().val("");
            clearDateTimePicker(this.$refs.datePicker.$el);
            this.invoicesListFilterStartDate = null;
            this.invoicesListFilterStartDateLocal = null;
            this.invoicesListFilterEndDate = null;
            this.clearInvoiceFilterOptions();
            this.resetPagingForModule();
            this.invoicesList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetInvoices();
        },
        clearAndResetSearchFilters() {
            this.invoiceSearchText = ''
            this.applySearchInvoicesFilter()
        },
        saveListItemID(id) {
            this.$store.state.scrollToItem = id;
        },
    },
};
