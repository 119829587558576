import router from '../router/index';
import { getCookie } from '@/utils/helpers';
import { routeLogin } from '../utils/endpoints';
import { MachineType } from '../enum/machineType';
import { listAction } from '../enum/listAction';
import ConstantValues from '@/plugins/constantValues';
import { Currency } from '@/data/models/Currency';
import { InvoiceConfiguration } from '@/data/models/InvoiceConfiguration';
import { UserRoleType, UserRoleTypeLabel } from '@/enum/userRoleType';
import { TimeZone } from '@/data/models/TimeZone';

export const state = {
    baseUrl: process.env.VUE_APP_BASE_URL,
    loggedIn: false,
    dashboardTitle: null,
    loader: true,
    tempUrl: '',
    offset: ConstantValues.defaultPageOffsetSize,
    limit: ConstantValues.defaultPageLimitSize,
    listCount: ConstantValues.defaultPageListCount,
    listLoading: false,
    filterOptions: [],
    totalRowCount: ConstantValues.defaultPageTotalRowCount,
    rowCount: ConstantValues.defaultPageRowCount,
    noData: false,
    filtersApplied: false,
    refreshTokenRetries: 0,
    isPermissionsSet: false,
    permissionsList: [],
    isListBind: false,
    componentsPermissions: ConstantValues.allComponentsPermissions,
    hoursList: [],
    minutesList: [],
    loginUserRole: null,
    loginUserName: null,
    loginUserBusinessName: null,
    machineType: MachineType.None,
    listActionType: listAction.Delete,
    enumOwnership: [
        { title: "Rented", value: 0 },
        { title: "Owned", value: 1 },
        { title: "Other", value: 2 }
    ],
    
    enumMemberRoles: [
        { name: "Operator", type: 4 },
        { name: "TeamLead", type: 3 },
        { name: "Manager", type: 2 },
    ],
    enumUserRoles: [
        { name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.Contractor]]), type: UserRoleType.Contractor },
        { name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.Farmer]]), type: UserRoleType.Farmer },
        { name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.Manager]]), type: UserRoleType.Manager },
        { name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.Operator]]), type: UserRoleType.Operator },
        { name: UserRoleTypeLabel.get(UserRoleType[UserRoleType[UserRoleType.TeamLead]]), type: UserRoleType.TeamLead },
    ],
    searchNotify: 'Press enter to search',
    jobAssignmentLevel: {
        "primaryAssignedUserLevel": 0,
        "secondaryAssignedUserLevel": 1,
    },
    enumPreference: {
        'NotArchived': 0,
        'Archived': 1,
        'All': 2
    },
    enumSubscriptionStatus: {
        'Trialing': 0,
        'Active': 1,
        'Pending': 2,
        'Cancelled': 3
    },

    statusFilterTypeList: [
        { title: 'Accepted', value: 0 },
        { title: 'Pending', value: 1 }
    ],
    userStatusFilterTypeList: [
        { title: 'Active', value: 0 },
        { title: 'Inactive', value: 1 },
        { title: 'Pending', value: 2 }
    ],
    customerTypeFilterList: [
        { title: 'All', value: null },
        { title: 'Internal', value: true },
        { title: 'External', value: false },
    ],
    enumListProductUnits: [],
    enumListLoadInfoUnits: [],
    enumListOperationUnits: [],
    loginLoader: false,
    disabledNavigation: false,
    hasBusinessProfile: false,
    enumActiveStatus: [
        { title: 'Active', value: 0 },
        { title: 'Inactive', value: 1 }
    ],
    routedFromTimesheets: false,
    showImagePreview: false,
    tempImageUrl: '',
    areaMeasurementUnitsList: [
        {
            title: 'Hectare',
            value: 0
        },
        {
            title: 'Acre',
            value: 1
        }
    ],
    showAllDashboardViews: true,
    snackbarShow: false,
    allItemsDeleted: false,
    dialogSelectedRows: false,
    allItemsArchived: false,
    archiveDialogSelectedRows: false,
    allItemsRestored: false,
    restoreDialogSelectedRows: false,
    selectedItems: [],
    conReqLoader: false,
    conSendLoader: false,
    inviteeRole: 0,
    maxCompanyNameLength: 40,
    maxAddressLength: 70,
    importSelectedFile: null,
    importResponseData: null,
    importInProgress: false,
    importfileName: null,
    importInvalidData: [],
    sentCurrentRequest: false,
    hasBusinessConfig: false,
    teamJobDetailsUpdatedRealTime: false,
    isPageRelated: false,
    isRoutedFromReferencePage: false,
    globalCurrenciesList: [],
    globalTimeZonesList: [],
    hasMoreData: false,
    globalTaxesNameList: [],
    globalCountriesList: [],
    invoiceConfiguration: new InvoiceConfiguration(),
    accountTab: null,
    isRoutedToAccountForTaxUpdate: false,
    drawerOpen: false,
    abortController: null,
    collapsedNavigation: false,
    notification: {
        title: null,
        message: null,
        callback: null,
        actionText: null,
        type: null
    },
    activeRouteIndex: null,
    showSuperAdminSubMenu: false,
    breadcrumbsItems: [],
    bulkActionResponse: null,
    dialogBulkActionResponse: false,
    dialogBulkActionResponseTitle: null,
    bulkActionLoader: false,
    isJobBeingAdded: false
}

export const getters = {
    loggedIn: state => state.loggedIn,
    dashboardTitle: state => state.dashboardTitle,
    noData: state => state.noData,
    filterOptions: state => state.filterOptions,
    listCount: state => state.listCount,
    listLoading: state => state.listLoading,
    filtersApplied: state => state.filtersApplied,
    refreshTokenRetries: state => state.refreshTokenRetries,
    isPermissionsSet: state => state.isPermissionsSet,
    permissionsList: state => state.permissionsList,
    loginUserRole: state => state.loginUserRole,
    isListBind: state => state.isListBind,
    componentsPermissions: state => state.componentsPermissions,
    enumOwnership: state => state.enumOwnership,
    searchNotify: state => state.searchNotify,
    enumMemberRoles: state => state.enumMemberRoles,
    enumUserRoles: state => state.enumUserRoles,
    enumRoleValuesString: state => state.enumRoleValuesString,
    jobAssignmentLevel: state => state.jobAssignmentLevel,
    minutesList: state => state.minutesList,
    hoursList: state => state.hoursList,
    enumPreference: state => state.enumPreference,
    loginUserName: state => state.loginUserName,
    loginUserBusinessName: state => state.loginUserBusinessName,
    enumListProductUnits: state => state.enumListProductUnits,
    enumListLoadInfoUnits: state => state.enumListLoadInfoUnits,
    enumListOperationUnits: state => state.enumListOperationUnits,
    enumSubscriptionStatus: state => state.enumSubscriptionStatus,
    loginLoader: state => state.loginLoader,
    disabledNavigation: state => state.disabledNavigation,
    hasBusinessProfile: state => state.hasBusinessProfile,
    statusFilterTypeList: state => state.statusFilterTypeList,
    enumActiveStatus: state => state.enumActiveStatus,
    routedFromTimesheets: state => state.routedFromTimesheets,
    customerTypeFilterList: state => state.customerTypeFilterList,
    showImagePreview: state => state.showImagePreview,
    tempImageUrl: state => state.tempImageUrl,
    areaMeasurementUnitsList: state => state.areaMeasurementUnitsList,
    totalRowCount: state => state.totalRowCount,
    showAllDashboardViews: state => state.showAllDashboardViews,
    snackbarShow: state => state.snackbarShow,
    allItemsDeleted: state => state.allItemsDeleted,
    selectedItems: state => state.selectedItems,
    dialogSelectedRows: state => state.dialogSelectedRows,
    conReqLoader: state => state.conReqLoader,
    conSendLoader: state => state.conSendLoader,
    inviteeRole: state => state.inviteeRole,
    maxCompanyNameLength: state => state.maxCompanyNameLength,
    maxAddressLength: state => state.maxAddressLength,
    importSelectedFile: state => state.importSelectedFile,
    importResponseData: state => state.importResponseData,
    importInProgress: state => state.importInProgress,
    importfileName: state => state.importfileName,
    importInvalidData: state => state.importInvalidData,
    allItemsArchived: state => state.allItemsArchived,
    archiveDialogSelectedRows: state => state.archiveDialogSelectedRows,
    sentCurrentRequest: state => state.sentCurrentRequest,
    allItemsRestored: state => state.allItemsRestored,
    restoreDialogSelectedRows: state => state.restoreDialogSelectedRows,
    userStatusFilterTypeList: state => state.userStatusFilterTypeList,
    hasBusinessConfig: state => state.hasBusinessConfig,
    machineType: state => state.machineType,
    listActionType: state => state.listActionType,
    teamJobDetailsUpdatedRealTime: state => state.teamJobDetailsUpdatedRealTime,
    isPageRelated: state => state.isPageRelated,
    isRoutedFromReferencePage: state => state.isRoutedFromReferencePage,
    globalCurrenciesList: state => state.globalCurrenciesList,
    globalTimeZonesList: state => state.globalTimeZonesList,
    hasMoreData: state => state.hasMoreData,
    limit: state => state.limit,
    offset: state => state.offset,
    globalTaxesNameList: state => state.globalTaxesNameList,
    invoiceConfiguration: state => state.invoiceConfiguration,
    globalCountriesList: state => state.globalCountriesList,
    accountTab: state => state.accountTab,
    isRoutedToAccountForTaxUpdate: state => state.isRoutedToAccountForTaxUpdate,
    drawerOpen: state => state.drawerOpen,
    abortController: state => state.abortController,
    collapsedNavigation: state => state.collapsedNavigation,
    notification: state => state.notification,
    activeRouteIndex: state => state.activeRouteIndex,
    showSuperAdminSubMenu: state => state.showSuperAdminSubMenu,
    breadcrumbsItems: state => state.breadcrumbsItems,
    bulkActionResponse: state => state.bulkActionResponse,
    dialogBulkActionResponse: state => state.dialogBulkActionResponse,
    dialogBulkActionResponseTitle: state => state.dialogBulkActionResponseTitle,
    bulkActionLoader: state => state.bulkActionLoader,
    isJobBeingAdded: state => state.isJobBeingAdded
}

export const mutations = {
    loginVerify(state) {
        state.loggedIn = true;
    },
    setTempUrl(state, data) {
        state.tempUrl = data;
    },
    setNoDataStatus(state, data) {
        state.noData = data != null && data.length == 0
    },

    setFilterOptions(state, data) {
        state.filterOptions.push(data);
    },
    setClearFilterOptions(state) {
        state.filterOptions = [];
    },
    resetPaging(state) {
        state.offset = ConstantValues.defaultPageOffsetSize;
        state.limit = ConstantValues.defaultPageLimitSize;
        state.listCount = ConstantValues.defaultPageListCount;
        state.totalRowCount = ConstantValues.defaultPageTotalRowCount;
        state.listLoading = false;
        state.noData = false;
        state.hasMoreData = false;
    },
    loggedOut(state) {
        state.loginLoader = false;
        state.loggedIn = false;
        state.tempUrl = '';
        state.offset = ConstantValues.defaultPageOffsetSize;
        state.limit = ConstantValues.defaultPageLimitSize;
        state.listCount = ConstantValues.defaultPageListCount;
        state.listLoading = false;
        state.filterOptions = [];
        state.totalRowCount = ConstantValues.defaultPageTotalRowCount;
        state.rowCount = ConstantValues.defaultPageRowCount;
        const routerState: any = router;
        if(routerState?.history?.current?.path !== routeLogin) {
            router.push(routeLogin);
        }
        state.refreshTokenRetries = 0;
        state.isListBind = false;
        state.isPermissionsSet = false;
        state.permissionsList = [];
        state.disabledNavigation = false;
        state.hasBusinessProfile = false;
        state.loginUserRole = null;
        state.showAllDashboardViews = true;
        state.collapsedNavigation = false;
    },
    setPermissionsList(state, data) {
        state.permissionsList = data;
    },
    setLoginUserRole(state) {
        const role = parseInt(JSON.parse(getCookie('role')));
        state.loginUserRole = role;
    },
    setHoursList(state, data) {
        state.hoursList.push(data);
    },
    setMinutesList(state, data) {
        state.minutesList.push(data);
    },
    setProductUnits(state, data) {
        state.enumListProductUnits = [];
        data.map(productUnit => {
            state.enumListProductUnits.push({
                title: productUnit,
                value: productUnit
            });
        });
    },
    setOperationUnits(state, data) {
        state.enumListOperationUnits = [];
        data && data.map(operationUnit => {
            state.enumListOperationUnits.push({
                title: operationUnit,
                value: operationUnit
            });
        });
    },
    setLoadInfoUnits(state, data) {
        state.enumListLoadInfoUnits = [];
        data && data.map(loadInfoUnit => {
            state.enumListLoadInfoUnits.push({
                title: loadInfoUnit,
                value: loadInfoUnit
            });
        });
    },
    setInvoiceConfiguration(state, data) {
        if(data?.businessProfile?.config) {
            const { config } = data.businessProfile;
            state.invoiceConfiguration = new InvoiceConfiguration().getInvoiceConfiguration(config);
        }
    },
    resetInvoiceConfiguration(state, _) {
        state.invoiceConfiguration = new InvoiceConfiguration();
    },
    setOwnerDetailsLocal(state, data) {
        if (data) {
           let fullOwnerBusinessName = '';
            const fullOwnerName = (data.firstName ? data.firstName : '') + ' ' + (data.lastName ? data.lastName : '');
            if (data.businessProfile) {
                localStorage.setItem('businessId', data.businessProfile.id);
            }
            if (data.businessProfile && data.businessProfile.title) {
                const { title } = data.businessProfile;
                fullOwnerBusinessName = title;
            } else {
                const { firstName, lastName } = data;
                fullOwnerBusinessName = (firstName ? firstName : '') + ' ' + (lastName ? lastName : '');
            }
            localStorage.setItem('fullOwnerBusinessName', fullOwnerBusinessName);
            state.loginUserBusinessName = fullOwnerBusinessName;
            localStorage.setItem('fullOwnerName', fullOwnerName);
            localStorage.setItem('colorCode', data.colorCode);
            if (data.businessProfile && data.businessProfile.config) {
                localStorage.setItem('defaultStartingLat', data.businessProfile.config.defaultLatitude);
                localStorage.setItem('defaultStartingLng', data.businessProfile.config.defaultLongitude);
                localStorage.setItem('defaultAreaMeasurementUnit', data.businessProfile.config.defaultAreaMeasurementUnit);
                if (data.businessProfile.config?.currency?.symbol) {
                    localStorage.setItem('currencySymbol', data.businessProfile.config.currency.symbol);
                }

                if (data.businessProfile.address?.country) {
                    localStorage.setItem('country', data.businessProfile.address.country);
                }
            }
        }
    },
    clearSnackbarDetails(state) {
        state.snackbarShow = false;
        state.allItemsDeleted = false;
        state.allItemsArchived = false;
        state.allItemsRestored = false;
        state.selectedItems = [];
        state.dialogSelectedRows = false;
    },
    
    setClearUserInvitationState(state) {
        state.inviteeRole = 0;
    },
    resetImportDataDetails(state) {
        state.importSelectedFile = null;
        state.importResponseData = null;
        state.importInProgress = false;
        state.importfileName = null;
        state.importInvalidData = [];
    },
    setSentCurrentRequest(state, data) {
        state.sentCurrentRequest = data;
    },
    
    setPageOffset(state, data) {
        state.offset = state.offset + data;
    },
    setDefaultPageLimitSize(state, limit) {
        state.limit = limit;
    },
    setGlobalCurrenciesList(state, data){
        state.globalCurrenciesList = [];
        data.forEach(x => state.globalCurrenciesList.push(new Currency().setCurrency(x)));
    },
    setGlobalTimeZonesList(state, data){
        state.globalTimeZonesList = [];
        data.forEach(tz => state.globalTimeZonesList.push(new TimeZone().setTimeZone(tz)));
    },
    setGlobalCountriesList(state, data){
        state.globalCountriesList = [];
        state.globalCountriesList = data;
    },
    setGlobalTaxesNameList(state, data){
        state.globalTaxesNameList = [];
        state.globalTaxesNameList = data;
    },
    setDrawerOpen(state, data) {
        state.drawerOpen = data;
    },
    setDashboardTitle(state, title) {
        state.dashboardTitle = title;
    },
    setCollapsedNavigation(state, data) {
        state.collapsedNavigation = data;
    },
    setNotification(state, notificationObject) {
        state.notification = notificationObject;
    },
    cleanUpNotification(state) {
        state.notification = null
    },
    resetBreadcrumbsItems(state) {
        state.dashboardTitle = null
        state.breadcrumbsItems = []
    },
    updateScrollToItem(state, itemId) {
        state.scrollToItem = itemId;
    },
    updatePagingAfterListItemRemoval(state) {
        state.listCount--;
        state.totalRowCount--;
        state.noData = state.listCount === 0;
    },
    toggleIsJobBeingAdded(state) {
        state.isJobBeingAdded = !state.isJobBeingAdded;
    }
}