import { Coordinate } from "./Coordinate";

export class Address {
    id?: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    coordinate: Coordinate;

    constructor() {
        this.addressLine1 =  null;
        this.addressLine2 = null;
        this.city = null;
        this.state = null;
        this.country = null;
        this.postalCode = null;
        this.coordinate = null;
    }

    public setResponseAddress(address: Address) {
        this.id = address?.id || null;
        this.addressLine1 = address?.addressLine1 || null;
        this.addressLine2 = address?.addressLine2 || null;
        this.city = address?.city || null;
        this.state = address?.state || null;
        this.country = address?.country || null;
        this.postalCode = address?.postalCode || null;
        this.coordinate = new Coordinate().getCoordinate(address?.coordinate || null);

        return this;
     }
}