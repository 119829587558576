































import { formFieldPb } from '@/utils/uiconstants';
import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { notify } from '@/utils/helpers';
import moment from 'moment';

export default {
    name: 'PersonalDetailsForm',
    mixins: [accountComputedMixin],
    components: {
        SectionLeftHeadings,
    },
    data() {
        return {
            formFieldPb,
        }
    },
}
