import { sortObjectsListByIdentifier } from "@/utils/helpers";

export class InvoiceConfiguration {
    invoiceInstructions: string;
    invoiceTermsInDays: number;
    salesTaxName: string;
    salesTaxRegistrationNumber: string;
    salesTaxRates: Array<any>;

    constructor() {
        this.invoiceInstructions = '';
        this.invoiceTermsInDays = 0;
        this.salesTaxName = '';
        this.salesTaxRegistrationNumber = '';
        this.salesTaxRates = [];
    }

    getInvoiceConfiguration(invoiceConfiguration: InvoiceConfiguration) {
        this.invoiceInstructions = invoiceConfiguration.invoiceInstructions || '';
        this.invoiceTermsInDays = invoiceConfiguration.invoiceTermsInDays || 0;
        this.salesTaxName = invoiceConfiguration.salesTaxName || '';
        this.salesTaxRegistrationNumber = invoiceConfiguration.salesTaxRegistrationNumber || '';

        if(invoiceConfiguration.salesTaxRates?.length) {
            this.salesTaxRates = sortObjectsListByIdentifier(invoiceConfiguration.salesTaxRates, 'rateInPercentage')
        }

        return this;
    }
}