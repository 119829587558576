import { fieldColors } from '@/utils/uiconstants';

    export default {
      name: 'InvoicesMapExternalMixin',
      data() {
            return {
               mapLoader: false
            }
        },
      computed: {
        invoiceDraftPreviewDetailsExternal: {
              get() {
                  return this.$store.getters.invoiceDraftPreviewDetailsExternal;
              },
              set(newVal) {
                  this.$store.state.invoicesExternalModule.invoiceDraftPreviewDetailsExternal = newVal;
              }
        },
        selectedJobsForInvoiceExternal: {
            get() {
                return this.$store.getters.selectedJobsForInvoiceExternal;
            },
            set(newVal) {
                this.$store.state.invoicesExternalModule.selectedJobsForInvoiceExternal = newVal;
            }
        },
      },
    }