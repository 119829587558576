




























































































































































































































































import accountComputedMixin from '@/components/account/account-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { checkFieldsValidity, getAddressAsSingleString } from '@/utils/helpers';
import Loader from '@/components/shared/loaders/list-loader.vue';
import { BaseButton as Button } from '@/components/shared/buttons/index';

export default {
    name: 'AccountFarmsAddress',
    mixins: [accountComputedMixin],
    components: {
        SectionLeftHeadings,
        Loader,
        Button
    },
    data() {
        return {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
            showFarmAddressAddFields: false
        }
    },
    computed: {
        isNewFarmFieldsValid() {
            if (checkFieldsValidity([
                this.addressLine1,
                this.postalCode
            ])) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        removeSelectedAddress(addressId: string) {
            this.$store.dispatch("deleteUserAddresses", addressId).then((res) => {
                this.$store.dispatch('getUserFarmAddress');
            });
        },
        
        Update(id) {
            let address = this.accountFarmAddresses.filter(x => x.id == id)[0];
            this.$store.dispatch('updateUserAddresses', address).then((res) => {
                this.$store.dispatch('getUserFarmAddress');
            });
        },

        displayAddFarmAddressFields() {
            this.showFarmAddressAddFields = true;
        },

        isFarmAddressValid(address){
             if (checkFieldsValidity([
                address.addressLine1,
                address.postalCode
            ])) {
                return true;
            } else {
                return false;
            }
        },

        addNewAddress() {
            let newAddressObj = {};
            newAddressObj = {
                addressLine1: this.addressLine1,
                addressLine2: this.addressLine2,
                city: this.city,
                state: this.state,
                postalCode: this.postalCode,
                country: this.country,
                coordinate: null,
            }
            this.$store.dispatch('addUserAddresses', newAddressObj).then((res) => {
                this.$store.dispatch('getUserFarmAddress');
                this.hideAddFarmAddressFields();
            });
        },

        hideAddFarmAddressFields() {
            this.addressLine1 = '';
            this.addressLine2 = '';
            this.city = '';
            this.state = '';
            this.postalCode = '';
            this.country = '';
            this.latitude = 0;
            this.longitude = 0;
            this.showFarmAddressAddFields = false;
        },

        getAddressInline(address: any) {
           return getAddressAsSingleString(address);
        },
    }
}
