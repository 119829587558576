export default {
    data() {
        return {
            tempParams: null
        }
    },
    methods: {
        setLocalParamsMx(selectedStateValue: any, localParamName: any) {
            if (selectedStateValue || selectedStateValue === false) {
                localStorage.setItem(localParamName, selectedStateValue)
            } else if ((selectedStateValue == '') ||
                selectedStateValue == undefined && localStorage.getItem(localParamName)) {
                localStorage.removeItem(localParamName)
            }
        },
        getLocalParamsMx(localParamName: any, defaultStateValue: any) {
            if (localStorage.getItem(localParamName)) {
                return localStorage.getItem(localParamName)
            } else {
                return defaultStateValue
            }
        },
        removeLocalParamsMx(localParamName: any, selectedStateValue: any) {
            localStorage.removeItem(localParamName)
            return selectedStateValue
        }
    }
}