import { Machine } from '@/data/models/Machine';
export const state = {
    machineListFiltered: new Array<Machine>(),
    machineDetails: new Machine(),
    machineSearchText: '',
    machineAddCheckListItems: [],
    showAddmachineCheckListItemBtn: true,
    showAddmachineCheckListItemFields: false,
    addmachineLoader: false,
    editmachineLoader: false,
    machinesFilterType: 0
}

export const getters = {
    machineListFiltered: state => state.machineListFiltered,
    machineDetails: state => state.machineDetails,
    machineSearchText: state => state.machineSearchText,
    vehicleListColumnTitles: state => state.vehicleListColumnTitles,
    implementListColumnTitles: state => state.implementListColumnTitles,
    machineAddCheckListItems: state => state.machineAddCheckListItems,
    showAddmachineCheckListItemBtn: state => state.showAddmachineCheckListItemBtn,
    showAddmachineCheckListItemFields: state => state.showAddmachineCheckListItemFields,
    addmachineLoader: state => state.addmachineLoader,
    editmachineLoader: state => state.editmachineLoader,
    machinesFilterType: state => state.machinesFilterType,
}
export const mutations = {
    setMachineListFiltered(state, data: Array<Machine>) {
        data.forEach(x => state.machineListFiltered.push(new Machine().getVehicle(x)));
    },
    setMachineDetails(state, data) {
        state.machineDetails = new Machine();
        state.machineDetails.getVehicle(data);
    },
    addMachine(state, data: Machine) {
        state.machineListFiltered.unshift(data);
    },

    updateMachine(state, data: Machine) {
        const index: number = state.machineListFiltered.findIndex((element, index) => {
            if (element.id == data.id) {
                return true;
            }
        });
        if (index >= 0) {
            state.machineListFiltered[index] = data;
        }
    },
    clearMachine(state) {
        state.machineDetails = new Machine();
    },
    resetMachineDetails(state) {
        this.commit('clearMachine');
        state.machineAddCheckListItems = [];
        state.showAddmachineCheckListItemBtn = true;
        state.showAddmachineCheckListItemFields = false;
        state.addmachineLoader = false;
        state.editmachineLoader = false;
    },
    setClearMachinesList(state) {
        state.machineListFiltered = [];
    }
}