export default {
    data() {
        return {
            columnWidths: [],
        }
    },
    methods: {
        setColumnWidths(columnWidths) {
            this.columnWidths = columnWidths;
        },
        calculateColumnWidths(listHeader) {
            const columns = [];
            this.columnWidths = this.recursivelyFindColumns(listHeader, columns);
        },
        recursivelyFindColumns(nodeList, columns) {
            if (nodeList?.childElementCount > 0 && nodeList?.childNodes?.length > 0) {
                nodeList.childNodes.forEach(childNode => {
                    this.recursivelyFindColumns(childNode, columns)
                })
            } else {
                const targetNode = nodeList?.childNodes[0]
                if (targetNode?.nodeName == "#text") {
                    const paddingLeftOnTargetNode = document.defaultView.getComputedStyle(nodeList).paddingLeft.replace('px', '');
                    const distanceOfTargetNodeFromLeftWindow = nodeList.offsetLeft + parseInt(paddingLeftOnTargetNode);
                    const widthOfTargetNodeWithoutPadding = nodeList.offsetWidth - parseInt(paddingLeftOnTargetNode);
                    columns.push({'left': distanceOfTargetNodeFromLeftWindow + 'px', 'width': widthOfTargetNodeWithoutPadding + 'px'});
                }
            }
            return columns;
        }
    }
}