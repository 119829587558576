















import { disabledColor } from "@/utils/uiconstants";
import { getColorPreset } from '@/utils/helpers';

export default {
    name: "Avatar",
    props: ["initials", "isCircular", "isThemeColor", "isDisabled", "isListAvatar", "height", "width", "backgroundColor", "contentClass"],
    data() {
        return {
            disabledColor,
            defaultSize: '28px'
        };
    },
    methods: {
        getAvatarStyles() {
            return {
                backgroundColor: this.isDisabled ? this.disabledColor : this.getInitialColor(),
                width: this.width ? this.width : this.defaultSize,
                height: this.height ? this.height : this.defaultSize,
            }
        },
        getInitials(data: string) {
            if (typeof data === 'number') return data;
            if (data !== null && data !== '' && typeof data === 'string') {
                if (data.includes('+')) return data;
                let chr;
                let d = data.replace(/[^a-zA-Z0-9]/g, ' ');
                d = d.toUpperCase().replace(/\s\s+/g, ' ');
                chr = d.trim().split(" ");
                if (chr.length >= 2) {
                    return chr[0][0] + chr[1][0];
                } else {
                    return d[0] + d[1];
                }
            }
            return '';
        },
        getInitialColor() {
            if (this.backgroundColor) {
                return this.backgroundColor;
            }
            else {
                return getColorPreset(this.initials);
            }
        },

    },
};
