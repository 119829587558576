import { formFieldPb, brandColor } from '@/utils/uiconstants';
import { getComputedProperties } from '@/utils/helpers/computed-generator';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';

const computedProperties: Record<string, Array<string>> = {
    'onboardModule': [
        'onboardingStates',
        'onboardingStateSelected',
        'onboardSelectUserVerified',
        'onboardSelectUserBusinessInfoFilled',
        'onboardSelectUserInvoicingInfoFilled',
        'onboardLoader',
        'onboardMapSearchValue',
        'onboardConfigTempColor'
    ],
    'usersModule': [
        'userProfile',
        'businessProfilePrefixTemp',
        'startingLat',
        'startingLng',
        'usersLoader',
        'shouldCallOnboardUpdate'
    ],
    'root': [
        'areaMeasurementUnitsList',
        'hasBusinessConfig',
        'globalCurrenciesList',
        'globalTimeZonesList',
        'globalTaxesNameList',
        'globalCountriesList'
    ]
}

export default {
    name: 'OnboardComputedMixin',
    data() {
        return {
            formFieldPb,
            startTimeMenu: false,
            endTimeMenu: false,
            time: null,
            brandColor,
        }
    },
    computed: {
        ...getComputedProperties(computedProperties),
        
        loginOwnerIsContractor() {
            return getLoggedInUserRole().isUserOwnerContractorLoggedIn;
        },
    },
}