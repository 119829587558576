




























































































































































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { listContainsItem } from '@/utils/helpers';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { mapMutations } from 'vuex';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import ConstantValues from '@/plugins/constantValues';
import LoadMoreDropdown from "@/components/shared/load-more-jobs-dropdown.vue";

export default {
    name: "TeamJobsProducts",
    mixins: [TeamJobsComputed, TeamJobsCommon],
    data(){
       return{
        searchTimer: null
       }
    },
    components: {
        LoadMoreDropdown
    },
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
        }),
        applySearchJobProductTimeout(e) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                this.searchProducts();
            }, ConstantValues.defaultSearchTimeout);
        },
        refeatchProductsList(){
            this.hasMoreData = true;
        },
        searchProducts() {
            this.teamJobProductsList = [];
            this.clearFilterOptions();
            this.$store.dispatch('setTeamJobProductsSearchText').then(res => {
                    this.$store.dispatch('getTeamJobProducts');
                    if (this.teamJobProductsSearchText == '') {
                        this.clearFilterOptions();
                    }
             });
        },
        removeProductFromProductsList(productIndex) {
            this.teamJobProducts = this.teamJobProducts.filter((prd, index) => index !== productIndex)
              this.enableTeamJobDiscard();
        },
        productExistsInProductsList() {
            return listContainsItem(this.teamJobProducts, [ConstantValues.defaultObjectIdentifier], this.teamJobProductToAdd.id)
        },
        addProductToProductsList() {
            if (this.teamJobProductToAdd) {
                this.toggleProductSelectionDropdown();
                this.teamJobProductsLoader = true;
                setTimeout(() => {
                    if (!this.productExistsInProductsList()) {
                        this.teamJobProducts.push(new TeamJobProduct().getTeamJobProduct(this.teamJobProductToAdd));
                        this.enableTeamJobDiscard();
                    }
                    const element: HTMLElement = document.querySelector('#productSelectionMenu .mdi-close') as HTMLElement;
                    element.click();
                    this.teamJobProductToAdd = new TeamJobProduct();
                    this.teamJobProductsSearchText = null;
                    this.teamJobProductsLoader = false;
                }, 200)
            }
        },
        onChangeProductValue(){
            this.enableTeamJobDiscard();
        },
        toggleProductSelectionDropdown() {
            this.teamJobShowJobAddProductsExpandedView = !this.teamJobShowJobAddProductsExpandedView;
            this.teamJobShowJobAddProductsDropdown = !this.teamJobShowJobAddProductsDropdown;
        },
        toggleDropdownAndOpenSelection() {
            if(this.teamJobViewPreference.products.isEditable) {
                this.toggleProductSelectionDropdown();
                setTimeout(() => {
                    const dropdownElement = document.getElementById('productSelectionDropdownMenu');
                    if (dropdownElement) dropdownElement.click();
                }, 100);
            }
        },
        appendMoreProducts(){
            this.clearFilterOptions();
            this.$store.dispatch('setTeamJobProductsSearchText').then(res => {
                   this.$store.dispatch('getTeamJobProducts');
            });
        },
        isProductPresentInSelectedProductList(product){
          return listContainsItem(this.teamJobProducts, [ConstantValues.defaultObjectIdentifier], product.id);
        }    
    }
}
