

















import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
export default {
    name: 'MapDialogBox',
    props: [
        'item',
        'btnText',
    ],
    components: {
        SecondaryButton
    },
    computed: {
        itemToView: {
            get() {
                return this.item
            },
            set(newVal) {
                this.$emit('change', newVal)
            }
        }
    }
}
