import ConstantValues from '@/plugins/constantValues';
import $ from 'jquery';

export const actions = {
    updateStates({state, commit}, response) {
        commit('resetError', false);
        try {
            if (response.data) {
                commit('setTotalResponseRowCount', response.data.value.length);
                commit('setResponseRowCount', response.data.value.length);
                if (response.data.value.length > 0) {
                    commit('setTotalRowCount', response.data.size);
                }
                if (state.offset == 0) {
                    commit('setNoDataStatus', response.data.value);
                } else {  
                    throw ('End of list!')
                }
                commit(`setOffset`, response.data.offset);
            } else {
                throw ('Network Error!')
            }
        } catch(error) {
            commit('apiCallError', {error, response})
        } finally {
            commit('doneFetchingData')
        }
        
    },

    async generateQueryString({dispatch, state}) {
        const filterOptions = await dispatch('getFilterOptions');
        await dispatch('jsonToQueryString', filterOptions);
        const queryString = await dispatch('getQueryString');
        return queryString;
    },

    async setSearchFilters({ state, commit }, {offset, limit, searchText, keepSearchText}) {
        commit('setClearFilterOptions');
        commit('setFilterOptions', [ConstantValues.searchTextQuery, keepSearchText ? searchText : '']);
        commit('setFilterOptions', [ConstantValues.offsetQuery, offset]);
        commit('setFilterOptions', [ConstantValues.limitQuery, limit]);
    },

    async jsonToQueryString({ commit }, obj) {
        let paramsUrl = "?";
        $(obj).each(function () {
            paramsUrl = paramsUrl + encodeURIComponent(this[0]) + "=" + encodeURIComponent(this[1]) + "&";
        });
        paramsUrl = paramsUrl.substring(0, paramsUrl.length - 1);
        commit("setQueryString", paramsUrl);
    },

    async getQueryString({state}) {
        return state.queryString;
    },

    async getFilterOptions({ state }) {
        return state.filterOptions;
    },
}