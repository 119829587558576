import {getComputedProperties} from '@/utils/helpers/computed-generator';

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule' : [
        'teamJobOperators',
        'teamJobVehiclesListForOperators',
        'teamJobImplementsListForOperators',
        'teamJobVehiclesSearchInputListForOperators',
        'teamJobImplementsSearchInputListForOperators',
        'teamJobShowJobAddOperatorExpandedView',
        'teamJobShowOperatorSelectionDropdown',
        'teamJobOperatorToAdd',
        'teamJobOperatorToAddLoader',
        'teamJobTeamMembersForOperatorsSeachText',
        'teamJobsTeamMembersListForOperators',
        'teamJobVehiclesSearchTextForOperators',
        'teamJobImplementsSearchTextForOperators',
        'teamJobVehiclesListForOperatorsSize',
        'teamJobImplementsListForOperatorsSize',
        'teamJobVehiclesListForOperatorsLoader',
        'teamJobImplementsListForOperatorsLoader',
    ],
};

export default {
    name: 'TeamJobsOperatorsComputedMixin',
    computed: getComputedProperties(computedProperties),
}