
































































































import { validateRegexAlphaNumeric } from "@/utils/helpers";
import InvoicesComputedMixin from "@/components/invoices/invoices-computed";

export default {
  name: "BusinessProfileForInvoiceAdd",
  mixins: [InvoicesComputedMixin],
  mounted() {
    this.$store.dispatch(
      "getBusinessProfileForInvoice",
      this.businessInfoUserId
    );
  },
  methods: {
    checkKeyDownAlphaNumeric(event) {
      validateRegexAlphaNumeric(event);
    },
  },
};
