import ConstantValues from '@/plugins/constantValues';
export default {
    computed: {
        permissionsList: function () {
            return this.$store.getters.permissionsList;
        },
        componentsPermissions() {
            return this.$store.getters.componentsPermissions;
        }
    },
    methods: {
        shouldComponentDisplay(componentPermissionName: string) {
            if (this.permissionsList.includes(ConstantValues.accessAllPermission)) {
                return true;
            }
            if (this.permissionsList.includes(componentPermissionName))
                return true;
            else
                return false;
        }
    }
}