















import subscriptionsComputedMixin from '@/components/subscriptions/subscriptions-computed';
import { DeleteItemDialog as SetDefaultCardDialog } from '@/components/shared/dialogs/index';

export default {
  components: {
    SetDefaultCardDialog
  },
  mixins: [subscriptionsComputedMixin],
  methods: {
    confirmDefaultCardChange() {
      this.showDefaultCardConfirmDialog = false;
      this.$store.dispatch("setDefaultCard", this.cardIdToSelect);
    },
    cancelDefaultCardChange() {
      this.showDefaultCardConfirmDialog = false;
    }
  }
};
