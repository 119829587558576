






































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsGenericInformation from '@/components/team-jobs/common/team-jobs-generic-information/team-jobs-generic-information.vue';
import TeamJobsLocationMap from '@/components/team-jobs/common/team-jobs-location-map.vue';
import TeamJobsOperators from '@/components/team-jobs/common/team-jobs-operators.vue';
import TeamJobsFields from '@/components/team-jobs/common/team-jobs-fields.vue';
import TeamJobsMetadata from '@/components/team-jobs/common/team-jobs-metadata.vue';
import TeamJobsBillingInformation from '@/components/team-jobs/common/team-jobs-billing.vue';
import TeamJobsProducts from '@/components/team-jobs/common/team-jobs-products.vue';
import TeamJobsTimeDetails from '@/components/team-jobs/common/team-jobs-time-details.vue';
import TeamJobsLocations from '@/components/team-jobs/common/team-jobs-addresses.vue';
import TeamJobsCompletionInformation from '@/components/team-jobs/common/team-jobs-completion-information.vue';
import ImageViewer from "@/components/shared/image-viewer.vue";

export default {
    mixins: [TeamJobsComputed],
    components: {
        TeamJobsGenericInformation,
        TeamJobsOperators,
        TeamJobsFields,
        TeamJobsMetadata,
        TeamJobsBillingInformation,
        TeamJobsProducts,
        TeamJobsTimeDetails,
        TeamJobsLocations,
        TeamJobsCompletionInformation,
        TeamJobsLocationMap,
        ImageViewer,
    }
}
