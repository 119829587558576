





export default {
    name: "Drawer",
    props: [
        "closeCallback"
    ],
    data() {
        return {
            previousDashboardTitle: null
        }
    },
    computed: {
        drawerIsOpen() {
            return this.$store.state.drawerOpen;
        }
    },
    watch: {
        drawerIsOpen() {
            if (!this.drawerIsOpen && this.drawerIsOpen === null) {
                this.closeCallback();
            }
            this.$store.commit("setCollapsedNavigation", this.drawerIsOpen);
        }
    },
    mounted() {
        this.previousDashboardTitle = this.$store.state.dashboardTitle
    },
    methods: {
        setDashboardTitleToPrevious() {
            this.$store.commit("setDashboardTitle", this.previousDashboardTitle);
        }
    }
}
