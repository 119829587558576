


















import { closeOverlay } from "@/utils/helpers";
import ImportData from "@/components/shared/import/import-csv-list.vue";
import { mapMutations } from "vuex";
import productsComputedMixin from "@/components/products/products-computed";
import { ImportResourceType } from "@/enum/importResourceType";

const productInfo = {
    costField: JSON.parse('"cost*"'),
    titleField: JSON.parse('"name*"'),
    unitField: JSON.parse('"unit*"'),
}

let productObj = {}

productObj[productInfo.titleField] = '';
productObj[productInfo.costField] = '';
productObj[productInfo.unitField] = '';

export default {
    name: "ProductsImportForm",
    mixins: [productsComputedMixin],
    components: {
        ImportData,
    },
    data() {
        return {
            productsSampleData: [
                productObj
            ],
            requiredImportFields: [
                "name",
                "cost",
                "unit",
            ],
            uploadNotes: [
                this.$stringConstants('uploadNotesDeleteColumnWarning'),
                this.$stringConstants('uploadNotesCommaWarning'),
                'All columns are required.'
            ],
            importResourceType: ImportResourceType.Products
        };
    },
    mounted() {
        this.$store.dispatch('getProductsUnits').then(res => {
            if(res?.data) {
                const units = res.data.join(', ')
                this.uploadNotes.push(`Supported units are: ${units}`)
            }
        })
    },
    methods: {
        ...mapMutations({
            clearFarmerFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
            resetImportForm: "resetImportDataDetails"
        }),
        closeImportProductsForm() {
            closeOverlay("importProductsFormOverlay");
        },
        refreshProductsList() {
            this.resetPaging()
            this.$store.commit('setClearFilterOptions')
            this.productsList = []
            this.$store.dispatch('getProductsListFiltered')
        }
    },
};
