import { InvoiceStatusType } from "@/enum/invoiceStatusType";
import { JobAccessLevelType } from "@/enum/jobAccessLevelType";
import { ResourceType } from "@/enum/resourceType";
import { SubscriptionStatusType } from "@/enum/subscriptionStatusType";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import { devLogger, getConvertedAreaUnit } from "@/utils/helpers";
import moment from "moment";
import { getDateTimeFormat, getDateTimeFormattedLocal } from '@/utils/helpers';
import { getCancellationDate, getSubscriptionStatusEnum, getSusbcriptionAmountIfExists, getTrialDaysLeft } from "@/utils/helpers/subscription-helper";

const exportDataSeparator = ", ";
const exportDataDateTimeFormat = "DD MMM YYYY hh:mm A";
const exportDataWithEmptySpaces = true;
const newLine = "\n";

const initialState = () => {
  return {
    exportDataOffset: 0,
    exportDataTotal: 0,
    exportDataLoaded: 0,
    exportData: [],
    exportDataLength: 0,
    exportDataTextFilters: "",
  };
};

function getAddressFormatted(address) {
  if (
    address.addressLine1 ||
    address.addressLine2 ||
    address.city ||
    address.state ||
    address.country ||
    address.postalCode
  ) {
    return (
      (address.addressLine1 ? address.addressLine1 + exportDataSeparator : "") +
      (address.addressLine2 ? address.addressLine2 + exportDataSeparator : "") +
      (address.city ? address.city + exportDataSeparator : "") +
      (address.state ? address.state + exportDataSeparator : "") +
      (address.country ? address.country + " - " : "") +
      (address.postalCode ? address.postalCode : "")
    );
  } else {
    if (address.coordinate) {
      return `Dropped Pin: (${address.coordinate.latitude}, ${address.coordinate.longitude})`;
    }
  }
}

function getLineItemFormatted(lineItem) {
  return `${lineItem.title}`;
}

function getDateTime(dateTime) {
  return dateTime
    ? moment.utc(dateTime).local().format(exportDataDateTimeFormat)
    : "";
}

function getCurrencyFormatted(currency) {
  return `${currency.id}`;
}

function showIfNotRepeated(key, value, invoiceId, items = state.exportData) {
  if (exportDataWithEmptySpaces) {
    if (
      items.find((item) => item[key] == value) &&
      items.find((item) => item.id === invoiceId)
    ) {
      return "";
    }
  }
  return value;
}

function getLineItemTaxRate(taxAmounts) {
  return taxAmounts?.[0]?.salesTaxRateInPercentage || 0;
}

function getLineItemTaxAmount(taxAmounts) {
  return taxAmounts?.[0]?.amount || 0;
}

export const state = initialState();

export const getters = {
  exportDataOffset: (state) => state.exportDataOffset,
  exportDataTotal: (state) => state.exportDataTotal,
  exportDataLoaded: (state) => state.exportDataLoaded,
  exportData: (state) => state.exportData,
  exportDataLength: (state) => state.exportDataLength,
  exportDataTextFilters: (state) => state.exportDataTextFilters,
};

export const mutations = {
  setInvoicesDataListForExport(state, invoicesData) {
    invoicesData.forEach((inv) => {
      state.exportDataLength++;

      inv.references.forEach((jobReference) => {
        if (inv?.lineItems.length) {
          const lineItems = inv.lineItems.filter((lineItem) =>
            jobReference.includes(lineItem.reference)
          );

          if (lineItems?.length) {
            lineItems.forEach((lineItem) => {
              const obj = {
                id: inv.id,
                invoice_number: showIfNotRepeated(
                  "invoice_number",
                  inv.number,
                  inv.id
                ),
                status: showIfNotRepeated(
                  "status",
                  InvoiceStatusType[inv.status],
                  inv.id
                ),
                customer_name: showIfNotRepeated(
                  "customer_name",
                  inv.customer.name,
                  inv.id
                ),
                customer_address: showIfNotRepeated(
                  "customer_address",
                  getAddressFormatted(inv.customer.address),
                  inv.id
                ),
                email_address: showIfNotRepeated(
                  "email_address",
                  inv.customer.emailAddress,
                  inv.id
                ),
                phone_number: showIfNotRepeated(
                  "phone_number",
                  inv.customer.phoneNumber,
                  inv.id
                ),
                reference_number: showIfNotRepeated(
                  "reference_number",
                  lineItem.reference,
                  inv.id
                ),
                line_item_date: getDateTime(lineItem.date),
                line_item: getLineItemFormatted(lineItem),
                line_item_rate: lineItem.rate,
                line_item_quantity: lineItem.quantity,
                line_item_unit: lineItem.unit,
                line_item_amount: lineItem.amount,
                line_item_tax_rate: getLineItemTaxRate(lineItem.taxAmounts),
                line_item_tax_amount: getLineItemTaxAmount(lineItem.taxAmounts),
                invoice_due_date: showIfNotRepeated(
                  "invoice_due_date",
                  getDateTime(inv.dueDate),
                  inv.id
                ),
                invoice_subtotal: showIfNotRepeated("invoice_subtotal", inv.subTotal, inv.id),
                invoice_tax: showIfNotRepeated("invoice_tax", inv.tax, inv.id),
                invoice_total: showIfNotRepeated("invoice_total", inv.total, inv.id),
                invoice_currency: showIfNotRepeated(
                  "invoice_currency",
                  getCurrencyFormatted(inv.currency),
                  inv.id
                ),
              };
              state.exportData.push(obj);
            });
          }
        }
      });
    });

    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },

  setJobsDataListForExport(state, jobsData) {
    const jobsDataSeparator = ", ";
    const jobsDataDateTimeFormat = "DD MMM YYYY hh:mm A";
    const jobsDataTrue = "TRUE";
    const jobsDataFalse = "FALSE";
    const newLine = "\n";

    function getUserName(customer) {
      return (
        (customer.businessName ? customer.businessName : customer.name) || ""
      );
    }

    function getDateTime(dateTime) {
      return dateTime
        ? moment.utc(dateTime).local().format(jobsDataDateTimeFormat)
        : "";
    }

    function getTimeDurationInMinutesWithActivityType(timeRecords, timeType) {
      let timeInSeconds = 0;
      if (timeRecords && timeRecords.length) {
        timeRecords.forEach((t) => {
          timeInSeconds += t[timeType];
        });
      }
      return (timeInSeconds / 60).toFixed(3);
    }

    function getTimeDurationInMinutes(timeInSeconds = 0) {
      return (timeInSeconds / 60).toFixed(3);
    }

    function getAddressFormatted(address) {
      if (
        address.addressLine1 ||
        address.addressLine2 ||
        address.city ||
        address.state ||
        address.country ||
        address.postalCode
      ) {
        return (
          (address.addressLine1
            ? address.addressLine1 + jobsDataSeparator
            : "") +
          (address.addressLine2
            ? address.addressLine2 + jobsDataSeparator
            : "") +
          (address.city ? address.city + jobsDataSeparator : "") +
          (address.state ? address.state + jobsDataSeparator : "") +
          (address.country ? address.country + " - " : "") +
          (address.postalCode ? address.postalCode : "")
        );
      } else {
        if (address.coordinate) {
          return `Dropped Pin: (${address.coordinate.latitude}, ${address.coordinate.longitude})`;
        }
      }
    }

    function getAddresses(addresses) {
      const tempAddresses = [];
      if (addresses && addresses.length) {
        addresses.forEach((a, i) => {
          const addressString = getAddressFormatted(a);
          tempAddresses.push(`ADDRESS ${i + 1}: ${addressString}`);
        });
        return tempAddresses;
      }
    }

    function getBillingQuantity(operation) {
      if (
        operation &&
        operation.billingQuantities &&
        operation.billingQuantities.length
      ) {
        return operation.billingQuantities[0].billingQuantity;
      }
      return "";
    }

    function getBillingUnit(operation) {
      if (operation && operation.billingUnit) {
        return operation.billingUnit;
      }
      return "";
    }

    function getMetaData(metaData) {
      const tempMetaData = [];
      if (metaData && metaData.length) {
        metaData.forEach((m) => {
          const metaDataString = `${m.title}: ${m.value ? m.value : 0} ${
            m.unit ? m.unit : ""
          }`;
          tempMetaData.push(metaDataString);
        });
      }
      return tempMetaData;
    }

    function getOperators(operatorsList) {
      function getOperatorName(operator, operatorIndex) {
        const teamLeadString =
          operator.level == JobAccessLevelType.Primary
            ? "Team Lead"
            : "Operator";
        const operatorNameString = `OPERATOR ${operatorIndex + 1}: ${
          operator.name
            ? operator.name
            : operator.businessName
            ? operator.businessName
            : ""
        } 
                                      (${teamLeadString})`;
        return operatorNameString;
      }

      function getOperatorMachine(operator, machineType: ResourceType) {
        let machineString = "None";

        switch (machineType) {
          case ResourceType.Vehicle:
            if (operator && operator.vehicle) {
              machineString = `${operator.vehicle.title} (${operator.vehicle.make}, ${operator.vehicle.model})`;
            }
            break;
          case ResourceType.Implement:
            if (operator && operator.implement) {
              machineString = `${operator.implement.title} (${operator.implement.make}, ${operator.implement.model})`;
            }
            break;
          default:
            devLogger().log("No machine assigned!");
        }

        return machineString;
      }

      const tempOperatorNames = [];
      if (operatorsList && operatorsList.length) {
        operatorsList.forEach((o, i) => {
          const operatorName = getOperatorName(o, i);
          const operatorVehicle = getOperatorMachine(o, ResourceType.Vehicle);
          const operatorImplement = getOperatorMachine(
            o,
            ResourceType.Implement
          );
          const operatorString = `${operatorName}, Vehicle: ${operatorVehicle}, Implement: ${operatorImplement} ${newLine}`;
          tempOperatorNames.push(operatorString);
        });
        return tempOperatorNames;
      }
    }

    function getFields(fieldsList) {
      const tempFields = [];
      if (fieldsList && fieldsList.length) {
        fieldsList.forEach((f) => {
          const fieldString = `${f.title} (${getConvertedAreaUnit(
            f.areaInSquareMeter
          )})`;
          tempFields.push(fieldString);
        });
      }
      return tempFields;
    }

    function getProducts(products) {
      const tempProducts = [];
      if (products && products.length) {
        products.forEach((p) => {
          const productString = `${p.title ? p.title : ""} - ${
            p.usages && p.usages.length ? p.usages[0].usage : ""
          } ${p.unit ? p.unit : ""}`;
          tempProducts.push(productString);
        });
      }
      return tempProducts;
    }

    function getTimeRecords(timeRecords) {
      const timeRecordsList = [];
      if (timeRecords && timeRecords.length) {
        timeRecords.forEach((t, i) => {
          const startTime = getDateTime(t.startedAt);
          const endTime = getDateTime(t.endedAt);
          const travelTime = getTimeDurationInMinutes(t.travelTimeInSeconds);
          const workTime = getTimeDurationInMinutes(t.workTimeInSeconds);
          const breakTime = getTimeDurationInMinutes(t.breakTimeInSeconds);
          const downTime = getTimeDurationInMinutes(t.downTimeInSeconds);
          const timeRecordString = `RECORD ${
            i + 1
          } Start time: ${startTime}, End time: ${endTime}, Travel time: ${travelTime}, Work time: ${workTime}, Break time: ${breakTime}, Down time: ${downTime}`;
          timeRecordsList.push(timeRecordString + "\n\t");
        });
      }

      return timeRecordsList;
    }

    jobsData.forEach((j) => {
      state.exportDataLength++;

      const reportObj = {
        number: j.number,
        customer: getUserName(j.customer),
        contractor: getUserName(j.contractor),
        scheduled_on: getDateTime(j.scheduledAt),
        created_on: getDateTime(j.createdOn),
        status: TeamJobStatusType[j.status],
        started_on: getDateTime(j.workStartedOn),
        completed_on: getDateTime(j.workCompletedOn),
        work_time_in_mins: getTimeDurationInMinutesWithActivityType(
          j.timeRecords,
          "workTimeInSeconds"
        ),
        travel_time_in_mins: getTimeDurationInMinutesWithActivityType(
          j.timeRecords,
          "travelTimeInSeconds"
        ),
        break_time_in_mins: getTimeDurationInMinutesWithActivityType(
          j.timeRecords,
          "breakTimeInSeconds"
        ),
        down_time_in_mins: getTimeDurationInMinutesWithActivityType(
          j.timeRecords,
          "downTimeInSeconds"
        ),
        operation: j.operation.title,
        addresses:
          j.addresses && j.addresses.length
            ? getAddresses(j.addresses).join(jobsDataSeparator)
            : "",
        notes: j.notes ? j.notes : "",
        billable_quantity: getBillingQuantity(j.operation),
        billing_unit: getBillingUnit(j.operation),
        extra_information:
          j.metaData && j.metaData.length
            ? getMetaData(j.metaData).join(jobsDataSeparator)
            : "",
        fields:
          j.fields && j.fields.length
            ? getFields(j.fields).join(jobsDataSeparator)
            : "",
        products:
          j.products && j.products.length
            ? getProducts(j.products).join(jobsDataSeparator)
            : "",
        operators:
          j.operators && j.operators.length
            ? getOperators(j.operators).join(jobsDataSeparator)
            : "",
        time_records:
          j.timeRecords && j.timeRecords.length
            ? getTimeRecords(j.timeRecords).join(jobsDataSeparator)
            : "",
        invoiced:
          j.invoiceNumber || j.invoiceUrl || j.isInvoiced
            ? jobsDataTrue
            : jobsDataFalse,
        approved_for_invoicing: j.isApprovedForInvoicing
          ? jobsDataTrue
          : jobsDataFalse,
      };

      state.exportData.push(reportObj);
    });

    state.exportDataLoaded = state.exportData.length;
    state.exportDataOffset = state.exportDataLoaded;
  },

  removeIdsFromExportableData(state) {
    const tempExportData = [];

    state.exportData.forEach((ed) => {
      const edObj = ed;
      delete edObj.id;
      tempExportData.push(edObj);
    });

    state.exportData = tempExportData;
  },

  setUsersDataListForExport(state, usersData) {
    usersData.forEach((jb) => {
      state.exportDataLength++;

      const obj = {
        name: jb.name,
        business_name: jb.businessName,
        email_address: jb.emailAddress,
        phone_number: jb.primaryPhoneNumber,
        onboarded_on: getDateTime(jb.createdOn),
        role: jb.roles.map(role => (role + '')).join(','),
        status: jb.isArchived ? 'Inactive' : 'Active',
        last_active: getDateTime(jb.lastActiveOn)
      }

      state.exportData.push(obj);
    })

    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },

  setBusinessesDataListForExport(state, businessesData) {

    function getSubscriptionText(business) {
      let subscriptionText = ''
      const unavailableText = 'NA'

      if(business.subscriptions.status !== null) {
        if(!business.subscriptions.cancellationScheduledOn) {

            if(business.subscriptions.status == SubscriptionStatusType.Trialing) {
              subscriptionText += getTrialDaysLeft(business.subscriptions.trialEnd)
            } else {
              if(business.subscriptions.status == SubscriptionStatusType.Active) {
                subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status) + ' '
                subscriptionText += business.subscriptions.currentPeriodStart ? getDateTimeFormat(business.subscriptions.currentPeriodStart) : unavailableText
                subscriptionText += ' - '
                subscriptionText += business.subscriptions.currentPeriodEnd ? getDateTimeFormat(business.subscriptions.currentPeriodEnd) : unavailableText
              } else if (business.subscriptions.status == SubscriptionStatusType.Cancelled) {
                subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status)
                subscriptionText += ' '
                subscriptionText += getDateTimeFormattedLocal(business.subscriptions.cancelledAt)
              } else {
                subscriptionText += getSubscriptionStatusEnum(business.subscriptions.status)
              }
            }

            if(business.subscriptions.status == SubscriptionStatusType.Trialing) {
              subscriptionText += ' '
              subscriptionText += business.subscriptions.trialStart ? getDateTimeFormat(business.subscriptions.trialStart) : unavailableText
              subscriptionText += ' - '
              subscriptionText += business.subscriptions.trialEnd ? getDateTimeFormat(business.subscriptions.trialEnd) : unavailableText
            }
        }
        subscriptionText += getCancellationDate(business.subscriptions)
      } else {
        if(business.subscriptions.trialStart || business.subscriptions.trialEnd) {
          subscriptionText += getTrialDaysLeft(business.subscriptions.trialEnd) 
          subscriptionText += ' '
          subscriptionText += business.subscriptions.trialStart ? getDateTimeFormat(business.subscriptions.trialStart) : unavailableText
          subscriptionText += ' - '
          subscriptionText += business.subscriptions.trialEnd ? getDateTimeFormat(business.subscriptions.trialEnd) : unavailableText
        }
      }

      return subscriptionText
    }

    businessesData.forEach((business) => {
      state.exportDataLength++;

      const obj = {
        business_name: business.name,
        country: business.country,
        owner_name: business.ownerName,
        owner_contact: business.ownerContact,
        owner_type: business.businessType,
        onboarded_on: getDateTime(business.createdOn),
        jobs: business.totalJobs,
        active_members: `(${business.teamMembers.active}/${business.teamMembers.total})`,
        subscriptions: getSubscriptionText(business),
        amount: getSusbcriptionAmountIfExists(business, false)
      }

      state.exportData.push(obj);
    })

    state.exportDataLoaded = state.exportDataLength;
    state.exportDataOffset = state.exportDataLoaded;
  },

  resetExportData(state) {
    Object.assign(state, initialState());
  },
};
