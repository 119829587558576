

































































































import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';

export default {
    name: 'ContactCompanyAddressForm',
    mixins: [contactComputedMixin],
    components: {
        SectionLeftHeadings
    }
}
