





















































































































































































































import { closeOverlay, checkFieldsValidity } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import moment from 'moment';
import timesheetsComputedMixin from '@/components/timesheets/timesheets-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import { TimeSheetType } from '@/enum/timeSheetType';
import ConstantValues from '@/plugins/constantValues';
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";

export default {
    name: "TimeSheetAddListView",
    data() {
        return {
            timeMemberId: localStorage.getItem(ConstantValues.ownerId),
            menuDate: false,
            breakMin: 0,
            breakHours: 0,
            travelMin: 0,
            travelHours: 0,
            timeSheetType: TimeSheetType.Working,
            timeSheetTypeTitle: null,
            timeNote: null,
            timeSheetDate: null,
            showTimeSheetFieldByType: true,
            TimeSheetType,
            searchTimerMemberAddFilter: null
        }
    },
    components: {
        HeaderPrimary,
        Button,
        VueCtkDateTimePicker
    },
    mixins: [timesheetsComputedMixin],
    computed: {
        isDisabledBtn() {
            if (this.showTimeSheetFieldByType != true) {
                if (checkFieldsValidity(
                    [this.timeMemberId, this.timeSheetDate
                    ])) {
                    return false
                } else {
                    return true
                }
            } else {
                if (checkFieldsValidity(
                    [this.timeMemberId, this.timeSheetDate, this.timeStart, this.timeEnd
                    ])) {
                    return false
                } else {
                    return true
                }
            }
        },
        computedDateFormatted() {
            return this.timeSheetDate ? moment(this.timeSheetDate).format('DD MMM YYYY') : ''
        },
    },
    methods: {
        ...mapMutations({
            clearTimeSheetFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),
        closeTimeSheetAddForm: function () {
            this.resetAddTimeSheetForm();
            closeOverlay("addTimeSheetFormOverlay");
        },
        applyMembersFilterAddSearchTimer() {
            if (this.searchTimerMemberAddFilter) {
                clearTimeout(this.searchTimerMemberAddFilter);
                this.searchTimerMemberAddFilter = null;
            }
            this.searchTimerMemberAddFilter = setTimeout(() => {
                this.searchTeamMembersFilter();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        searchTeamMembersFilter: function () {
            this.clearTimeSheetFilterOption();
            this.$store.dispatch('setSearchTextForTimesheetMembers').then(res => {
                this.$store.dispatch('getTimesheetMembersList');
                if (this.timesheetMembersSearchText == '') {
                    this.clearTimeSheetFilterOption();
                }
            });
        },
        addTimeSheet: function () {
            let startTimeConverted = '';
            let endTimeConverted = '';
            let timeSheetEndDate = this.getTimesheetEndDate(this.timeSheetDate, this.timeStart, this.timeEnd);
            
            startTimeConverted = moment.utc(moment(this.timeSheetDate + ' ' + this.timeStart).utc()).format();
            endTimeConverted = moment.utc(moment(timeSheetEndDate + ' ' + this.timeEnd).utc()).format();
           
            const timeSheetDto = {
                userId: this.timeMemberId,
                type: this.timeSheetType,
                startTime: startTimeConverted,
                endTime: endTimeConverted,
                travelTimeInMinutes: this.travelHours * 60 + this.travelMin,
                breakTimeInMinutes: this.breakHours * 60 + this.breakMin,
                note: this.timeNote
            }
            this.$store.dispatch('addNewTimesheet', timeSheetDto).then((res) => {
                if (res != undefined) {
                    this.clearTimeSheetFilterOption();
                    this.resetPaging();
                    this.resetAddTimeSheetForm();
                    this.setFilterOptionsAndGetTimesheets();
                }
            });
        },
        setFilterOptionsAndGetTimesheets(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            this.$store.dispatch('setTimesheetsFilter', filters).then(() => {
                this.$store.dispatch('getTimesheetsList').then(() => {
                    this.timesheetsFilterLoader = false;
                });
                this.$store.dispatch('getTimesheetsStatistics');
                if (this.timesheetsSearchText == '') {
                    this.clearTimeSheetFilterOption();
                    this.filtersApplied = false;
                }
            });
        },
        resetAddTimeSheetForm: function () {
            this.timeMemberId = localStorage.getItem(ConstantValues.ownerId);
            this.timeStart = null;
            this.timeEnd = null;
            this.menuDate = false;
            this.breakMin = 0;
            this.breakHours = 0;
            this.travelMin = 0;
            this.travelHours = 0;
            this.timeSheetType = TimeSheetType.Working;
            this.timeNote = null;
            this.timeSheetDate = null;
            this.showTimeSheetFieldByType = true;
        },
        getItemText: function (title) {
            this.timeSheetTypeTitle = title;
        },
        checkTimeSheetType: function (type) {
            if (type == TimeSheetType.Holiday || type == TimeSheetType.Sick) {
                this.showTimeSheetFieldByType = false;
                this.breakMin = 0;
                this.breakHours = 0;
                this.travelMin = 0;
                this.travelHours = 0;
            }
            else {
                this.showTimeSheetFieldByType = true;
            }
        },
        proceeedToGetMemberTime: function (id) {
            if (id != undefined) {
                this.$store.dispatch('getTimesheetMembersWorkTime', id);
            }
        }
    }
}
