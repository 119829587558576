































export default {
  name: 'BaseFlyout',
  props: ['value', 'noOverlay', 'persistent', 'flyoutClass', 'width'],
  methods: {
    handleOutsideClick(event: MouseEvent | KeyboardEvent) {
      if (this.persistent) return;
      const targetElement: HTMLElement | null = event.target as HTMLElement;
      if (!targetElement?.classList.contains('flyout-overlay')) return;
      if (this.$listeners['confirm-dismiss']) {
        this.$emit('confirm-dismiss');
      } else {
        this.$emit('input', false);
      }
    }
  }
}
