



































































































































































































import TeamJobsComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-computed";
import { ButtonActionPrimary, HoverTextButton } from "@/components/shared/buttons/index";
import { routeInvoicesDetails, routeJobs } from "@/utils/endpoints";
import {
    getLoggedInUserRole,
    getOwnerId,
} from "@/utils/helpers/user-role-helpers";
import { getOperatorsWithChecklists } from "@/utils/helpers/jobs-helpers";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import router from "@/router/index";
import moment from "moment";
import { TeamJobTimeDetails } from "../../../data/models/TeamJobTimeDetails";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { ApprovalStatusType } from "@/enum/approvalStatusType";
import { DeleteItemDialog } from "@/components/shared/dialogs/index";
import ScrollerMixin from '@/mixins/scroller-mixin';
import { UserRoleType } from "@/enum/userRoleType";
import $ from 'jquery';
import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';

export default {
    name: "TeamJobsAdd",
    mixins: [TeamJobsComputed, TeamJobsCommon, ScrollerMixin],
    components: {
        ButtonActionPrimary,
        DeleteItemDialog,
        HoverTextButton,
        SecondaryButton
    },
    data() {
        return {
            dialogDeclineTeamJob: false,
            declineTeamJobReason: "",
            TeamJobStatusType,
            ApprovalStatusType,
            dialogForceFinish: false,
            UserRoleType
        };
    },
    computed: {
        canCompleteJob: {
            get() {
                if (this.teamJobUser) {
                    const contractorOrItsManagerLoggedIn = getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn;
                    const farmerOrItsManagerLoggedIn = getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn;
                    const createdForSelf = getOwnerId() == this.teamJobUser.id;
                    return (contractorOrItsManagerLoggedIn || (farmerOrItsManagerLoggedIn && createdForSelf));
                }
                return false;
            }
        }
    },
    beforeDestroy() {
        this.routedFromJobDetails = true;
    },
    methods: {
        acceptTeamJob() {
            let resourceId = this.teamJobDetails.id;
            this.$store
                .dispatch("acceptTeamJobByContractor", resourceId)
                .then((res) => {
                    if (res) {
                        this.resetTeamJobs();
                        this.mountTeamJobsDetails();
                    }
                });
        },
        openDialogJobDecline() {
            this.dialogDeclineTeamJob = true;
        },
        closeJobDecline() {
            this.declineTeamJobReason = "";
            this.dialogDeclineTeamJob = false;
        },
        proceedToDeclineTeamJob() {
            let obj = {
                resourceId: this.teamJobDetails.id,
                reason: {
                    reason: this.declineTeamJobReason,
                },
            };
            this.$store.dispatch("declineTeamJobByContractor", obj).then(() => {
                    this.closeJobDecline();
                    this.$router.back();
            });
        },
        openDialogToForceFinishTeamJob() {
            this.dialogForceFinish = true;
        },
        isVisibleForceFinish() {
            if(this.teamJobDetails && this.teamJobDetails.status === TeamJobStatusType.InProgress) {
                if (this.teamJobOperators.length > 0) {
                    const hasWorked = this.teamJobOperators.some(o => o.operatorDetails.workStatus !== null);
                    return hasWorked;
                }
            }
        },
        proceedToForceFinishTeamJob() {
            let resourceId = this.teamJobDetails.id;
            this.$store.dispatch("forceFinishTeamJob", resourceId).then((res) => {
                if (res) {
                    this.resetTeamJobs();
                    this.mountTeamJobsDetails();
                }
            });
            this.dialogForceFinish = false;
        },
        getOperators() {
            return getOperatorsWithChecklists(
                this.teamJobOperation,
                this.teamJobOperators
            );
        },
        navigateToPreviousRoute() {
            const jobsList = this.$store.state.teamJobsListModule.teamJobsList;
            const jobIndex = jobsList.findIndex(job => job.id === this.teamJobDetails.id);
            if (jobIndex > -1) {
                this.$router.back();
            } else {
                this.$router.push(routeJobs);
            }
        },
        updateInProgressJob(actionType) {
            const timeRecords = [];
            let custId = null;
            if (
                getLoggedInUserRole().isFarmerLoggedIn ||
                getLoggedInUserRole().isFarmersManagerLoggedIn
            ) {
                custId = getOwnerId();
                if (this.teamJobUser) {
                    this.teamJobContractorId = this.teamJobUser.id;
                } else {
                    this.teamJobContractorId = getOwnerId();
                }
            } else if (
                getLoggedInUserRole().isContractorLoggedIn ||
                getLoggedInUserRole().isContractorsManagerLoggedIn
            ) {
                this.teamJobContractorId = getOwnerId();
                custId = this.teamJobUser ? this.teamJobUser.id : getOwnerId();
            }
            const operatorsForTeamJob = this.teamJobOperators.length
                ? this.getOperators()
                : [];
            if (this.teamJobTimeDetailsList.length > 0) {
                this.teamJobTimeDetailsList.map((x) => {
                    timeRecords.push(
                        new TeamJobTimeDetails().getTeamJobTimeDetailsRequest(x)
                    );
                });
            }
            const teamJobCompleteDto = {
                id: this.teamJobDetails.id,
                scheduledAt:
                    this.teamJobScheduledDate != null
                        ? moment.utc(moment(this.teamJobScheduledDate).utc()).format()
                        : null,
                instructions: this.teamJobInstructions,
                customerId: custId,
                contractorId: this.teamJobContractorId,
                operation: {
                    id: this.teamJobOperation ? this.teamJobOperation.id : null,
                    billingQuantities: this.teamJobOperation
                        ? this.teamJobOperation.billingQuantities
                        : [],
                },
                fields: this.teamJobSelectedFields.map((field) => {
                    return {
                        id: field.id,
                    };
                }),
                operators: operatorsForTeamJob,
                metaData: this.teamJobMetaDataList,
                addresses: this.teamJobAddresses,
                products: this.teamJobProducts,
                attachments: this.teamJobAttachments,
                timeRecords: timeRecords,
                notes: this.teamJobNotes,
            };
            this.$store.dispatch(actionType, teamJobCompleteDto).then((res) => {
                if (res) {
                    this.resetTeamJobs();
                    this.mountTeamJobsDetails();
                }
            });
        },
        resetDetailsPage() {
            this.resetTeamJobs();
            this.mountTeamJobsDetails();
        },
        
        generateReport() {
            this.invoiceReportGenerationInProgress = true
            this.generatedFromJobDetails = true
            setTimeout(() => {
                $("#notiModal").fadeOut()
            }, 3000);
            this.$store.dispatch("generateInvoiceReportUrls",this.teamJobDetails.id).then(() => {
                this.invoiceReportGenerationInProgress = false
                this.generatedFromJobDetails = false
            })
        },
        approveJobForInvoicing() {
            this.teamJobsLoader = true;
            this.$store.dispatch('approveJobForInvoicing', this.teamJobDetails.id).then(() => {
                this.resetTeamJobs();
                this.mountTeamJobsDetails();
            })
        },
        handleViewInvoice() {
            const {service, id} = this.teamJobDetails.invoiceReference;
            if (service === 0) {
                this.$store.state.isRoutedFromReferencePage = true;
                router.push(routeInvoicesDetails + id);
            } else {
                this.$store.dispatch('getInvoiceUrl', {id, service}).then(response => response && window.open(response, '_blank'));
            }
        }
    },
};
