























import $ from 'jquery';
import { mapMutations } from 'vuex';
import permissionsMixin from '@/mixins/permissions-mixin';
import InvoicesExternalComputedMixin from '@/components/invoices-external/invoices-external-computed';
import ConstantValues from '@/plugins/constantValues';

export default {
    name: "CustomerListVue",
    mixins: [permissionsMixin, InvoicesExternalComputedMixin],
    data() {
        return {
            contactSearchTempText: ''
        }
    },
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
            resetPaging: "resetPaging"
        }),
        getExternalContactsList() {
            this.clearFilterOptions();
            this.resetPaging();
            this.contacts = [];
            this.filtersApplied = true;
            this.$store.dispatch('setContactsSearchFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(res => {
                this.loadedContacts = 0;
                this.$store.dispatch('getExternalInvoicingServiceContacts', this.integrationType).then(() => {
                    if (this.contactSearchText == '') {
                        this.clearFilterOptions();
                        this.filtersApplied = false;
                    }
                });

            });
        },
        applySearchContactsFilter() {
            this.resetContactsPaging();
            if(this.contactSearchText.trim()) {
                this.contactSearchTempText = this.contactSearchText
                this.getExternalContactsList()
            } else {
                this.resetExternalContactsList()
            }
        },
        resetExternalContactsList() {
            this.resetContactsPaging();
            this.getExternalContactsList()
        },
        resetContactsPaging() {
            this.clearFilterOptions();
            this.resetPaging();
            this.tempIntegratedContacts = [];
            this.contacts = [];
        }
    },
}
