import buildUrl from "build-url"
import { 
    apiOperations, 
    apiProducts, 
    apiTeamMembers, 
    apiUsers, 
    apiFields, 
    apiAddresses, 
    apiJobs, 
    apiVehicles, 
    apiImplements, 
    apiJobAccept, 
    apiJobDecline, 
    routeJobs, 
    apiJobLocations, 
    apiJobPartComplete, 
    apiJobComplete, 
    apiJobForceFinish, 
    apiJobCancel,
    apiQuickbooksInvoice,
    apiSageInvoice,
    apiXeroInvoice
} from "@/utils/endpoints";
import requests from "@/utils/requests";
import { getResponseData, responseHasListData, notify, getInitials, devLogger, getListLengthAbsolute } from "@/utils/helpers";
import loadGoogleMapsApi from 'load-google-maps-api';
import $ from 'jquery';
import { 
    createPolygonShapes, 
    polygonMouseHoverEvent, 
    createHighlightedPolyShape, 
    boundMapOnCordinates, 
    setLabelsOnMap, 
    setLabelsInvisible, 
    setLabelsVisible, 
    changeInfoWindowTheme, 
    createPolyline, 
    extendBoundsByLocations
} from '@/utils/helpers/gmaps-helpers';
import FieldLabelComponent from '@/components/shared/labels/fields-label-stroke.vue';
import Vue from 'vue';
import { getLoggedInUserRole, getOwnerId } from "@/utils/helpers/user-role-helpers";
import router from '@/router';
import signalR from '@/utils/signalr';
import { ExternalAccountType } from '@/enum/externalAccountType';
import operatorIcon from '@/components/shared/icon/icon.vue';
import StringConstants from "@/plugins/stringConstants";
import ConstantValues from "@/plugins/constantValues";
import { TeamJobMapFieldDto } from "@/data/models/TeamJobMapFieldDto";

const fieldLabelConstructor = Vue.extend(FieldLabelComponent);
const operatorIconConstructor = Vue.extend(operatorIcon);
const defaultBusinessLatitude = localStorage.getItem('defaultStartingLat') ? parseFloat(localStorage.getItem('defaultStartingLat')) : ConstantValues.defaultBusinessLatitude;
const defaultBusinessLongitude = localStorage.getItem('defaultStartingLng') ? parseFloat(localStorage.getItem('defaultStartingLng')) : ConstantValues.defaultBusinessLongitude;

export const actions = {

    async getTeamJobsOwners({ state, rootState, commit, dispatch }) {
        rootState.hasMoreData = true;
        state.teamJobUsersListLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + getOwnerId() + '/' + state.teamJobOwnerTypeText[state.teamJobOwnerType].toLowerCase() + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.teamJobUsersListLoader = false;
        if (responseHasListData(result)) {
            commit('setTeamJobsOwners', getResponseData(result));
            if(result?.data?.size && !state.teamJobUserSearchText) {
                state.teamJobUsersListSize = result.data.size;
            }
            if(result.data.size == result.data.value.length || result.data.value.length == 0){
                rootState.hasMoreData = false;
            }
            return result;
        }
    },

    async setTeamJobCustomerFilters({ commit, state }, data) {
        if(data) {
            commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
            commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
        }
        if (state.teamJobUserSearchText != null) {
            const search = state.teamJobUserSearchText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async setTeamJobFieldCustomerSearchText({ commit, state }, data) {
        if(data) {
            commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
            commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
        }
        if (state.teamJobFieldsCustomerSearchText != null) {
            const search = state.teamJobFieldsCustomerSearchText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async getTeamJobOperations({ state, rootState, commit, dispatch }) {
        state.teamJobOperationsListLoader = true;
        rootState.hasMoreData = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: state.teamJobOperationOwnerId + '/' + apiOperations + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.teamJobOperationsListLoader = false;
        if (responseHasListData(result)) {
            commit('setTeamJobsOperations', getResponseData(result));
            if(!state.teamJobOperationsSearchText) {
                state.teamJobOperationsListSize = result.data.size;
            }
            if(result.data.size == result.data.value.length || result.data.value.length == 0){
                rootState.hasMoreData = false;
            }
        }
    },

    async getTeamJobOperationResource({ state, rootState, dispatch }, id) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: state.teamJobOperationOwnerId + '/' + apiOperations + '/' + id
        });
        const result = await requests.getData(url);
        return result;
    },

    async setTeamJobOperationsFilters({ commit, state }) {
        if (state.teamJobOperationsSearchText != null) {
            const search = state.teamJobOperationsSearchText.trim();
            if (search && search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
            }
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.teamJobOperationsList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        return
    },

    async getTeamJobTeamMembers({state, rootState, commit, dispatch }) {
        state.teamJobOperatorToAddLoader = true;
        rootState.hasMoreData = true;
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthWithoutOwner(state.teamJobsTeamMembersListForOperators)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + getOwnerId() + '/' + apiTeamMembers + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.teamJobOperatorToAddLoader = false;
        if (result && result.data) {
            commit('setTeamMembersListForTeamsJob', [result.data.value, rootState.loginUserName]);
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.data.value);
            }
            if(result.data.size == result.data.value.length || result.data.value.length == 0){
                rootState.hasMoreData = false;
            }
        }
    },

    async getTeamJobVehiclesList({ state, commit, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiVehicles + rootState.tempUrl
        });

        state.teamJobVehiclesListForOperatorsLoader = true;
        const result = await requests.getData(url);
        state.teamJobVehiclesListForOperatorsLoader = false;
         
        if (result?.data) {
            if (result.data.value.length > 0) {
                commit('setTeamJobVehiclesList', result.data.value);
            }
            if(!state.teamJobVehiclesSearchTextForOperators) {
                state.teamJobVehiclesListForOperatorsSize = result.data.size;
            }
         }
    },

    async getTeamJobImplementsList({ state, commit, rootState, dispatch }) {
        rootState.hasMoreData = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiImplements + rootState.tempUrl
        });

        state.teamJobImplementsListForOperatorsLoader = true;
        const result = await requests.getData(url);
        state.teamJobImplementsListForOperatorsLoader = false;

        if (result?.data) {
                if (result.data.value.length > 0) {
                    commit('setTeamJobImplementsList', result.data.value);
                }
                if(!state.teamJobImplementsSearchTextForOperators) {
                    state.teamJobImplementsListForOperatorsSize = result.data.size;
                }
        }
    },

    async setTeamJobMachinesPaging({commit}, data) {
        const { searchText, list } = data;
        if(searchText) {
            commit('setFilterOptions', [ConstantValues.offsetQuery, ConstantValues.defaultPageOffsetSize]);
            commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        } else {
            commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(list)]);
            commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        }
    },

    async setTeamJobVehiclesSearchFilters({ commit, state, dispatch }) {
        if (state.teamJobVehiclesSearchTextForOperators != null) {
            const search = state.teamJobVehiclesSearchTextForOperators.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
            }
        }
        
        await dispatch('setTeamJobMachinesPaging', {
            searchText: state.teamJobVehiclesSearchTextForOperators,
            list: state.teamJobVehiclesListForOperators
        })

        return true;
    },

    async setTeamJobImplementsSearchFilters({ commit, state, dispatch }) {
        if (state.teamJobImplementsSearchTextForOperators != null) {
            const search = state.teamJobImplementsSearchTextForOperators.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
            }
        }
        await dispatch('setTeamJobMachinesPaging', {
            searchText: state.teamJobImplementsSearchTextForOperators,
            list: state.teamJobImplementsListForOperators
        })
        return true
    },

    async getTeamJobAddresses({ commit, rootState, state }) {
        let farmAddressesOwner = getOwnerId()
        if ((getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) && state.teamJobUser) {
            farmAddressesOwner = state.teamJobUser.id
        }
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + farmAddressesOwner + apiAddresses
        });
        const result = await requests.getData(url);
        if (responseHasListData(result)) {
            commit('setTeamJobsAddressList', getResponseData(result));
        }
    },

    async getTeamJobProducts({state, commit, rootState, dispatch }) {
        rootState.hasMoreData = true;
        state.teamJobProductsLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: getOwnerId() + apiProducts + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.teamJobProductsLoader = false;
        if (result && result.data) {
            if (result.data.value.length > 0) {
                commit('setProductsListForTeamsJob', result.data.value);
            }
            if(result.data.size == result.data.value.length || result.data.value.length == 0){
                rootState.hasMoreData = false;
            }
        }
    },

    async setTeamJobProductsSearchText({ commit, state }) {
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.teamJobProductsList)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
        if (state.teamJobProductsSearchText != null) {
            const search = state.teamJobProductsSearchText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async setTeamMembersForTeamJobSearchText({ commit, state }) {
        if (state.teamJobTeamMembersForOperatorsSeachText != null) {
            const search = state.teamJobTeamMembersForOperatorsSeachText.trim();
            if (search.length > 0) {
                commit('setFilterOptions', [ConstantValues.searchTextQuery, search]);
                return true
            }
        }
        return false
    },

    async addTeamJobAttachment({ commit, rootState }, data) {
        const { obj, type, purpose } = data;
        const url = buildUrl(rootState.baseUrl, {
            path: "attachments"
        });
        const result = await requests.postData(url, obj);

        if (result != null && result[0]) {
            commit('setTeamJobAttachment', {
                result: result[0],
                type: type,
                purpose: purpose
            });
            return result;
        }
    },

    async deleteTeamJobAttachment({ rootState, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: "attachments" + "?url=" + data
        });
        const result = await requests.deleteData(url,);
        if (result != null) {
            commit('deleteAttachmentFromList', data);
        }
    },

    async getTeamJobAllFields({ state, dispatch }, pageId) {
        await dispatch("getTeamJobFields", pageId);
    },


    async getTeamJobFields({ state, commit, rootState, dispatch }, pageId) {
            const url = buildUrl(rootState.baseUrl, {
                path: apiFields + '?&' + ConstantValues.limitQuery + '=10' + '&offset=0'
            });
            const result = await requests.getData(url);
            if (result != null && 'data' in result) {
               const recordSize = result.data.size;
               state.teamJobFieldsTotal = recordSize;
               const urlPath = [];
               const totalCallValue = parseInt((recordSize / ConstantValues.defaultPageLimitForJobsFields).toFixed(0));
               const getAllFields = async (i) => {
                const tempOffset = ConstantValues.defaultPageLimitForJobsFields * i;
                const url = buildUrl(rootState.baseUrl, {
                    path: apiFields + '?&' + ConstantValues.limitQuery + '=' + ConstantValues.defaultPageLimitForJobsFields + '&offset=' + tempOffset
                });
                urlPath.push(url);
               }
               const setDataOnMap = async () => {
                if (recordSize == state.teamJobFieldsList.length){
                    await dispatch('drawPolygonOnTeamJobsFieldMap', state.teamJobFieldsList);
                    await dispatch('setMapBoundTeamJobsMap', state.teamJobFieldsList);
                    await dispatch('setTeamJobsFieldsListLabels', state.teamJobFieldsList);
                    }
                }
               for (let i = 0; i <= totalCallValue; i++) {
                await getAllFields(i);
               }
               await Promise.all(urlPath.map(async (url) => {
                const featchResult = await requests.getData(url, true);
                if (featchResult != null && 'data' in featchResult) {
                   await commit('setFieldsForTeamJobs', featchResult.data.value);
                   setDataOnMap();
                }
              }));
              if (recordSize == 0) {
                    commit('setNoDataStatus', recordSize);
              }
            }
    },

    async destroyMapInstance({ state }){
        google.maps.event.clearInstanceListeners(window);
        google.maps.event.clearInstanceListeners(document);
    },

    async initializeTeamJobsMap({ state }) {
        state.teamJobsFieldMap = null;
        $("#teamJobsFieldMap").html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const mapZoom = ConstantValues.defaultMapZoom;
            const { google } = window;
            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": defaultBusinessLatitude, "lng": defaultBusinessLongitude }),
                mapTypeControl: true,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.teamJobsFieldMap = new google.maps.Map(document.getElementById('teamJobsFieldMap'), mapOptions);
        });
    },

    async drawPolygonOnTeamJobsFieldMap({ state, dispatch, rootState }, data) {
       data = data.filter(x => JSON.parse(x.polygon).length > 0);
        for (let i = 0; i < data.length; i++) {
            const dataField = new TeamJobMapFieldDto(data[i]);
            state.polygonShapes = [];
            const PolygonShape = createPolygonShapes(state.teamJobsFieldMap, dataField);
            state.teamJobPolygons.push(PolygonShape);
            const addListenersOnPolygon = function (PolygonShape) {
                google.maps.event.addListener(PolygonShape, 'click', function (event) {
                    dispatch('storeSelectedFieldOnTeamJobsMap', dataField);
                    state.hasTeamJobChangesOnDetails = true;
                });
            }
            addListenersOnPolygon(PolygonShape);
            polygonMouseHoverEvent(PolygonShape, rootState);
            state.polygonShapes.push(PolygonShape);
            state.polygonShapes[state.polygonShapes.length - 1].setMap(state.teamJobsFieldMap);
        }
    },

    async storeSelectedFieldOnTeamJobsMap({ state, dispatch }, data) {
        let fieldExist = false;
        const inFieldList = state.teamJobSelectedFieldsForList.includes(data);
        if (state.teamJobSelectedFields.length == 0) {
            state.teamJobSelectedFields.push(data);
            if (!inFieldList) {
                state.teamJobSelectedFieldsForList.push(data);
            }
            await dispatch('highlightAddedFieldsOnTeamJobsMap', data);
        } else {
            state.teamJobSelectedFields.map((x) => {
                if (x.id == data.id) {
                    state.teamJobSelectedFields = state.teamJobSelectedFields.filter(x => x.id != data.id);
                    state.teamJobSelectedFieldsForList = state.teamJobSelectedFieldsForList.filter(x => x.id != data.id);
                    dispatch('removeHighlightAddedFieldsOnTeamJobsMap', data);
                    fieldExist = true;
                }
            })
            if (!fieldExist) {
                state.teamJobSelectedFields.push(data);
                if (!inFieldList) {
                    state.teamJobSelectedFieldsForList.push(data);
                }
                await dispatch('highlightAddedFieldsOnTeamJobsMap', data);
            }
        }
    },

    async removeHighlightAddedFieldsOnTeamJobsMap({ state }, data) {
        const polyPath = state.teamJobSelectedPolygon.filter(x => x.fieldId == data.id)[0].polyPath;
        state.teamJobSelectedPolygon = state.teamJobSelectedPolygon.filter(x => x.fieldId != data.id);
        polyPath.setMap(null);
    },

    async clearExistingFieldsTeamJobsMap({ state }) {
        if (state.teamJobPolygons.length > 0) {
            for (let i = 0; i < state.teamJobPolygons.length; i++) {
                state.teamJobPolygons[i].setMap(null);
            }
            state.teamJobPolygons = [];
        }
    },

    async highlightAddedFieldsOnTeamJobsMap({ state, dispatch }, data) {
        if (data.polygon.length > 0) {
            const fieldShape = await createHighlightedPolyShape(state.teamJobsFieldMap, data);
            fieldShape.setMap(state.teamJobsFieldMap);
            const selectedFields = {
                fieldId: data.id,
                polyPath: fieldShape
            }
            state.teamJobSelectedPolygon.push(selectedFields);
            const addListenersOnPolygon = function (fieldShape) {
                google.maps.event.addListener(fieldShape, 'click', function (event) {
                    dispatch('storeSelectedFieldOnTeamJobsMap', data);
                    state.hasTeamJobChangesOnDetails = true;
                });
            }
            addListenersOnPolygon(fieldShape);
        }
    },

    async setMapBoundTeamJobsMap({ state }, data) {
        if (data != null && data.length != 0) {
            boundMapOnCordinates(state.teamJobsFieldMap, data);
        }
    },

    async setTeamJobsFieldsListLabels({ state }, data) {
        try {
            if (data) {
                if (state.teamJobFieldLabels.length > 0 && state.teamJobsFieldMap) {
                    for (let i = 0; i < state.teamJobFieldLabels.length; i++) {
                        state.teamJobFieldLabels[i].setMap(null);
                    }
                }
                for (let i = 0; i < data.length; i++) {
                    const tempBounds = new google.maps.LatLngBounds();
                    const dataField = new TeamJobMapFieldDto(data[i]);
                    
                    if (dataField.polygon.length > 0) {
                        const fieldName = data[i].title;

                        const fieldLabelComponent = new fieldLabelConstructor({ propsData: { fieldName } });
                        fieldLabelComponent.$mount();

                        const fieldLabelString = new XMLSerializer().serializeToString(fieldLabelComponent.$el);

                        const fieldLabelUrl = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(fieldLabelString);
                        for (let j = 0; j < dataField.polygon.length; j++) {
                            const x = {
                                lat: dataField.polygon[j].latitude,
                                lng: dataField.polygon[j].longitude
                            }
                            const BoundLatLng = new google.maps.LatLng({ "lat": parseFloat(x.lat), "lng": parseFloat(x.lng) });
                            tempBounds.extend(BoundLatLng);
                        }
                        const centroid = tempBounds.getCenter();

                        const markerLabel = new google.maps.Marker({
                            position: centroid,
                            icon: fieldLabelUrl
                        });
                        state.teamJobFieldLabels.push(markerLabel);
                    }
                }

                if (state.teamJobFieldLabels.length > 0) {
                    for (let i = 0; i < state.teamJobFieldLabels.length; i++) {
                        state.teamJobFieldLabels[i].setMap(state.teamJobsFieldMap);
                    }
                }

                setLabelsOnMap(state.teamJobFieldLabels, state.teamJobsFieldMap)

                setLabelsInvisible(state.teamJobFieldLabels)

                if (google.maps && state.teamJobsFieldMap) {
                    google.maps.event.addListener(state.teamJobsFieldMap, ConstantValues.defaultZoomChangedEvent, function () {
                        const zoomLevel = state.teamJobsFieldMap.getZoom();
                        if (zoomLevel >= ConstantValues.defaultLabelVisibilityZoomLevel) {
                            setLabelsVisible(state.teamJobFieldLabels)
                        } else {
                            setLabelsInvisible(state.teamJobFieldLabels)
                        }
                    });
                }
            }
        }
        catch (e) {
            devLogger().logError(e);
        }
    },

    async navigateToSearchField({ state }, fieldId) {
         if (state.teamJobFieldsList && state.teamJobFieldsList.length > 0) {
            const polygonPoints = JSON.parse(state.teamJobFieldsList.filter(field => field.id == fieldId)[0].polygon)

            const bounds = new google.maps.LatLngBounds()

            polygonPoints.map(polygon => {
                const LatLng = new google.maps.LatLng(polygon.latitude, polygon.longitude)
                bounds.extend(LatLng)
            })

            if (state.teamJobsFieldMap) {
                state.teamJobsFieldMap.fitBounds(bounds)
                state.teamJobsFieldMap.setZoom(ConstantValues.defaultMapZoom)
            }
        }
    },

    async filterCustomerFieldsTeamJob({ state, dispatch }) {
        dispatch('clearExistingFieldsTeamJobsMap', state.teamJobFieldsList);
        if (state.teamJobFieldCustomerId) {
            state.teamJobFieldsList = state.teamJobFieldsTempList.filter(x => x.ownerId === state.teamJobFieldCustomerId);
        } else {
            state.teamJobFieldsList = state.teamJobFieldsTempList;
        }
        if (state.teamJobFieldsList.length != 0) {
            await dispatch('drawPolygonOnTeamJobsFieldMap', state.teamJobFieldsList);
            await dispatch('setMapBoundTeamJobsMap', state.teamJobFieldsList);
            await dispatch('setTeamJobsFieldsListLabels', state.teamJobFieldsList);
        }
        else {
            const tempBounds = new google.maps.LatLngBounds({ "lat": defaultBusinessLatitude, "lng": defaultBusinessLongitude });
            state.teamJobsFieldMap.fitBounds(tempBounds);
            state.teamJobsFieldMap.setZoom(ConstantValues.defaultMapZoom)
        }
    },

    async getTeamJobDetails({ rootState, commit }, resourceId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + resourceId
        });
        const result = await requests.getData(url);

        if (result != null && 'data' in result) {
            if (result.data != null) {
                commit('setTeamJobDetails', result.data);
                return result.data;
            }
        }
        return false;
    },

    async saveTeamJob({ state, rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs
        });
        state.teamJobsAddLoader = true;
        const result = await requests.postData(url, data);
        state.teamJobsAddLoader = false;
        if (result[0] != null) {
            return result[0];
        }
    },

    async teamJobChangeInfoWindowTheme() {
        changeInfoWindowTheme('#job-map-drop-pin', 'dark')
    },

    async getGoogleMapToSelectDefaultLocation({ rootState, state, dispatch }) {
        const _usersModule = rootState.usersModule
        state.jobDropPinMap = null
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(async () => {
            const mapZoom = ConstantValues.defaultMapZoom
            const { google } = window
            const mapOptions = {
                zoom: 16,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": defaultBusinessLatitude, "lng": defaultBusinessLongitude }),
                mapTypeControl: true,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                rotateControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.jobDropPinMap = new google.maps.Map(document.getElementById('job-map-drop-pin'), mapOptions)

            state.teamJobMapLocationInfoWindow = new google.maps.InfoWindow()

            if (_usersModule.startingLat && _usersModule.startingLng) {

                state.teamJobAddressesDropPinLatitude = _usersModule.startingLat
                state.teamJobAddressesDropPinLongitude = _usersModule.startingLng

                state.teamJobDropPinLatLng = JSON.stringify({
                    lat: _usersModule.startingLat,
                    lng: _usersModule.startingLng
                }, null, 2)

                if (state.teamJobMapLocationInfoWindow) {
                    state.teamJobMapLocationInfoWindow.setPosition(new google.maps.LatLng(_usersModule.startingLat, _usersModule.startingLng))
                    state.teamJobMapLocationInfoWindow.setContent(_usersModule.startingLat.toFixed(4).toString() + ', ' + _usersModule.startingLng.toFixed(4).toString())

                    state.teamJobMapLocationInfoWindow.open(state.jobDropPinMap)
                }
            } else {
                if (state.teamJobMapLocationInfoWindow) {
                    state.teamJobMapLocationInfoWindow.setPosition(new google.maps.LatLng(defaultBusinessLatitude, defaultBusinessLongitude))
                    state.teamJobMapLocationInfoWindow.setContent(defaultBusinessLatitude.toFixed(4).toString() + ', ' + defaultBusinessLongitude.toFixed(4).toString())
                    state.teamJobMapLocationInfoWindow.open(state.jobDropPinMap)
                }
            }

            setTimeout(() => {
                dispatch('teamJobChangeInfoWindowTheme')
            }, 200)

            state.jobDropPinMap.addListener("click", (mapsMouseEvent) => {
                state.teamJobAddressesDropPinLatitude = mapsMouseEvent.latLng.toJSON().lat
                state.teamJobAddressesDropPinLongitude = mapsMouseEvent.latLng.toJSON().lng

                state.teamJobMapLocationInfoWindow.close()

                state.teamJobMapLocationInfoWindow.setPosition(mapsMouseEvent.latLng)
                const latLngString = state.teamJobAddressesDropPinLatitude.toFixed(4).toString().trim() + ', ' + state.teamJobAddressesDropPinLongitude.toFixed(4).toString().trim()
                state.teamJobMapLocationInfoWindow.setContent(latLngString)

                state.teamJobMapLocationInfoWindow.open(state.jobDropPinMap)
                dispatch('teamJobChangeInfoWindowTheme')
            })
        })
    },

    async updateTeamJob({ rootState, state, commit }, data) {
        state.teamJobsLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + data.id
        });
        const result = await requests.putData(url, data);
        try {
            if (result[0] != null) {
                commit('updateJobInList', result[0]);
                return result[0];
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            state.teamJobsLoader = false;
        }
    },

    async deleteTeamJob({ rootState, state, commit }, id) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + id
        });
        state.teamJobsLoader = true;
        const result = await requests.deleteData(url);
        state.teamJobsLoader = false;
        if (result) {
            const jobIndex = rootState.teamJobsListModule.teamJobsList.findIndex(teamJob => teamJob.id === id);
            const isLastIndex = jobIndex === rootState.teamJobsListModule.teamJobsList.length - 1;
            const scrollToIndex = isLastIndex ? jobIndex - 1 : jobIndex;
            if (jobIndex > -1) {
                commit('removeJobFromList', jobIndex);
                commit('updatePagingAfterListItemRemoval');
                if (jobIndex !== 0) {
                    commit('updateScrollToItem', rootState.teamJobsListModule.teamJobsList[scrollToIndex].id);
                }
            }
            return true
        } else {
            return false
        }
    },

    async acceptTeamJobByContractor({ rootState, state, commit }, resourceId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + resourceId + apiJobAccept
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, '');
        if (result != null) {
            commit('updateJobInList', result);
            return result;
        }
        else {
            state.teamJobsLoader = false;
        }
    },

    async declineTeamJobByContractor({ rootState, state, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + data.resourceId + apiJobDecline
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, data.reason);
        state.teamJobsLoader = false;
        if (result[0] != null) {
            notify(StringConstants.teamJobDeclinedSuccessfully, 'success');
            commit('updateJobInList', result[0]);
        }
    },

    async cancelTeamJobByContractor({ rootState, state, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + data.resourceId + apiJobCancel
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, data.reason);
        state.teamJobsLoader = false;
        if (result[0] != null) {
            notify(StringConstants.teamJobCancelledSuccessfully, 'success');
            const jobIndex = rootState.teamJobsListModule.teamJobsList.findIndex(job => job.id === result[0].id);
            const isLastIndex = jobIndex === rootState.teamJobsListModule.teamJobsList.length - 1;
            const scrollToIndex = isLastIndex ? jobIndex - 1 : jobIndex;
            if (jobIndex > -1) {
                commit('removeJobFromList', jobIndex);
                if (jobIndex !== 0) {
                    commit('updateScrollToItem', rootState.teamJobsListModule.teamJobsList[scrollToIndex].id);
                }
            }
            return true;
        } else {
            return false;
        }
    },

    async forceFinishTeamJob({ rootState, state, commit }, resourceId) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + "/" + resourceId + apiJobForceFinish
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, '');
        if (result != null) {
            commit('updateJobInList', result[0]);
            return result;
        }
        else {
            state.teamJobsLoader = false;
        }
    },

    async initializeTeamJobsLocationMap({ state }) {
        state.teamJobsLocationMap = null;
        $("#teamJobsLocationMap").html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const mapZoom = ConstantValues.defaultMapZoom;
            const { google } = window;
            const mapStyle = [{
                  elementType: 'labels',
                  stylers: [{visibility: 'off'}]
                }];
            const mapOptions = {
                zoom: mapZoom,
                center: new google.maps.LatLng({ "lat": defaultBusinessLatitude, "lng": defaultBusinessLongitude }),
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                 }
             };
            state.teamJobsLocationMap = new google.maps.Map(document.getElementById('teamJobsLocationMap'), mapOptions);
        });
    },

    async drawFieldsOnLocationMap({ state }, data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].polygon.length > 0) {
                state.locationFieldsShapes = [];
                const PolygonShape = createPolygonShapes(state.teamJobsLocationMap, data[i]);
                state.locationFieldsShapes.push(PolygonShape);
                state.locationFieldsShapes.push(PolygonShape);
                state.locationFieldsShapes[state.locationFieldsShapes.length - 1].setMap(state.teamJobsLocationMap);
            }
        }
    },

    async getOperatorLocationHistory({ rootState, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + data[0] + apiJobLocations + '?operatorId=' + data[1]
        });
        const result = await requests.getData(url);
        if (result.data) {
            commit('setTeamJobsOperatorLocationHistory', result.data);
            return result.data;
        }
    },

    async clearOperatorPolylines({ state }) {
        if (state.operatorLocationShapes) {
            state.operatorLocationShapes.map((x) => {
                x.setMap(null);
            });
        }
    },

    async setLocationBounds({ state }, locations) {
        if (locations) {
            extendBoundsByLocations(locations, state.teamJobDetails.fields, state.teamJobsLocationMap);
        }
    },

    async drawSelectedOperatorPolylines({ state, dispatch }) {
        const data = state.selectedOperatorLocations.filter(x => x.locations.length > 0);
        state.operatorLocationShapes = [];
        for (let i = 0; i < data.length; i++) {
            const polyline = createPolyline(data[i].locations);
            state.operatorLocationShapes.push(polyline);
            polyline.setMap(state.teamJobsLocationMap);
            await dispatch('setLocationBounds', data[i].locations)
        }
        if (data.length == 0) {
            await dispatch('setLocationBounds', [])
        }
    },

    async partCompleteTeamJob({ state, rootState, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + data.id + apiJobPartComplete
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            commit('updateJobInList', result[0]);
            return result[0];
        }
        else {
            state.teamJobsLoader = false;
        }
    },

    async completeTeamJob({ state, rootState, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '/' + data.id + apiJobComplete
        });
        state.teamJobsLoader = true;
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            commit('updateJobInList', result[0]);
            return result[0];
        }
        else {
            state.teamJobsLoader = false;
        }
    },

    async subscribeJobOperators({ state }, data) {
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                await signalR.fnSubscribeGroup(data[i].id);
            }
        }
    },

    async clearOperatorMarkerOnMap({ state }) {
        if (state.operatorsMarkers.length != 0) {
            state.operatorsMarkers.map(x => {
                x.marker.setMap(null);
            })
        }
    },

    async getPolyLineMarkersOnMap({ state, dispatch }, data) {
        const location = data;
        if (location.userId == state.teamJobLocationSelectedOperatorId) {
            dispatch('createRealTimePolyLineOnMap', location);
        }
        const userId = location.userId;
        const infoLat = location.latitude;
        const infoLng = location.longitude;
        const initials = getInitials(location.name);
        
        const updateMarker = state.operatorsMarkers.filter(x => x.userId === location.userId);
        if (updateMarker.length > 0) {
            const position = new google.maps.LatLng(infoLat, infoLng);
            updateMarker[0].marker.setPosition(position);
            return
        }
        function createMarkerIcon(fillColor) {
            const iconComponent = new operatorIconConstructor({ propsData: { fillColor, initials } })
            iconComponent.$mount();
            const iconDom = iconComponent.$el;
            const iconString = new XMLSerializer().serializeToString(iconComponent.$el);
            const operatorUrl = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString);

            const icon = {
                url: operatorUrl,
                fillColor: fillColor,
                strokeColor: '#ffffff',
                fillOpacity: 1,
                strokeWeight: 1,
                scale: new google.maps.Size(68, 36),
                anchor: new google.maps.Point(34, 6),

            };
            return icon;
        }
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(infoLat, infoLng),
            map: state.teamJobsLocationMap,
            visible: true,
            zIndex: 999,
            icon: createMarkerIcon("#ffffff")
        });

        const oprMarker = {
            userId: location.userId,
            marker: marker
        }
        state.operatorsMarkers.push(oprMarker);
    },

    async createRealTimePolyLineOnMap({ state }, data) {
        const latLng = {
            latitude: data.latitude,
            longitude: data.longitude,
            timestamp: data.timestamp
        }
        if (state.selectedOperatorLocations.length) {
            const lastLocationTimestamp = state.selectedOperatorLocations[state.selectedOperatorLocations.length - 1].timestamp;
            const timeGapLessThanThreshold = (new Date(data.timestamp).getTime() - new Date(lastLocationTimestamp).getTime()) < 30000;
            if (timeGapLessThanThreshold) {
                const polyline = state.operatorLocationShapes[state.operatorLocationShapes.length - 1];
                const path = polyline.getPath();
                path.push(new google.maps.LatLng(data.latitude, data.longitude));
            }
        }
        if (data.timestamp && state.operatorRealTimeLocation.length) {
            const lastRealTimeTimestamp = new Date(state.operatorRealTimeLocation[state.operatorRealTimeLocation.length - 1].timestamp).getTime();
            const currentTimestamp = new Date(data.timestamp).getTime();
            const selectedOperator = state.teamJobOperators.find(operator => operator.operatorDetails.id === state.teamJobLocationSelectedOperatorId);
            const travelHistory = selectedOperator.operatorDetails.travelHistory;
            const workHistory = selectedOperator.operatorDetails.workHistory;
            const pauseStatusHistory = [...workHistory, ...travelHistory].filter(history => history.status === 1).sort((a,b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
            const hasOperatorReachedLocation = travelHistory[travelHistory.length - 1]?.status === 2;
            const hasOperatorStartedJob = workHistory;
            const lastPauseTimestamp = new Date(pauseStatusHistory[0]?.createdOn).getTime();

            if ((lastPauseTimestamp <= currentTimestamp
                && lastPauseTimestamp >= lastRealTimeTimestamp)
                || (hasOperatorReachedLocation && !hasOperatorStartedJob)) {
                state.operatorLocationShapes = [];
                state.operatorRealTimeLocation = [];
            }
        }
        const coordsToDraw = [];
        state.operatorRealTimeLocation.push(latLng);

        if (state.operatorRealTimeLocation.length > 2) {
            const polyline = state.operatorLocationShapes[state.operatorLocationShapes.length - 1];
            const polylinePath = polyline.getPath();
            polylinePath.push(new google.maps.LatLng(latLng.latitude, latLng.longitude));
            return;
        } else if (state.operatorRealTimeLocation.length > 1) {
            state.operatorRealTimeLocation.forEach((location: {latitude: number, longitude: number}) => {
                coordsToDraw.push({
                    latitude: location.latitude,
                    longitude: location.longitude
                });
            });
            const polyline = createPolyline(coordsToDraw);
            state.operatorLocationShapes.push(polyline);
            polyline.setMap(state.teamJobsLocationMap);
        }
    },

    async getInvoiceUrl({rootState, state, commit, dispatch}, data) {
        const {id, service} = data;
        let targetServiceUrl;
        state.teamJobsLoader = true;
        if (service === ExternalAccountType.QuickBooks) {
            targetServiceUrl = apiQuickbooksInvoice;
        } else if (service === ExternalAccountType.Sage) {
            targetServiceUrl = apiSageInvoice;
        } else if (service === ExternalAccountType.Xero) {
            targetServiceUrl = apiXeroInvoice;
        }

        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + targetServiceUrl + '/' + id
        });
        const result = await requests.getDataRes(url);
        state.teamJobsLoader = false;
        return result.data;
    },

    async openJobContextMenu({state}, id) {
        alert('Job menu for ' + id);
    }
}