















































































import { formFieldPb, containerLeftPad, containerRightPad } from '@/utils/uiconstants';
import { closeOverlay } from '@/utils/helpers';
import { mapMutations } from 'vuex';
import teamMembersComputedMixin from '@/components/team-members/team-members-computed';
import { HeaderPrimary } from '@/components/shared/headers/index';
import { BaseButton as Button } from '@/components/shared/buttons/index';
import ConstantValues from '@/plugins/constantValues';
import {FeatureTypeLabels} from '@/enum/featureType';
import {ManagerFeatureLabels, TeamLeadFeatureLabels, OperatorFeatureLabels, UserRoleType} from '@/enum/userRoleType';

export default {
    computed: {
        Features () {
            return FeatureTypeLabels;
        },
        ManagerFeatures() {
            return ManagerFeatureLabels;
        },
        TeamLeadFeatures() {
            return TeamLeadFeatureLabels;
        },
        OperatorFeatures() {
            return OperatorFeatureLabels;
        },
        UserRole() {
            return UserRoleType;
        }
    },
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad
        }
    },
    components: {
        HeaderPrimary,
        Button
    },
    mixins: [teamMembersComputedMixin],
    methods: {
        ...mapMutations({
            resetPaging: 'resetPaging',
            setClearFilterOptions: 'setClearFilterOptions',
            setClearTeamMembersList: 'setClearTeamMembersList',
            resetSnackbarDetails: "clearSnackbarDetails",
        }),
        
        updateTeamMemberRole() {
            closeOverlay("editTeamMemberFormOverlay");
            const updateRoleObj = {
                oldRole: this.teamMembeOldRole,
                newRole: this.teamMemberDetails.roles.type
            }
            this.$store.dispatch('updateTeamMemberRole', [this.teamMemberDetails.id, updateRoleObj]).then((res) => {
                this.resetSnackbarDetails();
                this.setClearFilterOptions();
                this.resetPaging();
                this.setClearTeamMembersList();

                this.$store.dispatch('fnSetTeamMembersFilter', [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then((rs) => {
                    this.$store.dispatch('getTeamMembers');
                });
            });
        },

         closeMemberEditForm() {
            closeOverlay("editTeamMemberFormOverlay");
        }
    }
}
