












































































import contactComputedMixin from "@/components/internal-contact/internal-contact-computed";
import SectionLeftHeadings from "@/components/shared/headers/section-left-headings.vue";
import ConstantValues from '@/plugins/constantValues';

export default {
    name: "ContactCompanyDetailsForm",
    mixins: [contactComputedMixin],
    props: ["isEdiablePrefix"],
    
    watch: {
        businessProfilePrefix() {
            this.businessProfilePrefix = this.getValidatedString(
                this.businessProfilePrefix
            );
        },
    },
    components: {
        SectionLeftHeadings,
    },

    methods: {
        updatePrefix(event: any) {
            if (this.isEdiablePrefix) {
                if (event.currentTarget.value) {
                    const shortenedBusinessName: string = this.getValidatedString(event.currentTarget.value).substring(0, ConstantValues.defaultBusinessProfilePrefixLength);

                    if (shortenedBusinessName.length == ConstantValues.defaultBusinessProfilePrefixLength) {
                        this.businessProfilePrefix = shortenedBusinessName;
                    }
                }
            }
        }
    },
};
