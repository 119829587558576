










































































import accountComputedMixin from "@/components/account/account-computed";
import SectionLeftHeadings from "@/components/shared/headers/section-left-headings.vue";
import { UserRoleType } from "@/enum/userRoleType";
export default {
  name: "CompanyDetailsForm",
  mixins: [accountComputedMixin],
  data() {
    return {
         UserRoleType
      };
  },
  components: {
    SectionLeftHeadings,
  },
  watch: {
    businessProfilePrefix() {
      this.businessProfilePrefix = this.getValidatedString(
        this.businessProfilePrefix
      );
    },
  },
};
