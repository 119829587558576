


















































































































































































































































import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import SectionLeftHeadings from '@/components/shared/headers/section-left-headings.vue';
import { checkFieldsValidity, getAddressAsSingleString } from '@/utils/helpers';
import { BaseButton as Button } from '@/components/shared/buttons/index';

export default {
    name: 'ContactFarmsAddress',
    mixins: [contactComputedMixin],
    components: {
        SectionLeftHeadings,
        Button,
    },
    props: ['contactId'],
    data() {
        return {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
            showFarmAddressAddFields: false,
        }
    },
    computed: {
        isNewFarmFieldsValid() {
            if (checkFieldsValidity([
                this.addressLine1,
                this.postalCode
            ])) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        isAddressValid(address) {
            if (checkFieldsValidity([
                address.addressLine1,
                address.postalCode,
            ])) {
                return true;
            } else {
                return false;
            }
        },
        
        removeSelectedAddress(addressId: string) {
            this.$store.dispatch("deleteContactAddresses", [this.contactId, addressId]).then((res) => {
                this.$store.dispatch('getContactFarmsDetailsByAdmin', this.contactId);
            });
        },

        Update(id) {
            let address = this.contactFarmAddresses.filter(x => x.id == id)[0];
            this.$store.dispatch('updateContactAddresses', [address, this.contactId]).then((res) => {
                this.$store.dispatch('getContactFarmsDetailsByAdmin', this.contactId);
            });
        },

        displayAddFarmAddressFields() {
            this.showFarmAddressAddFields = true;
        },

        addNewAddress() {
            let newAddressObj = {};
            newAddressObj = {
                addressLine1: this.addressLine1,
                addressLine2: this.addressLine2,
                city: this.city,
                state: this.state,
                postalCode: this.postalCode,
                country: this.country,
                coordinate: null,
            }
            this.$store.dispatch('addContactAddresses', [newAddressObj, this.contactId]).then((res) => {
                this.$store.dispatch('getContactFarmsDetailsByAdmin', this.contactId);
                this.hideAddFarmAddressFields();
            });
        },
        getFarmAddressInLine(address){
            return getAddressAsSingleString(address);
        },

        hideAddFarmAddressFields() {
            this.addressLine1 = '';
            this.addressLine2 = '';
            this.city = '';
            this.state = '';
            this.postalCode = '';
            this.country = '';
            this.latitude = 0;
            this.longitude = 0;
            this.showFarmAddressAddFields = false;
        },
    }
}
