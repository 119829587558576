export enum ExternalIntegrationType {
    Quickbooks = 1,
    Xero = 2,
    Sage = 3,
}

export const ExternalIntegrationTypeLabels = {
    [ExternalIntegrationType.Quickbooks]: 'quickbooks',
    [ExternalIntegrationType.Xero]: 'xero',
    [ExternalIntegrationType.Sage]: 'sage',
};
