















































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { HeaderPrimary } from "@/components/shared/headers/index";
import { formFieldPb, containerLeftPad, containerRightPad } from "@/utils/uiconstants";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { Coordinate } from '@/data/models/Coordinate';
import { TeamJobAddress } from '@/data/models/TeamJobAddress';
import { closeOverlay, notify, listContainsItem } from '@/utils/helpers';
import { TeamJobAddressSelectionType } from '@/enum/jobAddressSelectionType';
import TeamJobsAddressesFlyoutAddressToggle from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-address-toggle.vue';
import TeamJobsAddressesFlyoutFarmAddresses from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-farm-address.vue';
import TeamJobsAddressesFlyoutDropPin from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-drop-pin.vue';
import TeamJobsAddressesFlyoutCustomAddress from '@/components/team-jobs/common/team-jobs-addresses-flyout/team-jobs-addresses-flyout-custom-address.vue';
import { isValidLatitude, isValidLongitude } from '@/utils/helpers/gmaps-helpers';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import ConstantValues from '@/plugins/constantValues';

export default {
    name: "TeamJobsAddressesFlyoutForm",
    props: [
        'addresses'
    ],
    data() {
        return {
            formFieldPb,
            containerLeftPad,
            containerRightPad,
            TeamJobAddressSelectionType,
        };
    },
    mixins: [TeamJobsComputed, TeamJobsCommon],
    components: {
        TeamJobsAddressesFlyoutAddressToggle,
        TeamJobsAddressesFlyoutFarmAddresses,
        TeamJobsAddressesFlyoutDropPin,
        TeamJobsAddressesFlyoutCustomAddress,
        HeaderPrimary,
        Button,
    },
    
    methods: {
        negativeKeydown(e) {
            if (!/[^-+]/.test(e.key)) {
                e.preventDefault();
            }
        },
        closeAddAddressesForm() {
            closeOverlay("jobAddressesFormOverlay");
        },
        clearAddAddressFormData() {
            this.teamJobFarmAddressesSelected = [];
            this.teamJobAddressInstructions = null;
        },
        pushAddress(address) {
            const teamJobAddress = new TeamJobAddress().getTeamJobAddress(address, this.teamJobAddressInstructions)
            this.teamJobAddresses.push(teamJobAddress)
            this.clearAndCloseForm();
        },
        clearAndCloseForm() { 
            this.clearAddAddressFormData();
            this.closeAddAddressesForm();
        },
        addFarmAddressToAddresses() {
            const addressesSelected = Array.isArray(this.teamJobFarmAddressesSelected) 
                                        ?  this.teamJobFarmAddressesSelected 
                                        : [this.teamJobFarmAddressesSelected]

            if(addressesSelected && addressesSelected.length) {
                addressesSelected.forEach(address => {
                    if(this.teamJobAddresses && this.teamJobAddresses.length) {
                        const jobAddressExists = listContainsItem(this.teamJobAddresses, [ConstantValues.defaultObjectIdentifier], address.id)
                        
                        if(!jobAddressExists) {
                            this.pushAddress(address)
                        } else {
                            notify(this.$stringConstants('teamJobLocationAlreadyAdded'), 'fail')
                            this.clearAddAddressFormData();
                        }
                    } else {
                         this.pushAddress(address)
                    }
                });
            }
        },
        addTeamJobCustomAddress() {
          if(this.teamJobCustomAddress.addressLine1) {
              this.pushAddress(this.teamJobCustomAddress)
          } else {
            notify(this.$stringConstants('teamJobLocationInvalid'), 'fail')
          }
        },
        addDropPinAddressToAddresses() {
          if(isValidLatitude(this.teamJobAddressesDropPinLatitude) && isValidLongitude(this.teamJobAddressesDropPinLongitude)) {
              const teamJobAddress = {
                id: null,
                addressLine1: null,
                addressLine2: null,
                city: null,
                state: null,
                country: null,
                description: this.teamJobAddressInstructions,
                postalCode: null,
                coordinate: new Coordinate().getCoordinate({
                  latitude: this.teamJobAddressesDropPinLatitude,
                  longitude: this.teamJobAddressesDropPinLongitude
                })
              }
              this.pushAddress(teamJobAddress)
          } else {
               notify(this.$stringConstants('teamJobLocationInvalidCoordinates'), 'fail')
          }
        },
        addAddressToJobLocations() {
            switch(this.teamJobAddressSelectionType) {
                case TeamJobAddressSelectionType.FarmAddress:
                     this.addFarmAddressToAddresses();
                      this.enableTeamJobDiscard();
                break

                case TeamJobAddressSelectionType.DropPin:
                     this.addDropPinAddressToAddresses();
                      this.enableTeamJobDiscard();
                break

                case TeamJobAddressSelectionType.CustomAddress:
                     this.addTeamJobCustomAddress();
                      this.enableTeamJobDiscard();
                break
                
                default: break
            }
        }
    }
}
