import { apiContactsImportUrl, apiFarmers, apiImport, apiUsers } from '@/utils/endpoints';
import request from '@/utils/requests';
import buildUrl from 'build-url';
import { closeOverlay, notify } from '@/utils/helpers';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { IntegrationAccessStates } from '@/enum/integrationAccessStates';
import { ExternalIntegrationType } from '@/enum/externalIntegrationType';

const INTEGRATION_BUFFER = 3000;

export const actions = {

    async getExternalInvoicingServiceContacts({ state, commit, rootState, dispatch }, type) {
        const loginOwnerId = localStorage.getItem(ConstantValues.ownerId);
        rootState.listLoading = true
        state.customerLoader = true
        state.fetchingintergrationDataProgress = true
        commit('setClearFilterOptions')
        commit('setFilterOptions', ['isDisabled', true]);

        await dispatch('setContactsSearchFilter');

        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: loginOwnerId + "/" + type + "/contacts" + rootState.tempUrl
        });
        
        const result = await request.getData(url);
        state.fetchingintergrationDataProgress = false;

        if (result != null && 'data' in result) {
            if (result?.data?.value?.length != 0) {
                commit('setExternalInvoicingServiceContacts', result.data.value);
            }

            if (result.data.offset == 0) {
                commit('setNoDataStatus', result.data.value);
            }
        }
        state.customerLoader = false;
        rootState.listLoading = false;
    },

    async getExternalInvoicingServiceAccess({ state, commit, rootState }, data) {
        state.quickbookAccessState = IntegrationAccessStates.NoAccess;
        state.xeroAccessState = IntegrationAccessStates.NoAccess;
        state.sageAccessState = IntegrationAccessStates.NoAccess;

        state.integrationsAuthenticationInProgress = true;
        const url = buildUrl(rootState.baseUrl, {
            path: "integrations"
        });

        const result = await request.getDataWithNoContent(url, true);

        if (result != null && 'data' in result) {
            if (result.data != null) {
                result.data.forEach((integration) => {
                    if(integration.isConnected) {
                        if (integration.type == ExternalIntegrationType.Quickbooks) {
                            state.quickbookAccessState = IntegrationAccessStates.HasAccess;
                        }
                        if (integration.type == ExternalIntegrationType.Xero) {
                            state.xeroAccessState = IntegrationAccessStates.HasAccess;
                        }
                        if (integration.type == ExternalIntegrationType.Sage) {
                            state.sageAccessState = IntegrationAccessStates.HasAccess;
                        }
                    }
                });

                const connectedIntegrationData = result.data.filter(integrationData => integrationData.isConnected == true)[0];
                if (connectedIntegrationData) {
                    commit("setIntegrationAccount", connectedIntegrationData);
                }
                return connectedIntegrationData;
            }
        }
        state.integrationsAuthenticationInProgress = false;
    },

    async startIntegrationAuthentication({ state, rootState }, data) {
        state.loadingIntegration = true;
        const url = buildUrl(rootState.baseUrl, {
            path: "integrations/" + data + "/start_auth"
        });
        const result = await request.postData(url, null);
        state.loadingIntegration = false;
        if (result != null && result.length > 0) {
            window.location.href = result;
        }
    },

    async disconnectIntegration({ state, rootState }, type) {
        state.loadingIntegration = true;
        const url = buildUrl(rootState.baseUrl, {
            path: "integrations/" + type + "/disconnect"
        });
        const result = await request.postData(url, null);
        state.loadingIntegration = false;
        location.reload();
    },

    async setContactsSearchFilter({ state, commit }) {
        if (state.contactSearchText) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.contactSearchText]);
        }
    },

    async linkContact({ state, rootState }, data) {
        const loginOwnerId = localStorage.getItem(ConstantValues.ownerId);
        const url = buildUrl(rootState.baseUrl, {
            path: loginOwnerId + "/" + data[0] + "/contacts/" + data[1] + '/link/' + data[2]
        });
        const result = await request.postData(url, null);
        if (result != null) {
            state.contacts = [];
            closeOverlay('customerLinkFormOverlay');
            return result;
        }
    },

    async unlinkContact({ rootState, state }, data) {
        const loginOwnerId = localStorage.getItem(ConstantValues.ownerId);
        const url = buildUrl(rootState.baseUrl, {
            path: loginOwnerId + "/" + data[0] + "/contacts/" + data[1] + '/link/' + data[2]
        });
        const result = await request.deleteData(url);
        if (result != null) {
            state.contacts = [];
            return result;
        }
    },

    async importContactsForIntegration({ state, rootState, dispatch }) {
        dispatch("jsonToQueryString", rootState.filterOptions);
        rootState.bulkActionResponse = null;
        const loginOwnerId = localStorage.getItem(ConstantValues.ownerId);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + "/"  + loginOwnerId + apiFarmers + apiContactsImportUrl + rootState.tempUrl
        });
        const result = await request.postData(url, {});
        
        if (result[0] != null) {
            if(result[0].failed) {
                rootState.dialogBulkActionResponseTitle = StringConstants.bulkActionResponseTitleCustomersImport
                rootState.dialogBulkActionResponse = true;
                rootState.bulkActionResponse = result[0];
            } else {
                notify(StringConstants.farmersImportedFromInvoicingService, 'success')
            }
            return result;
        }
    },

    async checkIsXeroIntegrationPending({ rootState }, email) {
        const url = buildUrl(rootState.baseUrl, {
            path: `integrations/xero/integration_pending?email=${email}`
        });

        const result = await request.getData(url);
        if (result?.status === 200) {
            return result.data;
        }

        return null;
    },

    async checkForIntegrations({ dispatch, state }, email) {
        const res = await dispatch('checkIsXeroIntegrationPending', email);

        if (res == null) return;

        if (res) {
            // state.showIntegrationPrompt = true;
            notify({
                message: StringConstants.integrationXeroIntegrating,
                type: 'warning',
                duration: INTEGRATION_BUFFER
            });

            setTimeout(() => {
                dispatch("startIntegrationAuthentication", 'xero');
            }, INTEGRATION_BUFFER);
        }
    }
}
