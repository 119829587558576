























































































import { BaseButton as Button } from '@/components/shared/buttons/index';

export default {
    name: 'BulkActionResponseDialog',
    props: [
        'bulk-action-callback-method', 
        'hasBulkActionCallbackMethod',
    ],
    computed: {
        bulkActionResponse: {
            get() {
                return this.$store.state.bulkActionResponse
            },
            set(newValue) {
                this.$store.state.bulkActionResponse = newValue
            }
        },
        dialogBulkActionResponse: {
            get() {
                return this.$store.state.dialogBulkActionResponse
            },
            set(newValue) {
                this.$store.state.dialogBulkActionResponse = newValue
            }
        },
        dialogBulkActionResponseTitle: {
            get() {
                return this.$store.state.dialogBulkActionResponseTitle
            },
            set(newValue) {
                this.$store.state.dialogBulkActionResponseTitle = newValue
            }
        },
        enumImportStatus() {
            return this.$store.state.integrationsModule.enumImportStatus
        },
    },
    components: {
        Button
    },
    methods: {
        closeDialogImportUserResponse() {
            this.bulkActionResponse = null
            this.dialogBulkActionResponse = false
            this.dialogBulkActionResponseTitle = null
        },
        bulkActionCallback() {
            if(this.hasBulkActionCallbackMethod) {
                this.$emit('bulk-action-callback-method')
            } else {
                this.closeDialogImportUserResponse()
            }
        }
    }
}
